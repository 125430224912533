import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../../utils';
import AsyncSelect from 'react-select/async';
import GoogleMapInput from '../../GoogleMapInput';


const CUSTOMERS_URL = `/oms/customers/`;
const INVENTORY_URL = `/oms/inventory/search/`;
const ESTIMATES_URL = `/oms/delivery-estimates/`;
const CHECKOUT_URL = `/oms/co/`;


const DELIVERY_EXPRESS = "express"
const DELIVERY_STANDARD = "standard"
const DELIVERY_NEXT_DAY = "nextday"


function AddOrderModal({ customerID, handleModalClose, handleReload }) {
    const [orderItems, setOrderItems] = useState([])
    const [location, setLocation] = useState();
    const [data, setData] = useState()
    const [paymentMethods, setPaymentMethods] = useState()
    const [deliveryWindow, setDeliveryWindow] = useState()
    const [processing, setProcessing] = useState(false)
    const [payOnDeliveryMethod, setPayOnDeliveryMethod] = useState()
    const [loadingInventory, setLoadingInventory] = useState(false)
    const [selectedItem, setSelectedItem] = useState()
    const [totalAmount, setTotalAmount] = useState()
    const [totalQuantity, setTotalQuantity] = useState()
    const [deliveryType, setDeliveryType] = useState()


    const verifyAddress = () => {
        if (true) {
            toast.error('Kindly add delivery address')
        }
    }


    const getPaymentMethods = async () => {
        try {
            const response = await axiosCustom.get(`${INVENTORY_URL}`);
            setPaymentMethods(response?.data)
            response?.data?.results?.map((item, i) => {
                if(item?.code == "101MD"){
                    setPayOnDeliveryMethod(item)
                }

            })
        } catch (err) {
            toast.error("Something went wrong");
        }
    }


    const getInventory = async (inputValue) => {
        const data = []
        const params = { s: inputValue ? inputValue : "" }
        const searchParams = new URLSearchParams(params).toString();
        setLoadingInventory(true)
        try {
            const response = await axiosCustom.get(`${INVENTORY_URL}?${searchParams}`);
            response?.data?.results?.map((item, i) => {
                item.order_quantity = 1
                data.push({ value: item, label: `(#${item?.id}) ${item?.name} - Ksh. ${item.price}` })
            })
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setLoadingInventory(false)
        }
        return data
    }

    const getDeliveryCost = () => {
        if (deliveryType == DELIVERY_EXPRESS) {
            return data?.deliveryFeeExpress
        } else {
            return data?.deliveryFeeStandard
        }
    }

    const quantityIncrease = async (index) => {
        const newQuantity = parseInt(orderItems[index]?.order_quantity) + 1;
        orderItems[index].order_quantity = newQuantity > 0 ? newQuantity : 1
        setOrderItems((prev) => [...orderItems])
    }


    const quantitySet = async (index, value) => {
        orderItems[index].order_quantity = value > 0 ? value : 1
        setOrderItems((prev) => [...orderItems])
    }

    const quantityDecrease = async (index) => {
        const newQuantity = parseInt(orderItems[index]?.order_quantity) - 1;
        orderItems[index].order_quantity = newQuantity > 0 ? newQuantity : 1
        setOrderItems((prev) => [...orderItems])
    }

    const itemAdd = async (item) => {
        setOrderItems((prev) => [...prev, item])
        setSelectedItem(null)
    }

    const addressesSelected = async (item) => {
        console.log(item)
        setLocation(item)
        const merchants = []
        orderItems.map((val) => {
            merchants.push(val?.passport?.id)
        })

        const destination = { lat: item?.lat, lng: item?.lng }
        const params = { merchants, destination }

        try {
            const response = await axiosCustom.post(ESTIMATES_URL, JSON.stringify(params));
            setData(response?.data)
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setLoadingInventory(false)
        }

    }

    const calculateTotalQuantity = async () => {
        let tempTotalQuantity = 0;
        orderItems.map(item => {
            tempTotalQuantity += parseInt(item?.order_quantity)
        })
        setTotalQuantity(tempTotalQuantity)
    }


    const calculateTotalAmount = async () => {
        let tempTotalAmount = 0;
        orderItems.map(item => {
            tempTotalAmount += parseInt(item?.order_quantity) * parseInt(item?.price)
        })
        setTotalAmount(tempTotalAmount)
    }


    const itemRemove = async (index) => {
        orderItems.splice(index, 1);
        setOrderItems((prev) => [...orderItems])

    }


    const submitData = async (e) => {
        e.preventDefault()
        setProcessing(true)
        const parts = []
        orderItems.map(item => {
            parts.push({id: item.id, quantity: item.order_quantity })
        })
        try {
            const params = {
                parts : parts, 
                delivery_details : deliveryWindow, 
                basket: "1", 
                deliveryCost: getDeliveryCost(),
                shippingAddress: {town: location.placeName, is_default: false, lat : location.lat, lng: location.lng, category: "home",address:location.placeName},
                AllDeliveryCost: data,
                deliveryType: "Express",
                deliveryWindow:deliveryWindow,
                paymentMethod:{"id":2,"name":"Pay on Delivery","code":"101MD","cellulant_code":"SAFKE","type":"mobile","provider":"MPESA","islog":true,"description":"101MD","target":null,"is_active":true,"rank":2,"logo":null,"date_created":"2023-08-30T20:18:47.929412+03:00"},
                isLoading:false,
                isSubmitted:false,
                promoCode:"",
                referralCode:"",
                deviceToken:"",
                allPaymentMethods:[{"id":1,"name":"Mpesa Express","code":"100MX","cellulant_code":"SAFKE","type":"mobile","provider":"MPESA","islog":true,"description":"100MX","target":null,"is_active":true,"rank":1,"logo":null,"date_created":"2023-08-30T20:18:23.738871+03:00"},{"id":2,"name":"Pay on Delivery","code":"101MD","cellulant_code":"SAFKE","type":"mobile","provider":"MPESA","islog":true,"description":"101MD","target":null,"is_active":true,"rank":2,"logo":null,"date_created":"2023-08-30T20:18:47.929412+03:00"},{"id":3,"name":"Card","code":"103C","cellulant_code":"CARD","type":"card","provider":"MPESA","islog":true,"description":"103C","target":null,"is_active":true,"rank":3,"logo":null,"date_created":"2023-08-30T20:19:40.030748+03:00"}],
                isCardPayment:false,
                cardDetails:{}
            }
            const response = await axiosCustom.post(`${CHECKOUT_URL}${customerID}/`, JSON.stringify(params));
            handleReload();
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        calculateTotalAmount()
        calculateTotalQuantity()
    }, [orderItems])

    useEffect(() => {
        getPaymentMethods()
    }, [])



    return (
        <>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div className='min-h-h-screen flex justify-center items-center overflow-x-hidden overflow-y-auto py-10 fixed inset-0 z-[100] outline-none focus:outline-none'>
                        <div class="relative p-4 w-full max-w-2xl max-h-full">
                            <div class="relative bg-white rounded-lg shadow ">
                                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                                    <h3 class="text-xl font-semibold text-gray-900 ">
                                        New Customer Order
                                    </h3>
                                    <button
                                        onClick={handleModalClose}
                                        type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white">
                                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div class="p-4 md:p-5">
                                    <form className="flex flex-col w-full gap-4 text-[14px]" onSubmit={submitData}>
                                        <div className="flex flex-col gap-4 w-full">
                                            <div className='flex gap-4 md:flex-row flex-col'>
                                                <div className='w-full flex flex-[2] flex-col'>

                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="make" className="text-[14px] text-black font-semibold">
                                                                Search a Part
                                                            </label>
                                                            <AsyncSelect
                                                                value={selectedItem}
                                                                className='w-full outline-none'
                                                                loadOptions={getInventory}
                                                                placeholder="Search"
                                                                isLoading={loadingInventory}
                                                                name="tagged_vehicles"
                                                                onChange={e => itemAdd(e.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {!orderItems || orderItems?.length < 1 ?
                                                <section class="bg-white ">
                                                    <div class="py-8 mx-auto px-4">
                                                        <span className='text-sm'>Cart is empty</span>
                                                    </div>
                                                </section>
                                                :
                                                <div class="relative overflow-x-auto">

                                                    <div className="flex flex-col px-2 gap-1 w-full py-1 justify-between">

                                                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
                                                            <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
                                                                <tr>
                                                                    <th scope="col" class="px-2 py-3">
                                                                        <span class="sr-only">Image</span>
                                                                    </th>
                                                                    <th scope="col" class="px-2 py-3">
                                                                        Product
                                                                    </th>
                                                                    <th scope="col" class="px-6 py-3">
                                                                        Price/Qty
                                                                    </th>
                                                                    <th scope="col" class="px-1 py-3">

                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    orderItems?.map((item, index) => (


                                                                        <tr class="bg-white border-b   hover:bg-gray-50 :bg-gray-600">
                                                                            <td class="p-2">
                                                                                <div className='flex flex-col'>

                                                                                    {
                                                                                        item?.thumbnail_urls && item?.thumbnail_urls?.length > 0 ?
                                                                                            <img src={item?.thumbnail_urls[0]} class="border border-slate-200 object-cover rounded-md h-16 w-16" />
                                                                                            :
                                                                                            <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                                                                                                <p className='font-satoshi text-[#F77B55] text-[14px] text-center'>No images</p>
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td class="p-2">
                                                                                <p className='font-semibold'>{item?.name}</p>
                                                                            </td>
                                                                            <td class="px-6 py-4">
                                                                                <div className='flex flex-col'>
                                                                                    <p className='mb-2'>Ksh. {formatNumber(item?.price, 0, 0)}</p>

                                                                                    <div class="flex items-center">
                                                                                        <button
                                                                                            onClick={(e) => quantityDecrease(index)}
                                                                                            class="inline-flex items-center justify-center p-1 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200    :bg-gray-700 :border-gray-600 :ring-gray-700" type="button">
                                                                                            <span class="sr-only">Quantity button</span>
                                                                                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                                                                                            </svg>
                                                                                        </button>
                                                                                        <div class="ms-3">
                                                                                            <input type="number" class="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1     :ring-blue-500 :border-blue-500" placeholder="1" value={item?.order_quantity} required onChange={(e) => quantitySet(index, e.target.value)} />
                                                                                        </div>
                                                                                        <button
                                                                                            onClick={(e) => quantityIncrease(index)}
                                                                                            class="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200    :bg-gray-700 :border-gray-600 :ring-gray-700" type="button">
                                                                                            <span class="sr-only">Quantity button</span>
                                                                                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                                                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                                                                            </svg>
                                                                                        </button>
                                                                                    </div>
                                                                                    <p className='font-semibold mt-2'>Ksh. {formatNumber(item?.price * item?.order_quantity, 0, 0)}</p>

                                                                                </div>
                                                                            </td>
                                                                            <td class="px-1 py-4">
                                                                                <p onClick={(e) => itemRemove(index)} class="font-sm text-red-600  hover:underline">Remove</p>
                                                                            </td>
                                                                        </tr>

                                                                    ))
                                                                }
                                                            </tbody>

                                                            <tfoot>
                                                                <tr class="font-semibold text-gray-900 ">
                                                                    <th scope="row" class="px-6 py-3 text-base">Total</th>
                                                                    <td class="px-2 py-3"><p>{totalQuantity} Items</p></td>
                                                                    <td class="px-6 py-3">
                                                                        <p className='font-semibold mt-2'>Ksh. {formatNumber(totalAmount, 0, 0)}</p>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            }

                                            {orderItems && orderItems?.length > 0 &&

                                                <>

                                                    <div className='flex gap-4 md:flex-row flex-col'>
                                                        <div className='w-full flex flex-[2] flex-col'>

                                                            <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                                <div className="flex flex-col py-2 w-full">
                                                                    <label htmlFor="make" className="text-[14px] text-black font-semibold">
                                                                        Delivery Address
                                                                    </label>
                                                                    <GoogleMapInput
                                                                        setLocation={(loc) => addressesSelected(loc)}
                                                                        classCss="mt-1 block w-full p-2 outline-none rounded-md border placeholder:text-[12px]" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {deliveryType &&
                                                        <div class="w-full flex flex-row items-center mt-1 mb-1 gap-1">
                                                            <div class="mb-2 text-[11px] font-semibold drop-shadow-sm">Ksh. {formatNumber(getDeliveryCost(), 0, 0)}</div>
                                                            <span class="mb-2 text-[11px] font-semibold drop-shadow-sm">Delivery fee applied</span>
                                                        </div>
                                                    }
                                                    <div className='w-full'>
                                                        <span className='mb-2 text-[13px] font-[550]'>Delivery options</span>
                                                        <div className='flex flex-col main-bg-8 rounded-md py-4 gap-4'>

                                                            {data?.deliveryOptions?.express &&
                                                                <div
                                                                    className={`flex items-center gap-2 cursor-pointer px-2 main-bg-8`}
                                                                    onClick={() => {
                                                                        setDeliveryType(DELIVERY_EXPRESS)
                                                                        setDeliveryWindow({
                                                                            "delivery_window_from": data?.deliveryOptions?.express?.delivery_window_from,
                                                                            "delivery_window_to": data?.deliveryOptions?.express?.delivery_window_to
                                                                        })
                                                                    }}
                                                                >
                                                                    <input
                                                                        type='radio'
                                                                        disabled={!data?.deliveryOptions?.express}
                                                                        checked={DELIVERY_EXPRESS == deliveryType}
                                                                    />
                                                                    <span>
                                                                        <span className={`text-[14px] ${DELIVERY_EXPRESS == deliveryType && 'font-[600]'}`}>Express delivery</span>
                                                                        <p className='text-sm text-gray-700 text-[11px] font-medium'>Instant pickup and delivery.</p>
                                                                        <p className='text-[11px] italic animate-in fade-in zoom-in-90 duration-500'>{data?.deliveryOptions?.express?.label}</p>
                                                                    </span>
                                                                </div>
                                                            }
                                                            {data?.deliveryOptions?.standard &&

                                                                <div
                                                                    className={`w-full flex flex-col justify-start items-start px-2 rounded main-bg-8`}
                                                                    onClick={() => setDeliveryType(DELIVERY_STANDARD)}
                                                                >
                                                                    <div
                                                                        className='flex gap-2 cursor-pointer items-center'>
                                                                        <input
                                                                            type='radio'
                                                                            disabled={!data?.deliveryOptions?.standard}
                                                                            checked={DELIVERY_STANDARD == deliveryType}
                                                                        />
                                                                        <span>
                                                                            <span className={`text-[14px] ${DELIVERY_STANDARD == deliveryType && 'font-[550]'}`}>Standard delivery</span>
                                                                            <p className='text-sm text-gray-700 text-[11px] font-medium'>
                                                                                Select your preferred time slot
                                                                            </p>
                                                                        </span>
                                                                    </div>
                                                                    {
                                                                        DELIVERY_STANDARD == deliveryType && data?.deliveryOptions?.standard &&
                                                                        data?.deliveryOptions?.standard?.length && data?.deliveryOptions?.standard?.map((option, i) => (
                                                                            <div
                                                                                onClick={() => setDeliveryWindow(option)}
                                                                                key={i} className='flex items-center gap-2 cursor-pointer ml-8 animate-in fade-in zoom-in-90 duration-500'>
                                                                                <input
                                                                                    checked={option?.label === deliveryWindow?.label}
                                                                                    type="checkbox"
                                                                                    className='text-xs accent-[#BF3054]'
                                                                                />
                                                                                <p className='text-[11px] italic'>{option?.label}</p>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            }
                                                            {data?.deliveryOptions?.nextday &&

                                                                <div
                                                                    className={`flex items-center gap-2 cursor-pointer px-2 main-bg-8`}
                                                                    onClick={() => {
                                                                        setDeliveryType(DELIVERY_NEXT_DAY)
                                                                        setDeliveryWindow({
                                                                            "delivery_window_from": data?.deliveryOptions?.nextday?.delivery_window_from,
                                                                            "delivery_window_to": data?.deliveryOptions?.nextday?.delivery_window_to
                                                                        })
                                                                    }}
                                                                >
                                                                    <input
                                                                        type='radio'

                                                                        disabled={!data?.deliveryOptions?.nextday}
                                                                        checked={DELIVERY_NEXT_DAY == deliveryType}
                                                                    />
                                                                    <p>
                                                                        <span className={`text-[14px] ${DELIVERY_NEXT_DAY == deliveryType && 'font-[550]'}`}>Next day (Country Wide) delivery</span>
                                                                        <p className='text-xs text-gray-700 text-[11px]'>
                                                                            250 Ksh for first 5kg. 30ksh for any
                                                                            extra kg
                                                                        </p>
                                                                        <p className='text-[11px] italic animate-in fade-in zoom-in-90 duration-500'>
                                                                            {data?.deliveryOptions?.nextday?.label}
                                                                        </p>
                                                                    </p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </>

                                            }

                                            <div className="flex py-3 justify-end">
                                                <div className="flex flex-row-reverse gap-4">
                                                    <button type='submit' className='bg-[#030A1A] text-[16px] text-white border border-[#030A1A] px-3 py-1 rounded-md'>Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div >
                    <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-70  fixed inset-0 z-50' />
                </section >
            }
        </>
    )
}
export default AddOrderModal