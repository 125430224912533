import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { API_PREFIX, LS_ACCOUNT_ID, LS_ACCESS_TOKEN } from "../constants";
import { getLocalStoreItem } from "../utils";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { DownloadIcon, ChevronDownIcon } from "../components/Icons";
import Orders from "../components/Orders";
import UsersMarketPlace from "../components/UsersMarketPlace";

const TAB_ORDERS = 1;
const TAB_USERS = 2;


function MarketPlacePage() {
  let currentUrlParams = new URLSearchParams(window.location.search);
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams);

  // authenticate export endpoint
  const authUserId = getLocalStoreItem(LS_ACCOUNT_ID);
  const token = getLocalStoreItem(LS_ACCESS_TOKEN);
  const key =
    token.substring(0, 4) + token.substring(token.length - 4, token.length);

  console.log("currentTabparam", currentUrlParams.get("tab"));
  const [activeTab, setActiveTab] = useState(
    currentUrlParams.get("tab") === "orders"
      ? TAB_ORDERS
      : currentUrlParams.get("tab") === "users"
      ? TAB_USERS
      : TAB_ORDERS
  );

  const switchOrderTab = () => {
    currentUrlParams.set("tab", "orders");
    currentUrlParams.set("page", 1);

    currentUrlParams.delete("usertype");

    setUrlParams(currentUrlParams);
    setActiveTab(TAB_ORDERS);
  };

  const switchUsersTab = () => {
    currentUrlParams.set("tab", "users");
    currentUrlParams.set("page", 1);

    setUrlParams(currentUrlParams);
    setActiveTab(TAB_USERS);
  };
  return (
    <>
      <div className="flex flex-col h-full overflow-y-scroll hide-scrollbar gap-2 w-full px-4 md:px-20 pt-20">
        <div className="flex flex-col ">
          <div className="flex flex-row gap-3 py-3 justify-between">
            <div className="flex flex-row gap-3">
              <div onClick={switchOrderTab} className="cursor-pointer">
                <p
                  className={` ${
                    activeTab === TAB_ORDERS
                      ? "text-[#D95762] underline decoration-4 underline-offset-[16px] md:underline-offset-[14px]  font-semibold"
                      : "text-[#666C7C]"
                  } font-semibold font-satoshi text-[18px] flex flex-row gap-1 `}
                >
                  Orders
                </p>
              </div>
              <div onClick={switchUsersTab} className="cursor-pointer">
                <p
                  className={` ${
                    activeTab === TAB_USERS
                      ? "text-[#D95762] underline decoration-4 underline-offset-[16px] md:underline-offset-[14px]  font-semibold"
                      : "text-[#666C7C]"
                  } font-semibold font-satoshi text-[18px] `}
                >
                  Users
                </p>
              </div>
            </div>

            <div>
              {activeTab === TAB_ORDERS && (
                <div className="flex flex-row">
                  <div className="w-52 text-right">
                    <Menu>
                      <MenuButton className="font-satoshi bg-[#E5E6E9] text-[#808491] inline-flex items-center gap-2 hover:bg-[#cdced3] rounded-md py-1.5 px-3 text-sm/6 shadow-inner shadow-white/10 focus:outline-none  data-[focus]:outline-1 data-[focus]:outline-white">
                        Options
                        <ChevronDownIcon />
                      </MenuButton>

                      <MenuItems
                        transition
                        anchor="bottom end"
                        className="shadow-lg w-64 origin-top-right rounded-xl border border-white/5 bg-white p-1 text-sm/6 text-gray-800 transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                      >
                        <MenuItem>
                          <a
                            href={`${API_PREFIX}/oms/orders/export/?id=${authUserId}&key=${key}`}
                          >
                            <button className="text-[#808491] font-satoshi group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 hover:bg-[#cdced3]">
                              <DownloadIcon />
                              Export orders
                            </button>
                          </a>
                        </MenuItem>
                        <MenuItem>
                          <a
                            href={`${API_PREFIX}/oms/orderlines/export/?id=${authUserId}&key=${key}`}
                          >
                            <button className="text-[#808491] font-satoshi group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10 hover:bg-[#cdced3]">
                              <DownloadIcon />
                              Export order items
                            </button>
                          </a>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="border-b border-[#E5E6E9] w-full "></div>
          {activeTab === TAB_ORDERS && <Orders />}
          {activeTab === TAB_USERS && <UsersMarketPlace />}

        </div>
      </div>
    </>
  );
}
export default MarketPlacePage;
