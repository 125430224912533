import React, { useEffect, useState, useCallback } from 'react'
import back from '../assets/ic_back.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { makeAuthenticatedRequest } from '../utils'
import Avatar from 'react-avatar'
import { PERMISSION_CHANGE_BRAND, PERMISSION_CHANGE_CARPART, PERMISSION_CHANGE_CONSUMER, PERMISSION_CHANGE_DSF, PERMISSION_CHANGE_JOB, PERMISSION_CHANGE_MECHANIC, PERMISSION_CHANGE_MERCHANT, PERMISSION_CHANGE_ORDER, PERMISSION_CHANGE_PART_REQUEST, PERMISSION_CHANGE_STAFF, PERMISSION_CHANGE_VEHICLE, PERMISSION_VIEW_BRAND, PERMISSION_VIEW_CARPART, PERMISSION_VIEW_CONSUMER, PERMISSION_VIEW_DSF, PERMISSION_VIEW_JOB, PERMISSION_VIEW_MECHANIC, PERMISSION_VIEW_MERCHANT, PERMISSION_VIEW_ORDER, PERMISSION_VIEW_PART_REQUEST, PERMISSION_VIEW_STAFF, PERMISSION_VIEW_VEHICLE } from '../constants'


function StaffDetailsPage() {
  const navigate = useNavigate()
  const { staffID } = useParams()
  const [data, setData] = useState()
  const [lastName, setLastName] = useState()
  const [firstName, setFirstName] = useState()
  const [testBoolean, setTestBoolean] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [newPermissions, setNewPermissions] = useState("")
  const [prevPermissions, setPrevPermissions] = useState([])

  const modifyPermissions = (permission) => {
    setTestBoolean(true)
    const tempPermission = JSON.parse(newPermissions);
    if (tempPermission.includes(permission)) {
      const index = tempPermission.indexOf(permission);
      tempPermission.splice(index, 1);
      //delete tempPermission[index]
    } else {
      tempPermission.push(permission)
    }
    setNewPermissions(JSON.stringify(tempPermission))
    console.log(tempPermission)
    console.log(newPermissions)
    console.log(newPermissions.includes('view_merchant'))
    setTestBoolean(false)
  }

  const fetchData = () => {
    setProcessing(true)
    makeAuthenticatedRequest(`/oms/staff/${staffID}/`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setData(data)
        setLastName(data.last_name)
        setFirstName(data.first_name)
        setPrevPermissions(data.permissions)
        setNewPermissions(JSON.stringify(data.permissions))
        setProcessing(false)
      });
  }

  const postData = () => {
    setProcessing(true)
    const params = {}
    if(firstName ) params.first_name = firstName
    if(lastName) params.last_name = lastName
    if(newPermissions) params.permissions = JSON.parse(newPermissions) 
    makeAuthenticatedRequest(`/oms/staff/${staffID}/`, "POST", JSON.stringify(params))
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setData(data)
        setLastName(data.last_name)
        setFirstName(data.first_name)
        setPrevPermissions(data.permissions)
        setNewPermissions(JSON.stringify(data.permissions))
        setProcessing(false)
      });
  }


  useEffect(() => {
    console.log("changed")
  }, [newPermissions])


  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }

      {data &&


        <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">

          <div className="flex flex-row">
            <div onClick={() => navigate(-1)}
              className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] text-[14px] rounded-lg">
              <img src={back} alt="" />
              Back
            </div>
          </div>

          <div className="flex flex-col w-full border rounded-md gap-4">
          <p className="font-satoshi text-left text-[#030A1A] text-[20px] font-semibold px-2">User Details</p>

          <div className="border-b w-full"></div>
            <div className='flex flex-col-reverse md:flex-row p-2 justify-between'>
              
              <div className='flex flex-col mt-2 gap-2'>
                <div className="grid flex-col md:grid-cols-2 ">
                  <div className="col-span-1">
                  <p className='font-satoshi text-[18px]'>First name</p>
                  </div>
                  <div className="col-span-1">
                  {/* <p className='font-satoshi text-[16px]'>{data.first_name}</p> */}

                  <input
                  type="text"
                  placeholder='First name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full p-1.5 border border-gray-100 rounded-md font-satoshi bg-gray-100 outline-none appearance-none"
                />
                  </div>
                </div>

                <div className="grid flex-col md:grid-cols-2">
                  <div className="col-span-1">
                  <p className='font-satoshi text-[18px]'>Last name</p>
                  </div>
                  <div className="col-span-1">
                  {/* <p className='font-satoshi text-[16px]'>{data.last_name}</p> */}
                  <input
                  type="text"
                  placeholder='Last name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full p-1.5 border border-gray-100 rounded-md font-satoshi bg-gray-100 outline-none appearance-none"
                />
                  </div>
                </div>
                <div className="grid flex-col md:grid-cols-2">
                  <div className="col-span-1">
                  <p className='font-satoshi text-[18px]'>Email</p>
                  </div>
                  <div className="col-span-1">
                  <p className='font-satoshi text-[16px]'>{data.email}</p>
                  </div>
                </div>
              </div>
              <Avatar name={`${data.first_name} ${data.last_name}`} size="60" className="mr-2 rounded-full shadow" />
            </div>
          </div>

          <div className="flex flex-row w-full gap-4">
            
          </div>


     
          {/* <div className="flex md:grid flex-col md:grid-cols-2 gap-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input
                  type="text"
                  placeholder='First name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                />
              </label>
            </div>

            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input
                  type="text"
                  placeholder='Last name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                />
              </label>
            </div>

          </div> */}


          <div className="flex flex-row w-full gap-4">
            <h3 className="font-satoshi text-left text-[#030A1A] text-[20px] font-semibold">Permissions</h3>
          </div>


          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] p-2 font-semibold'>Merchants</p>


            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col  p-2 md:grid-cols-3 gap-3">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_MERCHANT} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_MERCHANT)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_MERCHANT} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_MERCHANT)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>


          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>Mechanics</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 p-2 gap-3">
            <div className="flex flex-col px-2 border rounded-md shadow-sm py-2">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_MECHANIC} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_MECHANIC)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 border rounded-md shadow-sm py-2">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_MECHANIC} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_MECHANIC)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>

          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>Consumers</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 gap-3 p-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_CONSUMER} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_CONSUMER)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_CONSUMER} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_CONSUMER)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>
          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>Inventory</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 gap-3 p-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_CARPART} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_CARPART)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_CARPART} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_CARPART)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>

          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>Orders</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 gap-3 p-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_ORDER} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_ORDER)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_ORDER} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_ORDER)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>
          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>DSF</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 gap-3 p-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_DSF} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_DSF)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_DSF} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_DSF)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>

          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>Staff</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 gap-3 p-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_STAFF} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_STAFF)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_STAFF} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_STAFF)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>

          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>Brands</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 gap-3 p-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_BRAND} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_BRAND)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_BRAND} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_BRAND)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>

          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>Vehicles</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 gap-3 p-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_VEHICLE} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_VEHICLE)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_VEHICLE} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_VEHICLE)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>

          <div className="flex flex-col w-full border rounded-md">
          <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold p-2'>Jobs</p>
            <div className="border-b w-full"></div>
          <div className="flex md:grid flex-col md:grid-cols-3 gap-3 p-2">
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_VIEW_JOB} checked={JSON.parse(newPermissions).includes(PERMISSION_VIEW_JOB)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can view</span>
              </label>
            </div>
            <div className="flex flex-col px-2 py-2 border rounded-md shadow-sm">
              <label className=" flex flex-row w-full">
                <input type="checkbox" className='mr-2' value={PERMISSION_CHANGE_JOB} checked={JSON.parse(newPermissions).includes(PERMISSION_CHANGE_JOB)} onChange={(e) => modifyPermissions(e.target.value)} />
                <span className='text-[#4D5364] text-[18px] font-satoshi'>Can change</span>
              </label>
            </div>
          </div>
          </div>


          <div className="flex flex-row gap-2">
            <button
              onClick={postData}
              className='px-4 py-2 font-satoshi text-center text-[12px] md:text-[16px] bg-black text-white rounded-md '>Submit</button>
          </div>

        </div>
      }
    </>
  )
}

export default StaffDetailsPage