import React, { useEffect } from 'react'
import back from '../assets/ic_back.svg'
import copy from '../assets/copy.svg'


import {  Link, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { makeAuthenticatedRequest } from '../utils'
import Rating from '../components/Rating'
import Avatar from 'react-avatar'
import { ENDPOINT_USERS } from '../routes'

function JobDetailsPage() {
    const navigate = useNavigate()
    const { jobID } = useParams()
    const [jobData, setJobData] = useState()

    const fetchJob = () => {
      makeAuthenticatedRequest(`/oms/job/${jobID}`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setJobData(data)
        });
    }
    

    useEffect(() => {
        fetchJob()
      }, [])

    //   console.log('jobID', jobID)
      console.log('jobData', jobData)

  return (
    <>
    { jobData ? 
    

    <>
    <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20">
    <div className="flex flex-row">
        <div onClick={() => navigate(-1)}
        className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] text-[14px] rounded-lg">
        <img src={back} alt="" />
        Back
        </div>
    </div>
    <div className="flex flex-row gap-6">
        <p className='font-satoshi text-[#030A1A] text-[16px] md:text-[18px] font-semibold'>Job #SPP-{jobData.id}</p>
        
        <img src={copy} alt="" className='hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`SPP-${jobData.id}`) }} />
    </div>
    <div className="flex flex-row gap-3">
        <p className='font-satoshi text-[#030A1A] text-[16px] md:text-[16px]'>{jobData?.payments!== null ? jobData?.payments.amount/jobData?.rate_per_hour + 'hours': null }</p>
        <p className='font-satoshi text-[#030A1A] text-[16px] md:text-[16px] font-semibold'> {jobData?.payments!== null ? ('ksh' + ' ' + jobData?.payments?.amount ): null}</p>
        {/* <p className='font-satoshi text-[#808491] text-[16px] md:text-[16px] '>4 Apr 2022</p> */}
        
    </div>
    <div className="flex flex-col md:flex-row gap-4 w-full ">
        {/* <Link 
        to={`${ENDPOINT_USERS}/${jobData.consumer.id}`} 
        className="flex flex-col w-full md:w-6/12 border rounded-lg gap-2 px-2 pt-1 pb-2"> */}
        <div className="flex flex-col w-full md:w-6/12 border rounded-lg gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Customer details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4">
            <div className="">
            <Avatar name={jobData.consumer.name} size={50} round={true} />

                {/* <img src="" className='rounded-full w-20 h-20 object-cover' alt="" /> */}
            </div>
            <div className="flex-1 flex flex-col gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{jobData.consumer.name? jobData.consumer.name :jobData.consumer.first_name}</p>
        <div className="flex flex-row gap-1">
            <Rating 
            avarageRating={jobData.rating_consumer}
                />
        

        {/* <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] '>0</p> */}

        </div>
        <div className="flex flex-row gap-1">
            <div className="border border-red-400 rounded-full p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-red-400 w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>

            </div>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>+{jobData.consumer.phone_number}</p>

        </div>

                
            </div>

        </div>

        
        </div>
        {/* </Link> */}
        <Link to={`${ENDPOINT_USERS}/mechanic/${jobData?.mechanic.id}`} className="flex flex-col w-full md:w-6/12 border rounded-lg gap-2 px-2 pt-1 pb-2">

        <div className="flex flex-col w-full  gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Mechanic details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4">
            <div className="">
                <Avatar name={jobData.mechanic.mechanic_name} size={50} round={true} />
                {/* <img src="" className='rounded-full w-20 h-20 object-cover' alt="" /> */}
            </div>
            <div className="flex-1 flex flex-col gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{jobData.mechanic.mechanic_name}</p>
        <div className="flex flex-row gap-1">
        <Rating 
            avarageRating={jobData.rating_mechanic}
            numberOfRatings={jobData.rating_mechanic}
                />
                

        {/* <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] '>4.1</p> */}

        </div>
        <div className="flex flex-row gap-1">
            <div className="border border-red-400 rounded-full p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-red-400 w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>

            </div>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>+{jobData.mechanic.phone_number}</p>

        </div>

                
            </div>

        </div>

        
        </div>
        </Link>
    </div>
    <div className="flex flex-col w-full border rounded-lg gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Job details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-col md:flex-row w-full gap-4">
            <div className="w-full md:w-7/12 flex flex-col gap-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Issue</p>
                <div className="bg-gray-200 rounded-md px-2 py-1 w-full">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{jobData.service_needed}</p>

                </div>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Details</p>
                <div className="bg-gray-200 rounded-md px-2 w-full">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{jobData.description? jobData.description: '-'}</p>
                </div>
            </div>
            <div className="flex-1 flex flex-col gap-1 w-full md:w-/5/12">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Customer vehicle</p>
        <div className="flex flex-row gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{jobData.car.make}-{jobData.car.model}</p>
        <img src="/cdn/images/vehicle.svg" className='rounded w-30 h-12 pb-4 object-cover' alt="" />

        </div>
        {/* <p className='font-satoshi text-[#808491] px-2 text-[16px] md:text-[16px] '>X123456MN0044789</p> */}

        <div className="flex flex-row gap-1">
            <div className="border border-red-400 rounded-full p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-red-400 w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>

            </div>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>+{jobData.consumer.phone_number}</p>

        </div>

                
            </div>

        </div>

        
        </div>

        <div className="flex flex-col w-full border rounded-lg gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Payment details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-col md:flex-row-reverse w-full gap-4">
        <div className="flex flex-col gap-3">
                <p className='font-satoshi text-[#666C7C] px-2 text-[16px] md:text-[16px] '>TOTAL</p>
                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{jobData?.payments!== null ?  ('ksh' + ' ' + jobData?.payments?.amount) : null}</p>
            </div>
            {/* <div className="flex flex-col gap-3">
                <p className='font-satoshi text-[#E89AA0] px-2 text-[16px] md:text-[16px] '>20% PROMO</p>
                <p className='font-satoshi text-[#666C7C] px-2 text-[16px] md:text-[16px] '>– 640</p>
            </div>
            <div className="flex flex-col gap-3">
                <p className='font-satoshi text-[#666C7C] px-2 text-[16px] md:text-[16px] '>SUBTOTAL</p>
                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>KSh 3200</p>
            </div> */}
            <div className="flex flex-col gap-3">
                <p className='font-satoshi text-[#666C7C] px-2 text-[16px] md:text-[16px] '>COMMISSION</p>
                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>
                    {jobData?.payments?.amount? 
                    ('KSh' + ' ' + (jobData?.payments?.amount)*10/100)
                    :
                    null
                }
                    </p>
            </div>
            <div className="flex flex-col gap-3">
                <p className='font-satoshi text-[#666C7C] px-2 text-[16px] md:text-[16px] '>MECHANIC PAY</p>
                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>
                    {jobData?.payments?.amount? 
                ('KSh' + ' ' + (jobData?.payments?.amount)*90/100)
                :
                null
            }</p>
            </div>


        </div>
        <div className="border-b border-b-gray-200"></div>
        {jobData?.payments && <div className="flex flex-col md:flex-row gap-3 w-full">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>
        Paid via MPESA
        </p>
        <p className='font-satoshi text-[#666C7C] px-2 text-[16px] md:text-[16px] '>07******78</p>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Transaction ID:</p>
        <p className='font-satoshi text-[#666C7C] px-2 text-[16px] md:text-[16px] '>QJ34HY87B67</p>




        </div>}


        
        </div>

       {jobData?.review_consumer && <div className="flex flex-col w-full border rounded-lg gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Review</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-col md:flex-row w-full gap-4">
            <div className="">
                <img src="" className='rounded-full w-20 h-20 object-cover' alt="" />
            </div>
            <div className="flex-1 flex flex-col gap-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Amelia Njeri</p>
        <div className="flex-1 flex flex-col gap-1 bg-[#F5F7FE] rounded-md">

        
        <div className="flex flex-row gap-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        

        <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] '>4.1</p>
        <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] '>Excellent</p>


        </div>
        <div className="flex flex-row gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>The mechanic was very professional.</p>
        </div>
        </div>

                
            </div>

        </div>

        
        </div>}

        {jobData?.report_consumer && <div className="flex flex-col w-full border rounded-lg gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Complaint</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-col md:flex-row w-full gap-4">
            <div className="">
                <img src="" className='rounded-full w-20 h-20 object-cover' alt="" />
            </div>
            <div className="flex-1 flex flex-col gap-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Amelia Njeri</p>
        <div className="flex-1 flex flex-col gap-1 bg-[#FBEEEF] rounded-md">

        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>
        The clutch was fine at the beginning but now doesn’t seem to be working. The car stalled again.
        </p>
        </div>

                
            </div>

        </div>

        
        </div>}
    </div>

    </>:
    <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
    <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
    <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
  </div>
    }
    </>
  )
}

export default JobDetailsPage