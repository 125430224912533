import React from 'react'
import CategoryAdd  from '../components/cms/Category/CategoryAdd'

function CategoriesAddPage() {
  return (
    <div>
        <CategoryAdd/>
    </div>
  )
}

export default CategoriesAddPage