import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import back from '../assets/ic_back.svg'
import copy from '../assets/copy.svg'
import Avatar from 'react-avatar'
import { ENDPOINT_SERVICE_CENTER } from '../routes'
import ModalLayout from '../components/shared/ModalLayout'
import { makeAuthenticatedRequest } from '../utils'
import Moment from 'react-moment'
import { Pagination } from '../components/Pagination'
import { toast } from 'react-toastify'

function ServiceCenterDetailsPage() {
    const { serviceCenterID } = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [staffData, setStaffData] = useState(null)
    const [addEmployee, setAddEmployee] = useState(false)
    const [editEmployee, setEditEmployee] = useState(false)
    const [editData, setEditData] = useState(null)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [id, setId] = useState('')
    const [employeeType, setEmployeeType] = useState('')
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [perPage, setPerPage] = useState(16)
    const [totalItems, setTotalItems] = useState(0)

    const handleOpenEditStaff = (data) => {
      setName(`${data?.staff?.first_name} ${data?.staff?.last_name}`)
      setEmail(data?.staff?.email)
      setPhone(data?.phone_number)
      setEmployeeType(data?.roles[0]?.name)
      setEditEmployee(true)
      setEditData(data)
    }


    const submitSearch = () => {
      setPage(1)
      fetchData()
    }

    const createServiceCenter = async (e) => {
      e.preventDefault()
      setLoading(true)
      const payload = {
        name,
        email,
        phone_number:phone,
        service_center_id: parseInt(serviceCenterID),
        user_type:employeeType
      }
      try {
        const res = await makeAuthenticatedRequest(`/oms/service_centers/staff/create/`, "POST", JSON.stringify(payload))
        if (!res.ok) {
          setLoading(false)
          handleCloseCreateEmployeeModal()
          throw new Error('staff creation failed.')
        }
        const data = await res.json();
        fetchServiceCenterStaff(1)
        setLoading(false)
        setName('')
        setEmail('')
        setPhone('')
        setName('')
        setEmployeeType('')
        setAddEmployee(false)
        toast.success('staff created successfully')
        handleCloseCreateEmployeeModal()
      } catch (error) {
        setLoading(false)
        toast.error('staff could not be created.')
      }
  }    

  const fetchServiceCenterStaff = (page) => {
    setProcessing(true)
    const params = {
      "s": search,
      "page": page,
      "per_page": perPage,
  }
  const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/service_centers/${serviceCenterID}/staff/?${searchParams}`)
        .then((response) => response.json())
        .then((data) => {
            // console.log("staff data",data)
            setStaffData(data)
            setTotalItems(data?.count)
            setProcessing(false)
        })
        .catch((err) => {
            setProcessing(false)

            console.error('err', err)
        })
}


const pageChanged = (pageNumber) => {
  setPage(pageNumber)
  fetchServiceCenterStaff(pageNumber)

}
  const fetchData = () => {
      setProcessing(true)
      makeAuthenticatedRequest(`/oms/service_centers/${serviceCenterID}`)
          .then((response) => response.json())
          .then((data) => {
              console.log(data)
              setData(data)
              setProcessing(false)
          })
          .catch((err) => {
              setProcessing(false)

              console.error('err', err)
          })
  }

  useEffect(() => {
    fetchData()
    fetchServiceCenterStaff(1)
  },[serviceCenterID])

  const handleDeleteStaff = async(id) => {
    setLoading(true)
    const payload = {
      deleted:true
    }

    try {
      const res = await  makeAuthenticatedRequest(`/oms/service_center/staff/${id}/`, "POST", JSON.stringify(payload))
      if (!res.ok) {
        setLoading(false)
        handleCloseEditEmployeeModal()
        throw new Error('staff delete failed.')
      }
      const data = await res.json();
      fetchServiceCenterStaff(1)
      setLoading(false)
      toast.success('sstaff deleted successfully')
    } catch (error) {
      setLoading(false)
      toast.error('staff update failed.')
    }
    
  }

  const handleUpdateStaff = async(e) => {
    e.preventDefault()
    setLoading(true)
    const payload = {
      name,
      email,
      phone_number:phone,
      user_type:employeeType
    }
    try {
      const res = await makeAuthenticatedRequest(`/oms/service_center/staff/${editData?.id}/`, "POST", JSON.stringify(payload))
      if (!res.ok) {
        setLoading(false)
        handleCloseEditEmployeeModal()
        throw new Error('staff update failed.')
      }
      const data = await res.json();
      fetchServiceCenterStaff(1)
      setLoading(false)
      handleCloseEditEmployeeModal()
      toast.success('staff updated successfully')
    } catch (error) {
      setLoading(false)
      toast.error('staff update failed.')
    }
  }
    const handleCloseCreateEmployeeModal = () => {
        setAddEmployee(false)
      }

      const handleCloseEditEmployeeModal = () => {
        setName("")
        setEmail("")
        setPhone("")
        setEditEmployee(false)
        setEditData(null)
      }


    const [open, setOPen] = useState([]);
    const [deleteEmployee, setDeleteEmployee] = useState('Delete')
  

    const handleSetDeleteEmployee = () => {
        setDeleteEmployee('Confirm')
        setTimeout(() => {
          setDeleteEmployee('Delete')
        }, 2000);
      }
    
      const toggleOpen = employeeId => {
        const openState = open.slice()
        const index = openState.indexOf(employeeId)
    
        if (index >= 0) {
          openState.splice(index, 1)
          setOPen(openState)
        } else {
          openState.push(employeeId)
          setOPen(openState)
        }
      }
      const editRef = useRef()

      useEffect(() => {
        let actionhandler = e => {
          if (editRef.current && !editRef.current.contains(e.target)) {
    
            setOPen([])
    
            // console.log(editRef.current)
          }
        }
        document.addEventListener('mousedown', actionhandler);
    
        return () => {
          document.removeEventListener('mousedown', actionhandler);
        }
    
    
      })
  return (
    <>
                {
                processing &&
                <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
                    <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
                    <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
                </div>
            }
{editEmployee &&
  <ModalLayout open={editEmployee} handleClose={handleCloseEditEmployeeModal}>

<div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
  <p className='text-[20px] font-semibold'>Edit service center employee</p>
  <form action="" onSubmit={handleUpdateStaff} className='flex flex-col gap-2 w-full'>

    <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>Name</label>
      <input 
       value={name} 
      onChange={(e) => setName(e.target.value)}
       type="text" className='outline-none px-2 py-2 rounded-md border' />
    </div>
    <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>Email</label>
      <input 
       value={email} 
      onChange={(e) => setEmail(e.target.value)}
       type="text" className='outline-none px-2 py-2 rounded-md border' />
    </div>
    <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>Phone number</label>
      <input 
       value={phone} 
      onChange={(e) => setPhone(e.target.value)}
       type="text" className='outline-none px-2 py-2 rounded-md border' />
    </div>
    {/* <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>ID number</label>
      <input 
       value={id} 
      onChange={(e) => setId(e.target.value)}
       type="text" className='outline-none px-2 py-2 rounded-md border' />
    </div> */}
    <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>Employee type</label>

    <div className="flex flex-row gap-2">

      <input  
        checked={employeeType==='Admin'}
        onChange={(e) => setEmployeeType('Admin')}
       type="radio" name='userType' className='outline-none px-2 py-2 rounded-md border accent-black' />
       <p className='text-[16px]'>Admin user</p>
    </div>

    <div className="flex flex-row gap-2">

        <input 
        checked={employeeType==='SERVICE_SUPERVISOR'}
       onChange={(e) => setEmployeeType('SERVICE_SUPERVISOR')}
       type="radio" name='userType' className='outline-none px-2 py-2 rounded-md border accent-black' />
       <p className='text-[16px]'>Regular user</p>

    </div>
    </div>
    <div className="flex flex-row-reverse gap-2 w-full">
      <button 
      type='submit'
      className='px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md'>Continue</button>
      <button
      onClick={handleCloseEditEmployeeModal}
       className='px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100'>Cancel</button>

    </div>
  </form>
</div>
</ModalLayout>}

{addEmployee &&
  <ModalLayout open={addEmployee} handleClose={handleCloseCreateEmployeeModal}>

<div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
  <p className='text-[20px] font-semibold'>Add service center employee</p>
  <form action="" onSubmit={createServiceCenter} className='flex flex-col gap-2 w-full'>

    <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>Name</label>
      <input 
       value={name} 
      onChange={(e) => setName(e.target.value)}
       type="text" className='outline-none px-2 py-2 rounded-md border' />
    </div>
    <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>Email</label>
      <input 
       value={email} 
      onChange={(e) => setEmail(e.target.value)}
       type="text" className='outline-none px-2 py-2 rounded-md border' />
    </div>
    <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>Phone number</label>
      <input 
       value={phone} 
      onChange={(e) => setPhone(e.target.value)}
       type="text" className='outline-none px-2 py-2 rounded-md border' />
    </div>
    {/* <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>ID number</label>
      <input 
       value={id} 
      onChange={(e) => setId(e.target.value)}
       type="text" className='outline-none px-2 py-2 rounded-md border' />
    </div> */}
    <div className="w-full flex flex-col gap-1">
      <label htmlFor="" className='text-[16px]'>Employee type</label>

    <div className="flex flex-row gap-2">

      <input  
        checked={employeeType==='Admin'}
        onChange={(e) => setEmployeeType('Admin')}
       type="radio" name='userType' className='outline-none px-2 py-2 rounded-md border accent-black' />
       <p className='text-[16px]'>Admin user</p>
    </div>

    <div className="flex flex-row gap-2">

        <input 
        checked={employeeType==='SERVICE_SUPERVISOR'}
       onChange={(e) => setEmployeeType('SERVICE_SUPERVISOR')}
       type="radio" name='userType' className='outline-none px-2 py-2 rounded-md border accent-black' />
       <p className='text-[16px]'>Regular user</p>

    </div>
    </div>
    <div className="flex flex-row-reverse gap-2 w-full">
      <button 
      type='submit'
      className='px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md'>Continue</button>
      <button
      onClick={handleCloseCreateEmployeeModal}
       className='px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100'>Cancel</button>

    </div>
  </form>
</div>
</ModalLayout>}
    <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20">
<div className="flex flex-row">
    <div onClick={() => navigate(-1)}
    className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] text-[14px] rounded-lg">
    <img src={back} alt="" />
    Back
    </div>
</div>
<div className="flex flex-row gap-6">
    <p className='font-satoshi text-[#030A1A] text-[16px] md:text-[18px] font-semibold'>Service center #SPP-{serviceCenterID}</p>
    
    <img src={copy} alt="" className='hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`SPP-${serviceCenterID}`) }} />
</div>
<div className="flex flex-row gap-3">
    <p className='font-satoshi text-[#808491] text-[16px] md:text-[16px] '>
    <Moment format="MMM, DD LT">
      {data?.date_created}
    </Moment>
    </p>
    
</div>
<div className="flex flex-col md:flex-row gap-4 w-full ">
    {/* <Link 
    to={`${ENDPOINT_USERS}/${consumer.id}`} 
    className="flex flex-col w-full md:w-6/12 border rounded-lg gap-2 px-2 pt-1 pb-2"> */}
    <div className="flex flex-col w-full border rounded-lg gap-2 px-2 pt-1 pb-2">
    <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Service center details</p>
    <div className="border-b border-b-gray-200"></div>
    <div className="flex flex-row w-full gap-4">
        <div className="">
        <img src="/cdn/images/vehicle.svg" className='w-16 h-16 rounded' alt="" />
        </div>
        <div className="flex-1 flex flex-col gap-1">
    <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{data?.name}</p>
   
    <div className="flex flex-col gap-1">

    <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{data?.location}</p>
    {/* <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Rating</p>    */}


    </div>

            
        </div>

    </div>

    
    </div>
    {/* </Link> */}
   

</div>
    <div className="flex flex-col w-full">
        <div className="flex flex-row w-full justify-between font-satoshi">

        <p className='font-semibold text-[20px]'>Employees</p>

        <button
            onClick={() =>setAddEmployee(true)}
                className='outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-white w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                Add Employee</button>
        </div>
          
                  <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg h-full border border-gray-200 mt-4">
                    <table class="table-auto w-full h-full ">
                      <thead className='pl-2'>
                        <tr className='pl-1 border-b border-gray-200'>
                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>#</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Roles</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Email</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Phone number</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Actions</th>

                        </tr>
                      </thead>
                      <tbody>
                        {staffData?.results?.map((staff) => (

                            <tr className='p-2 border-b border-b-gray-200'>
                              <td
                                className='p-2 text-xs md:text-[16px] text-left font-satoshi inline-block'>
                                {/* <Link to={`${ENDPOINT_SERVICE_CENTER}/${1}`}> */}
                                  <p>SPP-{staff?.id}</p>
                                {/* </Link> */}
                              </td>
                              <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                <div className=" text-[10px] md:text-[14px] ">
                                <p>{staff?.staff?.first_name} {staff?.staff?.last_name}</p>
                                </div>
                              </td>

                              <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                              <div className=" text-[10px] md:text-[12px] ">
                                {staff?.roles?.map((role) => (
                                  <p key={role?.id} className='text-[12px]'>{role?.name}</p>
                                ))}
                                </div>
                               
                              </td>
                              <td
                                className='p-4 text-xs md:text-[16px] cursor-pointer'>
                                <div className=" text-[10px] md:text-[14px] ">
                                  <p>{staff?.staff?.email}</p>
                                </div>
                              </td>
                              <td className='p-2 text-xs md:text-[16px] text-left font-satoshi'>
                                <div className=" text-[10px] md:text-[14px] ">
                                  <p>{staff?.phone_number}</p>
                                </div>
                              </td>
                              <td className='p-2 text-xs md:text-[16px] text-left font-satoshi relative'>
                                

                              <svg
                            onClick={() => toggleOpen(staff?.id)}
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="cursor-pointer w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                            </svg>

                              {open.includes(staff?.id) && (
                                    <>
                                    <div ref={editRef} className={`border border-gray-200 w-40 -top-10 mt-4 bg-white z-50 absolute right-4 rounded-md shadow-md pr-4 py-2 pl-2 `}>
                                    
                                        <div
                                              onClick={() =>handleOpenEditStaff(staff)}
                                            className='flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer'>
                                            <div className='flex flex-row gap-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />

                                            </svg>
                                            <p className='font-satoshi text-[12px]'>Edit</p>
                                            </div>
                                        </div>

                                        <div
                                        onClick={(e) => {
                                            handleSetDeleteEmployee()
                                            handleDeleteStaff(staff?.id)
                                        }}
                                        className='flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer'>
                                        <div className='flex flex-row gap-2'>
                                            {deleteEmployee === 'Delete' ?
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                            </svg>
                                            }
                                            <p className='font-satoshi text-sm text-[#BF3054]'>{deleteEmployee}</p>
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                
                              </td>

                            

                            </tr>
                        ))}
                            



                      </tbody>
                    </table>
                    {staffData?.results?.length > 0 &&
                                    <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={page} />
                                }
                  </div>

            </div>



</div>
</>
  )
}

export default ServiceCenterDetailsPage