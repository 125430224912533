import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { axiosCustom, formatNumber, getLocalStoreItem } from "../../utils";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import { CUSTOMERS_TAB_ORDERS } from "../../routes";
import AddOrderModal from "./modals/AddOrderModal";

import { API_PREFIX, LS_ACCOUNT_ID, LS_ACCESS_TOKEN } from "../../constants";

const CUSTOMERS_URL = `/oms/customers/`;

function OrdersTab() {
  const { customerID } = useParams();
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [currentOrder, setCurrentOrder] = useState();
  const [processing, setProcessing] = useState(false);
  const [showNewOrderModal, setShowNewOrderModal] = useState(false);

  // authenticate export endpoint
  const userId = getLocalStoreItem(LS_ACCOUNT_ID);
  const token = getLocalStoreItem(LS_ACCESS_TOKEN);
  const key =
    token.substring(0, 4) + token.substring(token.length - 4, token.length);

  const getImage = (object) => {
    let imageURL = null;
    object?.cart?.lines.map((line) => {
      if (
        line?.carpart?.thumbnail_urls?.length &&
        line?.carpart?.thumbnail_urls[0]
      ) {
        imageURL = line?.carpart?.thumbnail_urls[0];
      }
    });
    return imageURL;
  };

  const getOneItemName = (object) => {
    let itemName = null;
    object?.cart?.lines.map((line) => {
      if (line?.carpart?.name) {
        itemName = line?.carpart?.name;
      }
    });
    return itemName;
  };

  const fetchData = async () => {
    setProcessing(true);
    try {
      const params = {
        s: search,
        page: page,
        per_page: perPage,
      };
      const searchParams = new URLSearchParams(params).toString();
      const response = await axiosCustom.get(
        `${CUSTOMERS_URL}${customerID}/orders/?${searchParams}`
      );
      setPage(response?.data?.page);
      setData(response?.data?.results);
      setCount(response?.data?.count);
      setPerPage(response?.data?.per_page);
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setProcessing(false);
    }
  };

  const submitSearch = async (e) => {
    e.preventDefault();
    await fetchData();
  };

  const handleReload = async () => {
    handleModalClose();
    await fetchData();
  };

  const handleModalClose = () => {
    setShowNewOrderModal(false);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <>
      {showNewOrderModal && (
        <AddOrderModal
          customerID={customerID}
          handleModalClose={handleModalClose}
          handleReload={handleReload}
        />
      )}
      {processing ? (
        <ReactLoading
          className="mb-10"
          type="bubbles"
          color="#030A1A"
          height={50}
          width={100}
        />
      ) : (
        <section>
          <div class="mx-auto max-w-screen-2xl">
            <div class="mt-4 relative overflow-hidden">
              <div class="flex flex-row px-2 py-3 space-y-3">
                <div class="flex items-center flex-1 space-x-4">
                  <form class="flex items-center" onSubmit={submitSearch}>
                    <label for="simple-search" class="sr-only">
                      Search
                    </label>
                    <div class="relative w-full">
                      <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                        onClick={submitSearch}
                      >
                        <svg
                          aria-hidden="true"
                          class="w-5 h-5 text-gray-500 "
                          fill="currentColor"
                          viewbox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        id="simple-search"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     :ring-primary-500 :border-primary-500"
                        placeholder="Search"
                        required=""
                      />
                    </div>
                  </form>
                </div>
                <div class="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
                  
                  <a
                    href={`${API_PREFIX}/oms/customers/${customerID}/orders/export/?id=${userId}&key=${key}`}
                    className="rounded-lg whitespace-nowrap text-center font-medium bg-[#E5E6E9]  cursor-pointer  px-4 py-2 font-satoshi text-[#808491] hover:bg-[#cdced3] text-sm"
                  >
                    Export
                    <span className="p-1 ml-2 text-[#b0b6c7] text-xs">
                      <svg
                        className="w-4 h-4 inline"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Download-2</title>
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g id="Download-2">
                            <rect
                              id="Rectangle"
                              fill-rule="nonzero"
                              x="0"
                              y="0"
                              width="24"
                              height="24"
                            ></rect>
                            <line
                              x1="12"
                              y1="14"
                              x2="12"
                              y2="20"
                              id="Path"
                              stroke="#0C0310"
                              stroke-width="2"
                              stroke-linecap="round"
                            ></line>
                            <path
                              d="M15,19 L12.7071,21.2929 C12.3166,21.6834 11.6834,21.6834 11.2929,21.2929 L9,19"
                              id="Path"
                              stroke="#0C0310"
                              stroke-width="2"
                              stroke-linecap="round"
                            ></path>
                            <path
                              d="M19.9495,16 C20.5978,15.3647 21,14.4793 21,13.5 C21,11.567 19.433,10 17.5,10 C17.3078,10 17.1192,10.0155 16.9354,10.0453 C16.4698,6.63095 13.5422,4 10,4 C6.13401,4 3,7.13401 3,11 C3,12.9587 3.80447,14.7295 5.10102,16"
                              id="Path"
                              stroke="#0C0310"
                              stroke-width="2"
                              stroke-linecap="round"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </a>

                  <button
                    onClick={(e) => setShowNewOrderModal(true)}
                    class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-black hover:bg-gray-800 focus:ring-4 focus:ring-primary-300  :bg-slate-400 focus:outline-none :ring-primary-800"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="text-white w-4 h-4 mr-1"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                    New Order
                  </button>
                </div>
              </div>

              {!data || data?.length < 1 ? (
                <section class="bg-white ">
                  <div class="py-8 mx-auto px-4">
                    <span className="text-sm">No orders to display</span>
                  </div>
                </section>
              ) : (
                <div class="relative overflow-x-auto">
                  {data?.map((item, i) => (
                    <>
                      <div
                        key={i}
                        className="w-full border border-slate-200 p-2 rounded flex flex-[5] gap-3 items-center justify-between mb-2"
                      >
                        <div className="flex flex-[4]">
                          <Link
                            to={`${item.id}`}
                            className="flex flex-[0.4] ml-2"
                          >
                            {getImage(item) ? (
                              <img
                                src={getImage(item)}
                                class="border border-slate-200 object-cover rounded-md h-16 w-16"
                              />
                            ) : (
                              <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                                <p className="font-satoshi text-[#F77B55] text-[14px] text-center">
                                  No images
                                </p>
                              </div>
                            )}
                          </Link>
                          <div className="flex flex-[3.6]">
                            <Link
                              to={`${item.id}`}
                              className="flex flex-col px-3"
                            >
                              <div className="flex flex-row gap-2">
                                <p className="flex text-gray-700 text-xs gap-2 items-center">
                                  <span className="font-semibold hover:underline">
                                    ORDER #{item?.order_id}
                                  </span>
                                  <span
                                    className={
                                      "text-xs px-2 py-1 rounded text-center text-black font-extrabold " +
                                      (item?.status === "New"
                                        ? "bg-[#B9E38D]"
                                        : item?.status === "New"
                                        ? "bg-[#B9E38D]"
                                        : item?.status === "Confirmed"
                                        ? "bg-[#54D3CA]"
                                        : item?.status === "Dispatched"
                                        ? "bg-[#8CD1FC]"
                                        : item?.status === "Delivered"
                                        ? "bg-[#3E5DDD]"
                                        : item?.status === "Requires prepayment"
                                        ? "bg-[#576298]"
                                        : item?.status === "To be exchanged"
                                        ? "bg-[#F0A6A5]"
                                        : item?.status === "Exchange complete"
                                        ? "bg-[#059D5F]"
                                        : item?.status === "Rejected"
                                        ? "bg-[#FCB28E]"
                                        : item?.status === "Reject Completed"
                                        ? "bg-[#F0A6A5]"
                                        : item?.status === "Complete"
                                        ? "bg-[#059D5F]"
                                        : item?.status === "Cancelled"
                                        ? "bg-[#DE6972]"
                                        : "bg-[#8d252e]")
                                    }
                                  >
                                    {item?.status}
                                  </span>
                                </p>
                              </div>
                              <div className="flex flex-row gap-1 mt-1">
                                <p className="text-xs md:text-sm text-gray-500 mr-2">
                                  {item?.item_count === 1 &&
                                  item?.cart &&
                                  item?.cart > 0
                                    ? item?.cart.lines[0].carpart.name
                                    : formatNumber(item?.item_count, 0, 0) +
                                      " items"}
                                </p>
                                <p
                                  className="text-xs font-semibold text-gray-500 mt-1"
                                  amount={item?.total}
                                >
                                  {item?.total
                                    ? "Ksh. " + formatNumber(item?.total, 0, 0)
                                    : "-"}
                                </p>
                              </div>
                              <p className="text-sm text-gray-500">
                                {getOneItemName(item)}{" "}
                                {item?.item_count > 1 ? (
                                  <span className="text-xs ml-1 text-red-400">
                                    +{item?.item_count - 1}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                              <p className="text-sm">
                                <Moment format="MMM, DD LT" className="flex">
                                  {item?.date_created}
                                </Moment>
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className="flex items-center py-2 flex-[1]">
                          {false && (
                            <Link
                              to={`${item.id}`}
                              className="bg-[#99DBF3] border border-[#99DBF3] rounded-md px-3 py-2 text-xs md:text-sm flex flex-row items-center justify-center"
                            >
                              View more
                            </Link>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              )}

              {data && data?.length > 0 && (
                <nav
                  class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                  aria-label="Table navigation"
                >
                  <span class="text-sm font-normal text-gray-500 ">
                    Showing
                    <span class="font-semibold text-gray-900 ">
                      {" "}
                      {(page - 1) * perPage + 1} -{" "}
                      {(page - 1) * perPage + perPage > count
                        ? count
                        : (page - 1) * perPage + perPage}
                    </span>
                    <span className="m-1">of</span>
                    <span class="font-semibold text-gray-900 "> {count} </span>
                    <span className="m-1">results</span>
                  </span>
                  {count > perPage && (
                    <ul class="inline-flex items-stretch -space-x-px">
                      <li>
                        <button
                          disabled={page <= 1}
                          onClick={(e) => setPage(Math.max(1, page - 1))}
                          className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white"
                        >
                          <span class="sr-only">Previous</span>
                          <svg
                            class="w-5 h-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewbox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={(e) => setPage(1)}
                          className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white"
                        >
                          1
                        </button>
                      </li>
                      {Math.ceil(count / perPage) >= 2 && (
                        <li>
                          <button
                            onClick={(e) => setPage(2)}
                            className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white"
                          >
                            2
                          </button>
                        </li>
                      )}

                      {Math.ceil(count / perPage) >= 3 && (
                        <li>
                          <button
                            onClick={(e) => setPage(3)}
                            className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white"
                          >
                            3
                          </button>
                        </li>
                      )}
                      <li>
                        <a
                          href="#"
                          class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white"
                        >
                          ...
                        </a>
                      </li>
                      <li>
                        <button
                          onClick={(e) => setPage(Math.ceil(count / perPage))}
                          className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white"
                        >
                          {Math.ceil(count / perPage)}
                        </button>
                      </li>
                      <li>
                        <button
                          disabled={page >= Math.ceil(count / perPage)}
                          onClick={(e) =>
                            setPage(
                              Math.min(page + 1, Math.ceil(count / perPage))
                            )
                          }
                          class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white"
                        >
                          <span class="sr-only">Next</span>
                          <svg
                            class="w-5 h-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewbox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  )}
                </nav>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default OrdersTab;
