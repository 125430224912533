import React, { useState, useEffect } from 'react'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ic_plus_big from "../../../assets/ic_plus_big.svg"
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { auth, storage } from '../../../firebaseUtils'
import ReactLoading from 'react-loading';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify'
import { getFirebaseTokenThenSignin, AccountObj, makeid } from '../../../utils';
import { ENDPOINT_INVENTORY } from '../../../routes';
import Compressor from 'compressorjs';
import { createMechanicSubsection } from './mechanicUtils';
import { getParentSections } from '../cmsUtils';


export const compresedImage = (file) =>
  new Promise((resolve) => {
    new Compressor(file, {
      minWidth: 640,
      maxHeight: 640,
      mimeType: 'image/webp',
      success(result) {
        resolve(result)
      },
      error(err) {
        console.log(err.message);
      },
    });
  });

const handleFileUploadOnFirebaseStorage = async (bucketName, uploadedImage) => {
  // 1. If no file, return
  if (uploadedImage === "" || uploadedImage === null) return "";
  // 2. Put the file into bucketName
  const fileID = makeid(20);
  const storageRef = ref(storage, `/${bucketName}/${fileID}.webp`);
  // const file = await fetch(uploadedImage).then(r => r.blob()).then(blobFile => new File([blobFile], fileID, { type: blobFile.type }))
  // const options = {
  //    maxSizeMB: 0.1,
  //    maxWidthOrHeight: 640,
  //    useWebWorker: true
  // }
  // const compressedFile = await imageCompression(file, options);
  await uploadBytesResumable(storageRef, uploadedImage);
  let downloadURL = null;
  await getDownloadURL(storageRef).then((fileURL) => downloadURL = fileURL);
  return downloadURL
};
const handleFilesUploadOnFirebaseStorage = async (uploadedImages) => {

  const bucketName = `/cms/heros`
  // 1. If no file, return
  if (uploadedImages.length === 0) return [];
  // 2. Create an array to store all download URLs
  let fileUrls = [];
  // 3. Loop over all the files
  for (var i = 0; i < uploadedImages.length; i++) {
    // 3A. Get a file to upload
    const uploadedImage = uploadedImages[i];
    // 3B. handleFileUploadOnFirebaseStorage function is in above section
    const downloadFileResponse = await handleFileUploadOnFirebaseStorage(bucketName, uploadedImage);
    // 3C. Push the download url to URLs array
    fileUrls.push(downloadFileResponse);
  }
  return fileUrls;
};

function MechanicAdd() {
  const navigate = useNavigate();
  const [subsectionTitle, setSubsectionTitle] = useState(null);
  const [subsectionSubtitle, setSubsectionSubtitle] = useState(null);

  const [link, setLink] = useState(null);
  const [linkTitle, setLinkTitle] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [coverImageIndex, setCoverImageIndex] = useState(-1)
  const refFileInput = React.createRef();
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedImages, setCroppedImages] = useState([])
  const [sectionName, setSectionName] = useState();
  const [isActive, setIsActive] = useState(false);
  const [isBanner, setIsBanner] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [parentSections, setParentSections] = useState([]);
  const [selectedParentSection, setSelectedParentSection] = useState(null)

  useEffect(() => {
    getParentSections()
      .then((results) => {
        const productSections = results.filter(section => section.section_type === "mechanic_section")
        let newSectionTypes = []
        productSections.map((section) => {
          newSectionTypes.push(
            {
              value: section.id,
              label: `${section.title}`,
            }
          )
        })
        setParentSections(newSectionTypes)
      })
      .catch((error)=>{
        toast.error("Error fetching parent sections")
      })
  }, [])

  const onFileChange = async (e) => {
    console.log(e.target.files)
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files)
      const newCroppedImages = []
      let lastCroppedImage = null;
      Array.from(e.target.files).map(async (file) => {
        const result = await compresedImage(file)
        lastCroppedImage = result
        newCroppedImages.push(lastCroppedImage)
        console.log(result)
        setCroppedImage(lastCroppedImage)
        setCroppedImages([...croppedImages, ...newCroppedImages])
      })
    }
  }

  useEffect(() => {
    if (croppedImage && croppedImages) {
      croppedImages.map((crImage, index) => {
        if (croppedImage == crImage) {
          setCoverImageIndex(index)
        }
      })
    }
  }, [croppedImage])



  const handleCancel = () => {
    navigate(ENDPOINT_INVENTORY)
  }

  const handleImageRemoveClick = index => {
    const lastImageIndex = croppedImages.length - 2;
    const list = [...croppedImages];
    list.splice(index, 1);
    setCroppedImages(list);
    if (lastImageIndex < 0) {
      setCroppedImage(null)
    } else {
      setCroppedImage(croppedImages[lastImageIndex])
    }
  }

  const handleImageClick = (index) => {
    console.log(index);
    setCroppedImage(croppedImages[index])
  }

  const handleSelectSectionType = (selected) => {
    console.log(selected.id)
    if (selected != null) {
      setSectionName(selected.id)
    }
  }

  const handleInputChange = (inputValue, event) => {
    console.log("Input value has changed:", inputValue, event);
    // Additional logic based on the input value can be added here
  };

  const components = {
    DropdownIndicator: null,
  };

  const sendToServer = async () => {
    setProcessing(true)

    let imageUrls
    if (croppedImages.length > 0) {
      imageUrls = await handleFilesUploadOnFirebaseStorage(croppedImages)
    } else {
      imageUrls = null
    }

    const tags = selectedTags.map(tag => tag.value)

    const params = {
      title: subsectionTitle,
      sub_title: subsectionSubtitle,
      tags: tags,
      is_active: isActive,
      is_banner: isBanner,
      link: link,
      link_title: linkTitle,
      section: selectedParentSection
    }

    if (imageUrls != null && imageUrls.length > 0) {
      params.image_url = imageUrls
      if (coverImageIndex > -1 && coverImageIndex <= imageUrls.length) {
        params.image_url = imageUrls[coverImageIndex]
      } else {
        params.image_url = imageUrls[imageUrls.length - 1]
      }
    }

    console.log("Params", params)

    createMechanicSubsection(params)
      .then((result) => {
        console.log(result)
        toast.success("Mechanic subsection successfully added!")
        setProcessing(false);
        navigate(-1)
      })
      .catch((error) => {
        console.log('error', error)
        toast.error("Something went wrong! Could not add mechanic subsection.")
        setProcessing(false);
      })
      .finally(() => {
        setProcessing(false);
      })

  };

  const handleSubmit = async (event, endpoint) => {
    event.preventDefault()

    if (subsectionTitle == null || subsectionTitle.trim().length < 1) {
      toast.error("Enter a valid section name")
      return
    }

    const accountObj = new AccountObj();
    const user = auth.currentUser;

    setProcessing(true)

    if (user) {
      await sendToServer(endpoint);
    } else {
      await getFirebaseTokenThenSignin()
        .then((userCredential) => {
          const user = userCredential.user
          sendToServer();

        }).then(() => {
          sendToServer()
        });
    }
  }

  return (
    <>
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-4 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
        </div>

        {false ?
          <ReactLoading type="bubbles" color="#030A1A" height={50} width={60} />
          :
          <div className="flex flex-col">

            <div className="pl-2 md:pl-28 pt-1  flex flex-row md:grid md:grid-cols-5 gap-2 border-b pb-2 w-full bg-white z-50">
              <div className="col-span-1">
                <h2 className='font-satoshi font-semibold text-xs md:text-lg'>Add a Mechanic Subsection</h2>
              </div>
              <div className="col-span-1 "></div>
              <div className="col-span-1 flex flex-row gap-2 items-end"></div>
            </div>
            <div className='flex flex-col md:flex-row md:w-[90%] gap-10 pl-2 md:pl-20 pt-1' >
              <div className="h-full pt-2 flex flex-col md:flex-row gap-20 mt-2 flex-[3] p-1 hide-scrollbar overflow-y-scroll">
                <div className="w-full flex flex-col gap-2">

                  <div className="flex flex-col gap-1 w-full mt-2 mb-2">
                    <p className='text-[#818490] font-satoshi text-md font-semibold'>Parent Section</p>
                    <Select
                      options={parentSections}
                      // onMenuScrollToBottom={loadMoreOptions}
                      // onInputChange={(inputValue) => handleInputChange(inputValue)}
                      isLoading={isLoading}
                      onChange={(e) => { setSelectedParentSection(e.value) }}
                    />
                  </div>

                  <p className='text-[#818490] font-satoshi text-md font-semibold'>Subsection Name</p>
                  <input
                    onChange={e => setSubsectionTitle(e.target.value)}
                    required
                    value={subsectionTitle}
                    className='border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none'
                    placeholder='e.g Top Mechaninics'
                  />

                  <p className='text-[#818490] font-satoshi text-md font-semibold'>Subtitle</p>
                  <input
                    onChange={e => setSubsectionSubtitle(e.target.value)}
                    required
                    value={subsectionSubtitle}
                    className='border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none'
                    placeholder='e.g Introducing Top Mechanics'
                  />

                  <p className='text-[#818490] font-satoshi text-md font-semibold'>Link</p>
                  <input
                    onChange={e => setLink(e.target.value)}
                    required
                    value={link}
                    className='border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none'
                    placeholder='e.g example.com'
                  />

                  <p className='text-[#818490] font-satoshi text-md font-semibold'>Link Title</p>
                  <input
                    onChange={e => setLinkTitle(e.target.value)}
                    required
                    value={linkTitle}
                    className='border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none'
                    placeholder='e.g Battery Advert'
                  />

                  <div>
                    <div>
                      {croppedImage ?
                        <img src={URL.createObjectURL(croppedImage)}
                          className='bg-white rounded-lg mt-4 mb-2 h-72 w-full border object-contain' alt=""
                        />
                        :
                        <div>
                          <input type="file"
                            name="file"
                            id="files"
                            ref={refFileInput}
                            accept="image/*"
                            multiple
                            onChange={onFileChange}
                            className='hidden' />
                          <label htmlFor="files" className='flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center'>
                            <img src={ic_plus_big} className='h-20 bg-gray-300' alt="" />
                          </label>
                        </div>
                      }
                    </div>
                    <div className="flex flex-row gap-2">
                      {croppedImages.map((image, i) => (
                        <div key={i} className='flex flex-row'>
                          <div class="mt-1 mr-1">
                            <img src={URL.createObjectURL(image)}
                              overflow-hidden rounded-lg object-contain h-20 w-20 border border-gray-200
                              className={image == croppedImages[coverImageIndex] ? 'bg-white rounded-lg w-full border object-contain h-20 w-20 border-4 border-teal-400' : 'bg-white rounded-lg w-full border object-contain h-20 w-20 border border-gray-200'}
                              alt="" onClick={() => handleImageClick(i)} />
                          </div>
                          <span className='text-red-500 p-2 hover:cursor z-50 -ml-8 -mt-2 h-2' onClick={() =>
                            handleImageRemoveClick(i)}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-tj5bde-Svg">
                              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                            </svg>
                          </span>
                        </div>
                      ))}
                      {croppedImage && (
                        <label htmlFor="file-upload" class="relative cursor-pointer rounded-md bg-gray-300 font-medium text-black focus-within:outline-none ">
                          <div class="mt-1 flex justify-center rounded-md p-6">
                            <div class="space-y-1 text-center">
                              <div class="flex text-sm text-gray-600">
                                <span className='font-satoshi bg-gray-300'>
                                  <img src={ic_plus_big} className=' h-7 w-7 bg-gray-300' alt="" />
                                </span>
                                <input ref={refFileInput} id="file-upload" name="file-upload" multiple type="file" className="sr-only" onChange={onFileChange} accept="image/*" />
                              </div>
                            </div>
                          </div>
                        </label>
                      )}
                    </div>
                  </div>

                  <p className='text-[#818490] font-satoshi text-md font-semibold'>Tags</p>
                  <div className="flex flex-col">
                    <CreatableSelect
                      components={components}
                      isClearable
                      isMulti
                      placeholder="Type something and press enter..."
                      onChange={e =>
                        setSelectedTags(e)}
                    />
                  </div>

                  <div className="py-3">
                    <div className='text-gray-500 font-bold text-sm gap-2 mr-4'>Make Banner
                      <span className='float-right'>
                        <label htmlFor="push-green-toggle" className="inline-flex relative items-center mr-5 cursor-pointer">

                          <input
                            onChange={() => setIsBanner(!isBanner)}
                            checked={isBanner}
                            type="checkbox"
                            value=""
                            id="push-green-toggle"
                            className="sr-only peer"
                          />

                          <div className="w-11 h-6 bg-gray-200 rounded-full  peer-focus:ring-3
                    peer-focus:ring-green-300 :ring-green-800 peer-checked:after:translate-x-full after:absolute after:top-0.5 after:left-[2px]
                      after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all
                      peer-checked:bg-green-600"></div>
                        </label>
                      </span>
                    </div>
                  </div>
                  <hr />


                  <div className="py-3">
                    <div className='text-gray-500 font-bold text-sm gap-2 mr-4'>Activate Section
                      <span className='float-right'>
                        <label htmlFor="push-green-toggle-2" className="inline-flex relative items-center mr-5 cursor-pointer">

                          <input
                            onChange={() => setIsActive(!isActive)}
                            checked={isActive}
                            type="checkbox"
                            value=""
                            id="push-green-toggle-2"
                            className="sr-only peer"
                          />

                          <div className="w-11 h-6 bg-gray-200 rounded-full  peer-focus:ring-3
                    peer-focus:ring-green-300 :ring-green-800 peer-checked:after:translate-x-full after:absolute after:top-0.5 after:left-[2px]
                      after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all
                      peer-checked:bg-green-600"></div>
                        </label>
                      </span>
                    </div>
                  </div>
                  <hr />

                  <div className="grid py-4 mt-4">
                    <div className="flex flex-row gap-4 md:col-span-2">

                    </div>
                    {
                      processing ?
                        <div className="flex flex-row gap-2">
                          <button className='py-1 md:py-2 px-1 md:px-4 text-center font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-xs md:text-sm' onClick={handleCancel} ><span className='text-center' >Processing</span> <ReactLoading type="bubbles" color="#030A1A" height={10} width={30} className="inline-block align-middle" /></button>
                        </div>
                        :
                        <div className="flex gap-2 w-full flex-row-reverse col-span-2">
                          <button title="save" className='py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-white border-gray-200 bg-[#19223B] text-sm' onClick={(e) => handleSubmit(e, ENDPOINT_INVENTORY)}>Save</button>
                          <button className='py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm' onClick={handleSubmit}><span className='text-center'>Save and add another</span></button>
                          <button className='py-1 md:py-2 px-1 md:px-4 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm' onClick={handleCancel}><span className='text-center' >Cancel</span></button>
                        </div>
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
        }
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
export default MechanicAdd
