import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { Routes, Route } from "react-router-dom"
import MarketPlacePage from './pages/MarketPlacePage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import { 
  AnonymousRoute, 
  AuthenticatedRoute, 
  CUSTOMERS_TAB_ACTIVITY, 
  CUSTOMERS_TAB_BOOKINGS, 
  CUSTOMERS_TAB_FAVORITES, 
  CUSTOMERS_TAB_ORDERS, 
  CUSTOMERS_TAB_ORDER_DETAILS, 
  CUSTOMERS_TAB_PART_REQUESTS, 
  CUSTOMERS_TAB_PART_REQUEST_DETAILS, 
  CUSTOMERS_TAB_PROFILE, 
  CUSTOMERS_TAB_SERVICES, 
  CUSTOMERS_TAB_ADD_PART_REQUESTS,
  CUSTOMERS_TAB_PAYMENTS,
  CUSTOMERS_TAB_MESSAGES,
  ENDPOINT_ACCOUNT, 
  ENDPOINT_BRANDS, 
  ENDPOINT_BTOB, 
  ENDPOINT_BTOB_CLIENT_DETAILS, 
  ENDPOINT_BTOB_ONBOARDING, 
  ENDPOINT_CUSTOMERS, 
  ENDPOINT_CUSTOMERS_ADD, 
  ENDPOINT_CUSTOMERS_DETAILS, 
  ENDPOINT_DASHBOARD, 
  ENDPOINT_DSF, 
  ENDPOINT_DSF_DETAILS, 
  ENDPOINT_DSF_EDIT, 
  ENDPOINT_EMAIL_VERIFY, 
  ENDPOINT_ENTER_EMAIL, 
  ENDPOINT_GRN_DETAILS, 
  ENDPOINT_INVENTORY, 
  ENDPOINT_INVENTORY_ADD, 
  ENDPOINT_INVENTORY_DETAILS, 
  ENDPOINT_INVENTORY_EDIT, 
  ENDPOINT_INVOICE_DETAILS, 
  ENDPOINT_JOB_DETAILS, 
  ENDPOINT_LEADS, 
  ENDPOINT_LEAD_DETAILS, 
  ENDPOINT_LEAD_EDIT, 
  ENDPOINT_LPO_DETAILS, 
  ENDPOINT_MARKETPLACE, 
  ENDPOINT_MECHANICS, 
  ENDPOINT_MECHANIC_DETAILS, 
  ENDPOINT_MERCHANT_DETAILS, 
  ENDPOINT_ORDERS, 
  ENDPOINT_ORDER_DETAILS, 
  ENDPOINT_PART_REQUESTS, 
  ENDPOINT_PART_REQUESTS_DETAILS, 
  ENDPOINT_RFQ_DETAILS, 
  ENDPOINT_SERVICE_BOOKING, 
  ENDPOINT_SERVICE_BOOKING_DETAILS, 
  ENDPOINT_SERVICE_CENTER, 
  ENDPOINT_SERVICE_CENTER_DETAILS,
  ENDPOINT_STAFF, 
  ENDPOINT_STAFF_DETAILS, 
  ENDPOINT_TRACK_ORDERS, 
  ENDPOINT_USERS, 
  ENDPOINT_USER_DETAILS, 
  ENDPOINT_VEHICLES, 
  ENDPOINT_CMS_ADD_HERO, 
  ENDPOINT_CMS, 
  ENDPOINT_CMS_HERO, 
  ENDPOINT_CMS_EDIT_HERO, 
  ENDPOINT_CMS_PRODUCT, 
  ENDPOINT_CMS_ADD_PRODUCT, 
  ENDPOINT_CMS_CATEGORY, 
  ENDPOINT_CMS_ADD_CATEGORY, 
  ENDPOINT_CMS_BRAND, 
  ENDPOINT_CMS_ADD_BRAND, 
  ENDPOINT_CMS_SHOP, 
  ENDPOINT_CMS_ADD_SHOP, 
  ENDPOINT_CMS_ACCESSORY, 
  ENDPOINT_CMS_ADD_ACCESSORY, 
  ENDPOINT_CMS_PARTNER, 
  ENDPOINT_CMS_ADD_PARTNER, 
  ENDPOINT_CMS_MECHANIC, 
  ENDPOINT_CMS_ADD_MECHANIC, 
  ENDPOINT_CMS_ADVERT, 
  ENDPOINT_CMS_ADD_ADVERT, 
  ENDPOINT_CMS_HERO_DETAILS, 
  ENDPOINT_CMS_PRODUCT_DETAILS, 
  ENDPOINT_CMS_ACCESSORY_DETAILS, 
  ENDPOINT_CMS_BRAND_DETAILS,  
  ENDPOINT_GENERATE_INVOICE, 
  ENDPOINT_SHORT_URLS, 
  ENDPOINT_SHORT_URLS_ADD,
  ENDPOINT_SHORT_URLS_DETAILS, 
  ENDPOINT_STAFF_ORDER_STATS, 
  ENDPOINT_STAFF_ORDER_STATS_DETAILS, 
  ENDPOINT_REVIEWS,
  ENDPOINT_REVIEW_DETAILS,
  ENDPOINT_USER_STATS, 
} from './routes';
import DashboardPage from './pages/DashboardPage';
import InventoryPage from './pages/InventoryPage';
import LoginPage from './pages/LoginPage';
import InventoryDetailsPage from './pages/InventoryDetailsPage';
import Layout from './components/shared/Layout';
import MechanicsPage from './pages/MechanicsPage';
import JobDetailsPage from './pages/JobDetailsPage';
import UserDetailsPage from './pages/UserDetailsPage';
import MechanicsDetailPage from './pages/MechanicsDetailPage';
import MerchantDetailsPage from './pages/MerchantDetailsPage';
import DSFsPage from './pages/DSFsPage';
import DSFsDetailsPage from './pages/DSFsDetailsPage';
import VehiclesPage from './pages/VehiclesPage';
import InventoryEdit from './pages/InventoryEdit';
import BrandsPage from './pages/BrandsPage';
import DsfEditPage from './pages/DsfEditPage';
import PartRequestsPage from './pages/PartRequestsPage';
import PartRequestDetails from './pages/PartRequestDetails';
import AccountPage from './pages/AccountPage';
import StaffPage from './pages/StaffPage';
import StaffDetailsPage from './pages/StaffDetailsPage';
import { hasPermission } from './utils';
import { 
  PERMISSION_CHANGE_CARPART, 
  PERMISSION_CHANGE_CONSUMER, 
  PERMISSION_CHANGE_DSF, 
  PERMISSION_CHANGE_JOB, 
  PERMISSION_CHANGE_MECHANIC, 
  PERMISSION_CHANGE_MERCHANT, 
  PERMISSION_CHANGE_ORDER, 
  PERMISSION_CHANGE_PART_REQUEST, 
  PERMISSION_CHANGE_STAFF, 
  PERMISSION_CHANGE_USER, 
  PERMISSION_VIEW_BRAND, 
  PERMISSION_VIEW_CARPART, 
  PERMISSION_VIEW_CONSUMER, 
  PERMISSION_VIEW_DSF, 
  PERMISSION_VIEW_MECHANIC, 
  PERMISSION_VIEW_ORDER, 
  PERMISSION_VIEW_PART_REQUEST, 
  PERMISSION_VIEW_STAFF, 
  PERMISSION_VIEW_VEHICLE 
} from './constants';
import Page404 from './pages/Page404';
import LeadsPage from './pages/LeadsPage';
import LeadDetails from './pages/LeadDetails';
import LeadEditPage from './pages/LeadEditPage';
import BookingDetails from './pages/BookingDetails';
import ServiceCenterDetailsPage from './pages/ServiceCenterDetailsPage';
import ServiceCentersPage from './pages/ServiceCentersPage';
import InventoryAdd from './pages/InventoryAdd';
import CustomerAdd from './pages/CustomerAdd';
import Customers from './pages/Customers';
import BClients from './pages/BClients';
import { ToastContainer } from 'react-toastify'
import CustomerLayout from './components/customers/CustomerLayout';
import ProfileTab from './components/customers/ProfileTab';
import OrdersTab from './components/customers/OrdersTab';
import ServicesTab from './components/customers/ServicesTab';
import BookingsTab from './components/customers/BookingsTab';
import PartRequestsTab from './components/customers/PartRequestsTab';
import FavoritesTab from './components/customers/FavoritesTab';
import OrderDetailsTab from './components/customers/OrderDetailsTab';
import PartRequestDetailsTab from './components/customers/PartRequestDetailsTab';
import AddPartRequestTab from './components/customers/AddPartRequestTab';
import PaymentsTab from './components/customers/PaymentsTab';
import Messages from './components/customers/Messages';
import ActivityModal from './components/customers/ActivityModal';
import HeroViewPage from './pages/HeroViewPage';
import ClientOnboarding from './pages/ClientOnboarding';
import RfqDetailsPage from './pages/RfqDetailsPage';
import LpoDetailsPage from './pages/LpoDetailsPage';
import GRNDetailsPage from './pages/GRNDetailsPage';
import BClientDetailsPage from './pages/BClientDetailsPage';
import CMSPage from "./pages/CMSPage";
import HeroPage from "./pages/HeroPage";
import HeroAddPage from "./pages/HeroAddPage";
import AccessoryPage from "./pages/AccessoryPage";
import AccessoryViewPage from "./pages/AccessoryViewPage";
import AdvertPage from "./pages/AdvertPage";
import BrandViewPage from "./pages/BrandViewPage";
import CategoriesPage from "./pages/CategoriesPage";
import ProductViewPage from "./pages/ProductViewPage";
import ShopPage from "./pages/ShopPage";
import PartnerPage from "./pages/PartnersPage";
import MechanicCMSPage from "./pages/MechanicCMSPage";
import HeroEditPage from "./pages/HeroEditPage";
import ProductAddPage from "./pages/ProductAddPage";
import AccessoryAddPage from "./pages/AccessoryAddPage";
import BrandAddPage from "./pages/BrandAddPage";
import CategoriesAddPage from "./pages/CategoriesAddPage";
import ShopAddPage from "./pages/ShopAddPage";
import MechanicsAddPage from "./pages/MechanicsAddPage";
import PartnersAddPage from "./pages/PartnersAddPage";
import AdvertAddPage from "./pages/AdvertAddPage";
import BClientInvoice from './pages/BClientInvoice';



import GenerateInvoice from './pages/GenerateInvoice';
import StaffOrderStats from './pages/StaffOrderStats';
import StaffOrderStatsDetails from './pages/StaffOrderStatsDetails';


import UrlsPage from './pages/UrlsPage';
import UrlsAddPage from './pages/UrlsAddPage'
import UrlsDetailsPage from './pages/UrlsDetailsPage';

import ReviewPage from './pages/ReviewPage';
import ReviewDetailsPage from './pages/ReviewDetailPage';
import UserAnalyticsPage from './pages/UserAnalyticsPage';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <>
          <Routes>
            <Route element={<AnonymousRoute />}>
              <Route path={ENDPOINT_ENTER_EMAIL} element={<LoginPage />} />
            </Route>

            <Route element={<AuthenticatedRoute />}>
              <Route path='/' element={<Layout />}>
                {hasPermission(PERMISSION_VIEW_STAFF) && <Route path={ENDPOINT_STAFF} element={<StaffPage />}></Route>}
                {hasPermission(PERMISSION_CHANGE_STAFF) && <Route path={ENDPOINT_STAFF_DETAILS} element={<StaffDetailsPage />}></Route>}
                {
                  hasPermission(PERMISSION_VIEW_CONSUMER) &&
                  hasPermission(PERMISSION_VIEW_ORDER) &&
                  hasPermission(PERMISSION_VIEW_BRAND) &&
                  hasPermission(PERMISSION_VIEW_VEHICLE) &&
                  <Route path={ENDPOINT_MARKETPLACE} element={<MarketPlacePage />}></Route>
                }
                {hasPermission(PERMISSION_VIEW_CARPART) && <Route path={ENDPOINT_INVENTORY} element={<InventoryPage />}></Route>}
                {hasPermission(PERMISSION_VIEW_VEHICLE) && <Route path={ENDPOINT_VEHICLES} element={<VehiclesPage />}></Route>}
                {hasPermission(PERMISSION_VIEW_BRAND) && <Route path={ENDPOINT_BRANDS} element={<BrandsPage />}></Route>}

                <Route index element={<DashboardPage />}></Route>
                <Route path={ENDPOINT_ACCOUNT} element={<AccountPage />}></Route>
                {hasPermission(PERMISSION_CHANGE_ORDER) && <Route path={ENDPOINT_ORDER_DETAILS} element={<OrderDetailsPage />}></Route>}
                {hasPermission(PERMISSION_VIEW_MECHANIC) && <Route path={ENDPOINT_MECHANICS} element={<MechanicsPage />}></Route>}
                {hasPermission(PERMISSION_CHANGE_JOB) && <Route path={ENDPOINT_JOB_DETAILS} element={<JobDetailsPage />}></Route>}
                {hasPermission(PERMISSION_CHANGE_MECHANIC) && <Route path={ENDPOINT_MECHANIC_DETAILS} element={<MechanicsDetailPage />}></Route>}
                {hasPermission(PERMISSION_CHANGE_CONSUMER) && <Route path={ENDPOINT_USER_DETAILS} element={<UserDetailsPage />}></Route>}
                {hasPermission(PERMISSION_CHANGE_MERCHANT) && <Route path={ENDPOINT_MERCHANT_DETAILS} element={<MerchantDetailsPage />}></Route>}
                {hasPermission(PERMISSION_VIEW_DSF) && <Route path={ENDPOINT_DSF} element={<DSFsPage />}></Route>}
                {hasPermission(PERMISSION_CHANGE_DSF) && <Route path={ENDPOINT_DSF_DETAILS} element={<DSFsDetailsPage />}></Route>}
                {hasPermission(PERMISSION_VIEW_CARPART) && <Route path={ENDPOINT_INVENTORY_DETAILS} element={<InventoryDetailsPage />}></Route>}
                {hasPermission(PERMISSION_CHANGE_CARPART) && <Route path={ENDPOINT_INVENTORY_EDIT} element={<InventoryEdit />}></Route>}
                {hasPermission(PERMISSION_CHANGE_DSF) && <Route path={ENDPOINT_DSF_EDIT} element={<DsfEditPage />}></Route>}
                {hasPermission(PERMISSION_VIEW_PART_REQUEST) && <Route path={ENDPOINT_PART_REQUESTS} element={<PartRequestsPage />}></Route>}
                {hasPermission(PERMISSION_CHANGE_PART_REQUEST) && <Route path={ENDPOINT_PART_REQUESTS_DETAILS} element={<PartRequestDetails />}></Route>}
                <Route path={ENDPOINT_LEADS} element={<LeadsPage />}></Route>
                <Route path={ENDPOINT_USER_STATS} element={<UserAnalyticsPage />}></Route>
                <Route path={ENDPOINT_BTOB} element={<BClients />}></Route>
                <Route path={ENDPOINT_RFQ_DETAILS} element={<RfqDetailsPage />}></Route>
                <Route path={ENDPOINT_LPO_DETAILS} element={<LpoDetailsPage />}></Route>
                <Route path={ENDPOINT_GRN_DETAILS} element={<GRNDetailsPage />}></Route>
                <Route path={ENDPOINT_BTOB_CLIENT_DETAILS} element={<BClientDetailsPage />}></Route>
                <Route path={ENDPOINT_INVOICE_DETAILS} element={<BClientInvoice />}></Route>                
                <Route path={ENDPOINT_BTOB_ONBOARDING} element={<ClientOnboarding />}></Route>
                <Route path={ENDPOINT_GENERATE_INVOICE} element={<GenerateInvoice />}></Route>


                <Route path={ENDPOINT_LEAD_DETAILS} element={<LeadDetails />}></Route>
                <Route path={ENDPOINT_LEAD_EDIT} element={<LeadEditPage />}></Route>

                <Route path={ENDPOINT_SERVICE_CENTER} element={<ServiceCentersPage />}></Route>
                <Route path={ENDPOINT_SERVICE_BOOKING_DETAILS} element={<BookingDetails />}></Route>
                <Route path={ENDPOINT_SERVICE_CENTER_DETAILS} element={<ServiceCenterDetailsPage />}></Route>

                <Route path={ENDPOINT_INVENTORY_ADD} element={<InventoryAdd />}></Route>
                <Route path={ENDPOINT_CUSTOMERS_ADD} element={<CustomerAdd />}></Route>
                <Route path={ENDPOINT_CUSTOMERS} element={<Customers />}></Route>

                <Route path={ENDPOINT_STAFF_ORDER_STATS} element={<StaffOrderStats />}></Route>
                <Route path={ENDPOINT_STAFF_ORDER_STATS_DETAILS} element={<StaffOrderStatsDetails />}></Route>

                <Route path={ENDPOINT_CMS_EDIT_HERO} element={<HeroEditPage />}></Route>
                <Route path={ENDPOINT_CMS_ADD_MECHANIC} element={<MechanicsAddPage />}></Route>
                <Route path={ENDPOINT_CMS_ADD_PARTNER} element={<PartnersAddPage />} ></Route>
                <Route path={ENDPOINT_CMS_ADD_ADVERT} element={<AdvertAddPage />}></Route>
                <Route path={ENDPOINT_CMS_ADD_SHOP} element={<ShopAddPage />}></Route>
                <Route path={ENDPOINT_CMS_ADD_PRODUCT} element={<ProductAddPage />}></Route>
                <Route path={ENDPOINT_CMS_ADD_CATEGORY} element={<CategoriesAddPage />}></Route>
                <Route path={ENDPOINT_CMS_ADD_ACCESSORY} element={<AccessoryAddPage />}></Route>
                <Route path={ENDPOINT_CMS_ADD_BRAND} element={<BrandAddPage />}></Route>
                <Route path={ENDPOINT_CMS_ADD_HERO} element={<HeroAddPage />}></Route>
                <Route path={ENDPOINT_CMS} element={<CMSPage />}></Route>
                <Route path={ENDPOINT_CMS_HERO} element={<HeroPage />}></Route>
                <Route path={ENDPOINT_CMS_ACCESSORY} element={<AccessoryPage />}></Route>
                <Route path={ENDPOINT_CMS_ADVERT} element={<AdvertPage />}></Route>
                <Route path={ENDPOINT_CMS_HERO_DETAILS} element={<HeroViewPage />}></Route>
                <Route path={ENDPOINT_CMS_PRODUCT_DETAILS} element={<ProductViewPage />}></Route>
                <Route path={ENDPOINT_CMS_ACCESSORY_DETAILS} element={<AccessoryViewPage />}></Route>
                <Route path={ENDPOINT_CMS_BRAND_DETAILS} element={<BrandViewPage />}></Route>

                <Route path={ENDPOINT_CUSTOMERS_DETAILS} element={<CustomerLayout />}>
                  <Route path='' element={<ProfileTab />}></Route>
                  <Route path={CUSTOMERS_TAB_ACTIVITY} element={<ActivityModal />}></Route>
                  <Route path={CUSTOMERS_TAB_PROFILE} element={<ProfileTab />}></Route>
                  <Route path={CUSTOMERS_TAB_ORDERS} element={<OrdersTab />}></Route>
                  <Route path={CUSTOMERS_TAB_PAYMENTS} element={<PaymentsTab />}></Route>
                  <Route path={CUSTOMERS_TAB_SERVICES} element={<ServicesTab />}></Route>
                  <Route path={CUSTOMERS_TAB_BOOKINGS} element={<BookingsTab />}></Route>
                  <Route path={CUSTOMERS_TAB_PART_REQUESTS} element={<PartRequestsTab />}></Route>
                  <Route path={CUSTOMERS_TAB_FAVORITES} element={<FavoritesTab />}></Route>
                  <Route path={CUSTOMERS_TAB_ORDER_DETAILS} element={<OrderDetailsTab />}></Route>
                  <Route path={CUSTOMERS_TAB_PART_REQUEST_DETAILS} element={<PartRequestDetailsTab />}></Route>
                  <Route path={CUSTOMERS_TAB_ADD_PART_REQUESTS} element={<AddPartRequestTab />}></Route>
                  <Route path={CUSTOMERS_TAB_MESSAGES} element={<Messages />}></Route>
                </Route>

                <Route path={ENDPOINT_SHORT_URLS} element={<UrlsPage />}></Route>
                <Route path={ENDPOINT_SHORT_URLS_ADD} element={<UrlsAddPage />}></Route>
                <Route path={ENDPOINT_SHORT_URLS_DETAILS} element={<UrlsDetailsPage />}></Route>

                <Route path={ENDPOINT_REVIEWS} element={<ReviewPage />}></Route>
                <Route path={ENDPOINT_REVIEW_DETAILS} element={<ReviewDetailsPage />}></Route>

              </Route>
            </Route>

            <Route path="*" element={<Page404 />} />

          </Routes>

          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.NODE_ENV !== "development") console.log = () => { };