import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import dayjs from "dayjs";
import { defaultDeviceConfig } from './defaultChartConfig'
import { formatNumber, formatNumberStr, makeAuthenticatedRequest } from '../../utils';

function DeviceStats() {
    const [chartOptions, setChartOptions] = useState(defaultDeviceConfig)
    const [series, setSeries] = useState(defaultDeviceConfig.series)
    const [data, setData] = useState([])

    const [dateRange, setDateRange] = useState({
        startDate: dayjs(new Date()).subtract(7, "day").format("YYYY-MM-DD"),
        endDate: dayjs(new Date()).format("YYYY-MM-DD")
    });

    const calcPercentage = (value1, value2) => {
        return parseFloat(formatNumber((value1 / value2) * 100, 2, 2));
    }

    const fetchData = (newValue) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate
        }
        const searchParams = new URLSearchParams(params).toString();
        const url = `/oms/analytics/device/?${searchParams}`


        makeAuthenticatedRequest(url)
            .then((response) => response.json())
            .then((data) => {
                setData(data)
                const total = data?.total
                const tempSeries = [
                    calcPercentage(data?.by_device?.desktop, total),
                    calcPercentage(data?.by_device?.tablet, total),
                    calcPercentage(data?.by_device?.mobile, total),
                    calcPercentage(data?.by_device?.other, total),
                ]
                console.log(tempSeries)
                setSeries(tempSeries)
            });
    }

    useEffect(() => {
        fetchData()
    }, [])



    return (
        <div
            class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm  sm:p-6 ">
            <div class="flex items-center justify-between pb-4 border-b border-gray-200 ">
                <div>
                    <h3 class="text-base font-normal text-gray-500 ">Visitors by</h3>
                    <span class="text-2xl font-bold leading-none text-gray-900 sm:text-3xl ">Device</span>
                </div>
                <a href="#"
                    class="inline-flex items-center p-2 text-xs font-medium uppercase rounded-lg text-primary-700 sm:text-sm hover:bg-gray-100  :bg-gray-700">
                    Full report
                    <svg class="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
                    </svg>
                </a>
            </div>
            <div>
                <Chart
                    type='donut'
                    series={series}
                    options={chartOptions}
                />
            </div>
            <div class="flex items-center justify-between pt-4 lg:justify-evenly sm:pt-6">
                <div>
                    <svg class="w-8 h-8 mb-1 text-gray-500 " fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd"
                            d="M2 4.25A2.25 2.25 0 014.25 2h11.5A2.25 2.25 0 0118 4.25v8.5A2.25 2.25 0 0115.75 15h-3.105a3.501 3.501 0 001.1 1.677A.75.75 0 0113.26 18H6.74a.75.75 0 01-.484-1.323A3.501 3.501 0 007.355 15H4.25A2.25 2.25 0 012 12.75v-8.5zm1.5 0a.75.75 0 01.75-.75h11.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75H4.25a.75.75 0 01-.75-.75v-7.5z">
                        </path>
                    </svg>
                    <h3 class="text-gray-500 ">Desktop</h3>
                    <h4 class="text-xl font-bold ">
                        {formatNumberStr(data?.by_device?.desktop)}
                    </h4>
                    <p class="flex items-center text-sm text-gray-500 ">
                        <span class="flex items-center mr-1.5 text-sm text-green-500 ">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true">
                                <path clip-rule="evenodd" fill-rule="evenodd"
                                    d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z">
                                </path>
                            </svg>
                            4%
                        </span>
                    </p>
                </div>
                <div>
                    <svg class="w-8 h-8 mb-1 text-gray-500 " fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd"
                            d="M5 1a3 3 0 00-3 3v12a3 3 0 003 3h10a3 3 0 003-3V4a3 3 0 00-3-3H5zM3.5 4A1.5 1.5 0 015 2.5h10A1.5 1.5 0 0116.5 4v12a1.5 1.5 0 01-1.5 1.5H5A1.5 1.5 0 013.5 16V4zm5.25 11.5a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z">
                        </path>
                    </svg>
                    <h3 class="text-gray-500 ">Tablet</h3>
                    <h4 class="text-xl font-bold ">
                        {formatNumberStr(data?.by_device?.tablet)}
                    </h4>
                    <p class="flex items-center text-sm text-gray-500 ">
                        <span class="flex items-center mr-1.5 text-sm text-red-600 ">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true">
                                <path clip-rule="evenodd" fill-rule="evenodd"
                                    d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z">
                                </path>
                            </svg>
                            0,6%
                        </span>
                    </p>
                </div>

                <div>
                    <svg class="w-8 h-8 mb-1 text-gray-500 " fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M8 16.25a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z"></path>
                        <path clip-rule="evenodd" fill-rule="evenodd"
                            d="M4 4a3 3 0 013-3h6a3 3 0 013 3v12a3 3 0 01-3 3H7a3 3 0 01-3-3V4zm4-1.5v.75c0 .414.336.75.75.75h2.5a.75.75 0 00.75-.75V2.5h1A1.5 1.5 0 0114.5 4v12a1.5 1.5 0 01-1.5 1.5H7A1.5 1.5 0 015.5 16V4A1.5 1.5 0 017 2.5h1z">
                        </path>
                    </svg>
                    <h3 class="text-gray-500 ">Phone</h3>
                    <h4 class="text-xl font-bold ">
                        {formatNumberStr(data?.by_device?.mobile)}
                    </h4>
                    <p class="flex items-center text-sm text-gray-500 ">
                        <span class="flex items-center mr-1.5 text-sm text-red-600 ">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true">
                                <path clip-rule="evenodd" fill-rule="evenodd"
                                    d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z">
                                </path>
                            </svg>
                            1%
                        </span>
                    </p>
                </div>

                <div>
                    <svg class="w-8 h-8 mb-1 text-gray-500 " fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd"
                            d="M5 1a3 3 0 00-3 3v12a3 3 0 003 3h10a3 3 0 003-3V4a3 3 0 00-3-3H5zM3.5 4A1.5 1.5 0 015 2.5h10A1.5 1.5 0 0116.5 4v12a1.5 1.5 0 01-1.5 1.5H5A1.5 1.5 0 013.5 16V4zm5.25 11.5a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z">
                        </path>
                    </svg>
                    <h3 class="text-gray-500 ">Other</h3>
                    <h4 class="text-xl font-bold ">
                        {formatNumberStr(data?.by_device?.other)}
                    </h4>
                    <p class="flex items-center text-sm text-gray-500 ">
                        <span class="flex items-center mr-1.5 text-sm text-red-600 ">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true">
                                <path clip-rule="evenodd" fill-rule="evenodd"
                                    d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z">
                                </path>
                            </svg>
                            0,6%
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default DeviceStats