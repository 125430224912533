import Datepicker from "react-tailwindcss-datepicker";
import Chart from 'react-apexcharts';
import React, { useEffect, useState } from 'react'
import dayjs from "dayjs";
import { formatNumber, makeAuthenticatedRequest } from "../../utils";
import { buildConfigs, defaultSalesChartConfig, resolveDateRangeToName } from "./defaultChartConfig";


export default function SaleStats() {


  const [data, setData] = useState()
  const configs = buildConfigs()
  const [processing, setProcessing] = useState(true)
  const [dateRangeName, setDateRangeName] = useState("Last 7 Days")
  const [series, setSeries] = useState(defaultSalesChartConfig.series)
  const [chartOptions, setChartOptions] = useState(defaultSalesChartConfig)
  const [dateRange, setDateRange] = useState({
    startDate: dayjs(new Date()).subtract(7, "day").format("YYYY-MM-DD"),
    endDate: dayjs(new Date()).format("YYYY-MM-DD")
  });

  const fetchData = (newValue) => {
    setProcessing(true)
    const params = {
      date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
      date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate
    }
    const searchParams = new URLSearchParams(params).toString();
    const url = `/oms/analytics/sales/?${searchParams}`


    makeAuthenticatedRequest(url)
      .then((response) => response.json())
      .then((data) => {
        setData(data)
        const series = structuredClone(chartOptions.series)
        series[0].data = data?.this_period?.data?.all
        series[1].data = data?.this_period?.data?.b2b
        series[2].data = data?.this_period?.data?.b2c

        setSeries(series)
        setProcessing(false)

        setChartOptions(prev => {
          return { ...prev, xaxis: { ...prev.xaxis, categories: data?.labels } }
        });
      });
  }

  const percentageIncrease = (value1, value2) => {
    const increase = value2 - value1;
    const percentageIncrease = (increase / value1) * 100;
    return percentageIncrease;
  }

  const computeTakeRate = (gross, net) => {
    return net / gross
  }

  const computeTakeRatePercentage = (gross, net) => {
    return computeTakeRate(gross, net) * 100
  }


  const handleDateRangeChange = (newValue) => {
    setDateRangeName(resolveDateRangeToName(newValue, configs?.shortcuts))
    setDateRange(newValue);
    fetchData(newValue)
  }


  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      {processing ?

        <div class="border border-gray-200 rounded-lg shadow-sm shadow rounded-md p-4 w-full mx-auto">
          <div class="animate-pulse flex flex-row">
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
            <div class="h-10 bg-slate-200 rounded h-48 w-1/12 ml-4"></div>
          </div>
        </div>
        :

        <div>
          <header>
            <nav className="bg-white border-gray-200 py-2.5">
              <div className="flex flex-wrap justify-between items-center">
                <div className="flex justify-start items-center font-semibold text-green-500">
                  {dateRangeName}
                </div>
                <div className="flex justify-end">
                  <Datepicker
                    value={dateRange}
                    showFooter={true}
                    onChange={handleDateRangeChange}
                    showShortcuts={true}
                    configs={configs}
                  />
                </div>
              </div>
            </nav>
          </header>

          <div class="flex items-center justify-between mb-4">

            <div class="flex-shrink-0">
              <span class="text-xl font-bold leading-none text-gray-900 sm:text-2xl">Ksh. {formatNumber(data?.this_period?.gross_revenue?.all, 0, 0)}</span>
              <h3 class="text-base font-light text-gray-500 font-bold">{formatNumber(data?.this_period?.count, 0, 0)} sales</h3>
            </div>

            {data?.this_period?.gross_revenue?.all > data?.previous_period?.gross_revenue?.all ?
              <div class="flex items-center justify-end flex-1 text-base font-medium text-green-600">
                {formatNumber(percentageIncrease(data?.previous_period?.gross_revenue?.all, data?.this_period?.gross_revenue?.all), 2, 2)}%
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"></path>
                </svg>
              </div>
              :
              <div class="flex items-center justify-end flex-1 text-base font-medium text-red-600">
                {formatNumber(percentageIncrease(data?.previous_period?.revenue?.all, data?.this_period?.gross_revenue?.all), 2, 2)}%
                <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                </svg>
              </div>
            }


          </div>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-1"></div>
            <div className="col-span-11">
              <Chart
                series={series}
                options={chartOptions}
              />
            </div>
          </div>

          <div>
            <div class="flex items-center justify-between pt-4 lg:justify-evenly sm:pt-6">
              <div>
                <h3 class="text-gray-500">Gross Revenue</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.gross_revenue?.all, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.gross_revenue?.all > data?.previous_period?.gross_revenue?.all ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.gross_revenue?.all, data?.this_period?.gross_revenue?.all), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.gross_revenue?.all, data?.this_period?.gross_revenue?.all), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
              <div>
                <h3 class="text-gray-500">B2B</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.gross_revenue?.b2b, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.gross_revenue?.b2b > data?.previous_period?.gross_revenue?.b2b ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.gross_revenue?.b2b, data?.this_period?.gross_revenue?.b2b), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.gross_revenue?.b2b, data?.this_period?.gross_revenue?.b2b), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
              <div>
                <h3 class="text-gray-500">B2C</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.gross_revenue?.b2c, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.gross_revenue?.b2c > data?.previous_period?.gross_revenue?.b2c ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.gross_revenue?.b2c, data?.this_period?.gross_revenue?.b2c), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.gross_revenue?.b2c, data?.this_period?.gross_revenue?.b2c), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
            </div>

            <div class="flex items-center justify-between pt-4 lg:justify-evenly sm:pt-6">
              <div>
                <h3 class="text-gray-500">Net Revenue</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.net_revenue?.all, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.net_revenue?.all > data?.previous_period?.net_revenue?.all ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.net_revenue?.all, data?.this_period?.net_revenue?.all), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.net_revenue?.all, data?.this_period?.net_revenue?.all), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
              <div>
                <h3 class="text-gray-500">B2B</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.net_revenue?.b2b, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.net_revenue?.b2b > data?.previous_period?.net_revenue?.b2b ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.net_revenue?.b2b, data?.this_period?.net_revenue?.b2b), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.net_revenue?.b2b, data?.this_period?.net_revenue?.b2b), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
              <div>
                <h3 class="text-gray-500">B2C</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.net_revenue?.b2c, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.net_revenue?.b2c > data?.previous_period?.net_revenue?.b2c ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.net_revenue?.b2c, data?.this_period?.net_revenue?.b2c), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.net_revenue?.b2c, data?.this_period?.net_revenue?.b2c), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
            </div>

            <div class="flex items-center justify-between pt-4 lg:justify-evenly sm:pt-6">
              <div>
                <h3 class="text-gray-500">Delivery Fee</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.delivery_cost?.all, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.delivery_cost?.all > data?.previous_period?.delivery_cost?.all ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.delivery_cost?.all, data?.this_period?.delivery_cost?.all), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.delivery_cost?.all, data?.this_period?.delivery_cost?.all), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
              <div>
                <h3 class="text-gray-500">B2B</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.delivery_cost?.b2b, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.delivery_cost?.b2b > data?.previous_period?.delivery_cost?.b2b ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.delivery_cost?.b2b, data?.this_period?.delivery_cost?.b2b), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.delivery_cost?.b2b, data?.this_period?.delivery_cost?.b2b), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
              <div>
                <h3 class="text-gray-500">B2C</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(data?.this_period?.delivery_cost?.b2c, 0, 0)}
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {data?.this_period?.delivery_cost?.b2c > data?.previous_period?.delivery_cost?.b2c ?
                    <span class="flex items-center mr-1.5 text-sm text-green-500">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.delivery_cost?.b2c, data?.this_period?.delivery_cost?.b2c), 2, 2)}%
                    </span>
                    :
                    <span class="flex items-center mr-1.5 text-sm text-red-600">
                      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                      </svg>
                      {formatNumber(percentageIncrease(data?.previous_period?.delivery_cost?.b2c, data?.this_period?.delivery_cost?.b2c), 2, 2)}%
                    </span>
                  }
                </p>
              </div>
            </div>

            <div class="flex items-center justify-between pt-4 lg:justify-evenly sm:pt-6">
              <div>
                <h3 class="text-gray-500">Take Rate</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(computeTakeRatePercentage(data?.this_period?.gross_revenue?.all, data?.this_period?.net_revenue?.all), 2, 2)}%
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {
                    computeTakeRate(data?.this_period?.gross_revenue?.all, data?.this_period?.net_revenue?.all) >
                      computeTakeRate(data?.previous_period?.gross_revenue?.all, data?.previous_period?.net_revenue?.all) ?
                      <span class="flex items-center mr-1.5 text-sm text-green-500">
                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                        </svg>
                        {
                          formatNumber(percentageIncrease(
                            computeTakeRate(data?.previous_period?.gross_revenue?.all, data?.previous_period?.net_revenue?.all),
                            computeTakeRate(data?.this_period?.gross_revenue?.all, data?.this_period?.net_revenue?.all)
                          ), 2, 2)}%
                      </span>
                      :
                      <span class="flex items-center mr-1.5 text-sm text-red-600">
                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                        </svg>
                        {
                          formatNumber(percentageIncrease(
                            computeTakeRate(data?.previous_period?.gross_revenue?.all, data?.previous_period?.net_revenue?.all),
                            computeTakeRate(data?.this_period?.gross_revenue?.all, data?.this_period?.net_revenue?.all)
                          ), 2, 2)}%
                      </span>
                  }
                </p>
              </div>
              <div>
                <h3 class="text-gray-500">B2B</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(computeTakeRatePercentage(data?.this_period?.gross_revenue?.b2b, data?.this_period?.net_revenue?.b2b), 2, 2)}%
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {
                    computeTakeRate(data?.this_period?.gross_revenue?.b2b, data?.this_period?.net_revenue?.b2b) >
                      computeTakeRate(data?.previous_period?.gross_revenue?.b2b, data?.previous_period?.net_revenue?.b2b) ?
                      <span class="flex items-center mr-1.5 text-sm text-green-500">
                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                        </svg>
                        {
                          formatNumber(percentageIncrease(
                            computeTakeRate(data?.previous_period?.gross_revenue?.b2b, data?.previous_period?.net_revenue?.b2b),
                            computeTakeRate(data?.this_period?.gross_revenue?.b2b, data?.this_period?.net_revenue?.b2b)
                          ), 2, 2)}%
                      </span>
                      :
                      <span class="flex items-center mr-1.5 text-sm text-red-600">
                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                        </svg>
                        {
                          formatNumber(percentageIncrease(
                            computeTakeRate(data?.previous_period?.gross_revenue?.b2b, data?.previous_period?.net_revenue?.b2b),
                            computeTakeRate(data?.this_period?.gross_revenue?.b2b, data?.this_period?.net_revenue?.b2b)
                          ), 2, 2)}%
                      </span>
                  }
                </p>
              </div>
              <div>
                <h3 class="text-gray-500">B2C</h3>
                <h4 class="text-xl font-bold">
                  {formatNumber(computeTakeRatePercentage(data?.this_period?.gross_revenue?.b2c, data?.this_period?.net_revenue?.b2c), 2, 2)}%
                </h4>
                <p class="flex items-center text-sm text-gray-500">
                  {
                    computeTakeRate(data?.this_period?.gross_revenue?.b2c, data?.this_period?.net_revenue?.b2c) >
                      computeTakeRate(data?.previous_period?.gross_revenue?.b2c, data?.previous_period?.net_revenue?.b2c) ?
                      <span class="flex items-center mr-1.5 text-sm text-green-500">
                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path clip-rule="evenodd" fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"></path>
                        </svg>
                        {
                          formatNumber(percentageIncrease(
                            computeTakeRate(data?.previous_period?.gross_revenue?.b2c, data?.previous_period?.net_revenue?.b2c),
                            computeTakeRate(data?.this_period?.gross_revenue?.b2c, data?.this_period?.net_revenue?.b2c)
                          ), 2, 2)}%
                      </span>
                      :
                      <span class="flex items-center mr-1.5 text-sm text-red-600">
                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path clip-rule="evenodd" fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z"></path>
                        </svg>
                        {
                          formatNumber(percentageIncrease(
                            computeTakeRate(data?.previous_period?.gross_revenue?.b2c, data?.previous_period?.net_revenue?.b2c),
                            computeTakeRate(data?.this_period?.gross_revenue?.b2c, data?.this_period?.net_revenue?.b2c)
                          ), 2, 2)}%
                      </span>
                  }
                </p>

              </div>
            </div>


          </div>
        </div >
      }
    </>
  )
}