import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../../utils';

const CUSTOMERS_URL = `/oms/customers/`;
export const FLAG = "flag"
export const UNFLAG = "unflag"


function FlagUnflagModal({ customerID, handleModalClose, handleReload, action }) {
    const [processing, setProcessing] = useState(false)
    const [remarks, setRemarks] = useState()

    const submitData = async (e) => {
        e.preventDefault();
        if(!remarks){
            toast.error("You must add a remark")
        }
        setProcessing(true)
        try {
            const params = {
                remarks : remarks,
                is_flagged : action === FLAG
            }
            await axiosCustom.post(`${CUSTOMERS_URL}${customerID}/flagging/`, JSON.stringify(params));
            handleReload();
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
    }, [])

    return (
        <>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div className='min-h-h-screen flex justify-center items-center overflow-x-hidden overflow-y-auto py-10 fixed inset-0 z-[100] outline-none focus:outline-none'>
                        <div class="relative p-4 w-full max-w-2xl max-h-full">
                            <div class="relative bg-white rounded-lg shadow ">
                                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                                    <h3 class="text-xl font-semibold text-gray-900 ">
                                        { action == FLAG ? "Flag" : "Unflag"} User
                                    </h3>
                                    <button
                                        onClick={handleModalClose}
                                        type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white" data-modal-hide="authentication-modal">
                                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div class="p-4 md:p-5">
                                    <form class="space-y-3" action="#" onSubmit={submitData}>
                                        <div>
                                            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 ">Remarks</label>
                                            <textarea
                                                cols="5" rows="4"
                                                type="name"
                                                name="name"
                                                id="name"
                                                value={remarks}
                                                onChange={(e) => setRemarks(e.target.value)}
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5    "
                                                placeholder="Remarks"
                                                required ></textarea>
                                        </div>


                                        <div className="flex py-3 justify-end">
                                                <div className="flex flex-row-reverse gap-4">
                                                    <button                                                 
                                                        onClick={submitData}
                                                        type='submit' 
                                                        className='bg-[#030A1A] text-[16px] text-white border border-[#030A1A] px-3 py-1 rounded-md'>Submit</button>
                                                </div>
                                            </div>

                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-70  fixed inset-0 z-50' />
                </section>
            }
        </>
    )
}
export default FlagUnflagModal