import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { formatDateAndTimeAgo, formatNumber, makeAuthenticatedRequest } from '../utils';
import { Pagination } from './Pagination';
import { BsDot } from 'react-icons/bs';
import { ENDPOINT_GRNS, ENDPOINT_LPOS, ENDPOINT_RFQS } from '../routes'

const GrnsData = () => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
    const [currentPage, setCurrentPage] = useState(currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)
    const [grns, setGRNs] = useState([])
    const [perPage, setPerPage] = useState(20)
    const[pages, setPages] = useState(0)
    const [itemCount, setItemCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [processing, setProcessing] = useState(false)
  
    const fetchGRNs = (page) => {
        setProcessing(true)
      const params = {
        "s": search,
        "page": page,
        "per_page": perPage,
      }
      const searchParams = new URLSearchParams(params).toString();
      makeAuthenticatedRequest(`/oms/grns/?${searchParams}`)
        .then((response) => response.json())
        .then((data) => {
          console.log('grns data',data)
          setGRNs(data)
          setCurrentPage(data?.page)
          setPerPage(data?.per_page)
          setPages(data?.number_of_pages)
          setItemCount(data?.count)
          setTotalItems(data?.count)
          setProcessing(false)
        }).catch((error) => {
          setProcessing(false)
          console.log(error)
    })
    }
    
    const pageChanged = (pageNumber) => {
      // console.log('called')
      currentUrlParams.set('page', pageNumber)
      setCurrentPage(Number(currentUrlParams.get('page')))
      setUrlParams(currentUrlParams)    
      fetchGRNs(Number(currentUrlParams.get('page')))
    }
    
    useEffect(() => {
      fetchGRNs(currentPage)
    }, [])
    const totalSum = grns?.results?.reduce((sum, grn) => {
        const lpoPartSum = grn?.grn_parts.reduce((partSum, lpoPart) => partSum + parseFloat(lpoPart.total), 0);
        return sum + lpoPartSum;
      }, 0);

      const calculateOrderSum = (order) => {
        return order.grn_parts.reduce((sum, part) => sum + parseFloat(part.total), 0);
      };

    //   console.log('grns',grns)
  return (
    <>
    {
  grns?.results?.length >0 && 
    <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
      <table class="table-auto w-full  ">
        <thead className='pl-2'>
          <tr className='pl-1 border-b border-gray-200'>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>GRN #</th>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Parts count</th>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>GRN value</th>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Date created</th>
          </tr>
        </thead>
        <tbody>
          {
            grns?.results?.slice().reverse().map((order) => (
              <tr className='p-2 border-b border-b-gray-200 relative'>
                <td className='pl-2'>
                  <Link to={`${ENDPOINT_GRNS}/${order?.id}`}>
                  #{order?.id}
                  </Link>
                </td>
                <td
                  className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                    <Link to={`${ENDPOINT_GRNS}/${order?.id}`}>

                 {order?.grn_parts?.length}
                    </Link>
                </td>
                <td className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                <Link to={`${ENDPOINT_GRNS}/${order?.id}`}>
                Ksh. {formatNumber(calculateOrderSum(order), 0, 0)}
                </Link>
                  </td>
                <td className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                <Link to={`${ENDPOINT_GRNS}/${order?.id}`}>
                 {formatDateAndTimeAgo(order?.date_created)}
                 </Link>
                </td>

              </tr>


            ))}



        </tbody>
      </table>
        {/* pagination */}
      
    </div>
}
</>
  )
}

export default GrnsData