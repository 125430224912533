import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ENDPOINT_ENTER_EMAIL } from '../routes';
import { clearAccountInfo, makeAuthenticatedRequest } from '../utils';


function AccountPage() {
    const navigate = useNavigate()

    const fetchApi = () => {
        makeAuthenticatedRequest(`/oms/analytics/`)
            .then((response) => response.json())
            .then((data) => {
            });
    }

    const logout = () => {
        clearAccountInfo()
        navigate(ENDPOINT_ENTER_EMAIL)
    }

    useEffect(() => {
        fetchApi();
    }, [])

    return (

        <>
            <div className='h-24'></div>
            <div className='flex w-full flex-col gap-2 py-4 md:py-8 px-4 md:px-20'>

                <p className='font-satoshi text-xl font-semibold'>Your Account</p>
                <div className='flex w-full flex-col gap-4 '>

                    <div className="flex md:grid flex-col md:grid-cols-2 gap-3">

                        <div className="flex flex-col px-4 py-2 col-span-1  gap-3 border border-[#E5E6E9] rounded-md">
                            <div className="flex flex-row justify-between">
                                <div className="flex flex-row gap-1">
                                    <p className='text-[#19223B] text-xl font-satoshi font-semibold'></p>
                                </div>
                            </div>
                            <div className="flex flex-col mt-4 mb-4">
                                <div
                                    onClick={logout}
                                    className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi text-[#808491] bg-[#F8DDDF]`}>
                                    <span className='p-1 ml-2 text-[#b0b6c7]  md:text-[16px] rounded'>Log out</span>
                                </div>

                            </div>
                        </div>
                    </div>




                </div>
            </div>

        </>

    )
}

export default AccountPage