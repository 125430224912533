import React from 'react'
import Partner from "../components/cms/Partner/Partner"

function PartnersPage() {
  return (
    <div>
      <Partner/>
      
    </div>
  )
}

export default PartnersPage
