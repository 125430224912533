import React from 'react'
import Advert from "../components/cms/Advert/Advert"
function AdvertPage() {
  return (
    <div>
      <Advert/>
      
    </div>
  )
}

export default AdvertPage
