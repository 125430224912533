import { toast } from "react-toastify";
import { makeAuthenticatedRequest } from "../../utils";

export class Reviews {
  constructor() {}

  async index(
    page = 1,
    perPage = 16,
    orderBy = "date_created",
    order = "desc"
  ) {
    try {
      const params = {
        page: page,
        per_page: perPage,
        order_by: orderBy,
        order: order,
      };

      const q = new URLSearchParams(params).toString();
      const response = await makeAuthenticatedRequest(`/oms/reviews/?${q}`);
      const data = await response.json();
      return data;
    } catch (error) {
      toast.error("An error occurred!");
    }
  }

  async update(id, data) {
    try {
      await makeAuthenticatedRequest(
        `/oms/reviews/${id}/`,
        "PUT",
        JSON.stringify(data)
      );

      return;
    } catch (error) {
      toast.error("An error occurred!");
      console.log(error);
    }
  }
}
