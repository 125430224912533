import React from 'react'
import { BsStarFill, BsStar } from "react-icons/bs"

const HomeRating = ({ rating, reviews }) => {
    const convertedRating = Math.floor(rating)
    const normalizedRating = Math.min(Math.max(convertedRating, 1), 5);

    // Create an array of filled star elements based on the rating
    const filledStars = Array.from({ length: normalizedRating }).map((_, index) => (
        <BsStarFill size={15} />
    ));

    // Create an array of empty star elements for the remaining stars
    const emptyStars = Array.from({ length: 5 - normalizedRating }).map((_, index) => (
        <BsStar size={15} />
    ));
    return (
        <div className='flex flex-row gap-1 items-center'>
            {filledStars}
            {emptyStars}
            {reviews &&
                <p className='text-gray-500 text-[14px]'>({reviews})</p>
            }
        </div>
    )
}

export default HomeRating