import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../Pagination";
import { ENDPOINT_CMS_ADD_PRODUCT, ENDPOINT_CMS_PRODUCT } from "../../../routes";
import { getProductSubsections } from "./ProductUtils";

function Hero() {
  const navigate = useNavigate();
  const [productSubsections, setProductSubsections] = useState([]);

  useEffect(() => {
    getProductSubsections()
      .then((res) => {
        setProductSubsections(res);
      })
  }, [])

  // Function to handle viewing detailed information
  const handleView = (id) => {
    // You can navigate to the detailed view page passing the ID or other necessary data
    navigate(`/hero/${id}`);
  };

  return (
    <>
      <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-12 md:pt-20 pb-10">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-4 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
        </div>
        <div>
          {/* buttons  */}
          <div className="flex flex-row rounded  w-fit float-right gap-2">
            <Link
              to={ENDPOINT_CMS_ADD_PRODUCT}
              className="outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="text-white w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add New Product Subsection
            </Link>
          </div>
        </div>


        <input
          type="text"
          name="s"
          id="simple-search"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    "
          placeholder="Search a Product "
        />



        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
              <table class="table-auto w-full">
                <thead className="pl-1 md:pl-2 table-auto rounded-lg">
                  <tr className="pl-1 md:pl-2 rounded-lg">


                    <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                      <span>
                        <span className="inline pr-1">Name</span>
                      </span>
                    </th>
                    <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                      <span>
                        <span className="inline pr-2">Products</span>
                      </span>
                    </th>

                    <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                      <span>
                        <span className="inline pr-2">Status</span>
                      </span>
                    </th>

                    <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                      <span>
                        <span className="inline pr-2">Order</span>
                      </span>
                    </th>

                    <th className="pl-2 pb-1 font-satoshi cursor-pointer text-xs relative md:text-sm tracking-wide text-left text-gray-400">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    productSubsections.map((subsection) => (
                      <tr
                        key={subsection.id}
                        className="p-2 border border-b-gray-200 relative"
                      >
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                          <p className="font-satoshi  text-[14px]">{subsection.title}</p>
                        </td>
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                          {/* <p className="font-satoshi  text-[14px]">{subsection.tags.join(', ')}</p> */}
                        </td>
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                          <p className="font-satoshi  text-[14px]">{subsection.is_active ? "Active" : "Inactive"}</p>
                        </td>

                        <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                          <p className="font-satoshi  text-[14px]">{subsection.order}</p>
                        </td>
                        <td className='p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]'>
                          <Link
                            to={`${ENDPOINT_CMS_PRODUCT}/${subsection.id}`}
                            className="bg-blue-500 text-white px-2 py-1 rounded-md"
                          >
                            View

                          </Link>
                        </td>

                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <Pagination
                pageChanged="true"
                totalItems="20"
                itemsPerPage="1"
                currentPage="1"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
