import { axiosCustom } from '../../../utils';
import { makeAuthenticatedRequest } from '../../../utils'

const CMS_HERO_URL = '/oms/cms/homepage-brand-subsections/';
const BRANDS_URL = `/car/part/brands/`;


export const createBrandSubsection = async (brandData) => {
    try {
        const response = await axiosCustom.post(CMS_HERO_URL, brandData);
        return response.data;
    } catch (error) {
        console.log("Error creating brand subsection:", error);
    }
};

export const getBrandSubsections = async () => {
    try {
        const response = await axiosCustom.get(CMS_HERO_URL);
        return response.data.results;
    } catch (error) {
        console.log("Error fetching brand subsections:", error);
    }
};

export const updateBrandSubsection = async (brandSubsectionId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_HERO_URL}${brandSubsectionId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("Error updating brand subsection:", error);
    }
};

export const deleteBrandSubsection = async (brandSubsectionId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_HERO_URL}${brandSubsectionId}/`);
        return response.data;
    } catch (error) {
        console.log("Error deleting hero:", error);
    }
};

export const getBrands = async () => {
    try {
        const data = await makeAuthenticatedRequest(BRANDS_URL)
        return data.json();
    } catch (error) {
        console.log("Error fetching brands:", error);
    }
}
