import React, { useEffect, useState, useMemo } from "react";
import HitsOverTimeGraph from "./HitsOverTimeGraph";
import Datepicker from "react-tailwindcss-datepicker";
import { getCreatedLink, getAnalytics, getLastPartOfUrl } from "./urlsUtils";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

function UrlsDetails() {
  const navigate = useNavigate();
  const { urlID } = useParams();
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState({});
  const [analytics, setAnalytics] = useState({});

  const fetchData = (urlID) => {
    setProcessing(true);
    getCreatedLink(urlID)
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const fetchAnalytics = async (shortLink) => {
    const response_data = await getAnalytics(shortLink);
    setAnalytics(response_data);
  };

  const graphHits = useMemo(() => {
    return analytics?.hits_over_time_graph?.map((item) => {
      return item?.hits;
    });
  }, [analytics]);

  const graphLabels = useMemo(() => {
    return analytics?.hits_over_time_graph?.map((item) => {
      return item?.label;
    });
  }, [analytics]);

  useEffect(() => {
    if (data?.short_link) {
      const code = getLastPartOfUrl(data?.short_link);
      fetchAnalytics(code);
    }
  }, [data]);

  useEffect(() => {
    fetchData(urlID);
  }, []);

  return (
    <>
      {processing && (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img
            src="/cdn/images/loading.gif"
            className="mr-3 cursor-pointer  h-24"
            alt="Sparepap Logo"
          />
          <h2 class="text-center text-white text-xl font-semibold">
            Processing...
          </h2>
        </div>
      )}

      <div className="px-4 md:px-20 flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
        {/* <p className="font-satoshi text-xl font-semibold mb-10">Analytics</p> */}

        {/* <div className='w-full'>
                    <div
                        className='w-60 float-right'>
                        <Datepicker
                            // value={dateRange}
                            // onChange={handleDateRangeChange}
                            showShortcuts={true}
                        />
                    </div>
                </div> */}

        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-4 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="mt-2 mb-2">
            <p className="font-satoshi font-semibold text-[18px] text-[#666C7C]">
              {`${data?.short_link} --> ${data?.full_link}`}
            </p>
          </div>
          <div className="mt-2 mb-4">
            <div className="flex flex-col md:flex-row gap-4">
              <div className="mt-1 mb-1 w-auto md:w-full  border border-gray-200 rounded-md p-2">
                <p className="block text-[14px] mb-4 font-satoshi text-gray-700">
                  Hits
                </p>
                <div className="">
                  <p className="block text-[20px] text-[#808491] font-semibold font-satoshi ">
                    <span className="text-[#19223B] mr-1 font-semibold text-sm md:text-[32px]">
                      {analytics?.hits | "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mt-1 mb-1 w-auto md:w-full  border border-gray-200 rounded-md p-2">
                <p className="block text-[14px] mb-4 font-satoshi text-gray-700">
                  Successful Hits
                </p>
                <div className="">
                  <p className="block text-xs md:text-[20px] text-[#808491] font-semibold font-satoshi ">
                    <span className="text-[#19223B] mr-1 font-semibold text-sm md:text-[32px]">
                      {analytics?.successful_hits | "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="mt-1 mb-1 w-auto md:w-full  border border-gray-200 rounded-md p-2">
                <p className="block text-[14px] mb-4 font-satoshi text-gray-700">
                  Missed Hits
                </p>
                <div className="">
                  <p className="block text-xs md:text-[20px] text-[#808491] font-semibold font-satoshi ">
                    <span className="text-[#19223B] mr-1 font-semibold text-sm md:text-[32px]">
                      {analytics?.missed_hits | "-"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-2 mb-4 border border-gray-200 rounded-md mt-1 mb-1 h-480">
            <HitsOverTimeGraph hits={graphHits} labels={graphLabels} />
          </div>
        </div>
      </div>
    </>
  );
}

export default UrlsDetails;
