import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Pagination } from '../components/Pagination'
import { ENDPOINT_INVENTORY, ENDPOINT_PART_REQUESTS } from '../routes'
import { formatDate, makeAuthenticatedRequest } from '../utils'
import { toast, ToastContainer } from 'react-toastify'


const NEW_TAB = "New"
const ALL_TAB = 'All'
const PLACED_TAB = 'Placed'
const ACCEPTED_TAB =  'Accepted'
const FULFILLED_TAB = 'Fulfilled'
const CANCELLED_TAB = 'Cancelled'

function PartRequestsPage() {

  let currentUrlParams = new URLSearchParams(window.location.search);
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)

  const [currentPage, setCurrentPage] = useState(currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)


  const [partRequests, setPartRequests] = useState([])
  const [search, setSearch] = useState('')
  const [currentTab, setCurrentTab] = useState(currentUrlParams.get('tab') === 'New' ? NEW_TAB:
                                              currentUrlParams.get('tab') === 'Placed' ? PLACED_TAB:
                                              currentUrlParams.get('tab') === 'Accepted' ? ACCEPTED_TAB:
                                              currentUrlParams.get('tab') === 'Fulfilled' ? FULFILLED_TAB:
                                              currentUrlParams.get('tab') === 'Cancelled' ? CANCELLED_TAB:
                                              currentUrlParams.get('tab') === 'All' ? ALL_TAB:
                                              NEW_TAB
                                              )


  console.log('CUrrenTabUrl', currentUrlParams.get('tab'))                                            

  const [perPage, setPerPage] = useState(16)
  const [itemCount, setItemCount] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [showStatus, setShowStatus] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [open, setOPen] = useState([])

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  useEffect(() => {
    window.addEventListener("resize", () => {
      const ismobile = window.innerWidth < 1200;
      if (ismobile !== isMobile) setIsMobile(ismobile);
    }, false);
  }, [isMobile]);

   
  const [statuses, setStatuses] = useState([
    {id:1, displayName:'Outsourcing', name:'Placed', bgColor:'[#19223B]', textColor:'white'},
    {id:2, displayName:'Accepted', name:'Accepted', bgColor:'blue-500', textColor:'white'}, 
    {id:3, displayName:'Fulfilled', name:'Fulfilled', bgColor:'green-500', textColor:'white'}, 
    {id:4, displayName:'Closed', name:'Cancelled', bgColor:'red-500', textColor:'white'}])
  
  
    // const filteredStatuses = statuses.filter(status => status.name !== request?.status);

    const handleShowRequestChange = requestID => {
      const openState = open.slice()
      const index = openState.indexOf(requestID)
  
      if (index >= 0) {
        openState.splice(index, 1)
        setOPen(openState)
      } else {
        openState.push(requestID)
        setOPen(openState)
      }
    }
  
    const cancelRef = useRef()
    useEffect(() => {
      let handler = e => {
        if (cancelRef.current && !cancelRef.current.contains(e.target)) {
  
          setOPen([])
  
          console.log(cancelRef.current)
        }
      }
      document.addEventListener('mousedown', handler);
  
      return () => {
        document.removeEventListener('mousedown', handler);
      }
  
    })
  
  


  const fetchPartRequests = (page) => {
    const params = {
      "s": search,
      "page": page,
      "per_page": perPage,
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/part_requests/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setPartRequests(data)
        // setCurrentPage(data.page)
        setPerPage(data.per_page)
        setItemCount(data.count)
        setTotalItems(data.count)

      });
  }

  const switchTab = (tabName) => {
    currentUrlParams.set('tab', `${tabName}`)
    currentUrlParams.set('page', currentPage)
    setCurrentPage(Number(currentUrlParams.get('page')))
    setUrlParams(currentUrlParams)
    setCurrentTab(tabName)

  }

  const pageChanged = (pageNumber) => {
    currentUrlParams.set('page', pageNumber)
    setCurrentPage(Number(currentUrlParams.get('page')))
    setUrlParams(currentUrlParams)  
    fetchPartRequests(currentPage)
  }

  useEffect(() => {
    fetchPartRequests(currentPage)
  }, [currentPage])

  const updatePartRequestStatus = (newStatus, partRequestID) => {
    const params = {
        status: newStatus //'Placed', 'Accepted', 'Fulfilled', 'Cancelled'
    }
    makeAuthenticatedRequest(`/oms/part_requests/${partRequestID}/`, "POST", JSON.stringify(params))
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        toast.success("Part request succesfully updated")
        setShowStatus(false)
        setOPen([])
        fetchPartRequests(1)
      }).catch((err) => {
        console.log('err', err)
        toast.error("Something went wrong")
     }).finally(() => {
        setProcessing(false);
     });
  }


  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000)

  const placedCount = partRequests?.results?.filter((placedOrder) => placedOrder.status === "Placed").length
  const acceptedCount = partRequests?.results?.filter((acceptedOrder) => acceptedOrder.status === 'Accepted').length
  const fullfilledCount = partRequests?.results?.filter((fullfilledOrder) => fullfilledOrder.status === 'Fulfilled').length
  const cancelledCount = partRequests?.results?.filter((cancelledOrder) => cancelledOrder.status === 'Cancelled').length
  const newCount = partRequests?.results?.filter((newOrder) => new Date(newOrder.date_created) > twentyFourHoursAgo).length



  

 

  return (
    <div className={` ${isMobile? 'max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar':''} flex w-full flex-col h-full  gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20`}>
      <p className='font-satoshi text-xl font-semibold'>Part Requests</p>


      <div className="flex flex-col">
      <div className="flex flex-row gap-2 mt-4 overflow-x-scroll scrollbar-hide">
      <div
              onClick={() => switchTab(NEW_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === NEW_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}> New
              <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{newCount}</span>
            </div>
      <div
              onClick={() => switchTab(PLACED_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === PLACED_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Outsourcing
              <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{placedCount}</span>
            </div>

        
            <div
              onClick={() => switchTab(ACCEPTED_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === ACCEPTED_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Accepted
              <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{acceptedCount}</span>
            </div>
            <div
              onClick={() => switchTab(FULFILLED_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === FULFILLED_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Fulfilled
              <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{fullfilledCount}</span>
            </div>
            <div
              onClick={() => switchTab(CANCELLED_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === CANCELLED_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Closed
              <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{cancelledCount}</span>
            </div>
            <div
              onClick={() => switchTab(ALL_TAB)}
              className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === ALL_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}> All
              <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{totalItems}</span>
            </div>

          </div>
        <div className="flex flex-col ">
          
              <div className={` ${isMobile? 'border  rounded-lg overflow-x-scroll hide-scrollbar':''} pt-2 w-full    mt-4`}>
                <table class="table-auto w-full  ">
                  <thead className='pl-2 border-b border-b-gray-200'>
                    <tr className='pl-1'>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                      <input type="checkbox" className='accent:gary-300' />
                      </th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Request #</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Part name</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Customer</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Part no</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Vin no</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Date</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Status</th>


                    </tr>
                  </thead>
                  <tbody>
                    {currentTab === ALL_TAB && (
                      <>
                      {partRequests?.results?.map((request) => (

                          <tr className='p-2 border-b border-b-gray-200 relative'>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                            
                          </td>
                          <Link to={`${ENDPOINT_PART_REQUESTS}/${request.id}`}>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="mt-0.5 w-4 h-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>

                              <p className='font-satoshi text-left text-[#030A1A] capitalize'>SP-{request.id}</p>

                          </td>
                          </Link>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>
                                {request.name}
                              </p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.passport?.name?request.passport?.name:request.passport?.first_name}</p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.part_number? request.part_number: '-'}</p>
                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.vin_number}</p>

                          </td>
                        
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[14px] capitalize'>
                                {formatDate(request.date_created)}
                              </p>

                          </td>

                          <td>

                              <div className="flex flex-col gap-2 relative w-full">
                              <div  onClick={() => handleShowRequestChange(request.id)} className={`
                              ${request.status === 'Placed'? 'bg-[#19223B]' 
                              :request.status === 'Accepted'? 'bg-blue-500'
                              :request.status === 'Fulfilled'? 'bg-green-500'
                              :request.status === 'Cancelled'? 'bg-red-500'
                              :null
                              } 
                              ${request.status === 'Placed'? 'text-white' 
                              :request.status === 'Accepted'? 'text-white'
                              :request.status === 'Fulfilled'? 'text-white'
                              :request.status === 'Cancelled'? 'text-white'
                              :null
                              }     
                              px-2 py-1 cursor-pointer rounded-lg flex flex-row gap-2  justify-between`}>

                              <p className='font-satoshi text-[18px]'>{request.status === 'Placed' ? 'Outsourcing': request.status === 'Cancelled' ? 'Closed' : request.status}</p>
                              {open.includes(request.id) ? 
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                              </svg>

                              :
                              <svg
                              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1 ">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                              </svg>
                              }

                              </div>
                              {open.includes(request.id) && (
                              <div ref={cancelRef}  className="flex flex-col gap-2 absolute top-10 z-50 bg-white shadow-2xl rounded-lg px-2 py-2 w-full">
                              {statuses.filter((statuss) => statuss.name !== request.status).map((status) => (

                              <div 
                              onClick={() => updatePartRequestStatus(status.name, request.id)}
                              className={`bg-${status.bgColor} cursor-pointer px-2 py-1 rounded-lg flex flex-row gap-2  justify-between`}>
                              <p className={`font-satoshi text-[18px] text-${status.textColor}`}>{status.displayName}</p>
                              </div>
                              ))}
                              </div>
                              )}
                              </div>
                              </td>
                        </tr>
                      ))}
                      </>

                    )}
                     {currentTab === NEW_TAB && (
                    //  {newCount !== 0 ?
                      <>
                       
                        {partRequests?.results?.filter((newOrder) => new Date(newOrder.date_created) > twentyFourHoursAgo).map((request) => (

                          <tr className='p-2 border-b border-b-gray-200 relative'>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                            
                          </td>
                          <Link to={`${ENDPOINT_PART_REQUESTS}/${request.id}`}>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="mt-0.5 w-4 h-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>

                              <p className='font-satoshi text-left text-[#030A1A] capitalize'>SP-{request.id}</p>

                          </td>
                          </Link>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>
                                {request.name}
                              </p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.passport?.name?request.passport?.name:request.passport?.first_name}</p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.part_number? request.part_number: '-'}</p>
                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.vin_number}</p>

                          </td>
                        
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[14px] capitalize'>
                                {formatDate(request.date_created)}
                              </p>

                          </td>
                          <td>

                          <div className="flex flex-col gap-2 relative w-full">
                    <div  onClick={() => handleShowRequestChange(request.id)} className={`
                    ${request.status === 'Placed'? 'bg-[#19223B]' 
                        :request.status === 'Accepted'? 'bg-blue-500'
                        :request.status === 'Fulfilled'? 'bg-green-500'
                        :request.status === 'Cancelled'? 'bg-red-500'
                        :null
                        } 
                    ${request.status === 'Placed'? 'text-white' 
                    :request.status === 'Accepted'? 'text-white'
                    :request.status === 'Fulfilled'? 'text-white'
                    :request.status === 'Cancelled'? 'text-white'
                    :null
                    }     
                    px-2 py-1 cursor-pointer rounded-lg flex flex-row gap-2  justify-between`}>

                    <p className='font-satoshi text-[18px]'>{request.status === 'Placed' ? 'Outsourcing': request.status === 'Cancelled' ? 'Closed' : request.status}</p>
                    {open.includes(request.id) ? 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                  </svg>
                  
                    :
                    <svg
                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1 ">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>
                    }

                    </div>
                    {open.includes(request.id) && (
                    <div ref={cancelRef}  className="flex flex-col gap-2 absolute top-10 z-50 bg-white shadow-2xl rounded-lg px-2 py-2 w-full">
                        {statuses.filter((statuss) => statuss.name !== request.status).map((status) => (

                        <div 
                        onClick={() => updatePartRequestStatus(status.name, request.id)}
                        className={`bg-${status.bgColor} cursor-pointer px-2 py-1 rounded-lg flex flex-row gap-2  justify-between`}>
                        <p className={`font-satoshi text-[18px] text-${status.textColor}`}>{status.displayName}</p>
                        </div>
                        ))}
                    </div>
                    )}
                </div>
                          </td>
                        </tr>
                      ))}
                      </>
                      // :
                      // <p className='font-satoshi text-[18px]'>No new part request</p>
                      // }

                    )}

                    {currentTab === PLACED_TAB && (
                      <>
                      {partRequests?.results?.filter((placedOrder) => placedOrder.status === "Placed").map((request) => (

                          <tr className='p-2 border-b border-b-gray-200 relative'>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                            
                          </td>
                          <Link to={`${ENDPOINT_PART_REQUESTS}/${request.id}`}>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="mt-0.5 w-4 h-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>

                              <p className='font-satoshi text-left text-[#030A1A] capitalize'>SP-{request.id}</p>

                          </td>
                          </Link>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>
                                {request.name}
                              </p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.passport?.name?request.passport?.name:request.passport?.first_name}</p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.part_number? request.part_number: '-'}</p>
                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.vin_number}</p>

                          </td>
                        
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[14px] capitalize'>
                                {formatDate(request.date_created)}
                              </p>

                          </td>
                          <td>

                            <div className="flex flex-col gap-2 relative w-full">
                            <div  onClick={() => handleShowRequestChange(request.id)} className={`
                            ${request.status === 'Placed'? 'bg-[#19223B]' 
                            :request.status === 'Accepted'? 'bg-blue-500'
                            :request.status === 'Fulfilled'? 'bg-green-500'
                            :request.status === 'Cancelled'? 'bg-red-500'
                            :null
                            } 
                            ${request.status === 'Placed'? 'text-white' 
                            :request.status === 'Accepted'? 'text-white'
                            :request.status === 'Fulfilled'? 'text-white'
                            :request.status === 'Cancelled'? 'text-white'
                            :null
                            }     
                            px-2 py-1 cursor-pointer rounded-lg flex flex-row gap-2  justify-between`}>

                            <p className='font-satoshi text-[18px]'>{request.status === 'Placed' ? 'Outsourcing': request.status === 'Cancelled' ? 'Closed' : request.status}</p>
                            {open.includes(request.id) ? 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                            </svg>

                            :
                            <svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1 ">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                            }

                            </div>
                            {open.includes(request.id) && (
                            <div ref={cancelRef}  className="flex flex-col gap-2 absolute top-10 z-50 bg-white shadow-2xl rounded-lg px-2 py-2 w-full">
                            {statuses.filter((statuss) => statuss.name !== request.status).map((status) => (

                            <div 
                            onClick={() => updatePartRequestStatus(status.name, request.id)}
                            className={`bg-${status.bgColor} cursor-pointer px-2 py-1 rounded-lg flex flex-row gap-2  justify-between`}>
                            <p className={`font-satoshi text-[18px] text-${status.textColor}`}>{status.displayName}</p>
                            </div>
                            ))}
                            </div>
                            )}
                            </div>
                            </td>
                        </tr>
                      ))}
                      </>

                    )}

                  {currentTab === ACCEPTED_TAB && (
                      <>
                      {partRequests?.results?.filter((acceptedOrder) => acceptedOrder.status === 'Accepted').map((request) => (

                          <tr className='p-2 border-b border-b-gray-200 relative'>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                            
                          </td>
                          <Link to={`${ENDPOINT_PART_REQUESTS}/${request.id}`}>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="mt-0.5 w-4 h-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>

                              <p className='font-satoshi text-left text-[#030A1A] capitalize'>SP-{request.id}</p>

                          </td>
                          </Link>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>
                                {request.name}
                              </p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.passport?.name?request.passport?.name:request.passport?.first_name}</p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.part_number? request.part_number: '-'}</p>
                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.vin_number}</p>

                          </td>
                        
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[14px]'>
                                {formatDate(request.date_created)}
                              </p>

                          </td>

                          <td>

                              <div className="flex flex-col gap-2 relative w-full">
                              <div  onClick={() => handleShowRequestChange(request.id)} className={`
                              ${request.status === 'Placed'? 'bg-[#19223B]' 
                              :request.status === 'Accepted'? 'bg-blue-500'
                              :request.status === 'Fulfilled'? 'bg-green-500'
                              :request.status === 'Cancelled'? 'bg-red-500'
                              :null
                              } 
                              ${request.status === 'Placed'? 'text-white' 
                              :request.status === 'Accepted'? 'text-white'
                              :request.status === 'Fulfilled'? 'text-white'
                              :request.status === 'Cancelled'? 'text-white'
                              :null
                              }     
                              px-2 py-1 cursor-pointer rounded-lg flex flex-row gap-2  justify-between`}>

                              <p className='font-satoshi text-[18px]'>{request.status === 'Placed' ? 'Outsourcing': request.status === 'Cancelled' ? 'Closed' : request.status}</p>
                              {open.includes(request.id) ? 
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                              </svg>

                              :
                              <svg
                              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1 ">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                              </svg>
                              }

                              </div>
                              {open.includes(request.id) && (
                              <div ref={cancelRef}  className="flex flex-col gap-2 absolute top-10 z-50 bg-white shadow-2xl rounded-lg px-2 py-2 w-full">
                              {statuses.filter((statuss) => statuss.name !== request.status).map((status) => (

                              <div 
                              onClick={() => updatePartRequestStatus(status.name, request.id)}
                              className={`bg-${status.bgColor} cursor-pointer px-2 py-1 rounded-lg flex flex-row gap-2  justify-between`}>
                              <p className={`font-satoshi text-[18px] text-${status.textColor}`}>{status.displayName}</p>
                              </div>
                              ))}
                              </div>
                              )}
                              </div>
                              </td>
                        </tr>
                      ))}
                      </>

                    )}

              {currentTab === FULFILLED_TAB && (
                      <>
                      {partRequests?.results?.filter((fullfiledOrder) => fullfiledOrder.status === 'Fulfilled').map((request) => (

                          <tr className='p-2 border-b border-b-gray-200 relative'>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                            
                          </td>
                          <Link to={`${ENDPOINT_PART_REQUESTS}/${request.id}`}>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="mt-0.5 w-4 h-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>

                              <p className='font-satoshi text-left text-[#030A1A] capitalize'>SP-{request.id}</p>

                          </td>
                          </Link>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>
                                {request.name}
                              </p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.passport?.name?request.passport?.name:request.passport?.first_name}</p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.part_number? request.part_number: '-'}</p>
                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.vin_number}</p>

                          </td>
                        
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[14px]'>
                                {formatDate(request.date_created)}
                              </p>

                          </td>
                          <td>

                            <div className="flex flex-col gap-2 relative w-full">
                            <div  onClick={() => handleShowRequestChange(request.id)} className={`
                            ${request.status === 'Placed'? 'bg-[#19223B]' 
                            :request.status === 'Accepted'? 'bg-blue-500'
                            :request.status === 'Fulfilled'? 'bg-green-500'
                            :request.status === 'Cancelled'? 'bg-red-500'
                            :null
                            } 
                            ${request.status === 'Placed'? 'text-white' 
                            :request.status === 'Accepted'? 'text-white'
                            :request.status === 'Fulfilled'? 'text-white'
                            :request.status === 'Cancelled'? 'text-white'
                            :null
                            }     
                            px-2 py-1 cursor-pointer rounded-lg flex flex-row gap-2  justify-between`}>

                            <p className='font-satoshi text-[18px]'>{request.status === 'Placed' ? 'Outsourcing': request.status === 'Cancelled' ? 'Closed' : request.status}</p>
                            {open.includes(request.id) ? 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                            </svg>

                            :
                            <svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1 ">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                            }

                            </div>
                            {open.includes(request.id) && (
                            <div ref={cancelRef}  className="flex flex-col gap-2 absolute top-10 z-50 bg-white shadow-2xl rounded-lg px-2 py-2 w-full">
                            {statuses.filter((statuss) => statuss.name !== request.status).map((status) => (

                            <div 
                            onClick={() => updatePartRequestStatus(status.name, request.id)}
                            className={`bg-${status.bgColor} cursor-pointer px-2 py-1 rounded-lg flex flex-row gap-2  justify-between`}>
                            <p className={`font-satoshi text-[18px] text-${status.textColor}`}>{status.displayName}</p>
                            </div>
                            ))}
                            </div>
                            )}
                            </div>
                            </td>
                        </tr>
                      ))}
                      </>

                    )}

                {currentTab === CANCELLED_TAB && (
                      <>
                      {partRequests?.results?.filter((cancelledOrder) => cancelledOrder.status === 'Cancelled').map((request) => (

                          <tr className='p-2 border-b border-b-gray-200 relative'>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                            
                          </td>
                          <Link to={`${ENDPOINT_PART_REQUESTS}/${request.id}`}>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer flex flex-row gap-1'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="mt-0.5 w-4 h-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>

                              <p className='font-satoshi text-left text-[#030A1A] capitalize'>SP-{request.id}</p>

                          </td>
                          </Link>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>
                                {request.name}
                              </p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.passport?.name?request.passport?.name:request.passport?.first_name}</p>

                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer '>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.part_number? request.part_number: '-'}</p>
                          </td>
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[16px] capitalize'>{request.vin_number}</p>

                          </td>
                        
                          <td
                            className='p-4 text-xs md:text-sm cursor-pointer'>
                              <p className='font-satoshi text-left text-[#030A1A] text-[14px] capitalize'>
                                {formatDate(request.date_created)}
                              </p>

                          </td>

                          <td>

                            <div className="flex flex-col gap-2 relative w-full">
                            <div  onClick={() => handleShowRequestChange(request.id)} className={`
                            ${request.status === 'Placed'? 'bg-[#19223B]' 
                            :request.status === 'Accepted'? 'bg-blue-500'
                            :request.status === 'Fulfilled'? 'bg-green-500'
                            :request.status === 'Cancelled'? 'bg-red-500'
                            :null
                            } 
                            ${request.status === 'Placed'? 'text-white' 
                            :request.status === 'Accepted'? 'text-white'
                            :request.status === 'Fulfilled'? 'text-white'
                            :request.status === 'Cancelled'? 'text-white'
                            :null
                            }     
                            px-2 py-1 cursor-pointer rounded-lg flex flex-row gap-2  justify-between`}>

                            <p className='font-satoshi text-[18px]'>{request.status === 'Placed' ? 'Outsourcing': request.status === 'Cancelled' ? 'Closed' : request.status}</p>
                            {open.includes(request.id) ? 
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                            </svg>

                            :
                            <svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1 ">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                            </svg>
                            }

                            </div>
                            {open.includes(request.id) && (
                            <div ref={cancelRef}  className="flex flex-col gap-2 absolute top-10 z-50 bg-white shadow-2xl rounded-lg px-2 py-2 w-full">
                            {statuses.filter((statuss) => statuss.name !== request.status).map((status) => (

                            <div 
                            onClick={() => updatePartRequestStatus(status.name, request.id)}
                            className={`bg-${status.bgColor} cursor-pointer px-2 py-1 rounded-lg flex flex-row gap-2  justify-between`}>
                            <p className={`font-satoshi text-[18px] text-${status.textColor}`}>{status.displayName}</p>
                            </div>
                            ))}
                            </div>
                            )}
                            </div>
                            </td>
                        </tr>
                      ))}
                      </>

                    )}


                    

                  </tbody>
                </table>
                {currentTab === ALL_TAB && (
                  <>
                {partRequests.results?.length > 0 &&
                    <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} />
                    }
                  </>
                )}
              </div>
            
        </div>
      </div>

      <ToastContainer

      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
    </div>
  )
}

export default PartRequestsPage