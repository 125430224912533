import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom';
import UsersMarketPlaceBuyers from './UsersMarketPlaceBuyers';
import UsersMarketPlaceMerchants from './UsersMarketPlaceMerchants';

const MERCHANT_TAB = "merchant"
const BUYERS_TAB = "consumers"

function UsersMarketPlace() {
  let currentUrlParams = new URLSearchParams(window.location.search)
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)

  const [currentTab, setCurrentTab] = useState(currentUrlParams.get('usertype') === 'buyers' ? BUYERS_TAB:
                                                currentUrlParams.get('usertype') === 'merchants' ? MERCHANT_TAB:
                                                BUYERS_TAB
                                              )
  const switchBuyersTab = () =>{
    currentUrlParams.set('usertype', 'buyers')
    setUrlParams(currentUrlParams)
    setCurrentTab(BUYERS_TAB)

  } 
  
  const switchMerchantsTab = () =>{
    currentUrlParams.set('usertype', 'merchants')
    setUrlParams(currentUrlParams)
    setCurrentTab(MERCHANT_TAB)

  }  

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between w-full gap-2 pt-2">
        <div className="flex flex-row gap-2">
          <div
            onClick={switchBuyersTab}
            className={`text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === BUYERS_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Buyers</div>
          <div
            onClick={switchMerchantsTab}
            className={`text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === MERCHANT_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Merchants</div>
        </div>
      </div>
      {currentTab === BUYERS_TAB && <UsersMarketPlaceBuyers/>}
      {currentTab === MERCHANT_TAB && <UsersMarketPlaceMerchants/>}
    </>
  )
}

export default UsersMarketPlace