import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import HomeRating from '../../../components/HomeRating'

function BrandView() {
    const [activeTab, setActiveTab] = useState(1)
    const navigate = useNavigate();


    return (
        <div className='flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20'>
            <div className="flex flex-row justify-between">
                <div
                    onClick={() => navigate(-1)}
                    className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-4 mt-0.5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                        />
                    </svg>
                    Back
                </div>
            </div>

            <div className='w-full px-4 md:px-40 flex flex-col gap-2'>
                <div className="flex flex-row gap-3">
                    <p className='font-semibold text-[24px]'>Brands Section Title</p>
                </div>

                <div className="flex flex-row gap-5 overflow-x-scroll w-full hide-scrollbar py-3">
                    <div className="bg-gray-100 hover:bg-white shadow-md border min-w-[10rem] p-2 rounded-md flex flex-col gap-1">
                        <img src="/cdn/images/merchant_logo.png" alt="" className='h-[4rem] rounded object-cover' />
                        <div className="flex flex-col w-full">
                            <HomeRating rating={4.7} reviews={4.7} />
                        </div>
                        <p className='text-gray-900'>Redline</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default BrandView
