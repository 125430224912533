import React, { useEffect, useMemo, useRef, useState } from "react";
import star from "../assets/ic_star.svg";
import axios from "axios";
import Avatar from "react-avatar";
import back from "../assets/ic_back.svg";
import placeholder from "../assets/placeholder.svg";
import copy from "../assets/copy.svg";
import Moment from "react-moment";
import moment from "moment";
import more from "../assets/ic_more.svg";
import CurrencyFormat from "../components/CurrencyFormat";
import ChangeStatusModal from "../components/ChangeStatusModal";
import "react-toastify/dist/ReactToastify.css";
import ModalLayout from "../components/shared/ModalLayout";
import Invoice from "../components/Invoice";
import OrderShelfLife from "../components/OrderShelfLife";
import DeliveryTimer from "../components/DeliveryTimer";
import GoogleMapInput from "../components/GoogleMapInput";
import Select from "react-select";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ENDPOINT_DSF } from "../routes";
import { formatNumber, makeAuthenticatedRequest } from "../utils";
import { toast, ToastContainer } from "react-toastify";
import { URL_SHORTENER_PREFIX } from "../constants";
import { OrderMeta } from "../components/OrderMeta";
import { RecentOrders } from "../components/customers/RecentOrders";
import { axiosCustom } from "../utils";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import CreateServiceJob from "../components/service/modals/CreateServiceJob";

const ESTIMATES_URL = `/oms/order/calculate-delivery`;
const DELIVERY_EXPRESS = "express";
const DELIVERY_STANDARD = "standard";
const DELIVERY_NEXT_DAY = "nextday";

function OrderDetailsPage() {
  let { orderID } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [showDropDownOptions, setShowDropDownOptions] = useState(false);

  const [orderDeliveryCostUpdate, setOrderDeliveryCostUpdate] = useState(0);
  const [orderDeliveryTime, setOrderDeliveryTime] = useState("");
  const [convertedTime, setConvertedTime] = useState("");
  const [processingStatus, setProcessingStatus] = useState(false);

  const [editDeliveryModal, setEditDeliveryModal] = useState(false);
  const [editDeliveryTimeModal, setEditDeliveryTimeModal] = useState(false);


  const [addServiceJobModal, setAddServiceJobModal] = useState(false);


  const [updateOrderData, setUpdateOrderData] = useState(false);
  const [editLocationModal, setEditLocationModal] = useState(false);

  const [newOrderLineUrl, setNewOrderLineUrl] = useState("");
  const [deleteOrderLine, setDeleteOrderLine] = useState("Delete");

  const [orderData, setOrderData] = useState(null);
  const [deliveryPartners, setDeliveryPartners] = useState([]);
  const [changeStatusModal, setchangeStatusModal] = useState("");
  const [openSendSMSModal, setOpenSendSMSModal] = useState(false);

  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  const [cancellationModal, setCancellationModal] = useState(false);
  const [orderSMS, setOrderSMS] = useState();
  const [orderNotes, setOrderNotes] = useState();

  const [payMerchant, setPayMerchant] = useState("Pay merchant");

  const statuses = ["New", "Dispatched", "Cancelled", "Confirmed"];

  const [selectedPartner, setSelectedPartner] = useState();

  const handleCloseAddServiceJob = () => {
    setAddServiceJobModal(false)
  }

  useEffect(() => {
    if (deliveryPartners && deliveryPartners.length > 0) {
      setSelectedPartner(deliveryPartners[0].id);
    }
  }, [deliveryPartners]);

  const [open, setOPen] = useState([]);
  const openLength = open.length;

  const statusOptions = [
    { name: "New", value: "New", label: "New", color: "#B9E38D" },
    {
      name: "Confirmed",
      value: "Confirmed",
      label: "Confirmed",
      color: "#54D3CA",
    },
    {
      name: "Dispatched",
      value: "Dispatched",
      label: "Dispatched",
      color: "#8CD1FC",
    },
    {
      name: "Delivered",
      value: "Delivered",
      label: "Delivered",
      color: "#3E5DDD",
    },
    {
      name: "Requires prepayment",
      value: "Requires prepayment",
      label: "Requires prepayment",
      color: "#576298",
    },
    {
      name: "To be exchanged",
      value: "To be exchanged",
      label: "To be exchanged",
      color: "#F0A6A5",
    },
    {
      name: "Exchange complete",
      value: "Exchange complete",
      label: "Exchange complete",
      color: "#059D5F",
    },
    {
      name: "Rejected",
      value: "Rejected",
      label: "Rejected",
      color: "#FCB28E",
    },
    {
      name: "Reject Completed",
      value: "Reject Completed",
      label: "Reject Completed",
      color: "#F0A6A5",
    },
    {
      name: "Complete",
      value: "Complete",
      label: "Complete",
      color: "#54D3CA",
    },
    {
      name: "Cancelled",
      value: "Cancelled",
      label: "Cancelled",
      color: "#DE6972",
    },
  ];

  const handleShowOrderChange = (orderID) => {
    const openState = open.slice();
    const index = openState.indexOf(orderID);

    if (index >= 0) {
      openState.splice(index, 1);
      setOPen(openState);
    } else {
      openState.push(orderID);
      setOPen(openState);
    }
  };

  const closeChangeModal = () => {
    setchangeStatusModal("");
  };

  const openCancelModal = () => {
    setCancellationModal(true);
    setchangeStatusModal("");
  };

  const submitOrderNotes = () => {
    setOrderNotes(null);
    setProcessing(true);
    const params = { message: orderNotes };

    makeAuthenticatedRequest(
      `/oms/order/${orderData.id}/notes/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        fetchOrder();
        setProcessing(false);
      });
  };

  const updateOrderDeliveryCost = () => {
    setProcessing(true);
    const params = {
      action: "change_delivery_cost",
      delivery_cost: orderDeliveryCostUpdate,
    };
    makeAuthenticatedRequest(
      `/oms/order/${orderData.order_id}/edit`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        //  console.log(data)
        fetchOrder();
        setProcessing(false);
        handleCloseeditDeliveryModalModal();
      });
  };

  const updateOrderDeliveryTime = () => {
    setProcessing(true);
    const params = {
      action: "change_delivery_time",
      delivery_time: convertedTime,
    };
    makeAuthenticatedRequest(
      `/oms/order/${orderData.order_id}/edit`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        //  console.log(data)
        fetchOrder();
        setProcessing(false);
        handleCloseeditDeliveryModalModal();
      });
  };

  const delOrderLine = (lineId) => {
    setProcessing(true);
    const params = {
      action: "delete_line",
      line: lineId,
    };
    makeAuthenticatedRequest(
      `/oms/order/${orderData.order_id}/edit`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        //  console.log(data)
        fetchOrder();
        setProcessing(false);
      });
  };

  const fetchOrder = () => {
    makeAuthenticatedRequest(`/oms/order/${orderID}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setOrderData(data);

        console.log("ORDER DATA::", data);
        setOrderDeliveryCostUpdate(data?.delivery_cost);
      });
  };

  const fetchDeliveryPartners = () => {
    makeAuthenticatedRequest(`/oms/delivery-partners/`)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setDeliveryPartners(data.results);
      });
  };

  useEffect(() => {
    fetchOrder();
    fetchDeliveryPartners();
  }, []);

  const cancelRef = useRef();
  const confirmRef = useRef();

  const getCurrentDateTime = () => {
    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm:ssZ");
    return currentDateTime;
  };

  const generateConfirmedSMS = async (order) => {
    let deliveryWindow = "";
    if (order.delivery_window_from) {
      const from = moment(order.delivery_window_from).format("h:mm A a Do");
      deliveryWindow = ` at ${from} `;
    }

    if (order.delivery_window_to) {
      const to = moment(order.delivery_window_to).format("h:mm A Do");
      deliveryWindow = ` before ${to} `;
    }

    if (order.delivery_window_from && order.delivery_window_to) {
      const from = moment(order.delivery_window_from).format("h:mm A a Do");
      const to = moment(order.delivery_window_to).format("h:mm A Do");
      deliveryWindow = ` between ${from} and ${to} `;
    }

    let total = parseInt(order.total);
    if (order.delivery_cost) total = total + parseInt(order.delivery_cost);

    const fullLink = `https://sparepap.com/order/${order.order_id}/track`;
    const { short_link } = await createShortLink(fullLink);

    const letterRep = `abcdefghijklmonpqrstuvwxyz`;
    const name = order.passport.first_name ? order.passport.first_name : "";
    let message = `${order.delivery_type} delivery: Order #${order.order_id}  Please pick `;
    order.lines.map((line, index) => {
      const letter = letterRep[index];
      message = `${message} \r\n(${letter}). ${line.quantity} X ${line.title} from ${line.merchant.merchant_name} (+${line.merchant.phone_number}) at ${line.merchant.merchant_address} ${line.merchant.merchant_address_details}`;
    });
    message = `${message} \r\nand deliver to ${name} (${order.passport.phone_number}), ${order.shipping_address.town}${deliveryWindow}. Customer to confirm item and pay Ksh ${total}. Kindly confirm payment via ${short_link} before you leave. Thank you`;
    return message;
  };

  const handleOrderStatusChange = (order, status, remarks, reason, partner) => {
    updateorderStatus(order, status, remarks, reason, partner);
  };

  const updateorderStatus = (order, status, remarks, reason, partner) => {
    setProcessingStatus(true);
    const params = {
      status: status,
    };
    if (remarks) {
      if (reason) params.message = `${reason}: ${remarks}`;
      else {
        params.message = remarks;
      }
    }
    if (status == "Dispatched" && partner) {
      params.delivery_partner = partner;
      params.date_disptached = getCurrentDateTime();
    }

    makeAuthenticatedRequest(
      `/oms/order/${order.id}/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setProcessingStatus(false);

        fetchOrder(orderID);
      });
  };

  const sendOrderSMS = () => {
    setOpenSendSMSModal(false);
    const params = {
      message: orderSMS,
      delivery_partner: selectedPartner,
    };

    makeAuthenticatedRequest(
      `/oms/order/${orderData.id}/sms/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        fetchOrder();
      });
  };

  const orderLinePay = (orderlineid) => {
    makeAuthenticatedRequest(`/oms/order/${orderlineid}/pay`, "POST")
      .then((response) => response.json())
      .then((data) => {
        // console.log('data', data)
        toast.info(`${data.detail}`);
        fetchOrder();
      });
  };

  const handlesetPayMerchant = () => {
    setPayMerchant("Confirm");
    setTimeout(() => {
      setPayMerchant("Pay merchant");
    }, 2000);
  };

  const handlePayMerchant = (lineId) => {
    if (payMerchant === "Confirm") {
      orderLinePay(lineId);
      setPayMerchant("Delete");
    }
  };

  const handleDeleteOrderLine = (lineId) => {
    if (deleteOrderLine === "Confirm") {
      delOrderLine(lineId);
      setDeleteOrderLine("Delete");
    }
  };

  useEffect(() => {
    let handler = (e) => {
      if (cancelRef.current && !cancelRef.current.contains(e.target)) {
        setCancellationModal(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const orderStatusChangeDialog = (status) => {
    setOPen([]);
    setchangeStatusModal(status);
  };

  const actionRef = useRef();

  useEffect(() => {
    let actionhandler = (e) => {
      if (actionRef.current && !actionRef.current.contains(e.target)) {
        setOPen([]);
      }
    };
    document.addEventListener("mousedown", actionhandler);

    return () => {
      document.removeEventListener("mousedown", actionhandler);
    };
  });

  const orderLienRef = useRef();

  useEffect(() => {
    let actionhandler = (e) => {
      if (orderLienRef.current && !orderLienRef.current.contains(e.target)) {
        setOpenOrderLine([]);
      }
    };
    document.addEventListener("mousedown", actionhandler);

    return () => {
      document.removeEventListener("mousedown", actionhandler);
    };
  });

  const [openOrderLine, setOpenOrderLine] = useState([]);
  const [openOrderLineEdit, setOpenOrderLineEdit] = useState([]);

  const handleSetDeleteOrderLine = () => {
    setDeleteOrderLine("Confirm");
    setTimeout(() => {
      setDeleteOrderLine("Delete");
    }, 2000);
  };

  const toggleOpenOrderLine = (ordLineId) => {
    const openState = open.slice();
    const index = openState.indexOf(ordLineId);

    if (index >= 0) {
      openState.splice(index, 1);
      setOpenOrderLine(openState);
    } else {
      openState.push(ordLineId);
      setOpenOrderLine(openState);
    }
  };

  const testData = [
    { colo: { blue: "skyblue" }, tt: "prod1" },
    { colo: { blue: "merchblue" }, tt: "prod2" },
    { colo: "red", tt: "prod4" },
  ];

  const groupByMerchant = (merchant, array) =>
    array.reduce(
      (cache, orderItem) => ({
        ...cache,
        [orderItem[merchant]]:
          [orderItem] in cache
            ? cache[orderItem[merchant]].concat(orderItem)
            : [orderItem],
      }),
      {}
    );

  // const totalSalesRevenue = orderData?.lines?.reduce((acc, line) => {
  //   const commissionPercentage =
  //     parseInt(line.carpart.passport.merchant_commission) / 100;
  //   const lineCommission = line.price * line.quantity * commissionPercentage;
  //   return acc + lineCommission;
  // }, 0);

  const totalSalesRevenue = orderData?.lines?.reduce((acc, line) => {
    return acc + line?.commission;
  }, 0);

  // const totalMerchantPay = orderData?.lines?.reduce((acc, line) => {
  //   const commissionPercentage =
  //     parseInt(line.carpart.passport.merchant_commission) / 100;
  //   const lineMerchantPay =
  //     line.price * line.quantity -
  //     line.price * line.quantity * commissionPercentage;
  //   return acc + lineMerchantPay;
  // }, 0);

  const totalMerchantPay = orderData?.lines?.reduce((acc, line) => {
    const lineMerchantPay = line?.price * line?.quantity - line?.commission;
    return acc + lineMerchantPay;
  }, 0);

  const handleCloseInvoiceModal = () => {
    setEditModal(false);
    setOpenInvoiceModal(false);
    setOpenInvoiceModal(false);
  };

  const calculatePrice = (price, wholesalePrice, quantity, moq) => {
    const newPrice = cleanDigit(price);
    const newWholesalePrice = cleanDigit(wholesalePrice);
    const newQuantity = cleanDigit(quantity);
    const newMoq = cleanDigit(moq);
    if (newMoq === 0 || newQuantity === 0 || newWholesalePrice === 0) {
      return newPrice;
    }
    if (newQuantity >= newMoq) {
      return newWholesalePrice;
    }
    return newPrice;
  };

  const cleanDigit = (val) => {
    if (val && !isNaN(val)) return parseFloat(val);
    return 0;
  };

  const calculateMerchantPrice = (
    price,
    wholesalePrice,
    merchantPrice,
    merchantWholesalePrice,
    quantity,
    moq,
    merchantCommission,
    merchantWholesaleCommission
  ) => {
    const newPrice = calculatePrice(price, wholesalePrice, quantity, moq);
    const newWholesalePrice = cleanDigit(wholesalePrice);
    const newMerchantPrice = cleanDigit(merchantPrice);
    const newMerchantCommission = cleanDigit(merchantCommission);
    const newMerchantWholesalePrice = cleanDigit(merchantWholesalePrice);
    const newMerchantWholesaleCommission = cleanDigit(
      merchantWholesaleCommission
    );
    const newQuantity = cleanDigit(quantity);
    const newMoq = cleanDigit(moq);

    if (newMoq > 0 && newQuantity > 0 && newQuantity >= newMoq) {
      if (newMerchantWholesalePrice > 0) {
        return newMerchantWholesalePrice;
      } else {
        return newPrice * ((100 - newMerchantWholesaleCommission) / 100);
      }
    }

    if (newMerchantPrice > 0) {
      return newMerchantPrice;
    } else {
      return newPrice * ((100 - newMerchantCommission) / 100);
    }
  };

  const handleCloseeditDeliveryModalModal = () => {
    setEditDeliveryModal(false);
  };

  const handleCloseeditDeliveryTimeModalModal = () => {
    setEditDeliveryTimeModal(false);
  };

  const handleCloseEditLocationModal = (e) => {
    e.preventDefault();
    setEditLocationModal(false);
  };

  const handleTimeChange = (event) => {
    // Convert inputTime to the desired format
    const dateObj = new Date();
    const timeArr = event.target.value.split(":");
    dateObj.setHours(timeArr[0]);
    dateObj.setMinutes(timeArr[1]);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const hour = ("0" + dateObj.getHours()).slice(-2);
    const minute = ("0" + dateObj.getMinutes()).slice(-2);
    const formattedTime = `${year}-${month}-${day}T${hour}:${minute}`;
    setConvertedTime(formattedTime);
    setOrderDeliveryTime(event.target.value);
  };

  const editableStatuses = ["New", "Confirmed"];
  const shelfLifeStatuses = ["Dispatched", "Delivered"];

  const createShortLink = async (fullLink) => {
    try {
      const url = `${URL_SHORTENER_PREFIX}create/`;
      const { data } = await axios.post(url, { full_link: fullLink });
      return data;
    } catch (error) {
      toast.error("An error occurred while generating a short link.");
    }
  };

  const handleOpenSMSModal = async () => {
    const sms = await generateConfirmedSMS(orderData);
    setOrderSMS(sms);
    setOpenSendSMSModal(true);
  };

  const [addLineModal, setAddLineModal] = useState(false);
  const [addLineCostModal, setAddLineCostModal] = useState(false);

  const [partLink, setPartLink] = useState("");
  const [isPartLinkValid, setIsPartLinkValid] = useState(true);
  const [newOrderLineQty, setNewOrderLineQty] = useState(0);
  const [newCost, setNewCost] = useState()
  const [newVendorCost, setNewVendorCost] = useState()
  const [newCostQuantity, setNewCostQuantity] = useState()
  const [newCostNarration, setNewCostNarration] = useState()

  const addNewOrderLine = async (orderId, params) => {
    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/${orderId}/edit`,
        "POST",
        JSON.stringify(params)
      );

      if (response.status !== 200) {
        toast.error("Order not found!");
        return;
      }

      return;
    } catch (error) {
      toast.error("An error occurred while adding the part.");
      return;
    }
  };

  const handleCloseAddLineModal = () => {
    setAddLineModal(false);
  };

  const handleCloseAddLineCostModal = () => {
    setAddLineCostModal(false);
  };

  const handlePartLinkChange = (e) => {
    setPartLink(e.target.value);
  };

  const handleAddPartSubmit = async (e) => {
    e.preventDefault();

    if (!newOrderLineQty || !partLink) {
      return;
    }

    try {
      new URL(partLink);
      setIsPartLinkValid(true);
    } catch (error) {
      setIsPartLinkValid(false);
      toast.error("Invalid URL");
      return;
    }

    const orderId = orderData?.order_id;
    if (!orderId) {
      return;
    }

    const params = {
      action: "add_line",
      quantity: newOrderLineQty,
      product: partLink,
    };

    setProcessing(true);
    await addNewOrderLine(orderId, params);
    fetchOrder();
    setAddLineModal(false);
    setPartLink("");
    setNewOrderLineQty(0);
    setProcessing(false);
    return;
  };

  const handleAddCostSubmit = async (e) => {
    e.preventDefault();

    if (!newCost || !newCostQuantity || !newCostNarration) {
      return;
    }

    const orderId = orderData?.order_id;
    if (!orderId) {
      return;
    }

    const params = {
      cost: newCost,
      action: "add_order_cost",
      vendor_cost: newVendorCost,
      quantity: newCostQuantity,
      narration: newCostNarration,
    };

    setProcessing(true);
    await addNewOrderLine(orderId, params);
    fetchOrder();
    setAddLineCostModal(false);
    setNewCost(null)
    setNewVendorCost(null)
    setNewCostQuantity(null)
    setNewCostNarration(null)
    setProcessing(false);
    return;
  };


  const [location, setLocation] = useState(null);
  const [deliveryType, setDeliveryType] = useState(null);
  const [deliveryWindow, setDeliveryWindow] = useState(null);
  const [deliveryResponse, setDeliveryResponse] = useState(null);
  const [deliveryFrom, setDeliveryFrom] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState("");
  const [editOrderDetailsModal, setEditOrderDetailsModal] = useState(false);
  const [editManually, setEditManually] = useState(false);

  const carPartIds = useMemo(() => {
    return orderData?.lines.map((line) => {
      return line?.carpart?.id;
    });
  }, [orderData]);

  const handleExpressRadioClick = () => {
    setDeliveryType(DELIVERY_EXPRESS);
    setDeliveryWindow({
      delivery_window_from:
        deliveryResponse?.deliveryOptions?.express?.delivery_window_from,
      delivery_window_to:
        deliveryResponse?.deliveryOptions?.express?.delivery_window_to,
    });
  };

  const handleStandardRadioClick = () => {
    setDeliveryType(DELIVERY_STANDARD);
  };

  const handleNextDayRadioClick = () => {
    setDeliveryType(DELIVERY_NEXT_DAY);
    setDeliveryWindow({
      delivery_window_from:
        deliveryResponse?.deliveryOptions?.nextday?.delivery_window_from,
      delivery_window_to:
        deliveryResponse?.deliveryOptions?.nextday?.delivery_window_to,
    });
  };

  const handleDeliveryAddress = async (loc) => {
    setLocation(loc);

    const payload = {
      carpart_ids: carPartIds,
      destination: { lat: loc?.lat, lng: loc?.lng },
    };

    const response = await axiosCustom.post(
      ESTIMATES_URL,
      JSON.stringify(payload)
    );

    setDeliveryResponse(response?.data);

    const { deliveryFeeStandard, deliveryFeeExpress, etaExpress } =
      response?.data;

    if (
      !(deliveryFeeStandard || etaExpress || deliveryFeeExpress) &&
      !editManually
    ) {
      toast.info(
        "We're unable to calculate the delivery fees, you could try editing manually."
      );
    }
  };

  const updateOrderDeliveryCostV2 = async (deliveryCost) => {
    const params = {
      action: "change_delivery_cost",
      delivery_cost: deliveryCost,
    };

    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/${orderData.order_id}/edit`,
        "POST",
        JSON.stringify(params)
      );

      response.status === 200
        ? toast.success("Delivery cost updated successfully!")
        : toast.error("An error occurred while updating the delivery cost.");
    } catch (error) {
      toast.error("Something went wrong!");
    }

    return;
  };

  const updateOrderDetails = async (params) => {
    setProcessing(true);
    setShowDropDownOptions(false);
    const response = await makeAuthenticatedRequest(
      `/oms/order/${orderData.order_id}/edit`,
      "POST",
      JSON.stringify(params)
    );
    fetchOrder();
    setProcessing(false);
  };

  const updateOrderDeliveryTimeV2 = async (deliveryTime) => {
    const params = {
      action: "change_delivery_time",
      delivery_time: deliveryTime,
    };

    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/${orderData.order_id}/edit`,
        "POST",
        JSON.stringify(params)
      );

      response.status === 200
        ? toast.success("Delivery time updated successfully!")
        : toast.error("An error occurred while updating the delivery time.");
    } catch (error) {
      toast.error("Something went wrong!");
    }

    return;
  };

  const updateOrderShippingAddressV2 = async (location) => {
    const shippingAddress = {
      lat: location?.lat,
      lng: location?.lng,
      town: location?.placeName,
      category: "home",
      is_default: false,
      phone_number: orderData?.passport.phone_number,
    };

    const params = {
      action: "change_shipping_address",
      shipping_address: shippingAddress,
    };

    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/${orderData.order_id}/edit`,
        "POST",
        JSON.stringify(params)
      );

      response.status === 200
        ? toast.success("Shipping address updated successfully!")
        : toast.error("An error occurred while updating the Shipping address.");
    } catch (error) {
      toast.error("Something went wrong!");
    }

    return;
  };

  const handleEditOrderDetailsFormSubmit = async (e) => {
    e.preventDefault();
    handleCloseEditOrderDetailsModal();

    location && (await updateOrderShippingAddressV2(location));

    if (editManually === true) {
      deliveryFee && (await updateOrderDeliveryCostV2(deliveryFee));
      deliveryFrom && (await updateOrderDeliveryTimeV2(deliveryFrom));
    } else {
      const { deliveryFeeExpress, deliveryFeeStandard } = deliveryResponse;
      deliveryType === DELIVERY_EXPRESS
        ? deliveryFeeExpress &&
        (await updateOrderDeliveryCostV2(deliveryFeeExpress))
        : deliveryFeeStandard &&
        (await updateOrderDeliveryCostV2(deliveryFeeStandard));

      const windowFrom = deliveryWindow?.delivery_window_from;
      windowFrom && (await updateOrderDeliveryTimeV2(windowFrom));
    }

    fetchOrder();
    setDeliveryFee("");
    setDeliveryFrom(null);
    setLocation(null);
    setEditManually(false);
    setDeliveryWindow(null);
  };

  const handleEditManuallyClick = () => {
    setEditManually(true);
  };

  const handleCloseEditOrderDetailsModal = () => {
    setEditOrderDetailsModal(false);
    // setDeliveryFee("");
    // setDeliveryFrom(null);
    // setLocation(null);
    // setEditManually(false);
    // setDeliveryWindow(null);
  };

  const handleDeliveryFeeChange = (e) => {
    setDeliveryFee(e.target.value);
  };
  const [orderLinePrice, setOrderLinePrice] = useState(0);
  const [orderLineQty, setOrderLineQty] = useState(1);
  const [orderLineMerchantPrice, setOrderLineMerchantPrice] = useState(0);
  const [selectedOrderLineStatus, setSelectedOrderLineStatus] = useState("");

  const ORDER_LINE_STATUSES = [
    { value: "New", label: "New" },
    { value: "Failed", label: "Failed" },
    { value: "Accepted", label: "Accepted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Refunded", label: "Refunded" },
    { value: "Complete", label: "Complete" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Completed", label: "Completed" },
    { value: "Delivered", label: "Delivered" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Processing", label: "Processing" },
    { value: "Dispatched", label: "Dispatched" },
    { value: "To be replaced", label: "To be replaced" },
    { value: "To be returned", label: "To be returned" },
    { value: "Requires prepayment", label: "Requires prepayment" },
    { value: "To be exchanged", label: "To be exchanged" },
    { value: "Exchange complete", label: "Exchange complete" },
    { value: "Reject completed", label: "Reject completed" },
    { value: "Dispatching", label: "Dispatching" },
  ];

  const toggleOpenOrderLineEdit = (ordLine) => {
    setOrderLinePrice(ordLine?.price);
    setOrderLineMerchantPrice(ordLine?.merchant_price);
    setOrderLineQty(ordLine?.quantity);
    setSelectedOrderLineStatus(ordLine?.status);
    const openState = open.slice();
    const index = openState.indexOf(ordLine?.id);

    if (index >= 0) {
      openState.splice(index, 1);
      setOpenOrderLineEdit(openState);
    } else {
      openState.push(ordLine?.id);
      setOpenOrderLineEdit(openState);
    }
  };

  const updateOrderLineQuantity = async (line) => {
    const params = {
      action: "change_quantity",
      line: line?.id,
      quantity: orderLineQty,
    };

    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/${orderData.order_id}/edit`,
        "POST",
        JSON.stringify(params)
      );

      response?.status === 200
        ? toast.success("Part quantity updated successfully!")
        : toast.error("An error occurred while updating quantity.");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const updateOrderLineMerchantPrice = async (line) => {
    const params = {
      action: "change_merchant_price",
      line: line?.id,
      merchant_price: Math.round(parseFloat(orderLineMerchantPrice))
    };


    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/${orderData.order_id}/edit`,
        "POST",
        JSON.stringify(params)
      );

      response?.status === 200
        ? toast.success("Part price updated successfully!")
        : toast.error("An error occurred while updating price.");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const updateOrderLinePrice = async (line) => {
    const params = {
      action: "change_price",
      line: line?.id,
      price: Math.round(parseFloat(orderLinePrice)),
    };


    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/${orderData.order_id}/edit`,
        "POST",
        JSON.stringify(params)
      );

      response?.status === 200
        ? toast.success("Part price updated successfully!")
        : toast.error("An error occurred while updating price.");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const updateOrderLineStatus = async (line, orderLineStatus) => {
    const params = {
      action: "change_line_status",
      status: orderLineStatus,
      line: line?.id,
    };

    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/${orderData.order_id}/edit`,
        "POST",
        JSON.stringify(params)
      );

      response?.status === 200
        ? toast.success("Order status updated successfully!")
        : toast.error("An error occurred while updating status.");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleEditOrderLineFormSubmit = async (e, line) => {
    e.preventDefault();

    handleCloseLineEditModal();

    orderLineQty !== line?.quantity && (await updateOrderLineQuantity(line));
    orderLinePrice !== line?.price && (await updateOrderLinePrice(line));
    orderLineMerchantPrice !== line?.merchant_price && (await updateOrderLineMerchantPrice(line));

    selectedOrderLineStatus !== line?.status &&
      (await updateOrderLineStatus(line, selectedOrderLineStatus));

    fetchOrder();
  };

  const handleCloseLineEditModal = () => {
    setOpenOrderLineEdit([]);
  };

  const handleOrderLineStatusChange = (e) => {
    setSelectedOrderLineStatus(e?.value);
  };

  return (
    <>
      {openInvoiceModal && (
        <ModalLayout
          open={openInvoiceModal}
          handleClose={handleCloseInvoiceModal}
        >
          <Invoice order={orderData} />
        </ModalLayout>
      )}

      {addLineCostModal && (
        <ModalLayout open={addLineCostModal} handleClose={handleCloseAddLineCostModal}>
          <div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
            <p className="text-[16px] font-semibold">Add New Cost</p>
            <form
              action=""
              onSubmit={(e) => handleAddCostSubmit(e)}
              className="flex flex-col gap-2 w-full"
            >

              <div className="w-full flex flex-col gap-1">
                <label htmlFor="" className="text-[14px]">
                  Cost
                </label>
                <input
                  onChange={(e) => setNewCost(e.target.value)}
                  value={newCost}
                  type="number"
                  className="outline-none px-2 py-2 rounded-md border"
                  min={1}
                />
              </div>

              <div className="w-full flex flex-col gap-1">
                <label htmlFor="" className="text-[14px]">
                  Quantity
                </label>
                <input
                  onChange={(e) => setNewCostQuantity(e.target.value)}
                  value={newCostQuantity}
                  type="number"
                  className="outline-none px-2 py-2 rounded-md border"
                  min={1}
                />
              </div>

              <div className="w-full flex flex-col gap-1">
                <label htmlFor="" className="text-[14px]">
                  Vendor Cost
                </label>
                <input
                  onChange={(e) => setNewVendorCost(e.target.value)}
                  value={newVendorCost}
                  type="number"
                  className="outline-none px-2 py-2 rounded-md border"
                  min={1}
                />
              </div>

              <div className="w-full flex flex-col gap-1">
                <label htmlFor="" className="text-[14px]">
                  Naration
                </label>
                <input
                  required
                  value={newCostNarration}
                  onChange={(e) => setNewCostNarration(e.target.value)}
                  type="text"
                  className="outline-none px-2 py-2 rounded-md border "
                />
              </div>
              <div className="flex flex-row-reverse gap-2 w-full">
                <button
                  type="submit"
                  className="px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md"
                >
                  Continue
                </button>
                <button
                  onClick={handleCloseAddLineCostModal}
                  className="px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </ModalLayout>
      )}


      {addServiceJobModal && (
        <ModalLayout
          title="Add service to order"
          open={addServiceJobModal}
          handleClose={handleCloseAddServiceJob}
        >
          <CreateServiceJob
            handleCloseModal={handleCloseAddServiceJob}
            order={orderData} />
        </ModalLayout>
      )}

      {addLineModal && (
        <ModalLayout open={addLineModal} handleClose={handleCloseAddLineModal}>
          <div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
            <p className="text-[16px] font-semibold">Add New Part</p>
            <form
              action=""
              onSubmit={(e) => handleAddPartSubmit(e)}
              className="flex flex-col gap-2 w-full"
            >
              <div className="w-full flex flex-col gap-1">
                <label htmlFor="" className="text-[14px]">
                  Part Link
                </label>
                <input
                  required
                  value={partLink}
                  onChange={(e) => handlePartLinkChange(e)}
                  type="text"
                  className={`outline-none px-2 py-2 rounded-md border ${isPartLinkValid ? "" : "border-red-500 text-red-600"
                    }`}
                />
              </div>
              <div className="w-full flex flex-col gap-1">
                <label htmlFor="" className="text-[14px]">
                  Quantity
                </label>
                <input
                  onChange={(e) => setNewOrderLineQty(e.target.value)}
                  value={newOrderLineQty}
                  type="number"
                  className="outline-none px-2 py-2 rounded-md border"
                  min={1}
                />
              </div>
              <div className="flex flex-row-reverse gap-2 w-full">
                <button
                  type="submit"
                  className="px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md"
                >
                  Continue
                </button>
                <button
                  onClick={handleCloseAddLineModal}
                  className="px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </ModalLayout>
      )}

      {editDeliveryModal && (
        <ModalLayout
          open={editDeliveryModal}
          handleClose={handleCloseeditDeliveryModalModal}
        >
          <div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
            <p className="text-[16px] font-semibold">Edit delivery cost</p>
            <form action="" className="flex flex-col gap-2 w-full">
              <div className="w-full flex flex-col gap-1">
                <label htmlFor="" className="text-[14px]">
                  Amount
                </label>
                <input
                  onChange={(e) => setOrderDeliveryCostUpdate(e.target.value)}
                  value={orderDeliveryCostUpdate}
                  type="number"
                  className="outline-none px-2 py-2 rounded-md border"
                  min={1}
                />
              </div>
              <div className="flex flex-row-reverse gap-2 w-full">
                <button
                  onClick={updateOrderDeliveryCost}
                  className="px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md"
                >
                  Continue
                </button>
                <button
                  onClick={handleCloseeditDeliveryModalModal}
                  className="px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </ModalLayout>
      )}

      {editOrderDetailsModal && (
        <ModalLayout
          open={editOrderDetailsModal}
          handleClose={handleCloseEditOrderDetailsModal}
        >
          <div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
            <p className="text-[16px] font-semibold">Edit order</p>
            <form
              onSubmit={(e) => handleEditOrderDetailsFormSubmit(e)}
              className="flex flex-col gap-2 w-full"
            >
              {!editManually && (
                <div>
                  <div className="flex gap-4 md:flex-row flex-col">
                    <div className="w-full flex flex-[2] flex-col">
                      <div className="flex w-full gap-3 md:flex-row flex-col">
                        <div className="flex flex-col py-2 w-full">
                          <label htmlFor="make" className="text-[14px]">
                            Delivery Address
                          </label>
                          <GoogleMapInput
                            setLocation={(loc) => handleDeliveryAddress(loc)}
                            classCss="mt-1 block w-full p-2 outline-none rounded-md border placeholder:text-[12px]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {(deliveryResponse?.deliveryFeeStandard ||
                    deliveryResponse?.etaExpress ||
                    deliveryResponse?.deliveryFeeExpress) && (
                      <div className="w-full">
                        <div className="flex flex-col main-bg-8 rounded-md py-4 gap-4">
                          {deliveryResponse?.deliveryOptions?.express && (
                            <>
                              <span className="mb-2 text-[13px] font-[550]">
                                Delivery options
                              </span>

                              <div
                                className={`flex items-center gap-2 cursor-pointer px-2 main-bg-8`}
                                onClick={() => handleExpressRadioClick()}
                              >
                                <input
                                  type="radio"
                                  disabled={
                                    !deliveryResponse?.deliveryOptions?.express
                                  }
                                  checked={DELIVERY_EXPRESS === deliveryType}
                                />
                                <span>
                                  <span
                                    className={`text-[14px] ${DELIVERY_EXPRESS === deliveryType &&
                                      "font-[600]"
                                      }`}
                                  >
                                    Express delivery
                                  </span>
                                  <p className="text-sm text-gray-700 text-[11px] font-medium">
                                    Instant pickup and delivery.
                                  </p>
                                  <p className="text-[11px] italic animate-in fade-in zoom-in-90 duration-500">
                                    {
                                      deliveryResponse?.deliveryOptions?.express
                                        ?.label
                                    }
                                  </p>
                                </span>
                              </div>
                            </>
                          )}
                          {deliveryResponse?.deliveryOptions?.standard && (
                            <div
                              className={`w-full flex flex-col justify-start items-start px-2 rounded main-bg-8`}
                              onClick={() => handleStandardRadioClick()}
                            >
                              <div className="flex gap-2 cursor-pointer items-center">
                                <input
                                  type="radio"
                                  disabled={
                                    !deliveryResponse?.deliveryOptions?.standard
                                  }
                                  checked={DELIVERY_STANDARD === deliveryType}
                                />
                                <span>
                                  <span
                                    className={`text-[14px] ${DELIVERY_STANDARD === deliveryType &&
                                      "font-[550]"
                                      }`}
                                  >
                                    Standard delivery
                                  </span>
                                  <p className="text-sm text-gray-700 text-[11px] font-medium">
                                    Select your preferred time slot
                                  </p>
                                </span>
                              </div>
                              {DELIVERY_STANDARD === deliveryType &&
                                deliveryResponse?.deliveryOptions?.standard &&
                                deliveryResponse?.deliveryOptions?.standard
                                  ?.length &&
                                deliveryResponse?.deliveryOptions?.standard?.map(
                                  (option, i) => (
                                    <div
                                      onClick={() => setDeliveryWindow(option)}
                                      key={i}
                                      className="flex items-center gap-2 cursor-pointer ml-8 animate-in fade-in zoom-in-90 duration-500"
                                    >
                                      <input
                                        checked={
                                          option?.label === deliveryWindow?.label
                                        }
                                        type="checkbox"
                                        className="text-xs accent-[#BF3054]"
                                      />
                                      <p className="text-[11px] italic">
                                        {option?.label}
                                      </p>
                                    </div>
                                  )
                                )}
                            </div>
                          )}
                          {deliveryResponse?.deliveryOptions?.nextday && (
                            <div
                              className={`flex items-center gap-2 cursor-pointer px-2 main-bg-8`}
                              onClick={() => handleNextDayRadioClick()}
                            >
                              <input
                                type="radio"
                                disabled={
                                  !deliveryResponse?.deliveryOptions?.nextday
                                }
                                checked={DELIVERY_NEXT_DAY === deliveryType}
                              />
                              <p>
                                <span
                                  className={`text-[14px] ${DELIVERY_NEXT_DAY === deliveryType &&
                                    "font-[550]"
                                    }`}
                                >
                                  Next day (Country Wide) delivery
                                </span>
                                <p className="text-xs text-gray-700 text-[11px]">
                                  250 Ksh for first 5kg. 30ksh for any extra kg
                                </p>
                                <p className="text-[11px] italic animate-in fade-in zoom-in-90 duration-500">
                                  {
                                    deliveryResponse?.deliveryOptions?.nextday
                                      ?.label
                                  }
                                </p>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                </div>
              )}

              {editManually && (
                <>
                  <div className="w-full flex flex-col gap-1 mt-4">
                    <label htmlFor="make" className="text-[14px] font-light">
                      Delivery Address
                    </label>
                    <GoogleMapInput
                      setLocation={(loc) => handleDeliveryAddress(loc)}
                      classCss="mt-1 block w-full p-2 outline-none rounded-md border placeholder:text-[12px]"
                    />
                  </div>

                  <div className="flex gap-4 md:flex-row flex-col mt-4">
                    <div className="flex flex-col flex-1">
                      <label htmlFor="" className="text-[14px] font-light">
                        Delivery Cost
                      </label>
                      <input
                        onChange={(e) => handleDeliveryFeeChange(e)}
                        value={deliveryFee}
                        type="number"
                        className="outline-none px-2 py-2 rounded-md border"
                      />
                    </div>

                    <div className="flex flex-col flex-1">
                      <p className="text-[14px] font-light">Delivery Time</p>
                      <DateTimePicker
                        minDate={new Date()}
                        onChange={setDeliveryFrom}
                        value={deliveryFrom}
                        className="outline-none px-1 py-1 rounded-md border"
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="flex flex-row-reverse gap-2 w-full mt-4">
                <button
                  type="submit"
                  className="px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md"
                >
                  Save
                </button>

                {!editManually && (
                  <>
                    <button
                      onClick={handleEditManuallyClick}
                      className="px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100"
                    >
                      Edit Manually
                    </button>
                  </>
                )}

                <button
                  onClick={handleCloseEditOrderDetailsModal}
                  className="px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </ModalLayout>
      )}

      {editDeliveryTimeModal && (
        <ModalLayout
          open={editDeliveryTimeModal}
          handleClose={handleCloseeditDeliveryTimeModalModal}
        >
          <div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
            <p className="text-[16px] font-semibold">Edit delivery time</p>
            <form action="" className="flex flex-col gap-2 w-full">
              <div className="w-full flex flex-col gap-1">
                <label htmlFor="" className="text-[14px]">
                  Time
                </label>
                <input
                  onChange={handleTimeChange}
                  value={orderDeliveryTime}
                  type="time"
                  className="outline-none px-2 py-2 rounded-md border"
                />
              </div>
              <div className="flex flex-row-reverse gap-2 w-full">
                <button
                  onClick={updateOrderDeliveryTime}
                  className="px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md"
                >
                  Continue
                </button>
                <button
                  onClick={handleCloseeditDeliveryTimeModalModal}
                  className="px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </ModalLayout>
      )}

      {orderData && !processing ? (
        <>
          <div className=" px-4 md:px-20 flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
            <div className="flex flex-row justify-between">
              <div
                onClick={() => navigate(-1)}
                className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] rounded-lg"
              >
                <img src={back} alt="" />
                Back
              </div>

              <button
                onClick={() => setShowDropDownOptions(!showDropDownOptions)}
                type="button"
                class="mb-1 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white"
                data-modal-hide="authentication-modal"
              >
                <img
                  // onMouseEnter={() => setShow(part.id)}
                  // onMouseLeave={() => setShow(-1)}
                  // onClick={() => toggleOpen(part.id)}
                  src={more}
                  alt=""
                  className="float-left md:float-right cursor-pointer"
                />

                {showDropDownOptions && (
                  <div
                    className={` border border-gray-200 w-40 top-5 mt-2 bg-white z-50 absolute right-4 rounded-md shadow-md p-0`}
                  >
                    <div
                      onClick={(e) => {
                        setOpenInvoiceModal(true);
                        setShowDropDownOptions(false);
                      }}
                      className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                    >
                      <p className="font-satoshi text-xs">Invoice</p>
                    </div>
                    {orderData?.order_type == "B2B" ? (
                      <div
                        onClick={(e) =>
                          updateOrderDetails({ action: "mark_as_b2c_order" })
                        }
                        className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                      >
                        <p className="font-satoshi text-xs text-[#BF3054]">
                          Mark order as B2C
                        </p>
                      </div>
                    ) : (
                      <div
                        onClick={(e) =>
                          updateOrderDetails({ action: "mark_as_b2b_order" })
                        }
                        className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                      >
                        <p className="font-satoshi text-xs text-[#BF3054]">
                          Mark order as B2B
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </button>
            </div>
            <div className="flex flex-row gap-1">
              <p className="font-satoshi text-[#030A1A] text-[16px] md:text-[20px] font-semibold">
                ORDER
              </p>
              <p className="font-satoshi text-[#030A1A] text-[16px] md:text-[20px] font-semibold">
                #{orderData?.order_id}
              </p>
              <img
                src={copy}
                alt=""
                className="hover:cursor-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(`${orderData?.order_id}`);
                }}
              />

              <Link
                to={
                  orderData?.referral_code !== null
                    ? `${ENDPOINT_DSF}/${orderData?.referral_code ? orderData?.referral_code : ""
                    }`
                    : ""
                }
              >
                <p className="font-satoshi text-[#030A1A] text-[16px] md:text-[20px] font-semibold cursor-pointer pl-3 font-semibold uppercase">
                  Code:
                  {orderData?.referral_code !== null
                    ? orderData?.referral_code
                    : orderData?.redeem_codes?.length > 0
                      ? orderData?.redeem_codes.map(
                        (rcode) => rcode.promo_code.code
                      )
                      : "-"}
                </p>
              </Link>
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              <p className="font-satoshi text-[#808491] text-[14px] md:text-[18px]">
                {orderData?.num_lines}{" "}{orderData?.num_lines === 1 ? "item" : "items"}
              </p>
              <p className="font-satoshi text-[#030A1A] text-[14px] md:text-[18px] font-semibold">
                Ksh {formatNumber(orderData?.totals, 0, 0)}
              </p>

              <div
                onClick={() => handleShowOrderChange(orderData?.id)}
                className={`
              ${orderData?.status === "New"
                    ? "bg-[#B9E38D] text-[#333B51]"
                    : orderData?.status === "Cancelled"
                      ? "bg-[#DE6972] text-white"
                      : orderData?.status === "Confirmed"
                        ? "bg-[#54D3CA] text-white"
                        : // : orderData?.status === 'Complete' ? 'bg-[#54D3CA] text-white'

                        orderData?.status === "Dispatched"
                          ? "bg-[#8CD1FC] text-white"
                          : orderData?.status === "Delivered"
                            ? "bg-[#3E5DDD] text-white"
                            : orderData?.status === "To be returned"
                              ? "bg-[#FCB28E] text-white"
                              : orderData?.status === "To be replaced"
                                ? "bg-[#F0A6A5] text-white"
                                : orderData?.status === "Complete"
                                  ? "bg-[#059D5F] text-white"
                                  : orderData?.status === "To be repaid"
                                    ? "bg-[#576298] text-white"
                                    : "bg-[#576298] text-white"
                  }
               rounded px-4 text-[14px] md:text-[18px] text-center relative py-2 cursor-pointer font-satoshi text-xs flex flex-row justify-between w-[50%] md:w-[30%]  `}
              >
                {processingStatus ? "Processing..." : orderData?.status}
                {
                  open.includes(orderData?.id) ? (
                    // arrow-down
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className=" h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 15.75l7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className=" h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  )

                  // arrow-up
                }

                {open.includes(orderData?.id) && (
                  <div
                    className={`bg-white border-4 w-full border-white rounded-md p-1 flex flex-col gap-2  hide-scrollbar overflow-y-scroll absolute top-8 z-50 shadow-2xl right-0 `}
                  >
                    {statusOptions
                      .filter(
                        (statusOption) =>
                          statusOption.name !== orderData?.status
                      )
                      .map((status) => (
                        <div
                          onClick={() => orderStatusChangeDialog(status.name)}
                          className={`bg-[${status.color}] rounded-md px-4 py-1.5 cursor-pointer`}
                        >
                          <p className="text-[13px] font-satoshi cursor-pointer">
                            {status.name}
                          </p>
                        </div>
                      ))}
                  </div>
                )}
              </div>
              <p className="font-satoshi text-[#808491] text-[14px] md:text-[18px] ">
                <Moment format="DD MMM Y">{orderData?.date_created}</Moment>
              </p>

              <div className="">
                {shelfLifeStatuses.includes(orderData?.status) && (
                  <OrderShelfLife
                    datePlaced={orderData?.date_created}
                    startDateTime={orderData?.date_disptached}
                    endDateTime={orderData?.date_delivered}
                  />
                )}

                <DeliveryTimer
                  // deliveryTo="2023-05-19T15:30:00Z"
                  deliveryTo={orderData?.delivery_window_to}
                />
              </div>
            </div>
            {false && (
              <div className="flex flex-row gap-2">
                <div
                  onClick={() => setActiveTab(0)}
                  className={` cursor-pointer font-satoshi ${activeTab === 0 ? "text-[#030A1A]" : "text-[#808491]"
                    } font-semibold text-[20px]`}
                >
                  Details
                </div>
                <div
                  onClick={() => setActiveTab(1)}
                  className={` cursor-pointer font-satoshi ${activeTab === 1 ? "text-[#030A1A]" : "text-[#808491]"
                    } font-semibold text-[20px]`}
                >
                  Timeline
                </div>
              </div>
            )}

            {activeTab === 0 && (
              <>
                <div className="flex flex-col md:flex-row  w-full gap-2">
                  <div className="flex flex-col border w-full border-[#E5E6E9] rounded-lg py-2">
                    <div className="">
                      <p className="font-satoshi text-[#030A1A] px-2 font-semibold text-[18px]">
                        Buyer details
                      </p>
                    </div>
                    <div className="border-b w-full border-[#E5E6E9]"></div>
                    <div className="flex flex-col px-2 py-1">
                      <div className="flex flex-row gap-2">
                        <div className="">
                          <Avatar
                            name={
                              orderData?.passport.first_name
                                ? `${orderData?.passport.first_name} ${orderData?.passport.last_name}`
                                : orderData?.passport.name
                            }
                            size="60"
                            className="mr-2 rounded-full shadow"
                          />
                        </div>
                        <div className="flex flex-1 flex-col gap-0 md:gap-2 ">
                          <p className="font-satoshi text-[#030A1A] text-xs md:text-[18px] font-semibold">
                            {orderData?.passport.first_name
                              ? `${orderData?.passport.first_name} ${orderData?.passport.last_name}`
                              : orderData?.passport.name}
                          </p>
                          <a href={`tel:+${orderData?.passport.phone_number}`}>
                            <div className="flex flex-row gap-2">
                              <div className="rounded-full border border-red-500 p-2 h-8">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-3 h-3 text-red-500"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                  />
                                </svg>
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="font-satoshi text-[#19223B] text-[16px]">
                                  +{orderData?.passport.phone_number}
                                </p>
                              </div>
                            </div>
                          </a>

                          <p className="font-satoshi text-[#999DA8] text-xs md:text-[16px]">
                            {orderData?.shipping_address?.town}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col border w-full border-[#E5E6E9] rounded-lg py-2">
                    <div className="">
                      <p className="font-satoshi text-[#030A1A] px-2 font-semibold text-[18px]">
                        Lead details
                      </p>
                    </div>
                    <div className="border-b w-full border-[#E5E6E9]"></div>
                    <div className="flex flex-col px-2 py-1">
                      <div className="flex flex-row gap-2">
                        <div className="">
                          <Avatar
                            name={
                              orderData?.passport?.referrer?.first_name
                                ? `${orderData?.passport?.referrer?.first_name} ${orderData?.passport?.referrer?.last_name}`
                                : orderData?.passport?.referrer?.username
                            }
                            size="60"
                            className="mr-2 rounded-full shadow"
                          />
                        </div>
                        <div className="flex flex-1 flex-col gap-0 md:gap-2 justify-center ">
                          <p className="font-satoshi text-[#030A1A] text-xs md:text-[18px] font-semibold">
                            {orderData?.passport?.referrer?.first_name
                              ? `${orderData?.passport?.referrer?.first_name} ${orderData?.passport?.referrer?.last_name}`
                              : orderData?.passport?.referrer?.username}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row justify-between">
                  <p className="font-satoshi text-[#030A1A] px-2 font-semibold text-[18px]">
                    Parts {orderData?.item_count && `(${orderData?.item_count})`}
                  </p>

                  <div className="flex flex-col md:flex-row gap-2">
                    {editableStatuses.includes(orderData?.status) &&
                      !updateOrderData && (
                        <button
                          onClick={() => setUpdateOrderData(true)}
                          className="text-white text-center rounded-md text-[14px] font-satoshi flex flex-row gap-1 px-2 py-1 bg-[#19223B] items-center justify-center"
                        >
                          Edit order
                        </button>
                      )}

                    {editableStatuses.includes(orderData?.status) &&
                      updateOrderData && (
                        <button
                          onClick={() => setUpdateOrderData(false)}
                          className="text-center rounded-md text-[14px] font-satoshi flex flex-row gap-1 px-2 py-1 bg-gray-200 items-center justify-center"
                        >
                          Cancel edit
                        </button>
                      )}
                    {editableStatuses.includes(orderData?.status) &&
                      updateOrderData && (
                        <div className="flex flex-row gap-2">
                          {false &&
                            <button
                              onClick={() => setAddLineCostModal(true)}
                              className="text-white text-center rounded-md text-[14px] font-satoshi flex flex-row gap-1 px-2 py-1 bg-[#19223B] items-center justify-center"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6v12m6-6H6"
                                />
                              </svg>
                              Add Costs
                            </button>
                          }



                          <button
                            onClick={() => setAddServiceJobModal(true)}
                            className="text-white text-center rounded-md text-[14px] font-satoshi flex flex-row gap-1 px-2 py-1 bg-[#19223B] items-center justify-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 6v12m6-6H6"
                              />
                            </svg>
                            Add Service
                          </button>
                          <button
                            onClick={() => setAddLineModal(true)}
                            className="text-white text-center rounded-md text-[14px] font-satoshi flex flex-row gap-1 px-2 py-1 bg-[#19223B] items-center justify-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 6v12m6-6H6"
                              />
                            </svg>
                            Add part
                          </button>

                          <button
                            onClick={() => setEditOrderDetailsModal(true)}
                            className="text-white text-center rounded-md text-[14px] font-satoshi flex flex-row gap-1 px-2 py-1 bg-[#19223B] items-center justify-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                            Edit Order Details
                          </button>
                        </div>
                      )}
                  </div>
                </div>
                <div className="pt-2 w-full  mt-4 rounded-lg border border-gray-200">
                  <div className="w-full overflow-x-scroll hide-scrollbar">
                    <table class="table-auto w-full ">
                      <thead className="pl-2 py-2">
                        <tr className="pl-1 border-b border-b-gray-200 ">
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Product
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Tags
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Shipping Mode
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Quantity
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Item Price
                          </th>

                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Sales Price
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Paid
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Merchant price
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400"></th>
                          {editableStatuses.includes(orderData?.status) && (
                            <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                              Actions
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {orderData?.lines.map((line) => (
                          <tr className="p-2 border-b border-b-gray-200 relative">
                            <td className="p-4 text-xs md:text-sm text-left flex flex-row gap-1 cursor-pointer font-satoshi">
                              <div className="">
                                {line?.carpart?.thumbnail_urls &&
                                  line?.carpart?.thumbnail_urls?.length > 0 ? (
                                  <img
                                    src={line.carpart.thumbnail_urls[0]}
                                    className="h-14 w-14 border rounded"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={placeholder}
                                    className="h-14 w-14 border rounded"
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="flex flex-col gap-1 font-semibold">
                                <a
                                  href={`https://sparepap.com/part/${line?.carpart?.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <p className="font-satoshi text-[#19223B] text-[14px]">
                                    {line?.title}
                                  </p>
                                </a>

                                <p className="font-satoshi text-[#19223B] text-[14px]">
                                  {line?.merchant?.merchant_name}
                                </p>

                                <p className="font-satoshi text-[#19223B] text-[14px]">
                                  {line?.merchant?.merchant_address}
                                </p>

                                <p className="font-satoshi text-[#19223B] text-[14px]">
                                  {line?.merchant?.phone_number
                                    ? `+ ${line?.merchant?.phone_number}`
                                    : null}
                                </p>
                                <p className="font-satoshi text-[#19223B] text-[14px]">
                                  {line?.merchant?.email_address}
                                </p>
                              </div>
                            </td>
                            <td className="p-4 text-xs md:text-[12px] text-left font-satoshi text-[#808491]">
                              <p className="font-satoshi text-[12px] uppercase">
                                {line?.status && (
                                  <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 m-1">
                                    {line?.status}
                                  </span>
                                )}
                                {line?.carpart?.is_expired && (
                                  <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                    Enquiry
                                  </span>
                                )}
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-[12px] text-left font-satoshi text-[#808491]">
                              {/* Shipping Mode */}
                              <p className="font-satoshi text-[12px] uppercase">
                                {orderData?.shipping_method}
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-[12px] text-left font-satoshi">
                              {/* Quantity */}
                              {line?.quantity}{" "}
                              {line?.quantity === 1 ? "item" : "items"}
                            </td>
                            <td className="py-4 px-1 text-xs md:text-[12px] text-left font-satoshi">
                              {/* price */}
                              {"Ksh. " + formatNumber(line?.price, 0, 0)}
                            </td>
                            <td className="py-4 px-1 text-xs md:text-[12px] text-left font-satoshi">
                              {/* Sales Price */}
                              Ksh.{" "}
                              {formatNumber(line?.price * line?.quantity, 0, 0)}
                            </td>
                            <td className="py-4 px-1 text-xs md:text-[12px] text-left font-satoshi">
                              {/*  Paid */}
                              <p
                                className={`font-satoshi text-[11px] flex flex-row uppercase ${orderData?.is_paid
                                  ? "text-cyan-500"
                                  : "text-[#BF3054]"
                                  }`}
                              >
                                {orderData?.is_paid ? "Yes" : "No"}
                              </p>
                            </td>
                            <td className="py-4 text-xs md:text-[12px] text-left font-satoshi ">
                              {/* Merchant price */}
                              Ksh.{" "}
                              {formatNumber(
                                line?.merchant_price,
                                0,
                                0
                              )}
                            </td>

                            <td className="py-4 px-2  text-xs md:text-[12px] text-left font-satoshi ">
                              {!statuses.includes(orderData?.status) &&
                                orderData?.is_paid &&
                                !line?.merchant_paid && (
                                  <button
                                    onClick={() => {
                                      handlesetPayMerchant();
                                      handlePayMerchant(line.id);
                                    }}
                                    className={`${payMerchant === "Confirm"
                                      ? "bg-red-200 text-red-600"
                                      : "bg-black text-white "
                                      } text-center font-satoshi text-[12px] px-4 py-1 flex flex-row gap-2 rounded-md `}
                                  >
                                    {payMerchant}
                                    {payMerchant === "Confirm" && (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-6 h-4 text-red-600"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                )}
                            </td>
                            {editableStatuses.includes(orderData?.status) && (
                              <td className="py-4 text-xs md:text-[12px] text-left font-satoshi ">
                                <svg
                                  onClick={() => toggleOpenOrderLine(line.id)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="cursor-pointer w-6 h-6"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                  />
                                </svg>
                              </td>
                            )}
                            {openOrderLine.includes(line.id) && line?.carpart && (
                              <>
                                <div
                                  ref={orderLienRef}
                                  className={`border border-gray-200 w-40 top-8 mt-4 bg-white z-50 absolute right-4 rounded-md shadow-md pr-4 py-2 pl-2 `}
                                >
                                  <div
                                    onClick={() =>
                                      toggleOpenOrderLineEdit(line)
                                    }
                                    className="flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer"
                                  >
                                    <div className="flex flex-row gap-2">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="w-4 h-4"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                      <p className="font-satoshi text-[12px]">
                                        Edit
                                      </p>
                                    </div>
                                  </div>

                                  <div
                                    onClick={(e) => {
                                      handleSetDeleteOrderLine();
                                      handleDeleteOrderLine(line.id);
                                    }}
                                    className="flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer"
                                  >
                                    <div className="flex flex-row gap-2">
                                      {deleteOrderLine === "Delete" ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          className="w-4 h-4 text-[#BF3054]"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          className="w-4 h-4 text-[#BF3054]"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                                          />
                                        </svg>
                                      )}
                                      <p className="font-satoshi text-sm text-[#BF3054]">
                                        {deleteOrderLine}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {openOrderLineEdit.includes(line.id) && (
                              <ModalLayout
                                open={openOrderLineEdit.includes(line.id)}
                                handleClose={handleCloseLineEditModal}
                              >
                                <div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
                                  <p className="text-[16px] font-semibold">
                                    Edit Order Line
                                  </p>
                                  <form
                                    action=""
                                    className="flex flex-col gap-2 w-full"
                                    onSubmit={(e) =>
                                      handleEditOrderLineFormSubmit(e, line)
                                    }
                                  >
                                    <div className="w-full flex flex-col gap-1">
                                      <label
                                        htmlFor=""
                                        className="text-[14px] font-light"
                                      >
                                        Part name
                                      </label>
                                      <input
                                        value={line.carpart.name}
                                        type="text"
                                        className="outline-none px-2 py-2 rounded-md border"
                                        readOnly
                                      />
                                    </div>
                                    <div className="w-full flex flex-col gap-1">
                                      <label
                                        htmlFor=""
                                        className="text-[14px] font-light"
                                      >
                                        Quantity
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          setOrderLineQty(e.target.value)
                                        }
                                        value={orderLineQty}
                                        type="number"
                                        className="outline-none px-2 py-2 rounded-md border"
                                        min={1}
                                      />
                                    </div>
                                    <div className="w-full flex flex-col gap-1">
                                      <label
                                        htmlFor=""
                                        className="text-[14px] font-light"
                                      >
                                        Price
                                      </label>
                                      <input
                                        onChange={(e) => setOrderLinePrice(e.target.value)}
                                        value={orderLinePrice}
                                        type="text"
                                        className="outline-none px-2 py-2 rounded-md border"
                                        min={1}
                                      />
                                    </div>

                                    <div className="w-full flex flex-col gap-1">
                                      <label
                                        htmlFor=""
                                        className="text-[14px] font-light"
                                      >
                                        Total Merchant Price <strong>(Qty * Price)</strong>
                                      </label>
                                      <input
                                        onChange={(e) => setOrderLineMerchantPrice(e.target.value)}
                                        value={orderLineMerchantPrice}
                                        type="text"
                                        className="outline-none px-2 py-2 rounded-md border"
                                        min={1}
                                      />
                                    </div>

                                    <div className="flex flex-col gap-1 w-full mt-2 mb-2">
                                      <p className="text-[14px] font-light">
                                        Status
                                      </p>
                                      <Select
                                        options={ORDER_LINE_STATUSES}
                                        onChange={(e) => {
                                          handleOrderLineStatusChange(e);
                                        }}
                                      />
                                    </div>

                                    <div className="flex flex-row-reverse gap-2 w-full">
                                      <button
                                        type="submit"
                                        className="px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md"
                                      >
                                        Save
                                      </button>
                                      <button
                                        onClick={handleCloseLineEditModal}
                                        className="px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100"
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </ModalLayout>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="flex flex-col gap-3 border justify-around  w-full  border-[#E5E6E9] rounded-lg py-2">
                  {orderData?.transaction?.map((trans) =>
                    trans?.payments?.map((payment) => (
                      <div className="flex flex-col md:flex-row gap-3 py-3 px-4">
                        <p className="font-satoshi text-[#19223B] px-2  text-xs md:text-[16px]">
                          Paid via MPESA
                        </p>
                        <p className="font-satoshi uppercase text-[#4D5364]  text-xs md:text-[16px]">
                          {payment.PhoneNumber}
                        </p>
                        <p className="font-satoshi text-[#19223B] px-2  text-xs md:text-[16px]">
                          Transaction ID:
                        </p>
                        <p className="font-satoshi uppercase text-[#4D5364] text-xs md:text-[16px]">
                          {payment.TransID}
                        </p>
                      </div>
                    ))
                  )}
                  {orderData?.transaction?.map(
                    (trans) =>
                      trans?.payments?.length > 0 && (
                        <div className="border-b w-full border-[#E5E6E9]"></div>
                      )
                  )}

                  <div className="flex flex-col md:flex-row-reverse   gap-3 py-2  w-full ">
                    <div className="flex flex-col gap-2 px-8">
                      <div className="grid grid-cols-2 gap-4">
                        <div className="col-span-1 gap-3 flex flex-col">
                          <p className="font-satoshi text-xs md:text-[16px]  text-black font-satoshi px-2 uppercase">
                            cart total:
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]  text-black font-satoshi px-2 uppercase">
                            DISCOUNT:
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]  text-black font-satoshi px-2 uppercase">
                            Delivery:
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]  text-black font-satoshi px-2 uppercase">
                            order total:
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]  text-black font-satoshi px-2 uppercase">
                            Merchant Pay:
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]  text-black font-satoshi px-2 uppercase">
                            Sales revenue:
                          </p>
                        </div>
                        <div className="col-span-1 gap-3 flex flex-col">
                          <p className="font-satoshi text-xs md:text-[16px]   text-[#030A1A] px-2 ">
                            {orderData.total_price
                              ? "Ksh. " +
                              formatNumber(
                                parseInt(orderData.total_price),
                                0,
                                0
                              )
                              : "-"}
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]  text-[#666C7C] px-2">
                            -
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]  text-[#030A1A] px-2">
                            {orderData.delivery_cost
                              ? "Ksh. " +
                              formatNumber(orderData.delivery_cost, 0, 0)
                              : "-"}
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]  text-[#030A1A] px-2">
                            {orderData.totals
                              ? "Ksh. " + formatNumber(orderData.totals, 0, 0)
                              : "-"}
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]   text-[#030A1A] px-2 ">
                            {" "}
                            Ksh. {formatNumber(orderData?.vendor_revenue, 0, 0)}{" "}
                          </p>
                          <p className="font-satoshi text-xs md:text-[16px]   text-[#030A1A] px-2 ">
                            {" "}
                            Ksh. {formatNumber(orderData?.net_revenue, 0, 0)}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Reviews */}
                <div className="flex flex-col md:flex-row w-full gap-4">
                  <div className="flex-1 border rounded-lg bg-white shadow-md p-4">
                    <p className="text-[#030A1A] text-lg font-semibold mb-4">
                      Ratings
                    </p>
                    <div className="mb-4">
                      <p className="text-black text-base font-bold font-['Satoshi']">
                        Customer Service Rating:
                      </p>
                      <StarRating value={orderData?.customer_service_rating} />
                    </div>
                    <div>
                      <p className="text-black text-base font-bold font-['Satoshi']">
                        Delivery Speed Rating:
                      </p>
                      <StarRating value={orderData?.delivery_rating} />
                    </div>
                  </div>
                  <div className="flex-1 border rounded-lg bg-white shadow-md p-4">
                    <p className="text-[#030A1A] text-lg font-semibold mb-4">
                      Customer Feedback
                    </p>
                    <div className="text-black text-base font-medium font-['Satoshi']">
                      {orderData?.review}
                    </div>
                  </div>
                </div>
                {/* End of Reviews */}

                <div className="flex flex-col md:flex-row  w-full gap-2">
                  <div className="flex flex-col border  w-full  border-[#E5E6E9] rounded-lg py-2">
                    <div className="">
                      <p className="font-satoshi text-[#030A1A] px-4 font-semibold text-[18px]">
                        Dispatch details
                      </p>
                    </div>
                    <div className="border-b w-full border-[#E5E6E9]"></div>
                    <div className="flex flex-col px-4 py-2">
                      <p className="font-satoshi text-xs md:text-[12px] font-semibold text-[#666C7C] capitalize">
                        Delivery address
                      </p>
                      <p className="font-satoshi text-[#030A1A] font-semibold text-[14px]">
                        {orderData?.shipping_address?.town}
                      </p>
                      <div className="border-b w-full border-[#E5E6E9] py-2"></div>
                    </div>
                    <div className="flex flex-col px-4 py-2">
                      <p className="font-satoshi text-xs md:text-[12px] font-semibold text-[#666C7C] capitalize">
                        Fullfilment partner
                      </p>
                      {/* { orderData.delivery_partner && */}
                      <div className="flex flex-row gap-2">
                        <p className="font-satoshi text-[#030A1A] font-semibold text-[16px]">
                          {orderData?.delivery_partner?.name}
                        </p>
                        <div className="flex flex-row gap-2">
                          <div className="rounded-full border border-red-500 p-2 h-8">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              className="w-3 h-3 text-red-500"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                              />
                            </svg>
                          </div>
                          <div className="flex flex-col gap-1">
                            <p className="font-satoshi text-[#19223B] text-[16px]">
                              +{orderData?.delivery_partner?.phone_number}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {orderData?.rider_details && (
                    <div className="flex flex-col md:flex-row  w-full gap-2">
                      <div className="flex flex-col border w-full border-[#E5E6E9] rounded-lg py-2">
                        <div className="">
                          <p className="font-satoshi text-[#030A1A] px-2 font-semibold text-[18px]">
                            Rider details
                          </p>
                        </div>
                        <div className="border-b w-full border-[#E5E6E9]"></div>
                        <div className="flex flex-col px-2 py-1">
                          <div className="flex flex-row gap-2">
                            <div className="flex flex-col gap-0 md:gap-1 ">
                              <p className="font-satoshi text-[#030A1A] text-xs md:text-[18px] font-semibold">
                                {orderData.rider_details.name}
                              </p>
                              <a
                                href={`tel:+${orderData.rider_details.phone_number}`}
                              >
                                <div className="flex flex-row gap-2">
                                  <div className="rounded-full border  p-2 h-8">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-3 h-3"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                      />
                                    </svg>
                                  </div>
                                  <div className="flex flex-col gap-1">
                                    <p className="font-satoshi text-[#19223B] text-[16px]">
                                      {orderData.rider_details?.phone_number}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <RecentOrders customerID={orderData?.passport?.id} />

                <OrderMeta orderData={orderData} />

                <div className="border border-[#E5E6E9] rounded-lg py-2 shadow-sm">
                  <p className="font-satoshi text-[#030A1A] px-4 py-2 font-semibold text-[18px]">
                    Order notes
                  </p>
                  <div className="border-b w-full border-[#E5E6E9]"></div>
                  <div className="flex flex-col p-2 w-full ">
                    <form action="" className="w-full">
                      <input
                        type="text"
                        value={orderNotes}
                        onChange={(e) => setOrderNotes(e.target.value)}
                        placeholder="Write a note"
                        className="w-full rounded-lg border outline-none font-satoshi px-4 py-2 placeholder:text-[#666C7C]"
                      />
                      {orderNotes && (
                        <div className="flex flex-row-reverse w-full py-4 gap-2">
                          <button
                            onClick={submitOrderNotes}
                            className="bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md"
                          >
                            Continue
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>

                <div className="flex flex-row">
                  {/* <div onClick={() => setOpenSendSMSModal(true)} */}
                  <div
                    onClick={() => handleOpenSMSModal()}
                    className="flex flex-row gap-1 cursor-pointer px-3 py-1 bg-[#F2F2F4] rounded-lg"
                  >
                    Assign a Courier Partner
                  </div>
                </div>
                {openSendSMSModal && (
                  <>
                    <div className="h-screen pt-14  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none">
                      <div className="relative p-4 w-full md:w-[50%] h-full md:h-auto">
                        <div
                          ref={cancelRef}
                          className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                        >
                          <div className="flex items-start justify-end p-2 rounded-t "></div>

                          <div className="relative p-3 flex-auto">
                            <div className="px-4 pt-4 pb-3 w-full">
                              <div className="flex flex-col">
                                <div
                                  onClick={() => setOpenSendSMSModal(false)}
                                  className=""
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6 float-right cursor-pointer"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </div>
                                <div className="flex flex-row gap-2 justify-between">
                                  <div className="flex flex-row gap-2">
                                    <img src="" alt="" />
                                    <h2 className="text-[#030A1A] font-satoshi font-bold text-[24px] mb-4">
                                      Assign a Courier Partner
                                    </h2>
                                  </div>
                                </div>

                                <div className="flex flex-col gap-2 py-2">
                                  <p className="flex flex-row py-1 text-[14px] font-satoshi text-[#333B51]">
                                    Select delivery partner{" "}
                                  </p>
                                </div>
                                <div className="flex flex-col gap-2">
                                  <select
                                    className="outline-none py-2 rounded-md border border-[#E5E6E9] font-satoshi text-[16px]"
                                    value={selectedPartner}
                                    onChange={(e) =>
                                      setSelectedPartner(e.target.value)
                                    }
                                  >
                                    {deliveryPartners.map((o) => (
                                      <option key={o.id} value={o.id}>
                                        {o.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="flex flex-col gap-2  pt-2">
                                  <label
                                    htmlFor=""
                                    className="text-[#666C7C] text-[13px]"
                                  >
                                    Message
                                  </label>
                                  <textarea
                                    cols="5"
                                    rows="10"
                                    onChange={(event) =>
                                      setOrderSMS(event.target.value)
                                    }
                                    className="px-2 border flex flex-row gap-2 outline-none font-satoshi border-[#BF3054] text-black rounded-md w-full placeholder:text-black text-[14px]"
                                  >
                                    {orderSMS}
                                  </textarea>
                                </div>
                              </div>
                              <div className="flex flex-row-reverse w-full py-4 gap-2">
                                <button
                                  onClick={sendOrderSMS}
                                  className="bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md"
                                >
                                  Continue
                                </button>
                                <button
                                  onClick={() => setOpenSendSMSModal(false)}
                                  className="border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      modalbackdrop=""
                      className="h-screen bg-gray-900/70 fixed inset-0 z-50"
                    />
                  </>
                )}

                {orderData.logs.map((log) => (
                  <div className="flex flex-col py-2 w-full">
                    <div className="flex flex-row gap-2 w-full">
                      <div className="">
                        <Avatar
                          name={
                            log?.user?.first_name
                              ? `${log?.user?.first_name} ${log?.user?.last_name}`
                              : log?.user?.email
                          }
                          size="50"
                          className="h-12 w-12 mr-2 rounded-full shadow"
                        />
                      </div>
                      <div className="flex flex-col gap-3 flex-1">
                        <div className="flex flex-row gap-2">
                          <p className="font-satoshi text-[#030A1A]  text-[18px]">
                            {log?.user?.first_name
                              ? `${log?.user?.first_name} ${log?.user?.last_name}`
                              : log?.user?.email}
                          </p>

                          <span>.</span>

                          <p className="font-satoshi text-[#666C7C]  text-[18px]">
                            <Moment
                              date={log?.date_created}
                              format="D MMM"
                              trim
                            />
                          </p>
                          <p className="font-satoshi text-[#666C7C]  text-[18px]">
                            <Moment
                              date={log?.date_created}
                              format="hh:mm"
                              trim
                            />
                          </p>
                        </div>
                        <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md">
                          <p className="font-satoshi text-[#030A1A]  text-[14px]">
                            {log?.system_message}
                          </p>
                        </div>
                        {log?.message && (
                          <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md">
                            <p className="font-satoshi text-[#030A1A]  text-[14px]">
                              {log?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                {false && (
                  <div className="flex flex-col border w-full md:w-[80%] border-[#E5E6E9] rounded-lg py-2">
                    <div className="">
                      <p className="font-satoshi text-[#030A1A] px-2 font-semibold text-[18px]">
                        Dispatch details
                      </p>
                    </div>
                    <div className="border-b w-full border-[#E5E6E9]"></div>
                    <div className="flex flex-col px-2 py-2">
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-1">
                          <p className="font-satoshi text-[#999DA8] px-2 text-[14px]">
                            Delivery address
                          </p>

                          <p className="font-satoshi text-[#030A1A] px-2 text-xs md:text-[18px] font-semibold">
                            123 Anywhere street, Nairobi
                          </p>
                          <div className="border-b w-full border-[#E5E6E9]"></div>
                        </div>
                        <div className="flex flex-col gap-1 py-2">
                          <p className="font-satoshi text-[#999DA8] px-2 text-[14px]">
                            Rider details
                          </p>
                          <div className="flex flex-row gap-2 justify-between">
                            <div className="px-2 flex flex-row gap-2">
                              <Avatar
                                name="Juma Mutiso"
                                size="50"
                                color="#F8DDDF"
                                className="mr-2 rounded-full shadow"
                              />
                              <div className="flex flex-col">
                                <p className="font-satoshi text-[#030A1A] px-2 text-[18px] font-semibold">
                                  Juma Mutiso
                                </p>

                                <div className="flex flex-row pl-1">
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <img src={star} alt="" />
                                  <p className="font-satoshi text-[#19223B] px-2 ">
                                    4.1
                                  </p>
                                </div>
                                <p className="font-satoshi text-[#030A1A] px-2 text-[16px] ">
                                  {" "}
                                  Bajaj Boxer 2015
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-row gap-2">
                              <div className="rounded-full border border-[#D95762] p-2 h-8">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-4 h-4 text-[#D95762]"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                  />
                                </svg>
                              </div>
                              <p className="font-satoshi text-xs md:text-[16px] text-[#030A1A]">
                                0712 345 678
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {activeTab === 1 && (
              <div className="">
                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#8FA7F0] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>

                    {/* <div className="border border-[#CCCED3] rounded-full p-2 h-7 w-7">
                    <div className="border border-[#CCCED3] bg-[#CCCED3] rounded-full h-2.5 w-2.5"></div>
    
                    </div> */}
                    <div className="flex items-center justify-center h-full">
                      <div
                        className={`bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Order #S0012ANC created
                    </p>

                    <div className="flex flex-row gap-1">
                      <p className="font-satoshi text-xs  text-[#666C7C] text-[14px]">
                        Express delivery • Delivery Zone 1 • 14 km
                      </p>
                    </div>
                    <p className="font-satoshi text-xs  text-[#666C7C] text-[14px]">
                      11:22AM
                    </p>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#53D3CA] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>

                    <div className="flex items-center justify-center h-full">
                      <div
                        className={` bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Order accepted by merchant
                    </p>

                    <div className="flex flex-row gap-1">
                      <p className="font-satoshi text-xs  text-[#666C7C] text-[14px]">
                        Order wait time 1m 49s
                      </p>
                    </div>
                    <p className="font-satoshi text-xs  text-[#666C7C] text-[14px]">
                      11:24AM
                    </p>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#8BD2FC] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>

                    <div className="flex items-center justify-center h-full">
                      <div
                        className={` bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Courier assigned
                    </p>

                    <div className="flex flex-row gap-1">
                      <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                        12:11AM
                      </p>
                      <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                        Motorbike
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#8BD2FC] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>

                    <div className="flex items-center justify-center h-full">
                      <div
                        className={` bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Order picked up
                    </p>

                    <div className="flex flex-row gap-1">
                      <p className="font-satoshi text-xs  text-[#666C7C] text-[14px]">
                        12.22 PM
                      </p>
                      <p className="font-satoshi text-xs  text-[#666C7C] text-[14px]">
                        Part verified
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#3B61DD] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-full">
                      <div
                        className={` bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Order delivered to buyer
                    </p>
                    <div className="flex flex-col gap-1">
                      <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                        Address
                      </p>
                      <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                        14.57 PM
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#039C5F] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>
                    <div className="flex items-center justify-center h-full">
                      <div
                        className={` bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Payment received
                    </p>
                    <div className="flex flex-row gap-1">
                      <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                        Cash on delivery • MPESA • KSh 48,998
                      </p>
                    </div>
                    <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                      15:01 PM
                    </p>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#039C5F] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>

                    <div className="flex items-center justify-center h-full">
                      <div
                        className={` bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Receipt confirmed
                    </p>

                    <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                      14.59 PM
                    </p>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#039C5F] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>

                    <div className="flex items-center justify-center h-full">
                      <div
                        className={` bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Order dropped off
                    </p>

                    <div className="flex flex-row gap-1">
                      <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                        Lang’ata fulfilment center
                      </p>
                    </div>
                    <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                      11.22 PM
                    </p>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#039C5F] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>

                    <div className="flex items-center justify-center h-full">
                      <div
                        className={` bg-[#456DE6] border-[#456DE6] border place-items-center w-0.5   h-full`}
                      ></div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Order ends
                    </p>

                    <div className="flex flex-row gap-1">
                      <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                        Lang’ata fulfilment center
                      </p>
                    </div>
                    <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                      11.22 PM
                    </p>
                  </div>
                </div>

                <div className="flex flex-row gap-4">
                  <div className="flex flex-col h-28">
                    <div className="bg-[#DBB0BB] rounded-full p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="text-white w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <p
                      className={` text-[#030A1A] font-satoshi font-semibold text-[18px] mt-1 `}
                    >
                      Return request received
                    </p>

                    <div className="flex flex-row gap-1">
                      <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                        Lang’ata fulfilment center
                      </p>
                    </div>
                    <p className="font-satoshi text-xs  text-[#030A1A] text-[14px]">
                      11.22 PM
                    </p>
                    <div className="flex flex-col gap-1">
                      <p className="font-satoshi text-xs  text-[#333B51] text-[13px]">
                        Note
                      </p>
                      <div className="flex flex-row gap-2">
                        <div className="flex-[1] border rounded-md py-2 px-4 border-[#F7F7F8]">
                          <p className="font-satoshi text-xs  text-[#333B51] text-[16px]">
                            Merchant shipped the wrong item
                          </p>
                        </div>
                        <div className="">
                          <img
                            src="/cdn/images/lady-1.jpg"
                            className="rounded-full w-[36px] h-[36px]"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {changeStatusModal && orderData && (
            <ChangeStatusModal
              delPartners={deliveryPartners}
              order={orderData}
              newStatus={changeStatusModal}
              handleClose={closeChangeModal}
              handleSubmit={handleOrderStatusChange}
              changeTo={
                changeStatusModal === "New"
                  ? "New"
                  : changeStatusModal === "Confirmed"
                    ? "Confirmed"
                    : changeStatusModal === "Complete"
                      ? "Complete"
                      : changeStatusModal === "Dispatched"
                        ? "Dispatched"
                        : changeStatusModal === "Delivered"
                          ? "Delivered"
                          : changeStatusModal === "Cancelled"
                            ? "Cancelled"
                            : changeStatusModal === "Requires prepayment"
                              ? "Requires prepayment"
                              : changeStatusModal === "To be exchanged"
                                ? "To be exchanged"
                                : changeStatusModal === "Exchange complete"
                                  ? "Exchange complete"
                                  : changeStatusModal === "Rejected"
                                    ? "Rejected"
                                    : changeStatusModal === "Reject Completed"
                                      ? "Reject Completed"
                                      : ""
              }
            />
          )}
        </>
      ) : (
        <p className="font-satoshi text-[20px]">Processing</p>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default OrderDetailsPage;

// Function component for Star Rating
const StarRating = ({ value }) => {
  const [rating, setRating] = useState(value); // Initial rating value

  const handleClick = (newValue) => {
    if (newValue === rating) {
      // If the clicked star is the current rating, reset to 0 (no rating)
      setRating(0);
    } else {
      setRating(newValue);
    }
  };
  return (
    <div className="flex items-center">
      {[1, 2, 3, 4, 5].map((index) => (
        <svg
          key={index}
          className={`h-6 w-6 fill-current ${index <= rating ? "text-yellow-500" : "text-gray-300"
            } cursor-pointer`}
          onClick={() => handleClick(index)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 1l2.807 5.685 6.277.914-4.54 4.43 1.07 6.235L10 15.4l-5.615 2.859 1.07-6.235-4.54-4.43 6.277-.914L10 1z"
            clipRule="evenodd"
          />
        </svg>
      ))}
    </div>
  );
};
