import React, { useEffect, useState } from 'react'
import ReactCodeInput from 'react-code-input';
import { useNavigate } from 'react-router-dom';
import { SESSION_STORAGE_VERIFY_EMAIL, SESSION_STORAGE_VERIFY_ID } from '../constants';
import { ENDPOINT_DASHBOARD, ENDPOINT_ENTER_EMAIL } from '../routes';
import { AccessTokenObj, AccountObj, makeAuthenticatedRequest } from '../utils';


//TODO: Validate the verificationID

function EnterCode({ email, verificationID }) {
    const navigate = useNavigate();

    const props = {
        inputStyle: {
            margin: '5px',
            width: '40px',
            borderRadius: '3px',
            fontSize: '20px',
            fontWeight: '500',
            height: '45px',
            textAlign: 'center',
            backgroundColor: 'white',
            color: 'black',
            border: '1.5px solid #19223B',
            outlineColor: '#D95762'
        }
    }


    const mdProps = {
        inputStyle: {
            margin: '9px',
            width: '80px',
            borderRadius: '2px',
            fontSize: '20px',
            fontWeight: '500',
            height: '70px',
            textAlign: 'center',
            backgroundColor: 'white',
            color: 'black',
            border: '1.5px solid #19223B',
            outlineColor: '#D95762'
        }
    }

    const [processing, setProcessing] = useState(false)
    const [verificationCode, setVerificationCode] = useState();
    const [error, setError] = useState();
    const [deactivated, setDeactivated] = useState(false)
    const [timeLeft, setTimeLeft] = useState(60)

    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    useEffect(() => {
        if (timeLeft === 0) {
            setDeactivated(false);
        }
    }, [timeLeft]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    if (!verificationID || !email) {
        navigate(ENDPOINT_ENTER_EMAIL)
    }

    const onCodeSubmit = (e) => {
        e.preventDefault();
        console.log(verificationCode)
        if (!verificationCode || verificationCode.trim().length != 6) {
            setError("Enter a valid code")
            return
        }

        setProcessing(true)
        const params = { "code": verificationCode.trim() }
        console.log(params)
        makeAuthenticatedRequest(`/oms/email/${verificationID}/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setProcessing(false)
                if (data && data.user) {
                    new AccessTokenObj(data)
                    new AccountObj(data.user, data.permissions);
                    navigate(ENDPOINT_DASHBOARD)
                } else {
                    setError(data[0])
                }
            }).catch(() => {
                setError("Something went wrong, try again later")
            }).finally(() => {
                setProcessing(false)
            });
    }

    const resendVerificationCode = (e) => {
        e.preventDefault();
        if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
            setError("Enter a valid email")
            return
        }

        setProcessing(true)
        setDeactivated(true)
        const params = { "email": email }
        makeAuthenticatedRequest(`/oms/email/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setProcessing(false)
                if (!data || !data.ID) {
                    setError(data[0])
                }
            }).catch(() => {
                setError("Something went wrong, try again later")
            }).finally(() => {
                setProcessing(false)
            });
    }

    useEffect(() => {
        let intervalId = null;
        if (deactivated) {
            intervalId = setInterval(() => {
                setTimeLeft((timeLeft) => timeLeft - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
            setTimeLeft(60)
        };
    }, [deactivated]);
    return (
        <>
            {
                processing ?
                    <div class="flex items-center justify-center h-screen overflow-hidden">
                        <img src='/cdn/images/loading.gif' className='mt-10 mb-10 h-10 w-10' alt='Processing' />
                    </div>
                    :

                    <div className="w-full bg-white h-full px-4 md:px-28">
                        <div className="flex flex-col w-full h-full gap-2 justify-center md:justify-start items-cemter md:items-start ">
                            <img src='/cdn/images/logo.svg' className='mt-4 md:mt-[108px] mb-4 md:mb-[80px] h-10 md:h-16' alt='Sparepap Logo' />
                            {
                                error ?
                                    <div class="bg-red-100 w-full md:w-[600px] border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
                                        <div class="flex">
                                            <div class="py-2"><svg class="fill-current h-6 w-6 text-red-400 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                            <div>
                                                <p class="font-bold font-satoshi text-[20px]">Error</p>
                                                <p class="text-sm font-satoshi text-[16px]">{error}</p>
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    <div class="flex flex-col w-full">
                                        {/* <div class="py-1">
                                    <svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div> */}
                                        <div>
                                            <p class="text-[14px] md:text-[28px] mb-2 md:mb-[89px] text-left font-satoshi py-2 md:py-8 text-[#030A1A] font-semibold">Verify</p>
                                            {/* <p class="font-bold">Enter verification code</p> */}
                                            <p class="text-[14px] md:text-[20px] font-satoshi py-1 text-[#030A1A]">We sent you a verification code to your email.</p>
                                            <p class="text-[14px] md:text-[20px] font-satoshi py-1 text-[#030A1A]">Please enter the 6-digit code sent to your phone.</p>


                                        </div>
                                    </div>
                            }
                            <form className="mt-2 md:mt-6 w-full">
                                <div className="mb-2">
                                    <div>
                                        <div className="flex flex-row relative mt-1 rounded-md shadow-sm">
                                            <ReactCodeInput
                                                // value={verificationCode}
                                                fields={6}
                                                onChange={(e) => {
                                                    setVerificationCode(e);
                                                }}
                                                {...isMobile ? (props) : (mdProps)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button onClick={onCodeSubmit} className="w-full md:w-[600px] h-auto md:h-[48px] font-satoshi text-[16px] md:text-[20px] px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#19223B] rounded-md hover:bg-[#19223B] focus:outline-none focus:bg-[#19223B] BgColor">
                                        Continue
                                    </button>
                                </div>
                            </form>
                            <p class="text-[14px] md:text-[20px] text-left font-satoshi py-1">Did not receive an email? <span disabled={deactivated} onClick={resendVerificationCode} class={`rounded-full text-[16px] md:text-[20px]  ${deactivated ? 'text-gray-300' : ''} px-2 py-1 text-xs font-bold mr-3  cursor-pointer`}>Resend verification code</span>
                                {deactivated ? <span className="bg-gray-200 text-center font-satoshi rounded-md px-4 py-1">00:{timeLeft}</span> : null}
                            </p>
                        </div>
                    </div>
            }
            {false &&
                <div className='container-fluid home-parent w-full py-6 md:py-28 h-full overflow-hidden'>
                    <div className='container-fluid relative mt-5 mb-5'>
                        <div className='container mx-auto'>
                            <div className='w-full flex flex-row justify-center items-start '>
                                <div className='flex flex-col w-9/12 '>
                                    <div className='w-full'>
                                        <div className="relative flex flex-col justify-center overflow-hidden pt-10 pb-10">

                                            <div className="w-full m-auto bg-white relative lg:max-w-sm">
                                                <img src='/cdn/images/logo.svg' className='mt-10 mb-10' alt='Sparepap Logo' />
                                                {
                                                    error ?
                                                        <div class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
                                                            <div class="flex">
                                                                <div class="py-1"><svg class="fill-current h-6 w-6 text-red-400 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                                                <div>
                                                                    <p class="font-bold">Error</p>
                                                                    <p class="text-sm">{error}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                                                            <div class="flex">
                                                                <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                                                <div>
                                                                    <p class="font-bold">Enter verification code</p>
                                                                    <p class="text-sm">We sent you a verification code to your email. Enter it below. </p>
                                                                    <p class="text-sm">Did not receive an email? <span onClick={resendVerificationCode} class="rounded-full bg-teal-500 uppercase px-2 py-1 text-xs font-bold mr-3 text-white cursor-pointer">Resend verification code</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                                <form className="mt-6">
                                                    <div className="mb-2">
                                                        <div>
                                                            <div className="flex flex-row justify-center text-center relative mt-1 rounded-md shadow-sm">
                                                                <ReactCodeInput
                                                                    // value={verificationCode}
                                                                    fields={6}
                                                                    onChange={(e) => {
                                                                        setVerificationCode(e);
                                                                    }}
                                                                    {...props}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <button onClick={onCodeSubmit} className="w-[396px] h-[48px]  px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-400 rounded-md hover:bg-red-400 focus:outline-none focus:bg-red-400 BgColor">
                                                            Continue
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default EnterCode