import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { makeAuthenticatedRequest } from "../utils";
import { toast } from "react-toastify";

export default function BulkUpdatePartTagsModal({
  handleModalClose,
  handleReload,
  ids,
}) {
  const [processing, setProcessing] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);

  const handleSelectedTagsChange = (value) => {
    setSelectedTags(value);
  };

  const getTags = async ({ params }) => {
    const queryParams = new URLSearchParams(params).toString();
    const url = `/oms/inventory/tags/?${queryParams}`;

    try {
      const response = await makeAuthenticatedRequest(url);

      if (response.status == 200) {
        const data = await response.json();
        const tags = data.results.map((tag) => ({
          value: tag.name,
          label: tag.name,
        }));
        return tags;
      } else {
        toast.error("Failed to fetch tags!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    const fetchTags = async () => {
      const tags = await getTags({ params: { per_page: 50 } });
      setTagOptions(tags);
    };

    fetchTags();
  }, []);

  const createPartTags = async ({ payload }) => {
    const requestPayload = JSON.stringify(payload);
    const url = "/oms/inventory/tag/";

    try {
      const response = await makeAuthenticatedRequest(
        url,
        "POST",
        requestPayload
      );

      if (response.status == 200) {
        toast.success("Part tags created successfully");
        handleReload();
        handleModalClose();
      } else {
        toast.error("Failed to create part tags!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);

    let payload = {};
    const tags = selectedTags.map((tag) => tag?.value);

    if (!(ids.length > 0 && tags.length > 0)) {
      toast.error("Please select tags and parts!");
      setProcessing(false);
      return;
    }

    payload = { carpart_ids: ids, tags: tags };
    await createPartTags({ payload });
    setProcessing(false);
    return;
  };

  return (
    <>
      {processing ? (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img
            src="/cdn/images/loading.gif"
            className="mr-3 cursor-pointer  h-24"
            alt="Sparepap Logo"
          />
          <h2 class="text-center text-white text-xl font-semibold">
            Processing...
          </h2>
        </div>
      ) : (
        <section>
          <div className="min-h-h-screen flex justify-center items-center overflow-x-hidden overflow-y-auto py-10 fixed inset-0 z-[100] outline-none focus:outline-none">
            <div class="relative p-4 w-full max-w-2xl max-h-full">
              <div class="relative bg-white rounded-lg shadow ">
                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                  <h3 class="text-xl font-semibold text-gray-900 ">
                    Tag Selected Parts
                  </h3>
                  <button
                    onClick={handleModalClose}
                    type="button"
                    class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white"
                  >
                    <svg
                      class="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                <div class="p-4 md:p-5">
                  <form
                    className="flex flex-col w-full gap-4 text-[14px]"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className="flex flex-col gap-4 w-full">
                      <div className="flex gap-4 md:flex-row flex-col">
                        <div className="w-full flex flex-[2] flex-col">
                          <div className="flex flex-col gap-2">
                            <p className="font-semibold text-gray-400 font-satoshi ">
                              Tags
                            </p>
                            <CreatableSelect
                              isMulti
                              options={tagOptions}
                              onChange={(value) =>
                                handleSelectedTagsChange(value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex py-3 justify-end">
                        <div className="flex flex-row-reverse gap-4">
                          <button
                            type="submit"
                            className="bg-[#030A1A] text-[16px] text-white border border-[#030A1A] px-3 py-1 rounded-md"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            modalbackdrop=""
            className="h-screen bg-gray-900 bg-opacity-70  fixed inset-0 z-50"
          />
        </section>
      )}
    </>
  );
}
