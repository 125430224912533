import React, { useState } from 'react'
import { AccountObj, makeAuthenticatedRequest } from '../utils';
import { useEffect } from 'react';
import { ENDPOINT_DSF } from '../routes';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { Pagination } from '../components/Pagination';
import DSFPagination from '../components/DSFPagination';

function DSFsPage() {
    const navigate = useNavigate()
    let currentUrlParams = new URLSearchParams(window.location.search);
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)

    const [currentPage, setCurrentPage] = useState(currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)
  
    const [dsfs, setDsfs] = useState([])
    const [perPage, setPerPage] = useState(20)
    const[pages, setPages] = useState(0)
    const [itemCount, setItemCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')

    const [phone_number, setPhone_number] = useState('')
    const [referrer_name, setReferrer_name] = useState('')
    const [referrer_id, setReferrer_id] = useState('')
    const [salesRep, setSalesRep] = useState('')
    const [processing, setProcessing] = useState(false)

    const [addReferralModal,setAddReferralModal] = useState(false)
    const accountObj = new AccountObj()


    const createDsf = async () => {
      if(phone_number === '' ){
        toast.error('Phone number is required')
      }else if(referrer_name === ''){
        toast.error('Referrer name is required')
      }else if(salesRep === ''){
        toast.error('sales representative email is required')
      }
      else{

        const params = {"referrals":[{ phone_number: phone_number, referrer_name:referrer_name, referrer_id:referrer_id}],
                        "sales":salesRep}
        return makeAuthenticatedRequest(`/oms/referral-code`, "POST", JSON.stringify(params))
           .then((response) => response.json())
           .then((data) => {
            // console.log('send', data)
              // return data
              if (data && data.created.length > 0) {
                toast.success("DSF succesfully added")
                setAddReferralModal(false)
                window.location.reload()
                
             } else {
                toast.error("DSF could not be added")
                setAddReferralModal(false)
             }
           })
           .catch((err) => {
            toast.error("Something went wrong could not create Dsf")
            console.log(err)
            setAddReferralModal(false)
  
         }).finally(() => {
            setProcessing(false);
         });
     }
      }




   const cancelRef = useRef()
   useEffect(() => {
    let handler = e => {
      if (cancelRef.current && !cancelRef.current.contains(e.target)) {

        setAddReferralModal(false)

        // console.log(cancelRef.current)
      }
    }
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    }

  }) 


  



      const fetchDsfs = (page) => {
        const params = {
          "s": search,
          "page": page,
          "per_page": perPage,
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/referral-code?${searchParams}`)
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setDsfs(data)
            // setCurrentPage(data.next)
            setPerPage(data.count)
            setPages(data.number_of_pages)
            setItemCount(data.count)
            setTotalItems(data.count)
          });
      }
      
      const pageChanged = (pageNumber) => {
        currentUrlParams.set('page', pageNumber)
        setCurrentPage(Number(currentUrlParams.get('page')))
        setUrlParams(currentUrlParams)    
        fetchDsfs(Number(currentUrlParams.get('page')))
      }
      console.log('currentPage', currentPage)
      useEffect(() => {
        fetchDsfs(currentPage)
      }, [])


  return (
    <>
    <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20">
    <p className='font-satoshi text-xl font-semibold'>DSF</p>

    <div className="flex flex-col">
            <div className="flex flex-col ">
              <div className="flex flex-row-reverse">
                <button
                onClick={() => setAddReferralModal(true)}
                 className='outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-white w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>

                  Add new DSF</button>
              </div>

                  <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                    <table class="table-auto w-full  ">
                      <thead className='pl-2'>
                        <tr className='pl-1 border-b border-gray-200'>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>
                            
                          </th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Referral code</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Phone number</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>ID number</th>

                        </tr>
                      </thead>
                      <tbody>
                      {dsfs?.results?.map((dsf) => (
                                
                            <tr className='p-2 border-b border-b-gray-200 font-satoshi relative'>
                               <td
                                className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                              </td>
                              <Link to={`${ENDPOINT_DSF}/${dsf.code}`}>
                              <td
                               className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                               {dsf.code}

                              </td>
                              </Link>
                            <td className='pl-2 text-xs md:text-[16px]'>
                              {dsf.referer_name}
                              </td>
                              <td className='pl-2 text-xs md:text-[16px]'>
                              {dsf.phone_number}

                              </td>
                              <td
                                
                                className='p-4 text-xs md:text-[16px] text-left cursor-pointer font-satoshi'>{dsf.referer_id_number ? dsf.referer_id_number : '-'}</td>
                                 <td className='pl-2'>
                              {dsf.sales?.email}

                              </td>
                             
                            </tr>
                        ))}

                      </tbody>
                    </table>
                    <DSFPagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} pages={pages} />

                  </div>

            </div>
          </div>
    </div>
    <ToastContainer

      position="bottom-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
      
    {addReferralModal &&
    <>
      <div className='h-screen pt-14  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
        <div className='relative p-4 w-full md:w-[30%] h-full md:h-auto'>
          <div ref={cancelRef} className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-end p-2 rounded-t '>
            </div>

            <div className='relative p-3 flex-auto'>
              <div className='px-4 pt-4 pb-3 w-full'>

                <div className="flex flex-col">
                  <div onClick={() => setAddReferralModal(false)} className="">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <div className="flex flex-row gap-2 justify-between">

                    <div className="flex flex-row gap-2">
                      <img src="" alt="" />
                      <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                        Add a new DSF
                      </h2>
                    </div>

                  </div>

                  <div className="flex flex-col gap-2 py-2">
                    <p className="flex flex-row py-2 text-[16px] font-satoshi text-[#333B51]">
                      {/* Please select a reason for the cancellation of this order */}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2">
                   <form onSubmit={createDsf} className='w-full flex flex-col gap-2'>
                   <div className="w-full flex flex-col gap-1">
                      <label htmlFor="rname" className='font-satoshi text-[14px]'>DSF Referrer name</label>
                      <input required name='rname' 
                      placeholder='Add dsf referrer name'
                      value={referrer_name}
                      onChange={(e) => setReferrer_name(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="phone" className='font-satoshi text-[14px]'>DSF Phone number</label>
                      <input required name='phone' 
                      type="text" 
                      placeholder='Add dsf phone number'
                      value={phone_number}
                      onChange={(e) => setPhone_number(e.target.value)}
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>


                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="rname" className='font-satoshi text-[14px]'>DSF ID number</label>
                      <input required name='rid' 
                      placeholder='Add dsf referrer id number'
                      value={referrer_id}
                      onChange={(e) => setReferrer_id(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="rname" className='font-satoshi text-[14px]'>DSF Sales representative email</label>
                      <input required name='rid' 
                      placeholder='Add dsf sales rep email'
                      value={salesRep}
                      onChange={(e) => setSalesRep(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
              
                   </form>
                  </div>
                  <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                    onClick={createDsf}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>Continue</button>
                  <button
                    onClick={() => setAddReferralModal(false)}
                    className='border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md'>Cancel</button>

                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-50' />
    </>
    }
    </>
  )
}

export default DSFsPage