import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../utils';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import { MARKETPLACE_HOME_URL } from '../../constants'

const ORDER_URL = `/oms/customers/order/`;


function OrderDetailsTab() {
    console.log(MARKETPLACE_HOME_URL)
    const { orderID } = useParams();
    const [data, setData] = useState()
    const [processing, setProcessing] = useState(false)
    const [fullName, setFullName] = useState()
    const [email, setEmail] = useState()

    const fetchData = async () => {
        setProcessing(true)
        try {
            const response = await axiosCustom.get(`${ORDER_URL}${orderID}`);
            setData(response?.data)
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    const getImage = (object) => {
        let imageURL = null;
        object?.cart?.lines.map(line => {
            if (
                line?.carpart?.thumbnail_urls?.length &&
                line?.carpart?.thumbnail_urls[0]) {
                imageURL = line?.carpart?.thumbnail_urls[0];
            }
        })
        return imageURL;
    }



    const submitData = async (e) => {
        e.preventDefault();
        if (!fullName) {
            toast.error("Name cannot be empty")
        }
        setProcessing(true)
        try {
            const params = {
                email: email,
                name: fullName
            }
            await axiosCustom.post(``, JSON.stringify(params));
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div class="mx-auto max-w-screen-2xl">
                        <div class="mt-4 relative">
                            <div className="flex flex-col md:grid grid-cols-6 mt-4">
                                <div className="col-span-6 w-full">
                                    <div className='w-full flex flex-row justify-between items-start md:items-center mt-3 mb-3'>
                                        <div className='mr-2 flex flex-col gap-2 md:flex-row justify-start items-start md:items-end'>
                                            <span className='text-gray-400 text-sm font-satoshi ml-0 md:ml-1'>Order #{data?.order_id}</span>
                                            <span
                                                className={'text-xs px-2 py-1 rounded text-center text-black font-extrabold ' + (
                                                    data?.status === 'New' ?
                                                        'bg-[#B9E38D]'
                                                        : data?.status === 'New' ?
                                                            'bg-[#B9E38D]'
                                                            : data?.status === 'Confirmed' ?
                                                                'bg-[#54D3CA]'
                                                                : data?.status === 'Dispatched' ?
                                                                    'bg-[#8CD1FC]'
                                                                    : data?.status === 'Delivered' ?
                                                                        'bg-[#3E5DDD]'
                                                                        : data?.status === 'Requires prepayment' ?
                                                                            'bg-[#576298]'
                                                                            : data?.status === 'To be exchanged' ?
                                                                                'bg-[#F0A6A5]'
                                                                                : data?.status === 'Exchange complete' ?
                                                                                    'bg-[#059D5F]'
                                                                                    : data?.status === 'Rejected' ?
                                                                                        'bg-[#FCB28E]'
                                                                                        : data?.status === 'Reject Completed' ?
                                                                                            'bg-[#F0A6A5]'
                                                                                            : data?.status === 'Complete' ?
                                                                                                'bg-[#059D5F]'
                                                                                                : data?.status === 'Cancelled' ?
                                                                                                    'bg-[#DE6972]'
                                                                                                    :
                                                                                                    'bg-[#8d252e]')}>
                                                {data?.status}
                                            </span>
                                            <p className='font-satoshi text-gray-500 text-sm '>
                                                <Moment format="MMM, DD LT" className='flex'>
                                                    {data?.date_created}
                                                </Moment>
                                            </p>
                                        </div>
                                    </div>


                                    <div className="w-full border border-gray-200 rounded">



                                        <p className='text-md py-3 px-2 font-satoshi text-black font-semibold'>Parts <span className='text-gray-400 text-sm font-satoshi ml-0 md:ml-1'>{data?.item_count} items</span></p>
                                        <div className="flex flex-col px-2 gap-3 border-y w-full py-3 justify-between">
                                            {
                                                data?.cart?.lines?.map((item) => (

                                                    <div className='flex items-center justify-between flex-[6]'>
                                                        <div className='flex gap-1 items-center flex-[4]'>
                                                            <div className="p-1 flex-[0.5]">
                                                                {
                                                                    item?.carpart?.thumbnail_urls && item?.carpart?.thumbnail_urls?.length > 0 ?
                                                                        <img src={item?.carpart?.thumbnail_urls[0]} class="border border-slate-300 shadow-md object-cover rounded-md h-16 w-16" />
                                                                        :
                                                                        <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                                                                            <p className='font-satoshi text-[#F77B55] text-[14px] text-center'>No images</p>
                                                                        </div>
                                                                }
                                                            </div>
                                                            <div className='flex items-center flex-[3.5] flex-wrap'>
                                                                <div className='flex flex-col gap-2'>
                                                                    <a target='_blank' className='font-semibold text-sm text-left line-clamp-2 md:w-3/4' href={`${MARKETPLACE_HOME_URL}/part/${item?.carpart?.id}`}>
                                                                        {item?.carpart?.name}
                                                                    </a>
                                                                    <div className='flex gap-2 text-gray-400 text-[11px] text-left items-center'>
                                                                        <span className=''>
                                                                            SKU #{item?.carpart?.sku} / OEM #{item?.carpart?.oem}
                                                                        </span>
                                                                    </div>
                                                                    <div className='flex text-xs gap-1 items-center font-semibold text-gray-600'>
                                                                        <span>{item?.carpart?.price ? "Ksh. " + formatNumber(parseInt(item?.carpart?.price), 0, 0) : "-"}</span>
                                                                        <span>x</span>
                                                                        <span>{item?.quantity}</span>
                                                                        <span>@</span>
                                                                        <span className='text-green-700'>{item?.quantity && item?.price ? "Ksh. " + formatNumber(item?.quantity * parseInt(item?.price), 0, 0) : "-"}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-end py-4 flex-[2]">


                                                            <div className="flex flex-col">
                                                                <a target="_blank" href={`${MARKETPLACE_HOME_URL}/merchant/${item?.carpart?.passport?.id}`} className="flex flex-row gap-4 p-2">
                                                                    <div className=" border boreder-gray-200 rounded-md p-2">
                                                                        {
                                                                            item?.carpart?.passport?.merchant_avatar ?
                                                                                <img src={item?.carpart?.passport?.merchant_avatar} class="border border-slate-200 object-cover rounded-md h-16 w-16" />
                                                                                :
                                                                                <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                                                                                    <p className='font-satoshi text-[#F77B55] text-[14px] text-center'>No merchant image</p>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <p className='text-sm font-satoshi text-black font-semibold'>{item?.carpart?.passport?.merchant_name}</p>
                                                                        {false &&
                                                                            <div lassName="flex flex-row gap-1">
                                                                                {Array.from({ 10: 1 }, (_, i) =>
                                                                                    <img key={i} src='/cdn/images/ic_star.svg' alt='' />
                                                                                )}
                                                                                <p className='text-sm font-satoshi text-gray-400'>{0}</p>
                                                                            </div>
                                                                        }
                                                                        <p className='text-sm font-satoshi text-gray-400'>{item?.carpart?.passport?.merchant_address}</p>
                                                                        <a href={`tel:+${item?.carpart?.passport?.phone_number}`} className='text-sm hover:underline font-satoshi text-gray-600'>+{item?.carpart?.passport?.phone_number}</a>

                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="flex justify-between items-center px-2 gap-4 border-border-t border-gray-200 w-full py-3">
                                            <div>
                                            </div>
                                            <div className='flex items-center px-2 gap-4 self-end justify-between'>
                                                <div className="flex flex-col gap-2">
                                                    <p className='font-satoshi text-gray-400 text-sm uppercase '>Shipping Cost</p>
                                                    <p className='font-satoshi text-black font-semibold text-sm'>{data?.delivery_cost ? "Ksh. " + formatNumber(parseInt(data?.delivery_cost), 0, 0) : "-"}</p>
                                                </div>
                                                <div className="flex flex-col gap-2">
                                                    <p className='font-satoshi text-gray-400 text-sm uppercase '>Sub Total</p>
                                                    <p className='font-satoshi text-black font-semibold text-sm'>{data?.total ? "Ksh. " + formatNumber(parseInt(data?.total), 0, 0) : "-"}</p>
                                                </div>
                                                <div className="flex flex-col gap-2">
                                                    <p className='font-satoshi text-[#EDB0B5] text-xs uppercase '>Discount</p>
                                                    <p className='font-satoshi text-black font-semibold text-sm'>{data?.discount ? "Ksh. " + formatNumber(parseInt(data?.discount), 0, 0) : "-"}</p>
                                                </div>
                                                <div className="flex flex-col gap-2">
                                                    <p className='font-satoshi text-gray-400 text-xs uppercase '>Total</p>
                                                    <p className='font-satoshi text-black font-semibold text-sm'>{data?.total ? "Ksh. " + formatNumber(parseInt(data?.total) + parseInt(data?.delivery_cost) - parseInt(data.discount), 0, 0) : "-"}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="w-full py-4">
                                        <div className="flex flex-row gap-3">
                                            <div className="border border-gray-200 px-3 py-4  w-full rounded-md ">
                                                <div className="flex flex-col">
                                                    <p className='font-satoshi text-md text-black font-semibold'>Transactions</p>
                                                    <div className="border-b border-gray-300 w-full py-1"></div>
                                                    {data?.transaction && data?.transaction?.length > 0 ?
                                                        <>

                                                            {

                                                                data?.transaction?.map((parentItem, index) => (
                                                                    <>
                                                                        <div className='flex flex-col mt-4 mb-4'>
                                                                            <div className='flex items-center justify-between flex-[5]'>
                                                                                <div className='flex gap-1 items-center flex-[3]'>
                                                                                    <p className='font-satoshi text-gray-400 text-sm uppercase'>Transaction ID: </p>
                                                                                </div>
                                                                                <div className="flex py-1 flex-[2]">
                                                                                    <p className='text-sm font-satoshi text-black'>#{parentItem?.id}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className='flex items-center justify-between flex-[5]'>
                                                                                <div className='flex gap-1 items-center flex-[3]'>
                                                                                    <p className='font-satoshi text-gray-400 text-sm uppercase'>Payment Method: </p>
                                                                                </div>
                                                                                <div className="flex py-1 flex-[2]">
                                                                                    <p className='text-sm font-satoshi text-black'>{parentItem?.method?.name}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className='flex items-center justify-between flex-[5]'>
                                                                                <div className='flex gap-1 items-center flex-[3]'>
                                                                                    <p className='font-satoshi text-gray-400 text-sm uppercase'>Billing Account: </p>
                                                                                </div>
                                                                                <div className="flex py-1 flex-[2]">
                                                                                    <p className='text-sm font-satoshi text-black'>{parentItem?.account_number}</p>
                                                                                </div>
                                                                            </div>


                                                                            <div className='flex items-center justify-between flex-[5]'>
                                                                                <div className='flex gap-1 items-center flex-[3]'>
                                                                                    <p className='font-satoshi text-gray-400 text-sm uppercase'>Ref. no.: </p>
                                                                                </div>
                                                                                <div className="flex py-1 flex-[2]">
                                                                                    <p className='text-sm font-satoshi text-black'>{parentItem?.reference_number}</p>
                                                                                </div>
                                                                            </div>

                                                                            <div className='flex items-center justify-between flex-[5]'>
                                                                                <div className='flex gap-1 items-center flex-[3]'>
                                                                                    <p className='font-satoshi text-gray-400 text-sm uppercase'>Amount Paid: </p>
                                                                                </div>
                                                                                <div className="flex py-1 flex-[2]">
                                                                                    <p className='text-sm font-satoshi text-black'>{parentItem?.amount_paid ? "Ksh. " + formatNumber(parentItem?.amount_paid, 0, 0) : "-"}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        {
                                                                            parentItem?.payments && parentItem?.payments?.length > 0 &&
                                                                            <div className='flex items-center justify-between flex-[6]'>
                                                                                <div className='flex gap-1 items-center flex-[2]'>
                                                                                    <p className='text-sm font-satoshi text-black font-semibold'>Ref. No.</p>
                                                                                </div>
                                                                                <div className="flex justify-end py-4 flex-[2]">
                                                                                    <p className='text-sm font-satoshi text-black font-semibold'>Amount</p>
                                                                                </div>
                                                                                <div className="flex justify-end py-4 flex-[2]">
                                                                                    <p className='text-sm font-satoshi text-black font-semibold'>Payment Ref.</p>

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            parentItem?.payments.map((item, index) => (
                                                                                <div className='flex items-center justify-between flex-[6]'>
                                                                                    <div className='flex gap-1 items-center flex-[2]'>
                                                                                        <p className='text-sm font-satoshi'>{item?.TransID}</p>
                                                                                    </div>
                                                                                    <div className="flex justify-end py-1 flex-[2]">
                                                                                        <p className='text-sm font-satoshi'>{item?.TransAmount}</p>
                                                                                    </div>
                                                                                    <div className="flex justify-end py-1 flex-[2]">
                                                                                        <p className='text-sm font-satoshi'>{item?.PhoneNumber}</p>

                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </>
                                                                ))
                                                            }
                                                        </>
                                                        :
                                                        <p>No transactions yet</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="border border-gray-200 px-3 py-4 w-full rounded-md ">
                                                <div className="flex flex-col">
                                                    <p className='font-satoshi text-md text-black font-semibold'>Delivery </p>
                                                    <div className="border-b border-gray-300 w-full py-1 "></div>
                                                    <div className="flex flex-col py-2 gap-1">
                                                        <p className='text-sm font-satoshi text-black'>{data?.shipping_address?.town}, Kenya</p>
                                                        <p className='text-sm font-satoshi text-black'>{data?.shipping_address?.phone_number}</p>
                                                        <p className='text-sm font-satoshi text-black'>{data?.delivery_type} Delivery @ KSh. {data?.delivery_cost}</p>
                                                        <p className='text-sm font-satoshi text-black'>Done by {data?.shipping_method}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
export default OrderDetailsTab