import React from 'react'
import { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../constants';

// google services

const GoogleMapInput = ({ setLocation, classCss, placeholder, required, location }) => {

    const { ref } = usePlacesWidget({
        apiKey: GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
        options: {
            types: ['establishment'],
            componentRestrictions: { 'country': "KE" },
            fields: ['place_id', 'geometry', 'name', 'address_components', 'icon', 'formatted_address'],
            strictBounds: false,
        },
        onPlaceSelected: (place) => {
            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()
            const placeName = place.name
            const locationPayload = {
                lat: lat,
                lng: lng,
                placeName: placeName,
                icon: place?.icon,
                others: place?.address_components
            }
            setLocation(locationPayload)
        }
    })

    return (
        <input
            required={required}
            ref={ref}
            type="text" name="locationAutocomplete" id="locationAutocomplete"
            placeholder={location?.placeName?.toLowerCase() || placeholder}
            className={classCss}
        />
    )
}

export default GoogleMapInput