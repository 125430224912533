
export const getName = (object) => {
    if (object?.name)
        return object?.name
    if (object?.first_name && object?.last_name)
        return `${object?.first_name} ${object?.last_name}`
    if (object?.first_name)
        return object?.first_name
    if (object?.last_name)
        return object?.last_name
    if (object?.contact?.first_name && object?.contact?.last_name)
        return `${object?.contact?.first_name} ${object?.contact?.last_name}`
    if (object?.contact?.full_names)
        return object?.contact?.full_names
    return "-"
}