import React, { useEffect, useState } from 'react'
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker'
import { BsCloudUploadFill } from 'react-icons/bs'
import { toast } from 'react-toastify'
import html2pdf from 'html2pdf.js';
import { axiosService, calculateVATandSubtotal, formatDate, formatNumber, makeAuthenticatedRequest } from '../utils'

const ExcelInvoice = () => {
    const [client_name, setClientName] = useState('')
    const [client_address, setClientAddress] = useState('')
    const [client_location, setClientLocation] = useState('')
    const [client_phone, setClientPhone] = useState('')
    const [lpo_number, setLpo_number] = useState('')
    const [kra, setKra] = useState('')



    const [file, setFile] = useState(null)
    const [invoice_date, setInvoiceDate] = useState(new Date())
    const [due_date, setDueDate] = useState(new Date())
    const [processing, setProcessing] = useState(false)
    const [invoiceData, setInvoiceData] = useState(null)

    const downloadPDF = () => {
        const element = document.getElementById('pdf-content');
        const opt = {
          margin:       0.5,
          filename:     `${invoiceData?.client_abbrv}.pdf`,
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { dpi: 192, letterRendering: true },
          jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
        };
        html2pdf().set(opt).from(element).save();
      };

    const viewDownload = () => {
        downloadPDF()

    }   

    let total = invoiceData?.invoice_data?.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue.total
      }, 0);



      const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
      };


    const handleSubmit = async() => {
        setProcessing(true)
        if (!file) {
            toast.error('Please select a file');
            return;
          }
        const formData = new FormData();
        formData.append('excel_file', file);
        formData.append('client_name', client_name);
        formData.append('invoice_date', invoice_date.toISOString());
        formData.append('due_date', due_date.toISOString());

        try {
            const response = await axiosService.post(`/oms/excel-invoices/`, formData);
            console.log(response.data);
            setInvoiceData(response.data)
            setProcessing(false);

          } catch (error) {
            console.error(error);
            setProcessing(false);
            toast.error('Invoice could not be generated');
          } finally {
            setProcessing(false);
          }
    }  

  return (
    <>
    {invoiceData ?
    <div className="">

    <div id='pdf-content' className='w-full flex flex-col gap-2 py-4 h-full justify-between'>
      <div className="flex flex-col w-full gap-2 h-full">

        <div className="flex flex-col gap-3 h-full py-2">
            <div className="flex flex-row items-center w-full justify-between">
              <p className='font-bold text-[#D95762] font-satoshi text-3xl'>Invoice</p>
              <div className="flex flex-row items-center gap-1">
              <img src="/cdn/images/logo.svg"  alt="" />
              </div>
            </div>
            <div className="w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full">

        <div className="flex flex-col">
            <div className="flex flex-row gap-2">
            <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Inovice Number:</p>
              <p className='font-satoshi text-[16px] text-gray-500 capitalize'>INV-SPL{invoiceData?.invoice_number}</p>
            </div>
            <div className="flex flex-row  gap-2">
            <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Reference:</p>
              <p className='font-satoshi text-[16px] text-gray-500 capitalize'>{lpo_number}</p>
            </div>
          </div>
        <div className="w-full items-end flex flex-col">
          <div className="flex flex-col">
            <div className="flex flex-row  gap-2">
            <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Date Issued</p>
              <p className='font-satoshi text-[16px] text-gray-500 capitalize'>{formatDate(invoiceData?.invoice_date)}</p>
            </div>
            <div className="flex flex-row  gap-2">
            <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Due Date</p>
              <p className='font-satoshi text-[16px] text-gray-500 capitalize'>{formatDate(invoiceData?.due_date)}</p>
            </div>
          </div>
          
        </div>
        </div>
        </div>

            <div className="flex flex-row  w-full py-4">

            <div className="grid grid-rows-2 w-full">
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>Sparepap Limited</p>
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>P.O. Box 6957-00100</p>
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>3rd Floor, West One Building Parklands</p>
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>Nairobi</p>
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>Tel: +254-715 22 22 39</p>
            </div>
            <div className="flex flex-col items-center w-full">
            <div className="flex flex-col">

            <div className="grid grid-rows-2 w-full">
            <p className='font-satoshi text-[14px] text-gray-900 capitalize'>Bill to:</p> 
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>{client_name}</p>
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>{client_location}</p>
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>{client_address}</p>
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>Tel: +{client_phone}</p>
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>PIN: {kra}</p>

            </div>
            </div>

            </div>
            </div>


        </div>
        <table class="table-auto w-full h-full ">
          <thead className='p-3 border-b'>
            <tr className=''>
             
              <th className='p-3 font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>#</th>
              <th className='p-3 font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>Description</th>

              <th className='p-3  font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>Qty</th>
              <th className='p-3 font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>Unit Price</th>
              <th className='p-3  font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>Amount</th>
            </tr>
          </thead>
          <tbody>
           
            {invoiceData?.invoice_data?.filter((completeData) => completeData.qty > 0).map((line, index) => (

              <tr className='p-4 border-b border-b-gray-200 items-center text-gray-500'>
              <td
                className='py-2 px-2 text-xs md:text-sm cursor-pointer items-center'>

                  <p className='font-satoshi text-left '>{index + 1}</p>

              </td>
              <td
                className='py-2 px-2 text-xs md:text-sm cursor-pointer items-center'>
               
                <p className='font-satoshi text-left text-[16px]'>{line.description}</p>
                
              </td>
              <td
                className='py-2 px-2 text-xs md:text-sm cursor-pointer items-center'>
               
                <p className='font-satoshi text-left text-[16px]'>{line.qty}</p>
                
              </td>
              <td
                className='py-2 px-2 text-xs md:text-sm cursor-pointer items-center'>

                <p className='font-satoshi text-left text-[16px]'>
                  {formatNumber(line.price,0,0)}
                  </p>
              </td>
              <td
                className='py-2 px-2  text-xs md:text-sm cursor-pointer items-center'>
               
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                    {formatNumber(line.total,0,0)}
                  </p>
                 

              </td>



            </tr>
            ))}



          </tbody>
        </table>


        </div>
        <div className="flex flex-row-reverse py-5 px-2 w-full ">
          <div className="flex flex-col gap-2">

          <div className="grid grid-cols-2  gap-1 border-b p-1.5">

        <p className='font-satoshi text-[14px] text-gray-400'>Subtotal</p>
        <p className='font-satoshi text-[14px] text-gray-800'>{formatNumber(calculateVATandSubtotal(total).subtotal,0,0)}</p>

          </div>

          <div className="grid grid-cols-2 gap-1  border-b p-1.5">

          <p className='font-satoshi text-[14px] text-gray-400'>VAT(16%)</p>
          <p className='font-satoshi text-[14px] text-gray-800'>{formatNumber(calculateVATandSubtotal(total).vatAmount,0,0)}</p>

            </div>
          <div className="grid grid-cols-2 gap-1  border-b p-1.5">

          <p className='font-satoshi text-[14px] text-gray-800'>Total</p>
          <p className='font-satoshi text-[14px] text-gray-800'>{formatNumber(total,0,0)}</p>

            </div>
          </div>

        </div>
        <div className="flex flex-col gap-2 py-3">
        <div className="flex flex-col gap-3">
        <p className='font-satoshi text-[14px] text-gray-500 font-normal'>Payment options</p>
        <div className=" border-t w-full border-dashed">
        <p className='font-satoshi text-[16px] text-gray-900'>Bank</p>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full">

        <div className="flex flex-col w-full">
        <p className='font-satoshi text-[16px] text-gray-500 capitalize'>Sparepap Limited Collection Account</p>
            <p className='font-satoshi text-[16px] text-gray-500 capitalize'>Standard Chatered Bank</p>
            <p className='font-satoshi text-[16px] text-gray-500 capitalize'>A/C: 0102476530002 (KES)</p>
            <p className='font-satoshi text-[16px] text-gray-500 capitalize'>Yaya Center Branch</p>
        </div>
        <div className="w-full items-end flex flex-col">
          <div className="flex flex-col">
  <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Cheque (Account Payee)</p>
<p className='font-satoshi text-[16px] text-gray-500 capitalize'>Sparepap Limited</p>
          </div>
          
        </div>
        </div>



        </div>


        </div>

       

        </div>

        <div className="w-full border-b border-red-400 mt-10">
          <p className='text-[14px] text-red-400 italic font-satoshi text-center py-2'>Thank you for your continued support</p>
        </div>
        <div className="flex flex-row  items-center text-gray-500 h-full py-4">
            
            <p className='font-satoshi text-[12px] '>Sparepap Limited | </p>
            <p className='font-satoshi text-[12px] ml-2'>P.O.Box 6957-00100 | </p>
            <p className='font-satoshi text-[12px] ml-2'>3 rd Floor, West One Building, Parklands Rd, Nairobi, Kenya | </p>
            <p className='font-satoshi text-[12px] ml-2'>Tel: +254 715 22 22 39</p>

           
            
        </div>
     
    </div>
    <button className='flex flex-row gap-1 rounded-md items-center px-4 py-2 bg-gray-200 font-satoshi text-[14px]' onClick={viewDownload}>Download

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>
 </button>
</div>
    :
    <div className="flex flex-col w-full gap-2 font-satoshi">
        <p className='font-semibold text-xl '>Invoice details</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
            <div className="flex flex-col w-full gap-1">
                <p className='text-[14px] text-gray-600'>Client name</p>
                <input
                value={client_name}
                onChange={(e) => setClientName(e.target.value)}
                 type="text" className='rounded-md border p-2 outline-none shadow-md w-full' placeholder='Client name' name="" id="" />
            </div>
            <div className="flex flex-col w-full gap-1">
                <p className='text-[14px] text-gray-600'>Client location</p>
                <input
                value={client_location}
                onChange={(e) => setClientLocation(e.target.value)}
                 type="text" className='rounded-md border p-2 outline-none shadow-md w-full' placeholder='Nairobi, Kenya' name="" id="" />
            </div>
            <div className="flex flex-col w-full gap-1">
                <p className='text-[14px] text-gray-600'>Client street address</p>
                <input
                value={client_address}
                onChange={(e) => setClientAddress(e.target.value)}
                 type="text" className='rounded-md border p-2 outline-none shadow-md w-full' placeholder='P.O Box 1267' name="" id="" />
            </div>
            <div className="flex flex-col w-full gap-1">
                <p className='text-[14px] text-gray-600'>Client phone</p>
                <input
                value={client_phone}
                onChange={(e) => setClientPhone(e.target.value)}
                 type="text" className='rounded-md border p-2 outline-none shadow-md w-full' placeholder='254712345678' name="" id="" />
            </div>
            <div className="flex flex-col w-full gap-1">
                <p className='text-[14px] text-gray-600'>Lpo number</p>
                <input
                value={lpo_number}
                onChange={(e) => setLpo_number(e.target.value)}
                 type="text" className='rounded-md border p-2 outline-none shadow-md w-full' placeholder='Lpo number' name="" id="" />
            </div>
            <div className="flex flex-col w-full gap-1">
                <p className='text-[14px] text-gray-600'>Kra pin</p>
                <input
                value={kra}
                onChange={(e) => setKra(e.target.value)}
                 type="text" className='rounded-md border p-2 outline-none shadow-md w-full' placeholder='KRA pin' name="" id="" />
            </div>
            
                </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
            <div className="flex flex-col w-full gap-1">
                <p className='text-[14px] text-gray-600'>Invoice date</p>
                <DateTimePicker
                
                 onChange={setInvoiceDate} value={invoice_date} />
                {/* <input type="text" className='rounded-md border p-2 outline-none shadow-md w-full' placeholder='Client name' name="" id="" /> */}
            </div>
            <div className="flex flex-col w-full gap-1">
                <p className='text-[14px] text-gray-600'>Invoice Due date</p>
                <DateTimePicker
                    
                 onChange={setDueDate} value={due_date} />
                {/* <input type="text" className='rounded-md border p-2 outline-none shadow-md w-full' placeholder='Client name' name="" id="" /> */}
            </div>
            </div>
            <div className=" flex flex-col justify-center items-center rounded-lg border p-4 bg-gray-200">
                    <label htmlFor="" className='text-gray-600'>{file ? file.name:'Upload excel file'}</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="excel-file" className="relative cursor-pointer rounded-md bg-gray-200">
                                <span className='flex flex-row gap-2 items-center w-full'>
                                <BsCloudUploadFill size={25}/>
                                   
                                </span>
                                <input
                                accept=".xlsx, .xls"
                                onChange={handleFileChange} 
                                id="excel-file" name="excel-file" type="file" className="sr-only"/>
                                </label>
                            </div>
                            </div>
                        </div>
                        <div className="">
                            {/* {client_name && file && invoice_date && due_date && */}
                            <button 
                            onClick={handleSubmit}
                            className='px-4 py-2 rounded-md bg-gray-900 text-white text-center'>
                                {processing ? 'Processing...' :'Generate invoice'}
                            </button>
                            {/* } */}
                        </div>
    </div>

    }
    
    </>
  )
}

export default ExcelInvoice
