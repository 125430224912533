import React, { useEffect, useMemo, useRef, useState } from "react";
import DropdownMenu from "../components/DropDownMenu";
import BulkUpdateExpiryModal from "../components/BulkUpdateExpiryModal";
import BulkUpdatePartTagsModal from "../components/BulkUpdatePartTagsModal";
import ic_plus_big from "../assets/ic_plus_big.svg";
import axios from "axios";
import more from "../assets/ic_more.svg";
import Moment from "react-moment";
import Reorder from "../components/Reorder";
import AsyncSelect from "react-select/async";
import { Pagination } from "../components/Pagination";
import { toast } from "react-toastify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ENDPOINT_INVENTORY, ENDPOINT_INVENTORY_ADD } from "../routes";
import {
  formatNumber,
  getLocalStoreItem,
  makeAuthenticatedRequest,
} from "../utils";
import {
  API_PREFIX,
  LS_ACCESS_TOKEN,
  LS_ACCESS_TOKEN_TYPE,
  LS_ACCOUNT_ID,
} from "../constants";

const TAB_ALL_PARTS = "all";
const TAB_PENDING = "pending";
const TAB_APPROVED = "approved";
const TAB_INCOMPLETE = "incomplete";
const TAB_CORRECTONS = "needs_corrections";
const TAB_EXPIRED = "expired";

function InventoryPage() {
  const navigate = useNavigate();
  let currentUrlParams = new URLSearchParams(window.location.search);
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams);

  const [processing, setProcessing] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    currentUrlParams.has("page") ? Number(currentUrlParams.get("page")) : 1
  );
  const [currentTab, setCurrentTab] = useState(TAB_ALL_PARTS);
  const [searchParams, setSearchParams] = useSearchParams();
  let s = searchParams.get("s") !== null ? searchParams.get("s") : "";
  const userId = getLocalStoreItem(LS_ACCOUNT_ID);
  const token = getLocalStoreItem(LS_ACCESS_TOKEN);
  const key =
    token.substring(0, 4) + token.substring(token.length - 4, token.length);

  const [perPage, setPerPage] = useState(21);
  const [search, setSearch] = useState(s);
  const [selectedMerchants, setSelectedMerchants] = useState([]);

  const [itemCount, setItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [incompleteCount, setIncompleteCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const [needsCorrectionCount, setNeedsCorrectionCount] = useState(0);
  const [pendingCorrectionCount, setPendingCorrectionCount] = useState(0);
  const [expiredCount, setExpiredCount] = useState(0);

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [importInventory, setImportInventory] = useState(false);
  const refFileInput = React.createRef();

  const [open, setOPen] = useState([]);
  const [list, setList] = useState([]);

  const [deletePart, setDeletePart] = useState("Delete");
  const [deleteAllPart, setDeleteAllPart] = useState("Delete");
  const [showActions, setShowActions] = useState(false);

  const actionRef = useRef();

  const onFileChange = async (e) => {
    console.log(e.target.files);
    if (e.target.files && e.target.files.length > 0) {
      setProcessing(true);
      setImportInventory(false);

      console.log(e.target.files);
      const selectedFile = e.target.files[0];

      const formData = new FormData();

      // Update the formData object
      formData.append("inventory", selectedFile, selectedFile.name);

      // Details of the uploaded file
      console.log(selectedFile);

      // Request made to the backend api
      // Send formData object
      const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
      const accessTokenType = localStorage.getItem(LS_ACCESS_TOKEN_TYPE);
      const headers = {
        Authorization: `${accessTokenType} ${accessToken}`,
      };

      const config = {
        headers: headers,
      };
      axios
        .post(`${API_PREFIX}/oms/inventory/import/`, formData, config)
        .then((response) => {
          fetchInventory(1);
          setProcessing(false);
        });
    }
  };

  const handleSetDeleteParts = () => {
    setDeleteAllPart("Confirm");
    setTimeout(() => {
      setDeleteAllPart("Delete");
    }, 2000);
  };

  const reorderInventory = (orderBy, order) => {
    setOrder(order);
    setOrderBy(orderBy);
  };

  useEffect(() => {
    fetchInventory(currentPage);
  }, [order, orderBy]);

  const toggleOpen = (partId) => {
    const openState = open.slice();
    const index = openState.indexOf(partId);

    if (index >= 0) {
      openState.splice(index, 1);
      setOPen(openState);
    } else {
      openState.push(partId);
      setOPen(openState);
    }
  };
  const loadMerchantsOptions = (inputValue) =>
    new Promise(async (resolve) => {
      const params = { s: inputValue };
      const searchParams = new URLSearchParams(params).toString();
      const response = await makeAuthenticatedRequest(
        `/oms/merchants/search/?${searchParams}`
      );
      const data = await response.json();
      resolve(data);
    });

  const pageChanged = (pageNumber) => {
    currentUrlParams.set("page", pageNumber);
    setCurrentPage(Number(currentUrlParams.get("page")));
    setUrlParams(currentUrlParams);
    fetchInventory(Number(currentUrlParams.get("page")));
  };

  const handleSendForCorrection = (part) => {
    setProcessing(true);
    const params = {};
    makeAuthenticatedRequest(
      `/oms/inventory/${part.id}/send_for_corrections/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        fetchInventory(currentPage);
        setProcessing(false);
      });
  };

  const handleApprove = (part) => {
    updatePartStatus(part, true);
  };

  const handleUnapprove = (part) => {
    updatePartStatus(part, false);
  };

  const submitSearch = () => {
    if (search) {
      currentUrlParams.delete("page");
      setUrlParams(currentUrlParams);
      console.log("searchUrlparams", urlParams);
      fetchInventory(1);
    }
  };

  const [inventoryData, setInventoryData] = useState(null);

  const fetchInventory = (page) => {
    setProcessing(true);
    const params = {
      page: page,
      per_page: perPage,
    };
    if (search) {
      params.s = search;
    }
    if (order) {
      params.order = order;
    }
    if (orderBy) {
      params.order_by = orderBy;
    }

    let merchants = [];
    if (selectedMerchants != null && selectedMerchants.length > 0) {
      selectedMerchants.map((vehicle) => {
        merchants.push(vehicle.value);
      });
      currentUrlParams.set("page", 1);
      setUrlParams(currentUrlParams);
      setCurrentPage(Number(urlParams.get("page")));
      console.log("searchurlParams", urlParams.get("page"));
      console.log("searchurlPar", currentPage);

      params.merchants = merchants;
    }

    if (currentTab == TAB_INCOMPLETE) {
      params["state"] = "incomplete";
    }
    if (currentTab == TAB_PENDING) {
      params["state"] = "pending";
    }
    if (currentTab == TAB_APPROVED) {
      params["state"] = "approved";
    }
    if (currentTab == TAB_CORRECTONS) {
      params["state"] = "needs_corrections";
    }
    if (currentTab == TAB_EXPIRED) {
      params["is_expired"] = "true";
    }

    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/inventory/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInventoryData(data);
        setCurrentPage(data?.page);
        setPerPage(data?.per_page);
        setItemCount(data?.count);
        setTotalItems(data?.total_items);
        setPendingCount(data?.pending_count);
        setApprovedCount(data?.approved_count);
        setIncompleteCount(data?.incomplete_count);
        setNeedsCorrectionCount(data?.needs_correction_count);
        setExpiredCount(data?.expired_count);
        // setPendingCorrectionCount(pendingCount-needsCorrectionCount)

        setProcessing(false);
      });
  };

  const updatePartStatus = (part, is_approved) => {
    const params = {
      is_approved: is_approved,
    };
    makeAuthenticatedRequest(
      `/oms/inventory/${part.id}/status/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        fetchInventory(currentPage);
      });
  };

  useEffect(() => {
    fetchInventory(currentPage);
  }, [currentTab, selectedMerchants]);

  useEffect(() => {
    setList(inventoryData);
  }, [list]);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempPart = inventoryData.results.map((part) => {
        return { ...part, isChecked: checked };
      });
      setInventoryData(tempPart);
    } else {
      let tempPart = inventoryData.results.map((part) =>
        part.name === name ? { ...part, isChecked: checked } : part
      );
      setInventoryData(tempPart);
    }
  };

  const actionsRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (
        actionRef &&
        actionRef.current &&
        !actionRef.current.contains(e.target)
      ) {
        setOPen([]);
        console.log(actionRef.current);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    let actionshandler = (e) => {
      if (
        actionRef &&
        actionRef.current &&
        !actionsRef.current.contains(e.target)
      ) {
        setShowActions(false);
        console.log(actionsRef.current);
      }
    };
    document.addEventListener("mousedown", actionshandler);
    return () => {
      document.removeEventListener("mousedown", actionshandler);
    };
  });

  const deleteInventory = async (partId) => {
    setProcessing(true);

    makeAuthenticatedRequest(`/oms/inventory/${partId}/`, "DELETE")
      .then((response) => {
        toast.success("Inventory deleted successfully");
        fetchInventory(currentPage);
      })
      .catch((error) => {
        toast.error("An error occurred while deleting inventory");
        console.log(error);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleSetDeletePart = (partId) => {
    setDeletePart("Confirm");

    if (deletePart === "Confirm") {
      deleteInventory(partId);
    }

    setTimeout(() => {
      setDeletePart("Delete");
    }, 3000);
    return;
  };

  const [productChecked, setProductChecked] = useState({});
  const [selectedAll, setSelectedAll] = useState(false);
  const [showBulkOptions, setShowBulkOptions] = useState(false);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);

  const handleCheckBoxChange = (partId) => {
    const currentState = productChecked[partId] || false;
    setProductChecked({ ...productChecked, [partId]: !currentState });
  };

  const handleModalClose = () => {
    setShowBulkUpdateModal(false);
  };

  const handleReload = () => {
    fetchInventory(currentPage);
  };

  const handleSelectedAllChange = () => {
    setSelectedAll(!selectedAll);

    let newProductChecked = {};
    inventoryData?.results?.forEach((part) => {
      newProductChecked[part.id] = !selectedAll;
    });

    setProductChecked(newProductChecked);
  };

  const ids = useMemo(() => {
    return Object.keys(productChecked).filter((id) => productChecked[id]);
  }, [productChecked]);

  // Parts tags
  const [showUpdateTagsModal, setShowUpdateTagsModal] = useState(false);

  const handleTagPartsClick = () => {
    if (!ids?.length) {
      toast.error("Please select at least one part to be tagged");
      return;
    }

    setShowUpdateTagsModal(true);
  };

  const handleTagModalClose = () => {
    setShowUpdateTagsModal(false);
  };

  return (
    <>
      {showBulkUpdateModal && (
        <BulkUpdateExpiryModal
          handleModalClose={handleModalClose}
          handleReload={handleReload}
          ids={ids}
        />
      )}

      {showUpdateTagsModal && (
        <BulkUpdatePartTagsModal
          handleModalClose={handleTagModalClose}
          handleReload={handleReload}
          ids={ids}
        />
      )}

      {processing && (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img
            src="/cdn/images/loading.gif"
            className="mr-3 cursor-pointer  h-24"
            alt="Sparepap Logo"
          />
          <h2 class="text-center text-white text-xl font-semibold">
            Processing...
          </h2>
        </div>
      )}
      <div className="w-full min-h-screen md:max-h-screen overflow-y-scroll hide-scrollbar px-4 md:px-20">
        <div className="h-24 "></div>
        <div className="flex flex-col gap-4">
          <div className="relative">
            <p className="font-satoshi text-xl font-semibold">
              Inventory Items
            </p>

            <div className="flex flex-row rounded  w-fit float-right gap-2 ">
              {false && (
                <div
                  onClick={() => setImportInventory(true)}
                  className="whitespace-nowrap text-center cursor-pointer bg-[#E5E6E9]  py-1 px-3 font-satoshi text-[#808491] hover:bg-[#cdced3]"
                >
                  {" "}
                  Import
                  <span className="p-1 ml-2 text-[#b0b6c7] text-xs">
                    <svg
                      className="w-5 h-5 inline"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Upload-2</title>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g id="Upload-2">
                          <rect
                            id="Rectangle"
                            fill-rule="nonzero"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          ></rect>
                          <line
                            x1="12"
                            y1="14"
                            x2="12"
                            y2="20"
                            id="Path"
                            stroke="#0C0310"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></line>
                          <path
                            d="M15,15 L12.7071,12.7071 C12.3166,12.3166 11.6834,12.3166 11.2929,12.7071 L9,15"
                            id="Path"
                            stroke="#0C0310"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></path>
                          <path
                            d="M19.9495,16 C20.5978,15.3647 21,14.4793 21,13.5 C21,11.567 19.433,10 17.5,10 C17.3078,10 17.1192,10.0155 16.9354,10.0453 C16.4698,6.63095 13.5422,4 10,4 C6.13401,4 3,7.13401 3,11 C3,12.9587 3.80447,14.7295 5.10102,16"
                            id="Path"
                            stroke="#0C0310"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                </div>
              )}

              {false && (
                <a
                  href={`${API_PREFIX}/oms/inventory/export/?id=${userId}&key=${key}`}
                  className="whitespace-nowrap text-center bg-[#E5E6E9]  cursor-pointer py-1 px-3 font-satoshi text-[#808491] hover:bg-[#cdced3]"
                >
                  {" "}
                  Export
                  <span className="p-1 ml-2 text-[#b0b6c7] text-xs">
                    <svg
                      className="w-5 h-5 inline"
                      viewBox="0 0 24 24"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Download-2</title>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g id="Download-2">
                          <rect
                            id="Rectangle"
                            fill-rule="nonzero"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          ></rect>
                          <line
                            x1="12"
                            y1="14"
                            x2="12"
                            y2="20"
                            id="Path"
                            stroke="#0C0310"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></line>
                          <path
                            d="M15,19 L12.7071,21.2929 C12.3166,21.6834 11.6834,21.6834 11.2929,21.2929 L9,19"
                            id="Path"
                            stroke="#0C0310"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></path>
                          <path
                            d="M19.9495,16 C20.5978,15.3647 21,14.4793 21,13.5 C21,11.567 19.433,10 17.5,10 C17.3078,10 17.1192,10.0155 16.9354,10.0453 C16.4698,6.63095 13.5422,4 10,4 C6.13401,4 3,7.13401 3,11 C3,12.9587 3.80447,14.7295 5.10102,16"
                            id="Path"
                            stroke="#0C0310"
                            stroke-width="2"
                            stroke-linecap="round"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                </a>
              )}

              <DropdownMenu>
                <ul
                  class="py-1 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownActionButton"
                >
                  <li>
                    <a
                      onClick={() => setImportInventory(true)}
                      href="#"
                      class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Import
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${API_PREFIX}/oms/inventory/export/?id=${userId}&key=${key}`}
                      class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Export
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={() => handleTagPartsClick()}
                      class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Tag parts
                    </a>
                  </li>

                  <li>
                    <a
                      href="#"
                      onClick={() =>
                        setShowBulkUpdateModal(!showBulkUpdateModal)
                      }
                      class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      Update expiry
                    </a>
                  </li>
                </ul>
              </DropdownMenu>

              <Link
                to={ENDPOINT_INVENTORY_ADD}
                className="outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="text-white w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                Add inventory
              </Link>

              {showBulkOptions && (
                <>
                  <div
                    className={`border border-gray-200 w-40   bg-white z-50 absolute  top-20 right-0  rounded-md shadow-md pr-2 py-2 pl-2 `}
                  >
                    <div
                      className="py-1 px-1 flex flex-row gap-2 cursor-pointer"
                      onClick=""
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                      <p
                        onClick={() =>
                          setShowBulkUpdateModal(!showBulkUpdateModal)
                        }
                        className="font-satoshi text-xs"
                      >
                        Update Expiry
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div>
            <AsyncSelect
              className="font-satoshi rounded-md bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none w-full"
              cacheOptions
              loadOptions={loadMerchantsOptions}
              placeholder="Filter by merchant name"
              isMulti
              name="merchants"
              onChange={(e) => setSelectedMerchants(e)}
            />
          </div>
          <div>
            <form
              className="flex items-start md:items-center font-satoshi"
              method="get"
            >
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div
                  onClick={submitSearch}
                  className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  required
                  value={search}
                  type="text"
                  name="s"
                  id="simple-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    "
                  placeholder="Search by part name"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="flex flex-row gap-2 mt-4 overflow-auto ">
          <div
            onClick={() => setCurrentTab(TAB_ALL_PARTS)}
            className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${
              currentTab === TAB_ALL_PARTS
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
            }`}
          >
            {" "}
            All
            <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
              {totalItems}
            </span>
          </div>
          <div
            onClick={() => setCurrentTab(TAB_APPROVED)}
            className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${
              currentTab === TAB_APPROVED
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
            }`}
          >
            Approved
            <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
              {approvedCount}
            </span>
          </div>
          <div
            onClick={() => setCurrentTab(TAB_PENDING)}
            className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${
              currentTab === TAB_PENDING
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
            }`}
          >
            Pending Approval
            <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
              {pendingCount}
            </span>
          </div>
          <div
            onClick={() => setCurrentTab(TAB_INCOMPLETE)}
            className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${
              currentTab === TAB_INCOMPLETE
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
            }`}
          >
            Incomplete
            <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
              {incompleteCount}
            </span>
          </div>
          <div
            onClick={() => setCurrentTab(TAB_CORRECTONS)}
            className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${
              currentTab === TAB_CORRECTONS
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
            }`}
          >
            Send for corrections
            <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
              {needsCorrectionCount}
            </span>
          </div>
          <div
            onClick={() => setCurrentTab(TAB_EXPIRED)}
            className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${
              currentTab === TAB_EXPIRED
                ? "text-white bg-[#333B51]"
                : "text-[#808491] hover:bg-[#E5E6E9]"
            }`}
          >
            Expired
            <span className="p-1 ml-2 text-[#b0b6c7] text-xs rounded">
              {expiredCount}
            </span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col ">
            {inventoryData && !processing ? (
              <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                <table class="table-auto w-full">
                  <thead className="pl-1 md:pl-2 table-auto rounded-lg">
                    <tr className="pl-1 md:pl-2 rounded-lg">
                      <th className="p-4 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        <input
                          type="checkbox"
                          name="allSelect"
                          onChange={() => handleSelectedAllChange()}
                          checked={selectedAll}
                        />
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        Image
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        <span>
                          <span className="inline pr-2">Merchant</span>
                          <Reorder
                            order={order}
                            orderBy={orderBy}
                            tag="passport__merchant_name"
                            reorderInventory={reorderInventory}
                          />
                        </span>
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        <span>
                          <span className="inline pr-2">Part name</span>
                          <Reorder
                            order={order}
                            orderBy={orderBy}
                            tag="name"
                            reorderInventory={reorderInventory}
                          />
                        </span>
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        <span>
                          <span className="inline pr-2">Date Added</span>
                          <Reorder
                            order={order}
                            orderBy={orderBy}
                            tag="date_created"
                            reorderInventory={reorderInventory}
                          />
                        </span>
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        <span>
                          <span className="inline pr-2">Price(KSH)</span>
                          <Reorder
                            order={order}
                            orderBy={orderBy}
                            tag="price"
                            reorderInventory={reorderInventory}
                          />
                        </span>
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        <span>
                          <span className="inline pr-2">Total sold</span>
                          <Reorder
                            order={order}
                            orderBy={orderBy}
                            tag="total_sold"
                            reorderInventory={reorderInventory}
                          />
                        </span>
                      </th>
                      <th className="pl-2 pb-1 font-satoshi cursor-pointer text-xs relative md:text-sm tracking-wide text-left text-gray-400">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {inventoryData?.results.map((part) => (
                      <tr
                        key={part.id}
                        className="p-2 border border-b-gray-200 relative"
                      >
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                          <input
                            type="checkbox"
                            id={part.id}
                            name={part.name}
                            checked={productChecked[part.id] || false}
                            onChange={() => handleCheckBoxChange(part.id)}
                          />
                        </td>
                        <td className="p-1 md:p-2 ">
                          {part.cover_image_url || part.thumbnail_urls ? (
                            <>
                              <img
                                src={
                                  part.cover_image_url
                                    ? part.cover_image_url
                                    : part.thumbnail_urls[0]
                                }
                                alt=""
                                className="h-4 md:h-9 w-4 md:w-9 md:mx-auto inline"
                              />
                              {part.thumbnail_urls &&
                                part.thumbnail_urls.length > 1 && (
                                  <span className="text-xs font-thin inline pl-1 align-bottom	">
                                    +{part.thumbnail_urls.length - 1}
                                  </span>
                                )}
                            </>
                          ) : (
                            <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                              <p className="font-satoshi text-[#F77B55] text-[14px] text-center">
                                No images
                              </p>
                            </div>
                          )}
                        </td>
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                          <p
                            className={`font-satoshi  text-[14px] ${
                              part.passport.merchant_name
                                ? ""
                                : "text-[#F77B55]"
                            }`}
                          >
                            {part.passport.merchant_name}
                          </p>
                        </td>

                        <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-xs">
                          <Link to={`${ENDPOINT_INVENTORY}/${part.id}`}>
                            <p
                              className={`font-satoshi  text-[14px] text-left ${
                                part.name ? "" : "text-[#F77B55]"
                              }`}
                            >
                              {part.name}
                            </p>
                          </Link>
                        </td>
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                          <Moment format="MMM, DD Y">
                            {part.date_created}
                          </Moment>
                        </td>
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                          {formatNumber(part.price, 0, 0)}
                        </td>
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                          {part.total_sold > 0 ? part.total_sold : "-"}
                        </td>

                        <td className="p-1 md:p-4 text-xs md:text-[18px] text-[#030A1A]">
                          <img
                            // onMouseEnter={() => setShow(part.id)}
                            // onMouseLeave={() => setShow(-1)}
                            onClick={() => toggleOpen(part.id)}
                            src={more}
                            alt=""
                            className="float-left md:float-right cursor-pointer"
                          />
                        </td>
                        {open.includes(part.id) && (
                          <>
                            <div
                              ref={actionRef}
                              className={`border border-gray-200 w-40 top-8 mt-4 bg-white z-50 absolute right-4 rounded-md shadow-md pr-4 py-2 pl-2 `}
                            >
                              {part.is_approved ? (
                                <div
                                  className="py-1 px-1 flex flex-row gap-2 cursor-pointer"
                                  onClick={() => handleUnapprove(part)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                  </svg>
                                  <p className="font-satoshi text-xs">
                                    Unapprove
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className="py-1 px-1 flex flex-row gap-2 cursor-pointer"
                                  onClick={() => handleApprove(part)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-4 h-4"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                    />
                                    +{" "}
                                  </svg>
                                  <p className="font-satoshi text-xs">
                                    Approve
                                  </p>
                                </div>
                              )}
                              {!part.is_needs_correction && (
                                <div
                                  onClick={() => {
                                    handleSendForCorrection(part);
                                  }}
                                  className="flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer"
                                >
                                  <div className="flex flex-row gap-2">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-4 h-4"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                      />
                                    </svg>
                                    <p className="font-satoshi text-[12px]">
                                      Send for correction
                                    </p>
                                  </div>
                                </div>
                              )}

                              <div
                                onClick={(e) => {
                                  handleSetDeletePart(part.id);
                                }}
                                className="flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer"
                              >
                                <div className="flex flex-row gap-2">
                                  {deletePart === "Delete" ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-4 h-4 text-[#BF3054]"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      className="w-4 h-4 text-[#BF3054]"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                                      />
                                    </svg>
                                  )}
                                  <p className="font-satoshi text-sm text-[#BF3054]">
                                    {deletePart}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Pagination
                  pageChanged={pageChanged}
                  totalItems={itemCount}
                  itemsPerPage={perPage}
                  currentPage={currentPage}
                />
              </div>
            ) : (
              <p className="font-satoshi text-[18px] py-6">
                {processing ? "Processing" : "No inventory to display"}
              </p>
            )}
          </div>
        </div>
      </div>

      {importInventory && (
        <>
          <div className="h-screen pt-14  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none">
            <div className="relative px-4 py-2 w-full md:w-[30%] h-full md:h-auto">
              <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-end p-2 rounded-t "></div>

                <div className="relative px-3 flex-auto">
                  <div className="px-4 pt-1 pb-3 w-full">
                    <div className="flex flex-col">
                      <div
                        onClick={() => setImportInventory(false)}
                        className="font-light"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 float-right cursor-pointer"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-row gap-1 justify-between">
                        <div className="flex flex-row gap-2">
                          <img src="" alt="" />
                          <h2 className="text-[#030A1A] font-satoshi font-bold text-[20px] mb-4">
                            Import Inventory
                          </h2>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 py-2">
                        <p className="flex flex-row py-1 text-[14px] font-satoshi text-[#333B51]">
                          Select excel file to import{" "}
                        </p>
                      </div>

                      <div className="flex flex-col gap-2">
                        <div>
                          <input
                            type="file"
                            name="import-file"
                            id="import-file"
                            ref={refFileInput}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={onFileChange}
                            className="hidden"
                          />
                          <label
                            htmlFor="import-file"
                            className="flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center"
                          >
                            <img
                              src={ic_plus_big}
                              className="h-20 bg-gray-300"
                              alt=""
                            />
                          </label>
                        </div>{" "}
                      </div>
                    </div>
                    <div className="flex flex-row-reverse w-full py-4 gap-2">
                      <button
                        onClick={() => {}}
                        className="bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md"
                      >
                        Submit
                      </button>
                      <button
                        onClick={() => setImportInventory(false)}
                        className="border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            modalbackdrop=""
            className="h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-50"
          />
        </>
      )}
    </>
  );
}

export default InventoryPage;
