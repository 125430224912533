import React, { useEffect, useState } from "react";
import { Pagination } from "../../components/Pagination";
import { Reviews } from "./utils";
import Moment from "react-moment";
import Avatar from "react-avatar";

function ReviewsIndex() {
  const [processing, setProcessing] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const fetchReviews = async (page, perPage) => {
    const r = new Reviews();
    const data = await r.index(page, perPage);

    setReviews(data?.results);
    setPage(data?.page);
    setPerPage(data?.per_page);
    setCount(data?.count);
  };

  useEffect(() => {
    fetchReviews(page, perPage);
  }, []);

  const pageChanged = (pageNumber) => {
    setPage(pageNumber);
    fetchReviews(pageNumber, perPage);
  };

  const handleApprovalChange = async (e, id) => {
    const data = {
      is_approved: e.target.value,
    };

    const r = new Reviews();
    await r.update(id, data);
    await fetchReviews(page, perPage);
  };

  return (
    <>
      {processing && (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img
            src="/cdn/images/loading.gif"
            className="mr-3 cursor-pointer  h-24"
            alt="Sparepap Logo"
          />
          <h2 class="text-center text-white text-xl font-semibold">
            Processing...
          </h2>
        </div>
      )}

      <section>
        <div className="p-16 w-full min-h-screen md:max-h-screen overflow-y-scroll hide-scrollbar">
          <div className="flex flex-col gap-4">
            <div className="mt-10 mb-10 font-bold text-lg">
              <h2>Reviews</h2>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col ">
              {reviews && !processing ? (
                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                  <table class="table-auto w-full">
                    <thead className="pl-1 md:pl-2 table-auto rounded-lg">
                      <tr className="pl-1 md:pl-2 rounded-lg">
                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                          <span>
                            <span className="inline pr-2">Name</span>
                          </span>
                        </th>

                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                          <span>
                            <span className="inline pr-2">Rating</span>
                          </span>
                        </th>

                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                          <span>
                            <span className="inline pr-2">Reply</span>
                          </span>
                        </th>

                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                          <span>
                            <span className="inline pr-2">Date</span>
                          </span>
                        </th>

                        <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                          <span>
                            <span className="inline pr-2">Approve</span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviews?.map((review) => (
                        <tr
                          key={review?.id}
                          className="p-2 border border-b-gray-200 relative"
                        >
                          <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                            <div className="flex flex-row">
                              <Avatar
                                name={review?.passport?.full_name}
                                size="40"
                                className="mr-2 rounded-full shadow"
                              />
                              <div className="flex flex-col align-middle h-full">
                                <p>
                                  {review?.passport?.first_name}{" "}
                                  {review?.passport?.last_name}
                                </p>
                                <p>{review?.passport?.phone_number}</p>
                              </div>
                            </div>
                          </td>

                          <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                            {review?.rating}
                          </td>

                          <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                            {review?.reply}
                          </td>

                          <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                            <Moment format="MMM, DD Y">
                              {review?.date_created}
                            </Moment>
                          </td>

                          <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                            <span className="float-left">
                              <label
                                htmlFor={`push-green-toggle-${review?.id}`}
                                className="inline-flex relative items-center mr-5 cursor-pointer"
                              >
                                <input
                                  onChange={(e) => {
                                    handleApprovalChange(e, review?.id);
                                  }}
                                  checked={review?.is_approved}
                                  type="checkbox"
                                  value={!review?.is_approved}
                                  id={`push-green-toggle-${review?.id}`}
                                  className="sr-only peer"
                                />
                                <div
                                  className="w-11 h-6 bg-gray-200 rounded-full  peer-focus:ring-3
                                            peer-focus:ring-green-300 :ring-green-800 peer-checked:after:translate-x-full after:absolute after:top-0.5 after:left-[2px]
                                            after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all
                                            peer-checked:bg-green-600"
                                ></div>
                              </label>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Pagination
                    pageChanged={pageChanged}
                    totalItems={count}
                    itemsPerPage={perPage}
                    currentPage={page}
                  />
                </div>
              ) : (
                <p className="font-satoshi text-[18px] py-6">
                  {processing ? "Processing" : "No reviews to display"}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReviewsIndex;
