import React from 'react'
import HeroAdd from '../components/cms/Hero/HeroAdd'

function HeroAddPage() {
  return (
    <div>
      <HeroAdd/>
    </div>
  )
}

export default HeroAddPage