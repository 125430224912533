import React from 'react'
import Mechanic from "../components/cms/Mechanic/Mechanic"
function MechanicCMSPage() {
  return (
    <div>
      <Mechanic/>
      
    </div>
  )
}

export default MechanicCMSPage

