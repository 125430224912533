import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { formatNumber, formatNumberStr, hasPermission, makeAuthenticatedRequest } from '../utils';
import { PERMISSION_VIEW_CONSUMER, PERMISSION_VIEW_MECHANIC, PERMISSION_VIEW_MERCHANT, PERMISSION_VIEW_ORDER, PERMISSION_VIEW_JOB, PERMISSION_VIEW_CARPART } from '../constants';
import Graph from '../components/Graph';
import { ENDPOINT_INVENTORY } from '../routes';
import Datepicker from "react-tailwindcss-datepicker";
import { WS_PREFIX } from '../constants'
import dayjs from "dayjs";
import Moment from 'react-moment';
import SalesStats from '../components/dashboard/SaleStats';
import TopStats from '../components/dashboard/TopStats';
import Chart from 'react-apexcharts';
import { BsDot } from 'react-icons/bs';
import OrderStats from '../components/dashboard/OrderStats';
import UserStats from '../components/dashboard/UserStats';
import Revenue from '../components/dashboard/Revenue';
import DeviceStats from '../components/dashboard/DeviceStats';
import OSStats from '../components/dashboard/OSStats';
import LastestLink from '../components/dashboard/LastestLink';


const INVENTORY_TAB = "inventory"
const MERCHANT_TAB = "merchant"
const CATEGORY_TAB = "category"
const VEHICLE_TAB = "vehicle"
const BRAND_TAB = "brand"

function UserAnalyticsPage() {
  const navigate = useNavigate()

  const [dateRange, setDateRange] = useState({
    startDate: dayjs(new Date()).format("YYYY-MM-DD"),
    endDate: dayjs(new Date()).format("YYYY-MM-DD")
  });

  const [activeNow, setActiveNow] = useState()
  const [visitorsToday, setVisitorsToday] = useState()
  const [sessionsToday, setSessionsToday] = useState()
  const [graphLabels, setGraphLabels] = useState([])
  const [graphOrders, setGraphOrders] = useState([])
  const [graphVisitors, setGraphVisitors] = useState([])
  const [graphSales, setGraphSales] = useState([])
  const [processing, setProcessing] = useState(false)

  const [activeTab, setActiveTab] = useState(1)
  const [data, setData] = useState()
  const [showDatePicker, setShowDatePicker] = useState(false)

  const [consumersCount, setConsumersCount] = useState()

  const [mechanicsCount, setMechanicsCount] = useState()
  const [mechanicsVerifiedCount, setMechanicsVerifiedCount] = useState()
  const [mechanicsUnverifiedCount, setMechanicsUnverifiedCount] = useState()

  const [merchantsCount, setMerchantsCount] = useState()
  const [merchantsVerifiedCount, setMerchantsVerifiedCount] = useState()
  const [merchantsUnverifiedCount, setMerchantsUnverifiedCount] = useState()


  const [totalInventoryCount, setTotalInventoryCount] = useState()
  const [pendingInventoryCount, setPendingInventoryCount] = useState()
  const [approvedInventoryCount, setApprovedInventoryCount] = useState()
  const [incompleteInventoryCount, setIncompleteInventoryCount] = useState()

  const [totalJobsCount, setTotalJobsCount] = useState()
  const [completedJobsCount, setCompletedJobsCount] = useState()
  const [notCompletedJobsCount, setNotCompletedJobsCount] = useState()

  const [orderCount, setOrderCount] = useState()
  const [completedOrderCount, setCompletedOrderCount] = useState()
  const [notCompletedOrderCount, setNotCompletedOrderCount] = useState()

  const [totalMarketValue, setTotalMarketValue] = useState()
  const [totalMarketValueApproved, setTotalMarketValueApproved] = useState()
  const [totalMarketValueNotApproved, setTotalMarketValueNotApproved] = useState()


  const [bestSellingData, setBestSellingData] = useState()
  const [currentTab, setCurrentTab] = useState(INVENTORY_TAB)


  const [totalOrderValue, setTotalOrderValue] = useState()
  const [totalOrderValueCompleted, setTotalOrderValueCompleted] = useState()
  const [totalOrderValueNotCompleted, setTotalOrderValueNotCompleted] = useState()

  const handleDateRangeChange = (newValue) => {
    console.log("newValue:", newValue);
    setDateRange(newValue);
  }

  const signupsChartColors = {
    backgroundBarColors: ['#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB']
  }

  const chartConfig = {
    series: [{
      name: 'Users',
      data: [1334, 2435, 1753, 1328, 1155, 1632, 1336]
    }],
    labels: ['01 Feb', '02 Feb', '03 Feb', '04 Feb', '05 Feb', '06 Feb', '07 Feb'],
    chart: {
      type: 'bar',
      height: '140px',
      foreColor: '#4B5563',
      fontFamily: 'Inter, sans-serif',
      toolbar: {
        show: false
      }
    },
    theme: {
      monochrome: {
        enabled: true,
        color: '#1A56DB'
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '90%',
        borderRadius: 3,
        colors: {
          backgroundBarColors: signupsChartColors.backgroundBarColors,
          backgroundBarRadius: 3
        },
      },
      dataLabels: {
        hideOverflowingLabels: false
      }
    },
    xaxis: {
      floating: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: '14px',
        fontFamily: 'Inter, sans-serif'
      }
    },
    states: {
      hover: {
        filter: {
          type: 'darken',
          value: 0.8
        }
      }
    },
    fill: {
      opacity: 1
    },
    yaxis: {
      show: false
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
  };

  const series = [{
    name: 'Users',
    data: [1334, 2435, 1753, 1328, 1155, 1632, 1336]
  }]

  const fetchVisitors = () => {
    const params = {}
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      params.start_date = dateRange.startDate
      params.end_date = dateRange.endDate
    }
    console.log(WS_PREFIX)
    const searchParams = new URLSearchParams(params).toString()
    const headers = {
      "Content-type": "application/json"
    }
  }


  const fetchAnalytics = () => {
    setProcessing(true)
    const params = {}
    if (dateRange && dateRange.startDate && dateRange.endDate) {
      params.start_date = dateRange.startDate
      params.end_date = dateRange.endDate
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/analytics/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {

        setProcessing(false)
        console.log(data)
        setData(data)
        let labels = []
        let sales = []
        let orders = []
        let visitors = []
        data?.graph_data?.map((item) => {
          labels = [item.label, ...labels]
          sales = [item.sales, ...sales]
          orders = [item.orders, ...orders]
          visitors = [item.visitors, ...visitors]
        })
        console.log(sales)
        console.log(orders)
        console.log(visitors)
        setGraphLabels(labels)
        setGraphOrders(orders)
        setGraphSales(sales)
        //setGraphVisitors(visitors)

        setConsumersCount(data.consumers_count)
        setMechanicsCount(data.mechanics_count)
        setMechanicsVerifiedCount(data.mechanics_verified_count)
        setMechanicsUnverifiedCount(data.mechanics_unverified_count)

        setMerchantsCount(data.merchant_count)
        setMerchantsVerifiedCount(data.merchant_verified_count)
        setMerchantsUnverifiedCount(data.merchant_unverified_count)

        setTotalInventoryCount(data.total_inventory_count)
        setPendingInventoryCount(data.pending_inventory_count)
        setApprovedInventoryCount(data.approved_inventory_count)

        setTotalJobsCount(data.total_jobs_count)
        setCompletedJobsCount(data.completed_jobs_count)
        setNotCompletedJobsCount(data.not_completed_jobs_count)

        setOrderCount(data.order_count)
        setCompletedOrderCount(data.completed_order_count)
        setNotCompletedOrderCount(data.not_completed_order_count)

        setTotalMarketValue(data.total_market_value)
        setTotalMarketValueApproved(data.total_market_approved_value)
        setTotalMarketValueNotApproved(data.total_market_not_approved_value)

        setTotalOrderValue(data.total_order_value)
        setTotalOrderValueCompleted(data.total_completed_order_value)
        setTotalOrderValueNotCompleted(data.total_not_completed_order_value)
      });
  }


  const fetchBestSelling = () => {
    const params = {}
    let url;
    const searchParams = new URLSearchParams(params).toString();
    if (currentTab == INVENTORY_TAB) {
      url = `/oms/analytics/best_products/?${searchParams}`
    }
    if (currentTab == MERCHANT_TAB) {
      url = `/oms/analytics/best_merchants/?${searchParams}`
    }
    if (currentTab == BRAND_TAB) {
      url = `/oms/analytics/best_brands/?${searchParams}`
    }

    if (currentTab == VEHICLE_TAB) {
      url = `/oms/analytics/best_vehicles/?${searchParams}`
    }

    if (currentTab == CATEGORY_TAB) {
      url = `/oms/analytics/best_category/?${searchParams}`
    }

    makeAuthenticatedRequest(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setBestSellingData(data)
      });
  }


  useEffect(() => {
    fetchAnalytics();
    fetchVisitors();
  }, [dateRange])

  useEffect(() => {
    setBestSellingData(null)
    fetchBestSelling();
  }, [currentTab])

  return (

    <>
      {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }

      <div className='h-10'></div>

      <div className='flex w-full flex-col gap-2 py-4 md:py-8 px-4 md:px-20'>
        <div className='w-full'>
          <div class="grid w-full grid-cols-1 gap-4 mt-4 md:grid-cols-2">
            <DeviceStats />
            <OSStats />
          </div>

        </div>
        <div className='w-full'>
          <LastestLink/>
        </div>

      </div>

    </>

  )
}

export default UserAnalyticsPage