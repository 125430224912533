import { formatNumber } from "../../utils";
import dayjs from "dayjs";



const chartColors = {
    backgroundBarColors: ['#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB', '#E5E7EB']
}

const salesChartColors = {
    borderColor: '#F3F4F6',
    labelColor: '#6B7280',
    opacityFrom: 0.45,
    opacityTo: 0,
}

export const resolveDateRangeToName = (newDateRange, shortcuts) => {
    const configsCopy = { ...shortcuts }

    configsCopy["today"] = {
        text: "Today",
        period: {
            start: dayjs(new Date()).format("YYYY-MM-DD"),
            end: dayjs(new Date()).format("YYYY-MM-DD")
        },
    }
    configsCopy["yesterday"] = {
        text: "Yesterday",
        period: {
            start: dayjs(new Date()).subtract(1, 'day').format("YYYY-MM-DD"),
            end: dayjs(new Date()).subtract(1, 'day').format("YYYY-MM-DD")
        },
    }


    configsCopy["currentMonth"] = {
        text: "This Month",
        period: {
            start: dayjs(new Date()).date(1).format("YYYY-MM-DD"),
            end: dayjs(new Date()).endOf('month').format("YYYY-MM-DD")
        },
    }

    configsCopy["pastMonth"] = {
        text: "Past Month",
        period: {
            start: dayjs(new Date()).subtract(1, "month").date(1).format("YYYY-MM-DD"),
            end: dayjs(new Date()).subtract(1, "month").endOf('month').format("YYYY-MM-DD")
        },
    }


    let name = "Custom"
    Object.keys(configsCopy).map((key, index) => {
        console.log(key)
        const theValue = configsCopy[key]
        console.log(theValue)
        console.log(theValue?.period?.start, newDateRange?.startDate, theValue?.period?.start == newDateRange?.startDate)
        console.log(theValue?.period?.end, newDateRange?.endDate, theValue?.period?.end == newDateRange?.endDate)
        if (theValue?.period?.start == newDateRange?.startDate && theValue?.period?.end == newDateRange?.endDate) {
            name = theValue?.text
        }
    })
    return name
}

export const buildConfigs = () => {
    return {
        shortcuts: {
            today: "Today",
            yesterday: "Yesterday",
            thisWeek: {
                text: "This Week",
                period: {
                    start: dayjs(new Date()).weekday(1).format("YYYY-MM-DD"),
                    end: dayjs(new Date()).format("YYYY-MM-DD")
                },
            },
            currentMonth: "This Month",
            pastMonth: "Past Month",
            last6Months: {
                text: "Last 6 Months",
                period: {
                    start: dayjs(new Date()).subtract(5, "month").format("YYYY-MM-01"),
                    end: dayjs(new Date()).format("YYYY-MM-DD")
                },
            },
            ytd: {
                text: "YTD",
                period: {
                    start: dayjs(new Date()).format("YYYY-01-01"),
                    end: dayjs(new Date()).format("YYYY-MM-DD")
                },
            },
        }
    };
}

export const defaultSalesChartConfig = {
    stroke: {
        curve: 'smooth',
    },
    chart: {
        height: 420,
        type: 'area',
        fontFamily: 'Inter, sans-serif',
        foreColor: salesChartColors.labelColor,
        toolbar: {
            show: false
        }
    },
    fill: {
        type: 'gradient',
        gradient: {
            enabled: true,
            type: "vertical",
            opacityFrom: salesChartColors.opacityFrom,
            opacityTo: salesChartColors.opacityTo
        }
    },
    dataLabels: {
        enabled: false
    },
    tooltip: {
        style: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
        },
    },
    grid: {
        show: true,
        borderColor: salesChartColors.borderColor,
        strokeDashArray: 1,
        padding: {
            left: 35,
            bottom: 15
        }
    },
    series: [
        {
            name: 'Sales',
            data: [6356, 6218, 6156, 6526, 6356, 6256, 6056],
            color: '#1A56DB',
            type: 'area'
        },
        {
            name: 'B2B',
            data: [6356, 6218, 6156, 6526, 6356, 6256, 6056],
            color: '#775DD0',
            type: 'area'
        },
        {
            name: 'B2C',
            data: [6356, 6218, 6156, 6526, 6356, 6256, 6056],
            color: '#FEB019',
            type: 'area'
        }
    ],
    markers: {
        size: 5,
        strokeColors: '#ffffff',
        hover: {
            size: undefined,
            sizeOffset: 3
        }
    },
    xaxis: {
        labels: {
            style: {
                colors: [salesChartColors.labelColor],
                fontSize: '14px',
                fontWeight: 500,
            },
        },
        axisBorder: {
            color: salesChartColors.borderColor,
        },
        axisTicks: {
            color: salesChartColors.borderColor,
        },
        crosshairs: {
            show: true,
            position: 'back',
            stroke: {
                color: salesChartColors.borderColor,
                width: 1,
                dashArray: 10,
            },
        },
    },
    yaxis: {
        labels: {
            style: {
                colors: [salesChartColors.labelColor],
                fontSize: '14px',
                fontWeight: 500,
            },
            formatter: function (value) {
                return 'Ksh. ' + formatNumber(value, 0, 0);
            }
        },
    },
    legend: {
        show: true,
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Inter, sans-serif',
        labels: {
            colors: [salesChartColors.labelColor]
        },
        itemMargin: {
            horizontal: 10
        }
    },
    responsive: [
        {
            breakpoint: 1024,
            options: {
                xaxis: {
                    labels: {
                        show: false
                    }
                }
            }
        }
    ]
};


export const defaultUsersConfig = {
    series: [{
        name: 'Orders',
        data: [1334, 2435, 1753, 1328, 1155, 1632, 1336]
    }],
    labels: ['01 Feb', '02 Feb', '03 Feb', '04 Feb', '05 Feb', '06 Feb', '07 Feb'],
    chart: {
        type: 'bar',
        height: '140px',
        foreColor: '#4B5563',
        fontFamily: 'Inter, sans-serif',
        toolbar: {
            show: false
        }
    },
    theme: {
        monochrome: {
            enabled: true,
            color: '#1A56DB'
        }
    },
    plotOptions: {
        bar: {
            columnWidth: '90%',
            borderRadius: 3,
            colors: {
                backgroundBarColors: chartColors.backgroundBarColors,
                backgroundBarRadius: 3
            },
        },
        dataLabels: {
            hideOverflowingLabels: false
        }
    },
    xaxis: {
        floating: false,
        labels: {
            show: false
        },
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
    },
    tooltip: {
        shared: true,
        intersect: false,
        style: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif'
        }
    },
    states: {
        hover: {
            filter: {
                type: 'darken',
                value: 0.8
            }
        }
    },
    fill: {
        opacity: 1
    },
    yaxis: {
        show: false
    },
    grid: {
        show: false
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
};

export const defaultDeviceConfig = {
    series: [70, 5, 20, 5],
    labels: ['Desktop', 'Tablet', 'Phone', 'Other'],
    colors: ['#775DD0', '#FDBA8C', '#1A56DB', '#16BDCA'],
    chart: {
        type: 'donut',
        height: 400,
        fontFamily: 'Inter, sans-serif',
        toolbar: {
            show: false
        },
    },
    responsive: [{
        breakpoint: 430,
        options: {
            chart: {
                height: 300
            }
        }
    }],
    stroke: {
        colors: ["#ffffff"]
    },
    states: {
        hover: {
            filter: {
                type: 'darken',
                value: 0.9
            }
        }
    },
    tooltip: {
        shared: true,
        followCursor: false,
        fillSeriesColor: false,
        inverseOrder: true,
        style: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif'
        },
        x: {
            show: true,
            formatter: function (_, { seriesIndex, w }) {
                const label = w.config.labels[seriesIndex];
                return label
            }
        },
        y: {
            formatter: function (value) {
                return value + '%';
            }
        }
    },
    grid: {
        show: false
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
}

export const defaultOSConfig = {
    series: [10, 10, 10, 10, 10, 10, 10, 30],
    labels: [
        "Android",
        "Chrome OS",
        "iOS",
        "Linux",
        "Mac OS X",
        "Ubuntu",
        "Windows",
        "Other",
    ],
    colors: ['#b60e10', '#233341', '#d1e2f0', '#6098b6', '#987143', '#d6828c', '#c9ad99', '#3e9896'],
    chart: {
        type: 'donut',
        height: 400,
        fontFamily: 'Inter, sans-serif',
        toolbar: {
            show: false
        },
    },
    responsive: [{
        breakpoint: 430,
        options: {
            chart: {
                height: 300
            }
        }
    }],
    stroke: {
        colors: ["#ffffff"]
    },
    states: {
        hover: {
            filter: {
                type: 'darken',
                value: 0.9
            }
        }
    },
    tooltip: {
        shared: true,
        followCursor: false,
        fillSeriesColor: false,
        inverseOrder: true,
        style: {
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif'
        },
        x: {
            show: true,
            formatter: function (_, { seriesIndex, w }) {
                const label = w.config.labels[seriesIndex];
                return label
            }
        },
        y: {
            formatter: function (value) {
                return value + '%';
            }
        }
    },
    grid: {
        show: false
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: false
    },
}