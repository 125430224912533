import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AccountObj, formatNumber, makeAuthenticatedRequest } from '../utils';
import { toast, ToastContainer } from 'react-toastify'
import back from '../assets/ic_back.svg'
import { ENDPOINT_BTOB } from '../routes';
import BClientQoutes from '../components/BClientQoutes';
import BClientRfqs from '../components/BClientRfqs';
import BClientLpos from '../components/BClientLpos';
import BClientInvoices from '../components/BClientInvoices';

const ORDERS_TAB = "Orders"
const SUPPORT_TICKETS_TAB = "Support tickets"
const DASHBOARD_TAB = "Dashboard"
const AACOUNT_TAB = "Account"

const ORDERS_QOUTES_TAB = "Quotes"
const ORDERS_LPOS_TAB = "Lpos"
const ORDERS_INVOICES_TAB = "Invoices"
const ORDERS_RFQS_TAB = "Rfqs"

const BClientDetailsPage = () => {
    const { clientID } = useParams()
    const [clientData, setClientData] = useState([])
    const navigate = useNavigate()
    const [currentTab, setCurrentTab] = useState(ORDERS_TAB)
    const [orderCurrentTab, setOrderCurrentTab] = useState(ORDERS_QOUTES_TAB)

    function extractYear(date_established) {
        const dateObject = new Date(date_established);
      
        const year = dateObject.getFullYear();
      
        return year;
      }

    const [processing, setProcessing] = useState(false)
    const fetchClientData = () => {
      makeAuthenticatedRequest(`/oms/btobclients/${clientID}/`)
        .then((response) => response.json())
        .then((data) => {
        //   console.log(data)
          setClientData(data)
        });
    }
    
    useEffect(() => {
      fetchClientData();
    }, [])

    const handleSuspendB2BClient = async () => {
        setProcessing(true)
        return makeAuthenticatedRequest(`/oms/btobclients/${clientID}/suspend/`, "POST")
          .then((response) => response.json())
          .then((data) => {
            toast.success("B2bClient succesfully suspended")
            fetchClientData();
          })
          .catch((err) => {
            toast.error("Something went wrong could not suspend client")
            console.log("err",err)
          }).finally(() => {
            setProcessing(false);
          });
      }
    
      const handleActivateB2BClient = async () => {
        setProcessing(true)
        return makeAuthenticatedRequest(`/oms/btobclients/${clientID}/un-suspend/`, "POST")
          .then((response) => response.json())
          .then((data) => {
            toast.success("B2bClient succesfully activated")
            fetchClientData()
          })
          .catch((err) => {
            toast.error("Something went wrong could not activated client")
            console.log("err",err)
          }).finally(() => {
            setProcessing(false);
          });
      }

  return (
    <>
       <div className=" px-4 md:px-20 flex flex-col w-full md:w-[90%] h-full  overflow-y-scroll hide-scrollbar gap-4  pb-10 font-satoshi justify-center">
        <div className="flex flex-col w-full gap-2 py-20">
            <div className="flex flex-row w-full gap-2 items-center">
            <Link to={ENDPOINT_BTOB}>Clients</Link>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" className="w-4 h-4 text-red-500">
  <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
</svg>

            <p className=''>{clientData?.name}</p>
            </div>
            <div className="flex flex-row">

        {/* <div
        onClick={() => navigate(-1)}
         className="flex flex-row gap-1 w-auto cursor-pointer  px-2 py-1 bg-[#F2F2F4] rounded-lg">
                <img src={back} alt="" />
                Back
                </div> */}
                <div className="flex flex-row items-center w-full justify-between">
                <div className="flex flex-row w-full gap-4 items-center">
                    <img src={clientData?.letterhead} alt="" className='w-20 h-16 rounded-md' />
                    <p className='font-semibold text-xl md:text-3xl font-satoshi'>{clientData?.name}</p>
                </div>
                <div className="w-full justify-end flex flex-col items-end">

                    {clientData?.suspended? 
                    <button 
                    onClick={handleActivateB2BClient}
                    className='text-center px-4 py-2 rounded-md text-[14px] bg-green-300'>{processing ? 'processing...':'Activate account'}</button>
                    :
                    <button 
                    onClick={handleSuspendB2BClient}
                    className='text-center px-4 py-2 rounded-md text-[14px] bg-red-300'>{processing ? 'processing...':'Deactivate account'}</button>
                    }
                </div>
                </div>
            </div>
            <div className="flex flex-col w-full gap-4">
                <div className="flex flex-row w-full gap-5 items-center border-b border-gray-300 overflow-x-auto">
                <div onClick={() => setCurrentTab(ORDERS_TAB)} className={` cursor-pointer ${currentTab === ORDERS_TAB ? 'text-gray-800 underline-offset-[5px] underline decoration-4':'text-gray-400'} font-semibold text-[16px]`}>{ORDERS_TAB}</div>
                <div onClick={() => setCurrentTab(SUPPORT_TICKETS_TAB)} className={` cursor-pointer ${currentTab === SUPPORT_TICKETS_TAB ? 'text-gray-800 underline-offset-[5px] underline decoration-4':'text-gray-400'} font-semibold text-[16px]`}>{SUPPORT_TICKETS_TAB}</div>
                <div onClick={() => setCurrentTab(DASHBOARD_TAB)} className={` cursor-pointer ${currentTab === DASHBOARD_TAB ? 'text-gray-800 underline-offset-[5px] underline decoration-4':'text-gray-400'} font-semibold text-[16px] line-clamp-1`}>{DASHBOARD_TAB}</div>
                <div onClick={() => setCurrentTab(AACOUNT_TAB)} className={` cursor-pointer ${currentTab === AACOUNT_TAB ? 'text-gray-800 underline-offset-[5px] underline decoration-4':'text-gray-400'} font-semibold text-[16px]`}>{AACOUNT_TAB}</div>
                </div>

                {currentTab === ORDERS_TAB && 
                 <div className="flex flex-row w-full gap-5 items-center overflow-x-auto">
                 <div onClick={() => setOrderCurrentTab(ORDERS_QOUTES_TAB)} className={` cursor-pointer ${orderCurrentTab === ORDERS_QOUTES_TAB ? 'bg-gray-800 rounded-md text-center px-4 py-1.5 text-white':'text-gray-400'} font-semibold text-[16px]`}>{ORDERS_QOUTES_TAB}</div>
                 <div onClick={() => setOrderCurrentTab(ORDERS_LPOS_TAB)} className={` cursor-pointer ${orderCurrentTab === ORDERS_LPOS_TAB ? 'bg-gray-800 rounded-md text-center px-4 py-1.5 text-white':'text-gray-400'} font-semibold text-[16px]`}>{ORDERS_LPOS_TAB}</div>
                 <div onClick={() => setOrderCurrentTab(ORDERS_INVOICES_TAB)} className={` cursor-pointer ${orderCurrentTab === ORDERS_INVOICES_TAB ? 'bg-gray-800 rounded-md text-center px-4 py-1.5 text-white':'text-gray-400'} font-semibold text-[16px] line-clamp-1`}>{ORDERS_INVOICES_TAB}</div>
                 <div onClick={() => setOrderCurrentTab(ORDERS_RFQS_TAB)} className={` cursor-pointer ${orderCurrentTab === ORDERS_RFQS_TAB ? 'bg-gray-800 rounded-md text-center px-4 py-1.5 text-white':'text-gray-400'} font-semibold text-[16px]`}>{ORDERS_RFQS_TAB}</div>
                 </div>
                }
                {currentTab === ORDERS_TAB && 
                <div className="flex flex-col gap-2 w-full">
                    {orderCurrentTab === ORDERS_QOUTES_TAB &&
                    <BClientQoutes clientId={clientData?.id}/>
                    }
                    {orderCurrentTab === ORDERS_RFQS_TAB &&
                    <BClientRfqs clientId={clientData?.id}/>
                    }
                    {orderCurrentTab === ORDERS_LPOS_TAB &&
                    <BClientLpos clientId={clientData?.id}/>
                    }
                    {orderCurrentTab === ORDERS_INVOICES_TAB &&
                    <BClientInvoices clientId={clientData?.id}/>
                    }
                </div>}
                {currentTab === AACOUNT_TAB && 
                <div className="flex flex-col w-full gap-4">

                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>Business details</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>
                    <div className="flex flex-row w-full items-center">
                    <div className="flex flex-col gap-2 flex-1 w-full ">
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Business name</p>

                            <p>{clientData?.name}</p>

                        </div>
                        <div className="grid flex-1 grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Business type</p>
                            <p>{clientData?.entity_type}</p>
                        </div>
                        <div className="grid flex-1 grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Established</p>
                            <p>{extractYear(clientData?.date_established)}</p>

                        </div>
                        <div className="grid flex-1 grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Email</p>
                            <p>{clientData?.email_address}</p>

                        </div>
                        <div className="grid flex-1 grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Phone number</p>
                            <p>{clientData?.phone_number}</p>

                        </div>

                    </div>
                    <div className="w-[20%]">
                        <img src={clientData?.letterhead} className='w-20 h-20 rounded-md' alt="" />
                    </div>
                    </div>
                </div>
                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>Address</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>
                    <div className="flex flex-row w-full items-center">
                    <div className="flex flex-col gap-2 flex-1 w-full ">
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>HQ</p>
                            
                            <p>{clientData?.street}</p>

                        </div>
                        <div className="grid flex-1 grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Street address</p>
                            <p>{clientData?.address}</p>

                        </div>

                    </div>
                    </div>
                </div>
                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>Financial information</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>
                    <div className="flex flex-row w-full items-center">
                    <div className="flex flex-col gap-2 flex-1 w-full ">
                        {clientData?.bankers?.map((banker) => (

                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>{banker?.bank_name}</p>
                            
                            <p>{banker?.account_number}</p>

                        </div>
                        ))}
                    </div>
                    </div>
                </div>

                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>Persons authorized for payment</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>
                    <div className="flex flex-row w-full items-center">
                    <div className="flex flex-col gap-2 flex-1 w-full ">
                        {clientData?.purchasers?.map((purchaser) => (
                            <div className="flex flex-col gap-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Name</p>
                            
                            <p>{purchaser?.name}</p>

                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Limit</p>
                            
                            <p>Ksh. {formatNumber(purchaser?.limit, 0, 0)}</p>

                        </div>
                            </div>
                        ))}
                    </div>
                    </div>
                </div>
                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>Owners</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>
                    <div className="flex flex-row w-full items-center">
                    <div className="flex flex-col gap-2 flex-1 w-full ">
                        {clientData?.owners?.map((owner) => (
                            <>
                            <div className="flex flex-col gap-2 bg-gray-200 rounded-md p-2 m-1">
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Name </p>
                            
                            <p>{owner?.name}</p>

                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Email</p>
                            <p>{owner?.email}</p>

                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Phone</p>
                            <p>{owner?.phone_number}</p>

                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Shares</p>
                            <p>{owner?.issued_share_capital}</p>

                        </div>
                        
                            </div>
                            </>
                        ))}
                    </div>
                    </div>
                </div>

                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>References</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>
                    <div className="flex flex-row w-full items-center">
                    <div className="flex flex-col gap-2 flex-1 w-full ">
                        {clientData?.references?.map((reference) => (
                            <div className="flex flex-col gap-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Name </p>
                            
                            <p>{reference?.name}</p>

                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Phone</p>
                            
                            <p>{reference?.phone_number}</p>

                        </div>
                            </div>
                        ))}
                    </div>
                    </div>
                </div>
                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>Tunovers</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>
                    <div className="flex flex-row w-full items-center">
                    <div className="flex flex-col gap-2 flex-1 w-full ">
                        {clientData?.tunovers?.map((tunover) => (
                            <div className="flex flex-col gap-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>{extractYear(tunover?.year)}</p>
                            
                            <p>Ksh. {formatNumber(tunover?.tunover, 0, 0)}</p>

                        </div>
                            </div>
                        ))}
                    </div>
                    </div>
                </div>

                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>Credit</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>
                    <div className="flex flex-row w-full items-center">
                    <div className="flex flex-col gap-2 flex-1 w-full ">
                            <div className="flex flex-col gap-2">
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Credit period</p>
                            
                            <p>{Math.abs(clientData?.credit_period)} Days</p>

                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Credit limit</p>
                            
                            <p>Ksh. {formatNumber(clientData?.credit_limit, 0, 0)}</p>

                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                            <p>Security bank</p>
                            
                            <p>{clientData?.security_bank}</p>

                        </div>
                            </div>
                    </div>
                    </div>
                </div>


                <div className="flex flex-col w-full border rounded-md p-2 gap-4">
                    <div className="flex flex-row justify-between w-full items-center border-b border-gray-200">
                        <p className='font-semibold text-[16px]'>Documents</p>
                        {/* <button className='font-semibold text-[16px] text-gray-400'>Edit</button> */}
                    </div>

                    <div className="grid grid-cols-4 w-full gap-2">
                        <img src={clientData?.reg_cert} className='w-full h-40 rounded-md' alt="" />
                        <img src={clientData?.kra} className='w-full h-40 rounded-md' alt="" />
                        <img src={clientData?.cr} className='w-full h-40 rounded-md' alt="" />
                        <img src={clientData?.cancelled_cheque} className='w-full h-40 rounded-md' alt="" />
                        
                        
                        {/* <iframe src={clientData?.cancelled_cheque} className='w-40 h-40' /> */}
                    </div>
                </div>
                </div>
                }
            </div>
            </div>
            </div>

    </>
  )
}

export default BClientDetailsPage