import React, { useState } from 'react'
import Select from 'react-select'
import { toast, ToastContainer } from 'react-toastify'
import { getFirebaseTokenThenSignin, makeAuthenticatedRequest, makeid } from '../utils'
import { MdDeleteForever } from "react-icons/md";
import moment from 'moment';
import { auth, storage } from '../firebaseUtils'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
// import { getFirebaseTokenThenSignin, AccountObj, makeAuthenticatedRequest, makeid, truncateNumber } from '../utils'
import { BsImage } from "react-icons/bs";
import { BsCloudUploadFill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { TbPhotoPlus } from "react-icons/tb";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ENDPOINT_BTOB } from '../routes';
import back from '../assets/ic_back.svg'

const STEP_ONE = "Business Identification"
const STEP_TWO = "Ownership and legal documentation"
const STEP_THREE = "Financial and banking information"
const STEP_FOUR = "References and purchase authorization"

const handleFileUploadOnFirebaseStorage = async (bucketName, uploadedImage) => {
  
   if (uploadedImage === "" || uploadedImage === null) return "";
   
   const fileID = makeid(20);
   const storageRef = ref(storage, `/${bucketName}/${fileID}.webp`);

   await uploadBytesResumable(storageRef, uploadedImage);
   let downloadURL = null;
   await getDownloadURL(storageRef).then((fileURL) => downloadURL = fileURL);
   return downloadURL
};

const businessTypes = [
    {value:"Sole Trader",label:"Sole Trader"},
    {value:"Partnership",label:"Partnership"},
    {value:"Private Limited Company",label:"Private Limited Company"},
    {value:"Public Limited Company",label:"Public Limited Company"},
    {value:"Statutory Board",label:"Statutory Board"},
]
const ClientOnboarding = () => {
    const navigate = useNavigate()
    const [step, setStep] = useState(STEP_ONE)
    const [processing, setProcessing] = useState(false)
    const [loading, setIsLoading] = useState(false)

    const [customer_name, setCustomer_name] = useState('')
    const [customer_last_name, setCustomer_last_name] = useState('')
    const [customer_phone, setCustomer_phone] = useState('')
    const [customer_email, setCustomer_email] = useState('')

    const [business_address, setBusiness_address] = useState('')
    const [business_street, setBusiness_street] = useState('')
    const [business_phone, setBusiness_phone] = useState('')
    const [business_email, setBusiness_email] = useState('')
    const [business_name, setBusiness_name] = useState('')
    const [businessLogo, setBusinessLogo] = useState('')
    const [businessLogoUrl, setBusinessLogoUrl] = useState('')
    const [businessType, setBusinessType] = useState('')
    const [businessDateEstablished, setBusinessDateEstablished] = useState('')

    const [partnerName, setPartnerName] = useState('')
    const [partnerPhone, setPartnerPhone] = useState('')
    const [partnerEmail, setPartnerEmail] = useState('')
    const [partnerShare, setPartnerShare] = useState('')
    const [partners, setPartners] = useState([])

    const [bankBranch, setBankBranch] = useState('')
    const [bankName, setBankName] = useState('')

    const [bankAccountName, setBankAccountName] = useState('')
    const [bankAccountNo, setBankAccountNo] = useState('')
    const [bankStatement, setBankStatement] = useState('')
    const [bankStatementUrl, setBankStatementUrl] = useState('')
    const [bankers, setBankers] = useState([])

    const [referencePhone, setReferencePhone] = useState('')
    const [referenceName, setReferenceName] = useState('')
    const [referenceAddress, setReferenceAddress] = useState('')
    const [references, setReferences] = useState([])

    const [authorizedName, setAuthorizedName] = useState('')
    const [authorizedLimit, setAuthorizedLimit] = useState('')
    const [authorized, setAuthorized] = useState([])

    const [year, setYear] = useState('')
    const [yearAmount, setYearAmount] = useState('')
    const [tunovers, setTunovers] = useState([])


    const [businessCreditLimit, setBusinessCreditLimit] = useState('')
    const [businessCreditPeriod, setBusinessCreditPeriod] = useState('')


    const [securityBank, setSecurityBank] = useState('')
    const [securityMaturity, setSecurityMaturity] = useState(new Date())
    const [securityValue, setSecurityValue] = useState('')

    const [acceptTerms, setAcceptTerms] = useState(false)

    const [companyRegCert, setCompanyRegCert] = useState(null)
    const [companyRegCertUrl, setCompanyRegCertUrl] = useState('')
    const [companyKraCert, setCompanyKraCert] = useState(null)
    const [companyKraCertUrl, setCompanyKraCertUrl] = useState('')
    const [companyCR12Cert, setCompanyCR12Cert] = useState(null)
    const [companyCR12CertUrl, setCompanyCR12CertUrl] = useState('')
    const [companyCancellChequeCert, setCompanyCancellChequeCert] = useState(null)
    const [companyCancellChequeCertUrl, setCompanyCancellChequeCertUrl] = useState('')

    const [directorsKraPin, setDirectorKraPin] = useState('')
    const [directorKraPinUrl, setDirectorKraPinUrl] = useState('')
    const [directorTCC, setDirectorTCC] = useState('')
    const [directorTCCUrl, setDirectorTCCUrl] = useState('')
    const [directorPassport, setDirectorPassport] = useState('')
    const [directorPassportUrl, setDirectorPassportUrl] = useState('')
    const [directorId, setDirectorId] = useState('')
    const [directorIdUrl, setDirectorIdUrl] = useState('')

    const [directors, setDirectors] = useState([])

    const handleInputChange = (setState) => (event) => {
        setState(event.target.value)
    }

    const handleAddTunover= () => {
        if(year === '' || yearAmount === ''){
            toast.error('Add all fields')
            return
        }
        const payload = {
            year:`${year}-01-01 00:00:00`,
            tunover:yearAmount,
        }
        setTunovers([...tunovers, payload])
        setYear('')
        setYearAmount('')
    }

    const handleAddPartner = async() => {

        // setState(event.target.value)
        if(partnerName === '' || partnerPhone === '' || partnerEmail === '' || partnerShare === '' || directorsKraPin === '' || directorTCC === '' || directorPassport === '' || directorId === '' ){
            toast.error('Add all fields')
            return
        }
        const payload = {
            name:partnerName,
            phone_number:`254${partnerPhone}`,
            email:partnerEmail,
            issued_share_capital:partnerShare,
            kra:directorKraPinUrl,
            tcc:directorTCCUrl,
            passport_copy:directorPassportUrl,
            id_number:directorIdUrl,
            
        }
        setDirectors([...directors, payload])
        setPartnerName('')
        setPartnerPhone('')
        setPartnerEmail('')
        setPartnerShare('')
        setDirectorKraPin('')
        setDirectorKraPinUrl('')
        setDirectorTCC('')
        setDirectorTCCUrl('')
        setDirectorPassport('')
        setDirectorPassportUrl('')
        setDirectorId('')
        setDirectorIdUrl('')
    }
    const handleAddBanker = async() => {
        if(bankBranch === '' || bankAccountName === '' || bankAccountNo === '' || bankStatementUrl === '' ){
            toast.error('Add all fields')
            return
        }
        const payload = {
            
            bank_name:bankBranch,
            name:bankName,
            account_name:bankAccountName,
            account_number:bankAccountNo,
            bank_statement_for_last_three_months:bankStatementUrl
        }
    
        setBankers([...bankers, payload])
        setBankBranch('')
        setBankName('')
        setBankAccountName('')
        setBankAccountNo('')
        setBankStatementUrl('')
    }

    const handleAddReference = () => {
        if(referencePhone === '' || referenceName === '' || referenceAddress === ''){
            toast.error('Add all fields')
            return
        }
        const payload = {
            phone_number:`254${referencePhone}`,
            name:referenceName,
            address:referenceAddress
        }        
        setReferences([...references, payload])
        setReferencePhone('')
        setReferenceName('')
        setReferenceAddress('')
    }

    const handleAddAuthorizedPerson = () => {
        if(authorizedName === '' || authorizedLimit === ''){
            toast.error('Add all fields')
            return
        }
        const payload = {
            name:authorizedName,
            limit:authorizedLimit
        }        
        setAuthorized([...authorized, payload])
        
        setAuthorizedName('')
        setAuthorizedLimit('')
    }
    const user = auth.currentUser;


    const handelfileUpload = async(event,setState, setStateUrl, state) => {
        const file = event.target.files[0];
        setState(file);
        setIsLoading(state)
        const uploadedFile = await handleFileUploadOnFirebaseStorage('b2bClients', file);
        setStateUrl(uploadedFile);
        setIsLoading(false)
    }
    const handleFileChange = async(event,setState, setStateUrl, state) => {

        // const file = event.target.files[0];
        // setState(file);
        // setIsLoading(state)
        // const uploadedFile = await handleFileUploadOnFirebaseStorage('b2bClients', file);
        // setStateUrl(uploadedFile);
        // setIsLoading(false)

        if (user) {
            await handelfileUpload(event,setState, setStateUrl, state);
    
         } else {
            await getFirebaseTokenThenSignin()
               .then((userCredential) => { 
                  const user = userCredential.user
                  handelfileUpload(event,setState, setStateUrl, state);
   
               }).then(() => {
                handelfileUpload(event,setState, setStateUrl, state);
                  
               });
         }

      };
    
    const handleFilesChange = (event,setState) => {
        const files = event.target.files;
        setState(files);
      };  
    
      const removeObjectByIndexAndUpdateState = (indexToRemove, stateArray, setState) =>{
        const updatedArray = [...stateArray.slice(0, indexToRemove), ...stateArray.slice(indexToRemove + 1)];
        setState(updatedArray);
      } 
      
      const clearStepOne = ()=> {
        setCustomer_name('')
        setCustomer_last_name('')
        setCustomer_phone('')
        setCustomer_email('')
        setBusiness_address('')
        setBusiness_street('')
        setBusiness_phone('')
        setBusiness_email('')
        setBusiness_name('')
        setBusinessLogo('')
        setBusinessLogoUrl('')
        setBusinessType('')
        setBusinessDateEstablished('')
    }  

    const clearStepTwo = ()=> {
        setPartnerName('')
        setPartnerPhone('')
        setPartnerEmail('')
        setPartnerShare('')
        setCompanyRegCert('')
        setCompanyRegCertUrl('')
        setCompanyKraCert('')
        setCompanyKraCertUrl('')
        setCompanyCR12Cert('')
        setCompanyCR12CertUrl('')
        setCompanyCancellChequeCert('')
        setCompanyCancellChequeCertUrl('')
        setDirectorKraPin('')
        setDirectorKraPinUrl('')
        setDirectorTCC('')
        setDirectorTCCUrl('')
        setDirectorPassport('')
        setDirectorPassportUrl('')
        setDirectorId('')
        setDirectorIdUrl('')
        setPartners([])
        setDirectors([])
    }  

    const clearStepThree = ()=> {
        setBankers([])
        setTunovers([])
        setBankBranch('')
        setBankName('')
        setBankAccountName('')
        setBankAccountNo('')
        setBankStatement('')
        setBankStatementUrl('')
        setYearAmount('')
        setYear('')
        setSecurityBank('')
        setSecurityMaturity('')
        setSecurityValue('')
        setBusinessCreditLimit('')
        setBusinessCreditPeriod('') 

    }  

    const clearStepFour = ()=> {
        setReferences([])
        setAuthorized([])
        setReferencePhone('')
        setReferenceName('')
        setReferenceAddress('')
        setReferences('')
        setAuthorizedName('')
        setAuthorizedLimit('')
    }  
    const clearState = ()=> {
        clearStepOne()
        clearStepTwo()
        clearStepThree()
        clearStepFour()
    }  

 
     const handleCreateB2BClient = async () => {
        setProcessing(true)
        if(business_phone === '' || customer_name=== "" ||
        customer_last_name=== "" ||
        customer_phone=== "" ||
        customer_email=== "" ||
        business_address=== "" ||
        business_street=== "" ||
        business_email=== "" ||
        business_name === "" ||
        businessCreditPeriod === "" ||
        businessDateEstablished === ""){
            toast.error('Business contact details, official details  and contact personell details are required')
            return
        }
        
        // if(authorized?.length === 0 ){
        //     toast.error('Business authorized purchasers are required')
        //     return
        // }
        // if(references?.length === 0 ){
        //     toast.error('Business references are required')
        //     return
        // }
        // if(partners?.length === 0 ){
        //     toast.error('Business owners are required')
        //     return
        // }
        
        // if(bankers?.length === 0 ){
        //     toast.error('Business bankers are required')
        //     return
        // }
        // if(directors?.length === 0 ){
        //     toast.error('Business directors are required')
        //     return
        // }

        // if(tunovers?.length < 3 ){
        //     toast.error('Business tunovers for the past 3 years are required')
        //     return
        // }

        // if(businessLogoUrl === '' || companyRegCertUrl=== '' || companyKraCertUrl === '' || companyCR12CertUrl === '' || companyCancellChequeCertUrl=== ''){
        //     toast.error('All business formal documents must be uploaded')
        //     return
        // }

        const params ={
            
            phone_number: `+254${business_phone}`,
            customer_name:customer_name,
            address: business_address,
            street: business_street,
            email_address:business_email,
            name:business_name,
            letterhead:businessLogoUrl,
            entity_type:businessType?.value,
            date_established:moment(businessDateEstablished).format('YYYY-MM-DD HH:mm'),
            credit_limit:businessCreditLimit,
            credit_period:businessCreditPeriod,
            security_bank: securityBank,
            security_maturity_date: moment(securityMaturity).format('YYYY-MM-DD HH:mm'),
            security_value: securityValue,
            reg_cert:companyRegCertUrl,
            kra:companyKraCertUrl,
            cr:companyCR12CertUrl,
            cancelled_cheque:companyCancellChequeCertUrl,
            accepted_terms: true,
            purchasers: authorized,
            references:references,
            owners: directors,
            bankers:bankers,
            directors:directors ,
            tunovers: tunovers
          }
        console.log(JSON.stringify(params))
        return makeAuthenticatedRequest(`/oms/btobclients/`, "POST", JSON.stringify(params))
          .then((response) => response.json())
          .then((data) => {
            // console.log('sendData', data)
            // return data
            // if(data?.)
            clearState()
            toast.success("B2bClient succesfully added")
            navigate(ENDPOINT_BTOB)
            
          })
          .catch((err) => {
            clearState()
            toast.error("Something went wrong could not create client")
            console.log("err",err)
          }).finally(() => {
            setProcessing(false);
            clearState()
          });
    
      } 
      const handleCreateB2BClientAndAddAnother = async () => {
        setProcessing(true)
        if(authorized?.length === 0 ){
            toast.error('Business authorized purchasers are required')
            return
        }
        if(references?.length === 0 ){
            toast.error('Business references are required')
            return
        }
        // if(partners?.length === 0 ){
        //     toast.error('Business owners are required')
        //     return
        // }
        
        if(bankers?.length === 0 ){
            toast.error('Business bankers are required')
            return
        }
        if(directors?.length === 0 ){
            toast.error('Business directors are required')
            return
        }

        if(tunovers?.length < 3 ){
            toast.error('Business tunovers for the past 3 years are required')
            return
        }

        if(businessLogoUrl === '' || companyRegCertUrl=== '' || companyKraCertUrl === '' || companyCR12CertUrl === '' || companyCancellChequeCertUrl=== ''){
            toast.error('All business formal documents must be uploaded')
            return
        }

        const params ={
            
            phone_number: `+254${business_phone}`,
            customer_name:customer_name,
            address: business_address,
            street: business_street,
            email_address:business_email,
            name:business_name,
            letterhead:businessLogoUrl,
            entity_type:businessType?.value,
            date_established:moment(businessDateEstablished).format('YYYY-MM-DD HH:mm'),
            credit_limit:businessCreditLimit,
            credit_period:businessCreditPeriod,
            security_bank: securityBank,
            security_maturity_date: moment(securityMaturity).format('YYYY-MM-DD HH:mm'),
            security_value: securityValue,
            reg_cert:companyRegCertUrl,
            kra:companyKraCertUrl,
            cr:companyCR12CertUrl,
            cancelled_cheque:companyCancellChequeCertUrl,
            accepted_terms: true,
            purchasers: authorized,
            references:references,
            owners: directors,
            bankers:bankers,
            directors:directors ,
            tunovers: tunovers
          }
        console.log(JSON.stringify(params))
        return makeAuthenticatedRequest(`/oms/btobclients/`, "POST", JSON.stringify(params))
          .then((response) => response.json())
          .then((data) => {
            // console.log('sendData', data)
            // return data
            // if(data?.)
            clearState()
            toast.success("B2bClient succesfully added")
            setStep(STEP_ONE)
            
          })
          .catch((err) => {
            clearState()
            toast.error("Something went wrong could not create client")
            console.log("err",err)
          }).finally(() => {
            setProcessing(false);
            clearState()
          });
    
      } 
    console.log('directors',directors)  
  return (
    <div className=" pl-4 md:px-20 flex flex-col w-full h-full overflow-y-scroll hide-scrollbar gap-4 pt-16 md:pt-20 pb-10 font-satoshi">
    <div className="flex flex-col gap-2 w-full">
        <div className="flex">

    <div
        onClick={() => navigate(-1)}
         className="flex flex-row gap-1 w-auto cursor-pointer  px-2 py-1 bg-[#F2F2F4] rounded-lg">
                <img src={back} alt="" />
                Back
                </div>
        </div>
    <p className='text-[24px] font-bold'>

        New business client
    </p>
        {/* <form action="" className='w-full flex flex-col gap-5'> */}
        {step === STEP_ONE &&
            <div className="flex flex-col w-full gap-2">
                <div className="flex flex-row gap-2 justify-between">
                    <p className='font-semibold text-[18px]'>{STEP_ONE}</p>
                    <button
                    onClick={() => setStep(STEP_TWO)}
                     className='rounded-md py-2 px-4 text-center bg-red-100 text-red-400'>Next</button>
                </div>
                <div className="flex flex-col gap-2 w-full">
                <p className='font-semibold text-gray-500 text-[18px]'>Step {step === STEP_ONE ? '1': step === STEP_TWO ? '2': step === STEP_THREE ? '3' : step === STEP_FOUR ? '4':null} of 4</p>
                <div className="grid grid-cols-4 gap-2 w-full">
                    <div className={`w-full h-2 rounded-lg ${step === STEP_ONE ? "bg-red-300":"bg-gray-200"}`}></div>
                    <div className={`w-full h-2 rounded-lg ${step === STEP_TWO ? "bg-red-300":"bg-gray-200"}`}></div>
                    <div className={`w-full h-2 rounded-lg ${step === STEP_THREE ? "bg-red-300":"bg-gray-200"}`}></div>
                    <div className={`w-full h-2 rounded-lg ${step === STEP_FOUR ? "bg-red-300":"bg-gray-200"}`}></div>

                </div>
                </div>
                <div className="flex flex-col md:flex-row w-full items-start gap-8">
                <div className="flex flex-col  w-full md:w-[85%] gap-4">
                <p className='text-gray-800 font-semibold text-[18px]'>Business contact details</p>
                <div className="grid grid-cols-1  w-full gap-4">

                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Business Name</label>
                    <input
                    value={business_name}
                    onChange={handleInputChange(setBusiness_name)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Name' />
                    </div>
                <div className="grid  grid-cols-1 md:grid-cols-2 w-full gap-2">

                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Business Address</label>
                    <input
                    value={business_address}
                    onChange={handleInputChange(setBusiness_address)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Address P.O Box' />
                    </div>
                    
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Business street/town</label>
                    <input
                    value={business_street}
                    onChange={handleInputChange(setBusiness_street)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Street/town' />
                    </div>
                    </div>
                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">

                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Business phone number</label>
                    <div className="flex flex-row items-center border rounded-md gap-1">
                        <p className=' py-2 px-1 bg-gray-100'>+254</p>
                    <input
                    value={business_phone}
                    onChange={handleInputChange(setBusiness_phone)}
                    maxLength={9}
                     type="number" className='w-full outline-none  py-2 px-1 flex-1' placeholder='' />
                    </div>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Business Email address</label>
                    <input
                    value={business_email}
                    onChange={handleInputChange(setBusiness_email)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Email' />
                    </div>
                    </div>
                    <div className="flex flex-col w-full gap-2">
                <p className='text-gray-800 font-semibold text-[18px]'>Business official details</p>

                    <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-2">
                {/* <p className='text-[18px] font-semibold'>Business official details <span className='text-red-600'>*</span></p> */}

                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Business Type</label>
                    <Select
                    className='p-3'
                    options={businessTypes}
                    onChange={e => setBusinessType(e)}
                    />
                  
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Date established</label>
                    <input
                    value={businessDateEstablished}
                    onChange={handleInputChange(setBusinessDateEstablished)}
                     type="date" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Telephone contact' />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Credit period (days)</label>
                            <input
                            value={businessCreditPeriod}
                            onChange={handleInputChange(setBusinessCreditPeriod)}
                            type="number" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Enter days' />
                            </div>
            </div>
                    </div>

                    <div className="flex flex-col w-full gap-3">
                <p className='text-gray-800 font-semibold text-[18px]'>Contact person</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>First Name</label>
                    <input
                    value={customer_name}
                    onChange={handleInputChange(setCustomer_name)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='First name' />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Last Name</label>
                    <input
                    value={customer_last_name}
                    onChange={handleInputChange(setCustomer_last_name)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Last name' />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Phone Number</label>
                    <input
                    value={customer_phone}
                    onChange={handleInputChange(setCustomer_phone)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Phone number' />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Email address</label>
                    <input
                    value={customer_email}
                    onChange={handleInputChange(setCustomer_email)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Email' />
                    </div>
                     </div>
                    </div>

                </div>
                </div>

                <div className="flex flex-col gap-1 w-[15%] mt-14">
                    {businessLogoUrl ? 
                    <img src={businessLogoUrl} className='h-32 w-32 rounded overflow-hidden' alt="" />
                    :
                <div className=" flex justify-center rounded-lg border p-2 h-32 w-32 bg-gray-200">
                    <div className="flex flex-row items-center text-center">
                    <div className="flex text-sm leading-6 text-gray-600">
                        <label for="file-upload" className="relative cursor-pointer rounded-md bg-gray-200">
                        <span>
                            <TbPhotoPlus size={35}/>
                            {loading==='bsnLogo' && 'Uploading ...'}
                        </span>
                        <input
                        onChange={e => handleFileChange(e,setBusinessLogo, setBusinessLogoUrl,'bsnLogo')}
                        id="file-upload" name="file-upload" type="file" className="sr-only"/>
                        </label>
                    </div>
                    {/* <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p> */}
                    </div>
                </div>}
                <label for="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">Add logo</label>

                </div>

                </div>
                <div className="flex flex-row-reverse w-full py-3">
                    <div className="flex flex-row gap-2">
                    <button
                    onClick={() => clearStepOne()}
                     className='rounded-md border border-gray-400 py-2 px-4 text-center text-gray-800'>Cancel</button>
                    <button
                    onClick={() => setStep(STEP_TWO)}
                     className='rounded-md py-2 px-6 text-center bg-gray-300 text-gray-600'>Save as draft</button>
                    </div>
                </div>
            </div>}

        {step === STEP_TWO &&
        <>
        <div className="flex flex-col w-full gap-2">
            <div className="flex flex-row gap-2 justify-between">
                <p className='font-semibold text-[18px]'>{STEP_TWO}</p>
                <div className="flex flex-row gap-2 items-center">
                <button
                onClick={() => setStep(STEP_ONE)}
                    className='rounded-md py-2 px-4 text-center bg-gray-200 text-gray-600'>Previous</button>
                <button
                onClick={() => setStep(STEP_THREE)}
                    className='rounded-md py-2 px-4 text-center bg-red-100 text-red-400'>Next</button>
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
            <p className='font-semibold text-gray-500 text-[18px]'>Step {step === STEP_ONE ? '1': step === STEP_TWO ? '2': step === STEP_THREE ? '3' : step === STEP_FOUR ? '4':null} of 4</p>
            <div className="grid grid-cols-4 gap-2 w-full">
                <div className={`w-full h-2 rounded-lg ${step === STEP_ONE ? "bg-red-300":"bg-gray-200"}`}></div>
                <div className={`w-full h-2 rounded-lg ${step === STEP_TWO ? "bg-red-300":"bg-gray-200"}`}></div>
                <div className={`w-full h-2 rounded-lg ${step === STEP_THREE ? "bg-red-300":"bg-gray-200"}`}></div>
                <div className={`w-full h-2 rounded-lg ${step === STEP_FOUR ? "bg-red-300":"bg-gray-200"}`}></div>

            </div>
            </div>
            </div>
            <div className="flex flex-col w-full gap-4">
                <p className='text-[18px] font-semibold'>Details of owners, partners or directors</p>
                <div className="grid grid-cols-1  w-full gap-4">
                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">

                <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Name</label>
                    <input
                    value={partnerName}
                    onChange={handleInputChange(setPartnerName)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Name' />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Phone number</label>
                    <div className="flex flex-row items-center border rounded-md gap-1">
                        <p className=' py-2 px-1 bg-gray-100'>+254</p>
                    <input
                    value={partnerPhone}
                    maxLength={9}
                    onChange={handleInputChange(setPartnerPhone)}
                     type="number" className='w-full outline-none py-2 px-1 flex-1' placeholder='Phone number' />
                     </div>
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Email</label>
                    <input
                    value={partnerEmail}
                    onChange={handleInputChange(setPartnerEmail)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Email' />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Issued share capital (ksh)</label>
                    <input
                    value={partnerShare}
                    onChange={handleInputChange(setPartnerShare)}
                     type="number" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Issued share capital' />
                    </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-4 w-full gap-3">

                    <div className="flex flex-col w-full">
                   
                  

                    <div className=" flex flex-col justify-center rounded-lg border p-4 bg-gray-200">
                    <label htmlFor="" className='text-gray-600'>KRA PIN Certificate</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="kra-directors" className="relative cursor-pointer rounded-md bg-gray-200">
                                <span className='flex flex-row gap-2 items-center'>
                                <BsCloudUploadFill size={25}/>
                                   {loading==='dirKraPin' ? 'Uploading ...' : 'Upload file'}
                                </span>
                                <input
                                
                                onChange={e => handleFileChange(e,setDirectorKraPin, setDirectorKraPinUrl, 'dirKraPin')} 
                                id="kra-directors" name="kra-directors" type="file" className="sr-only"/>
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className=" flex flex-col justify-center rounded-lg border p-4 bg-gray-200">
                    <label htmlFor="" className='text-gray-600'>TCC for director</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="tcc-directors" className="relative cursor-pointer rounded-md bg-gray-200">
                                <span className='flex flex-row gap-2 items-center'>
                                <BsCloudUploadFill size={25}/>
                                {loading==='dirKraTcc' ? 'Uploading ...' : 'Upload file'}
                                </span>
                                <input
                                
                                onChange={e => handleFileChange(e,setDirectorTCC,setDirectorTCCUrl,'dirKraTcc')} 
                                id="tcc-directors" name="tcc-directors" type="file" className="sr-only"/>
                                </label>
                            </div>
                           
                            </div>
                    </div>

                    <div className=" flex flex-col justify-center rounded-lg border p-4 bg-gray-200">
                    <label htmlFor="" className='text-gray-600'>Copies of passport</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="passports" className="relative cursor-pointer rounded-md bg-gray-200">
                               
                                <span className='flex flex-row gap-2 items-center'>
                                <BsCloudUploadFill size={25}/>
                                {loading==='dirKraPass' ? 'Uploading ...' : 'Upload file'}
                                </span>
                                <input
                                onChange={e => handleFileChange(e,setDirectorPassport, setDirectorPassportUrl,'dirKraPass')} 
                                id="passports" name="passports" type="file" className="sr-only"/>
                                </label>
                            </div>
                            </div>
                        </div>


                        <div className=" flex flex-col justify-center rounded-lg border p-4 bg-gray-200">
                    <label htmlFor="" className='text-gray-600'>ID</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="ids-directors" className="relative cursor-pointer rounded-md bg-gray-200">
                                <span className='flex flex-row gap-2 items-center'>
                                <BsCloudUploadFill size={25}/>
                                {loading==='dirIdUrl' ? 'Uploading ...' : 'Upload file'}
                                </span>
                                <input
                                onChange={e => handleFileChange(e,setDirectorId, setDirectorIdUrl,'dirIdUrl')}
                                id="ids-directors" name="ids-directors" type="file" className="sr-only"/>
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>


                </div>
                {directors?.length > 0 &&
                                                    <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                                                    <table class="table-auto w-full  ">
                                                    <thead className='pl-2'>
                                                        <tr className='pl-1 border-b border-gray-200'>
                                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Name</th>
                                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Phone no</th>
                                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Emaill</th>
                                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Shares</th>
                                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Delete</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {directors?.map((director, index) => (
                                                            <tr className='p-2 border-b border-b-gray-200 '>
                                                                <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                                <div className=" text-[10px] md:text-[14px] ">
                                                                {director?.name}
                                                                </div>
                                                            </td>
                                                            <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                                <div className=" text-[10px] md:text-[14px] ">
                                                                {director?.phone_number}
                                                                </div>
                                                            </td>
                                                            <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                                <div className=" text-[10px] md:text-[14px] ">
                                                                {director?.email}
                                                                </div>
                                                            </td>
                                                            <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                                <div className=" text-[10px] md:text-[14px] ">
                                                                {director?.issued_share_capital}
                                                                </div>
                                                            </td>
                                                            <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                                <div className=" text-[10px] md:text-[14px] ">
                                                                <button onClick={() => removeObjectByIndexAndUpdateState(index, directors, setDirectors)}>
                                                                        <MdDeleteForever className='text-red-500' size={25}/>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    </table>
                                                    </div>
                }
                
                <div className="flex flex-col gap-2 w-full py-2">
                    <p className='text-[14px]'>{directors?.length} directors added.</p>
                    <div className="flex flex-row w-full">
                        <button onClick={handleAddPartner} className='bg-gray-200 px-2 flex flex-row  items-center gap-2 py-2 text-center rounded-md'>
                            <FiPlus size={20}/>
                            {loading==='directors' ? 'Uploading files...':'Add owner/ director'}
                            </button>
                    </div>
                </div>

            </div>

            <div className="flex flex-col w-full gap-2 py-3">
                <p  className='text-[18px] font-semibold'>Business documents</p>
                <div className="grid grid-cols-1 w-full">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-3">

                    <div className=" flex flex-col justify-center rounded-lg border p-4 bg-gray-200">
                    <label htmlFor="" className='text-gray-600'>Certificate of incoporation</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="reg-cert" className="relative cursor-pointer rounded-md bg-gray-200">
                               <span className='flex flex-row gap-2 items-center'>
                                <BsCloudUploadFill size={25}/>
                                {loading==='comReguRL' ? 'Uploading ...' : 'Upload file'}
                                </span>
                                <input
                                onChange={e => handleFileChange(e,setCompanyRegCert, setCompanyRegCertUrl,'comReguRL')}
                                id="reg-cert" name="reg-cert" type="file" className="sr-only"/>
                                </label>
                            </div>
                            </div>
                        </div>
                    
                   

                        <div className=" flex flex-col justify-center rounded-lg border p-4 bg-gray-200">
                    <label htmlFor="" className='text-gray-600'>KRA PIN certificate</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="kra-cert" className="relative cursor-pointer rounded-md bg-gray-200">
                                <span className='flex flex-row gap-2 items-center'>
                                <BsCloudUploadFill size={25}/>
                                {loading==='comkRAReguRL' ? 'Uploading ...' : 'Upload file'}
                                </span>
                                <input
                                onChange={e => handleFileChange(e,setCompanyKraCert, setCompanyKraCertUrl, 'comkRAReguRL')} 
                                id="kra-cert" name="kra-cert" type="file" className="sr-only"/>
                                </label>
                            </div>
                            </div>
                        </div>
                    
                  
                        <div className=" flex flex-col justify-center rounded-lg border p-4 bg-gray-200">
                     <label htmlFor="" className='text-gray-600'>Company CR12</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="cr12" className="relative cursor-pointer rounded-md bg-gray-200">
                                <span className='flex flex-row gap-2 items-center'>
                                <BsCloudUploadFill size={25}/>
                                {loading==='comCRUrl' ? 'Uploading ...' : 'Upload file'}
                                </span>
                                <input
                                onChange={e => handleFileChange(e,setCompanyCR12Cert, setCompanyCR12CertUrl,'comCRUrl')} 
                                id="cr12" name="cr12" type="file" className="sr-only"/>
                                </label>
                            </div>
                            </div>
                        </div>
                   

                        <div className=" flex flex-col justify-center rounded-lg border p-4 bg-gray-200">
                    <label htmlFor="" className='text-gray-600'>Cancelled cheque</label>
                            <div className="text-center">
                            <div className="flex text-sm leading-6 text-gray-600">
                                <label for="cheque" className="relative cursor-pointer rounded-md bg-gray-200">
                                <span className='flex flex-row gap-2 items-center'>
                                <BsCloudUploadFill size={25}/>
                                {loading==='comCheque' ? 'Uploading ...' : 'Upload file'}
                                </span>
                                <input
                                 onChange={e => handleFileChange(e,setCompanyCancellChequeCert, setCompanyCancellChequeCertUrl,'comCheque')}  
                                id="cheque" name="cheque" type="file" className="sr-only"/>
                                </label>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-row-reverse w-full">
                    <div className="flex flex-row gap-2">
                    <button
                    onClick={() =>clearStepTwo()}
                     className='rounded-md border border-gray-400 py-2 px-4 text-center text-gray-800'>Cancel</button>
                    <button
                    onClick={() => setStep(STEP_THREE)}
                     className='rounded-md py-2 px-6 text-center bg-gray-300 text-gray-600'>Save as draft</button>
                    </div>
                </div>
        </>
        }

        {step === STEP_THREE &&
                <>
                <div className="flex flex-col w-full gap-2">
                    <div className="flex flex-row gap-2 justify-between">
                        <p className='font-semibold text-[18px]'>{STEP_THREE}</p>
                        <div className="flex flex-row gap-2 items-center">
                        <button
                        onClick={() => setStep(STEP_TWO)}
                            className='rounded-md py-2 px-4 text-center bg-gray-200 text-gray-600'>Previous</button>
                        <button
                        onClick={() => setStep(STEP_FOUR)}
                            className='rounded-md py-2 px-4 text-center bg-red-100 text-red-400'>Next</button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                    <p className='font-semibold text-gray-500 text-[18px]'>Step {step === STEP_ONE ? '1': step === STEP_TWO ? '2': step === STEP_THREE ? '3' : step === STEP_FOUR ? '4':null} of 4</p>
                    <div className="grid grid-cols-4 gap-2 w-full">
                        <div className={`w-full h-2 rounded-lg ${step === STEP_ONE ? "bg-red-300":"bg-gray-200"}`}></div>
                        <div className={`w-full h-2 rounded-lg ${step === STEP_TWO ? "bg-red-300":"bg-gray-200"}`}></div>
                        <div className={`w-full h-2 rounded-lg ${step === STEP_THREE ? "bg-red-300":"bg-gray-200"}`}></div>
                        <div className={`w-full h-2 rounded-lg ${step === STEP_FOUR ? "bg-red-300":"bg-gray-200"}`}></div>

                    </div>
                    </div>
                </div>
                <div className="flex flex-col w-full gap-4">
                <p className='text-[18px] font-semibold'>Business bankers</p>
                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4 py-2">
                <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Name</label>
                            <input
                            value={bankName}
                            onChange={handleInputChange(setBankName)}
                            type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Name' />
                            </div>
                        <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Branch</label>
                            <input
                            value={bankBranch}
                            onChange={handleInputChange(setBankBranch)}
                            type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Branch name' />
                            </div>
                            <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Account name</label>
                            <input
                            value={bankAccountName}
                            onChange={handleInputChange(setBankAccountName)}
                            type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Account name' />
                            </div>

                            <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Account number</label>
                            <input
                            value={bankAccountNo}
                            onChange={handleInputChange(setBankAccountNo)}
                            type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Account number' />
                            </div>

                </div>
                
                <div className=" flex flex-col justify-center rounded-lg border p-4 w-full bg-gray-200 py-2">
                <label htmlFor="" className='text-gray-600'>Copy of bank statement(last 3 months)</label>
                    <div className="text-center">
                    <div className="flex text-sm leading-6 text-gray-600">
                        <label for="bank-statement" className="relative cursor-pointer rounded-md bg-gray-200">
                        <span className='flex flex-row gap-2 items-center'>
                        <BsCloudUploadFill size={25}/>
                        {loading==='comStatement' ? 'Uploading ...' : 'Upload file'}
                        </span>
                        <input
                        onChange={e => handleFileChange(e,setBankStatement,setBankStatementUrl,'comStatement')} 
                        id="bank-statement" name="bank-statement" type="file" className="sr-only"/>
                        </label>
                    </div>
                    </div>
                </div>

                    {bankers?.length > 0 &&
                                    <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                                    <table class="table-auto w-full  ">
                                    <thead className='pl-2'>
                                        <tr className='pl-1 border-b border-gray-200'>
                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Bank</th>
                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Branch</th>
                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Acc/ No</th>
                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Acc Name</th>
                                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {bankers?.map((banker, index) => (
                                            <tr className='p-2 border-b border-b-gray-200 '>
                                                <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                <div className=" text-[10px] md:text-[14px] ">
                                                {banker?.name}
                                                </div>
                                            </td>
                                            <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                <div className=" text-[10px] md:text-[14px] ">
                                                {banker?.bank_name}
                                                </div>
                                            </td>
                                            <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                <div className=" text-[10px] md:text-[14px] ">
                                                {banker?.account_name}
                                                </div>
                                            </td>
                                            <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                <div className=" text-[10px] md:text-[14px] ">
                                                {banker?.account_number}
                                                </div>
                                            </td>
                                            <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                <div className=" text-[10px] md:text-[14px] ">
                                                            <button onClick={() => removeObjectByIndexAndUpdateState(index, bankers, setBankers)}>
                                                <MdDeleteForever className='text-red-500' size={25}/>
                                            </button>
                                                </div>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    </table>
                                    </div>
                    }

                    <div className="flex flex-row w-full py-2">
                    <button onClick={handleAddBanker} className='bg-gray-200 px-2 py-2 text-center rounded-md flex flex-row gap-2 items-center'>
                        <FiPlus size={20}/>
                        {loading ? 'Uploading...':'Add banker'}
                        </button>
                </div>
                </div>
                <div className="flex flex-col w- gap-3 py-3">
                <p className='text-[18px] font-semibold'>Financial history <span className='text-gray-400'>(tunover for last 3 years)</span></p>
                <div className="flex flex-col w-full gap-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                        <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Year </label>
                            <input
                            value={year}
                            onChange={handleInputChange(setYear)}
                            type="number" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Enter Year ' />
                            </div>
                            <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Amount (Ksh)</label>
                            <input
                            value={yearAmount}
                            onChange={handleInputChange(setYearAmount)}
                            type="number" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Amount in shillings' />
                            </div>
                        </div>
                        {tunovers?.length > 0 &&
                        
                        <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                        <table class="table-auto w-full  ">
                        <thead className='pl-2'>
                            <tr className='pl-1 border-b border-gray-200'>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Year</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Amount</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                        {tunovers?.map((tunover, index) => (
                                <tr className='p-2 border-b border-b-gray-200 '>
        
                                <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                    <div className=" text-[10px] md:text-[14px] ">
                                    {tunover?.year}
                                    </div>
                                </td>
                                <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                    <div className=" text-[10px] md:text-[14px] ">
                                    {tunover?.tunover}
                                    </div>
                                </td>
                                <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                    <div className=" text-[10px] md:text-[14px] ">
                                    <button onClick={() => removeObjectByIndexAndUpdateState(index, tunovers, setTunovers)}>
                                        <MdDeleteForever className='text-red-500' size={25}/>
                                    </button>
                                    </div>
                                </td>
                                </tr>
                            ))}
                        </tbody>
                        </table>
                        </div>}
                        <div className="flex flex-col gap-2 w-full">
                            <p className='text-[14px]'>{tunovers?.length} tunovers added.</p>
                            <div className="flex flex-row w-full">
                                <button onClick={handleAddTunover} className='bg-gray-200 px-2 py-2 text-center rounded-md flex flex-row items-center gap-2'>
                                <FiPlus size={20}/>
                                    Add tunover
                                    </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="flex flex-col w-full gap-2 py-3">
                        <p className='text-[18px] font-semibold'>Business credit application</p>
                        <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                        <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Credit limit applied for (ksh)</label>
                            <input
                            value={businessCreditLimit}
                            onChange={handleInputChange(setBusinessCreditLimit)}
                            type="number" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Enter amount' />
                            </div>
                            {/* <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Credit period (days)</label>
                            <input
                            value={businessCreditPeriod}
                            onChange={handleInputChange(setBusinessCreditPeriod)}
                            type="number" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Enter days' />
                            </div> */}

                        </div>

                    </div>

                    <div className="flex flex-col w-full gap-4 py-3">
                        <p className='text-[18px] font-semibold'>Security offered</p>
                        <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-4">
                        <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Bank</label>
                            <input
                            value={securityBank}
                            onChange={handleInputChange(setSecurityBank)}
                            type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Bank' />

                            </div>
                            <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Maturity date</label>
                            <input
                            value={securityMaturity}
                            onChange={handleInputChange(setSecurityMaturity)}
                            type="date" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Maturity date' />
                            </div>
                            <div className="flex flex-col gap-1 w-full">
                            <label htmlFor="" className='text-gray-600'>Value (ksh)</label>
                            <input
                            value={securityValue}
                            onChange={handleInputChange(setSecurityValue)}
                            type="number" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Value (ksh)' />
                            </div>
                        </div>

                    </div>
                <div className="flex flex-row-reverse w-full">
                        <div className="flex flex-row gap-2">
                        <button
                        onClick={() => clearStepThree()}
                            className='rounded-md border border-gray-400 py-2 px-4 text-center text-gray-800'>Cancel</button>
                        <button
                        onClick={() => setStep(STEP_FOUR)}
                            className='rounded-md py-2 px-6 text-center bg-gray-300 text-gray-600'>Save as draft</button>
                        </div>
                </div>
                </>
        }
        {step === STEP_FOUR &&
        <>
                <div className="flex flex-col w-full gap-2">
                    <div className="flex flex-row gap-2 justify-between">
                        <p className='font-semibold text-[18px]'>{STEP_FOUR}</p>
                        <div className="flex flex-row gap-2 items-center">
                        <button
                        onClick={() => setStep(STEP_THREE)}
                            className='rounded-md py-2 px-4 text-center bg-gray-200 text-gray-600'>Previous</button>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 w-full">
                    <p className='font-semibold text-gray-500 text-[18px]'>Step {step === STEP_ONE ? '1': step === STEP_TWO ? '2': step === STEP_THREE ? '3' : step === STEP_FOUR ? '4':null} of 4</p>
                    <div className="grid grid-cols-4 gap-2 w-full">
                        <div className={`w-full h-2 rounded-lg ${step === STEP_ONE ? "bg-red-300":"bg-gray-200"}`}></div>
                        <div className={`w-full h-2 rounded-lg ${step === STEP_TWO ? "bg-red-300":"bg-gray-200"}`}></div>
                        <div className={`w-full h-2 rounded-lg ${step === STEP_THREE ? "bg-red-300":"bg-gray-200"}`}></div>
                        <div className={`w-full h-2 rounded-lg ${step === STEP_FOUR ? "bg-red-300":"bg-gray-200"}`}></div>

                    </div>
                    </div>
                </div>
        <div className="flex flex-col gap-4">

        <div className="flex flex-col w-full gap-4">
                <p className='text-[18px] font-semibold'>References for the company</p>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 w-full gap-4">
                <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Name</label>
                    <input
                    value={referenceName}
                    onChange={handleInputChange(setReferenceName)} 
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Enter name' />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Phone number</label>
                    <div className="flex flex-row items-center border rounded-md gap-1">
                        <p className=' py-2 px-1 bg-gray-100'>+254</p>
                    <input
                    value={referencePhone}
                    maxLength={9}
                    onChange={handleInputChange(setReferencePhone)} 
                     type="number" className='w-full outline-none py-2 px-1' placeholder='Phone number' />
                     </div>
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Address</label>
                    <input
                    value={referenceAddress}
                    onChange={handleInputChange(setReferenceAddress)} 
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Address' />
                    </div>

                </div>
                {references?.length > 0 &&

                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                <table class="table-auto w-full  ">
                <thead className='pl-2'>
                    <tr className='pl-1 border-b border-gray-200'>
                    <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                    <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Phone no</th>
                    <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Address</th>
                    <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Delete</th>
                    </tr>
                </thead>
                <tbody>
                {references?.map((reference, index) => (
                        <tr className='p-2 border-b border-b-gray-200 '>

                        <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                            <div className=" text-[10px] md:text-[14px] ">
                            {reference?.name}
                            </div>
                        </td>
                        <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                            <div className=" text-[10px] md:text-[14px] ">
                            {reference?.phone_number}
                            </div>
                        </td>
                        <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                            <div className=" text-[10px] md:text-[14px] ">
                            {reference?.address}
                            </div>
                        </td>
                        <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                            <div className=" text-[10px] md:text-[14px] ">
                            <button onClick={() => removeObjectByIndexAndUpdateState(index, references, setReferences)}>
                                                <MdDeleteForever className='text-red-500' size={25}/>
                                            </button>
                            </div>
                        </td>
                        </tr>
                    ))}
                </tbody>
                </table>
                </div>
                }
                <div className="flex flex-col gap-2 w-full">
                <p className='text-[14px]'>{references?.length} references added.</p>
                    <div className="flex flex-row w-full">
                        <button onClick={handleAddReference} className='bg-gray-200 px-2 py-2 text-center rounded-md flex flex-row items-center gap-2 '>
                        <FiPlus size={20}/>
                            Add reference
                            </button>
                    </div>
                </div>
        </div>

            <div className="flex flex-col w-full gap-4 py-3">
                <p className='text-[18px] font-semibold'>Persons authorized to make purchases <span className='text-red-600'>*</span></p>
                <div className="grid grid-cols-1 md:grid-cols-2 w-full gap-4">
                <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Name</label>
                    <input
                    value={authorizedName}
                    onChange={handleInputChange(setAuthorizedName)}
                     type="text" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Enter name' />
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="" className='text-gray-600'>Limit</label>
                    <input
                    value={authorizedLimit}
                    onChange={handleInputChange(setAuthorizedLimit)}
                     type="number" className='w-full outline-none py-2 px-1 border rounded-md' placeholder='Limit' />
                    </div>
                </div>
                {authorized?.length > 0 &&
                
                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                <table class="table-auto w-full  ">
                  <thead className='pl-2'>
                    <tr className='pl-1 border-b border-gray-200'>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Limit</th>
                      <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {authorized?.map((authorize, index) => (
                        <tr className='p-2 border-b border-b-gray-200 '>

                          <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                            <div className=" text-[10px] md:text-[14px] ">
                              {authorize?.name}
                            </div>
                          </td>
                           <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                            <div className=" text-[10px] md:text-[14px] ">
                             {authorize?.limit}
                            </div>
                          </td>
                           <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                            <div className=" text-[10px] md:text-[14px] ">
                                   <button onClick={() => removeObjectByIndexAndUpdateState(index, authorized, setAuthorized)}>
                            <MdDeleteForever className='text-red-500' size={25}/>
                        </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
                }
                <div className="flex flex-col gap-2 w-full">
                <p className='text-[14px]'>{authorized?.length} authorized persons added.</p>
                    <div className="flex flex-row w-full">
                        <button onClick={handleAddAuthorizedPerson} className='bg-gray-200 px-2 py-2 text-center rounded-md flex flex-row items-center gap-2'>
                        <FiPlus size={20}/>
                            Add authorized person
                            </button>
                    </div>
                </div>
            </div>

                <div className="flex flex-row-reverse w-full py-3">
                    <div className="flex flex-row gap-2">
                    <button
                    onClick={() => clearStepFour()}
                     className='rounded-md border border-gray-400 py-2 px-4 text-center text-gray-800'>Cancel</button>
                    <button
                    disabled={loading} onClick={handleCreateB2BClientAndAddAnother}
                     className='rounded-md py-2 px-6 text-center border border-[#261F4D] text-[#261F4D]'>Save and add new</button>
                      <button
                      disabled={loading} onClick={handleCreateB2BClient}
                     className='rounded-md py-2 px-6 text-center bg-[#261F4D] text-white'>Save client</button>
                    </div>
                </div>
        </div>
        </>
        
        }

{/*             
            <div className="flex flex-col gap-2">

                <a href="" className='text-[16px] text-blue-900'>
                    Read terms and conditions
                </a>
            <div className="flex flex-row items-center gap-2">
                <input
                value={acceptTerms}
                onChange={(e) => setAcceptTerms(e.target.checked)}
                 type="checkbox" className='outline-none w-6 h-6 border accent-black' />
                <p>Accept terms and conditions</p>
            </div>
            </div> */}
        {/* </form> */}
      
    </div>
</div>
  )
}

export default ClientOnboarding