import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../../utils';
import { getName } from '../utils';
import Avatar from 'react-avatar';


const CUSTOMERS_URL = `/oms/customers/`;

function RemindersModal({ customerID, handleModalClose, customer, handleReload }) {
    console.log(customer)
    const [processing, setProcessing] = useState(false)
    const [data, setData] = useState()


    const fetchData = async () => {
        try {
            const response = await axiosCustom.get(`${CUSTOMERS_URL}${customerID}/follow-up/`);
            setData(response?.data?.results)
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }


    const markAsDone = async (item) => {
        setProcessing(true)
        try {
            const params = { done: true }
            await axiosCustom.post(`${CUSTOMERS_URL}follow-up/${item.id}/`, JSON.stringify(params));
            await fetchData()
        } catch (err) {
            console.log(err)
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <section>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <div className='min-h-h-screen flex justify-center items-center overflow-x-hidden overflow-y-auto py-10 fixed inset-0 z-[100] outline-none focus:outline-none'>
                    <div class="relative p-4 w-full max-w-2xl max-h-full">
                        <div class="relative bg-white rounded-lg shadow ">
                            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                                <h3 class="text-xl font-semibold text-gray-900 ">
                                    Customer Reminders
                                </h3>
                                <button
                                    onClick={handleModalClose}
                                    type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white" data-modal-hide="authentication-modal">
                                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                </button>
                            </div>
                            <div class="p-4 md:p-5">
                                {data && data?.length > 0 &&

                                    <ol class="relative border-s border-gray-200 ">
                                        {data?.map((item, index) => (
                                            <li class="mb-5 ms-6">
                                                <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm  ">
                                                    <div class="items-center justify-between mb-3 sm:flex">
                                                        <time class="mb-1 text-xs font-normal text-gray-400 sm:order-last sm:mb-0"><Moment fromNow>{item?.date_created}</Moment></time>
                                                        <div class="text-sm font-normal text-gray-500 lex "><span className='font-semibold text-gray-900 '>{item?.created_by.first_name} {item?.created_by.last_name}</span> scheduled a reminder for <Moment format="MMM, DD LT" className='font-semibold text-gray-900 '>{item?.datetime}</Moment></div>
                                                    </div>
                                                    {
                                                        item?.done ?
                                                            <div class="line-through p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50   ">{item?.message}</div>
                                                            :
                                                            <div class="p-3 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50   ">{item?.message}</div>
                                                    }
                                                    {!item?.done &&
                                                        <div class="flex items-center mb-4" onClick={() => markAsDone(item)}>
                                                            <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 :ring-blue-600  focus:ring-2  " />
                                                            <label for="default-checkbox" class="m-1 text-sm">Mark as done</label>
                                                        </div>
                                                    }
                                                </div>
                                            </li>
                                        ))}
                                    </ol>

                                }
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            }
            <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-70  fixed inset-0 z-50' />
        </section>
    )
}
export default RemindersModal