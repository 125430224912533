import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { AccountObj, makeAuthenticatedRequest } from '../utils';
import { toast, ToastContainer } from 'react-toastify'
import back from '../assets/ic_back.svg'

function DsfEditPage() {
    const { dsfID } = useParams()
    const [dsfData, setDsfData] = useState([])

    const navigate = useNavigate()



    const [phone_number, setPhone_number] = useState('')
    const [referrer_name, setReferrer_name] = useState('')
    const [referrer_id, setReferrer_id] = useState('')
    const [salesRep, setSalesRep] = useState('')
    


    const fetchDsf = (dsfID) => {
      makeAuthenticatedRequest(`/oms/referral-code/${dsfID}`)
        .then((response) => response.json())
        .then((data) => {
          setDsfData(data)
          setPhone_number(data?.phone_number)
          setReferrer_name(data?.referer_name)
          setReferrer_id(data?.referer_id_number)
          setSalesRep(data?.sales?.email)
      
        });
    }
    useEffect(() => {
      fetchDsf(dsfID)
    }, [])



    const [processing, setProcessing] = useState(false)
    const accountObj = new AccountObj()

    const updateDsf = async () => {
        if(phone_number === '' ){
          toast.error('Phone number is required')
        }else if(referrer_name === ''){
          toast.error('Referrer name is required')
        }else if(salesRep === ''){
          toast.error('salesRep is required')
        }else{
  
          const params = {"referrals":[{ phone_number: phone_number, referrer_name:referrer_name, referrer_id:referrer_id}],
                          "sales":salesRep}
          return makeAuthenticatedRequest(`/oms/referral-code`, "PUT", JSON.stringify(params))
             .then((response) => response.json())
             .then((data) => {
              console.log('send', data)
                // return data
                if (data && data.created.length > 0) {
                  toast.success("DSF succesfully updated")
                  window.location.reload()
                  
               } else {
                  toast.error("DSF could not be updated")
               }
             })
             .catch((err) => {
              toast.error("Something went wrong could update create Dsf")
              console.log('err', err)
    
           }).finally(() => {
              setProcessing(false);
           });
       }
        }


    console.log('dsfData',dsfData)
    console.log('phone',dsfData?.phone_number)

  return (
    <>
    <div>
        <div className="flex flex-col w-full gap-2 py-20">
            <div className="flex flex-row">

        <div
        onClick={() => navigate(-1)}
         className="flex flex-row gap-1 w-auto cursor-pointer  px-2 py-1 bg-[#F2F2F4] rounded-lg">
                <img src={back} alt="" />
                Back
                </div>
            </div>
        <div className="flex flex-col gap-2 w-full md:w-[60%]">
                   <form onSubmit={updateDsf} className='w-full flex flex-col gap-2'>
                   <div className="w-full flex flex-col gap-1">
                      <label htmlFor="rname" className='font-satoshi text-[14px]'>DSF Name</label>
                      <input required name='rname' 
                      placeholder='Add dsf referrer name'
                      value={referrer_name}
                      onChange={(e) => setReferrer_name(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="phone" className='font-satoshi text-[14px]'>DSF Phone Number</label>
                      <input required name='phone' 
                      type="text" 
                      placeholder='Add dsf phone number'
                      value={phone_number}
                      onChange={(e) => setPhone_number(e.target.value)}
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>


                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="rname" className='font-satoshi text-[14px]'>DSF ID Number</label>
                      <input required name='rid' 
                      placeholder='Add dsf referrer id number'
                      value={referrer_id}
                      onChange={(e) => setReferrer_id(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="rname" className='font-satoshi text-[14px]'>DSF Sales Representative</label>
                      <input required name='rid' 
                      placeholder='Add dsf sales rep email'
                      value={salesRep}
                      onChange={(e) => setSalesRep(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
              
                   </form>
                  <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                    onClick={updateDsf}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>Save</button>
                 
                </div>
                  </div>
        </div>
    </div>
        <ToastContainer

        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />

        </>
  )
}

export default DsfEditPage