import React, { useEffect, useRef, useState } from 'react'
import placeholder from "../assets/placeholder.svg"
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { formatNumber, makeAuthenticatedRequest } from '../utils';
import { Pagination } from '../components/Pagination';
import ic_plus_big from "../assets/ic_plus_big.svg"
import axios from "axios";
import more from "../assets/ic_more.svg"
import Moment from 'react-moment';
import Reorder from '../components/Reorder';
import AsyncSelect from 'react-select/async';
import { ENDPOINT_INVENTORY } from '../routes';
import { API_PREFIX, LS_ACCESS_TOKEN, LS_ACCESS_TOKEN_TYPE } from '../constants';

const TAB_ALL_PARTS = "all"
const TAB_APPROVED = "approved"
const TAB_NOT_APPROVED = "not_approved"


function BrandsPage() {
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchParams, setSearchParams] = useSearchParams();
  let s = searchParams.get("s") !== null ? searchParams.get("s") : '';
  const [currentTab, setCurrentTab] = useState(TAB_ALL_PARTS)


  const [perPage, setPerPage] = useState(21)
  const [search, setSearch] = useState(s)
  const [selectedMerchants, setSelectedMerchants] = useState([]);

  const [itemCount, setItemCount] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [approvedItemCount, setApprovedItemCount] = useState(0)
  const [notApprovedItemCount, setNotApprovedItemCount] = useState(0)


  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()


  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;



  const [open, setOPen] = useState([]);

  const [list, setList] = useState([]);


  const reorderInventory = (orderBy, order) => {
    setOrder(order)
    setOrderBy(orderBy)
  }

  useEffect(() => {
    fetchInventory(1)
  }, [order, orderBy, currentTab]);


  const pageChanged = (pageNumber) => {
    setCurrentPage(pageNumber)
    fetchInventory(pageNumber)
  }

  const submitSearch = () => {
    fetchInventory(1)
  }

  const [inventoryData, setInventoryData] = useState(null)

  const fetchInventory = (page) => {
    setProcessing(true)
    const params = {
      "page": page,
      "per_page": perPage
    }
    if (search) {
      params.s = search
    }
    if (order) {
      params.order = order
    }
    if (orderBy) {
      params.order_by = orderBy
    }
    if(currentTab == TAB_APPROVED){
      params.is_public = true
    }
    if(currentTab == TAB_NOT_APPROVED){
      params.is_public = false
    }

    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/brands/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setInventoryData(data)
        setCurrentPage(data.page)
        setPerPage(data.per_page)
        setItemCount(data.count)
        setApprovedItemCount(data.approved_item_count)
        setNotApprovedItemCount(data.not_approved_item_count)
        setTotalItems(data.total_items)
        setProcessing(false)
      });
  }


  useEffect(() => {
    fetchInventory(1);
  }, [selectedMerchants])


  useEffect(() => {
    setList(inventoryData);
  }, [list]);


  return (

    <>
      {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }
      <div className='w-full min-h-screen md:max-h-screen overflow-y-scroll hide-scrollbar'>
        <div className='h-24 '></div>
        <div className='flex flex-col gap-4'>
          <div>
            <p className='font-satoshi text-xl font-semibold'>Brands</p>
          </div>
          <div>
            <form className="flex items-start md:items-center font-satoshi" method="get">
              <label htmlFor="simple-search" className="sr-only">Search</label>
              <div className="relative w-full">
                <div
                  onClick={submitSearch}
                  className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md">
                  <svg aria-hidden="true" className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd">
                    </path>
                  </svg>
                </div>
                <input
                  onChange={e => setSearch(e.target.value)}
                  required
                  value={search}
                  type="text" name="s"
                  id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    " placeholder="Search brands" />
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-row gap-2 mt-4 overflow-auto ">
          <div
            onClick={() => setCurrentTab(TAB_ALL_PARTS)}
            className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === TAB_ALL_PARTS ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}> All
            <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{totalItems}</span>
          </div>
          <div
            onClick={() => setCurrentTab(TAB_APPROVED)}
            className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === TAB_APPROVED ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Approved
            <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{approvedItemCount}</span>
          </div>
          <div
            onClick={() => setCurrentTab(TAB_NOT_APPROVED)}
            className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === TAB_NOT_APPROVED ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Not Approved
            <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{notApprovedItemCount}</span>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col ">
            {
              inventoryData && !processing ? (
                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                  <table class="table-auto w-full">
                    <thead className='pl-1 md:pl-2 table-auto rounded-lg'>
                      <tr className='pl-1 md:pl-2 rounded-lg'>
                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                        </th>

                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                          <span>
                            <span className='inline pr-2'>ID</span>
                            <Reorder order={order} orderBy={orderBy} tag='pk' reorderInventory={reorderInventory} />
                          </span>
                        </th>

                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                          <span>
                            <span className='inline pr-2'>Name</span>
                            <Reorder order={order} orderBy={orderBy} tag='name' reorderInventory={reorderInventory} />
                          </span>
                        </th>
                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                          <span>
                            <span className='inline pr-2'>Parts</span>
                            <Reorder order={order} orderBy={orderBy} tag='name' reorderInventory={reorderInventory} />
                          </span>
                        </th>
                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                          <span>
                            <span className='inline pr-2'>Slug</span>
                            <Reorder order={order} orderBy={orderBy} tag='slug' reorderInventory={reorderInventory} />
                          </span>
                        </th>

                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                          <span>
                            <span className='inline pr-2'>Public</span>
                            <Reorder order={order} orderBy={orderBy} tag='sub_model' reorderInventory={reorderInventory} />
                          </span>
                        </th>

                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                          <span>
                            <span className='inline pr-2'>Date Added</span>
                            <Reorder order={order} orderBy={orderBy} tag='date_created' reorderInventory={reorderInventory} />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {inventoryData?.results.map((vehicle) => (
                        <tr key={vehicle.id} className='p-2 border border-b-gray-200 relative'
                        >
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]'>
                            {vehicle.image_url ?
                              <img src={vehicle.image_url} alt="" className='rounded-md border-[#c7bfbc] border h-12 w-12' />
                              :
                              <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-12 w-12">
                                <p className='font-satoshi text-[#F77B55] text-[14px] text-center'>No images</p>
                              </div>
                            }
                          </td>
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]'>
                            <p className={`font-satoshi  text-[14px]`}>#{vehicle.id}</p>
                          </td>
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]'>
                            <p className={`font-satoshi  text-[14px]`}>{vehicle.name}</p>
                          </td>
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]'>
                            <p className={`font-satoshi  text-[14px]`}>{vehicle.parts_count}</p>
                          </td>
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]'>
                            <p className={`font-satoshi  text-[14px]`}>{vehicle.slug}</p>
                          </td>
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]'>
                            <p className={`font-satoshi  text-[14px]`}>{vehicle.is_public ? 'Yes' : 'No'}</p>
                          </td>
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi'>
                            <Moment format="MMM, DD Y">
                              {vehicle.date_created}
                            </Moment>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                  <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} />
                </div>
              ) :
                <p className='font-satoshi text-[18px] py-6'>{processing ? "Processing" : "No inventory to display"}</p>
            }
          </div>
        </div>
      </div>
    </>

  )
}

export default BrandsPage