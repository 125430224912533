import { axiosCustom } from '../../../utils';

const CMS_HERO_URL = '/oms/cms/homepage-product-subsections/';

export const createProductSubsection = async (productData) => {
    try {
        const response = await axiosCustom.post(CMS_HERO_URL, productData);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const getProductSubsections  = async () => {
    try {
        const response = await axiosCustom.get(CMS_HERO_URL);
        return response.data.results;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const updateProductSubsection   = async (productSubsectionId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_HERO_URL}${productSubsectionId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const deleteProductSubsection  = async (productSubsectionId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_HERO_URL}${productSubsectionId}/`);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};

