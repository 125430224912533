import { React, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import Select from 'react-select';
import { toast } from "react-toastify";
import {
  createShopSubsection,
  getShops,
} from "./shopUtils";
import {
  isNonNegativeInteger,
  isValidString,
  getParentSections,
} from "../cmsUtils"

function ProductAdd() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedShops, setSelectedShops] = useState([]);
  const [subsectionTitle, setSubsectionTitle] = useState(null)
  const [order, setOrder] = useState(null)
  const [selectedParentSection, setSelectedParentSection] = useState(null)
  const [isActive, setIsActive] = useState(false);
  const [background, setBackground] = useState(null)
  const [textColor, setTextColor] = useState(null)
  const [merchants, setMerchants] = useState([]);
  const [parentSections, setParentSections] = useState([]);

  useEffect(() => {
    getParentSections()
      .then((results) => {
        setParentSections(results)
      })
      .catch((error) => {
        toast.error("Something went wrong. Could not fetch parent sections")
      })
  }, [])

  const parentSectionOptions = useMemo(() => {
    const shopSections = parentSections.filter(section => section.section_type === "shop_section")
    return shopSections.map((shopSection) => {
      return {
        value: shopSection?.id,
        label: shopSection?.title
      }
    })
  }, [parentSections])

  useEffect(() => {
    getShops()
      .then((data) => {
        setMerchants(data?.results)
      })
      .catch((error) => {
        toast.error("Something went wrong. Could not fetch shops")
      })
  }, [])

  const shopOptions = useMemo(() => {
    console.log("Creating Shop Options")
    return merchants.map((merchant => {
      return {
        value: merchant?.id,
        label: merchant?.merchant_name,
      }
    }))
  }, [merchants])

  const handleSelectedShops = (e) => {
    setSelectedShops(e)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setProcessing(true)

    const shops = selectedShops.map(shop => shop?.value)

    if (selectedParentSection === null) {
      toast.error("A parent section is required");
      setProcessing(false);
      return
    }

    if (!isValidString(subsectionTitle)) {
      toast.error("Invalid title");
      setProcessing(false);
      return
    }

    if (!isNonNegativeInteger(order)) {
      toast.error("Invalid order");
      setProcessing(false);
      return
    }

    if (shops.length === 0) {
      toast.error("Shops are required");
      setProcessing(false);
      return
    }

    if (!isValidString(textColor)) {
      toast.error("Invalid text color");
      setProcessing(false);
      return
    }

    if (!isValidString(background)) {
      toast.error("Invalid background");
      setProcessing(false);
      return
    }

    const params = {
      background: background,
      text_color: textColor,
      is_active: isActive,
      order: order,
      title: subsectionTitle,
      section: selectedParentSection,
      merchants: shops,
    }

    createShopSubsection(params)
      .then((response) => {
        toast.success("Shop subsection successfully added.")
        navigate(-1)
      })
      .catch((error) => {
        toast.error("Something went wrong. Could not add shop  subsection.")
      })
      .finally(() => {
        setProcessing(false);
      })
  }

  return (
    <>
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-4 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
        </div>
        <div className="flex flex-col">
          <div className="pl-2 md:pl-28 pt-1  flex flex-row md:grid md:grid-cols-5 gap-2 border-b pb-2 w-full bg-white z-50">
            <div className="col-span-1">
              <h2 className="font-satoshi font-semibold text-xs md:text-lg">
                Add a shop Subsection
              </h2>
            </div>
            <div className="col-span-1 "></div>
            <div className="col-span-1 flex flex-row gap-2 items-end"></div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:w-[90%] gap-10 pl-2 md:pl-20 pt-1">
          <div className="h-full pt-2 flex flex-col md:flex-row gap-20 mt-2 flex-[3] p-1 hide-scrollbar overflow-y-scroll">
            <div className="w-full flex flex-col gap-2">

              <div className="flex flex-col gap-1 w-full mt-2 mb-2">
                <p className='text-[#818490] font-satoshi text-md font-semibold'>Parent Section</p>
                <Select
                  options={parentSectionOptions}
                  onChange={(e) => { setSelectedParentSection(e.value) }}
                />
              </div>

              <p className="text-[#818490] font-satoshi text-md font-semibold">
                Subsection Title
              </p>
              <input
                onChange={e => setSubsectionTitle(e.target.value)}
                required
                className="border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                placeholder="e.g Trending shops"
              />

              <p className="text-[#818490] font-satoshi text-md font-semibold">
                Order
              </p>
              <input
                onChange={e => setOrder(e.target.value)}
                required
                className="border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                placeholder="e.g 1"
              />

              <p className='text-[#818490] font-satoshi text-md font-semibold'>Shops</p>
              <div className="flex flex-col">
                <Select
                  isMulti
                  isClearable
                  name="shop"
                  options={shopOptions}
                  onChange={e => handleSelectedShops(e)} />
              </div>

              <p className="text-[#818490] font-satoshi text-md font-semibold">
                Text Color
              </p>
              <input
                onChange={e => setTextColor(e.target.value)}
                required
                className="border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                placeholder="e.g black"
              />

              <p className="text-[#818490] font-satoshi text-md font-semibold">
                Background Color
              </p>
              <input
                onChange={e => setBackground(e.target.value)}
                required
                className="border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                placeholder="e.g white"
              />

              <div className="py-3">
                <div className='text-gray-500 font-bold text-sm gap-2 mr-4'>Activate Section
                  <span className='float-right'>
                    <label htmlFor="push-green-toggle" className="inline-flex relative items-center mr-5 cursor-pointer">
                      <input
                        onChange={() => setIsActive(!isActive)}
                        checked={isActive}
                        type="checkbox"
                        value=""
                        id="push-green-toggle"
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full  peer-focus:ring-3
                    peer-focus:ring-green-300 :ring-green-800 peer-checked:after:translate-x-full after:absolute after:top-0.5 after:left-[2px]
                      after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all
                      peer-checked:bg-green-600"></div>
                    </label>
                  </span>
                </div>
              </div>
              <hr />

              {processing ? (
                <div className="flex flex-row gap-2">
                  <button className='py-1 md:py-2 px-1 md:px-4 text-center font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-xs md:text-sm' >
                    <span className='text-center' >Processing</span> 
                    <ReactLoading type="bubbles" color="#030A1A" height={10} width={30} className="inline-block align-middle" />
                  </button>
                </div>
              ) : (
                <div class="mt-2 flex gap-2 w-full flex-row-reverse col-span-2">
                  <button
                    onClick={(e) => handleSubmit(e)}
                    type="submit"
                    title="save"
                    class="py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-white border-gray-200 bg-[#19223B] text-sm"
                  >
                    Save
                  </button>
                  <button class="py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm">
                    <span class="text-center">Save and add another</span>
                  </button>
                  <button class="py-1 md:py-2 px-1 md:px-4 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm">
                    <span class="text-center">Cancel</span>
                  </button>
                </div>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductAdd;
