import { axiosCustom } from '../../../utils';
import { makeAuthenticatedRequest } from '../../../utils'

const CMS_HERO_URL = '/oms/cms/homepage-shop-subsections/';
const SHOPS_URL = `/merchant/search`;


export const createShopSubsection = async (shopData) => {
    try {
        const response = await axiosCustom.post(CMS_HERO_URL, shopData);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const getShopSubsections = async () => {
    try {
        const response = await axiosCustom.get(CMS_HERO_URL);
        return response.data.results;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const updateShopSubsection = async (shopSubsectionId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_HERO_URL}${shopSubsectionId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const deleteShopSubsection = async (shopSubsectionId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_HERO_URL}${shopSubsectionId}/`);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const getShops = async () => {
    try {
        const data = await makeAuthenticatedRequest(SHOPS_URL)
        return data.json();
    } catch (error) {
        console.log("an error occured", error)
    }
};
