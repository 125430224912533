import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../utils';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import Datepicker from "react-tailwindcss-datepicker";
import dayjs from "dayjs";


const CUSTOMERS_URL = `/oms/customers/`;


function PaymentsTab() {
    const { customerID } = useParams()
    const [data, setData] = useState()
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [perPage, setPerPage] = useState(20)
    const [processing, setProcessing] = useState(false)
    const [dateRange, setDateRange] = useState({
        startDate: dayjs(new Date()).format("YYYY-MM-DD"),
        endDate: dayjs(new Date()).format("YYYY-MM-DD")
    });

    const handleDateRangeChange = (newValue) => {
        setDateRange(newValue);
    }

    const fetchData = async () => {
        setProcessing(true)
        try {
            const params = {
                "page": page,
                "per_page": perPage,
            }

            if (dateRange?.startDate && dateRange?.endDate) {
                params.start_date = dateRange.startDate;
                params.end_date = dateRange.endDate
            }

            const searchParams = new URLSearchParams(params).toString();
            const response = await axiosCustom.get(`${CUSTOMERS_URL}${customerID}/payments/?${searchParams}`);
            setPage(response?.data?.page)
            setData(response?.data?.results)
            setCount(response?.data?.count)
            setPerPage(response?.data?.per_page)
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, [page, dateRange])

    return (
        <>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div class="mx-auto max-w-screen-2xl">
                        <div class="mt-4 relative overflow-hidden">
                            <div className='w-full mb-10'>
                                <div
                                    className='w-60 float-right'>
                                    <Datepicker
                                        value={dateRange}
                                        onChange={handleDateRangeChange}
                                        showShortcuts={true}
                                    />
                                </div>
                            </div>

                            {!data || data?.length < 1
                                ?
                                <section class="bg-white ">
                                    <div class="py-8 mx-auto px-4">
                                        <span className='text-sm'>No transactions to display</span>
                                    </div>
                                </section>
                                :
                                <div class="relative overflow-x-auto">
                                    <>
                                        <table class="w-full text-sm text-left text-gray-500">
                                            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                                <tr>
                                                    <th scope="col" class="px-4 py-3">#</th>
                                                    <th scope="col" class="px-4 py-3">Date</th>
                                                    <th scope="col" class="px-4 py-3">Narration</th>
                                                    <th scope="col" class="px-4 py-3">Credit</th>
                                                    <th scope="col" class="px-4 py-3">Debit</th>
                                                    <th scope="col" class="px-4 py-3">Balance</th>
                                                </tr>
                                            </thead>

                                            {data?.map((item, i) => (
                                                <tbody>
                                                    <tr class="cursor-pointer border-b hover:bg-gray-100" >
                                                        <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                            <div class="flex items-center">
                                                                <div className='flex flex-col'>
                                                                    <span>
                                                                        SP{item?.id}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <th scope="row" class="flex items-center px-2 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                            <div className='flex flex-row'>
                                                                <div className=" text-[10px] md:text-[14px] ">
                                                                    <Moment format="MMM, DD LT" className='flex'>
                                                                        {item.date_created}
                                                                    </Moment>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                            <div class="flex items-center">
                                                                <div className='flex flex-col'>
                                                                    <span>
                                                                        {item?.narration}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                            <div class="flex items-center">
                                                                <div className='flex flex-col'>
                                                                    <span>

                                                                        {item?.entry_type === "Credit"
                                                                            ? `+${formatNumber(parseInt(item?.amount), 0, 0)}`
                                                                            : ""
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                            <div class="flex items-center">
                                                                <div className='flex flex-col'>
                                                                    <span>

                                                                        {item?.entry_type === "Debit"
                                                                            ? `-${formatNumber(parseInt(item?.amount), 0, 0)}`
                                                                            : ""
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                            <div class="flex items-center">
                                                                <div className='flex flex-col'>
                                                                    <span>
                                                                        {formatNumber(parseInt(item?.balance), 0, 0)}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                    </>
                                </div>
                            }

                            {data && data?.length > 0 &&
                                <nav class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Table navigation">
                                    <span class="text-sm font-normal text-gray-500 ">
                                        Showing
                                        <span class="font-semibold text-gray-900 "> {((page - 1) * perPage) + 1} - {((page - 1) * perPage) + perPage > count ? count : ((page - 1) * perPage) + perPage}</span>
                                        <span className='m-1'>of</span>
                                        <span class="font-semibold text-gray-900 "> {count} </span>
                                        <span className='m-1'>results</span>
                                    </span>
                                    {count > perPage &&
                                        <ul class="inline-flex items-stretch -space-x-px">
                                            <li>
                                                <button
                                                    disabled={page <= 1}
                                                    onClick={e => setPage(Math.max(1, page - 1))}
                                                    className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">
                                                    <span class="sr-only">Previous</span>
                                                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                    </svg>
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={e => setPage(1)}
                                                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">1</button>
                                            </li>
                                            {
                                                Math.ceil(count / perPage) >= 2 &&
                                                <li>
                                                    <button
                                                        onClick={e => setPage(2)}
                                                        className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">2</button>
                                                </li>
                                            }

                                            {
                                                Math.ceil(count / perPage) >= 3 &&
                                                <li>
                                                    <button
                                                        onClick={e => setPage(3)}
                                                        className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">3</button>
                                                </li>
                                            }
                                            <li>
                                                <a href="#" class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">...</a>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={e => setPage(Math.ceil(count / perPage))}
                                                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">{Math.ceil(count / perPage)}</button>
                                            </li>
                                            <li>
                                                <button
                                                    disabled={page >= Math.ceil(count / perPage)}
                                                    onClick={e => setPage(Math.min(page + 1, Math.ceil(count / perPage)))}
                                                    class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">
                                                    <span class="sr-only">Next</span>
                                                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                                    </svg>
                                                </button>
                                            </li>
                                        </ul>
                                    }
                                </nav>
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
export default PaymentsTab
