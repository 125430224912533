import React from 'react'
import BrandView from '../components/cms/Brand/BrandView'

function BrandViewPage() {
  return (
    <div>
      <BrandView />
    </div>
  )
}

export default BrandViewPage
