import React from 'react'
import UrlsAdd from '../components/urls/UrlsAdd'

function UrlsAddPage() {
  return (
    <div>
      <UrlsAdd/>
    </div>
  )
}

export default UrlsAddPage
