import { axiosCustom } from '../../utils';

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const isValidLink = (link) => {
    try {
        new URL(link);
        return true;
    } catch (error) {
        return false;
    }
};

export const isValidString = (inputString) => {
    return typeof inputString === 'string' && inputString.trim().length > 0;
};

export const isNonNegativeInteger = (value) => {
    return Number.isInteger(parseInt(value)) && parseInt(value) >= 0;
};

export const isValidInteger = (value) => {
    return Number.isInteger(value);
};

export const getParentSections = async (perPage = 100) => {
    try {
        const response = await axiosCustom.get('/oms/cms/homepage-sections/', {
            params: {
                per_page: perPage
            }
        });

        return response.data.results;
    } catch (error) {
        console.log("an error occured while fetching parent sections:  ", error);
    }
}