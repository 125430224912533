import React, { useEffect, useState } from 'react'
import back from '../assets/ic_back.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Avatar from 'react-avatar'
import Moment from 'react-moment';
import { ENDPOINT_LEADS } from '../routes'
import { makeAuthenticatedRequest } from '../utils'
import { toast } from 'react-toastify';


function LeadDetails() {
    const { leadID } = useParams()
    const navigate = useNavigate()
    const [edit, setEdit] = useState(false)
    const [leadNotes, setleadNotes] = useState('')
    const [processing, setProcessing] = useState(false)
    const [addVehicleModal, setAddVehicleModal] = useState(false)

    const [leadData, setLeadData] = useState([])

    const [leadFirstName, setLeadFirstName] = useState('')
    const [leadLastName, setLeadLastName] = useState('')
    const [leadPhoneNumber, setLeadPhoneNumber] = useState('')
    const [leadEmail, setLeadEmail] = useState('')
    const [leadPartServiceNeeded, setLeadPartServiceNeeded] = useState('')
    const [leadVehicleModel, setLeadVehicleModel] = useState('')


    
    const updateLead = () => {
       
        const params = {
          first_name:leadFirstName,
          last_name:leadLastName,
          email:leadEmail,
          phone_number:leadPhoneNumber
        }
        makeAuthenticatedRequest(`/oms/consumers/lead/${leadID}/`, "POST", JSON.stringify(params))
          .then((response) => response.json())
          .then((data) => {
            // console.log(data)
    
            fetchLead(leadID)
          });
      }


    const fetchLead = () => {
        setProcessing(true)
        makeAuthenticatedRequest(`/oms/consumers/lead/${leadID}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setLeadData(data)
                setLeadFirstName(data?.first_name)
                setLeadLastName(data?.last_name)
                setLeadPhoneNumber(data?.phone_number)
                setLeadEmail(data?.email)
                // setLeadPartServiceNeeded(data?.)
                setLeadVehicleModel(data?.vehicle)
                setProcessing(false)
            });
    }
    useEffect(() => {
        fetchLead()
    }, [])


    
    const editLeadDetails = () => {
        setProcessing(true)
        const params = {
            first_name:leadFirstName,
            last_name:leadLastName,
            phone_number:leadPhoneNumber,
            email:leadEmail
        }
        makeAuthenticatedRequest(`/oms/consumers/lead/${leadData.id}/`, "POST", JSON.stringify(params))
        .then((response) => response.json())
            .then((data) => {
            setProcessing(false);
            setLeadData(data)
            toast.info('Lead updated successfully')
        })
        .catch((err) => {
            toast.error('Something went wrong')

            setProcessing(false);

        }).finally(() => {
            setProcessing(false);
        });



    }


    const submitleadNotes = (event) => {
        event.preventDefault();
        setProcessing(true)

        const params = {
            remarks: leadNotes
        }
        makeAuthenticatedRequest(`/oms/consumers/lead/${leadData.id}/new_note/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                setLeadData(data)
                setleadNotes(null)
            })
            .catch((err) => {

                setProcessing(false);

            }).finally(() => {
                setProcessing(false);
            });
    }

    return (
        <>
            {
                processing &&
                <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
                    <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
                    <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
                </div>
            }
            <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
                <div className="flex flex-row justify-between">
                    <div onClick={() => navigate(-1)}
                        className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-4 mt-0.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                        </svg>

                        Back
                    </div>
                    <div className=" flex flex-row gap-2">

                        {!edit ?
                        <button
                            onClick={() => setEdit(true)}
                            className='outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2'>
                            Edit</button>

                            :
                            <div className="flex flex-row gap-2">

                                <button
                                    onClick={editLeadDetails}
                                    className='outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2'>
                                    {processing? 'Saving...':'Save'}
                                </button>
                                <button
                                    onClick={() => setEdit(false)}
                                    className='outline-none rounded-md flex flex-row gap-2 font-satoshi bg-gray-200 px-4 py-2'>
                                    Cancel</button>
                            </div>
                           


                        }
                    </div>

                </div>

                <div className="flex flex-row w-full gap-4">
                    <div className="">
                        {/* <img src={disc} className='rounded-full w-20 h-20 object-cover' alt="" /> */}
                        <Avatar name={`${leadData?.first_name} ${leadData?.last_name}`} circle='true' size={45} className="rounded-full shadow" />
                    </div>
                    <div className="flex-1 flex flex-col gap-2">
                        {edit ?
                            <div className="flex-1 flex flex-row gap-2">
                                <input
                                    readOnly={!edit}
                                    value={leadFirstName}
                                    onChange={(e) => setLeadFirstName(e.target.value)}
                                    placeholder="First name"
                                    type="text" className={`px-4 py-2 rounded-md outline-none font-satoshi w-full ${edit ? 'border' : ''}`} />
                                <input
                                    readOnly={!edit}
                                    value={leadLastName}
                                    placeholder="Last name"

                                    onChange={(e) => setLeadLastName(e.target.value)}
                                    type="text" className={`px-4 py-2 rounded-md outline-none font-satoshi w-full ${edit ? 'border' : ''}`} />


                            </div>
                            :

                            <p className='font-satoshi text-[#030A1A] capitalize px-2 text-[16px] md:text-[16px] mt-2'>{leadData?.first_name} {leadData?.last_name}</p>
                        }


                    </div>

                </div>

                <div className="flex flex-row gap-4 w-full ">
                    <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
                        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Contacts</p>
                        <div className="border-b border-b-gray-200"></div>
                        <div className="flex flex-row w-full gap-4 px-2">
                            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] pt-2 md:text-[16px] '>Phone:</p>

                            <div className="flex-1 flex flex-col md:flex-row gap-6 w-full">
                                <div className="flex flex-row gap-1 w-full">

                                    <input
                                        readOnly={!edit}
                                        value={leadPhoneNumber}
                                        onChange={(e) => setLeadPhoneNumber(e.target.value)}
                                        type="text" className={`px-4 py-2 rounded-md outline-none font-satoshi w-full ${edit ? 'border' : ''}`} />


                                </div>

                                <div className="flex flex-row gap-1 w-full">
                                    <p className='font-satoshi text-[#999DA8] px-2 text-[16px] pt-2 md:text-[16px] '>Email:</p>
                                    <input
                                        readOnly={!edit}
                                        value={leadEmail}
                                        onChange={(e) => setLeadEmail(e.target.value)}
                                        type="text" className={`px-4 py-2 rounded-md outline-none font-satoshi w-full ${edit ? 'border' : ''}`} />
                                    {/* <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] '>{leadData?.email}</p> */}

                                </div>


                            </div>

                        </div>


                    </div>


                </div>
                {false &&
                <>

                <div className="flex flex-row gap-4 w-full ">
                    <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
                        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Vehicle</p>
                        <div className="border-b border-b-gray-200"></div>
                        <div className="flex flex-row p-2">

                        <button
                        onClick={() => setAddVehicleModal(true)}
                            className='bg-gray-200 font-satoshi rounded-md px-2 py-1 text-center text-[16px]'>Add vehicle</button>
                        </div>



                    </div>


                </div>
                <div className="flex flex-row gap-4 w-full ">
                    <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
                        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Address</p>
                        <div className="border-b border-b-gray-200"></div>



                    </div>


                </div>
                </>
                }



                {/* <div className="flex flex-row gap-4 w-full ">
        <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Vehicle details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4 px-2">
            
            <div className="flex-1 flex flex-col md:flex-row gap-6">
                <input
                readOnly={!edit}
                value={leadVehicleModel}
                onChange={(e) => setLeadVehicleModel(e.target.value)}
                 type="text" className={`px-4 py-2 outline-none w-full rounded-md font-satoshi ${edit?'border':''}`} />


                
            </div>

        </div>

        
        </div>


    </div> */}

                {/* <div className="flex flex-row gap-4 w-full ">
        <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Part / Service needed</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-col px-2">

        <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] bg-gray-100 px-2 py-3 rounded-md'>-</p>
        </div>
         


        
        </div>


    </div> */}
                <div className="flex flex-row gap-4 w-full ">
                    <div className="flex flex-col gap-2 w-full">

                        <p className='font-satoshi text-[18px] font-semibold'>Notes</p>

                        <div className="flex flex-col py-4 w-full">
                            <form submit={(e) => { e.preventDefault() }} className='w-full'>
                                <input type="text"
                                    value={leadNotes}
                                    onChange={(e) => setleadNotes(e.target.value)}
                                    placeholder='Write a note'
                                    className='w-full rounded-lg border outline-none font-satoshi px-4 py-2 placeholder:text-[#666C7C]' />
                                {leadNotes &&
                                    <div className="flex flex-row-reverse w-full py-4 gap-2">
                                        <button
                                            onClick={submitleadNotes}
                                            className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>
                                            {processing ?
                                                'Submiting ...' : 'Submit'
                                            }
                                        </button>
                                    </div>
                                }

                            </form>
                        </div>

                        <div className="flex flex-col w-full gap-2">
                            {
                                leadData?.notes?.map((log) => (
                                    <div className="flex flex-col py-2 w-full">
                                        <div className="flex flex-row gap-2 w-full">
                                            <div className=''>
                                                <Avatar name={log.user.first_name ? `${log.user.first_name} ${log.user.last_name}` : log.user.email} size="50" className="h-12 w-12 mr-2 rounded-full shadow" />
                                            </div>
                                            <div className='flex flex-col gap-3 flex-1'>
                                                <div className='flex flex-row gap-2'>
                                                    <p className="font-satoshi text-[#030A1A]  text-[18px]">
                                                        {log.user.first_name ? `${log.user.first_name} ${log.user.last_name}` : log.user.email}
                                                    </p>

                                                    <span>.</span>

                                                    <p className="font-satoshi text-[#666C7C]  text-[18px]">
                                                        <Moment date={log.date_created} format="D MMM" trim />

                                                    </p>
                                                    <p className="font-satoshi text-[#666C7C]  text-[18px]">
                                                        <Moment date={log.date_created} format="hh:mm" trim />
                                                    </p>
                                                    <p className="font-satoshi text-[#666C7C]  text-[14px]">
                                                        {log.system_message}
                                                    </p>
                                                </div>
                                                <div className='flex flex-col px-2 py-2 bg-gray-100 rounded-md'>
                                                    
                                                </div>
                                                {log.message &&
                                                    <div className='flex flex-col px-2 py-2 bg-gray-100 rounded-md'>
                                                        <p className="font-satoshi text-[#030A1A]  text-[14px]">
                                                            {log.message}
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}


                        </div>
                    </div>

                </div>

            </div>

            {addVehicleModal &&
        <>
          <div className='h-screen pt-1 md:pt-0  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
            <div className='relative p-4 w-full md:w-[40%] h-full md:h-auto'>
              <div  className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-start justify-end p-2 rounded-t '>
                </div>

                <div className='relative p-3 flex-auto'>
                  <div className='px-4 pt-4 pb-3 w-full'>

                    <div className="flex flex-col">
                      <div onClick={() => setAddVehicleModal(false)} className="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                      <div className="flex flex-row gap-2 justify-between">
                        <div className="flex flex-row gap-2">
                          <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                            Enter details
                          </h2>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2">
                      





                      </div>
                      <div className="flex flex-row-reverse w-full py-4 gap-2">
                        <button
                          className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>
                          Submit
                        </button>
                        

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-50' />
        </>
      }


        </>
    )
}

export default LeadDetails