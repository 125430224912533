
import React from 'react'


function Rating({ avarageRating, numberOfRatings }) {
    const avarageRatingInteger = Math.floor(avarageRating || 0);

    return (
        <div>
        
            <div className='font-semibold text-sm flex flex-row justify-start items-center gap-2'>
                {[...Array(avarageRatingInteger)].map((x, i) =>
                     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                     <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                     </svg>
                )}
            <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] '>{numberOfRatings || 0}</p>

            </div>
        </div>
    )
}

export default Rating