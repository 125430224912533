import React, { useState } from 'react';
import { FaHome, FaShoppingBag, FaListAlt, FaTags, FaStoreAlt, FaRegListAlt, FaHandshake, FaTools, FaAd } from 'react-icons/fa';
import HeroPage from './HeroPage';
import ProductPage from './ProductPage';
import AccessoryPage from './AccessoryPage';
import BrandPage from './BrandPage';
import CategoriesPage from './CategoriesPage';
import ShopPage from './ShopPage';
import MechanicCMSPage from './MechanicCMSPage';
import AdvertPage from './AdvertPage';
import PartnersPage from './PartnersPage';

function CMSPage() {
  const [activeTab, setActiveTab] = useState(0); 

  const tabData = [
    { name: 'Hero', icon: <FaHome />, component: <HeroPage /> },
    { name: 'Product', icon: <FaShoppingBag />, component: <ProductPage /> },
    { name: 'Accessory', icon: <FaListAlt />, component: <AccessoryPage /> },
    { name: 'Brand', icon: <FaTags />, component: <BrandPage /> },
    { name: 'Category', icon: <FaRegListAlt />, component: <CategoriesPage /> },
    { name: 'Shop', icon: <FaStoreAlt />, component: <ShopPage /> },
    { name: 'Mechanic', icon: <FaTools />, component: <MechanicCMSPage /> },
    { name: 'Advert', icon: <FaAd />, component: <AdvertPage /> },
    { name: 'Partners', icon: <FaHandshake />, component: <PartnersPage /> }
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const renderTabs = () => {
    return tabData.map((tab, index) => (
      <div
        key={index}
        onClick={() => handleTabClick(index)}
        className={`cursor-pointer inline-flex items-center gap-2 py-2 gap- text-gray-600 ${
          activeTab === index
            ? 'text-blue-500 border-b-2 border-blue-500'
            : 'border-b border-transparent'
        }`}
      >
        {tab.icon}
        <span>{tab.name}</span>
      </div>
    ));
  };

  return (
    <div className='px-4 md:px-20 flex flex-col w-full h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10 font-satoshi justify-center'>
      <p className='font-semibold text-[24px] text-gray-900'>CMS Portal</p>

      {/* Render tabs */}
      <div className='mt-2 space-x-4'>
        {renderTabs()}
      </div>

      {/* Content for each tab */}
      <div className='mt-2'>
        {/* Render content based on the active tab */}
        {tabData[activeTab].component}
      </div>
    </div>
  );
}

export default CMSPage;
