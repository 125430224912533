import React from 'react'
import Category from "../components/cms/Category/Category"

function CategoriesPage() {
  return (
    <div>
      <Category/>
    </div>
  )
}

export default CategoriesPage
