import html2pdf from 'html2pdf.js';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { calculateVATandSubtotal, formatNumber } from '../utils'
import { toast } from 'react-toastify'

function Invoice({order}) {
    const [viewPdf, setViewPdf] = useState(false)
    const [name, setName] = useState(`${order?.passport.first_name} ${order?.passport.last_name}`)
    const [phone, setPhone] = useState(order?.passport.phone_number)

    const downloadPDF = () => {
        const element = document.getElementById('pdf-content');
        const opt = {
          margin:       0.5,
          filename:     `${name}.pdf`,
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { dpi: 192, letterRendering: true },
          jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
        };
        html2pdf().set(opt).from(element).save();
      };

    const viewDownload = () => {
        setViewPdf(true)
        downloadPDF()

    }  

      
    const [addNewNaration, setAddNewNaration] = useState(false)
    const [addProduct, setAddProduct] = useState(false)

    const [lines, setLines] = useState([])

    const [narrationTitle, setNarrationTitle] = useState('')


    const [invoiceType, setInvoiceType] = useState(1)

    const [narrationPrice, setNarrationPrice] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [deliveryTotal, setDeliveryTotal] = useState(0)




    const [narrationTitles, setNarrationTitles] = useState([])
    const [newNarrationTitles, setNewNarrationTitles] = useState([])

    const [quoteTitles, setQuoteTitles] = useState([])



    const [products, setProducts] = useState([])
    const [productName, setProductName] = useState('')
    const [productQty, setProductQty] = useState()
    const [productPrice, setProductPrice] = useState()


    const handleAddProduct = () => {
        const newItem = {
          name: productName,
          id:Math.floor(Math.random() * 10000),
          quantity: productQty,
          price: productPrice,
          total:productQty * productPrice 
        };
        setLines([...lines, newItem])
        setProducts([...products, newItem]);
        setProductName('')
        setProductQty('')
        setProductPrice('')
      };

    const handleDeleteNarration = (narration) => {
        const narrationPrice = narration.price
        const gtotal = grandTotal
        const updatedGtotal = gtotal - parseInt(narrationPrice)
        setGrandTotal(updatedGtotal)
        setNarrationTitles(narrationTitles.filter((narrationT) => narrationT !== narration));
        setNewNarrationTitles(newNarrationTitles.filter((narrationT) => narrationT !== narration))
    }  
    const handleDeleteLine = (newLine) => {
        setLines(lines.filter((line) => line !== newLine));
    }

    const handleAddNaration = () => {
        const newItem = {
          title: narrationTitle,
          price: parseInt(narrationPrice)
        };
        const gtotal = grandTotal
        const updatedGtotal = gtotal + parseInt(newItem.price)
        setGrandTotal(updatedGtotal)
        setNarrationTitles([...narrationTitles, newItem]);
        setNewNarrationTitles([...newNarrationTitles,newItem])
        setNarrationTitle('');
        setNarrationPrice('');
      };

      const handleEditLine = (index, quantity, price) => {
        const newProducts = [...lines];
        const lineQty =  newProducts[index].quantity
        const linePrice = newProducts[index].price
        const lineTotal = newProducts[index].total
        if(quantity && price){

            newProducts[index].quantity = quantity;
            newProducts[index].price = price; 
            newProducts[index].total = price * quantity; 
        }else{
        newProducts[index].quantity = lineQty;
        newProducts[index].price = linePrice; 
        newProducts[index].total = lineTotal; 

        }

        setLines(newProducts); 
      };


      const handleEditNarration = (index, price) => {
        const newNarrations = [...narrationTitles];

        const gtotal = grandTotal
        const narrationPrice = newNarrations[index].price
        const subGrand = gtotal - narrationPrice


        if(price){

            newNarrations[index].price = price; 
        }else{
            newNarrations[index].price = narrationPrice; 

        }
        const updatedGtotal = subGrand + price
        
        if(newNarrations[index].title.split(" ")[0] === 'Delivery'){
            setDeliveryTotal(price)
        }
        // name(price)
        setGrandTotal(updatedGtotal)
        

        setNarrationTitles(newNarrations); 
      };
    
useEffect(()=>{

    const linesTotal = lines.reduce((acc, curr) => acc + curr.total, 0)
    const delivery = parseInt(order?.delivery_cost)
    const gTotal = linesTotal + delivery

    setGrandTotal(gTotal)
    setSubTotal(linesTotal)
    setDeliveryTotal(delivery)

    setNarrationTitles([
        {
            title:'Grand total ',
            price:grandTotal,
        },
        {
            title:'Delivery cost',
            price:deliveryTotal,
        },
        
        {
            title:'Vat(16%)',
            price:calculateVATandSubtotal(linesTotal).vatAmount,
    
        },
        {
            title:'Sub total',
            price:calculateVATandSubtotal(linesTotal).subtotal,
    
        },
       

])
setQuoteTitles([
  {
      title:'Grand total ',
      price:grandTotal,
  },
  {
    title:'Delivery cost',
    price:deliveryTotal,

  },
  {
    title:'Vat(16%)',
    price:calculateVATandSubtotal(grandTotal).vatAmount,

  },
  {
      title:'Sub total',
      price:calculateVATandSubtotal(grandTotal).subtotal,

  },
 

])
console.log('vat',calculateVATandSubtotal(6334))
},[lines])

    useEffect(()=>{

        setLines(order?.lines?.map((line) => ({
            name:line?.carpart?line?.carpart?.name:line?.title,
            id:line?.id,
            quantity:line?.quantity,
            price:parseInt(line?.price),
            total:Number(line?.price)*line?.quantity

    
        })))



    },[])


    useEffect(() => {

          setNarrationTitles([
              {
                  title:'Grand total ',
                  price:grandTotal,
              },
              {
                  title:'Delivery cost',
                  price:deliveryTotal,
              },
              {
                title:'Vat(16%)',
                price:calculateVATandSubtotal(grandTotal-deliveryTotal).vatAmount,
        
              },
              {
                  title:'Sub total',
                  price:calculateVATandSubtotal(grandTotal-deliveryTotal).subtotal,
          
              },
             
  
      ])
      setQuoteTitles([
        {
            title:'Grand total ',
            price:grandTotal,
        },
        {
          title:'Delivery cost',
          price:deliveryTotal,
  
        },
        {
          title:'Vat(16%)',
          price:calculateVATandSubtotal(grandTotal).vatAmount,
  
        },
        {
            title:'Sub total',
            price:calculateVATandSubtotal(grandTotal).subtotal,
    
        },
       

])
  },[grandTotal, subTotal, deliveryTotal, lines])

  console.log('lines', lines)
  const handleSwitchInvoiceState = () => {
    if(invoiceType === 1){
      setInvoiceType(2)
      setDeliveryTotal(0)
      setGrandTotal(0)
      setLines([])
      setQuoteTitles([
        {
            title:'Grand total ',
            price:grandTotal,
        },
        {
          title:'Delivery cost',
          price:deliveryTotal,
  
        },
        {
          title:'Vat(16%)',
          price:calculateVATandSubtotal(grandTotal).vatAmount,
  
        },
        {
            title:'Sub total',
            price:calculateVATandSubtotal(grandTotal).subtotal,
    
        },
       

])
    }else{
      toast.info('If you want to go back to receipt close this modal and reopen again');
      setInvoiceType(1)
    }
  }



  return (
    <>
    <div  className='w-full flex flex-col gap-2 py-4'>
    {!viewPdf &&
    <>
        <div className="flex flex-row justify-between border-b border-black">
            <div className="flex justify-center items-center">
                <img src="/cdn/images/logo.svg"  alt="" />
            </div>
            <div 
            className="flex flex-col gap-2 justify-center items-center cursor-pointer">
              <button
              onClick={handleSwitchInvoiceState}
              className='bg-gray-200 rounded-md text-center px-4 py-2 font-satoshi'
              >Make {invoiceType === 1 ? 'quote':'receipt'}</button>
                <p className='font-satoshi text-[30px] text-gray-400 uppercase'>{invoiceType === 1 ? 'RECEIPT':'QUOTATION'}</p>
            </div>
        </div>
        <div className="flex flex-row justify-between">
            <div className="grid grid-rows-2">
              {invoiceType === 1 ? 
              <>
                <p className='font-satoshi text-[20px]'>{order?.passport.first_name} {order?.passport.last_name}</p>
                <p className='font-satoshi text-[20px]'>+{order?.passport.phone_number}</p>
              </>
              :
              <div className='w-full gap-2 flex flex-col'>
            <input onChange={(e) => setName(e.target.value)} value={name} type="text" name="" id="" placeholder='Enter name to appear on quotation' className='outline-none w-full font-satoshi text-[14px] px-2 py-1 rounded-md border border-slate-300' />
            <input onChange={(e) => setPhone(e.target.value)} value={phone} type="text" name="" id="" placeholder='254712345678' className='outline-none w-full font-satoshi text-[14px] px-2 py-1 rounded-md border border-slate-300' />

              </div>

              }
            </div>
            <div className="grid grid-rows-2">
                <p className='font-satoshi text-[20px]'>Sparepap Ltd</p>
                <p className='font-satoshi text-[20px]'>West One, Parklands rd, Nairobi</p>
                <p className='font-satoshi text-[20px]'>+254 706 542 222</p>

            </div>
        </div>
        <div className="flex flex-row gap-2">
        <p className='font-satoshi text-[22px] text-gray-400 uppercase'>{invoiceType === 1 ? 'ORDER':'QUOTE'} #</p>
        <p className='font-satoshi text-[22px] uppercase'>{order?.order_id}</p>


        </div>

        <table class="table-auto w-full  ">
          <thead className='p-4 bg-gray-400'>
            <tr className=''>
             
              <th className='p-4 font-satoshi text-xs md:text-sm text-left '>#</th>
              <th className='p-4 font-satoshi text-xs md:text-sm text-left '>Part</th>
              <th className='p-4  font-satoshi text-xs md:text-sm text-left '>Qty</th>
              <th className='p-4 font-satoshi text-xs md:text-sm text-left '>Price</th>
              <th className='p-4  font-satoshi text-xs md:text-sm text-left '>Total</th>
              <th className='p-4  font-satoshi text-xs md:text-sm text-left '>Action</th>
            </tr>
          </thead>
          {/* ACTIONS -edit delete */}
          <tbody>
           
            {lines?.map((line, index) => (

              <tr className='p-4 border-b border-b-gray-200 items-center'>
             
             
              <td
                className='py-3 text-xs md:text-sm cursor-pointer items-center'>

                  <p className='font-satoshi text-left text-[#030A1A]'>{index + 1}</p>

              </td>
            
             
              <td
                className='py-3 text-xs md:text-sm cursor-pointer items-center'>
                  
                    <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{line.name}</p>
                  


              </td>
              <td
                className='py-3 text-xs md:text-sm cursor-pointer items-center'>
               
                <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{line.quantity}</p>
                
              </td>
              <td
                className='py-3 text-xs md:text-sm cursor-pointer items-center'>

                <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{line.price}</p>
                 


              </td>
              <td
                className='py-3  text-xs md:text-sm cursor-pointer items-center'>
               
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{line.total}</p>
                 

              </td>
              <td
                className='py-3  text-xs md:text-sm cursor-pointer items-center flex flex-row gap-3'>

                <button
                className='font-satoshi text-blue-500'
                        onClick={() => handleEditLine(
                            index, parseInt(prompt("Enter new quantity:", line.quantity)),parseInt(prompt("Enter new price:", line.price)))}>
                        Edit
                        </button>

                        <svg 
                    onClick={()=> handleDeleteLine(line)}
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 text-red-500 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>


                </td>


            </tr>
            ))}



          </tbody>
        </table>
        {addProduct &&
        <div className="flex flex-row gap-2 w-full">

        <input onChange={(e) => setProductName(e.target.value)} value={productName} type="text" name="" id="" placeholder='Enter part name' className='outline-none w-[70%] font-satoshi text-[14px] px-2 py-1 rounded-md border border-slate-300' />
        <input onChange={(e) => setProductQty(e.target.value)} value={productQty} type="number" min={0} name="" id="" placeholder='Quantity' className=' w-[15%] outline-none font-satoshi text-[14px] px-2 py-1 rounded-md border border-slate-300' />
        <input onChange={(e) => setProductPrice(e.target.value)} value={productPrice} type="number" min={0} name="" id="" placeholder='Item price' className=' w-[15%] outline-none font-satoshi text-[14px] px-2 py-1 rounded-md border border-slate-300' />
        
        </div>
}
        <div className="flex flex-row-reverse">

{addProduct ?
            <button
            onClick={handleAddProduct}
             className='font-satoshi text-[14px] bg-slate-800 text-white rounded-md px-2 py-2'>Add</button>
             :
             <button
             onClick={() => setAddProduct(true)}
              className='font-satoshi text-[14px] bg-slate-800 text-white rounded-md px-2 py-2'>Add part</button>
}

            {/* <button className='font-satoshi text-[14px] bg-slate-800 text-white rounded-md px-2 py-2'>Edit</button> */}

            </div>

        <div className="flex flex-row-reverse">
            <div className={`grid grid-row-3 gap-2`}>
                  {newNarrationTitles.length>0 &&
                  newNarrationTitles.map((narration, index) => (
                    <div className="flex flex-row justify-between">

                    <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-1">
                        <p className='font-satoshi text-[18px] uppercase'>{narration.title} :</p>
                        
                    </div>
                    <div className="col-span-1">
                        <p className='font-satoshi text-[18px] uppercase'>{formatNumber(narration.price,0,0)}</p>
                        
                    </div>
                  </div>
                  <div className="flex flex-row gap-2">

                  <svg 
                  onClick={()=> handleDeleteNarration(narration)}
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 text-red-500 cursor-pointer">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>

                  </div>
                  ))
                  }
                  {narrationTitles.length> 0 &&
                narrationTitles.map((narration, index) => (
                    <div className="flex flex-row justify-between">

                        <div className="grid grid-cols-2 gap-2">
                        <div className="col-span-1">
                            <p className='font-satoshi text-[18px] uppercase'>{narration.title} :</p>
                            
                        </div>
                        <div className="col-span-1">
                            <p className='font-satoshi text-[18px] uppercase'>{formatNumber(narration.price,0,0)}</p>
                            
                        </div>
                    </div>
                    <div className="flex flex-row gap-2">
                    {narration.title.split(" ")[0] === 'Delivery' &&
                    <button
                    className='font-satoshi text-blue-400'
                        onClick={() => handleEditNarration(
                            index,parseInt(prompt("Enter new price:", narration.price)))}>
                        Edit
                        </button>
}
{narration.title.split(" ")[0] !== 'Delivery' && narration.title.split(" ")[0] !== 'Sub' && narration.title.split(" ")[0] !== 'Grand' &&
                    <svg 
                    onClick={()=> handleDeleteNarration(narration)}
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 text-red-500 cursor-pointer">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
}
                    </div>

                    </div>
                )).reverse()
                }

            </div>
        </div>
        {addNewNaration &&
        <div className="flex flex-row-reverse gap-2 w-full">
            
<input onChange={(e) => setNarrationPrice(e.target.value)} type="number" min={0} value={narrationPrice} name="price"  placeholder='Amount' className='  outline-none font-satoshi text-[14px] px-2 py-1 rounded-md border border-slate-300' />
<input onChange={(e) => setNarrationTitle(e.target.value)}
 type="text" name="title"  value={narrationTitle} placeholder='Title' className=' outline-none font-satoshi text-[14px] px-2 py-1 rounded-md border border-slate-300' />

</div>
}
        <div className="flex flex-row-reverse">
        {!addNewNaration ?

         <button
         onClick={() => setAddNewNaration(true)}
          className='font-satoshi text-[14px] bg-slate-800 text-white rounded-md px-2 py-2'>Add new item</button>
          :
                  
         <button
         onClick={handleAddNaration}
          className='font-satoshi text-[14px] bg-slate-800 text-white rounded-md px-2 py-2'>Add</button>
        }
        </div>

        <div className="flex flex-col justify-center items-center mt-auto">
            <p className='font-satoshi text-[16px] '>SparePap Ltd</p>
            <p className='font-satoshi text-[16px] '>@ West One, Parklands Rd, Nairobi</p>
            <p className='font-satoshi text-[14px] '>This receipt was autogenerated. Incase of any enquiries, email us at hello@sparepap.com or call +254 706 542 222</p>


            
        </div>
        </>
}
        <div className="flex justify-start items-start flex-row gap-4">


        <button className='flex flex-row gap-1 rounded-md items-center px-4 py-2 bg-gray-200 font-satoshi text-[14px]' onClick={viewDownload}>{viewPdf? 'Download':'Preview'}
        {viewPdf &&
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>
}
 </button>

{viewPdf &&
 <button className='flex flex-row gap-1 rounded-md items-center px-4 py-2 bg-gray-200 font-satoshi text-[14px]' onClick={() => setViewPdf(false)}>Close preview</button>
}
</div>
     
    </div>
{viewPdf &&
    <div id='pdf-content' className='w-full flex flex-col gap-2 py-4 h-full justify-between'>
      <div className="flex flex-col w-full gap-2 h-full">

     
        <div className="flex flex-row justify-between border-b border-black h-full">
            <div className="flex justify-center items-center">
                <img src="/cdn/images/logo.svg"  alt="" />
            </div>
            <div className="flex justify-center items-center pb-1">
                <p className='font-satoshi text-[30px] text-gray-400 uppercase'>{invoiceType === 1 ? 'RECEIPT':'QUOTATION'}</p>
            </div>
        </div>
        <div className="flex flex-row justify-between h-full">
            <div className="grid grid-rows-2">
            <p className='font-satoshi text-[20px]'>{name}</p>
            <p className='font-satoshi text-[20px]'>+{phone}</p>
            </div>
            <div className="grid grid-rows-2">
                <p className='font-satoshi text-[20px]'>Sparepap Ltd</p>
                <p className='font-satoshi text-[20px]'>West One, Parklands rd, Nairobi</p>
                <p className='font-satoshi text-[20px]'>+254 706 542 222</p>

            </div>
        </div>
        <div className="flex flex-row gap-2 h-full">
        <p className='font-satoshi text-[22px] text-gray-400 uppercase'>{invoiceType === 1 ? 'ORDER':'QUOTE'} #</p>
        <p className='font-satoshi text-[22px] uppercase'>{order?.order_id}</p>


        </div>

        <table class="table-auto w-full h-full ">
          <thead className='p-4 bg-gray-400'>
            <tr className=''>
             
              <th className='p-4 font-satoshi text-xs md:text-sm text-left '>#</th>
              <th className='p-4 font-satoshi text-xs md:text-sm text-left '>Part</th>
              <th className='p-4  font-satoshi text-xs md:text-sm text-left '>Qty</th>
              <th className='p-4 font-satoshi text-xs md:text-sm text-left '>Price</th>
              <th className='p-4  font-satoshi text-xs md:text-sm text-left '>Total</th>
            </tr>
          </thead>
          {/* ACTIONS -edit delete */}
          <tbody>
           
            {lines?.map((line, index) => (

              <tr className='p-4 border-b border-b-gray-200 items-center'>
             
             
              <td
                className='py-3 text-xs md:text-sm cursor-pointer items-center'>

                  <p className='font-satoshi text-left text-[#030A1A]'>{index + 1}</p>

              </td>
            
             
              <td
                className='py-3 text-xs md:text-sm cursor-pointer items-center'>
                  
                    <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{line.name}</p>
                  


              </td>
              <td
                className='py-3 text-xs md:text-sm cursor-pointer items-center'>
               
                <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{line.quantity}</p>
                
              </td>
              <td
                className='py-3 text-xs md:text-sm cursor-pointer items-center'>

                <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>KSh. {formatNumber(line.price,0,0)}</p>
                 


              </td>
              <td
                className='py-3  text-xs md:text-sm cursor-pointer items-center'>
               
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>KSh. {formatNumber(line.total,0,0)}</p>
                 

              </td>



            </tr>
            ))}



          </tbody>
        </table>


        <div className="flex flex-row-reverse w-full h-full">
            <div className={`grid grid-row-3 gap-2`}>
            {newNarrationTitles.length>0 &&
                  newNarrationTitles.map((narration) => (

                    <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-1">
                        <p className='font-satoshi text-[14px] uppercase'>{narration.title}</p>
                        
                    </div>
                    <div className="col-span-1 flex justify-start items-start">
                        <p className='font-satoshi text-[14px] uppercase'>: KSh. {formatNumber(narration.price,0,0)}</p>
                        
                    </div>
                  </div>
                  ))
                  }

                  {invoiceType === 1 ?
                  <>
                {narrationTitles.length> 0 &&
                narrationTitles.map((narration) => (

                        <div className="grid grid-cols-2 gap-2">
                        <div className="col-span-1">
                            <p className='font-satoshi text-[14px] uppercase'>{narration.title}</p>
                            
                        </div>
                        <div className="col-span-1 flex justify-start items-start">
                            <p className='font-satoshi text-[14px] uppercase'>: KSh. {formatNumber(narration.price,0,0)}</p>
                            
                        </div>
                    </div>
                )).reverse()}
                  </>
  
                :
                <>
                {quoteTitles.map((narration) => (

                  <div className="grid grid-cols-2 gap-2">
                  <div className="col-span-1">
                      <p className='font-satoshi text-[14px] uppercase'>{narration.title}</p>
                      
                  </div>
                  <div className="col-span-1 flex justify-start items-start">
                      <p className='font-satoshi text-[14px] uppercase'>: KSh. {formatNumber(narration.price,0,0)}</p>
                      
                  </div>
              </div>
          )).reverse()}
                </>
                }

            </div>
        </div>
        </div>

        <div className="flex flex-col justify-center items-center mt-auto h-full">
            
            <p className='font-satoshi text-[16px] '>SparePap Ltd</p>
            <p className='font-satoshi text-[16px] '>@ West One, Parklands Rd, Nairobi</p>
            <p className='font-satoshi text-[14px] '>This receipt was autogenerated. Incase of any enquiries, email us at hello@sparepap.com or call +254 706 542 222</p>


            
        </div>
     
    </div>
}

{viewPdf &&
<button className='flex flex-row gap-1 rounded-md items-center px-4 py-2 bg-gray-200 font-satoshi text-[14px]' onClick={viewDownload}>{viewPdf &&'Download'}
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
</svg>
</button>
}
    </>
  )
}

export default Invoice