import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../../utils';
import AsyncSelect from 'react-select/async';
import { ENGINE_TYPES, VEHICLE_TYPES, VEHICLE_YEARS } from '../../../constants';
import Select from 'react-select';



const CUSTOMERS_URL = `/oms/customers/`;
const VEHICLES_URL = `/oms/vehicles/`;

function AddCarModal({ customerID, handleModalClose, customer, handleReload }) {
    const [yom, setYom] = useState()
    const [data, setData] = useState()
    const [make, setMake] = useState()
    const [model, setModel] = useState()
    const [submodel, setSubModel] = useState()
    const [nickname, setNickname] = useState()
    const [numberPlate, setNumberPlate] = useState()
    const [engineType, setEngineType] = useState()
    const [modelNumber, setModelNumber] = useState()
    const [chasisNumber, setChasisNumber] = useState()
    const [vehicleType, setVehicleType] = useState()
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    const [perPage, setPerPage] = useState(20)
    const [processing, setProcessing] = useState(false)
    const [loadingMakes, setLoadingMakes] = useState(false)
    const [loadingModels, setLoadingModels] = useState(false)
    const [loadingSubModels, setLoadingSubModels] = useState(false)

    const getCarMakes = async (inputValue) => {
        const data = []
        const params = { s: inputValue }
        const searchParams = new URLSearchParams(params).toString();
        setLoadingMakes(true)
        try {
            const response = await axiosCustom.get(`${VEHICLES_URL}makes/?${searchParams}`);
            response?.data?.results?.map( (item, i) => {
                data.push({ value : item?.id, label : item?.name})
            })
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setLoadingMakes(false)
        }
        return data
    }

    const getCarModels = async (inputValue) => {
        const data = []
        if( !make || !make?.value) return data
        const makeID = make?.value;
        const params = { s: inputValue }
        const searchParams = new URLSearchParams(params).toString();
        setLoadingModels(true)
        try {
            const response = await axiosCustom.get(`${VEHICLES_URL}${makeID}/models/?${searchParams}`);
            response?.data?.results?.map( (item, i) => {
                data.push({ value : item?.id, label : item?.name})
            })
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setLoadingModels(false)
        }
        return data
    }

    const getCarSubModels = async (inputValue) => {
        const data = []
        if( !model || !model?.value) return data
        const modelID = model?.value;
        const params = { s: inputValue }
        const searchParams = new URLSearchParams(params).toString();
        setLoadingSubModels(true)
        try {
            const response = await axiosCustom.get(`${VEHICLES_URL}${modelID}/submodels/?${searchParams}`);
            response?.data?.results?.map( (item, i) => {
                data.push({ value : item?.id, label : item?.name})
            })
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setLoadingSubModels(false)
        }
        return data
    }

    const vehicleTypeChanged = (e) => {
        setVehicleType(e)
        
    }

    const submitData = async () => {
        setProcessing(true)
        try {
            const params = {
                nickname: nickname,
                chasis: chasisNumber,
                number_plate: numberPlate,

                year: yom?.label,
                make: make?.label,
                model: model?.label,
                engine: engineType?.label,
                variant: modelNumber?.label,
                vehicle_type: vehicleType?.label,
            }
            const response = await axiosCustom.post(`${CUSTOMERS_URL}${customerID}/cars/`, JSON.stringify(params));
            setPage(response?.data?.page)
            setData(response?.data?.results)
            setCount(response?.data?.count)
            setPerPage(response?.data?.per_page)
            handleReload();
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
    }, [])

    return (
        <>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div className='min-h-h-screen flex justify-center items-center overflow-x-hidden overflow-y-auto py-10 fixed inset-0 z-[100] outline-none focus:outline-none'>
                        <div class="relative p-4 w-full max-w-2xl max-h-full">
                            <div class="relative bg-white rounded-lg shadow ">
                                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                                    <h3 class="text-xl font-semibold text-gray-900 ">
                                        Add Customer Vehicle
                                    </h3>
                                    <button
                                        onClick={handleModalClose}
                                        type="button" class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white">
                                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div class="p-4 md:p-5">
                                    <form className="flex flex-col w-full gap-4 text-[14px]" onSubmit={submitData}>
                                        <div className="flex flex-col gap-4 w-full">
                                            <div className='flex gap-4 md:flex-row flex-col'>
                                                <div className='w-full flex flex-[2] flex-col'>
                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label
                                                                htmlFor="chasis" className="block text-[14px] text-black font-semibold">
                                                                1. VIN/Chassis number <span className='text-red-600' >*</span>
                                                            </label>
                                                            <input type="text" name="chasis" id="chasis"
                                                                value={chasisNumber}
                                                                minLength={4}
                                                                maxLength={20}
                                                                required
                                                                onInvalid={(e) => e.target.setCustomValidity("Please add VIN number")}
                                                                onInput={e => e.target.setCustomValidity('')}
                                                                onChange={(e) => setChasisNumber(e.target.value.toUpperCase().replace(/\s/g, ""))}
                                                                placeholder='e.g. wvwzzz232x'
                                                                className="mt-1 block w-full p-2 outline-none rounded-md border placeholder:text-[12px]" />
                                                        </div>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label
                                                                htmlFor="chasis" className="block text-[14px] text-black font-semibold">
                                                                2. Number Plate <span className='text-red-600' >*</span>
                                                            </label>
                                                            <input type="text" name="chasis" id="chasis"
                                                                value={numberPlate}
                                                                required
                                                                minLength={4}
                                                                maxLength={7}
                                                                onInvalid={(e) => e.target.setCustomValidity("Please add a number plate")}
                                                                onInput={e => e.target.setCustomValidity('')}
                                                                onChange={(e) => setNumberPlate(e.target.value.toUpperCase().replace(/\s/g, ""))}
                                                                placeholder='e.g. KXY124E'
                                                                className="mt-1 block w-full p-2 outline-none rounded-md border" />
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="type" className="text-[14px] text-black font-semibold">
                                                                3. Vehicle type <span className='text-red-600' >*</span>
                                                            </label>
                                                            <Select
                                                                required
                                                                id='type'
                                                                name="type"
                                                                options={VEHICLE_TYPES}
                                                                className='w-full outline-none'
                                                                placeholder="e.g Cars & SUVs"
                                                                onChange={(e) => setVehicleType(e)}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="make" className="text-[14px] text-black font-semibold">
                                                                4. Vehicle Make <span className='text-red-600' >*</span>
                                                            </label>
                                                            <AsyncSelect
                                                                required
                                                                isLoading={loadingMakes}
                                                                className='w-full outline-none'
                                                                loadOptions={getCarMakes}
                                                                placeholder="Search"
                                                                name="vehice_make"
                                                                onChange={e => setMake(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="make" className="text-[14px] text-black font-semibold">
                                                                5. Model Name <span className='text-red-600' >*</span>
                                                            </label>
                                                            <AsyncSelect
                                                                required
                                                                isLoading={loadingModels}
                                                                className='w-full outline-none'
                                                                loadOptions={getCarModels}
                                                                placeholder="Search"
                                                                name="vehice_model"
                                                                onChange={e => setModel(e)}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="make" className="text-[14px] text-black font-semibold">
                                                                6. Model Number <span className='text-red-600' >*</span>
                                                            </label>

                                                            <AsyncSelect
                                                                required
                                                                isLoading={loadingSubModels}
                                                                className='w-full outline-none'
                                                                loadOptions={getCarSubModels}
                                                                placeholder="Search"
                                                                name="vehice_submodel"
                                                                onChange={e => setSubModel(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="make" className="text-[14px] text-black font-semibold">
                                                                7. Engine Type <span className='text-red-600' >*</span>
                                                            </label>
                                                            <Select
                                                                required
                                                                options={ENGINE_TYPES}
                                                                id='engineType'
                                                                className='w-full outline-none'
                                                                placeholder="e.g Petrol"
                                                                onChange={e => setEngineType(e)}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="type" className="text-[14px] text-black font-semibold">
                                                                8. Year of Manufacture (YOM) <span className='text-red-600' >*</span>
                                                            </label>
                                                            <AsyncSelect
                                                                loadOptions={VEHICLE_YEARS}
                                                                onChange={(e) => {setYom(e)}}
                                                                required
                                                                id='engineType'
                                                                placeholder="e.g 2010"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col py-2">
                                                        <label htmlFor="nickname" className="text-[14px] text-black font-semibold">9. Nickname <span className='font-medium'>(optional)</span></label>
                                                        <input type="text" name="nickname" id="nickname"
                                                            value={nickname}
                                                            onChange={(e) => setNickname(e.target.value)}
                                                            placeholder='eg Venom'
                                                            className="mt-1 block w-full p-2 outline-none rounded-md border" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex py-3 justify-end">
                                                <div className="flex flex-row-reverse gap-4">
                                                    <button type='submit' className='bg-[#030A1A] text-[16px] text-white border border-[#030A1A] px-3 py-1 rounded-md'>Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-70  fixed inset-0 z-50' />
                </section>
            }
        </>
    )
}
export default AddCarModal