import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { formatNumber, makeAuthenticatedRequest } from '../utils';
import { Pagination } from '../components/Pagination';
import Moment from 'react-moment';
import Reorder from '../components/Reorder';
import AsyncSelect from 'react-select/async';
import Avatar from 'react-avatar';
import { ENDPOINT_STAFF } from '../routes';



function StaffPage() {
  const navigate = useNavigate()
  const [processing, setProcessing] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchParams, setSearchParams] = useSearchParams();
  let s = searchParams.get("s") !== null ? searchParams.get("s") : '';

  const [perPage, setPerPage] = useState(21)
  const [search, setSearch] = useState(s)

  const [itemCount, setItemCount] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()

  const [data, setData] = useState();

  const reorderData = (orderBy, order) => {
    setOrder(order)
    setOrderBy(orderBy)
  }

  useEffect(() => {
    fetchData(1)
  }, [order, orderBy]);


  const pageChanged = (pageNumber) => {
    setCurrentPage(pageNumber)
    fetchData(pageNumber)
  }

  const submitSearch = () => {
    fetchData(1)
  }


  const fetchData = (page) => {
    setProcessing(true)
    const params = {
      "page": page,
      "per_page": perPage
    }
    if (search) {
      params.s = search
    }
    if (order) {
      params.order = order
    }
    if (orderBy) {
      params.order_by = orderBy
    }

    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/staff/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setData(data)
        setCurrentPage(data.page)
        setPerPage(data.per_page)
        setItemCount(data.count)
        setTotalItems(data.total_items)
        setProcessing(false)
      });
  }

  return (

    <>
      {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }
      <div className='w-full min-h-screen md:max-h-screen overflow-y-scroll hide-scrollbar'>
        <div className='h-24 '></div>
        <div className='flex flex-col gap-4'>
          <div>
            <p className='font-satoshi text-xl font-semibold'>Staff</p>
          </div>
          <div>
            <form className="flex items-start md:items-center font-satoshi" method="get">
              <label htmlFor="simple-search" className="sr-only">Search</label>
              <div className="relative w-full">
                <div
                  onClick={submitSearch}
                  className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md">
                  <svg aria-hidden="true" className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd">
                    </path>
                  </svg>
                </div>
                <input
                  onChange={e => setSearch(e.target.value)}
                  required
                  value={search}
                  type="text" name="s"
                  id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    " placeholder="Search by name, email" />
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col ">
            {
              data && !processing ? (
                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                  <table class="table-auto w-full">
                    <thead className='pl-1 md:pl-2 table-auto rounded-lg'>
                      <tr className='pl-1 md:pl-2 rounded-lg'>
                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                          <span>
                            <span className='inline pr-2'>Name</span>
                            <Reorder order={order} orderBy={orderBy} tag='email' reorderInventory={reorderData} />
                          </span>
                        </th>
                        <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>
                          <span>
                            <span className='inline pr-2'>Registration Date</span>
                            <Reorder order={order} orderBy={orderBy} tag='last_login' reorderInventory={reorderData} />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.results.map((item) => (
                        <tr key={item.pk} className='p-2 border border-b-gray-200 relative'>
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]'>
                            <div className='flex flex-row'>
                              <Avatar name={item.username} size="40" className="mr-2 rounded-full shadow" />
                              <div className='flex flex-col align-middle h-full'>
                                <Link to={`${ENDPOINT_STAFF}/${item.pk}/`} className="no-underline hover:underline">{item.first_name} {item.last_name}</Link>
                                <Link to={`${ENDPOINT_STAFF}/${item.pk}/`} className="no-underline hover:underline">{item.email}</Link>
                              </div>
                            </div>
                          </td>
                          <td className='p-4 text-xs md:text-sm text-left font-satoshi'>
                            <Moment format="MMM, DD Y">
                              {item.date_joined}
                            </Moment>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                  <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} />
                </div>
              ) :
                <p className='font-satoshi text-[18px] py-6'>{processing ? "Processing" : "No staff to display"}</p>
            }
          </div>
        </div>
      </div>
    </>

  )
}

export default StaffPage