import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SESSION_STORAGE_VERIFY_EMAIL, SESSION_STORAGE_VERIFY_ID } from '../constants';
import { ENDPOINT_EMAIL_VERIFY } from '../routes';
import { makeAuthenticatedRequest } from '../utils';

function EnterEmail({ handleEmailChange }) {
    const [processing, setProcessing] = useState(false)
    const [email, setEmail] = useState('')
    const [error, setError] = useState()
    const navigate = useNavigate();

    const onEmailSubmit = (e) => {
        e.preventDefault();
        if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
            setError("Enter a valid email")
            return
        }

        setProcessing(true)
        const params = { "email": email }
        makeAuthenticatedRequest(`/oms/email/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setProcessing(false)
                if (data && data.ID) {
                    handleEmailChange(email, data.ID)
                } else {
                    setError(data[0])
                }
            }).catch(() => {
                setError("Something went wrong, try again later")
            }).finally(() => {
                setProcessing(false)
            });
    }
    useEffect(() => {
        if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
            setError(null)
        }
    }, [email])
    return (
        <>
            {
                processing ?
                    <div class="flex items-center justify-center h-full overflow-hidden">
                        <img src='/cdn/images/loading.gif' className='mt-2 md:mt-10 mb-2 md:mb-10 h-10 w-10' alt='Processing' />
                    </div>
                    :
                    <div className="w-[98%] md:w-full bg-white h-full px-4 md:px-[106px] justify-center items-center  ">
                        <div className="flex flex-col w-full h-full gap-2 ">
                            <div className="">

                                <img src='/cdn/images/logo.svg' className='mt-2 md:mt-[108px] mb-2 md:mb-[210px] h-8 md:h-16 float-left' alt='Sparepap Logo' />
                            </div>
                            <div className="">
                                <p class="text-[#030A1A] font-satoshi font-semibold text-[24px]">Login</p>

                            </div>
                            <form className="mt-6 w-full ">
                                <div className="mb-2 w-full md:w-[600px] ">
                                    <div>

                                        {error &&
                                            <div class="bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
                                                <div class="flex">
                                                    <div class="py-2"><svg class="fill-current h-6 w-6 text-red-400 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                                                    <div>
                                                        <p class="font-bold font-satoshi">Error</p>
                                                        <p class="text-sm font-satoshi text-[16px]">{error}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="relative mt-1 w-full rounded-md shadow-sm">
                                            <label htmlFor="" className='font-satoshi text-[] text-[12px]'>Email</label>
                                            <input
                                                onChange={e => setEmail(e.target.value)}
                                                value={email}
                                                type="email"
                                                className="block  px-4 h-[44px] w-full md:w-[600px] md:py-3 mt-2 text-gray-700 bg-white font-satoshi text-[16px] md:text-[20px]  border border-[#19223B] rounded-md focus:border-[#19223B] focus:ring-[#19223B] focus:outline-none focus:ring focus:ring-opacity-40"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <button
                                        onClick={onEmailSubmit}
                                        className="w-full md:w-[600px] h-auto md:h-[48px] px-4 py-2 md:py-3 tracking-wide text-white font-satoshi text-[16px] md:text-[20px] transition-colors duration-200 transform bg-[#19223B] rounded-md hover:bg-[#19223B] focus:outline-none focus:bg-[#19223B] BgColor">
                                        Sign in
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

            }
        </>
    )
}

export default EnterEmail