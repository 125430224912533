import React from 'react'
// import blueBlob from "../assets/blueblob.svg"
// import redBlob from "../assets/redblob.svg"

function Gradient({oppacity}) {
  return (
    <>
        <div className={`opacity-${oppacity} bg-gradient-to-br from-[#BF3054]   to-[#BF3054] w-fit md:w-full overflow-hidden justify-center sm-none`}>
      <div className="relative">
      {/* <img src={redBlob} alt="" className='absolute' /> */}
      {/* <img src={blueBlob} alt="" className='absolute' /> */}
      </div>
    <div className="flex flex-col mx-auto pt-4 md:pt-[98px] pb-2 md:pb-[145px] pl-4 md:pl-24">

        <img src='/cdn/images/logo.svg' alt="" className='h-20 md:h-48 w-28 z-50 md:w-52' />
        <div className=" pt-[10px] md:pt-[45px] pb-[10px] md:pb-[44px]">
        <p className='text-white font-bold text-[18px] md:text-[48px] mb-2 w-full md:w-9/12 font-satoshi'>Welcome to Sparepap OMS!</p>
        {/* <p className='text-white font-bold text-[18px] md:text-[48px] w-full md:w-9/12 font-satoshi'>Earn more.</p>  */}
        </div>
        <p className='text-white pt-2 md:pt-8 pb-[10px] md:pb-[49px] mb-3 w-full md:w-9/12 text-[14px] md:text-[24px] font-satoshi'>Monitor the performance of our inventory and order fulfillment processes in real-time.</p>
  
        <p className='text-white pb-[10px] md:pb-[44px] mt-2 w-full md:w-9/12 text-[14px] md:text-[24px] font-satoshi'>Keep an eye on the key performance indicators (KPI) in our Operations Management Dashboard and have an overview of the efficiency of our spare parts business.</p>
 
   
        </div>
</div>
    </>
  )
}

export default Gradient