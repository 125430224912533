import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import ReactLoading from 'react-loading';
import { axiosCustom } from '../../utils';

import Avatar from 'react-avatar'
import Moment from 'react-moment';
import { makeAuthenticatedRequest } from '../../utils'
import { WS_PREFIX } from '../../constants'

import { toast } from 'react-toastify';


function ActivityModal() {
    const { customerID } = useParams();
    const navigate = useNavigate()
    const [edit, setEdit] = useState(false)
    const [leadNotes, setleadNotes] = useState('')
    const [sessionCount, setSessionCount] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [addVehicleModal, setAddVehicleModal] = useState(false)

    const [leadData, setLeadData] = useState([])

    const [leadFirstName, setLeadFirstName] = useState('')
    const [leadLastName, setLeadLastName] = useState('')
    const [leadPhoneNumber, setLeadPhoneNumber] = useState('')
    const [leadEmail, setLeadEmail] = useState('')
    const [leadPartServiceNeeded, setLeadPartServiceNeeded] = useState('')
    const [leadVehicleModel, setLeadVehicleModel] = useState('')




    const fetchLead = () => {
        setProcessing(true)
        makeAuthenticatedRequest(`/oms/consumers/lead/${customerID}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setLeadData(data)
                setLeadFirstName(data?.first_name)
                setLeadLastName(data?.last_name)
                setLeadPhoneNumber(data?.phone_number)
                setLeadEmail(data?.email)
                // setLeadPartServiceNeeded(data?.)
                setLeadVehicleModel(data?.vehicle)
                setProcessing(false)
            });
    }

    const fetchStats = async () => {
        try {
            const response = await axiosCustom.get(`${WS_PREFIX}/user/${customerID}`);
            setSessionCount(response?.data?.session_count)
        } catch (err) {
            toast.error("Something went wrong");
        }
    }
    useEffect(() => {
        fetchLead()
        fetchStats()
    }, [])



    const editLeadDetails = () => {
        setProcessing(true)
        const params = {
            first_name: leadFirstName,
            last_name: leadLastName,
            phone_number: leadPhoneNumber,
            email: leadEmail
        }
        makeAuthenticatedRequest(`/oms/consumers/lead/${leadData.id}/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                setProcessing(false);
                setLeadData(data)
                toast.info('Lead updated successfully')
            })
            .catch((err) => {
                toast.error('Something went wrong')

                setProcessing(false);

            }).finally(() => {
                setProcessing(false);
            });



    }


    const submitleadNotes = (event) => {
        event.preventDefault();
        setProcessing(true)

        const params = {
            remarks: leadNotes
        }
        makeAuthenticatedRequest(`/oms/consumers/lead/${leadData.id}/new_note/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                setLeadData(data)
                setleadNotes('')
            })
            .catch((err) => {

                setProcessing(false);

            }).finally(() => {
                setProcessing(false);
            });
    }

    return (
        <>
            {
                processing &&
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />

            }
            <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-10 md:pt-10 pb-10">
                {
                    sessionCount != 0 &&
                    <div class="p-4 text-sm text-gray-800 rounded-lg bg-gray-50  " role="alert">
                        <span class="font-medium">{sessionCount}</span> site visits.
                    </div>

                }

                <div className="flex flex-row gap-4 w-full ">
                    <div className="flex flex-col gap-2 w-full">
                        {false &&

                            <p className='font-satoshi text-[18px] font-semibold'>Notes</p>
                        }

                        <div className="flex flex-col py-4 w-full">
                            <form submit={(e) => { e.preventDefault() }} className='w-full'>
                                <input type="text"
                                    value={leadNotes}
                                    onChange={(e) => setleadNotes(e.target.value)}
                                    placeholder='Write a note'
                                    className='w-full rounded-lg border outline-none font-satoshi px-4 py-2 placeholder:text-[#666C7C]' />
                                {leadNotes &&
                                    <div className="flex flex-row-reverse w-full py-4 gap-2">
                                        <button
                                            onClick={submitleadNotes}
                                            className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>
                                            {processing ?
                                                'Submiting ...' : 'Submit'
                                            }
                                        </button>
                                    </div>
                                }

                            </form>
                        </div>

                        <div className="flex flex-col w-full gap-2">
                            {
                                leadData?.notes?.map((log) => (
                                    <div className="flex flex-col py-2 w-full">
                                        <div className="flex flex-row gap-2 w-full">
                                            <div className=''>
                                                <Avatar name={log.user.first_name ? `${log.user.first_name} ${log.user.last_name}` : log.user.email} size="50" className="h-12 w-12 mr-2 rounded-full shadow" />
                                            </div>
                                            <div className='flex flex-col gap-3 flex-1'>
                                                <div className='flex flex-row gap-2'>
                                                    <p className="font-satoshi text-[#030A1A]  text-[18px]">
                                                        {log.user.first_name ? `${log.user.first_name} ${log.user.last_name}` : log.user.email}
                                                    </p>

                                                    <span>.</span>

                                                    <p className="font-satoshi text-[#666C7C]  text-[18px]">
                                                        <Moment date={log.date_created} format="D MMM" trim />

                                                    </p>
                                                    <p className="font-satoshi text-[#666C7C]  text-[18px]">
                                                        <Moment date={log.date_created} format="hh:mm" trim />
                                                    </p>
                                                    <p className="font-satoshi text-[#666C7C]  text-[14px]">
                                                        {log.system_message}
                                                    </p>
                                                </div>
                                                {log.message &&
                                                    <div className='flex flex-col px-2 py-2 bg-gray-100 rounded-md'>
                                                        <p className="font-satoshi text-[#030A1A]  text-[14px]">
                                                            {log.message}
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}


                        </div>
                    </div>

                </div>

            </div>

            {addVehicleModal &&
                <>
                    <div className='h-screen pt-1 md:pt-0  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
                        <div className='relative p-4 w-full md:w-[40%] h-full md:h-auto'>
                            <div className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                                <div className='flex items-start justify-end p-2 rounded-t '>
                                </div>

                                <div className='relative p-3 flex-auto'>
                                    <div className='px-4 pt-4 pb-3 w-full'>

                                        <div className="flex flex-col">
                                            <div onClick={() => setAddVehicleModal(false)} className="">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                            <div className="flex flex-row gap-2 justify-between">
                                                <div className="flex flex-row gap-2">
                                                    <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                                                        Enter details
                                                    </h2>
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-2">






                                            </div>
                                            <div className="flex flex-row-reverse w-full py-4 gap-2">
                                                <button
                                                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>
                                                    Submit
                                                </button>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-50' />
                </>
            }


        </>
    )
}
export default ActivityModal