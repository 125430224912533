import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "../../Pagination";
import { ENDPOINT_CMS_ADD_PARTNER } from "../../../routes";
import { getPartnerSubsections } from "./partnerUtils";

function Partner() {
  const navigate = useNavigate();
  const [partnerSubsections, setPartnerSubsections] = useState([]);

  useEffect(() => {
    getPartnerSubsections()
      .then((results) => {
        setPartnerSubsections(results)
        console.log(results)
      })
      .catch((error) => {
        console.error("Error fetching partner subsections:", error);
        throw error;
      })
      .finally();

  }, [])

  // Function to handle viewing detailed information
  const handleView = (id) => {
    // You can navigate to the detailed view page passing the ID or other necessary data
    navigate(`/hero/${id}`);
  };

  return (
    <>
      <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-10 md:pt-20 pb-10">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-4 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
        </div>
        <div>
          {/* buttons  */}
          <div className="flex flex-row rounded  w-fit float-right gap-2">
            <Link
              to={ENDPOINT_CMS_ADD_PARTNER}
              className="outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="text-white w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add New Partner
            </Link>
          </div>
        </div>


        <input
          type="text"
          name="s"
          id="simple-search"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    "
          placeholder="Search Partner"
        />



        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
              <table class="table-auto w-full">
                <thead className="pl-1 md:pl-2 table-auto rounded-lg">
                  <tr className="pl-1 md:pl-2 rounded-lg">
                    <th className="p-4 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                      Image
                    </th>

                    <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                      <span>
                        <span className="inline pr-1">Name</span>
                      </span>
                    </th>
                    <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                      <span>
                        <span className="inline pr-2">Tags</span>
                      </span>
                    </th>

                    <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                      <span>
                        <span className="inline pr-2">Status</span>
                      </span>
                    </th>
                    <th className="pl-2 pb-1 font-satoshi cursor-pointer text-xs relative md:text-sm tracking-wide text-left text-gray-400">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {partnerSubsections.map((partnerSubsection) => (
                    <tr
                      key={partnerSubsection.id}
                      className="p-2 border border-b-gray-200 relative"
                    >
                      <td className="p-1 md:p-2 ">
                        <img
                          src={partnerSubsection?.image_url}
                          alt="Icon"
                          className="h-4 md:h-9 w-4 md:w-9 md:mx-auto inline"
                        />
                      </td>
                      <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                        <p className="font-satoshi  text-[14px]">{partnerSubsection?.name || "-"}</p>
                      </td>
                      <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                        <p className="font-satoshi  text-[14px]">{partnerSubsection?.tags.join(", ") || "-"}</p>
                      </td>
                      <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                        <p className="font-satoshi  text-[14px]">{partnerSubsection?.is_active ? "Active" : "Inactive"}</p>
                      </td>
                      <td className="p-4 text-xs md:text-sm text-left font-satoshi max-w-[10em]">
                        <button
                          onClick={() => handleView(partnerSubsection?.id)}
                          className="bg-blue-500 text-white px-2 py-1 rounded-md"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                pageChanged="true"
                totalItems="20"
                itemsPerPage="1"
                currentPage="1"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Partner;
