import React from 'react'

import Hero from "../components/cms/Hero/Hero"

function HeroPage() {
  return (
    <div>
      <Hero/>
    </div>
  )
}

export default HeroPage
