import React from 'react'
import Shop from "../components/cms/Shop/Shop"

function ShopPage() {
  return (
    <div>
      <Shop/>
      
    </div>
  )
}

export default ShopPage
