import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { makeAuthenticatedRequest } from '../utils'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { FiMoreVertical } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IoIosAdd } from "react-icons/io";




const RfqDetailsPage = () => {
    const { rfqID } = useParams()
    const navigate = useNavigate()
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [brandsData, setBrandsData] = useState([]);
    const [rfqData, setRfqData] = useState([])
    const [updatedPrices, setUpdatedPrices] = useState({});
    const [updatedParts, setUpdatedParts] = useState([]);
    const [showAddButton, setShowAddButton] = useState(null);



    const handlePriceChange = (index, value, part) => {
        const payload = {
            id:part?.id,
            price:parseFloat(value)

        }
          // Check if the part with the same id exists in updatedParts
        const existingIndex = updatedParts.findIndex((p) => p.id === part.id);

        if (existingIndex !== -1) {
            // If exists, remove the existing one
            const updatedPartsCopy = [...updatedParts];
            updatedPartsCopy.splice(existingIndex, 1);
            setUpdatedParts(updatedPartsCopy);
        }

        // Add the new payload to updatedParts
        setUpdatedParts((prevParts) => [...prevParts, payload]);
      // Update the updatedPrices state with the new price
      setUpdatedPrices((prevPrices) => ({ ...prevPrices, [index]: parseFloat(value) }));
    };

    // console.log('updatedParts',updatedParts)
  


    const fetchRfq = () => {
        setProcessing(true)
        makeAuthenticatedRequest(`/oms/rfqs/${rfqID}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setRfqData(data)
                setProcessing(false)
            });
    }
    useEffect(() => {
        fetchRfq()
    }, [])

    const [newRows, setNewRows] = useState([])
    
    const handleCreateNewRow = (id) => {
      const part = getObjectById(id)
      
      const newIndex = newRows.length;

      let rowPart = {
        parentId:part?.id,
        idd:newIndex,
        condition:part?.condition,
        Condition:"",
        delivery_note:null,
        grn:null,
        brand:part?.brand,
        Brand:"",
        image:"",
        lpo:part?.lpo,
        part_name:part?.part_name,
        part_number:part?.part_number,
        part_type:part?.part_type,
        Type:"",
        vehicle:part?.vehicle,
        total:0,
        rfq:part?.rfq,
        quantity:1,
        price:0
      }
      console.log('rowPart',rowPart)
      setNewRows([...newRows, rowPart])
    }

    const handleCreateNewRfqParts = async() => {
      setLoading(true)
      const payload = {
       parts:newRows 
      }
      console.log('payload',payload)
      await makeAuthenticatedRequest(`/oms/create-rfqparts/`, "POST", JSON.stringify(payload))
      .then((response) => response.json())
      .then((data) => {
          fetchRfq()
          setNewRows([])
          setLoading(false)

      })
      .catch((err) => {

        setLoading(false);
        toast.error('Something went wrong while sending new variants')

      }).finally(() => {
        setLoading(false);
        setNewRows([])

      });
    }

    const submitQuote = async () => {
      if(newRows?.length > 0) {
        await handleCreateNewRfqParts()
      } else {

        setLoading(true)
  
          const payload = {
              parts: updatedParts
          }
          console.log('payload',payload)
  
          makeAuthenticatedRequest(`/oms/rfqs/${rfqData?.id}/parts/update-prices/`, "PUT", JSON.stringify(payload))
              .then((response) => response.json())
              .then((data) => {
                  fetchRfq()
                  setUpdatedParts([])
                  setNewRows([])
                  setLoading(false)
                  toast.success('Qoutation was send successfully')
  
              })
              .catch((err) => {
  
                setLoading(false);
                toast.error('Something went wrong. Quote was not send')
  
              }).finally(() => {
                setLoading(false);
                setNewRows([])
              });
      }
    }

    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        // border: 'none',
        boxShadow: state.isFocused ? 'none' : 'none', 
      }),
      indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
    };
    

    const fetchBrands = () => {
      makeAuthenticatedRequest(`/car/part/brands/`)
         .then((response) => response.json())
         .then((data) => {
            setBrandsData(data);
            return []
         });
   }
   useEffect(() => {
    fetchBrands()
  },[])

  const returnBrandName = (id) => {
    let matches =  brandsData?.filter((brand) => brand.value === parseInt(id))
    console.log('matches',matches[0].label)
    if (matches.length > 0){
      return matches[0]
    }else {
      return null
    }
    
  }
  const CONDITION_OPTIONS = [
    {'value':'New', 'label':'New'},
    {'value':'Used', 'label':'Used'},
    {'value':'Refurbished', 'label':'Refurbished'},
]


    function getObjectById(id) {
      // Find the object with the matching id
      const foundObject = rfqData?.parts?.find(obj => obj.id === id);
    
      // Return the found object or null if not found
      return foundObject || null;
    }




    const updateField = (partId, fieldName, newValue) => {
      setNewRows(prevParts => {
        return prevParts.map(part => {
          if (part.idd === partId) {
            // Update the specified field for the matching part
            return {
              ...part,
              [fieldName]: newValue
            };
          }
          return part;
        });
      });
    };

    const TYPE_OPTIONS = [
      {'value':'OEM', 'label':'OEM'},
      {'value':'After market', 'label':'After market'},
  ]
    
  const addRef = useRef(null)

  useEffect(() => {
    let actionhandler = e => {
      if (addRef.current && !addRef.current.contains(e.target)) {

        setShowAddButton(null)

        // console.log(addRef.current)
      }
    }
    document.addEventListener('mousedown', actionhandler);

    return () => {
      document.removeEventListener('mousedown', actionhandler);
    }


  })

    const handleDeleteNewRow = (id) => {

      const updatedParts = newRows.filter(part => part.idd !== id);
      setNewRows(updatedParts)
      return updatedParts;
    }
  
    const getBrandByValue = (value) => {
      const foundBrand = brandsData.find(brand => brand.value === value);
      return foundBrand ? foundBrand.label : null
    };


    function alphabet(index) {
      const alphabet = 'abcdefghijklmnopqrstuvwxyz';
      return alphabet[index];
    }
  

    
  return (
    <>
        {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
            <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
            <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
    }
    <div className="flex w-full px-4 md:px-10 flex-col h-full  gap-4 pt-32 md:pt-20 pb-10">
    <div className="flex flex-row justify-between">
                    <div onClick={() => navigate(-1)}
                        className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-4 mt-0.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                        </svg>

                        Back
                    </div>
                    <div className=" flex flex-row gap-2 font-satoshi">
                        {(updatedParts.length > 0 || newRows?.length > 0) &&
                        <button 
                        onClick={submitQuote}
                        className='text-white text-center px-4 py-2 rounded-md bg-gray-900 '>{loading ? 'Submitting...':'Submit quote'}</button>
                        }
                    </div>

                </div>
                <p className='font-satoshi text-3xl font-semibold'>RFQ #{rfqID}</p>
                <div className="pt-2 w-full  rounded-lg  border mt-4">
                          <table class="table-auto w-full  ">
                            <thead className='pl-2'>
                              <tr className='pl-1 border-b border-gray-200'>
                              <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>#</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Name</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Vehicle</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Part No</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Condition</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Type</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Quantity</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Brand</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Price</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Remarks</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Image</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide font-light text-left text-gray-400'>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                            {rfqData?.parts?.slice().reverse().map((part, index) => (
                                  <>    
                                  <tr className='p-1 border-b border-b-gray-200 font-satoshi'>
                                  <td
                                    className='p-2 text-xs md:text-[14px] text-left font-satoshi text-[#808491]'>
                                      
                                      <p className='text-[14px] text-[#808491]'>
                                         {index + 1 }
                                         </p>
                                    </td>
                                    
                                    <td
                                    className='p-2 text-xs md:text-[14px] text-left font-satoshi text-[#808491]'>
                                       <p className='text-[14px] text-[#808491]'>
                                        {part?.part_name}
                                        </p> 
                                    </td>
                                    
                                  <td className='pl-2 text-xs md:text-[16px] flex-1'>
                                    <p className='font-semibold text-[14px] text-gray-600'>
                                    
                                    {part?.vehicle.make} - {part?.vehicle.model}- {part?.vehicle.year} 
                                    </p>
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[14px] text-left font-satoshi text-[#808491]'>
                                      {part?.part_number} 
                                    </td>
                                    <td className='pl-2 text-xs md:text-[16px] '>
                                    <p className='text-[14px] text-gray-600'>
                                    {part?.condition ?
                                    (
                                      part?.condition
                                    ) :
                                    
                                    <Select
                                    styles={customStyles}

                                      //   value={rfq?.Condition}
                                      //   onChange={(e) => {
                                      //   handleInputChange(rfq?.id, "Condition", e)
                                      //   handleInputChange(rfq?.id, "condition", e.value)

                                      // }}
                                        required
                                        id='Condition'
                                        name="Condition"
                                        className='w-30 outline-none'
                                        options={CONDITION_OPTIONS}
                                        placeholder="Condition"
                                    />}
                                    </p>
                                    </td>
                                    <td className='pl-2 text-xs md:text-[16px] '>
                                    <p className='text-[14px] text-gray-600'>
                                    {part?.part_type}
                                    </p>
                                    </td>
                                    <td className='pl-2 text-xs md:text-[16px]'>
                                    <p className='text-[14px] text-center text-gray-500'>
                                    {part?.quantity}
                                    </p>
                                    </td>
                                    <td className={`p-2 text-xs md:text-[16px] text-left font-satoshi capitalize`}>
                                    <p className='text-[14px] text-gray-500'>
                                    
                                    {getBrandByValue(part?.brand)}
                                    </p>
                                  </td>
                                    <td
                                    className='p-2 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       
                                       <input
                                        value={updatedPrices[index] !== undefined ? updatedPrices[index] : parseFloat(part.price)}
                                        onChange={(e) => handlePriceChange(index, e.target.value, part)}
                                        type="number"
                                        className='border outline-none px-2 py-1.5 text-[14px] rounded-md '
                                    />
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[16px] text-left font-satoshi text-[#808491] w-80 flex-1'>
                                      <p className='text-[14px] text-gray-500'>
                                    
                                       {part?.remarks}
                                      </p>
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[14px] text-left font-satoshi text-[#808491]'>
                                      
                                      {part?.image ?
                                       <img src={part?.image} className='w-14 h-14 rounded-md' alt="" />
                                       :
                                       <div className="w-14 h-14 rounded-md bg-gray-200 flex flex-col justify-center items-center">
                                        <p className='text-[13px] text-center'>No image</p>
                                       </div>
                                      }
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[16px] font-satoshi flex flex-col text-[#808491] relative'>
                                     <button
                                     onClick={() => setShowAddButton(part?.id)
                                      }
                                      className='flex flex-col items-center justify-center'>
                                     <FiMoreVertical />

                                     </button>
                                     {showAddButton === part?.id &&
                                    <div ref={addRef} className="absolute -bottom-6 right-4 w-full">
                                      <button onClick={()=> handleCreateNewRow(part?.id)} className='flex flex-row items-center gap-1 border rounded-md py-1.5 px-2 bg-gray-200'>
                                        <IoIosAdd/> <p className='text-[14px]'>Options</p>
                                      </button>
                                    </div>}
                                    </td>
                                  </tr>
                                  
                                  {newRows.filter((newRow) => newRow?.parentId === part?.id).map((row, index) => (
                                      
                                  <tr className='p-2 border-b border-b-gray-200 font-satoshi'>
                                    <td
                                    className='p-2 text-xs md:text-[14px] text-right font-satoshi text-[#808491]'>
                                        <p className='text-[14px] text-gray-600 text-right'>
                                        ({alphabet(index)})
                                        </p>
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       <p className='text-[14px] text-gray-600'>
                                       {row?.part_name}
                                       </p>
                                    </td>
                                    
                                  <td className='pl-2 text-xs md:text-[16px] flex-1'>
                                    <p className='font-semibold text-[14px] text-gray-600'>
                                    
                                    {row?.vehicle.make} - {row?.vehicle.model}- {row?.vehicle.year} 
                                    </p>
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                      <p className='text-[14px] text-gray-600'>
                                      {row?.part_number} 
                                      </p>
                                    </td>
                                    <td className='pl-2 text-xs md:text-[16px] '>
                                    <p className='text-[14px] text-gray-600'>
                                    {row?.condition ?
                                    (
                                      <p className='text-[14px] text-gray-600'>{row?.condition}</p>
                                    ) :
                                    
                                    <Select
                                    styles={customStyles}

                                        value={row?.Condition}
                                        onChange={(e) => {
                                          updateField(row?.idd, "Condition", e)
                                          updateField(row?.idd, "condition", e.value)

                                      }}
                                        required
                                        id='Condition'
                                        name="Condition"
                                        className='w-30 outline-none'
                                        options={CONDITION_OPTIONS}
                                        placeholder="Condition"
                                    />}
                                    </p>
                                    </td>
                                    <td className='pl-2 text-xs md:text-[16px] '>
                                    <p className='text-[14px] text-gray-600'>
                                    {row?.part_type ?
                                    (
                                      <p className='text-[14px] text-gray-600'>{row?.part_type}</p>
                                    ) :
                                    
                                    <Select
                                    styles={customStyles}

                                        value={row?.Type}
                                        onChange={(e) => {
                                          updateField(row?.idd, "Type", e)
                                          updateField(row?.idd, "part_type", e.value)

                                      }}
                                        required
                                        id='Type'
                                        name="Type"
                                        className='w-30 outline-none'
                                        options={TYPE_OPTIONS}
                                        placeholder="Type"
                                    />}
                                    </p>
                                    </td>

                                    <td className='pl-2 text-xs md:text-[16px]'>
                                    <p className='text-gray-500 text-[14px] text-center'>
                                    {row?.quantity}
                                    </p>
                                    </td>
                                    <td className={`p-2 text-xs md:text-[16px] text-left font-satoshi capitalize`}>
                                    {row?.brand ? 
                                    <p>{getBrandByValue(row?.brand)}</p>
                                    :

                                    <Select
                                    styles={customStyles}
                                          className='p-1 rounded-lg w-full text-[#19223B] font-satoshi text-md w-30'
                                          placeholder="Brand"
                                          value={row?.Brand}
                                          onChange={(e) => {
                                            updateField(row?.idd, "Brand", e)
                                            updateField(row?.idd, "brand", e.value)
  
                                        }}
                                          name="brand"
                                          options={brandsData}
                                          />
                                    }
                                    
                                  </td>

                                    <td
                                    className='p-2 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       
                                       <input
                                        value={parseFloat(row.price)}
                                        onChange={(e) => updateField(row?.idd, "price",e.target.value)}
                                        type="number"
                                        className='border outline-none px-2 py-1.5 text-[14px] rounded-md'
                                    />
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       {row?.remarks}
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       {/* {row?.part_name} */}
                                    </td>
                                    <td
                                    className='p-2 text-xs md:text-[16px] text-left font-satoshi flex flex-col items-center justify-center text-[#808491]'>
                                       
                                       <button onClick={() => handleDeleteNewRow(row?.idd)}
                                       className='flex flex-col justify-center items-center text-red-400'>
                                       <MdDelete />
                                       </button>
                                    </td>
                                  </tr>
                                      ))}
                                  </>

                              ))}

                            </tbody>
                          </table>
                   
    </div>
    </div>
    </>
  )
}

export default RfqDetailsPage