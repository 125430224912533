import React from 'react'

import Product from "../components/cms/Product/Product"

function ProductPage() {
  return (
    <div>
    <Product/>
    </div>
  )
}

export default ProductPage
