import Datepicker from "react-tailwindcss-datepicker";
import dayjs from "dayjs";
import { BsDot } from "react-icons/bs";
import React, { useEffect, useState } from 'react'
import { formatNumber, makeAuthenticatedRequest } from "../../utils";
import { Link } from "react-router-dom";
import { ENDPOINT_INVENTORY } from "../../routes";
import { buildConfigs, resolveDateRangeToName } from "./defaultChartConfig";

const TAB_B2C = "top_b2c"
const TAB_B2B = "top_b2b"
const TAB_BRANDS = "top_brands"
const TAB_PRODUCTS = "top_products"
const TAB_SEARCHES = "top_searches"
const TAB_MERCHANTS = "top_merchants"
const TAB_CATEGORIES = "top_categories"

const TAB_CLASSNAMES = "inline-block w-full p-4 rounded-tl-lg bg-gray-50 hover:bg-gray-100 focus:outline-none"
const ACTIVE_TAB_CLASSNAMES = "text-blue-600 inline-block w-full p-4 rounded-tl-lg bg-gray-50 hover:bg-gray-100 focus:outline-none"

export default function TopStats() {
    const [processing, setProcessing] = useState(true)
    const [perPage, setPerPage] = useState(11)
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)



    const [dataProducts, setDataProducts] = useState()
    const [dataSearches, setDataSearches] = useState()
    const [dataMerchants, setDataMerchants] = useState()

    const [dataBrands, setDataBrands] = useState()
    const [dataCustomers, setDataCustomers] = useState()
    const [dataBusinesses, setDataBusinesses] = useState()

    const [dataCategories, setDataCategories] = useState()
    const [currentTab, setCurrentTab] = useState(TAB_SEARCHES)
    const [dateRange, setDateRange] = useState({
        startDate: dayjs(new Date()).subtract(7, "day").format("YYYY-MM-DD"),
        endDate: dayjs(new Date()).format("YYYY-MM-DD")
    });

    const configs = buildConfigs()
    const [dateRangeName, setDateRangeName] = useState("Last 7 Days")


    const handleDateRangeChange = (newValue) => {
        setDateRangeName(resolveDateRangeToName(newValue, configs?.shortcuts))
        setDateRange(newValue);
    }

    const buildSearchString = (query) => {
        const params = { q: query }
        const searchParams = new URLSearchParams(params).toString();
        return `https://sparepap.com/parts?${searchParams}`
    }

    const fetchTopProducts = (newValue, newPage) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate,
            page: newPage ? newPage : page,
            per_page: perPage
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/analytics/best_products/?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                setDataProducts(data)
                setCount(data?.count)
            });
    }

    const fetchTopMerchants = (newValue, newPage) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate,
            page: newPage ? newPage : page,
            per_page: perPage
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/analytics/best_merchants/?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                setDataMerchants(data)
                setCount(data?.count)
            });
    }

    const fetchTopSearches = (newValue, newPage) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate,
            page: newPage ? newPage : page,
            per_page: perPage
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/analytics/searches/?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                setDataSearches(data)
                setCount(data?.total)
            });
    }

    const increasePage = () => {
        setPage(page + 1)
    }

    const descreasePage = () => {
        setPage(page - 1)
    }

    const fetchCustomers = (newValue, newPage) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate,
            page: newPage ? newPage : page,
            per_page: perPage
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/analytics/customers/?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                setDataCustomers(data)
                setCount(data?.count)
            });
    }

    const fetchBusinesses = (newValue, newPage) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate,
            page: newPage ? newPage : page,
            per_page: perPage
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/analytics/businesses/?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                setDataBusinesses(data)
                setCount(data?.count)
            });
    }

    const fetchTopBrands = (newValue, newPage) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate,
            page: newPage ? newPage : page,
            per_page: perPage
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/analytics/best_brands/?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                setDataBrands(data)
                setCount(data?.count)
            });
    }

    const fetchTopCategories = (newValue, newPage) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate,
            page: newPage ? newPage : page,
            per_page: perPage
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/analytics/best_categories/?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                setDataCategories(data)
                setCount(data?.count)
            });
    }

    const fetchTopStats = (thisDate, thisPage) => {

        if (currentTab == TAB_CATEGORIES) {
            fetchTopCategories(thisDate, thisPage)
        }
        if (currentTab == TAB_MERCHANTS) {
            fetchTopMerchants(thisDate, thisPage)
        }

        if (currentTab == TAB_PRODUCTS) {
            fetchTopProducts(thisDate, thisPage)
        }

        if (currentTab == TAB_SEARCHES) {
            fetchTopSearches(thisDate, thisPage)
        }

        if (currentTab == TAB_BRANDS) {
            fetchTopBrands(thisDate, thisPage)
        }


        if (currentTab == TAB_B2B) {
            fetchBusinesses(thisDate, thisPage)
        }

        if (currentTab == TAB_B2C) {
            fetchCustomers(thisDate, thisPage)
        }
    }

    useEffect(() => {
        if( page != 1){
            setPage(1)
        } else {
            setProcessing(true)
            fetchTopStats(null, 1)
            setProcessing(false)
        }
        
    }, [currentTab])

    useEffect(() => {
        setProcessing(true)
        fetchTopStats(dateRange, page)
        setProcessing(false)
    }, [page, dateRange])

    return (
        <>
            {processing ?
                <div class="border border-gray-200 rounded-lg shadow-sm shadow rounded-md p-4 w-full mx-auto">
                    <div class="animate-pulse flex space-x-4">
                        <div class="flex-1 space-y-6 py-1">
                            <div class="h-6 bg-slate-200 rounded"></div>
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-10 bg-slate-200 rounded col-span-2"></div>
                                    <div class="h-10 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div class="h-6 bg-slate-200 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <header className="mb-2">
                        <nav className="bg-white border-gray-200 py-2.5">
                            <div className="flex flex-wrap justify-between items-center">
                                <div className="flex justify-start items-center font-semibold text-green-500">
                                    {dateRangeName}
                                </div>
                                <div className="flex justify-end">
                                    <Datepicker
                                        value={dateRange}
                                        onChange={handleDateRangeChange}
                                        showShortcuts={true}
                                        configs={configs}
                                    />
                                </div>
                            </div>
                        </nav>
                    </header>
                    <ul class="text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg sm:flex" role="tablist">

                        <li class="w-full">
                            <button
                                onClick={() => setCurrentTab(TAB_SEARCHES)}
                                type="button" role="tab" className={currentTab == TAB_SEARCHES ? ACTIVE_TAB_CLASSNAMES : TAB_CLASSNAMES}>Top Searches</button>
                        </li>

                        <li class="w-full">
                            <button
                                onClick={() => setCurrentTab(TAB_PRODUCTS)}
                                type="button" role="tab" className={currentTab == TAB_PRODUCTS ? ACTIVE_TAB_CLASSNAMES : TAB_CLASSNAMES}>Top Products</button>
                        </li>
                        <li class="w-full">
                            <button
                                onClick={() => setCurrentTab(TAB_MERCHANTS)}
                                type="button" role="tab" className={currentTab == TAB_MERCHANTS ? ACTIVE_TAB_CLASSNAMES : TAB_CLASSNAMES}>Top Merchants</button>
                        </li>
                        <li class="w-full">
                            <button
                                onClick={() => setCurrentTab(TAB_CATEGORIES)}
                                type="button" role="tab" className={currentTab == TAB_CATEGORIES ? ACTIVE_TAB_CLASSNAMES : TAB_CLASSNAMES}>Top Categories</button>
                        </li>

                        <li class="w-full">
                            <button
                                onClick={() => setCurrentTab(TAB_BRANDS)}
                                type="button" role="tab" className={currentTab == TAB_BRANDS ? ACTIVE_TAB_CLASSNAMES : TAB_CLASSNAMES}>Top Brands</button>
                        </li>

                        <li class="w-full">
                            <button
                                onClick={() => setCurrentTab(TAB_B2C)}
                                type="button" role="tab" className={currentTab == TAB_B2C ? ACTIVE_TAB_CLASSNAMES : TAB_CLASSNAMES}>Top Customers</button>
                        </li>

                        <li class="w-full">
                            <button
                                onClick={() => setCurrentTab(TAB_B2B)}
                                type="button" role="tab" className={currentTab == TAB_B2B ? ACTIVE_TAB_CLASSNAMES : TAB_CLASSNAMES}>Top Businesses</button>
                        </li>

                    </ul>
                    <div class="border-t border-gray-200">
                        {!processing ?
                            <>
                                {currentTab == TAB_PRODUCTS &&
                                    <>
                                        {dataProducts?.results?.length > 0 ?

                                            <div className="pt-4">
                                                <ul role="list" className="divide-y divide-gray-200">
                                                    {dataProducts?.results?.map((item) => (

                                                        <li className="py-3">
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center  w-full">
                                                                    {
                                                                        item?.carpart__cover_image_url ?
                                                                            <img className="flex-shrink-0 w-10 h-10 cover" src={item?.carpart__cover_image_url} />
                                                                            :
                                                                            <div className="border border-gray-200 bg-gray-100 rounded-md">
                                                                                <p className="text-gray-300 text-xs text-center  h-10 w-10">
                                                                                    No image
                                                                                </p>
                                                                            </div>
                                                                    }
                                                                    <div class="ml-3 overflow-hidden">
                                                                        <Link className="font-medium text-gray-900 truncate" to={`/inventory/${item.carpart__id}`}>
                                                                            {item?.carpart__name}
                                                                        </Link>
                                                                        <div class="flex items-center justify-start flex text-sm text-gray-500 space-x-1">
                                                                            <span>Ksh. {formatNumber(item?.price, 0, 0)}</span><BsDot />
                                                                            <span>{formatNumber(item?.quantity, 0, 0)} sold </span><BsDot />
                                                                            <span className="font-semibold">Ksh. {formatNumber(item?.line_total, 0, 0)} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )

                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <p className="text-center text-md m-6 text-gray-500">No top products to display</p>
                                        }
                                    </>
                                }

                                {currentTab == TAB_MERCHANTS &&
                                    <>
                                        {dataMerchants?.results?.length > 0 ?

                                            <div className="pt-4">
                                                <ul role="list" className="divide-y divide-gray-200">
                                                    {dataMerchants?.results?.map((item) => (

                                                        <li className="py-3">
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center  w-full">
                                                                    {
                                                                        item?.merchant__merchant_thumbnail ?
                                                                            <img className="flex-shrink-0 w-10 h-10 cover" src={item?.merchant__merchant_thumbnail} />
                                                                            :
                                                                            <div className="border border-gray-200 bg-gray-100 rounded-md">
                                                                                <p className="text-gray-300 text-xs text-center  h-10 w-10">
                                                                                    No image
                                                                                </p>
                                                                            </div>
                                                                    }
                                                                    <div class="ml-3 overflow-hidden">
                                                                        <Link className="font-medium text-gray-900 truncate" to={`/user/merchant/${item.merchant__id}`}>
                                                                            {item?.merchant__merchant_name}
                                                                        </Link>
                                                                        <div class="flex items-center justify-start flex text-sm text-gray-500 space-x-1">
                                                                            <span>{formatNumber(item?.total_sales_count, 0, 0)} sold </span><BsDot />
                                                                            <span className="font-semibold">Ksh. {formatNumber(item?.total_sales, 0, 0)} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )

                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <p className="text-center text-md m-6 text-gray-500">No top merchants to display</p>
                                        }
                                    </>
                                }

                                {currentTab == TAB_CATEGORIES &&
                                    <>
                                        {dataCategories?.results?.length > 0 ?
                                            <div className="pt-4">
                                                <ul role="list" className="divide-y divide-gray-200">
                                                    {dataCategories?.results?.map((item) => (

                                                        <li className="py-3">
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center  w-full">
                                                                    {
                                                                        item?.carpart__parent_categories__image_url ?
                                                                            <img className="flex-shrink-0 w-10 h-10 cover" src={item?.carpart__parent_categories__image_url} />
                                                                            :
                                                                            <div className="border border-gray-200 bg-gray-100 rounded-md">
                                                                                <p className="text-gray-300 text-xs text-center  h-10 w-10">
                                                                                    No image
                                                                                </p>
                                                                            </div>
                                                                    }
                                                                    <div class="ml-3 overflow-hidden">
                                                                        <div className="font-medium text-gray-900 truncate">
                                                                            {item?.carpart__parent_categories__name}
                                                                        </div>
                                                                        <div class="flex items-center justify-start flex text-sm text-gray-500 space-x-1">
                                                                            <span>{formatNumber(item?.total_sales_count, 0, 0)} sold </span><BsDot />
                                                                            <span className="font-semibold">Ksh. {formatNumber(item?.total_sales, 0, 0)} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )

                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <p className="text-center text-md m-6 text-gray-500">No top categories to display</p>
                                        }
                                    </>
                                }


                                {currentTab == TAB_BRANDS &&
                                    <>
                                        {dataBrands?.results?.length > 0 ?
                                            <div className="pt-4">
                                                <ul role="list" className="divide-y divide-gray-200">
                                                    {dataBrands?.results?.map((item) => (

                                                        <li className="py-3">
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center  w-full">
                                                                    {
                                                                        item?.carpart__brand__image_url ?
                                                                            <img className="flex-shrink-0 w-10 h-10 cover" src={item?.carpart__brand__image_url} />
                                                                            :
                                                                            <div className="border border-gray-200 bg-gray-100 rounded-md">
                                                                                <p className="text-gray-300 text-xs text-center  h-10 w-10">
                                                                                    No image
                                                                                </p>
                                                                            </div>
                                                                    }
                                                                    <div class="ml-3 overflow-hidden">
                                                                        <div className="font-medium text-gray-900 truncate">
                                                                            {item?.carpart__brand__name}
                                                                        </div>
                                                                        <div class="flex items-center justify-start flex text-sm text-gray-500 space-x-1">
                                                                            <span>{formatNumber(item?.total_sales_count, 0, 0)} sold </span><BsDot />
                                                                            <span className="font-semibold">Ksh. {formatNumber(item?.total_sales, 0, 0)} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )

                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <p className="text-center text-md m-6 text-gray-500">No top brands to display</p>
                                        }
                                    </>
                                }

                                {currentTab == TAB_SEARCHES &&
                                    <>
                                        {dataSearches?.results?.length > 0 ?
                                            <div className="pt-4">
                                                <ul role="list" className="divide-y divide-gray-200">
                                                    {dataSearches?.results?.map((item) => (

                                                        <li className="py-3">
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center  w-full">
                                                                    <div class="ml-3 overflow-hidden">
                                                                        <a className="font-medium text-gray-900 truncate" target="_blank" href={buildSearchString(item?.q)}>
                                                                            {item?.q}
                                                                        </a>
                                                                        <div class="flex items-center justify-start flex text-sm text-gray-500 space-x-1">
                                                                            <span>{formatNumber(item?.total_sessions, 0, 0)} searches </span>                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <p className="text-center text-md m-6 text-gray-500">No top searches to display</p>
                                        }
                                    </>
                                }


                                {currentTab == TAB_B2B &&
                                    <>
                                        {dataBusinesses?.results?.length > 0 ?
                                            <div className="pt-4">
                                                <ul role="list" className="divide-y divide-gray-200">
                                                    {dataBusinesses?.results?.map((item) => (

                                                        <li className="py-3">
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center  w-full">
                                                                    <div class="ml-3 overflow-hidden">
                                                                        <p className="font-medium text-gray-900 truncate">
                                                                            {item?.order__passport__first_name} {item?.order__passport__last_name}
                                                                        </p>
                                                                        <div class="flex items-center justify-start flex text-sm text-gray-500 space-x-1">
                                                                            <span>{formatNumber(item?.total_sales_count, 0, 0)} sold </span><BsDot />
                                                                            <span className="font-semibold">Ksh. {formatNumber(item?.total_sales, 0, 0)} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <p className="text-center text-md m-6 text-gray-500">No top businesses to display</p>
                                        }
                                    </>
                                }

                                {currentTab == TAB_B2C &&
                                    <>
                                        {dataCustomers?.results?.length > 0 ?
                                            <div className="pt-4">
                                                <ul role="list" className="divide-y divide-gray-200">
                                                    {dataCustomers?.results?.map((item) => (

                                                        <li className="py-3">
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center  w-full">
                                                                    <div class="ml-3 overflow-hidden">
                                                                        <p className="font-medium text-gray-900 truncate">
                                                                            {item?.order__passport__first_name} {item?.order__passport__last_name}
                                                                        </p>
                                                                        <div class="flex items-center justify-start flex text-sm text-gray-500 space-x-1">
                                                                            <span>{formatNumber(item?.total_sales_count, 0, 0)} sold </span><BsDot />
                                                                            <span className="font-semibold">Ksh. {formatNumber(item?.total_sales, 0, 0)} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                    )}
                                                </ul>
                                            </div>
                                            :
                                            <p className="text-center text-md m-6 text-gray-500">No top customers to display</p>
                                        }
                                    </>
                                }
                            </>
                            :
                            <></>
                        }


                        <div className="flex items-center justify-center gap-2">
                            {
                                page > 1 &&
                                <a onClick={descreasePage} className="flex items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                                    <svg class="hover:cursor w-3.5 h-3.5 me-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5H1m0 0 4 4M1 5l4-4" />
                                    </svg>
                                    Previous
                                </a>
                            }
                            { perPage * page < count &&
                                <a onClick={increasePage} className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                                    Next
                                    <svg class="hover:cursor w-3.5 h-3.5 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                    </svg>
                                </a>
                            }
                        </div>

                    </div>
                </div>
            }
        </>
    )
}
