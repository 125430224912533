import React from 'react'
import Brand from "../components/cms/Brand/Brand"

function BrandPage() {
  return (
    <div>
      <Brand/>
    </div>
  )
}

export default BrandPage
