import { useEffect, useState } from "react";
import { axiosCustom } from "../../../utils";
import { toast } from "react-toastify";

export default function AddMessageModal({ closeModal, reload, phoneNumbers }) {
  const [message, setMessage] = useState("");

  const handleError = (error) => {
    if (error.response) {
      toast.error("An error occured while sending message!");
    } else if (error.request) {
      toast.error("Something went wrong!");
    } else {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const sendMessage = async ({ data }) => {
    const requestBody = JSON.stringify(data);
    const url = "/oms/customers/sms/";

    try {
      await axiosCustom.post(url, requestBody);
      toast.success("Message sent successfuly!");
      await reload();
    } catch (error) {
      return handleError(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      phone_numbers: phoneNumbers,
    };

    const customerMessage = message.trim();
    if (!customerMessage) {
      toast.error("Kindly write a message");
      return;
    }

    data = { ...data, message: customerMessage };
    await sendMessage({ data });
    closeModal();
  };

  return (
    <>
      <div
        id="defaultModal"
        tabindex="-1"
        aria-hidden="true"
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-full bg-gray-900 bg-opacity-50"
      >
        <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <div class="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
              <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                Write Message
              </h3>
              <button
                type="button"
                onClick={closeModal}
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="grid gap-4 mb-4 sm:grid-cols-2">
                <div class="sm:col-span-2">
                  <label
                    for="message"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Message
                  </label>
                  <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Write your message here"
                  ></textarea>
                </div>
              </div>

              <div className="flex flex-row-reverse">
                <button
                  type="submit"
                  class="text-white inline-flex items-center bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 me-2 text-white group-hover:text-gray-500  :text-gray-300"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
