import React, { useState, useEffect } from 'react'
import Avatar from 'react-avatar';
import Datepicker from "react-tailwindcss-datepicker";
import dayjs from "dayjs";
import { toast, ToastContainer } from 'react-toastify'
import { axiosCustom, formatNumber } from '../utils';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT_STAFF_ORDER_STATS } from '../routes';
import { getName } from '../components/customers/utils';

const STAFF_ORDER_STATS_URL = `/oms/staff-order-stats/`;

function StaffOrderStats() {
    const navigate = useNavigate();
    const [data, setData] = useState()
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [perPage, setPerPage] = useState(60)
    const [processing, setProcessing] = useState(false)
    const [dateRange, setDateRange] = useState({
        startDate: dayjs(new Date()).format("YYYY-MM-DD"),
        endDate: dayjs(new Date()).format("YYYY-MM-DD")
    });

    const handleDateRangeChange = (newValue) => {
        setDateRange(newValue);
    }
    
    const fetchData = async () => {
        setProcessing(true)
        try {
            const params = {
                "page": page,
                "per_page": perPage
            }

            if (dateRange?.startDate && dateRange?.endDate) {
                params.start_date = dateRange.startDate;
                params.end_date = dateRange.endDate
            }

            const searchParams = new URLSearchParams(params).toString();
            const response = await axiosCustom.get(`${STAFF_ORDER_STATS_URL}?${searchParams}`);
            setPage(response?.data?.page)
            setData(response?.data?.results)
            setCount(response?.data?.count)
            setPerPage(response?.data?.per_page)

        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [page, dateRange])

    return (
        <>
            <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">

                {processing ?
                    <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
                        <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
                        <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
                    </div>
                    :
                    <div className="flex flex-col">
                        <div className='mt-10 mb-10 font-bold text-lg'>
                            <h2>Staff</h2>
                        </div>

                        <div className='w-full mb-10'>
                            <div
                                className='w-60 float-right'>
                                <Datepicker
                                    value={dateRange}
                                    onChange={handleDateRangeChange}
                                    showShortcuts={true}
                                />
                            </div>
                        </div>

                        {!processing && !data && !data?.length < 1 ?
                            <section class="bg-white ">
                                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                                    <div class="mx-auto max-w-screen-sm">
                                        <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">No Staff to display.</p>
                                        <p class="mb-4 text-lg font-light text-gray-500 ">Try again later </p>
                                    </div>
                                </div>
                            </section>
                            :
                            <section>
                                <div class="mx-auto max-w-screen-2xl">
                                    <div class="relative overflow-x-auto bg-white border border-slate-200 shadow-md  sm:rounded-lg">
                                        <div class="overflow-x-auto">

                                            {
                                                data && data?.length > 0 ?
                                                    <table class="w-full text-sm text-left text-gray-500">
                                                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                                            <tr>
                                                                <th scope="col" class="px-4 py-3">Name</th>
                                                                <th scope="col" class="px-4 py-3">No. of Sales</th>
                                                                <th scope="col" class="px-4 py-3">Sum of Sales</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.map((item, index) => (
                                                                <tr class="cursor-pointer border-b hover:bg-gray-100" onClick={() => navigate(`${ENDPOINT_STAFF_ORDER_STATS}/${item.pk}`)}>
                                                                    <th scope="row" class="flex items-center px-2 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                                        <div className='flex flex-row'>
                                                                            <Avatar name={getName(item)} size="40" className="mr-2 rounded-full shadow" />
                                                                            <div className='flex flex-col align-middle'>
                                                                                <div className="no-underline font-semibold capitalize">{getName(item)}</div>
                                                                                {
                                                                                    item.email &&
                                                                                    <div className="no-underline text-xs">{item.email}</div>
                                                                                }
                                                                                {
                                                                                    item.phone_number &&
                                                                                    <a href={`tel:+${item.phone_number}`} onClick={e => e.stopPropagation()} className="no-underline hover:underline text-xs">+{item.phone_number}</a>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </th>
                                                                    <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                                        {item?.stats ?
                                                                            <div class="flex items-center">
                                                                                <div className='flex flex-col'>
                                                                                    <span>
                                                                                        {formatNumber(parseInt(item?.stats?.number_of_sales), 0, 0)}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div>-</div>
                                                                        }
                                                                    </td>
                                                                    <td class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap">
                                                                        {item?.stats ?
                                                                            <div class="flex items-center">
                                                                                <div className='flex flex-col'>
                                                                                    <span>
                                                                                        Ksh. {formatNumber(parseInt(item?.stats?.sum_of_sales), 0, 0)}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div>-</div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <p className='font-satoshi text-[18px] py-10 px-2 font-semibold text-center'>No Staff to display</p>
                                            }
                                        </div>

                                        {data && data?.length > 0 &&
                                            <nav class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Table navigation">

                                                <span class="text-sm font-normal text-gray-500">
                                                    Showing
                                                    <span class="font-semibold text-gray-900"> {((page - 1) * perPage) + 1} - {((page - 1) * perPage) + perPage > count ? count : ((page - 1) * perPage) + perPage}</span>
                                                    <span className='m-1'>of</span>
                                                    <span class="font-semibold text-gray-900"> {count} </span>
                                                    <span className='m-1'>results</span>
                                                </span>
                                                <ul class="inline-flex items-stretch -space-x-px">
                                                    <li>
                                                        <button
                                                            disabled={page <= 1}
                                                            onClick={e => setPage(Math.max(1, page - 1))}
                                                            className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">
                                                            <span class="sr-only">Previous</span>
                                                            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                            </svg>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            onClick={e => setPage(1)}
                                                            className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">1</button>
                                                    </li>
                                                    {
                                                        Math.ceil(count / perPage) >= 2 &&
                                                        <li>
                                                            <button
                                                                onClick={e => setPage(2)}
                                                                className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">2</button>
                                                        </li>
                                                    }

                                                    {
                                                        Math.ceil(count / perPage) >= 3 &&
                                                        <li>
                                                            <button
                                                                onClick={e => setPage(3)}
                                                                className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">3</button>
                                                        </li>
                                                    }
                                                    <li>
                                                        <a href="#" class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">...</a>
                                                    </li>
                                                    <li>
                                                        <button
                                                            onClick={e => setPage(Math.ceil(count / perPage))}
                                                            className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">{Math.ceil(count / perPage)}</button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            disabled={page >= Math.ceil(count / perPage)}
                                                            onClick={e => setPage(Math.min(page + 1, Math.ceil(count / perPage)))}
                                                            class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">
                                                            <span class="sr-only">Next</span>
                                                            <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                                            </svg>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        }
                                    </div>
                                </div>
                            </section>
                        }
                    </div>
                }
            </div>

            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}
export default StaffOrderStats
