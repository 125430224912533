import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ENDPOINT_DSF } from '../routes'
import back from '../assets/ic_back.svg'
import { useState } from 'react'
import { makeAuthenticatedRequest } from '../utils'
import Avatar from 'react-avatar'

function DSFsDetailsPage() {
    const { dsfID } = useParams()
    const [dsfData, setDsfData] = useState([])

    const navigate = useNavigate()

    const fetchDsf = (dsfID) => {
      makeAuthenticatedRequest(`/oms/referral-code/${dsfID}`)
        .then((response) => response.json())
        .then((data) => {
          setDsfData(data)
        });
    }
    useEffect(() => {
      fetchDsf(dsfID)
    }, [])

    console.log('dsfData',dsfData)
  return (
        <>
        {dsfData ? 
          <div className=" px-4 md:px-20 flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
            <div className="flex flex-row justify-between">
              <div 
              onClick={() => navigate(-1)}
                className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] rounded-lg">
                <img src={back} alt="" className='w-[30%]' />
                Back
              </div>
              <Link to={`${ENDPOINT_DSF}/${dsfData?.code}/edit`}>
              <button
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>Edit</button>
              </Link>
            </div>
            <div className="flex flex-row gap-6">
        <p className='font-satoshi text-[#030A1A] text-[16px] md:text-[18px] font-semibold'>Referral code #{dsfData?.code}</p>
        <p className='font-satoshi text-[#030A1A] text-[16px] md:text-[18px]'>Commission: {dsfData?.commission}%</p>

        
    </div>

    <div className="flex flex-col md:flex-row gap-4 w-full ">


        <div className="flex flex-col w-full border rounded-lg gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Referee details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4">
            <div className="">
                <Avatar name={dsfData?.referer_name} size={50} round={true} />
            </div>
            <div className="flex-1 flex flex-col gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{dsfData?.referer_name}</p>

        <div className="flex flex-row gap-1">
            <div className="border border-red-400 rounded-full p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-red-400 w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>

            </div>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{dsfData?.phone_number}</p>

        </div>

                
            </div>

        </div>

        
        </div>

          {dsfData?.sales &&

        <div className="flex flex-col w-full border rounded-lg gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Sales representative details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4">
            <div className="">
                <Avatar name={dsfData?.sales?.email} size={50} round={true} />
            </div>
            <div className="flex-1 flex flex-col gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{dsfData?.sales?.email}</p>



                
            </div>

        </div>

        
        </div>
          }
    </div>

    <div className="flex flex-col gap-2 px-2 border rounded-md">
            <p className='font-satoshi text-[#999DA8]  px-2 text-[16px] md:text-[16px]'>Orders</p>
            <div className="border-b w-full border-gray-200"></div>

              {dsfData?.orders?.map((order) => (
                <>
                
 
            
                    <div className="flex flex-row gap-2 justify-between">
                            <div className="flex flex-col gap-1">
                            <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Order # {order.order_number}</p>
            
            
            
                            </div>
            
                           
                          <div className="flex flex-col gap-1">
                            <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Amount to be paid</p>
                        <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>{order.amount}</p>
                            </div>
                            <div className="flex flex-col gap-1">
                            <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Order total</p>
                        <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>{order.order_amount}</p>
                            </div>
                            <div className="flex flex-col gap-1">
                            <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Status</p>
                        <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>{order.order_status}</p>
                            </div>
            
                        </div>
            
                    <div className="border-b border-b-gray-200"></div>
                </>
              ))}




            </div>
    </div>
    :
    <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
    <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
    <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
  </div>
}
    </>
  )
}

export default DSFsDetailsPage
