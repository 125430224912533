import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { formatDateAndTimeAgo, formatNumber, makeAuthenticatedRequest } from '../utils';
import { Pagination } from './Pagination';
import { BsDot } from 'react-icons/bs';
import { ENDPOINT_LPOS } from '../routes';

const BClientLpos = ({clientId}) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
    const [currentPage, setCurrentPage] = useState(currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)
    const [lpos, setLpos] = useState([])
    const [perPage, setPerPage] = useState(20)
    const[pages, setPages] = useState(0)
    const [itemCount, setItemCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [processing, setProcessing] = useState(false)
  
    const fetchLpos = (page, clientID) => {
        setProcessing(true)
      const params = {
        "s": search,
        "page": page,
        "per_page": perPage,
      }
      const searchParams = new URLSearchParams(params).toString();
      makeAuthenticatedRequest(`/oms/btobclients/${clientID}/lpos/?${searchParams}`)
        .then((response) => response.json())
        .then((data) => {
          console.log('rfq data',data)
          setLpos(data)
          setCurrentPage(data?.page)
          setPerPage(data?.per_page)
          setPages(data?.number_of_pages)
          setItemCount(data?.count)
          setTotalItems(data?.count)
          setProcessing(false)
        }).catch((error) => {
          setProcessing(false)
          console.log(error)
    })
    }
    
    const pageChanged = (pageNumber) => {
      // console.log('called')
      currentUrlParams.set('page', pageNumber)
      setCurrentPage(Number(currentUrlParams.get('page')))
      setUrlParams(currentUrlParams)    
      fetchLpos(Number(currentUrlParams.get('page')))
    }
    
    useEffect(() => {
      fetchLpos(currentPage, clientId)
    }, [currentPage, clientId])

    const totalSum = lpos?.results?.reduce((sum, lpo) => {
        const lpoPartSum = lpo?.lpo_parts.reduce((partSum, lpoPart) => partSum + parseFloat(lpoPart.total), 0);
        return sum + lpoPartSum;
      }, 0);

      const calculateOrderSum = (order) => {
        return order.lpo_parts.reduce((sum, part) => sum + parseFloat(part.total), 0);
      };
  return (
    <>
                  {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }
    {
  lpos?.results?.length >0 ?
    <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
      <table class="table-auto w-full  ">
        <thead className='pl-2'>
          <tr className='pl-1 border-b border-gray-200'>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Lpo #</th>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Parts count</th>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Lpo value</th>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Order date</th>
            <th className='pl-2 pb-1 font-satoshi font-light text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Status</th>
          </tr>
        </thead>
        <tbody>
          {
            lpos?.results?.slice().reverse().map((order) => (
              <tr className='p-2 border-b border-b-gray-200 relative'>
                <td className='pl-2'>
                  <Link to={`${ENDPOINT_LPOS}/${order?.id}`}>
                  #{order?.id}
                  </Link>
                </td>
                <td
                  className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                 {order?.lpo_parts?.length}
                </td>
                <td className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
               
                Ksh. {formatNumber(calculateOrderSum(order), 0, 0)}
                  </td>
                <td className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                 {formatDateAndTimeAgo(order?.date_created)}
                </td>
                
                <td className={`p-4 text-xs md:text-[16px] text-left font-satoshi capitalize`}>
                  <div className={`text-center px-4 py-1.5 rounded-md ${order.status === 'New' ?
                'bg-[#B9E38D]'
                  : order.status === 'Cancelled' ?
                    'bg-[#DE6972]'
                    : order.status === 'Quote sent' ?
                      'bg-[#54D3CA]'
                      : order.status === 'LPO sent' ?
                        'bg-[#8CD1FC]'
                        : null}`}>

                  {order?.status}
                        </div>
                </td>

              </tr>


            ))}



        </tbody>
      </table>
        {/* pagination */}
      
    </div>

   :
   <div className="flex flex-col justify-center items-center">
    <p>Client does not have any LPOs at the moment</p>
   </div>
   }
</>
  )
}

export default BClientLpos