import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { PartCondition, axiosCustom, formatNumber } from '../../utils';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import { useNavigate, useParams } from 'react-router-dom';
import { CUSTOMERS_TAB_PART_REQUESTS, ENDPOINT_CUSTOMERS } from '../../routes';
import AddCarModal from './modals/AddCarModal';
import SelectCarModal from './modals/SelectCarModal';


const CUSTOMERS_URL = `/oms/customers/`;
const VEHICLES_URL = `/oms/vehicles/`;
const MERCHANTS_URL = `/oms/merchants/`;


function AddPartRequestTab() {
    const navigate = useNavigate()
    const { customerID } = useParams()
    const [yom, setYom] = useState()
    const [partName, setPartName] = useState()
    const [quantity, setQuantity] = useState(1)
    const [vehicleData, setVehicleData] = useState([])
    const [selectedCar, setSelectedCar] = useState()
    const [processing, setProcessing] = useState(false)
    const [partCondition, setPartCondition] = useState()
    const [loadingCars, setLoadingCars] = useState(false)
    const [partDescription, setPartDescription] = useState()
    const [showAddCarModal, setShowAddCarModal] = useState()
    const [selectedMerchants, setSelectedMerchants] = useState()
    const [loadingMerchants, setLoadingMerchants] = useState(false)


    const partCondtionChanged = (event) => {
        setPartCondition(event.target.value);
    }

    const handleSelectedCar = (car) => {
        setSelectedCar(car)
    }

    const getMerchants = async (inputValue) => {
        const data = []
        const params = { s: inputValue ? inputValue : "" }
        const searchParams = new URLSearchParams(params).toString();
        setLoadingMerchants(true)
        try {
            const response = await axiosCustom.get(`${MERCHANTS_URL}?${searchParams}`);
            response?.data?.results?.map((item, i) => {
                data.push({ value: item?.id, label: item?.merchant_name })
            })
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setLoadingMerchants(false)
        }
        return data
    }

    const getCars = async (inputValue) => {
        const data = []
        const params = { s: inputValue ? inputValue : "" }
        const searchParams = new URLSearchParams(params).toString();
        setLoadingCars(true)
        try {
            const response = await axiosCustom.get(`${CUSTOMERS_URL}${customerID}/cars/?${searchParams}`);
            response?.data?.results?.map((item, i) => {
                const label = item?.make + " " + item?.model + " " + item?.engine + " " + item?.year + " " + item?.chasis + " " + item?.number_plate
                data.push({ value: item?.id, label: label })
            })
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setLoadingCars(false)
        }
        return data
    }


    const submitData = async () => {
        setProcessing(true)
        const merchants = []
        selectedMerchants?.map(item => {
            merchants.push(item?.value)
        })
        if (!partCondition) {
            toast.error("You must select a part condition")
            return
        }
        try {
            const params = {
                name: partName,
                quantity: quantity,
                condition: PartCondition.DISPLAY(partCondition),
                description: partDescription,
            }
            if(selectedCar){
                params['tagged_vehicle'] = selectedCar.id
            }
            if(merchants && merchants?.length > 0){
                params['tagged_merchants'] = merchants
            }


            const response = await axiosCustom.post(`${CUSTOMERS_URL}${customerID}/part-requests/`, JSON.stringify(params));
            navigate(`${ENDPOINT_CUSTOMERS}/${customerID}/${CUSTOMERS_TAB_PART_REQUESTS}`);
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }
    const handleModalClose = () => {
        setShowAddCarModal(false)
    }

    const handleReloadCars = async () => {
        handleModalClose()
        //await fetchDataCars()
    }


    useEffect(() => {
        getCars()
        getMerchants()
    }, [])

    return (
        <>
            {
                showAddCarModal &&
                <SelectCarModal
                    customerID={customerID}
                    handleModalClose={handleModalClose}
                    handleSelectedCar={handleSelectedCar}
                />
            }
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div class="mx-auto max-w-screen-2xl">
                        <div class="relative p-4 w-full max-w-2xl max-h-full">
                            <div class="relative bg-white rounded-lg shadow ">
                                <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
                                    <h3 class="text-xl font-semibold text-gray-900 ">
                                        Add Customer Part Request
                                    </h3>
                                </div>
                                <div class="p-4 md:p-5">
                                    <form className="flex flex-col w-full gap-4 text-[14px]" onSubmit={submitData}>
                                        <div className="flex flex-col gap-4 w-full">
                                            <div className='flex gap-4 md:flex-row flex-col'>
                                                <div className='w-full flex flex-[2] flex-col'>
                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label
                                                                htmlFor="chasis" className="block text-[14px] text-black font-semibold">
                                                                Part Name <span className='text-red-600' >*</span>
                                                            </label>
                                                            <input type="text" name="chasis" id="chasis"
                                                                value={partName}
                                                                required
                                                                onInvalid={(e) => e.target.setCustomValidity("Please add a valid part name")}
                                                                onInput={e => e.target.setCustomValidity('')}
                                                                onChange={(e) => setPartName(e.target.value)}
                                                                placeholder='e.g. Spark Plugs'
                                                                className="mt-1 block w-full p-2 outline-none rounded-md border placeholder:text-[12px]" />
                                                        </div>
                                                    </div>
                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label
                                                                htmlFor="chasis" className="block text-[14px] text-black font-semibold">
                                                                Part Description <span className='text-red-600' >*</span>
                                                            </label>
                                                            <textarea type="text" name="chasis" id="chasis"
                                                                value={partDescription}
                                                                required
                                                                onInvalid={(e) => e.target.setCustomValidity("Please add a valid part description")}
                                                                onInput={e => e.target.setCustomValidity('')}
                                                                onChange={(e) => setPartDescription(e.target.value)}
                                                                placeholder='e.g. Spark Plugs for Suzuki Alto'
                                                                className="mt-1 block w-full p-2 outline-none rounded-md border placeholder:text-[12px]" />
                                                        </div>
                                                    </div>

                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label
                                                                htmlFor="chasis" className="block text-[14px] text-black font-semibold">
                                                                Quantity <span className='text-red-600' >*</span>
                                                            </label>
                                                            <input type="number" name="chasis" id="chasis"
                                                                value={quantity}
                                                                min={1}
                                                                max={1000000000}
                                                                required
                                                                onChange={(e) => setQuantity(e.target.value)}
                                                                className="mt-1 block w-full p-2 outline-none rounded-md border placeholder:text-[12px]" />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col py-2">
                                                        <label htmlFor="part_condition" className="text-[14px] text-black font-semibold">Part Condition <span className='text-red-600' >*</span></label>
                                                        <div className='flex flex-row'>
                                                            <div className="flex flex-row mt-1 mr-3">
                                                                <input
                                                                    checked={partCondition === PartCondition.BRAND_NEW}
                                                                    onChange={partCondtionChanged}
                                                                    name="part_condition" type="radio" value={PartCondition.BRAND_NEW} className="mr-1" />
                                                                <span>Brand new</span>
                                                            </div>

                                                            <div className="flex flex-row mt-1 mr-3">
                                                                <input
                                                                    checked={partCondition === PartCondition.REFURBISHED}
                                                                    onChange={partCondtionChanged}
                                                                    name="part_condition" type="radio" value={PartCondition.REFURBISHED} className="mr-1" />
                                                                <span>Refurbished</span>
                                                            </div>

                                                            <div className="flex flex-row mt-1 mr-3">
                                                                <input
                                                                    checked={partCondition === PartCondition.SECOND_HAND}
                                                                    onChange={partCondtionChanged}
                                                                    name="part_condition" type="radio" value={PartCondition.SECOND_HAND} className="mr-1" />
                                                                <span>Used</span>
                                                            </div>

                                                            <div className="flex flex-row mt-1 mr-3">
                                                                <input
                                                                    checked={partCondition === PartCondition.ANY}
                                                                    onChange={partCondtionChanged}
                                                                    name="part_condition" type="radio" value={PartCondition.ANY} className="mr-1" />
                                                                <span>Any</span>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="make" className="text-[14px] text-black font-semibold">
                                                                Tag a Car
                                                            </label>
                                                            <div
                                                                onClick={() => setShowAddCarModal(true)}
                                                                className='w-full border border-gray-300 outline-none bg-gray-300 p-2 flex flex-row text-black mt-1 rounded'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-black w-4 h-4 mr-1 mt-1">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                                </svg>
                                                                <span>Tag your car</span>
                                                            </div>
                                                            {selectedCar &&
                                                                <div
                                                                    className={`mt-2 mb-2 flex items-center gap-2 w-full cursor-pointer border p-2 rounded-sm bg-white'}`}>
                                                                    <img src={selectedCar?.photo_url} alt="" className='rounded w-[50px] h-[50px] object-cover border' />
                                                                    <div className='flex flex-col'>
                                                                        <p className="text-[#030A1A] font-satoshi text-[16px] font-semibold">{selectedCar?.make} {selectedCar?.model} {selectedCar?.year}</p>
                                                                        <small>{selectedCar?.number_plate}</small>
                                                                        <small>{selectedCar?.chasis}</small>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='flex w-full gap-3 md:flex-row flex-col'>
                                                        <div className="flex flex-col py-2 w-full">
                                                            <label htmlFor="make" className="text-[14px] text-black font-semibold">
                                                                Tag a merchant(s)
                                                            </label>
                                                            <AsyncSelect
                                                                isMulti
                                                                className='w-full outline-none'
                                                                isLoading={loadingMerchants}
                                                                loadOptions={getMerchants}
                                                                placeholder="Search"
                                                                name="tagged_merchants"
                                                                onChange={e => setSelectedMerchants(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex py-3 justify-end">
                                                <div className="flex flex-row-reverse gap-4">
                                                    <button type='submit' className='bg-[#030A1A] text-[16px] text-white border border-[#030A1A] px-3 py-1 rounded-md'>Submit</button>
                                                </div>
                                            </div>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </section>
            }
        </>
    )
}
export default AddPartRequestTab