import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function HitsOverTimeGraph({ labels, hits }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        ticks: { precision: 0 },
        min: 0,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Hits",
        data: hits,
        borderColor: "#E8795C",
        backgroundColor: "#DC806433",
        borderWidth: 2,
        pointRadius: 3,
        fill: true,
        tension: 0.2
      },
    ],
  };
  return (
    <div className="">
      <Line options={options} data={data} />
    </div>
  );
}
export default HitsOverTimeGraph;
