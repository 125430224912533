import React from 'react'

import ProductAdd from '../components/cms/Product/ProductAdd'

function ProductAddPage() {
  return (
    <div>
        <ProductAdd/>
    </div>
  )
}

export default ProductAddPage