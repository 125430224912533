import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'

function HeroView() {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
            <div className="flex flex-row justify-between">
                <div
                    onClick={() => navigate(-1)}
                    className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-4 mt-0.5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                        />
                    </svg>
                    Back
                </div>
            </div>
            <div className="w-full flex flex-col gap-2">
                <div className="flex flex-col md:flex-row gap-6 w-full font-satoshi">
                    <div className="flex flex-col gap-2 w-full md:w-[70%]">
                        <div className="flex flex-col w-full gap-1">
                            <label htmlFor="" className='font-satoshi text-gray-400 font-semibold'  >Title</label>
                            <input type="text" className='rounded-md px-4 py-2.5 border' value='Super' />
                        </div>
                        <div className="flex flex-col w-full gap-2 bg-white rounded-lg mt-4 mb-2 h-72 w-full border object-contain">
                            <img src='/cdn/images/loading.gif' className='object-contain rounded h-72' alt="" />
                        </div>


                        <div className='flex flex-col w-full gap-2 border-[#F77B55] rounded-md p-2'>
                            <p className='font-semibold text-[#333B51] font-satoshi'>Tags</p>
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="rounded-md border px-4 py-1.5 flex flex-row gap-2 justify-between">
                                    <p className='font-satoshi text-[14px] '>Tags - Names</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 mt-1">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            </div>
                            <div className="py-3">
                                <div className='font-semibold text-[#333B51] font-satoshi'>
                                    Activate Section
                                </div>
                                
                                <div className='text-gray-500 font-bold text-sm gap-2 mr-4'>
                                    <span className='float-left'>
                                        <label htmlFor="push-green-toggle" className="inline-flex relative items-center mr-5 cursor-pointer">

                                            <input
                                                onChange={() => setIsActive(!isActive)}
                                                checked={isActive}
                                                type="checkbox"
                                                value=""
                                                id="push-green-toggle"
                                                className="sr-only peer"
                                            />

                                            <div className="w-11 h-6 bg-gray-200 rounded-full  peer-focus:ring-3
                                            peer-focus:ring-green-300 :ring-green-800 peer-checked:after:translate-x-full after:absolute after:top-0.5 after:left-[2px]
                                            after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all
                                            peer-checked:bg-green-600">
                                            </div>
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HeroView
