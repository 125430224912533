import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  Outlet,
  useParams,
  useLocation,
  Link,
  useNavigate,
} from "react-router-dom";
import {
  CUSTOMERS_TAB_ACTIVITY,
  CUSTOMERS_TAB_BOOKINGS,
  CUSTOMERS_TAB_FAVORITES,
  CUSTOMERS_TAB_ORDERS,
  CUSTOMERS_TAB_PART_REQUESTS,
  CUSTOMERS_TAB_PAYMENTS,
  CUSTOMERS_TAB_PROFILE,
  CUSTOMERS_TAB_SERVICES,
  CUSTOMERS_TAB_TICKETS,
  ENDPOINT_CUSTOMERS,
  CUSTOMERS_TAB_MESSAGES,
} from "../../routes";
import more from "../../assets/ic_more.svg";
import { axiosCustom } from "../../utils";
import FlagUnflagModal, { FLAG, UNFLAG } from "./modals/FlagUnflagModal";

const CUSTOMERS_URL = `/oms/customers/`;

function CustomerLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { customerID } = useParams();

  const [data, setData] = useState();
  const [processing, setProcessing] = useState(false);
  const [currentTab, setCurrentTab] = useState();
  const [showDropDownOptions, setShowDropDownOptions] = useState(false);
  const [showFlagginModal, setShowFlagginModal] = useState(false);
  const [flaggingAction, setFlaggingAction] = useState(FLAG);

  const changeCurrentPath = () => {
    const { pathname } = location;
    let tab = CUSTOMERS_TAB_PROFILE;
    if (pathname && pathname.includes(customerID)) {
      tab = pathname.split(customerID)[1].split("/")[1];
    }
    if (tab) setCurrentTab(tab);
    else setCurrentTab(CUSTOMERS_TAB_PROFILE);
  };

  const navigateBack = () => {
    const { pathname } = location;
    const profileHome = `${ENDPOINT_CUSTOMERS}/${customerID}/`;

    if (pathname == profileHome) {
      navigate(ENDPOINT_CUSTOMERS);
    } else {
      const pathSplit = pathname.split("/");
      pathSplit.pop();
      navigate(pathSplit.join("/"));
    }
  };

  const handleReload = () => {
    handleModalClose();
    window.location.reload(false);
  };

  const handleModalClose = () => {
    setShowFlagginModal(false);
  };

  const fetchData = async () => {
    setProcessing(true);
    try {
      const response = await axiosCustom.get(`${CUSTOMERS_URL}${customerID}/`);
      setData(response?.data);
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setProcessing(false);
    }
  };

  const submitData = async (e, params) => {
    e.preventDefault();
    setProcessing(true);
    try {
      await axiosCustom.post(
        `${CUSTOMERS_URL}${customerID}/`,
        JSON.stringify(params)
      );
      window.location.reload(false);
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setProcessing(false);
    }
  };
  const performFlagging = async (action) => {
    setFlaggingAction(action);
    setShowFlagginModal(true);
    setShowDropDownOptions(false);
  };

  useEffect(() => {
    changeCurrentPath();
    fetchData();
  }, [location]);

  return (
    <>
      {showFlagginModal && (
        <FlagUnflagModal
          handleModalClose={handleModalClose}
          customerID={customerID}
          handleReload={handleReload}
          action={flaggingAction}
        />
      )}
      {processing ? (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img
            src="/cdn/images/loading.gif"
            className="mr-3 cursor-pointer  h-24"
            alt="Sparepap Logo"
          />
          <h2 class="text-center text-white text-xl font-semibold">
            Processing...
          </h2>
        </div>
      ) : (
        <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
          <div class="flex items-center justify-between relative">
            <div className="none mr-2 flex flex-row justify-between mb-2">
              <button
                onClick={() => navigateBack()}
                className="bg-gray-200 border rounded-md px-2 py-1 gap-1 border-gray-200 text-center font-satoshi text-sm flex flex-row"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <g id="ic_back" transform="translate(-103 -49)">
                    <rect
                      id="Rectangle_561"
                      data-name="Rectangle 561"
                      width="20"
                      height="20"
                      transform="translate(103 49)"
                      fill="none"
                      opacity="0"
                    />
                    <g
                      id="Group_1699"
                      data-name="Group 1699"
                      transform="translate(108 54.625)"
                    >
                      <path
                        id="Path_1149"
                        data-name="Path 1149"
                        d="M-1037.5,2208l-2.5,2.5,2.5,2.5"
                        transform="translate(1040 -2208)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                      <path
                        id="Path_1150"
                        data-name="Path 1150"
                        d="M-1035,2217.625h2.188a2.813,2.813,0,0,0,2.813-2.812h0a2.813,2.813,0,0,0-2.812-2.812H-1040"
                        transform="translate(1040 -2209.5)"
                        fill="none"
                        stroke="#000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </g>
                  </g>
                </svg>
                Back
              </button>
            </div>
            <button
              onClick={() => setShowDropDownOptions(!showDropDownOptions)}
              type="button"
              class="mb-1 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white"
              data-modal-hide="authentication-modal"
            >
              <img
                // onMouseEnter={() => setShow(part.id)}
                // onMouseLeave={() => setShow(-1)}
                // onClick={() => toggleOpen(part.id)}
                src={more}
                alt=""
                className="float-left md:float-right cursor-pointer"
              />
            </button>

            {showDropDownOptions && (
              <div
                className={` border border-gray-200 w-40 top-5 mt-2 bg-white z-50 absolute right-4 rounded-md shadow-md p-0`}
              >
                {data?.is_hot_lead ? (
                  <div
                    onClick={(e) => submitData(e, { is_hot_lead: false })}
                    className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                  >
                    <p className="font-satoshi text-xs">Unmark as hot lead</p>
                  </div>
                ) : (
                  <div
                    onClick={(e) => submitData(e, { is_hot_lead: true })}
                    className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                  >
                    <p className="font-satoshi text-xs">Mark as hot lead</p>
                  </div>
                )}

                {data?.is_btob_client ? (
                  <div
                    onClick={(e) => submitData(e, { is_btob_client: false })}
                    className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                  >
                    <p className="font-satoshi text-xs text-[#BF3054]">
                      Unmark as B2B
                    </p>
                  </div>
                ) : (
                  <div
                    onClick={(e) => submitData(e, { is_btob_client: true })}
                    className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                  >
                    <p className="font-satoshi text-xs text-[#BF3054]">
                      Mark as B2B
                    </p>
                  </div>
                )}

                {data?.is_flagged ? (
                  <div
                    onClick={(e) => performFlagging(UNFLAG)}
                    className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                  >
                    <p className="font-satoshi text-xs text-[#BF3054]">
                      Unflag customer
                    </p>
                  </div>
                ) : (
                  <div
                    onClick={(e) => performFlagging(FLAG)}
                    className="py-2 px-6 flex flex-row gap-2 hover:cursor-pointer hover:bg-slate-50"
                  >
                    <p className="font-satoshi text-xs text-[#BF3054]">
                      Flag customer
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="flex flex-col">
            <div class="border-b border-gray-200 ">
              <ul class="flex flex-wrap mb-[-3px] text-sm font-medium text-center text-gray-500 ">
                <li class="me-2">
                  {currentTab == CUSTOMERS_TAB_PROFILE ? (
                    <Link
                      to={CUSTOMERS_TAB_PROFILE}
                      className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active group"
                      aria-current="page"
                    >
                      <svg
                        className="w-4 h-4 me-2 text-blue-600 "
                        viewBox="0 0 19 20"
                        fill="#2563eb"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                      <span className="ml-1 font-semibold">Profile</span>
                    </Link>
                  ) : (
                    <Link
                      to={CUSTOMERS_TAB_PROFILE}
                      className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                    >
                      <svg
                        class="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                      </svg>
                      <span className="ml-1 font-semibold">Profile</span>
                    </Link>
                  )}
                </li>
                <li class="me-2">
                  {currentTab == CUSTOMERS_TAB_ORDERS ? (
                    <Link
                      to={CUSTOMERS_TAB_ORDERS}
                      className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active   group"
                      aria-current="page"
                    >
                      <svg
                        className="w-4 h-4 me-2 text-blue-600 "
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.6 0.399994C3.38783 0.400015 3.18436 0.484307 3.03434 0.634332C2.88432 0.784356 2.80002 0.987827 2.8 1.19999V14.8H1.2C0.987835 14.8 0.784364 14.8843 0.63434 15.0343C0.484315 15.1844 0.400023 15.3878 0.400002 15.6C0.400002 17.7996 2.20039 19.6 4.4 19.6H14.8C14.8266 19.5998 14.8532 19.5982 14.8797 19.5953C17.0426 19.5521 18.8 17.7727 18.8 15.6V1.19999C18.8 0.987827 18.7157 0.784356 18.5657 0.634332C18.4156 0.484307 18.2122 0.400015 18 0.399994H3.6ZM4.4 1.99999H17.2V15.6C17.2 16.9348 16.1348 18 14.8 18C13.4652 18 12.4 16.9348 12.4 15.6C12.4 15.3878 12.3157 15.1844 12.1657 15.0343C12.0156 14.8843 11.8122 14.8 11.6 14.8H4.4V1.99999ZM6.8 4.39999V5.99999H8.4V4.39999H6.8ZM10 4.39999V5.99999H14.8V4.39999H10ZM6.8 7.59999V9.19999H8.4V7.59999H6.8ZM10 7.59999V9.19999H14.8V7.59999H10ZM6.8 10.8V12.4H8.4V10.8H6.8ZM10 10.8V12.4H14.8V10.8H10Z"
                          fill="#2563eb"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Orders</span>
                    </Link>
                  ) : (
                    <Link
                      to={CUSTOMERS_TAB_ORDERS}
                      className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                    >
                      <svg
                        class="w-4 h-4 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M3.6 0.399994C3.38783 0.400015 3.18436 0.484307 3.03434 0.634332C2.88432 0.784356 2.80002 0.987827 2.8 1.19999V14.8H1.2C0.987835 14.8 0.784364 14.8843 0.63434 15.0343C0.484315 15.1844 0.400023 15.3878 0.400002 15.6C0.400002 17.7996 2.20039 19.6 4.4 19.6H14.8C14.8266 19.5998 14.8532 19.5982 14.8797 19.5953C17.0426 19.5521 18.8 17.7727 18.8 15.6V1.19999C18.8 0.987827 18.7157 0.784356 18.5657 0.634332C18.4156 0.484307 18.2122 0.400015 18 0.399994H3.6ZM4.4 1.99999H17.2V15.6C17.2 16.9348 16.1348 18 14.8 18C13.4652 18 12.4 16.9348 12.4 15.6C12.4 15.3878 12.3157 15.1844 12.1657 15.0343C12.0156 14.8843 11.8122 14.8 11.6 14.8H4.4V1.99999ZM6.8 4.39999V5.99999H8.4V4.39999H6.8ZM10 4.39999V5.99999H14.8V4.39999H10ZM6.8 7.59999V9.19999H8.4V7.59999H6.8ZM10 7.59999V9.19999H14.8V7.59999H10ZM6.8 10.8V12.4H8.4V10.8H6.8ZM10 10.8V12.4H14.8V10.8H10Z"
                          fill="#9ca3af"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Orders</span>
                    </Link>
                  )}
                </li>
                <li class="me-2">
                  {currentTab == CUSTOMERS_TAB_SERVICES ? (
                    <Link
                      to={CUSTOMERS_TAB_SERVICES}
                      className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active   group"
                      aria-current="page"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-blue-600 "
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.4 0.399994C2.7488 0.399994 0.599998 2.54879 0.599998 5.19999C0.599998 6.97599 1.5664 8.52272 3 9.35312V17.2C3 18.5256 4.0744 19.6 5.4 19.6C6.7256 19.6 7.8 18.5256 7.8 17.2V9.35312C9.2336 8.52272 10.2 6.97599 10.2 5.19999C10.2 4.49599 10.0454 3.82896 9.77344 3.22656L7 5.99999H6.2H4.6V3.59999L7.37344 0.826556C6.77104 0.554556 6.104 0.399994 5.4 0.399994ZM11.5859 6.79999C11.4387 7.36239 11.2154 7.89999 10.925 8.39999H12.4859C13.2899 8.39999 14.0364 8.79914 14.4828 9.46875L15.3891 10.8266C15.3358 10.8189 15.2811 10.8074 15.2281 10.8H9.4L9.44062 17.2H15.2844C15.7378 18.1402 16.6935 18.8 17.8 18.8C19.0338 18.8 20.0778 17.9803 20.4469 16.8641L21.4 16.4V13.2766C21.4 12.5262 20.8797 11.8749 20.1469 11.7141C19.4563 11.5623 18.5348 11.3751 17.5562 11.1922L15.8156 8.58124C15.0716 7.46604 13.8283 6.79999 12.4875 6.79999H11.5859ZM17.8 14.8C18.4722 14.8 19 15.3278 19 16C19 16.6722 18.4722 17.2 17.8 17.2C17.1278 17.2 16.6 16.6722 16.6 16C16.6 15.3278 17.1278 14.8 17.8 14.8ZM5.4 16.4C5.8416 16.4 6.2 16.7584 6.2 17.2C6.2 17.6416 5.8416 18 5.4 18C4.9584 18 4.6 17.6416 4.6 17.2C4.6 16.7584 4.9584 16.4 5.4 16.4Z"
                          fill="#2563eb"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Services</span>
                    </Link>
                  ) : (
                    <Link
                      to={CUSTOMERS_TAB_SERVICES}
                      className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.4 0.399994C2.7488 0.399994 0.599998 2.54879 0.599998 5.19999C0.599998 6.97599 1.5664 8.52272 3 9.35312V17.2C3 18.5256 4.0744 19.6 5.4 19.6C6.7256 19.6 7.8 18.5256 7.8 17.2V9.35312C9.2336 8.52272 10.2 6.97599 10.2 5.19999C10.2 4.49599 10.0454 3.82896 9.77344 3.22656L7 5.99999H6.2H4.6V3.59999L7.37344 0.826556C6.77104 0.554556 6.104 0.399994 5.4 0.399994ZM11.5859 6.79999C11.4387 7.36239 11.2154 7.89999 10.925 8.39999H12.4859C13.2899 8.39999 14.0364 8.79914 14.4828 9.46875L15.3891 10.8266C15.3358 10.8189 15.2811 10.8074 15.2281 10.8H9.4L9.44062 17.2H15.2844C15.7378 18.1402 16.6935 18.8 17.8 18.8C19.0338 18.8 20.0778 17.9803 20.4469 16.8641L21.4 16.4V13.2766C21.4 12.5262 20.8797 11.8749 20.1469 11.7141C19.4563 11.5623 18.5348 11.3751 17.5562 11.1922L15.8156 8.58124C15.0716 7.46604 13.8283 6.79999 12.4875 6.79999H11.5859ZM17.8 14.8C18.4722 14.8 19 15.3278 19 16C19 16.6722 18.4722 17.2 17.8 17.2C17.1278 17.2 16.6 16.6722 16.6 16C16.6 15.3278 17.1278 14.8 17.8 14.8ZM5.4 16.4C5.8416 16.4 6.2 16.7584 6.2 17.2C6.2 17.6416 5.8416 18 5.4 18C4.9584 18 4.6 17.6416 4.6 17.2C4.6 16.7584 4.9584 16.4 5.4 16.4Z"
                          fill="#9ca3af"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Services</span>
                    </Link>
                  )}
                </li>
                <li class="me-2">
                  {currentTab == CUSTOMERS_TAB_BOOKINGS ? (
                    <Link
                      to={CUSTOMERS_TAB_BOOKINGS}
                      className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active   group"
                      aria-current="page"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-blue-600 "
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          class="bentblocks_een"
                          d="M4,10h6V4H4V10z M6,6h2v2H6V6z M28,6v2H14V6H28z M4,19h6v-6H4V19z M6,15h2v2H6V15z M14,15h14v2H14
	V15z M4,28h6v-6H4V28z M6,24h2v2H6V24z M14,24h14v2H14V24z"
                          fill="#2563eb"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Bookings</span>
                    </Link>
                  ) : (
                    <Link
                      to={CUSTOMERS_TAB_BOOKINGS}
                      className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        viewBox="0 0 22 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          class="bentblocks_een"
                          d="M4,10h6V4H4V10z M6,6h2v2H6V6z M28,6v2H14V6H28z M4,19h6v-6H4V19z M6,15h2v2H6V15z M14,15h14v2H14
	V15z M4,28h6v-6H4V28z M6,24h2v2H6V24z M14,24h14v2H14V24z"
                          fill="#9ca3af"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Bookings</span>
                    </Link>
                  )}
                </li>
                <li class="me-2">
                  {currentTab == CUSTOMERS_TAB_PART_REQUESTS ? (
                    <Link
                      to={CUSTOMERS_TAB_PART_REQUESTS}
                      className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active   group"
                      aria-current="page"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-blue-600 "
                        viewBox="0 0 22 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.4 0.200001C9.29399 0.198501 9.18874 0.218086 9.09037 0.257617C8.99199 0.297148 8.90246 0.355837 8.82696 0.430273C8.75146 0.504708 8.69152 0.593406 8.6506 0.691212C8.60968 0.789018 8.58861 0.893981 8.58861 1C8.58861 1.10602 8.60968 1.21098 8.6506 1.30879C8.69152 1.4066 8.75146 1.49529 8.82696 1.56973C8.90246 1.64416 8.99199 1.70285 9.09037 1.74238C9.18874 1.78191 9.29399 1.8015 9.4 1.8H12.6C12.706 1.8015 12.8113 1.78191 12.9096 1.74238C13.008 1.70285 13.0975 1.64416 13.173 1.56973C13.2485 1.49529 13.3085 1.4066 13.3494 1.30879C13.3903 1.21098 13.4114 1.10602 13.4114 1C13.4114 0.893981 13.3903 0.789018 13.3494 0.691212C13.3085 0.593406 13.2485 0.504708 13.173 0.430273C13.0975 0.355837 13.008 0.297148 12.9096 0.257617C12.8113 0.218086 12.706 0.198501 12.6 0.200001H9.4ZM11 3.4C5.9952 3.4 1.88623 7.22919 1.44063 12.1172C1.39743 12.5892 1.76703 13 2.24063 13H19.7594C20.233 13 20.6026 12.5892 20.5594 12.1172C20.1138 7.22919 16.0048 3.4 11 3.4ZM1.4 14.6C1.29399 14.5985 1.18874 14.6181 1.09037 14.6576C0.991993 14.6971 0.902456 14.7558 0.82696 14.8303C0.751464 14.9047 0.691515 14.9934 0.650597 15.0912C0.609679 15.189 0.588608 15.294 0.588608 15.4C0.588608 15.506 0.609679 15.611 0.650597 15.7088C0.691515 15.8066 0.751464 15.8953 0.82696 15.9697C0.902456 16.0442 0.991993 16.1029 1.09037 16.1424C1.18874 16.1819 1.29399 16.2015 1.4 16.2H20.6C20.706 16.2015 20.8113 16.1819 20.9096 16.1424C21.008 16.1029 21.0975 16.0442 21.173 15.9697C21.2485 15.8953 21.3085 15.8066 21.3494 15.7088C21.3903 15.611 21.4114 15.506 21.4114 15.4C21.4114 15.294 21.3903 15.189 21.3494 15.0912C21.3085 14.9934 21.2485 14.9047 21.173 14.8303C21.0975 14.7558 21.008 14.6971 20.9096 14.6576C20.8113 14.6181 20.706 14.5985 20.6 14.6H1.4Z"
                          fill="#2563eb"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Part Requests</span>
                    </Link>
                  ) : (
                    <Link
                      to={CUSTOMERS_TAB_PART_REQUESTS}
                      className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        viewBox="0 0 22 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.4 0.200001C9.29399 0.198501 9.18874 0.218086 9.09037 0.257617C8.99199 0.297148 8.90246 0.355837 8.82696 0.430273C8.75146 0.504708 8.69152 0.593406 8.6506 0.691212C8.60968 0.789018 8.58861 0.893981 8.58861 1C8.58861 1.10602 8.60968 1.21098 8.6506 1.30879C8.69152 1.4066 8.75146 1.49529 8.82696 1.56973C8.90246 1.64416 8.99199 1.70285 9.09037 1.74238C9.18874 1.78191 9.29399 1.8015 9.4 1.8H12.6C12.706 1.8015 12.8113 1.78191 12.9096 1.74238C13.008 1.70285 13.0975 1.64416 13.173 1.56973C13.2485 1.49529 13.3085 1.4066 13.3494 1.30879C13.3903 1.21098 13.4114 1.10602 13.4114 1C13.4114 0.893981 13.3903 0.789018 13.3494 0.691212C13.3085 0.593406 13.2485 0.504708 13.173 0.430273C13.0975 0.355837 13.008 0.297148 12.9096 0.257617C12.8113 0.218086 12.706 0.198501 12.6 0.200001H9.4ZM11 3.4C5.9952 3.4 1.88623 7.22919 1.44063 12.1172C1.39743 12.5892 1.76703 13 2.24063 13H19.7594C20.233 13 20.6026 12.5892 20.5594 12.1172C20.1138 7.22919 16.0048 3.4 11 3.4ZM1.4 14.6C1.29399 14.5985 1.18874 14.6181 1.09037 14.6576C0.991993 14.6971 0.902456 14.7558 0.82696 14.8303C0.751464 14.9047 0.691515 14.9934 0.650597 15.0912C0.609679 15.189 0.588608 15.294 0.588608 15.4C0.588608 15.506 0.609679 15.611 0.650597 15.7088C0.691515 15.8066 0.751464 15.8953 0.82696 15.9697C0.902456 16.0442 0.991993 16.1029 1.09037 16.1424C1.18874 16.1819 1.29399 16.2015 1.4 16.2H20.6C20.706 16.2015 20.8113 16.1819 20.9096 16.1424C21.008 16.1029 21.0975 16.0442 21.173 15.9697C21.2485 15.8953 21.3085 15.8066 21.3494 15.7088C21.3903 15.611 21.4114 15.506 21.4114 15.4C21.4114 15.294 21.3903 15.189 21.3494 15.0912C21.3085 14.9934 21.2485 14.9047 21.173 14.8303C21.0975 14.7558 21.008 14.6971 20.9096 14.6576C20.8113 14.6181 20.706 14.5985 20.6 14.6H1.4Z"
                          fill="#9ca3af"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Part Requests</span>
                    </Link>
                  )}
                </li>
                <li class="me-2">
                  {currentTab == CUSTOMERS_TAB_FAVORITES ? (
                    <Link
                      to={CUSTOMERS_TAB_FAVORITES}
                      className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active   group"
                      aria-current="page"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-blue-600 "
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.63594 0.199997C2.74394 0.199997 0.400002 2.54393 0.400002 5.43593C0.400002 10.4699 6.23918 15.2798 8.96719 17.4219C8.96979 17.424 8.97239 17.426 8.975 17.4281C8.99369 17.4428 9.01911 17.4637 9.0375 17.4781L9.03906 17.4766C9.31584 17.6857 9.6531 17.7992 10 17.8C10.3474 17.7995 10.6853 17.686 10.9625 17.4766V17.4781C10.9679 17.4739 10.9758 17.4683 10.9813 17.4641C10.9854 17.4608 10.9912 17.4564 10.9953 17.4531C11.0006 17.4485 11.0058 17.4438 11.0109 17.4391C13.7305 15.3047 19.6 10.4819 19.6 5.43593C19.6 2.54393 17.2561 0.199997 14.3641 0.199997C11.6001 0.199997 10 2.6 10 2.6C10 2.6 8.39994 0.199997 5.63594 0.199997Z"
                          fill="#2563eb"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Favorites</span>
                    </Link>
                  ) : (
                    <Link
                      to={CUSTOMERS_TAB_FAVORITES}
                      className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.63594 0.199997C2.74394 0.199997 0.400002 2.54393 0.400002 5.43593C0.400002 10.4699 6.23918 15.2798 8.96719 17.4219C8.96979 17.424 8.97239 17.426 8.975 17.4281C8.99369 17.4428 9.01911 17.4637 9.0375 17.4781L9.03906 17.4766C9.31584 17.6857 9.6531 17.7992 10 17.8C10.3474 17.7995 10.6853 17.686 10.9625 17.4766V17.4781C10.9679 17.4739 10.9758 17.4683 10.9813 17.4641C10.9854 17.4608 10.9912 17.4564 10.9953 17.4531C11.0006 17.4485 11.0058 17.4438 11.0109 17.4391C13.7305 15.3047 19.6 10.4819 19.6 5.43593C19.6 2.54393 17.2561 0.199997 14.3641 0.199997C11.6001 0.199997 10 2.6 10 2.6C10 2.6 8.39994 0.199997 5.63594 0.199997Z"
                          fill="#9ca3af"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Favorites</span>
                    </Link>
                  )}
                </li>
                <li class="me-2">
                  {currentTab == CUSTOMERS_TAB_ACTIVITY ? (
                    <Link
                      to={CUSTOMERS_TAB_ACTIVITY}
                      className="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active   group"
                      aria-current="page"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-blue-600 "
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.26 9.96L14.95 12.94C14.66 13.31 14.25 13.55 13.78 13.6C13.31 13.66 12.85 13.53 12.48 13.24L10.65 11.8C10.58 11.74 10.5 11.74 10.46 11.75C10.42 11.75 10.35 11.77 10.29 11.85L7.91 14.94C7.76 15.13 7.54 15.23 7.32 15.23C7.16 15.23 7 15.18 6.86 15.07C6.53 14.82 6.47 14.35 6.72 14.02L9.1 10.93C9.39 10.56 9.8 10.32 10.27 10.26C10.73 10.2 11.2 10.33 11.57 10.62L13.4 12.06C13.47 12.12 13.54 12.12 13.59 12.11C13.63 12.11 13.7 12.09 13.76 12.01L16.07 9.03C16.32 8.7 16.8 8.64 17.12 8.9C17.45 9.17 17.51 9.64 17.26 9.96Z"
                          fill="#2563eb"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Activity</span>
                    </Link>
                  ) : (
                    <Link
                      to={CUSTOMERS_TAB_ACTIVITY}
                      className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                    >
                      <svg
                        className="w-5 h-5 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.26 9.96L14.95 12.94C14.66 13.31 14.25 13.55 13.78 13.6C13.31 13.66 12.85 13.53 12.48 13.24L10.65 11.8C10.58 11.74 10.5 11.74 10.46 11.75C10.42 11.75 10.35 11.77 10.29 11.85L7.91 14.94C7.76 15.13 7.54 15.23 7.32 15.23C7.16 15.23 7 15.18 6.86 15.07C6.53 14.82 6.47 14.35 6.72 14.02L9.1 10.93C9.39 10.56 9.8 10.32 10.27 10.26C10.73 10.2 11.2 10.33 11.57 10.62L13.4 12.06C13.47 12.12 13.54 12.12 13.59 12.11C13.63 12.11 13.7 12.09 13.76 12.01L16.07 9.03C16.32 8.7 16.8 8.64 17.12 8.9C17.45 9.17 17.51 9.64 17.26 9.96Z"
                          fill="#9ca3af"
                        />
                      </svg>
                      <span className="ml-1 font-semibold">Activity</span>
                    </Link>
                  )}
                </li>
                {
                  <li class="me-2">
                    {currentTab == CUSTOMERS_TAB_PAYMENTS ? (
                      <Link
                        to={CUSTOMERS_TAB_PAYMENTS}
                        class="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active   group"
                        aria-current="page"
                      >
                        <svg
                          className="w-5 h-5 me-2 text-blue-600 "
                          viewBox="0 0 19 19"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M16 2H0V6C1.10457 6 2 6.89543 2 8C2 9.10457 1.10457 10 0 10V14H16V10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6V2ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                            fill="#2563eb"
                            fill-rule="evenodd"
                          />
                        </svg>
                        <span className="ml-1 font-semibold">Payments</span>
                      </Link>
                    ) : (
                      <Link
                        to={CUSTOMERS_TAB_PAYMENTS}
                        className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                      >
                        <svg
                          className="w-5 h-5 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 19 19"
                        >
                          <path
                            clip-rule="evenodd"
                            d="M16 2H0V6C1.10457 6 2 6.89543 2 8C2 9.10457 1.10457 10 0 10V14H16V10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6V2ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                            fill="#9ca3af"
                            fill-rule="evenodd"
                          />
                        </svg>
                        <span className="ml-1 font-semibold">Payments</span>
                      </Link>
                    )}
                  </li>
                }
                {
                  <li class="me-2">
                    {currentTab == CUSTOMERS_TAB_MESSAGES ? (
                      <Link
                        to={CUSTOMERS_TAB_MESSAGES}
                        class="inline-flex items-center justify-center p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active   group"
                        aria-current="page"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="#9ca3af"
                          className="w-5 h-5 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        >
                          <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                          <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                        </svg>

                        <span className="ml-1 font-semibold">Messages</span>
                      </Link>
                    ) : (
                      <Link
                        to={CUSTOMERS_TAB_MESSAGES}
                        className="inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 :text-gray-300 group"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="#9ca3af"
                          className="w-5 h-5 me-2 text-gray-400 group-hover:text-gray-500  :text-gray-300"
                        >
                          <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z" />
                          <path d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z" />
                        </svg>

                        <span className="ml-1 font-semibold">Messages</span>
                      </Link>
                    )}
                  </li>
                }
              </ul>
            </div>
          </div>

          <Outlet />
        </div>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
export default CustomerLayout;
