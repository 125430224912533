import React from 'react'

import PartnerAdd from '../components/cms/Partner/PartnerAdd'

function PartnersAddPage() {
  return (
    <div>
        <PartnerAdd/>
    </div>
  )
}

export default PartnersAddPage