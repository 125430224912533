import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { makeAuthenticatedRequest } from '../utils'
import { toast } from 'react-toastify';
import ChangeLpoStatus from '../components/ChangeLpoStatus';

const LpoDetailsPage = () => {
    const { lpoID } = useParams()
    const navigate = useNavigate()
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOPen] = useState([]);
    const [changeStatusModal, setchangeStatusModal] = useState('')
    const [currentOrder, setCurrentOrder] = useState()

    const [lpoData, setLpoData] = useState([])

    const [selectedParts, setSelectedParts] = useState([])

    const handleSelectPart = (id) => {
      // Check if the id exists in selectedParts
      const existingIndex = selectedParts.findIndex((partId) => partId === id);
    
      if (existingIndex !== -1) {
        // If exists, remove the existing id
        const selectedPartsCopy = [...selectedParts];
        selectedPartsCopy.splice(existingIndex, 1);
        setSelectedParts(selectedPartsCopy);
      } else {
        // If doesn't exist, add the new id
        setSelectedParts((prevSelectedParts) => [...prevSelectedParts, id]);
      }
    };

    console.log('selectedParts',selectedParts)

    const fetchLpo = () => {
        setProcessing(true)
        makeAuthenticatedRequest(`/oms/lpos/${lpoID}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setLpoData(data)
                const ids = data?.lpo_parts.map(item => item.id);
                setSelectedParts(ids)
                setProcessing(false)
            });
    }
    useEffect(() => {
        fetchLpo()
    }, [])

    const submitDeliveryNote = () => {
      setLoading(true)

        const payload = {
            part_ids: selectedParts,
            lpo:lpoData?.id,
            client:lpoData?.client?.id
        }
        makeAuthenticatedRequest(`/oms/delivery-notes/`, "POST", JSON.stringify(payload))
            .then((response) => response.json())
            .then((data) => {
                fetchLpo()
                setLoading(false)
                toast.success('Order dispatched sucessfully created successfully')

            })
            .catch((err) => {

              setLoading(false);
              toast.error('Delivery could not be created!. Try again later.')


            }).finally(() => {
              setLoading(false);
            });
    }
    const handleChangeRfqPartStatus = (status, rfqPartId) => {
        const payload = {
            status
        }
        makeAuthenticatedRequest(`/oms/rfqpart/${rfqPartId}/status-update/`, "POST", JSON.stringify(payload))
        .then((response) => response.json())
        .then((data) => {
            fetchLpo()
            setIsLoading(false)

        })
        .catch((err) => {

          setIsLoading(false);

        }).finally(() => {
          setIsLoading(false);
        });

    }
    const cancelRef = useRef()
    const actionRef = useRef()
    const confirmRef = useRef()

    const handleShowOrderChange = orderID => {
        const openState = open.slice()
        const index = openState.indexOf(orderID)
    
        if (index >= 0) {
          openState.splice(index, 1)
          setOPen(openState)
        } else {
          openState.push(orderID)
          setOPen(openState)
        }
      }
      const closeChangeModal = () => {
        setchangeStatusModal('')
      }
      
      const statusOptions = [
        { name: 'Part sourcing', value: 'Part sourcing', label: 'Part sourcing', color: '#54D3CA' },
        { name: 'Dispatched', value: 'Dispatched', label: 'Dispatched', color: '#8CD1FC' },
        { name: 'Delivered', value: 'Delivered', label: 'Delivered', color: '#3E5DDD' },
        { name: 'Completed', value: 'Completed', label: 'Completed', color: '#059D5F' },
        { name: 'Rejected', value: 'Rejected', label: 'Rejected', color: '#FCB28E' },
        { name: 'Returned', value: 'Returned', label: 'Returned', color: '#DE6972' },
      ]

      const orderStatusChangeDialog = (part, status) => {
        setOPen([])
        setCurrentOrder(part)
        setchangeStatusModal(status)
      }

      useEffect(() => {
        let actionhandler = e => {
          if (actionRef.current && !actionRef.current.contains(e.target)) {
    
            setOPen([])
    
            // console.log(actionRef.current)
          }
        }
        document.addEventListener('mousedown', actionhandler);
    
        return () => {
          document.removeEventListener('mousedown', actionhandler);
        }
    
      })
    
      useEffect(() => {
        let confirmHandler = e => {
          if (confirmRef.current && !confirmRef.current.contains(e.target)) {
    
            setchangeStatusModal('')
    
            // console.log(confirmRef.current)
          }
        }
        document.addEventListener('mousedown', confirmHandler);
    
        return () => {
          document.removeEventListener('mousedown', confirmHandler);
        }
    
      })
  return (
    <>
            {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
            <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
            <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
    }
    <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
    <div className="flex flex-row justify-between">
                    <div onClick={() => navigate(-1)}
                        className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-4 mt-0.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                        </svg>

                        Back
                    </div>
                    <div className=" flex flex-row gap-2">
                        {selectedParts.length > 0 &&
                        <button 
                        onClick={submitDeliveryNote}
                        className='text-white text-center px-4 py-2 rounded-md bg-gray-900 '>{loading ? 'Submitting...':'Dispatch'}</button>
                        }
                    </div>

                </div>

                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                          <table class="table-auto w-full  ">
                            <thead className='pl-2'>
                              <tr className='pl-1 border-b border-gray-200'>
                              {/* <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'></th> */}
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Vehicle</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Quantity</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Part No</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Price</th>
                                <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                            {lpoData?.lpo_parts?.slice().reverse().map((part, index) => (
                                      
                                  <tr className='p-3 border-b border-b-gray-200 font-satoshi relative'>
                                    {/* <td
                                    className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       <input
                                       checked={selectedParts.includes(part?.id)}
                                       onChange={() => handleSelectPart(part?.id)}
                                        type="checkbox" className='accent-gray-900 w-5 h-5 rounded-md outline-none' />
                                    </td> */}
                                    <td
                                    className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       {part?.part_name}
                                    </td>
                                    
                                  <td className='pl-2 text-xs md:text-[16px] flex-1 w-[40%]'>
                                    <p className='text-[16px] text-gray-600'>
                                    
                                    {part?.vehicle.make}/{part?.vehicle.model}/{part?.vehicle.year} 
                                    </p>
                                    </td>
                                    <td className='pl-2 text-xs md:text-[16px]'>
                                    <p className='text-gray-500'>
                                    {part?.quantity}
                                    </p>
                                    </td>
                                  <td
                                    className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                      {part?.part_number} 
                                    </td>
                                    <td
                                    className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       {parseFloat(part.price)}
                                    </td>
                                    <td
                                    className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       {parseFloat(part?.total)}
                                    </td>
                                  </tr>
                              ))}

                            </tbody>
                          </table>
                          <div className="flex flex-row">

                          </div>
                   
    </div>
    </div>
    {changeStatusModal && currentOrder && (
            <ChangeLpoStatus part={currentOrder} newStatus={changeStatusModal} handleClose={closeChangeModal} handleSubmit={handleChangeRfqPartStatus}
              changeTo={(changeStatusModal === 'Part sourcing' ?
                'Part sourcing'
                : changeStatusModal === 'Dispatched' ?
                  'Dispatched'
                  : changeStatusModal === 'Delivered' ?
                  'Delivered'
                  : changeStatusModal === 'Completed' ?
                  'Completed'
                  : changeStatusModal === 'Rejected' ?
                  'Rejected'
                  : changeStatusModal === 'Returned' ?
                    'Returned':
                                '')}
            />
          )}
    </>
  )
}

export default LpoDetailsPage