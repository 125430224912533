import React from 'react'
import AccessoryView from '../components/cms/Accessory/AccessoryView'

function AccessoryViewPage() {
    return (
        <div>
            <AccessoryView />
        </div>
    )
}
export default AccessoryViewPage
