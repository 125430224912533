import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import back from '../assets/ic_back.svg'
import copy from '../assets/copy.svg'
import Avatar from 'react-avatar'
import { makeAuthenticatedRequest } from '../utils'
import { useState } from 'react'
import { ENDPOINT_USERS } from '../routes'
import { async } from '@firebase/util'

function BookingDetails() {
    const { bookingID } = useParams()
    const [data, setData] = useState([])

    const fetchBooking = async() => {
        await makeAuthenticatedRequest(`/oms/bookings/${bookingID}`)
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setData(data)
          });
      }
    

      const [center, setCenter] = useState([])

      const fetchCenter = async() => {

        await fetchBooking()
          makeAuthenticatedRequest(`/oms/service_centers/${data?.service_center?.id}`)
            .then((response) => response.json())
            .then((data) => {
              console.log(data)
              setCenter(data)
            });
        }
        

    
      useEffect(() => {
        fetchBooking();
        // fetchCenter()

      }, [])
    
    const navigate = useNavigate()

    console.log('data',data)

 
  return (
    <>
        <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20">
    <div className="flex flex-row">
        <div onClick={() => navigate(-1)}
        className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] text-[14px] rounded-lg">
        <img src={back} alt="" />
        Back
        </div>
    </div>
    <div className="flex flex-row gap-6">
        <p className='font-satoshi text-[#030A1A] text-[16px] md:text-[18px] font-semibold'>Booking #SPP-{bookingID}</p>
        
        <img src={copy} alt="" className='hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`SPP-${bookingID}`) }} />
    </div>
    <div className="flex flex-row gap-3">
        <p className='font-satoshi text-[#808491] text-[16px] md:text-[16px] '>24 Apr 2023</p>

        <div className={`${data?.status === 'M'? 'bg-[#DE6972] text-white' 
            :data?.status === 'O'? 'bg-[#54D3CA] text-white'
            :data?.status === 'N'? 'bg-[#FCB28E] text-white'
            :data?.status === 'K'? 'bg-[#059D5F] text-white'
            :data?.status === 'B'? 'bg-[#DE6972] text-white':
            'bg-gray-200'
            } 
            px-2 py-1 text-center font-satoshi capitalize rounded-md text-[14px]`}>
            {data?.status === 'M'? 'Missed' 
            :data?.status === 'O'? 'Confirmed'
            :data?.status === 'N'? 'Not confirmed'
            :data?.status === 'K'? 'Checked in'
            :data?.status === 'B'? 'Cancelled':
            null
            }


            </div>
        
    </div>
    <div className="flex flex-col md:flex-row gap-4 w-full ">
        <Link 
        to={`${ENDPOINT_USERS}/${data?.customer?.id}`} 
        className="flex flex-col w-full md:w-6/12 border rounded-lg gap-2 px-2 pt-1 pb-2">
        <div className="flex flex-col w-full   gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Customer details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4">
            <div className="">
            <Avatar name={data?.customer?.first_name} size={50} round={true} />
            </div>
            <div className="flex-1 flex flex-col gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{data?.customer?.first_name} {data?.customer?.last_name}</p>
       
        {/* <div className="flex flex-row gap-1">
            <div className="border border-red-400 rounded-full p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-red-400 w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>

            </div>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>+254742415221</p>

        </div> */}

                
            </div>

        </div>

        
        </div>
        </Link>
       

        <div className="flex flex-col w-full md:w-6/12 border rounded-lg gap-2 px-2 pt-1 pb-2">
        <div className="flex flex-col w-full  gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Vehicle details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4">
            <div className="">
                <img src={data?.car_specs?.photo_url? data?.car_specs?.photo_url :"/cdn/images/vehicle.svg"} className='rounded w-30 h-20 object-cover' alt="" />
            </div>
            <div className="flex-1 flex flex-col gap-1">
            <div className="flex flex-row gap-0.5">

        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{data?.car_specs?.make}</p>
            <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{data?.car_specs?.model} </p>
            <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{data?.car_specs?.year} </p>

</div>
     
        <div className="flex flex-row gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] uppercase'>{data?.car_number_plate}</p>


        </div>

                
            </div>

        </div>

        
        </div>
        </div>

    </div>
    <div className="flex flex-col w-full border rounded-lg gap-2 px-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Service details</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-col md:flex-row w-full gap-4">
            <div className="w-full md:w-7/12 flex flex-col gap-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Issue</p>
                <div className="bg-gray-200 rounded-md px-2 py-1 w-full">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{data?.service_needed}</p>

                </div>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>Details</p>
                <div className="bg-gray-200 rounded-md px-2 w-full">
        <p className='font-satoshi text-[#030A1A] px-2 py-2 text-[16px] md:text-[16px] '>{data?.service_description}</p>
                </div>
            </div>
            <div className="flex-1 flex flex-col gap-1 w-full md:w-/5/12">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold '>Service center details</p>
       
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{data?.service_center?.name}, Westlands</p>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>24 Arp 2023</p>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>8.00 AM - 10.00 AM</p>



                
            </div>

        </div>

        
        </div>



    </div>
    </>
  )
}

export default BookingDetails