import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../utils';
import Moment from 'react-moment';
import { useParams } from 'react-router-dom';
import { MARKETPLACE_HOME_URL } from '../../constants'
import Avatar from 'react-avatar';


const CUSTOMERS_URL = `/oms/customers/`;


function ServicesTab() {
    const { customerID } = useParams();
    const [data, setData] = useState();
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    const [perPage, setPerPage] = useState(20)
    const [processing, setProcessing] = useState(false)


    const fetchData = async () => {
        setProcessing(true)
        try {
            const params = {
                "s": search,
                "page": page,
                "per_page": perPage,
            }
            const searchParams = new URLSearchParams(params).toString();
            const response = await axiosCustom.get(`${CUSTOMERS_URL}${customerID}/jobs/?${searchParams}`);
            setPage(response?.data?.page)
            setData(response?.data?.results)
            setCount(response?.data?.count)
            setPerPage(response?.data?.per_page)
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }


    const submitSearch = async (e) => {
        e.preventDefault();
        await fetchData();
    }

    useEffect(() => {
        fetchData();
    }, [page])

    return (
        <>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div class="mx-auto max-w-screen-2xl">
                        <div class="mt-4 relative overflow-hidden">

                            <div class="flex flex-col px-2 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
                                <div class="flex items-center flex-1 space-x-4">
                                    <form class="flex items-center" onSubmit={submitSearch}>
                                        <label for="simple-search" class="sr-only">Search</label>
                                        <div class="relative w-full">
                                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" onClick={submitSearch}>
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 " fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <input
                                                value={search}
                                                onChange={e => setSearch(e.target.value)}
                                                type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     :ring-primary-500 :border-primary-500" placeholder="Search" required="" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {!data || data?.length < 1 ?
                                <section class="bg-white ">
                                    <div class="py-8 mx-auto px-4">
                                        <span className='text-sm'>No services to display</span>
                                    </div>
                                </section>
                                :
                                <div class="relative overflow-x-auto">

                                    <div className="flex flex-col px-2 gap-1 w-full py-1 justify-between">
                                        {
                                            data?.map((item) => (
                                                <div className="grid grid-cols-2 col-span-1 mb-1 rounded border border-slate-200 px-4 py-1">
                                                    <div className=' flex flex-col'>
                                                        <div className='flex gap-1 items-center flex-[4]'>
                                                            <div className="p-1 flex-[0.5]">
                                                                {
                                                                    item?.mechanic?.mechanic_thumbnail ?
                                                                        <img src={item?.mechanic?.mechanic_thumbnail} class="border border-slate-200 object-cover rounded-full h-16 w-16" />
                                                                        :
                                                                        <Avatar name={item?.mechanic?.mechanic_name} size="60" className="mr-2 rounded-full shadow" />
                                                                }
                                                            </div>
                                                            <div className='flex items-center flex-[3.5] flex-wrap'>
                                                                <div className='flex flex-col gap-2'>
                                                                    <div className='font-semibold text-sm text-left line-clamp-2 capitalize'>
                                                                        {item?.mechanic?.mechanic_name}
                                                                    </div>
                                                                    <div className='flex gap-2 text-gray-400 text-[11px] text-left items-center'>
                                                                        <a tel={`+${item?.mechanic?.phone_number}`}>
                                                                            +{item?.mechanic?.phone_number}
                                                                        </a>
                                                                    </div>
                                                                    <p className='text-xs text-gray-700 text-ellipsis mr-6'>{item?.car?.make} {item?.car?.model} {item?.car?.variant} {item?.car?.year}</p>
                                                                    <p className='text-xs text-gray-700 text-ellipsis mr-6'>{item?.service_needed}</p>
                                                                    <p className='text-sm'>
                                                                        <Moment format="MMM, DD LT" className='flex'>
                                                                            {item?.date_created}
                                                                        </Moment>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className=' flex flex-col'>

                                                        <div className="flex">

                                                            <div className="flex flex-col">
                                                                <div className="flex flex-row gap-4 p-2">
                                                                    <div className="flex flex-col">
                                                                        <span className="text-xs px-3 py-2 rounded text-center text-black font-extrabold bg-orange-200">
                                                                            {item?.status}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }

                            {data && data?.length > 0 &&
                                <nav class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0">
                                    <span class="text-sm font-normal text-gray-500 ">
                                        Showing
                                        <span class="font-semibold text-gray-900 "> {((page - 1) * perPage) + 1} - {((page - 1) * perPage) + perPage > count ? count : ((page - 1) * perPage) + perPage}</span>
                                        <span className='m-1'>of</span>
                                        <span class="font-semibold text-gray-900 "> {count} </span>
                                        <span className='m-1'>results</span>
                                    </span>
                                    {count > perPage &&
                                        <ul class="inline-flex items-stretch -space-x-px">
                                            <li>
                                                <button
                                                    disabled={page <= 1}
                                                    onClick={e => setPage(Math.max(1, page - 1))}
                                                    className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">
                                                    <span class="sr-only">Previous</span>
                                                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                    </svg>
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={e => setPage(1)}
                                                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">1</button>
                                            </li>
                                            {
                                                Math.ceil(count / perPage) >= 2 &&
                                                <li>
                                                    <button
                                                        onClick={e => setPage(2)}
                                                        className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">2</button>
                                                </li>
                                            }

                                            {
                                                Math.ceil(count / perPage) >= 3 &&
                                                <li>
                                                    <button
                                                        onClick={e => setPage(3)}
                                                        className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">3</button>
                                                </li>
                                            }
                                            <li>
                                                <a href="#" class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">...</a>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={e => setPage(Math.ceil(count / perPage))}
                                                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">{Math.ceil(count / perPage)}</button>
                                            </li>
                                            <li>
                                                <button
                                                    disabled={page >= Math.ceil(count / perPage)}
                                                    onClick={e => setPage(Math.min(page + 1, Math.ceil(count / perPage)))}
                                                    class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">
                                                    <span class="sr-only">Next</span>
                                                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                                    </svg>
                                                </button>
                                            </li>
                                        </ul>
                                    }
                                </nav>
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
export default ServicesTab