import React, { useEffect, useState } from 'react';

const DeliveryTimer = ({deliveryTo }) => {
    const [remainingTime, setRemainingTime] = useState('');
    const [remainingMins, setRemainingMins] = useState('');
    const [remainingSecs, setRemainingSecs] = useState('');

    const [timeColor, setTimeColor] = useState('');
    const [textColor, setTextColor] = useState('');
    const nowTime = new Date()

  
    useEffect(() => {
      const calculateRemainingTime = () => {
        // const currentTime = new Date().getTime();
        // const deliveryToTime = new Date(deliveryTo).getTime();

        const currentTime = new Date().toISOString().slice(0, 19);
        const deliveryToTime = new Date(deliveryTo);
        deliveryToTime.setHours(deliveryToTime.getHours() - 3)

        const currentTimen = new Date(currentTime);
        const deliveryToTimen = new Date(deliveryToTime.toISOString().slice(0, 19))

        // console.log()
        // console.log()


  
        if (currentTimen > deliveryToTimen) {
          setRemainingTime('Delivery passed');
          setTimeColor('red');
        } else {
            const timeDifference = deliveryToTimen.getTime() - currentTimen.getTime();

          const timeDiffMillis = deliveryToTime - currentTime;
          const minutesRemaining = Math.ceil(timeDiffMillis / (1000 * 60));
          
          const days = Math.floor(timeDiffMillis /(1000 * 60 * 60 * 24));
          const hours = Math.floor(timeDiffMillis / (1000 * 60 * 60));
          const minutess = Math.floor((timeDiffMillis % (1000 * 60 * 60)) / (1000 * 60));
          const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            
          if (minutes > 30) {

            setRemainingMins(minutes)
            setRemainingSecs(seconds)
            setRemainingTime(`Time remaining: ${minutes} minutes, ${seconds} seconds`);
            setTimeColor('green');
            setTextColor('white')
          } else {
            setRemainingMins(minutes)
            setRemainingSecs(seconds)
            setRemainingTime(`Time remaining: ${minutes} minutes,  ${seconds} seconds`);
            setTimeColor('orange');
            setTextColor('black')
          }
        }
      };
  
      const intervalId = setInterval(calculateRemainingTime, 1000);
  
      return () => clearInterval(intervalId);
    }, [deliveryTo]);
  
  

  return (
    <div className="font-satoshi">
        {remainingTime === 'Delivery passed'?
        <p style={{ color: timeColor }}>{remainingTime}</p>
        :
        <div className="flex flex-row gap-2">
            {/* clock */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 h-8" style={{color:timeColor}}>
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            <div className="flex px-2 py-1 gap-2 rounded-md " style={{ backgroundColor: timeColor, color:textColor }}>
                <div className="">
                    {remainingMins} min
                </div>
                <div className="">
                    {remainingSecs} sec
                </div>
            </div>
        </div>
        }
    </div>
        // <p style={{ color: timeColor }}>{remainingTime}</p>

  );
};

export default DeliveryTimer;