import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ENDPOINT_DASHBOARD } from '../routes';

function Page404() {
    const navigate = useNavigate();
  return (
    <>
        <div className='bg-white'>
            <div className="flex flex-col mx-auto pt-36 md:pt-5 pb-24 pl-2 md:pl-24 items-center">

            <img src='/cdn/images/logo.svg' alt="" className='h-40 w-40' />
            
            <p className='text-black font-bold text-3xl mt-4 mb-2 text-left font-satoshi'>Page not found.</p>
    
            <button onClick={() => navigate(ENDPOINT_DASHBOARD)} className='items-start text-center p-2 font-satoshi font-bold bg-red-500 text-white mt-8 mb-4 rounded-md w-[98%] md:w-6/12'>Back to home</button>
    
            </div>
        </div>
    </>
  )
}

export default Page404