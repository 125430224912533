import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import HomeRating from '../../../components/HomeRating'

function AccessoryView() {

    const [activeTab, setActiveTab] = useState(1)
    const navigate = useNavigate();

  return (
      <div className='flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20'>
          <div className="flex flex-row justify-between">
              <div
                  onClick={() => navigate(-1)}
                  className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
              >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-4 mt-0.5"
                  >
                      <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                      />
                  </svg>
                  Back
              </div>
          </div>

          <div className="flex flex-row gap-3 items-center">
              <p className='font-bold text-[24px]'>Accessories</p>
              {/* icon */}
              <img src='/cdn/images/hot.svg' alt="" />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-3 w-full gap-6 py-4">
              <div className="w-full">
                  <div className="bg-white p-5 rounded-xl h-full flex flex-col gap-2 border shadow-lg">
                      <p className='text-gray-900 font-semibold text-[20px]'>Popular</p>
                      <div className="w-full h-full ">
                          <img src="/cdn/images/watches.png" className='w-full h-full md:h-96 rounded-lg' alt="" />

                      </div>

                      <div className="flex flex-col gap-2">
                          <HomeRating rating={5} />
                      </div>
                  </div>
              </div>
              <div className="flex flex-col w-full gap-4">
                  <div className="bg-white py-2 px-3 rounded-lg flex flex-col gap-2 border shadow-lg">
                      <p className='text-gray-900 font-semibold text-[20px]'>Added today</p>
                      <div className="grid grid-cols-4 gap-2">
                          <div className="h-[5rem] w-full rounded-lg">
                              <img src="/cdn/images/Rectangle7393.png" className='w-full h-full rounded-lg overflow-hidden object-cover' alt="" />
                          </div>

                          <div className="h-[5rem] w-full rounded-lg">
                              <img src="/cdn/images/Rectangle4.png" className='w-full h-full rounded-lg overflow-hidden object-cover' alt="" />
                          </div>
                          <div className="h-[5rem] w-full rounded-lg">
                              <img src="/cdn/images/Rectangle43.png" className='w-full h-full rounded-lg overflow-hidden object-cover' alt="" />
                          </div>
                          <div className="h-[5rem] w-full rounded-lg">
                              <img src="/cdn/images/Rectangle741.png" className='w-full h-full rounded-lg overflow-hidden object-cover' alt="" />
                          </div>
                      </div>
                  </div>
                  <div className="flex flex-col w-full gap-2 bg-white py-2 px-3 rounded-lg border shadow-lg">
                      <p className='text-gray-900 font-semibold text-[20px]'>Interior</p>
                      <div className="grid grid-cols-2 w-full gap-2">
                          <div className="bg-gray-100 rounded-lg p-2">
                              <img src="/cdn/images/Rectangle739.png" className='w-full h-[7rem] mx-auto object-cover' alt="" />
                          </div>
                          <div className="bg-gray-100 rounded-lg p-2">
                              <img src="/cdn/images/Rectangle741.png" className='w-full h-[7rem] mx-auto object-cover' alt="" />
                          </div>
                          <div className="bg-gray-100 rounded-lg p-2">
                              <img src="/cdn/images/Rectangle740.png" className='w-full h-[7rem] mx-auto object-cover' alt="" />
                          </div>
                          <div className="bg-gray-100 rounded-lg p-2">
                              <img src="/cdn/images/Rectangle742.png" className='w-full h-[7rem] mx-auto object-cover' alt="" />
                          </div>
                      </div>
                  </div>
              </div>
              <div className="flex flex-col w-full gap-4">
                  <div className="flex flex-col w-full gap-2 bg-white py-2 px-3 rounded-lg border shadow-lg">
                      <p className='text-gray-900 font-semibold text-[20px]'>Interior</p>
                      <div className="grid grid-cols-2 w-full gap-2">
                          <div className="h-[8rem] w-full rounded-lg p-2">
                              <img src="/cdn/images/ford.png" className='w-full h-full object-cover rounded-lg overflow-hidden' alt="" />
                          </div>
                          <div className="h-[8rem] w-full  rounded-lg p-2">
                              <img src="/cdn/images/Rectangle745.png" className='w-full h-full object-cover rounded-lg overflow-hidden' alt="" />
                          </div>
                          <div className="h-[8rem] w-full rounded-lg p-2">
                              <img src="/cdn/images/Rectangle743.png" className='w-full h-full object-cover rounded-lg overflow-hidden' alt="" />
                          </div>
                          <div className="h-[8rem] w-full rounded-lg p-2">
                              <img src="/cdn/images/Rectangle744.png" className='w-full h-full object-cover rounded-lg overflow-hidden' alt="" />
                          </div>
                      </div>
                  </div>
                  <div className="bg-white py-2 px-3 rounded-xl flex flex-col gap-2 border shadow-lg">

                      <div className="flex flex-row gap-6  px-4 py-2 w-full ">
                          <div className="h-full w-[10rem]">
                              <img src="/cdn/images/Rectangle7393.png" className='w-full h-full rounded' alt="" />
                          </div>
                          <div className="flex flex-col gap-2">
                              <p className='text-gray-900 font-semibold text-[20px]'>Best seller</p>
                              <HomeRating rating={5} />
                              <p className=' font-semibold text-[16px]'>465 sold</p>

                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div>
  )
}

export default AccessoryView
