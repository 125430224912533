import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/20/solid'


function DSFPagination({ currentPage, totalItems, itemsPerPage, pageChanged, pages }) {
    
  return (
    <div className="flex sm:py-3 sm:px-6 w-full">
    <div className="flex sm:flex-1">
      <nav className="isolate inline-flex -space-x-px" aria-label="Pagination">
        <button
          disabled={currentPage <= 1}
          onClick={e => pageChanged(1)}
          className={`relative inline-flex items-center px-2 py-2 text-sm font-medium ${currentPage - 1 > 0 ? 'hover:bg-gray-50 text-indigo-600' : "text-gray-500 hover:bg-gray-50"} focus:z-20`}>
          <span className="sr-only">Previous</span>
          <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          disabled={currentPage <= 1}
          onClick={e => pageChanged(currentPage - 1)}
          className={`relative inline-flex items-center px-2 py-2 text-sm font-medium ${currentPage - 1 > 0 ? 'hover:bg-gray-50 text-indigo-600' : "text-gray-500 hover:bg-gray-50"} focus:z-20`}>
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
          {/* <span className="sr-only">Previous</span> */}

        </button>
        <span className="relative inline-flex items-center sm:px-4 py-2 text-sm text-gray-700">
          <p className='flex gap-1'>Page
            <span className="font-medium">
              <input onChange={e => pageChanged(Number(e.target.value))} min={1} max={pages} type="number" value={currentPage} className='outline-none w-12 inline' id='pagination-input' />
            </span> of
            <span className="font-medium">{pages}
            </span>
          </p>
        </span>
        <button
          disabled={currentPage >= pages}
          onClick={e => pageChanged(currentPage + 1)}
          className={`relative inline-flex items-center px-2 py-2 text-sm font-medium ${currentPage < pages ? 'hover:bg-gray-50 text-indigo-600' : "text-gray-500 hover:bg-gray-50"} focus:z-20`}>
          <span className="sr-only">next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          {/* <span className="">next</span> */}

        </button>
        <button
          disabled={currentPage >= pages}
          onClick={e => pageChanged(pages)}
          className={`relative inline-flex items-center px-2 py-2 text-sm font-medium ${currentPage < pages ? 'hover:bg-gray-50 text-indigo-600' : "text-gray-500 hover:bg-gray-50"} focus:z-20`}>
          <span className="sr-only">Previous</span>
          <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </nav>
    </div>
  </div>
  )
}

export default DSFPagination