import React, { useEffect, useState } from 'react'
import dayjs from "dayjs";
import { makeAuthenticatedRequest } from '../../utils';
import Moment from 'react-moment';


function LastestLink() {

    const [data, setData] = useState([])

    const [dateRange, setDateRange] = useState({
        startDate: dayjs(new Date()).subtract(7, "day").format("YYYY-MM-DD"),
        endDate: dayjs(new Date()).format("YYYY-MM-DD")
    });

    const fetchData = (newValue) => {
        const params = {
            date_start: newValue?.startDate ? newValue.startDate : dateRange.startDate,
            date_end: newValue?.endDate ? newValue.endDate : dateRange.endDate
        }
        const searchParams = new URLSearchParams(params).toString();
        const url = `/oms/analytics/links/?${searchParams}`

        makeAuthenticatedRequest(url)
            .then((response) => response.json())
            .then((data) => {
                setData(data)
            });
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (

        <div class="p-4 bg-white border border-gray-200 rounded-lg shadow-sm  sm:p-6 ">
            <div class="items-center justify-between lg:flex">
                <div class="mb-4 lg:mb-0">
                    <h3 class="mb-2 text-xl font-bold text-gray-900 ">Site Visits</h3>
                    <span class="text-base font-normal text-gray-500 ">This is are latest site visits</span>
                </div>
            </div>
            <div class="flex flex-col mt-6">
                <div class="overflow-x-auto rounded-lg">
                    <div class="inline-block min-w-full align-middle">
                        <div class="overflow-hidden shadow sm:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-200 ">
                                <thead class="bg-gray-50 ">
                                    <tr>
                                        <th scope="col"
                                            class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                                            Device
                                        </th>
                                        <th scope="col"
                                            class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                                            Time
                                        </th>
                                        <th scope="col"
                                            class="p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                                            URL
                                        </th>
                                    </tr>
                                </thead>

                                <tbody class="bg-white ">
                                    {data?.results?.map((item) => (

                                        <tr>
                                            <td class="p-4 text-sm font-normal text-gray-900 whitespace-nowrap ">
                                                {item?.os_family}
                                            </td>
                                            <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap ">
                                                <Moment
                                                    fromNow
                                                >
                                                    {item?.last_seen}
                                                </Moment>
                                            </td>
                                            <td class="p-4 text-sm font-semibold text-xs text-gray-900 whitespace-nowrap ">
                                                <a href={item?.last_hit_url} target='_blank'>{item?.last_hit_url}</a>
                                            </td>
                                        </tr>
                                    )


                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LastestLink