import React from 'react'
import BrandAdd from '../components/cms/Brand/BrandAdd'

function BrandAddPage() {
  return (
    <div>
        <BrandAdd/>
    </div>
  )
}

export default BrandAddPage