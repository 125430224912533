import React, { useState, useEffect } from "react";
import AddMessageModal from "./modals/AddMessageModal";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { axiosCustom } from "../../utils";
import { useSearchParams } from "react-router-dom";

function Messages() {
  const navigate = useNavigate();
  const { customerID } = useParams();
  const [showAddMessageModal, setShowAddMessageModal] = useState(false);
  const [messages, setMessages] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState(searchParams.get("s") || "");
  const [perPage, setPerPage] = useState(searchParams.get("per_page") || 10);
  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const { data } = await axiosCustom.get(`/oms/customers/${customerID}`);
        setCustomerData(data);
      } catch (error) {
        toast.error("Something went wrong!");
        console.log("ERROR:", error);
      }
    };

    getCustomerDetails();
  }, [customerID]);

  const handleError = ({ error, message }) => {
    if (error.response) {
      toast.error(message);
    } else if (error.request) {
      toast.error("Something went wrong!");
    } else {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const getMessages = async () => {
    const requestParams = new URLSearchParams(searchParams).toString();

    try {
      const { data } = await axiosCustom.get(
        `/oms/customers/${customerID}/sms/?${requestParams}`
      );
      setMessages(data?.results);
      setCurrentPage(data?.page);
      setCount(data?.count);
    } catch (error) {
      return handleError({
        error,
        message: "An error occured while fetching messages!",
      });
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setSearchParams({ ...searchParams, s: searchInput });
  };

  useEffect(() => {
    getMessages();
  }, [customerID, searchParams]);

  const handleNext = () => {
    const totalPages = Math.ceil(count / perPage);
    const newPage = currentPage + 1;
    if (newPage > totalPages) return;
    setSearchParams({ ...searchParams, page: newPage });
  };

  const handlePrev = () => {
    const newPage = currentPage - 1;
    if (newPage < "1") return;
    setSearchParams({ ...searchParams, page: newPage });
  };

  return (
    <>
      <section className=" dark:bg-gray-900 ">
        <div className="w-full">
          <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
              <div className="w-full md:w-1/2">
                <form
                  className="flex items-center"
                  onSubmit={handleSearchSubmit}
                >
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <input
                      onChange={(e) => setSearchInput(e.target.value)}
                      type="text"
                      id="simple-search"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Search"
                      required=""
                      value={searchInput}
                    />
                  </div>
                </form>
              </div>
              <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
                <button
                  onClick={() => setShowAddMessageModal(true)}
                  type="button"
                  className="flex items-center justify-center text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"
                >
                  <svg
                    className="h-4 w-4 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path
                      clip-rule="evenodd"
                      fillRule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    />
                  </svg>
                  Message
                </button>
              </div>
            </div>
            <div className="overflow-x-auto">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 py-3">
                      SMS
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-4 py-3">
                      Delivered
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {messages?.map((message, index) => (
                    <tr className="border-b dark:border-gray-700" key={index}>
                      <th
                        scope="row"
                        className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {message?.phone_number}
                      </th>
                      <td className="px-4 py-3">{message?.sms}</td>
                      <td className="px-4 py-3">{message?.status}</td>
                      <td className="px-4 py-3">
                        {message?.delivered ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="green"
                            className="h-5 w-5"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="red"
                            className="h-5 w-5 "
                          >
                            <path
                              fillRule="evenodd"
                              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <nav
              className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {(page - 1) * perPage + 1} - {page * perPage}{" "}
                </span>
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {count}
                </span>
              </span>
              <ul className="inline-flex items-stretch -space-x-px">
                <li>
                  <a
                    href="#"
                    onClick={handlePrev}
                    className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <span className="sr-only">Previous</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={handleNext}
                    className="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  >
                    <span className="sr-only">Next</span>
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      {showAddMessageModal && (
        <AddMessageModal
          phoneNumbers={[customerData?.phone_number]}
          closeModal={() => setShowAddMessageModal(false)}
          reload={getMessages}
        />
      )}
    </>
  );
}
export default Messages;
