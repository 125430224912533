import { React, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { BsDot } from "react-icons/bs"
import { MdOutlineAddShoppingCart } from "react-icons/md"

function ProductView() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1)


    return (
        <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20" >
            <div className="flex flex-row justify-between">
                <div
                    onClick={() => navigate(-1)}
                    className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-4 mt-0.5"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
                        />
                    </svg>
                    Back
                </div>
            </div>

            <div className='w-full px-4 md:px-40 flex flex-col gap-2'>
                <div className="flex flex-row gap-3 items-center">
                    <p className='font-bold text-[24px]'>Featured deals- Sbsection Title</p>
                    {/* icon */}
                    <img src='/cdn/images/hot.svg' alt="" />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-6 gap-y-6 gap-2 w-full h-full py-4">
                    {/* <div className="md:grid md:grid-cols-5 flex flex-row gap-5 overflow-x-scroll w-full hide-scrollbar py-3"> */}

                    {/* Products */}
                    <div className='min-w-[14rem] font-satoshi h-full border flex flex-col gap-2 rounded-lg shadow-md bg-white relative'>
                        <div className="absolute top-0 right-10 bg-red-400 rounded-b-md px-2 pt-2 pb-3 text-white">
                            <p className='font-semibold'>25%</p>
                        </div>
                        <img
                            className='rounded-t-lg overflow-hidden mx-auto w-auto md:w-[11rem] h-[12rem]'
                            src="https://firebasestorage.googleapis.com/v0/b/sparepap-b0733.appspot.com/o/merchant%2FKE-DWCR-L1UZ7A%2Finventory%2FKE-4RP3-JTORVN.webp?alt=media&token=29786f2f-e76c-4652-b7dc-fe0942269739"
                            alt="" />
                        <div className="flex flex-col w-full p-2">
                            <p className='text-gray-900'>Nissan B14 bonnet</p>
                            <div className="flex flex-row gap-1 items-center text-gray-500 text-[13px]">
                                {/* brand */}
                                {/* <img src="" alt="" /> */}
                                <p>Tesla</p>
                                <BsDot />
                                <p>Used</p>
                                <BsDot />
                                <p>Shamas</p>
                            </div>
                            <p className='text-gray-900 font-semibold'>Ksh 15,000</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default ProductView
