import React from 'react'
import Accessory from "../components/cms/Accessory/AccessoryList"


function AccesoryPage() {
  return (
    <div>
      <Accessory/>
      
    </div>
  )
}

export default AccesoryPage
