import React from 'react'
import { useState } from 'react'
import Urls from '../components/urls/Urls'

const URLS_TAB = "Urls"

function UrlsPage() {
    const [activeMainTab, setActiveMainTab] = useState(URLS_TAB)

    return (
        <>
            <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20">
                <div className="flex flex-row gap-2 mt-4  scrollbar-hide w-full">
                    <div
                        onClick={() => setActiveMainTab(URLS_TAB)}
                        className={`whitespace-nowrap text-center cursor-pointer  text-xl font-semibold  py-1 px-3 rounded-md font-satoshi ${activeMainTab === URLS_TAB ? 'text-red-500 underline underline-offset-4 decoration-2' : ''}`}> Urls
                    </div>
                </div>
                {activeMainTab === URLS_TAB && <Urls />}
            </div>
        </>
    )
}

export default UrlsPage
