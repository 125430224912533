import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  PERMISSION_CHANGE_ORDER,
  PERMISSION_VIEW_CARPART,
  PERMISSION_VIEW_CONSUMER,
  PERMISSION_VIEW_DSF,
  PERMISSION_VIEW_JOB,
  PERMISSION_VIEW_MECHANIC,
  PERMISSION_VIEW_ORDER,
  PERMISSION_VIEW_PART_REQUEST,
  PERMISSION_VIEW_STAFF,
} from "../../constants";
import {
  ENDPOINT_BTOB,
  ENDPOINT_CUSTOMERS,
  ENDPOINT_DASHBOARD,
  ENDPOINT_DSF,
  ENDPOINT_INVENTORY,
  ENDPOINT_LEADS,
  ENDPOINT_MARKETPLACE,
  ENDPOINT_MECHANICS,
  ENDPOINT_ORDERS,
  ENDPOINT_PART_REQUESTS,
  ENDPOINT_SERVICE_BOOKING,
  ENDPOINT_SERVICE_CENTER,
  ENDPOINT_STAFF,
  ENDPOINT_TRACK_ORDERS,
  ENDPOINT_USERS,
  ENDPOINT_CMS,
  ENDPOINT_GENERATE_INVOICE,
  ENDPOINT_SHORT_URLS,
  ENDPOINT_REVIEWS,
} from "../../routes";
import { hasPermission } from "../../utils";

function Side() {
  const [activeTab, setActiveTab] = useState(1);
  const [current, setCurrent] = useState(window.location.pathname);

  const { pathname } = useLocation();
  console.log("pathname", pathname);

  const navigate = useNavigate();
  return (
    <div className="w-60 md:flex hidden h-full fixed flex-col border-r">
      <div className="flex flex-col h-screen pt-10 pl-4 pr-6 bg-white shadow w-full">
        <div className="space-y-3 w-full">
          <Link to={ENDPOINT_DASHBOARD}>
            <div className="flex ">
              <img
                src="/cdn/images/logo.svg"
                className="mr-3 cursor-pointer  h-7"
                alt="Sparepap Logo"
              />
            </div>
          </Link>
          <div className="flex flex-col w-full py-16">
            <ul className="pt-2 pb-4 space-y-4 text-sm w-full">
              <li
                className="rounded-sm w-full cursor-pointer pb-[20px]"
                onClick={() => navigate(ENDPOINT_DASHBOARD)}
              >
                <div className="flex items-center pl-4 pr-10 rounded-md">
                  <p
                    className={`text-xs md:text-[18px] ${
                      pathname === ENDPOINT_DASHBOARD
                        ? "text-[#E91A47]"
                        : "text-[#707070]"
                    } font-satoshi`}
                  >
                    Dashboard
                  </p>
                </div>
              </li>
              {(hasPermission(PERMISSION_VIEW_CONSUMER) ||
                hasPermission(PERMISSION_VIEW_CONSUMER)) && (
                <li
                  onClick={() => navigate(ENDPOINT_MARKETPLACE)}
                  className="rounded-sm w-full cursor-pointer pb-[20px]"
                >
                  <div className="flex items-center pl-4 pr-10 rounded-md">
                    <p
                      className={`text-xs md:text-[18px] ${
                        pathname === ENDPOINT_MARKETPLACE
                          ? "text-[#E91A47]"
                          : "text-[#707070]"
                      } font-satoshi`}
                    >
                      MarketPlace
                    </p>
                  </div>
                </li>
              )}
              {(hasPermission(PERMISSION_VIEW_MECHANIC) ||
                hasPermission(PERMISSION_VIEW_JOB)) && (
                <li
                  className="rounded-sm w-full cursor-pointer pb-[20px]"
                  onClick={() => navigate(ENDPOINT_MECHANICS)}
                >
                  <div className="flex items-center pl-4 pr-10 rounded-md">
                    <p
                      className={`text-xs md:text-[18px] ${
                        pathname === ENDPOINT_MECHANICS
                          ? "text-[#E91A47]"
                          : "text-[#707070]"
                      } font-satoshi`}
                    >
                      Mechanics
                    </p>
                  </div>
                </li>
              )}
              {false && (
                <li
                  onClick={() => navigate(ENDPOINT_ORDERS)}
                  className="rounded-sm w-full cursor-pointer pb-[20px]"
                >
                  <div className="flex items-center pl-4 pr-10 rounded-md">
                    <p
                      className={`text-xs md:text-[18px] ${
                        pathname === ENDPOINT_ORDERS
                          ? "text-[#E91A47]"
                          : "text-[#707070]"
                      } font-satoshi`}
                    >
                      Orders
                    </p>
                  </div>
                </li>
              )}

              {hasPermission(PERMISSION_VIEW_CARPART) && (
                <li
                  onClick={() => navigate(ENDPOINT_INVENTORY)}
                  className="rounded-sm w-full cursor-pointer pb-[20px]"
                >
                  <div className="flex items-center pl-4 pr-10 rounded-md">
                    <p
                      className={`text-xs md:text-[18px] ${
                        pathname === ENDPOINT_INVENTORY
                          ? "text-[#E91A47]"
                          : "text-[#707070]"
                      } font-satoshi`}
                    >
                      Inventory
                    </p>
                  </div>
                </li>
              )}
              {hasPermission(PERMISSION_VIEW_DSF) && (
                <li
                  onClick={() => navigate(ENDPOINT_DSF)}
                  className="rounded-sm w-full cursor-pointer pb-[20px]"
                >
                  <div className="flex items-center pl-4 pr-10 rounded-md">
                    <p
                      className={`text-xs md:text-[18px] ${
                        pathname === ENDPOINT_DSF
                          ? "text-[#E91A47]"
                          : "text-[#707070]"
                      } font-satoshi`}
                    >
                      DSF
                    </p>
                  </div>
                </li>
              )}
              {hasPermission(PERMISSION_VIEW_PART_REQUEST) && (
                <li
                  onClick={() => navigate(ENDPOINT_PART_REQUESTS)}
                  className="rounded-sm w-full cursor-pointer pb-[20px]"
                >
                  <div className="flex items-center pl-4 pr-10 rounded-md">
                    <p
                      className={`text-xs md:text-[18px] ${
                        pathname === ENDPOINT_PART_REQUESTS
                          ? "text-[#E91A47]"
                          : "text-[#707070]"
                      } font-satoshi`}
                    >
                      Part Requests
                    </p>
                  </div>
                </li>
              )}

              {hasPermission(PERMISSION_VIEW_STAFF) && (
                <li
                  onClick={() => navigate(ENDPOINT_STAFF)}
                  className="rounded-sm w-full cursor-pointer pb-[20px]"
                >
                  <div className="flex items-center pl-4 pr-10 rounded-md">
                    <p
                      className={`text-xs md:text-[18px] ${
                        pathname === ENDPOINT_STAFF
                          ? "text-[#E91A47]"
                          : "text-[#707070]"
                      } font-satoshi`}
                    >
                      Staff
                    </p>
                  </div>
                </li>
              )}
              <li
                onClick={() => navigate(ENDPOINT_CUSTOMERS)}
                className="rounded-sm w-full cursor-pointer pb-[20px]"
              >
                <div className="flex items-center pl-4 pr-10 rounded-md">
                  <p
                    className={`text-xs md:text-[18px] ${
                      pathname === ENDPOINT_CUSTOMERS
                        ? "text-[#E91A47]"
                        : "text-[#707070]"
                    } font-satoshi`}
                  >
                    Customers
                  </p>
                </div>
              </li>
              <li
                onClick={() => navigate(ENDPOINT_GENERATE_INVOICE)}
                className="rounded-sm w-full cursor-pointer pb-[20px]"
              >
                <div className="flex items-center pl-4 pr-10 rounded-md">
                  <p
                    className={`text-xs md:text-[18px] ${
                      pathname.includes(ENDPOINT_GENERATE_INVOICE)
                        ? "text-[#E91A47]"
                        : "text-[#707070]"
                    } font-satoshi`}
                  >
                    Invoices
                  </p>
                </div>
              </li>

              <li
                onClick={() => navigate(ENDPOINT_CMS)}
                className="rounded-sm w-full cursor-pointer pb-[20px]"
              >
                <div className="flex items-center pl-4 pr-10 rounded-md">
                  <p
                    className={`text-xs md:text-[18px] ${
                      pathname.includes(ENDPOINT_CMS)
                        ? "text-[#E91A47]"
                        : "text-[#707070]"
                    } font-satoshi`}
                  >
                    CMS
                  </p>
                </div>
              </li>

              <li
                onClick={() => navigate(ENDPOINT_SERVICE_CENTER)}
                className="rounded-sm w-full cursor-pointer pb-[20px]"
              >
                <div className="flex items-center pl-4 pr-10 rounded-md">
                  <p
                    className={`text-xs md:text-[18px] ${
                      pathname === ENDPOINT_SERVICE_BOOKING
                        ? "text-[#E91A47]"
                        : "text-[#707070]"
                    } font-satoshi`}
                  >
                    Services
                  </p>
                </div>
              </li>

              <li
                onClick={() => navigate(ENDPOINT_SHORT_URLS)}
                className="rounded-sm w-full cursor-pointer pb-[20px]"
              >
                <div className="flex items-center pl-4 pr-10 rounded-md">
                  <p
                    className={`text-xs md:text-[18px] ${
                      pathname === ENDPOINT_SHORT_URLS
                        ? "text-[#E91A47]"
                        : "text-[#707070]"
                    } font-satoshi`}
                  >
                    Urls
                  </p>
                </div>
              </li>

              <li
                onClick={() => navigate(ENDPOINT_REVIEWS)}
                className="rounded-sm w-full cursor-pointer pb-[20px]"
              >
                <div className="flex items-center pl-4 pr-10 rounded-md">
                  <p
                    className={`text-xs md:text-[18px] ${
                      pathname === ENDPOINT_REVIEWS
                        ? "text-[#E91A47]"
                        : "text-[#707070]"
                    } font-satoshi`}
                  >
                    Reviews
                  </p>
                </div>
              </li>

              {false && (
                <>
                  <li
                    onClick={() => setActiveTab(3)}
                    className="rounded-sm w-full cursor-pointer"
                  >
                    <div className="flex items-start pl-4  rounded-md">
                      <p
                        className={`text-xs md:text-sm ${
                          activeTab === 3 ? "text-[#E91A47]" : "text-[#707070]"
                        } text-[16px] font-satoshi`}
                      >
                        Service centers
                      </p>
                    </div>
                  </li>
                  <li
                    onClick={() => setActiveTab(4)}
                    className="rounded-sm w-full cursor-pointer"
                  >
                    <div className="flex items-start pl-4  rounded-md">
                      <p
                        className={`text-xs md:text-sm ${
                          activeTab === 4 ? "text-[#E91A47]" : "text-[#707070]"
                        } text-[16px] font-satoshi`}
                      >
                        All
                      </p>
                    </div>
                  </li>
                  <li
                    onClick={() => setActiveTab(5)}
                    className="rounded-sm w-full cursor-pointer"
                  >
                    <div className="flex items-start pl-4  rounded-md">
                      <p
                        className={`text-xs md:text-sm ${
                          activeTab === 5 ? "text-[#E91A47]" : "text-[#707070]"
                        } text-[16px] font-satoshi`}
                      >
                        Settings
                      </p>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Side;
