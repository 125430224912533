import React from 'react'

import HeroEdit from '../components/cms/Hero/HeroEdit'

function HeroEditPage() {
  return (
    <div>
      <HeroEdit/>
    </div>
  )
}

export default HeroEditPage