import React, { useEffect, useState, useRef } from "react";
import Avatar from "react-avatar";
import Moment from "react-moment";
import { Link, useSearchParams } from "react-router-dom";
import { ENDPOINT_JOBS, ENDPOINT_USERS } from "../routes";
import { makeAuthenticatedRequest } from "../utils";
import { Pagination } from "../components/Pagination";
import { toast } from "react-toastify";

const JOBS_TAB = "jobs";
const USERS_TAB = "users";

const MECHANICS_TAB = "mechanics";
const CUSTOMERS_TAB = "customers";

function MechanicsPage() {
  let currentUrlParams = new URLSearchParams(window.location.search);
  const [activeTab, setActiveTab] = useState(
    currentUrlParams.get("sd") === "users" ||
      currentUrlParams.get("sd") === "consumers"
      ? USERS_TAB
      : JOBS_TAB
  );
  const [currentUserTab, setCurrentUserTab] = useState(
    currentUrlParams.get("sd") === "users"
      ? MECHANICS_TAB
      : currentUrlParams.get("sd") === "consumers"
      ? CUSTOMERS_TAB
      : MECHANICS_TAB
  );

  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams);
  let [urlJobsParams, setUrlJobsParams] = useSearchParams(currentUrlParams);
  let [urlUsersParams, setUrlUsersParams] = useSearchParams(currentUrlParams);

  // console.log(currentUrlParams.has('page') )
  const [currentPage, setCurrentPage] = useState(
    currentUrlParams.has("page") && currentUrlParams.get("sd") === "jobs"
      ? Number(currentUrlParams.get("page"))
      : 1
  );

  const [showTabs, setShowTabs] = useState(false);
  const [showUserTabs, setShowUserTabs] = useState(false);
  const [users, setUsers] = useState([]);

  const [consumerCurrentPage, setConsumerCurrentPage] = useState(
    currentUrlParams.has("page") && currentUrlParams.get("sd") === "consumers"
      ? Number(currentUrlParams.get("page"))
      : 1
  );
  const [userCurrentPage, setUserCurrentPage] = useState(
    currentUrlParams.has("page") && currentUrlParams.get("sd") === "users"
      ? Number(currentUrlParams.get("page"))
      : 1
  );

  const [perPage, setPerPage] = useState(16);
  const [consumersPerPage, setConsumersPerPage] = useState(16);

  const [itemCount, setItemCount] = useState(0);
  const [consumerItemCount, setConsumerItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [jobsTotalItems, setJobsTotalItems] = useState(0);

  const [consumerTotalItems, setConsumerTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [searchConsumer, setSearchConsumer] = useState("");
  const [open, setOPen] = useState([]);

  const actionRef = useRef();

  const [consumerData, setConsumerdata] = useState(null);

  const fetchConsumers = (consumerpage) => {
    const params = {
      s: searchConsumer,
      page: consumerpage,
      per_page: perPage,
    };
    const consumersearchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/consumers/?${consumersearchParams}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setConsumerdata(data);
        setConsumersPerPage(data.per_page);
        // setCurrentPage(data.page)
        setPerPage(data.per_page);
        setConsumerItemCount(data.count);
        setConsumerTotalItems(data.count);
      });
  };

  const fetchUsers = (page) => {
    const params = {
      s: search,
      page: page,
      per_page: perPage,
    };
    const searchParams = new URLSearchParams(params).toString();

    makeAuthenticatedRequest(`/oms/mechanics/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("data",data)
        setUsers(data);
        // setCurrentPage(data.page)
        setPerPage(data.per_page);
        setItemCount(data.count);
        setTotalItems(data.count);
      });
  };

  const pageChanged = (pageNumber) => {
    currentUrlParams.set("sd", "users");
    currentUrlParams.set("page", pageNumber);

    setUserCurrentPage(Number(currentUrlParams.get("page")));
    setUrlUsersParams(currentUrlParams);
    fetchUsers(userCurrentPage);
  };

  const consumerPageChanged = (pageNumber) => {
    currentUrlParams.set("sd", "consumers");
    currentUrlParams.set("page", pageNumber);

    setConsumerCurrentPage(Number(currentUrlParams.get("page")));
    setUrlParams(currentUrlParams);
    fetchConsumers(Number(currentUrlParams.get("page")));
  };

  const switchToJobs = () => {
    currentUrlParams.set("sd", "jobs");
    currentUrlParams.set("page", 1);
    setUrlJobsParams(currentUrlParams);
    setActiveTab(JOBS_TAB);
  };

  const switchToUsers = () => {
    currentUrlParams.set("sd", "users");
    currentUrlParams.set("page", 1);
    setUrlUsersParams(currentUrlParams);
    setActiveTab(USERS_TAB);
  };

  const switchToConsumers = () => {
    currentUrlParams.set("sd", "consumers");
    currentUrlParams.set("page", 1);
    setUrlUsersParams(currentUrlParams);
    setCurrentUserTab(CUSTOMERS_TAB);
  };

  const switchToMechanics = () => {
    currentUrlParams.set("sd", "users");
    currentUrlParams.set("page", 1);
    setUrlUsersParams(currentUrlParams);
    setCurrentUserTab(MECHANICS_TAB);
  };

  const statusOptions = [
    { name: "REQUESTED", value: "R", label: "REQUESTED", color: "#B9E38D" },
    { name: "ACCEPTED", value: "A", label: "ACCEPTED", color: "#54D3CA" },
    { name: "STARTED", value: "S", label: "STARTED", color: "#8CD1FC" },
    { name: "TIMEOUT", value: "T", label: "TIMEOUT", color: "#707070" },

    { name: "COMPLETED", value: "C", label: "COMPLETED", color: "#059D5F" },
    { name: "DISPUTED", value: "D", label: "DISPUTED", color: "#F0A6A5" },
    { name: "CANCELLED", value: "X", label: "CANCELLED", color: "#DE6972" },
    { name: "REJECTED", value: "Z", label: "REJECTED", color: "#FCB28E" },
    { name: "FINISHED", value: "F", label: "FINISHED", color: "#54D3CA" },
    { name: "REPORTED", value: "M", label: "REPORTED", color: "#F0A6A5" },
  ];

  const handleShowOrderChange = (jobID) => {
    const openState = open.slice();
    const index = openState.indexOf(jobID);

    if (index >= 0) {
      openState.splice(index, 1);
      setOPen(openState);
    } else {
      openState.push(jobID);
      setOPen(openState);
    }
  };

  // console.log(open, 'open')

  useEffect(() => {
    let actionhandler = (e) => {
      if (actionRef.current && !actionRef.current.contains(e.target)) {
        setOPen([]);

        // console.log(actionRef.current)
      }
    };
    document.addEventListener("mousedown", actionhandler);

    return () => {
      document.removeEventListener("mousedown", actionhandler);
    };
  });

  useEffect(() => {
    fetchUsers(userCurrentPage);
    fetchConsumers(consumerCurrentPage);
  }, []);

  useEffect(() => {
    fetchUsers(userCurrentPage);
  }, [userCurrentPage]);

  // REQUESTED = 'R'
  // ACCEPTED = 'A'
  // STARTED = 'S'
  // TIMEOUT = 'T'
  // COMPLETED = 'C'
  // DISPUTED = 'D'
  // CANCELLED = 'X'
  // REJECTED = 'Z'
  // FINISHED = 'F'
  // REPORTED = 'M'

  // STATUSES = (
  //     (REQUESTED, 'Requested'),
  //     (TIMEOUT, 'Timeout'),
  //     (REJECTED, 'Rejected'),
  //     (ACCEPTED, 'Accepted'),
  //     (STARTED, 'Started'),
  //     (COMPLETED, 'Completed'),
  //     (DISPUTED, 'Disputed'),
  //     (CANCELLED, 'Cancelled'),
  //     (FINISHED, 'Finished'),
  //     (REPORTED, 'Reported'),
  // )

  const [jobStatus, setJobStatus] = useState("");
  const [activeStatusTab, setActiveStatusTab] = useState("All");
  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [jobsCurrentPage, setJobsCurrentPage] = useState(1);
  const [jobsPerPage, setJobsPerPage] = useState(10);

  const getJobs = async (page, perPage, status) => {
    try {
      const params = {
        page: page,
        per_page: perPage,
      };

      if (status) {
        params.status = status;
      }

      const searchParams = new URLSearchParams(params).toString();
      const response = await makeAuthenticatedRequest(
        `/oms/jobs/?${searchParams}`
      );
      const data = await response.json();

      setJobs(data?.results);
      setJobsPerPage(data?.per_page);
      setTotalJobs(data?.count);
      setJobsCurrentPage(data?.page);
      return data;
    } catch (error) {
      toast.error("Error fetching jobs");
      console.log(error);
    }
  };

  const jobsPageChanged = (pageNumber) => {
    getJobs(pageNumber, jobsPerPage, jobStatus);
  };

  const handleTabChange = (jobStatusTab) => {
    setActiveStatusTab(jobStatusTab);
    setJobsCurrentPage(1);

    const status = {
      All: "",
      Requested: "R",
      Accepted: "A",
      Started: "S",
      Timeout: "T",
      Completed: "C",
      Disputed: "D",
      Cancelled: "X",
      Rejected: "Z",
      Finished: "F",
      Reported: "M",
    };

    setJobStatus(status[jobStatusTab]);
  };

  useEffect(() => {
    getJobs(jobsCurrentPage, jobsPerPage, jobStatus);
  }, [jobStatus]);

  return (
    <>
      <div className="w-full  max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar  px-4 md:px-20">
        <div className="h-24 "></div>
        <p className="font-satoshi text-xl font-semibold">Mechanics</p>

        {activeTab === JOBS_TAB ? (
          <div
            onClick={() => setShowTabs(!showTabs)}
            className="flex flex-row md:hidden cursor-pointer py-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
              />
            </svg>
            <p className="font-satoshi text-[#030A1A] text-[14px] md:text-[14px] font-semibold">
              Nav
            </p>
          </div>
        ) : (
          <div
            onClick={() => setShowUserTabs(!showUserTabs)}
            className="flex flex-row md:hidden cursor-pointer py-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
              />
            </svg>
            <p className="font-satoshi text-[#030A1A] text-[14px] md:text-[14px] font-semibold">
              Nav
            </p>
          </div>
        )}

        <div className={` md:flex flex-col md:flex-row gap-2 mt-4 border-b`}>
          <div
            onClick={switchToJobs}
            className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi font-semibold text-[18px] ${
              activeTab === JOBS_TAB
                ? "text-[#D95762] underline underline-offset-8"
                : "text-[#808491]"
            }`}
          >
            Jobs
          </div>

          <div
            onClick={switchToUsers}
            className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi font-semibold text-[18px] ${
              activeTab === USERS_TAB
                ? "text-[#D95762] underline underline-offset-8"
                : "text-[#808491]"
            }`}
          >
            Users
          </div>
        </div>
        {activeTab === JOBS_TAB && (
          <div className="flex flex-col w-full">
            <div
              className={` ${
                showTabs ? "" : "hidden"
              } md:flex flex-col md:flex-row gap-2 mt-4`}
            >
              <div
                onClick={() => handleTabChange("All")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "All"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                All
              </div>

              <div
                onClick={() => handleTabChange("Requested")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Requested"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Requested
              </div>

              <div
                onClick={() => handleTabChange("Timeout")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Timeout"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Timeout
              </div>

              <div
                onClick={() => handleTabChange("Rejected")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Rejected"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Rejected
              </div>

              <div
                onClick={() => handleTabChange("Accepted")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Accepted"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Accepted
              </div>

              <div
                onClick={() => handleTabChange("Started")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Started"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Started
              </div>

              <div
                onClick={() => handleTabChange("Completed")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Completed"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Completed
              </div>

              <div
                onClick={() => handleTabChange("Disputed")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Disputed"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Disputed
              </div>

              <div
                onClick={() => handleTabChange("Cancelled")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Cancelled"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Cancelled
              </div>

              <div
                onClick={() => handleTabChange("Finished")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Finished"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Finished
              </div>

              <div
                onClick={() => handleTabChange("Reported")}
                className={`text-left md:text-center cursor-pointer py-1 px-3 rounded-md font-satoshi ${
                  activeStatusTab === "Reported"
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Reported
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex flex-col ">
                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                  {jobs.length > 0 ? (
                    <table class="table-auto w-full  ">
                      <thead className="pl-2 border-b border-b-gray-200">
                        <tr className="pl-1">
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Job #
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Mechanic
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Client
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Time
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {jobs?.map((job) => (
                          <tr
                            className="p-2 border-b border-b-gray-200 relative"
                            key={job?.id}
                          >
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <Link to={`${ENDPOINT_JOBS}/${job.id}`}>
                                <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                  SPP-{job?.id}
                                </p>
                              </Link>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                {job?.mechanic?.mechanic_name?.toUpperCase()}
                              </p>
                              <p className="font-satoshi text-left text-[13px] text-[#808491]">
                                +{job?.mechanic?.phone_number}
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                {job?.consumer?.name?.toUpperCase()}
                              </p>
                              <p className="font-satoshi text-left text-[13px] text-[#808491]">
                                +{job?.consumer?.phone_number}
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                <Moment
                                  className="text-[10px] md:text-[14px] text-[#BF3054]"
                                  fromNow
                                >
                                  {job?.date_created}
                                </Moment>
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <div
                                ref={actionRef}
                                onClick={() => handleShowOrderChange(job?.id)}
                                className={` ${
                                  job?.status === "T"
                                    ? "text-white bg-[#707070]"
                                    : job.status === "C"
                                    ? "text-white bg-[#059D5F]"
                                    : job.status === "X"
                                    ? "text-white bg-[#DE6972]"
                                    : null
                                } text-xs flex flex-row cursor-pointer justify-between relative rounded px-3 py-2 font-satoshi text-center bg-[#B9E38D]`}
                              >
                                {job?.status === "R" ? (
                                  <p>REQUESTED</p>
                                ) : job.status === "A" ? (
                                  <p>ACCEPTED</p>
                                ) : job.status === "S" ? (
                                  <p>STARTED</p>
                                ) : job.status === "T" ? (
                                  <p>TIMEOUT</p>
                                ) : job.status === "C" ? (
                                  <p>COMPLETED</p>
                                ) : job.status === "D" ? (
                                  <p>DISPUTED</p>
                                ) : job.status === "X" ? (
                                  <p>CANCELLED</p>
                                ) : job.status === "Z" ? (
                                  <p>REJECTED</p>
                                ) : job.status === "F" ? (
                                  <p>FINISHED</p>
                                ) : job.status === "M" ? (
                                  <p>REPORTED</p>
                                ) : null}
                              </div>

                              {open.includes(job.id) && (
                                <div
                                  className={`bg-white border-4 border-white rounded-md p-1 flex flex-col gap-2 w-[17%] hide-scrollbar overflow-y-scroll absolute top-14 z-50 shadow-2xl right-2`}
                                >
                                  {statusOptions
                                    .filter(
                                      (statusOption) =>
                                        statusOption.name !== job.status
                                    )
                                    .map((status, index) => (
                                      <div
                                        className={`bg-[${status.color}] rounded-md px-4 py-1.5 cursor-pointer`}
                                        key={index}
                                      >
                                        <p className="text-[13px] font-satoshi cursor-pointer">
                                          {status.name}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <Pagination
                        pageChanged={jobsPageChanged}
                        totalItems={totalJobs}
                        itemsPerPage={jobsPerPage}
                        currentPage={jobsCurrentPage}
                      />
                    </table>
                  ) : (
                    <>No {activeStatusTab} jobs to display.</>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {activeTab === USERS_TAB && (
          <div className="flex flex-col w-full">
            <div
              className={` ${
                showUserTabs ? "" : "hidden"
              } md:flex flex-col md:flex-row gap-2 mt-4`}
            >
              <div
                onClick={switchToMechanics}
                className={`text-left md:text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${
                  currentUserTab === MECHANICS_TAB
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Mechanics
              </div>

              <div
                onClick={switchToConsumers}
                className={`text-left md:text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${
                  currentUserTab === CUSTOMERS_TAB
                    ? "text-white bg-[#333B51]"
                    : "text-[#808491] hover:bg-[#E5E6E9]"
                }`}
              >
                Customers
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col ">
                {currentUserTab === MECHANICS_TAB && (
                  <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                    <table class="table-auto w-full  ">
                      <thead className="pl-2 border-b border-b-gray-200">
                        <tr className="pl-1">
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide gap-1 text-left text-gray-400">
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide  gap-1 text-left text-gray-400">
                            Name
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Jobs completed
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Rating
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Entity type
                          </th>
                          {/* <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Last active</th> */}
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Contact
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide gap-1 text-left text-gray-400"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.results?.map((user) => (
                          <tr
                            key={user.id}
                            className="p-2 border-b border-b-gray-200 relative"
                          >
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              {user.mechanic_thumbnail ? (
                                <img
                                  src={user.mechanic_thumbnail}
                                  alt=""
                                  className="rounded-full w-6 h-6"
                                />
                              ) : (
                                <Avatar
                                  name={user.mechanic_name}
                                  size="32"
                                  textSizeRatio={1.75}
                                  className="rounded-full shadow"
                                />
                              )}
                            </td>

                            <Link to={`${ENDPOINT_USERS}/mechanic/${user.id}`}>
                              <td className="p-4 text-xs md:text-sm capitalize cursor-pointer flex flex-row">
                                <p className="font-satoshi text-left text-[#030A1A]">
                                  {user.mechanic_name}
                                </p>
                              </td>
                            </Link>
                            <td className="p-4 text-xs md:text-sm cursor-pointer ">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                3
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer ">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                {user.mechanic_rating}
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                {user.get_mechanic_entity_type_display}
                              </p>
                            </td>
                            {/* <td
                              className='p-4 text-xs md:text-sm cursor-pointer'>
                                <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>3d 22 hr</p>
                                 
                            </td> */}
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <p className="font-satoshi text-left text-[13px] text-[#808491]">
                                +{user.phone_number}
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              {user.is_mechanic_online ? (
                                <div className="font-satoshi text-center text-[14px] px-4 py-1 rounded-md bg-[#ECF0FD]">
                                  Online
                                </div>
                              ) : (
                                <div className="font-satoshi text-center text-[14px] px-4 py-1 rounded-md bg-[#F8DDDF]">
                                  Offline
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      pageChanged={pageChanged}
                      totalItems={totalItems}
                      itemsPerPage={perPage}
                      currentPage={userCurrentPage}
                    />
                  </div>
                )}
                {currentUserTab === CUSTOMERS_TAB && (
                  <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                    <table class="table-auto w-full  ">
                      <thead className="pl-2 border-b border-b-gray-200">
                        <tr className="pl-1">
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide  gap-1 text-left text-gray-400">
                            Name
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Jobs requested
                          </th>
                          {/* <th className='pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400'>Jobs completed</th> */}
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Rating
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Location
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                            Contact
                          </th>
                          <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide gap-1 text-left text-gray-400"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {consumerData?.results.map((customer) => (
                          <tr className="p-2 border-b border-b-gray-200 relative">
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <Avatar
                                name={
                                  customer.name
                                    ? customer.name
                                    : customer.first_name
                                }
                                size="32"
                                textSizeRatio={1.75}
                                className="rounded-full shadow"
                              />
                            </td>
                            <Link to={`${ENDPOINT_USERS}/${customer.id}`}>
                              <td className="p-4 text-xs md:text-sm capitalize cursor-pointer flex flex-row">
                                <p className="font-satoshi text-left text-[#030A1A]">
                                  {customer.name
                                    ? customer.name
                                    : customer.first_name}
                                </p>
                              </td>
                            </Link>
                            <td className="p-4 text-xs md:text-sm cursor-pointer ">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                -
                              </p>
                            </td>

                            <td className="p-4 text-xs md:text-sm cursor-pointer ">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                -
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <p className="font-satoshi text-left text-[#030A1A] text-[16px]">
                                {customer.addresses.length > 0
                                  ? customer.addresses[0].town
                                  : "-"}
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <p className="font-satoshi text-left text-[13px] text-[#808491]">
                                <a href="tel:+{user.phone_number}">
                                  +{customer.phone_number}
                                </a>
                              </p>
                            </td>
                            <td className="p-4 text-xs md:text-sm cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
                                />
                              </svg>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      pageChanged={consumerPageChanged}
                      totalItems={consumerTotalItems}
                      itemsPerPage={consumersPerPage}
                      currentPage={consumerCurrentPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MechanicsPage;
