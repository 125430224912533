import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { formatDateAndTimeAgo, formatNumber, makeAuthenticatedRequest } from '../utils';
import { Pagination } from './Pagination';
import { BsDot } from 'react-icons/bs';
import { ENDPOINT_RFQS } from '../routes';

const BClientRfqs = ({clientId}) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
    const [currentPage, setCurrentPage] = useState(currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)
    const [rfqs, setRfqs] = useState([])
    const [perPage, setPerPage] = useState(20)
    const[pages, setPages] = useState(0)
    const [itemCount, setItemCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [processing, setProcessing] = useState(false)
  
    const fetchRfqs = (page, clientID) => {
        setProcessing(true)
      const params = {
        "s": search,
        "page": page,
        "per_page": perPage,
      }
      const searchParams = new URLSearchParams(params).toString();
      makeAuthenticatedRequest(`/oms/btobclients/${clientID}/rfqs/?${searchParams}`)
        .then((response) => response.json())
        .then((data) => {
          console.log('rfq data',data)
          setRfqs(data)
          setCurrentPage(data?.page)
          setPerPage(data?.per_page)
          setPages(data?.number_of_pages)
          setItemCount(data?.count)
          setTotalItems(data?.count)
          setProcessing(false)
        }).catch((error) => {
          setProcessing(false)
          console.log('error',error)
    })
    }
    
    const pageChanged = (pageNumber) => {
      // console.log('called')
      currentUrlParams.set('page', pageNumber)
      setCurrentPage(Number(currentUrlParams.get('page')))
      setUrlParams(currentUrlParams)    
      fetchRfqs(Number(currentUrlParams.get('page')))
    }
    
    useEffect(() => {
      fetchRfqs(currentPage, clientId)
    }, [currentPage, clientId])
  return (
    <>
              {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }
      {rfqs?.results?.length > 0 ?
    <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                         <table class="table-auto w-full  ">
                           <thead className='pl-2'>
                             <tr className='pl-1 border-b border-gray-200'>

                               <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Order#</th>
                               <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400 flex-1 w-[60%] justify-start items-start'>Details</th>
                               <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Contact person</th>
                               <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Order date</th>

                               <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Status</th>

                             </tr>
                           </thead>
                           <tbody>
                           {rfqs?.results?.slice().reverse().map((order) => (
                                     
                                 <tr className='p-3 border-b border-b-gray-200 font-satoshi relative'>
                                   
                                   <td
                                   className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                       <Link to={`${ENDPOINT_RFQS}/${order?.id}`}>
                                     #{order?.id}
                                       </Link>
                                   </td>
                                   {/* </Link> */}
                                 <td className='pl-2 text-xs md:text-[16px] flex-1 w-[40%]'>
                                   <p className='font-semibold text-[18px] text-gray-600'>
                                   {order?.customer_name}
                                   </p>
                                   <div className="flex flex-row items-center w-full gap-2 pt-1">
                                     <p className='text-[16px] text-gray-400'>
                                       {order?.parts?.length} Order {order?.parts?.length ===1 ? '':'s'}
                                     </p>
                                     <BsDot size={25} className='text-gray-500'/>
                                     <p className='text-[16px] text-gray-400'>
                                     Ksh. {formatNumber(order?.total_price, 0, 0)} total sales
                                     </p>
                                     <BsDot size={25} className='text-gray-500'/>
                                     <p className='text-[16px] text-teal-400'>
                                       {order?.status}
                                     </p>
                                   </div>
                                   </td>
                                   <td className='pl-2 text-xs md:text-[16px] '>
                                   <p className='text-[18px] text-gray-600'>
                                   {order?.client?.customer_name}
                                   </p>
                                   <p className='text-[16px] text-gray-400'>
                                   {order?.client?.email_address}
                                     </p>
                                   </td>
                                   <td className='pl-2 text-xs md:text-[16px]'>
                                   <p className='text-gray-500'>
                                   {formatDateAndTimeAgo(order?.date_created)}
                                   </p>
                                   </td>
                                   <td className={`p-4 text-xs md:text-[16px] text-left font-satoshi capitalize`}>
                                   <div className={`text-center px-4 py-1.5 rounded-md ${order.status === 'New' ?
                                 'bg-[#B9E38D]'
                                   : order.status === 'Cancelled' ?
                                     'bg-[#DE6972]'
                                     : order.status === 'Quote sent' ?
                                       'bg-[#54D3CA]'
                                       : order.status === 'LPO sent' ?
                                         'bg-[#8CD1FC]'
                                         : null}`}>
   
                                   {order?.status}
                                         </div>
                                 </td>
                                 </tr>
                             ))}

                           </tbody>
                         </table>
                         {rfqs?.results?.length > 0 &&
                         <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} pages={pages} />
                         }
   </div>
   :
   <div className="flex flex-col justify-center items-center">
    <p>Client does not have any RFQs at the moment</p>
   </div>
   }
   </>
  )
}

export default BClientRfqs