import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import { formatDate, formatDateAndTimeAgo, formatNumber, makeAuthenticatedRequest } from '../utils';
import { Pagination } from './Pagination';
import { BsDot } from 'react-icons/bs';
import { ENDPOINT_INVOICES } from '../routes';

const InvoicesData = () => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
    const [currentPage, setCurrentPage] = useState(currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)
    const [invoices, setInvoices] = useState([])
    const [perPage, setPerPage] = useState(20)
    const[pages, setPages] = useState(0)
    const [itemCount, setItemCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [search, setSearch] = useState('')
    const [processing, setProcessing] = useState(false)
  
    const fetchInvoices = (page) => {
        setProcessing(true)
      const params = {
        "s": search,
        "page": page,
        "per_page": perPage,
      }
      const searchParams = new URLSearchParams(params).toString();
      makeAuthenticatedRequest(`/oms/invoices/?${searchParams}`)
        .then((response) => response.json())
        .then((data) => {
          console.log('Invoices',data)
          setInvoices(data)
          setCurrentPage(data?.page)
          setPerPage(data?.per_page)
          setPages(data?.number_of_pages)
          setItemCount(data?.count)
          setTotalItems(data?.count)
          setProcessing(false)
        }).catch((error) => {
          setProcessing(false)
          console.log(error)
    })
    }
    
    const pageChanged = (pageNumber) => {
      // console.log('called')
      currentUrlParams.set('page', pageNumber)
      setCurrentPage(Number(currentUrlParams.get('page')))
      setUrlParams(currentUrlParams)    
      fetchInvoices(Number(currentUrlParams.get('page')))
    }
    
    useEffect(() => {
      fetchInvoices(currentPage)
    }, [currentPage])
  return (
    <>
    {
processing &&
<div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
<img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
<h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
</div>
}
{invoices?.results?.length > 0 ?
<div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
           <table class="table-auto w-full  ">
             <thead className='pl-2'>
               <tr className='pl-1 border-b border-gray-200'>

                 <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Invoice#</th>
                 <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Total parts</th>
                 <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Total price</th>
                 <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Invoice date</th>
                 <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Invoice due data</th>
                 <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Overdue</th>

               </tr>
             </thead>
             <tbody>
             {invoices?.results?.slice().reverse().map((order) => (
                       
                   <tr className='p-3 border-b border-b-gray-200 font-satoshi relative'>
                     
                     <td
                     className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                         <Link to={`${ENDPOINT_INVOICES}/${order?.id}`}>
                       #{order?.id}
                         </Link>
                     </td>
                     {/* </Link> */}
                   <td className='pl-2 text-xs md:text-[16px] flex-1 w-[40%]'>
                   <Link to={`${ENDPOINT_INVOICES}/${order?.id}`}>
                      
                       <p className='text-[16px] text-teal-400'>
                         {order?.grn?.grn_parts.length}
                       </p>
                       </Link>
                     </td>
                     <td className='pl-2 text-xs md:text-[16px]'>
                     <Link to={`${ENDPOINT_INVOICES}/${order?.id}`}>
                     <p className='text-gray-500'>
                     Ksh. {formatNumber(order?.total_parts_price, 0, 0)}
                     </p>
                     </Link>
                     </td>
                     <td className='pl-2 text-xs md:text-[16px]'>
                     <Link to={`${ENDPOINT_INVOICES}/${order?.id}`}>
                     <p className='text-gray-500'>
                     {formatDate(order?.date_created)}
                     </p>
                     </Link>
                     </td>
                     <td className='pl-2 text-xs md:text-[16px]'>
                     <Link to={`${ENDPOINT_INVOICES}/${order?.id}`}>

                     <p className='text-gray-500'>
                     {formatDate(order?.over_due_date)}
                     </p>
                     </Link>
                     </td>
                     <td className='pl-2 text-xs md:text-[16px]'>
                     <p className='text-gray-500'>
                     {order?.over_due ? 'Yes':"No"}
                     </p>
                     </td>
                   </tr>
               ))}
                      
             </tbody>
           </table>
           {invoices?.results?.length > 0 &&
           <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} pages={pages} />
           }
</div>
:
<div className="flex flex-col justify-center items-center">
<p>We don't have any Invoices at the moment</p>
</div>
}
</>
  )
}

export default InvoicesData