import { Outlet } from 'react-router-dom'
import Topbar from '../Topbar'
import Side from './Side'
import React from "react";

function Layout() {

  const open = [1, 2, 3, 4]
  const oplenLen = open.length

  return (
    <div className="flex flex-no-wrap">
      <div className="w-60 absolute sm:relative md:h-full flex-col justify-between sm:flex">
        <Side />
      </div>
      <div className="container md:w-4/5 w-11/12 max-h-screen">
        <Topbar len={oplenLen} />
        <div className="w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Layout;
