import React, { useState } from 'react'
import Gradient from '../components/Gradient'
import EnterEmail from './EnterEmail'
import EnterCode from './EnterCode'

function LoginPage() {
  const [email, setEmail] = useState()
  const [verifyID, setVerifyID] = useState()
  const onHandleEmailChange = (recievedEmail, receivedID) => {
    setEmail(recievedEmail)
    setVerifyID(receivedID)
  }

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 h-screen'>
        <Gradient />
        { email && verifyID ?
          <EnterCode email={email} verificationID={verifyID} />
          :
          <EnterEmail handleEmailChange={onHandleEmailChange} />
        }
      </div>
    </>
  )
}

export default LoginPage