import React, { useEffect, useState } from 'react'
import { defaultUsersConfig } from './defaultChartConfig'
import Chart from 'react-apexcharts';
import { formatNumber, makeAuthenticatedRequest } from '../../utils';

export default function UserStats() {
    const [todayCount, setTodayCount] = useState(0)
    const [liveCount, setLiveCount] = useState(0)
    const [series, setSeries] = useState(defaultUsersConfig.series)
    const [chartOptions, setChartOptions] = useState(defaultUsersConfig)
    const [processing, setProcessing] = useState(false)

    const fetchData = () => {
        setProcessing(true)
        const url = `/oms/analytics/visitors/`

        makeAuthenticatedRequest(url)
            .then((response) => response.json())
            .then((data) => {
                const series = structuredClone(chartOptions.series)
                series[0].data = data?.series
                series[0].name = "Users"
                setSeries(series)
                setTodayCount(data?.today_count)
                setLiveCount(data?.live_count)
                setChartOptions(prev => {
                    return { ...prev, xaxis: { ...prev.xaxis, categories: data?.labels } }
                });
                setProcessing(false)
            });
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {processing ?
                <div class="border border-gray-200 rounded-lg shadow-sm shadow rounded-md p-4 w-full mx-auto">
                    <div class="animate-pulse flex space-x-4">
                        <div class="flex-1 space-y-6 py-1">
                            <div class="h-6 bg-slate-200 rounded"></div>
                            <div class="space-y-3">
                                <div class="grid grid-cols-3 gap-4">
                                    <div class="h-10 bg-slate-200 rounded col-span-2"></div>
                                    <div class="h-10 bg-slate-200 rounded col-span-1"></div>
                                </div>
                                <div class="h-6 bg-slate-200 rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div
                    class="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:flex sm:p-6">
                    <div class="w-full">
                        <h3 class="text-base font-normal text-gray-500">Users</h3>
                        <span class="text-2xl font-bold leading-none text-gray-900 sm:text-3xl">{formatNumber(todayCount, 0, 0)} <span className='text-xs font-semibold'>today</span></span>
                        {
                            parseInt(liveCount) > 0 &&
                            <p class="flex items-center text-base font-normal text-gray-500">
                                <span class="flex items-center mr-1.5 text-sm text-green-500">
                                    {formatNumber(liveCount, 0, 0)}
                                </span>
                                past 30 mins
                            </p>
                        }
                    </div>
                    <div class="w-full" id="new-products-chart">
                        <Chart
                            type='bar'
                            series={series}
                            options={chartOptions}
                        />

                    </div>
                </div>
            }
        </>
    )
}
