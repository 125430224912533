import React from 'react'
import AccessoryAdd from '../components/cms/Accessory/AccessoryAdd'

function AccessoryAddPage() {
  return (
    <div>
        <AccessoryAdd/>
    </div>
  )
}

export default AccessoryAddPage