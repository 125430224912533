const {
  REACT_APP_CLIENT_KEY,
  REACT_APP_API_PREFIX,
  REACT_APP_WS_PREFIX,
  REACT_APP_MARKETPLACE_HOME_URL,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_URL_SHORTENER_PREFIX,
} = process.env;
export const CLIENT_KEY = REACT_APP_CLIENT_KEY;
export const API_PREFIX = REACT_APP_API_PREFIX;
export const URL_SHORTENER_PREFIX = REACT_APP_URL_SHORTENER_PREFIX;
export const WS_PREFIX = REACT_APP_WS_PREFIX;
export const GOOGLE_MAPS_API_KEY = REACT_APP_GOOGLE_MAPS_API_KEY;
export const MARKETPLACE_HOME_URL = REACT_APP_MARKETPLACE_HOME_URL;
export const SESSION_STORAGE_VERIFY_ID = "FuiVEZSt4bomsv2";
export const SESSION_STORAGE_VERIFY_EMAIL = "FuiVEZSt4aomsv2";
export const LS_ACCESS_TOKEN = "FuiVEZSt4Qomsv2";
export const LS_ACCESS_TOKEN_TYPE = "nfKfI0WrsQomsv2";
export const LS_REQUEST_TOKEN = "5IPlA_yO6Qomsv2";
export const LS_REQUEST_TOKEN_TYPE = "XMKYY3oyKQomsv2";
export const LS_ACCOUNT_ID = "WqowiqYCVQomsv2";
export const LS_ACCOUNT_LAST_NAME = "X5asDqSfSdomsv2";
export const LS_ACCOUNT_FIRST_NAME = "X5asDqSfSwomsv2";
export const LS_ACCOUNT_USERNAME = "bS3xbYVWbAomsv2";
export const LS_ACCOUNT_PERMISSIONS = "bS3xbYVWbBomsv2";

export const PERMISSION_VIEW_STAFF = "oms_view_staff";
export const PERMISSION_CHANGE_STAFF = "oms_change_staff";
export const PERMISSION_VIEW_BRAND = "oms_view_brand";
export const PERMISSION_CHANGE_BRAND = "oms_change_brand";
export const PERMISSION_VIEW_VEHICLE = "oms_view_vehicle";
export const PERMISSION_CHANGE_VEHICLE = "oms_change_vehicle";
export const PERMISSION_VIEW_ORDER = "oms_view_order";
export const PERMISSION_CHANGE_ORDER = "oms_change_order";
export const PERMISSION_VIEW_CARPART = "oms_view_carpart";
export const PERMISSION_CHANGE_CARPART = "oms_change_carpart";
export const PERMISSION_VIEW_CONSUMER = "oms_view_consumer";
export const PERMISSION_CHANGE_CONSUMER = "oms_change_consumer";
export const PERMISSION_VIEW_MERCHANT = "oms_view_merchant";
export const PERMISSION_CHANGE_MERCHANT = "oms_change_merchant";
export const PERMISSION_VIEW_MECHANIC = "oms_view_mechanic";
export const PERMISSION_CHANGE_MECHANIC = "oms_change_mechanic";
export const PERMISSION_VIEW_JOB = "oms_view_job";
export const PERMISSION_CHANGE_JOB = "oms_change_job";
export const PERMISSION_VIEW_DSF = "oms_view_dsf";
export const PERMISSION_CHANGE_DSF = "oms_change_dsf";
export const PERMISSION_VIEW_PART_REQUEST = "oms_view_part_request";
export const PERMISSION_CHANGE_PART_REQUEST = "oms_change_part_request";

export const VEHICLE_TYPES = [
  {
    label: "Cars & SUVs",
    value: "Cars & SUVs",
  },
  {
    label: "Trucks, Vans & Buses",
    value: "Trucks & Buses",
  },
  {
    label: "Motorcyles & Scooters",
    value: "Motorcycles",
  },
];

export const ENGINE_TYPES = [
  {
    label: "Petrol",
    value: "Petrol",
  },
  {
    label: "Diesel",
    value: "Diesel",
  },
  {
    label: "Hybrid",
    value: "Hybrid",
  },
];

export const PartType = { 
  OEM: "oem", 
  AFTER_MARKET: "after-market", 
  DISPLAY: function(value) {
      if(value == PartType.AFTER_MARKET){
          return "Aftermarket"
      }
      if(value == PartType.OEM){
          return "OEM"
      }
  } 
}

export const VEHICLE_YEARS = () => {
  const years = [];
  const d = new Date();
  const year = d.getFullYear();
  for (let i = year; i > 1990; i--) {
    years.push({
      label: `${i}`,
      value: `${i}`,
    });
  }
  return years;
};

export const CUSTOMER_TYPES = [
  {
    value: "internal",
    label: "INTERNAL",
  },
  {
    value: "b2b",
    label: "B2B",
  },
  {
    value: "b2c",
    label: "B2C",
  },
];
