import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { makeAuthenticatedRequest } from '../../utils'
import { ENDPOINT_SERVICE_BOOKING } from '../../routes'
import { Pagination } from '../Pagination'

const ALL_TAB = 'all'
const ONGOING_TAB = "ongoing"
const COMPLETE_TAB = "complete"
const COLLECTED_TAB = "collected"

function Bookings() {
    const [data, setData] = useState()
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [perPage, setPerPage] = useState(16)
    const [processing, setProcessing] = useState(false)
    const [currentTab, setCurrentTab] = useState(ALL_TAB)
    const [addServiceCenter, setAddServiceCenter] = useState(false)
    const [totalItems, setTotalItems] = useState(0)
  

    const fetchData = (page) => {
        setProcessing(true)
        const params = {
            "s": search,
            "page": page,
            "per_page": perPage,
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/bookings/?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setData(data)
                setProcessing(false)
                setTotalItems(data.count)
            });
    }

    const submitSearch = () => {
        setPage(1)
        fetchData(1)
    }

    useEffect(() => {
        fetchData(1)
    }, [])

    const pageChanged = (pageNumber) => {
        setPage(pageNumber)
        fetchData(pageNumber)

      }



    return (
        <>
            {
                processing &&
                <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
                    <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
                    <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
                </div>
            }
            <div className="flex flex-col">
                <div className="flex flex-col ">

                    <div className='mt-2 mb-2'>
                        <form className="flex items-start md:items-center font-satoshi" method="get">
                            <label htmlFor="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div
                                    onClick={submitSearch}
                                    className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md">
                                    <svg aria-hidden="true" className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <input
                                    onChange={e => setSearch(e.target.value)}
                                    required
                                    value={search}
                                    type="text" name="s"
                                    id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    " placeholder="Search for a booking" />
                            </div>
                        </form>
                    </div>

                    {
                        false &&
                        <div className="flex flex-row gap-2 mt-4 overflow-x-scroll scrollbar-hide">
                            <div
                                onClick={() => setCurrentTab(ALL_TAB)}
                                className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === ALL_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}> All
                                <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{0}</span>
                            </div>
                            <div
                                onClick={() => setCurrentTab(ONGOING_TAB)}
                                className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === ONGOING_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Ongoing
                                <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{0}</span>
                            </div>
                            <div
                                onClick={() => setCurrentTab(COMPLETE_TAB)}
                                className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === COMPLETE_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Complete
                                <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{0}</span>
                            </div>
                            <div
                                onClick={() => setCurrentTab(COLLECTED_TAB)}
                                className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === COLLECTED_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Collected
                                <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{0}</span>
                            </div>
                        </div>
                    }


                    <div className="flex flex-col ">
                        {
                            !processing && data ? (
                                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                                    <table class="table-auto w-full  ">
                                        <thead className='pl-2'>
                                            <tr className='pl-1 border-b border-gray-200'>
                                                <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Customer</th>
                                                <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Vehicle</th>
                                                <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Service center</th>
                                                <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Appointment Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.results.map((item, index) => (

                                                    <tr className='p-2 border-b border-b-gray-200 relative'>
                                                        <td
                                                            className='p-2 md:text-[16px] text-left font-satoshi inline-block hover:underline'>
                                                            <Link to={`${ENDPOINT_SERVICE_BOOKING}/${item?.id}`}>
                                                                <p>
                                                                    {
                                                                        (item.customer.first_name || item.customer.last_name) ?
                                                                            <span>{item.customer.first_name}</span> :
                                                                            <span>{item.customer.name}</span>
                                                                    }
                                                                </p>
                                                                { item.car_number_plate && 
                                                                    <p className=' text-xs uppercase text-amber-500'>{item.car_number_plate}</p>
                                                                
                                                                }
                                                            </Link>
                                                        </td>
                                                        <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                            <div className=" text-[10px] md:text-[14px] ">
                                                                {item.car_specs && <span>{item.car_specs.make} {item.car_specs.model} {item.car_specs.variant} {item.car_specs.year}</span>}
                                                            </div>
                                                        </td>

                                                        <td className='p-2 text-xs md:text-[16px] text-left font-satoshi'>
                                                            <div className=" text-[10px] md:text-[14px] ">
                                                                {item.service_center && item.service_center.name}
                                                            </div>
                                                        </td>


                                                        <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                                            <div className=" text-[10px] md:text-[14px] ">
                                                                {
                                                                    item.appointment_date &&
                                                                    <Moment format="MMM, DD LT">
                                                                        {item.appointment_date}
                                                                    </Moment>
                                                                }
                                                            </div>

                                                        </td>
                                                    </tr>
                                                ))
                                            }



                                        </tbody>
                                    </table>
                                    {data.results.length > 0 &&
                                    <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={page} />
                                }
                                </div>
                            ) :
                                <>
                                    {!processing &&
                                        <p className='font-satoshi text-[18px] py-6'>No bookings to display</p>
                                    }
                                </>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
export default Bookings