import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { axiosCustom } from "../../utils";
import Avatar from "react-avatar";
import Moment from "react-moment";
import EditProfileTab from "./modals/EditProfileModal";
import { getName } from "./utils";
import AddCarModal from "./modals/AddCarModal";
import { useParams } from "react-router-dom";

const CUSTOMERS_URL = `/oms/customers/`;

function ProfileTab() {
  const { customerID } = useParams();
  const [dataProfile, setDataProfile] = useState();
  const [dataCars, setDataCars] = useState();
  const [dataAddresses, setDataAddresses] = useState();
  const [dataAddresses2, setDataAddresses2] = useState();
  const [processingCars, setProcessingCars] = useState(false);
  const [showModalAddCar, setShowModalAddCar] = useState(false);
  const [processingProfile, setProcessingProfile] = useState(false);
  const [processingAddresses, setProcessingAddresses] = useState(false);
  const [showModalEditProfile, setShowModalEditProfile] = useState(false);

  const handleModalClose = () => {
    setShowModalEditProfile(false);
    setShowModalAddCar(false);
  };

  const handleReloadProfile = async () => {
    handleModalClose();
    await fetchDataProfile();
  };

  const handleReloadCars = async () => {
    handleModalClose();
    await fetchDataCars();
  };

  const fetchDataProfile = async () => {
    setProcessingProfile(true);
    try {
      const response = await axiosCustom.get(`${CUSTOMERS_URL}${customerID}`);
      setDataProfile(response?.data);
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setProcessingProfile(false);
    }
  };

  const fetchDataAddresses = async () => {
    setProcessingAddresses(true);
    try {
      const response = await axiosCustom.get(
        `${CUSTOMERS_URL}${customerID}/addresses/`
      );
      setDataAddresses2(response?.data?.results);
      if (response?.data?.results?.length > 2) {
        setDataAddresses(response?.data?.results?.slice(0, 2));
      } else {
        setDataAddresses(response?.data?.results);
      }
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setProcessingAddresses(false);
    }
  };

  const showMoreAddresses = () => {
    setDataAddresses(dataAddresses2);
  };
  const showLessAddresses = () => {
    if (dataAddresses2?.length > 2) {
      setDataAddresses(dataAddresses2?.slice(0, 2));
    }
  };

  const fetchDataCars = async () => {
    setProcessingAddresses(true);
    try {
      const response = await axiosCustom.get(
        `${CUSTOMERS_URL}${customerID}/cars/`
      );
      setDataCars(response?.data?.results);
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setProcessingAddresses(false);
    }
  };

  useEffect(() => {
    fetchDataProfile();
    fetchDataAddresses();
    fetchDataCars();
  }, []);

  return (
    <>
      {showModalEditProfile && dataProfile && (
        <EditProfileTab
          customerID={customerID}
          handleModalClose={handleModalClose}
          customer={dataProfile}
          handleReload={handleReloadProfile}
        />
      )}

      {showModalAddCar && dataProfile && (
        <AddCarModal
          customerID={customerID}
          handleModalClose={handleModalClose}
          customer={dataProfile}
          handleReload={handleReloadCars}
        />
      )}
      <div className="w-full">
        <div className="none mr-2 flex flex-row justify-start items-end mb-2">
          <h1 className="font-bold text-lg"></h1>
        </div>

        <div className="w-full  bg-white rounded-lg border-gray-300">
          <div className="p-3 mt-4 mb-4 bg-white border border-slate-200 shadow-sm  sm:rounded-lg">
            <div class="flex items-center justify-between border-b rounded-t ">
              <p className="mb-2 font-semibold text-sm">Account details</p>
              <button
                onClick={(e) => setShowModalEditProfile(true)}
                type="button"
                class="mb-1 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  className="h-4 w-4"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.9888 4.28491L19.6405 2.93089C18.4045 1.6897 16.4944 1.6897 15.2584 2.93089L13.0112 5.30042L18.7416 11.055L21.1011 8.68547C21.6629 8.1213 22 7.33145 22 6.54161C22 5.75176 21.5506 4.84908 20.9888 4.28491Z" />
                  <path d="M16.2697 10.9422L11.7753 6.42877L2.89888 15.3427C2.33708 15.9069 2 16.6968 2 17.5994V21.0973C2 21.5487 2.33708 22 2.89888 22H6.49438C7.2809 22 8.06742 21.6615 8.74157 21.0973L17.618 12.1834L16.2697 10.9422Z" />
                </svg>
              </button>
            </div>
            {processingProfile ? (
              <ReactLoading
                className="mb-10"
                type="bubbles"
                color="#030A1A"
                height={50}
                width={100}
              />
            ) : (
              <>
                <div className="mt-2 float-right flex flex-col">
                  {dataProfile?.thumbnail ? (
                    <img
                      className="w-20 h-20 bg-gray-400 rounded-full object-cover"
                      src={dataProfile?.thumbnail}
                      alt={"Profile photo for " + getName(dataProfile)}
                    />
                  ) : (
                    <Avatar
                      name={getName(dataProfile)}
                      size="80"
                      className="mr-2 rounded-full shadow"
                    />
                  )}
                </div>
                <div className="py-2 flex flex-col gap-3">
                  <div className="flex flex-col gap-2 md:flex-row md:gap-20 items-start md:items-center">
                    <p className="text-black text-left text-[14px] w-24">
                      Name
                    </p>
                    <p className="text-black font-bold text-sm text-left capitalize">
                      {getName(dataProfile)}
                    </p>
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row md:gap-20 items-start md:items-center">
                    <p className="text-black text-left text-[14px] w-24">
                      Email
                    </p>
                    <small className="text-gray-400 text-sm text-left">
                      {dataProfile?.email ? dataProfile.email : "-"}
                    </small>
                  </div>

                  <div className="flex flex-col gap-2 md:flex-row md:gap-20 items-start md:items-center">
                    <p className="text-black text-left text-[14px] w-24">
                      Phone Number
                    </p>
                    <small className="text-gray-400 text-sm text-left hover:underline">
                      {dataProfile?.phone_number ? (
                        <a href={`tel:+${dataProfile?.phone_number}`}>
                          +{dataProfile?.phone_number}
                        </a>
                      ) : (
                        "-"
                      )}
                    </small>
                  </div>
                  <div className="flex flex-col gap-2 md:flex-row md:gap-20 items-start md:items-center">
                    <p className="text-black text-left text-[14px] w-24">
                      Date Joined
                    </p>
                    <small className="text-gray-400 text-sm text-left">
                      <Moment format="MMM, DD, YYYY" className="">
                        {dataProfile?.date_created}
                      </Moment>
                    </small>
                  </div>

                  <div>
                    {dataProfile?.is_btob_client && (
                      <span class="ml-1 mb-1 inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                        Business
                      </span>
                    )}
                    {dataProfile?.is_hot_lead && (
                      <span class="ml-1 mb-1 inline-flex items-center rounded-md bg-green-50 px-1 py-1 font-medium text-xs text-green-700 ring-1 ring-inset ring-green-600/20">
                        Hot Lead
                      </span>
                    )}
                    {dataProfile?.is_flagged && (
                      <span class="ml-1 mb-1 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                        Flagged
                      </span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="p-3 mt-4 mb-4 bg-white border border-slate-200 shadow-sm  sm:rounded-lg">
            <div className="">
              <p className="mb-2 font-semibold text-sm">Address</p>
            </div>
            <hr />
            {processingAddresses ? (
              <ReactLoading
                className="mb-10"
                type="bubbles"
                color="#030A1A"
                height={50}
                width={100}
              />
            ) : (
              <div className="py-2 flex flex-col gap-3">
                {dataAddresses && dataAddresses?.length ? (
                  <div className="flex flex-col">
                    <div className="grid grid-cols-2 gap-4 p-1">
                      {dataAddresses?.map((item, i) => (
                        <div
                          key={i}
                          className="p-3 rounded-md border flex justify-between items-center"
                        >
                          <div>
                            <p className="font-bold capitalize text-[15px]">
                              {item?.category?.toLowerCase()}
                            </p>
                            <p className="capitalize text-[13px]">
                              {item?.town?.toLowerCase()}
                            </p>
                            <p className="capitalize text-[13px]">
                              {(item?.notes || item?.others)?.toLowerCase()}
                            </p>
                          </div>
                          <div className="cursor-pointer flex flex-col justify-between gap-2">
                            {item?.lat && item?.lng && (
                              <a
                                href={`https://www.google.com/maps/@${item.lat},${item.lng},21z`}
                                target="_blank"
                              >
                                <svg
                                  width="20px"
                                  height="20px"
                                  viewBox="0 0 192 192"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                >
                                  <path
                                    stroke="#000000"
                                    stroke-width="12"
                                    d="M96 22a51.88 51.88 0 0 0-36.77 15.303A52.368 52.368 0 0 0 44 74.246c0 16.596 4.296 28.669 20.811 48.898a163.733 163.733 0 0 1 20.053 28.38C90.852 163.721 90.146 172 96 172c5.854 0 5.148-8.279 11.136-20.476a163.723 163.723 0 0 1 20.053-28.38C143.704 102.915 148 90.841 148 74.246a52.37 52.37 0 0 0-15.23-36.943A51.88 51.88 0 0 0 96 22Z"
                                  />
                                  <circle
                                    cx="96"
                                    cy="74"
                                    r="20"
                                    stroke="#000000"
                                    stroke-width="12"
                                  />
                                </svg>
                              </a>
                            )}
                            {item?.num_orders_as_shipping_address && (
                              <span class="text-xs text-gray-400">
                                {item?.num_orders_as_shipping_address} order
                                {item?.num_orders_as_shipping_address > 1
                                  ? "s"
                                  : ""}
                              </span>
                            )}
                            <span className="text-xs text-gray-400">
                              <Moment format="MMM, DD, YYYY" className="">
                                {item?.date_updated}
                              </Moment>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    {dataAddresses2?.length > 2 &&
                      dataAddresses?.length == 2 && (
                        <p
                          className="m-4 text-blue-500 text-sm hover:cursor-pointer"
                          onClick={() => showMoreAddresses()}
                        >
                          Show more..
                        </p>
                      )}

                    {dataAddresses2?.length > 2 &&
                      dataAddresses?.length == dataAddresses2?.length && (
                        <p
                          className="m-4 text-blue-500 text-sm hover:cursor-pointer"
                          onClick={() => showLessAddresses()}
                        >
                          Show less..
                        </p>
                      )}
                  </div>
                ) : (
                  <div className="py-2">
                    <span className="text-sm">No address</span>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="p-3 mt-4 mb-4 bg-white border border-slate-200 shadow-sm  sm:rounded-lg">
            <div class="flex items-center justify-between border-b rounded-t ">
              <p className="mb-2 font-semibold text-sm">Cars</p>
              <button
                onClick={(e) => setShowModalAddCar(true)}
                type="button"
                class="mb-1 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center :bg-gray-600 :text-white"
                data-modal-hide="authentication-modal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
            {processingCars ? (
              <ReactLoading
                className="mb-10"
                type="bubbles"
                color="#030A1A"
                height={50}
                width={100}
              />
            ) : (
              <div className="py-2 flex flex-col gap-3">
                {dataCars && dataCars?.length ? (
                  <div class="flex overflow-x-auto space-x-8 w-full py-5">
                    {dataCars?.map((item, i) => (
                      <div class="flex-shrink-0 max-w-sm bg-white border border-gray-200 rounded-md">
                        {false && (
                          <img
                            class="max-h-[280px]  max-w-[200px] bg-cover"
                            src={item?.photo_url}
                            alt=""
                          />
                        )}
                        <div class="p-5 flex flex-col">
                          {item?.photo_url && (
                            <div class="mb-2 h-40 w-40 p-1 align-bottom">
                              <img
                                class="h-full w-full rounded-lg"
                                src={item?.photo_url}
                                alt=""
                              />
                            </div>
                          )}
                          <h5 class="mb-1 text-sm font-bold tracking-tight text-gray-900 ">
                            {item?.make} {item?.model} {item?.variant}{" "}
                            {item?.year} {item?.engine}
                          </h5>
                          <span class="mb-1 font-normal text-red-400 ">
                            {item?.chasis}
                          </span>
                          {item?.number_plate && (
                            <span class="mb-1 font-bold text-gray-700 ">
                              {item?.number_plate}
                            </span>
                          )}
                          {item?.nickname && (
                            <span class="mb-2 text-sm text-gray-400 ">
                              @{item?.nickname.toLowerCase()}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="py-2">
                    <span className="text-sm">No cars</span>
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="p-3 mt-4 mb-4 bg-white border border-slate-200 shadow-sm  sm:rounded-lg">
            <div className="">
              <p className="mb-2 font-semibold text-sm">Tags</p>
            </div>
            <hr />
            <div className="py-2 flex flex-col gap-3">
              <div className="py-2">
                <span className="text-sm">No tags</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ProfileTab;
