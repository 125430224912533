import React, { useState, useEffect } from 'react'
import ReactLoading from 'react-loading';
import { toast, ToastContainer } from 'react-toastify'
import { axiosCustom } from '../utils';
import CreatableSelect from 'react-select/creatable';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT_CUSTOMERS } from '../routes';

//TODO: Allow staff to mark as hot lead
const CUSTOMERS_URL = `/oms/customers/`;

function CustomerAdd() {
   const navigate = useNavigate();
   const [email, setEmail] = useState()
   const [notes, setNotes] = useState()
   const [lastName, setLastName] = useState()
   const [firstName, setFirstName] = useState()
   const [processing, setProcessing] = useState(false)
   const [phoneNumber, setPhoneNumber] = useState()
   const [selectedTags, setSelectedTags] = useState([]);
   const components = {
      DropdownIndicator: null,
   };


   const submitCustomer = async (e) => {
      e.preventDefault();
      if (phoneNumber || email) {
         setProcessing(true);
         const params = {}
         if (phoneNumber) {
            params.phone_number = phoneNumber
         }
         if (email) {
            params.email = email
         }
         if (notes) {
            params.notes = notes
         }
         if (firstName) {
            params.first_name = firstName
         }
         if (lastName) {
            params.last_name = lastName
         }

         const tags = [];
         selectedTags.map(tag => {
            tags.push({ name: tag.value })
         })
         if (tags) {
            params.tags = tags
         }


         try {
            const response = await axiosCustom.post(CUSTOMERS_URL, JSON.stringify(params));
            navigate(ENDPOINT_CUSTOMERS);
         } catch (err) {
            if (err?.response?.status == 400) {

               const errors = err?.response?.data;
               Object.keys(errors).map((key, index) => (
                  errors[key].map((val) => {
                     toast.error(val);
                  })
               ));
            } else {
               toast.error("Something went wrong");
            }
         } finally {
            setProcessing(false)
         }
      } else {
         toast.error("You need at least a phone number or email to add a customer")
      }

   }

   return (
      <>
         <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
            {processing ?
               <ReactLoading type="bubbles" color="#030A1A" height={50} width={60} />
               :
               <div className="flex flex-col">
                  <div className='mt-10 mb-10 font-bold text-lg'>
                     <h2>Add a Customer</h2>
                  </div>

                  <form class="w-full mx-auto" onSubmit={(event) => event.preventDefault()}>
                     <div class="grid md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-5 group">
                           <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" name="floating_first_name" id="floating_first_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   :border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                           <label for="floating_first_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First name</label>
                        </div>
                        <div class="relative z-0 w-full mb-5 group">
                           <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" name="floating_last_name" id="floating_last_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   :border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                           <label for="floating_last_name" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Last name</label>
                        </div>
                     </div>
                     <div class="grid md:grid-cols-2 md:gap-6">
                        <div class="relative z-0 w-full mb-5 group">
                           <input value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="tel" name="floating_phone" id="floating_phone" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   :border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                           <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Phone number</label>
                        </div>
                        <div class="relative z-0 w-full mb-5 group">
                           <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="floating_email" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   :border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                           <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
                        </div>
                     </div>
                     <div class="relative z-0 w-full mb-5 group">
                        <div class="relative z-0 w-full mb-5 group">
                           <textarea value={notes} onChange={(e) => setNotes(e.target.value)} type="text" name="floating_notes" id="floating_notes" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   :border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                           <label for="floating_notes" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Notes</label>
                        </div>
                     </div>
                     <div class="relative z-0 w-full mb-5 mt-4 group">
                        <label for="floating_tags" class="absolute text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus: peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Tags</label>
                        <CreatableSelect
                           components={components}
                           isClearable
                           className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 appearance-none   :border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                           isMulti
                           placeholder="Type something and press enter..."
                           onChange={e =>
                              setSelectedTags(e)}
                        />
                     </div>
                     <button onClick={submitCustomer} type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center  :bg-blue-700 :ring-blue-800">Submit</button>
                  </form>


               </div>
            }
         </div>
         <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
         />
      </>
   )
}
export default CustomerAdd