import React from "react";

const Reorder = ({ orderBy, order, tag, reorderInventory }) => {
    return (
        <span>
            {orderBy && orderBy == tag ?
                order && order == "desc" ?
                    <span onClick={() => reorderInventory(tag, "asc")}>
                        <svg className="w-4 h-4 inline cursor-pointer text-gray-400" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <title>sort-ascending</title>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="add" fill="#000000" transform="translate(64.000000, 106.666667)">
                                    <path d="M399.082027,100.52224 L368.915413,130.688853 L320,81.77344 L320,320 L277.333333,320 L277.333333,81.77088 L228.41536,130.688853 L198.248747,100.52224 L298.666667,-2.84217094e-14 L399.082027,100.52224 Z M106.666667,64 L-1.42108547e-14,64 L-1.42108547e-14,106.666667 L106.666667,106.666667 L106.666667,64 Z M149.333333,170.666667 L-1.42108547e-14,170.666667 L-1.42108547e-14,213.333333 L149.333333,213.333333 L149.333333,170.666667 Z M-1.42108547e-14,320 L192,320 L192,277.333333 L-1.42108547e-14,277.333333 L-1.42108547e-14,320 Z" id="Shape">
                                    </path>
                                </g>
                            </g>
                        </svg>
                    </span>
                    :
                    <span onClick={() => reorderInventory(tag, "desc")}>
                        <svg className="w-4 h-4 inline cursor-pointer text-gray-400" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <title>sort-descending</title>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="add" fill="#000000" transform="translate(64.000000, 106.666667)">
                                    <path d="M-4.26325641e-14,213.333333 L106.666667,213.333333 L106.666667,256 L-4.26325641e-14,256 L-4.26325641e-14,213.333333 Z M-4.26325641e-14,149.333333 L149.333333,149.333333 L149.333333,106.666667 L-4.26325641e-14,106.666667 L-4.26325641e-14,149.333333 Z M-4.26325641e-14,42.6666667 L192,42.6666667 L192,-2.84217094e-14 L-4.26325641e-14,-2.84217094e-14 L-4.26325641e-14,42.6666667 Z M368.916693,189.31904 L320,238.235733 L320,-2.84217094e-14 L277.333333,-2.84217094e-14 L277.333333,238.235733 L228.41664,189.31904 L198.250027,219.485653 L298.666667,320 L399.083307,219.485653 L368.916693,189.31904 Z" id="Shape">
                                    </path>
                                </g>
                            </g>
                        </svg>
                    </span>
                :
                <span onClick={() => reorderInventory(tag, "desc")}>
                    <svg className="w-4 h-4 inline cursor-pointer text-gray-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 8L5 16" stroke="#33363F" stroke-width="2" stroke-linejoin="round" />
                        <path d="M5.20801 2.31202L8.17083 6.75624C8.52526 7.28788 8.14414 8 7.50519 8L2.49481 8C1.85586 8 1.47474 7.28788 1.82917 6.75624L4.79199 2.31202C4.89094 2.16359 5.10906 2.16359 5.20801 2.31202Z" fill="#33363F" />
                        <path d="M5.20801 21.688L8.17083 17.2438C8.52526 16.7121 8.14414 16 7.50519 16L2.49481 16C1.85586 16 1.47474 16.7121 1.82917 17.2438L4.79199 21.688C4.89094 21.8364 5.10906 21.8364 5.20801 21.688Z" fill="#33363F" />
                        <path d="M11 7H19" stroke="#33363F" stroke-width="2" stroke-linecap="round" />
                        <path d="M11 12H19" stroke="#33363F" stroke-width="2" stroke-linecap="round" />
                        <path d="M11 17H19" stroke="#33363F" stroke-width="2" stroke-linecap="round" />
                    </svg>
                </span>

            }
        </span>
    );
};

export default Reorder;