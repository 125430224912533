import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import back from '../assets/ic_back.svg'
import placeholder from "../assets/placeholder.svg"
import partRequsetPlaceholder from "../assets/part-request.jpg"
import copy from '../assets/copy.svg'
import Avatar from 'react-avatar'
import { AccountObj, formatDate, makeAuthenticatedRequest } from '../utils'
import { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'


function PartRequestDetails() {
    const accountObj = new AccountObj();

    const { partRequestID } = useParams()
    const [requestData, setRequestData] = useState([])
    const [processing, setProcessing] = useState(false)


    const [activeImage, setActiveImage] = useState('')
    const [offerNotes, setOfferNotes] = useState('')

    const [showStatus, setShowStatus] = useState(false)

    const [statuses, setStatuses] = useState([
        { id: 1, displayName: 'Outsourcing', name: 'Placed', bgColor: '[#19223B]', textColor: 'white' },
        { id: 2, displayName: 'Accepted', name: 'Accepted', bgColor: 'blue-500', textColor: 'white' },
        { id: 3, displayName: 'Fulfilled', name: 'Fulfilled', bgColor: 'green-500', textColor: 'white' },
        { id: 4, displayName: 'Closed', name: 'Cancelled', bgColor: 'red-500', textColor: 'white' }])





    const navigate = useNavigate()

    const fetchRequest = () => {
        makeAuthenticatedRequest(`/oms/part_requests/${partRequestID}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setRequestData(data)
                setActiveImage(data.image_urls[0])
            });
    }


    useEffect(() => {
        fetchRequest()
    }, [])


    const filteredStatuses = statuses.filter(status => status.name !== requestData?.status);

    const handleExclude = () => {
        setStatuses(filteredStatuses);
    }


    const submitofferNotes = () => {
        setOfferNotes(null)
        setProcessing(true)
        const params = { message: offerNotes }

        makeAuthenticatedRequest(`/oms/part_requests/${partRequestID}/notes/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                toast.success("Note succesfully added")
                setOfferNotes(null)
                fetchRequest()
                setProcessing(false)
            });
    }



    const updatePartRequestStatus = (newStatus) => {
        const params = {
            status: newStatus //'Placed', 'Accepted', 'Fulfilled', 'Cancelled'
        }
        makeAuthenticatedRequest(`/oms/part_requests/${partRequestID}/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                toast.success("Part request succesfully updated")
                setShowStatus(false)
                fetchRequest()
            }).catch((err) => {
                console.log('err', err)
                toast.error("Something went wrong")
            }).finally(() => {
                setProcessing(false);
            });
    }

    console.log('accountObj', accountObj)
    console.log('requestData', requestData)
    return (
        <>

            <div className=" px-4 md:px-20 flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
                <div
                    onClick={() => navigate(-1)}
                    className="flex flex-row">

                    <div className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] rounded-lg">
                        <img src={back} alt="" />
                        Back
                    </div>
                </div>

                <div className="flex flex-row gap-1">
                    <p className='font-satoshi text-[#030A1A] text-[12px] md:text-[20px] font-semibold'>PART REQUEST</p>
                    <p className='font-satoshi text-[#030A1A] text-[12px] md:text-[20px] font-semibold'>#{requestData?.reference_number}</p>
                    <img src={copy} alt="" className='hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`303039`) }} />

                    <div className="flex flex-col gap-2 relative w-full md:w-[15%]">
                        <div onClick={() => setShowStatus(!showStatus)} className={`
                    ${requestData.status === 'Placed' ? 'bg-[#19223B]'
                                : requestData.status === 'Accepted' ? 'bg-blue-500'
                                    : requestData.status === 'Fulfilled' ? 'bg-green-500'
                                        : requestData.status === 'Cancelled' ? 'bg-red-500'
                                            : null
                            } 
                    ${requestData.status === 'Placed' ? 'text-white'
                                : requestData.status === 'Accepted' ? 'text-white'
                                    : requestData.status === 'Fulfilled' ? 'text-white'
                                        : requestData.status === 'Cancelled' ? 'text-white'
                                            : null
                            }     
                    px-2 py-1 cursor-pointer rounded-lg flex flex-row gap-2  justify-between`}>

                            <p className='font-satoshi text-[18px]'>{requestData.status === 'Placed' ? 'Outsourcing' : requestData.status === 'Cancelled' ? 'Closed' : requestData.status}</p>
                            {showStatus ?
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                </svg>

                                :
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-5 mt-1 ">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                </svg>
                            }

                        </div>
                        {showStatus ?
                            <div className="flex flex-col gap-2 absolute top-10 bg-gray-200 rounded-lg px-2 py-2 w-full">
                                {filteredStatuses.map((status) => (

                                    <div
                                        onClick={() => updatePartRequestStatus(status.name)}
                                        className={`bg-${status.bgColor} cursor-pointer px-2 py-1 rounded-lg flex flex-row gap-2 shadow-xl  justify-between`}>
                                        <p className={`font-satoshi text-[18px] text-${status.textColor}`}>{status.displayName}</p>
                                    </div>
                                ))}
                            </div>
                            :
                            null}
                    </div>


                </div>
                <div className="flex flex-col md:flex-row gap-2 w-full">
                    <div className="flex flex-col w-full   gap-2 px-2 pt-1 pb-2">

                        <div className='w-full flex md:grid flex-col md:grid-cols-2  gap-6'>
                            <div className="flex flex-col gap-6 col-span-1">
                                <div className={` ${requestData?.image_urls?.length > 0 ? 'border border-gray-100' : 'border border-red-200'} rounded `}>
                                    {requestData?.image_urls?.length > 0 ?
                                        <img

                                            src={activeImage} className='rounded h-80' />
                                        :
                                        <div className='h-80 flex justify-center items-center bg-red-50 '>

                                            <p className='font-satoshi text-[18px] text-center text-red-400 font-medium'>No image</p>
                                        </div>

                                    }
                                </div>
                                <div className='flex  gap-2  overflow-x-scroll hide-scrollbar'>
                                    {requestData?.image_urls?.length > 0 ?

                                        requestData?.image_urls?.map((image) => (
                                            <img
                                                onClick={() => setActiveImage(image)}
                                                src={image} className='rounded object-cover h-20 w-30 border border-gray-200' />
                                        ))
                                        :
                                        null

                                    }

                                </div>
                            </div>
                            <div className="flex flex-col col-span-1 gap-4 border rounded-lg px-2 w-full">
                                <p className='font-satoshi text-[#030A1A] px-2 py-2 text-[16px] md:text-[16px] font-semibold'>Request details</p>

                                <div className="border-b border-b-gray-200"></div>

                                <div className="grid grid-rows-7 gap-4  px-2 py-2">

                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col">
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold'>Part name</p>
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{requestData?.name}</p>
                                    </div>

                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col">
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold'>Part no</p>
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{requestData?.part_number ? '#' + requestData?.part_number : '-'}</p>
                                    </div>

                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col">
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold'>Car</p>
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px]'></p>
                                    </div>
                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col">
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold'>Model/VIN</p>
                                        <p className='font-satoshi text-left text-[#030A1A] text-[13px]'>#{requestData?.vin_number}</p>
                                    </div>
                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col">
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold'>Condition</p>
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{requestData?.condition}</p>
                                    </div>
                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col">
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold'>Quantity</p>
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>{requestData?.quantity}</p>
                                    </div>

                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col">
                                        <p className='font-satoshi text-left text-[#030A1A] text-[16px] font-semibold'>Customer</p>
                                        <div className='col-span-1 flex flex-col gap-1'>

                                            <p className='font-satoshi text-left text-[#030A1A] text-[16px] '>
                                                {requestData?.passport?.first_name} {requestData?.passport?.last_name}
                                            </p>
                                            <p className='font-satoshi text-left text-[#030A1A] text-[16px] '>
                                                {requestData?.passport?.phone_number ? ('+' + requestData?.passport?.phone_number) : null}
                                            </p>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>


                    </div>




                </div>

                <div className="flex flex-col w-full gap-2 ">
                    {/* request details */}
                    <div className="flex flex-col">
                        {/* req details */}

                        {/* customer details */}

                    </div>
                    {requestData?.offers?.length > 0 ?
                        <>
                            <p className='font-satoshi text-[18px] font-semibold'>Offers</p>
                            <div className="flex flex-col gap-2 rounded-md border">
                                {requestData?.offers?.map((offer) => (

                                    <div className="flex md:grid flex-col md:grid-cols-3 gap-2 border-b border-gray-200">
                                        <div className="col-span-1 p-1">
                                            <img src={offer?.images ? offer?.images[0] : placeholder} alt="" className='w-full md:w-24 h-24' />
                                        </div>
                                        <div className="col-span-2 flex flex-col gap-2 p-2">
                                            <div className="flex md:grid flex-col md:grid-cols-2 gap-3">
                                                <div className="col-span-1">

                                                    <p className='font-satoshi text-[18px]'>{offer?.carpart} - {offer.partnumber}</p>
                                                    <div className="flex flex-row gap-3">
                                                        <p className='font-satoshi text-gray-500 text-[14px]'>{offer.manufacturer}</p>
                                                        <p className='font-satoshi text-gray-500 text-[14px]'>{offer.condition === 'N' ? 'New' : offer.condition === 'R' ? 'Refurbished' : offer.condition === 'U' ? 'Used' : offer.condition}</p>

                                                    </div>
                                                    <p className='font-satoshi text-[18px]'>Ksh {offer.price}</p>
                                                    <p className='font-satoshi text-gray-500 text-[16px]'>{offer.quantity} units available</p>
                                                </div>
                                                <div className="col-span-1 flex flex-col gap-2">

                                                    <div className="flex flex-row gap-5  rounded-lg p-2">
                                                        <div className="">
                                                            <img src={offer.passport?.merchant_thumbnail} className='rounded-full w-[5rem] h-[5rem]' alt="" />
                                                        </div>
                                                        <div className="flex flex-col gap-1">


                                                            <p className='font-satoshi text-[18px]'>
                                                                {offer.passport?.merchant_name}
                                                            </p>
                                                            <p className='font-satoshi text-[16px]'>
                                                                {offer.passport?.merchant_contact_phone_number}
                                                            </p>
                                                            {
                                                                offer.passport?.merchant_username &&
                                                                <span className='font-satoshi text-[16px] text-[#666C7C]'>{`@${offer.passport?.merchant_username}`}</span>
                                                            }
                                                            <div className='w-full flex justify-start items-center text-xs'>
                                                                <img src="/cdn/images/ic_location.svg" alt="" className='w-5 h-5 object-cover -ml-1' />
                                                                <span className='font-satoshi text-[14px] text-[#666C7C] line-clamp-1' title={offer.passport?.merchant_address}>
                                                                    {offer.passport?.merchant_address ? offer.passport?.merchant_address : 'Kenya'}
                                                                </span>
                                                            </div>
                                                            <p className='font-satoshi text-gray-500 text-[16px]'>Submitted on {formatDate(offer.date_created)}</p>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                ))}

                            </div>
                        </>
                        :
                        <p className='font-satoshi text-[18px] font-semibold'>No offers</p>
                    }

                    <p className='font-satoshi text-[18px] font-semibold'>Notes</p>


                    <div className="flex flex-col py-2 w-full">
                        <form action="" className='w-full'>
                            <input type="text"
                                value={offerNotes}
                                onChange={(e) => setOfferNotes(e.target.value)}
                                placeholder='Write a note'
                                className='w-full rounded-lg border outline-none font-satoshi px-4 py-2 placeholder:text-[#666C7C]' />
                            {offerNotes &&
                                <div className="flex flex-row-reverse w-full py-4 gap-2">
                                    <button
                                        onClick={submitofferNotes}
                                        className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>Submit</button>
                                </div>
                            }

                        </form>
                    </div>

                    <div className="flex flex-col w-full gap-2">
                        {requestData?.notes?.map((note, i) => (

                            <div key={i} className="flex flex-col w-full gap-1">
                                <div className="flex flex-row gap-4 w-full">
                                    <div className="">
                                        {/* <Avatar name={'Test User'} size="50" className="h-12 w-12 mr-2 rounded-full shadow" /> */}

                                    </div>
                                    <div className="flex-1">
                                        <p className='font-satoshi rounded-md w-full text-[18px] px-1 py-2 bg-gray-100'>{note.message}</p>
                                    </div>
                                </div>

                            </div>
                        ))}


                    </div>



                    {/* request offers */}
                </div>
            </div>

            <ToastContainer

                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export default PartRequestDetails