import { axiosCustom } from '../../../utils';

const CMS_ADVERT_URL = '/oms/cms/advert-subsections/';
const HOMEPAGE_SECTION_URL = '/oms/cms/homepage-sections/';

export const createAdvertSubsection = async (advertData) => {
    try {
        const response = await axiosCustom.post(CMS_ADVERT_URL, advertData);
        return response.data;
    } catch (error) {
        console.log("An error occured.", error.code)
    }
};

export const getAdvertSubsections  = async () => {
    try {
        const response = await axiosCustom.get(CMS_ADVERT_URL);
        return response.data.results;
    } catch (error) {
        console.log("An error occured.", error.code)
    }
};

export const updateAdvertSubsection   = async (advertSubsectionId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_ADVERT_URL}${advertSubsectionId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("An error occured.", error.code)
    }
};

export const deleteAdvertSubsection  = async (advertSubsectionId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_ADVERT_URL}${advertSubsectionId}/`);
        return response.data;
    } catch (error) {
        console.log("An error occured.", error.code)
    }
};
