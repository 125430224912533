import axios from "axios";
import Urls from "./Urls";
import { API_PREFIX, URL_SHORTENER_PREFIX } from "../../constants";
import { makeAuthenticatedRequest } from "../../utils";
import { toast } from "react-toastify";

export const getCreatedLinks = async (page = 1, perPage = 10, s = "") => {
  try {
    const params = { page: page, per_page: perPage };

    if (s) {
      params.s = s;
    }

    const url = `${URL_SHORTENER_PREFIX}links/`;
    const { data } = await axios.get(url, {
      params: params,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCreatedLink = async (urlID) => {
  try {
    const url = `${URL_SHORTENER_PREFIX}links/${urlID}/`;
    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createLink = async (linkData) => {
  const url = `${URL_SHORTENER_PREFIX}create/`;
  const response = await axios.post(url, linkData);
  return response;
};

export const isValidLink = (link) => {
  try {
    new URL(link);
    return true;
  } catch (error) {
    return false;
  }
};

export const getAnalytics = async (shortLink) => {
  const params = new URLSearchParams({ short_link: shortLink });
  const url = `/oms/analytics/short_urls/?${params}`;
  const response = await makeAuthenticatedRequest(url, "GET");
  const data = await response.json();
  return data;
};

export const getLastPartOfUrl = (urlString) => {
  try {
    const lastIndex = urlString.lastIndexOf("/");
    const lastPart = urlString.substring(lastIndex + 1);
    return lastPart;
  } catch (error) {
    toast.error("An error occurred!");
  }
};
