import React, { useState, useEffect, useMemo, useRef } from "react";
import clockDanger from "../assets/watch-2.svg";
import Avatar from "react-avatar";
import Moment from "react-moment";
import moment from "moment";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import RemindersModal from "../components/customers/modals/RemidersModal";
import AddMessageModal from "../components/customers/modals/AddMessageModal";
import { toast, ToastContainer } from "react-toastify";
import { axiosCustom, formatNumber, getLocalStoreItem } from "../utils";
import { Link, useNavigate } from "react-router-dom";
import { API_PREFIX, LS_ACCESS_TOKEN, LS_ACCOUNT_ID } from "../constants";
import { getName } from "../components/customers/utils";
import {
  ENDPOINT_BTOB,
  ENDPOINT_CUSTOMERS,
  ENDPOINT_CUSTOMERS_ADD,
} from "../routes";

//TODO: Fix pagination, should be more intuitive

const CUSTOMERS_URL = `/oms/customers/`;
const TAB_ALL = "all";
const TAB_BUSINESS = "business";
const TAB_CUSTOMERS = "customers";

function Customers() {
  const navigate = useNavigate();
  const optionsRef = useRef(null);

  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(60);
  const [modalLeadID, setModalLeadID] = useState();
  const [reminderModal, setReminderModal] = useState(false);
  const [remindersModal, setRemindersModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(TAB_ALL);
  const [processing, setProcessing] = useState(false);
  const [followUpRemarks, setFollowUpRemarks] = useState();
  const [currentCustomer, setCurrentCustomer] = useState();
  const [followUpDateTime, setFollowUpDateTime] = useState(new Date());
  const [showFiltersDropDown, setShowFiltersDropDown] = useState(false);
  const [isBusinessChecked, setIsBusinessChecked] = useState(false);
  const [isIndividualChecked, setIsIndividualChecked] = useState(false);
  const [isHotLeadChecked, setIsHotLeadChecked] = useState(false);
  const [isWithReminderChecked, setIsWithReminderChecked] = useState(false);
  const [isMyReminderChecked, setIsMyReminderChecked] = useState(false);
  const [isOverdueReminderChecked, setIsOverdueReminderChecked] =
    useState(false);
  const [isBusinessCount, setIsBusinessCount] = useState(0);
  const [isIndividualCount, setIsIndividualCount] = useState(0);
  const [isHotLeadCount, setIsHotLeadCount] = useState(0);
  const [isWithReminderCount, setIsWithReminderCount] = useState(0);
  const [isMyReminderCount, setIsMyReminderCount] = useState(0);
  const [isOverdueReminderCount, setIsOverdueReminderCount] = useState(0);
  const [customerChecked, setCustomerChecked] = useState({});
  const [selectedAll, setSelectedAll] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showAddMessageModal, setShowAddMessageModal] = useState(false);
  const [customerNumbers, setCustomerNumbers] = useState([]);

  // Select customers
  const handleCheckBoxChange = (customer) => {
    const { id, phone_number } = customer;
    const isChecked = customerChecked[id] || false;
    setCustomerChecked({ ...customerChecked, [id]: !isChecked });

    //  add or remove phone number of the selected customer
    if (isChecked) {
      const index = customerNumbers.indexOf(phone_number);
      if (index !== -1) customerNumbers.splice(index, 1);
    } else {
      setCustomerNumbers([...customerNumbers, phone_number]);
    }
  };

  const handleSelectedAllChange = () => {
    setSelectedAll(!selectedAll);
    let newCustomerChecked = {};
    data?.forEach((customer) => {
      newCustomerChecked[customer.id] = !selectedAll;
    });
    setCustomerChecked(newCustomerChecked);
  };
  //

  // Handle when a user clicks outside the options menu
  const handleOutsideClick = (e) => {
    if (optionsRef?.current && !optionsRef?.current.contains(e.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    const run = () => {
      console.log("here");
      document.addEventListener("click", (e) => handleOutsideClick(e));
    };

    run();
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);
  //

  // handle send bulk sms
  const handleSendBulkSMS = () => {
    if (!customerNumbers.length) {
      toast.error("Select atleast one customer to send message");
      return;
    }

    setShowAddMessageModal(true);
  };

  const userId = getLocalStoreItem(LS_ACCOUNT_ID);
  const token = getLocalStoreItem(LS_ACCESS_TOKEN);
  const key =
    token.substring(0, 4) + token.substring(token.length - 4, token.length);

  const dateIsBeforeNow = (theDate) => {
    return moment(theDate).isAfter(new Date());
  };
  const dateIsAterNow = (theDate) => {
    return moment(theDate).isBefore(new Date());
  };

  const openReminderModal = (item) => {
    setReminderModal(true);
    setModalLeadID(item.id);
    if (item.follow_up_datetime) {
      setFollowUpDateTime(new Date(item.follow_up_datetime));
    } else {
      setFollowUpDateTime(new Date(moment(new Date()).add(60, "m")));
    }
    setFollowUpRemarks("");
  };

  const showRemidersModal = async (customer) => {
    setCurrentCustomer(customer);
    setRemindersModal(true);
  };

  const setReminder = async () => {
    setProcessing(true);
    setReminderModal(false);

    const params = {};
    if (followUpRemarks) params.message = followUpRemarks;

    if (followUpDateTime)
      params.datetime = moment(followUpDateTime).toISOString();
    try {
      await axiosCustom.post(
        `${CUSTOMERS_URL}${modalLeadID}/follow-up/`,
        JSON.stringify(params)
      );
      await fetchData();
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setProcessing(false);
    }
  };

  const fetchData = async () => {
    setProcessing(true);
    try {
      const params = {
        s: search,
        page: page,
        per_page: perPage,
      };
      const filters = [];
      if (isBusinessChecked) {
        filters.push("business");
      }
      if (isIndividualChecked) {
        filters.push("individual");
      }
      if (isHotLeadChecked) {
        filters.push("hot_lead");
      }
      if (isMyReminderChecked) {
        filters.push("my_reminders");
      }
      if (isOverdueReminderChecked) {
        filters.push("overdue_reminders");
      }
      if (isWithReminderChecked) {
        filters.push("with_reminder");
      }

      if (filters.length) {
        params["filters"] = filters;
      }

      const searchParams = new URLSearchParams(params).toString();
      const response = await axiosCustom.get(
        `${CUSTOMERS_URL}?${searchParams}`
      );
      setPage(response?.data?.page);
      setData(response?.data?.results);
      setCount(response?.data?.count);
      setPerPage(response?.data?.per_page);

      setIsBusinessCount(response?.data?.is_business_count);
      setIsIndividualCount(response?.data?.is_individual_count);
      setIsHotLeadCount(response?.data?.is_hot_lead_count);
      setIsMyReminderCount(response?.data?.my_reminders_count);
      setIsWithReminderCount(response?.data?.with_reminder_count);
      setIsOverdueReminderCount(response?.data?.overdue_reminders_count);
    } catch (err) {
      toast.error("Something went wrong");
    } finally {
      setProcessing(false);
    }
  };

  const tabChanged = async (e) => {
    setCurrentTab(e.target.value);
  };

  const submitSearch = async (e) => {
    e.preventDefault();
    await fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [
    page,
    currentTab,
    isBusinessChecked,
    isHotLeadChecked,
    isIndividualChecked,
    isMyReminderChecked,
    isWithReminderChecked,
    isOverdueReminderChecked,
  ]);

  return (
    <>
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
        {processing ? (
          <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
            <img
              src="/cdn/images/loading.gif"
              className="mr-3 cursor-pointer  h-24"
              alt="Sparepap Logo"
            />
            <h2 class="text-center text-white text-xl font-semibold">
              Processing...
            </h2>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="mt-10 mb-10 font-bold text-lg">
              <h2>Customers</h2>
            </div>

            <div class="text-sm font-medium text-center text-gray-500 border-b border-slate-100 dark:text-gray-400 dark:border-gray-700 ml-5 mr-5">
              <ul class="flex flex-wrap -mb-px">
                <li
                  class="me-2"
                  onClick={() => {
                    setIsBusinessChecked(false);
                    setIsIndividualChecked(false);
                    setCurrentTab(TAB_ALL);
                  }}
                >
                  <a
                    href="#"
                    className={
                      currentTab == TAB_ALL
                        ? "inline-block p-4 border-b-4 border-[#D95762] rounded-t-lg active text-[#D95762] text-[16px] font-semibold"
                        : "inline-block p-4 border-b-4 border-transparent rounded-t-lg hover:text-gray-600 text-[16px] font-semibold"
                    }
                  >
                    All Customers
                  </a>
                </li>
                <li
                  class="me-2"
                  onClick={() => {
                    setIsBusinessChecked(false);
                    setIsIndividualChecked(true);
                    setCurrentTab(TAB_CUSTOMERS);
                  }}
                >
                  <a
                    href="#"
                    className={
                      currentTab == TAB_CUSTOMERS
                        ? "inline-block p-4 border-b-4 border-[#D95762] rounded-t-lg active text-[#D95762] text-[16px] font-semibold"
                        : "inline-block p-4 border-b-4 border-transparent rounded-t-lg hover:text-gray-600 text-[16px] font-semibold"
                    }
                  >
                    Retail Customers (B2C)
                  </a>
                </li>
                <li
                  class="me-2"
                  onClick={() => {
                    setIsBusinessChecked(true);
                    setIsIndividualChecked(false);
                    setCurrentTab(TAB_BUSINESS);
                  }}
                >
                  <a
                    href="#"
                    className={
                      currentTab == TAB_BUSINESS
                        ? "inline-block p-4 border-b-4 border-[#D95762] rounded-t-lg active text-[#D95762] text-[16px] font-semibold"
                        : "inline-block p-4 border-b-4 border-transparent rounded-t-lg hover:text-gray-600 text-[16px] font-semibold"
                    }
                  >
                    Enterprise Customers (B2B)
                  </a>
                </li>
              </ul>
            </div>

            {!processing && !data && !data?.length < 1 ? (
              <section class="bg-white ">
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                  <div class="mx-auto max-w-screen-sm">
                    <p class="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl ">
                      No customers to display.
                    </p>
                    <p class="mb-4 text-lg font-light text-gray-500 ">
                      Try again later{" "}
                    </p>
                  </div>
                </div>
              </section>
            ) : (
              <section>
                <div class="mx-auto max-w-screen-2xl">
                  <div class="relative overflow-x-auto bg-white border border-slate-200 shadow-md  sm:rounded-lg">
                    <div class="flex flex-col px-4 py-3 space-y-3 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:space-x-4">
                      <div class="flex items-center flex-1 space-x-4">
                        <form class="flex items-center" onSubmit={submitSearch}>
                          <label for="simple-search" class="sr-only">
                            Search
                          </label>
                          <div class="relative w-full">
                            <div
                              class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                              onClick={submitSearch}
                            >
                              <svg
                                aria-hidden="true"
                                class="w-5 h-5 text-gray-500"
                                fill="currentColor"
                                viewbox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <input
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              id="simple-search"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2 "
                              placeholder="Search"
                              required=""
                            />
                          </div>
                        </form>
                      </div>
                      <div class="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
                        <div className="relative" ref={optionsRef}>
                          <button
                            id="actionsDropdownButton"
                            data-dropdown-toggle="actionsDropdown"
                            class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                            type="button"
                            onClick={() => setShowOptions(!showOptions)}
                          >
                            Options
                            <svg
                              className="text-gray-800 w-4 h-4 ml-1"
                              fill="currentColor"
                              viewbox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                            >
                              <path
                                clip-rule="evenodd"
                                fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              />
                            </svg>
                          </button>

                          {showOptions && (
                            <div
                              id="actionsDropdown"
                              class="z-10 absolute top-12 left-0 bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
                            >
                              <ul
                                class="py-1 text-sm text-gray-700 dark:text-gray-200"
                                aria-labelledby="actionsDropdownButton"
                              >
                                <li>
                                  <button
                                    class="block w-full text-start px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={handleSendBulkSMS}
                                  >
                                    Send bulk sms
                                  </button>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>

                        <Link
                          to={ENDPOINT_BTOB}
                          class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                        >
                          B2B Portal
                        </Link>

                        <div
                          onClick={() => {
                            setShowFiltersDropDown(!showFiltersDropDown);
                          }}
                          id="filterDropdownButton"
                          data-dropdown-toggle="filterDropdown"
                          class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                          type="button"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                            class="w-4 h-4 mr-2 text-gray-400"
                            viewbox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Filter
                          <svg
                            class="-mr-1 ml-1.5 w-5 h-5"
                            fill="currentColor"
                            viewbox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-hidden="true"
                          >
                            <path
                              clip-rule="evenodd"
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            />
                          </svg>
                          <div
                            onMouseLeave={() => {
                              setShowFiltersDropDown(false);
                            }}
                            id="dropdown"
                            className={`absolute top-[3.5rem] py-4 px-3 z-100 shadow border border-gray-200 rounded-lg bg-white ${
                              showFiltersDropDown ? "block" : "hidden"
                            }`}
                          >
                            <div className="w-full flex flex-col items-center px-2 gap-2">
                              <ul
                                class="space-y-2 text-sm"
                                aria-labelledby="dropdownDefault"
                              >
                                <li class="flex items-center">
                                  <input
                                    type="checkbox"
                                    checked={isBusinessChecked}
                                    onChange={() =>
                                      setIsBusinessChecked(!isBusinessChecked)
                                    }
                                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 :ring-primary-600  focus:ring-2  "
                                  />
                                  <label
                                    for="apple"
                                    class="ml-2 font-bold text-gray-900 "
                                  >
                                    Business ({isBusinessCount})
                                  </label>
                                </li>

                                <li class="flex items-center">
                                  <input
                                    type="checkbox"
                                    checked={isIndividualChecked}
                                    onChange={() =>
                                      setIsIndividualChecked(
                                        !isIndividualChecked
                                      )
                                    }
                                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 :ring-primary-600  focus:ring-2  "
                                  />
                                  <label
                                    for="apple"
                                    class="ml-2 font-bold text-gray-900 "
                                  >
                                    Individual ({isIndividualCount})
                                  </label>
                                </li>

                                <li class="flex items-center">
                                  <input
                                    type="checkbox"
                                    checked={isHotLeadChecked}
                                    onChange={() =>
                                      setIsHotLeadChecked(!isHotLeadChecked)
                                    }
                                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 :ring-primary-600  focus:ring-2  "
                                  />
                                  <label
                                    for="apple"
                                    class="ml-2 font-bold text-gray-900 "
                                  >
                                    Hot Lead ({isHotLeadCount})
                                  </label>
                                </li>

                                <li class="flex items-center">
                                  <input
                                    type="checkbox"
                                    checked={isWithReminderChecked}
                                    onChange={() =>
                                      setIsWithReminderChecked(
                                        !isWithReminderChecked
                                      )
                                    }
                                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 :ring-primary-600  focus:ring-2  "
                                  />
                                  <label
                                    for="apple"
                                    class="ml-2 font-bold text-gray-900 "
                                  >
                                    Has Reminder ({isWithReminderCount})
                                  </label>
                                </li>

                                <li class="flex items-center">
                                  <input
                                    type="checkbox"
                                    checked={isMyReminderChecked}
                                    onChange={() =>
                                      setIsMyReminderChecked(
                                        !isMyReminderChecked
                                      )
                                    }
                                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 :ring-primary-600  focus:ring-2  "
                                  />
                                  <label
                                    for="apple"
                                    class="ml-2 font-bold text-gray-900 "
                                  >
                                    Has My Reminder ({isMyReminderCount})
                                  </label>
                                </li>

                                <li class="flex items-center">
                                  <input
                                    type="checkbox"
                                    checked={isOverdueReminderChecked}
                                    onChange={() =>
                                      setIsOverdueReminderChecked(
                                        !isOverdueReminderChecked
                                      )
                                    }
                                    class="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 :ring-primary-600  focus:ring-2  "
                                  />
                                  <label
                                    for="apple"
                                    class="ml-2 font-bold text-gray-900 "
                                  >
                                    Has Overdue Reminder (
                                    {isOverdueReminderCount})
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <a
                          href={`${API_PREFIX}/oms/customers/export/?id=${userId}&key=${key}`}
                          className="flex items-center justify-center flex-shrink-0 px-3 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
                        >
                          <svg
                            className="w-5 h-5 inline mr-1"
                            viewBox="0 0 24 24"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>Download-2</title>
                            <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <g id="Download-2">
                                <rect
                                  id="Rectangle"
                                  fill-rule="nonzero"
                                  x="0"
                                  y="0"
                                  width="24"
                                  height="24"
                                ></rect>
                                <line
                                  x1="12"
                                  y1="14"
                                  x2="12"
                                  y2="20"
                                  id="Path"
                                  stroke="#0C0310"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                ></line>
                                <path
                                  d="M15,19 L12.7071,21.2929 C12.3166,21.6834 11.6834,21.6834 11.2929,21.2929 L9,19"
                                  id="Path"
                                  stroke="#0C0310"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                ></path>
                                <path
                                  d="M19.9495,16 C20.5978,15.3647 21,14.4793 21,13.5 C21,11.567 19.433,10 17.5,10 C17.3078,10 17.1192,10.0155 16.9354,10.0453 C16.4698,6.63095 13.5422,4 10,4 C6.13401,4 3,7.13401 3,11 C3,12.9587 3.80447,14.7295 5.10102,16"
                                  id="Path"
                                  stroke="#0C0310"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                ></path>
                              </g>
                            </g>
                          </svg>
                          Export
                        </a>

                        <Link
                          to={ENDPOINT_CUSTOMERS_ADD}
                          class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-black hover:bg-gray-800 focus:ring-4 focus:ring-primary-300 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="text-white w-4 h-4 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m7.5-7.5h-15"
                            />
                          </svg>
                          Add Customer
                        </Link>
                      </div>
                    </div>
                    <div class="overflow-x-auto">
                      {false && (
                        <div className="flex flex-row gap-3 m-4 font-semibold text-xs align-middle">
                          <span>Show only</span>
                          <span>
                            <input
                              type="radio"
                              name="tab"
                              value={TAB_ALL}
                              checked={currentTab === TAB_ALL}
                              onChange={tabChanged}
                            />{" "}
                            All
                          </span>
                          <span>
                            <input
                              type="radio"
                              name="tab"
                              value={TAB_CUSTOMERS}
                              checked={currentTab === TAB_CUSTOMERS}
                              onChange={tabChanged}
                            />{" "}
                            Individual
                          </span>
                          <span>
                            <input
                              type="radio"
                              name="tab"
                              value={TAB_BUSINESS}
                              checked={currentTab === TAB_BUSINESS}
                              onChange={tabChanged}
                            />{" "}
                            Business
                          </span>
                        </div>
                      )}

                      {data && data?.length > 0 ? (
                        <table class="w-full text-sm text-left text-gray-500">
                          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                              <th className="p-4 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                                <input
                                  type="checkbox"
                                  name="allSelect"
                                  onChange={() => handleSelectedAllChange()}
                                  checked={selectedAll}
                                />
                              </th>
                              <th scope="col" class="px-4 py-3">
                                Name
                              </th>
                              <th scope="col" class="px-4 py-3">
                                Orders
                              </th>
                              <th scope="col" class="px-4 py-3">
                                Follow-up
                              </th>
                              <th scope="col" class="px-4 py-3">
                                Remark
                              </th>
                              {false && (
                                <>
                                  <th scope="col" class="px-4 py-3">
                                    Sales
                                  </th>
                                  <th scope="col" class="px-4 py-3">
                                    Tags
                                  </th>
                                  <th scope="col" class="px-4 py-3">
                                    Last Seen
                                  </th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((item, index) => (
                              <tr class="cursor-pointer border-b hover:bg-gray-100">
                                <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                                  <input
                                    type="checkbox"
                                    id={item.id}
                                    name={item.id}
                                    checked={customerChecked[item.id] || false}
                                    onChange={() => handleCheckBoxChange(item)}
                                  />
                                </td>

                                <td
                                  scope="row"
                                  class="flex items-center px-2 py-2 font-medium text-gray-900 whitespace-nowrap"
                                  onClick={() =>
                                    navigate(`${ENDPOINT_CUSTOMERS}/${item.id}`)
                                  }
                                >
                                  <div className="flex flex-row">
                                    <Avatar
                                      name={getName(item)}
                                      size="40"
                                      className="mr-2 rounded-full shadow"
                                    />
                                    <div className="flex flex-col align-middle">
                                      <div className="no-underline font-semibold capitalize">
                                        {getName(item)}
                                      </div>
                                      {item.email && (
                                        <div className="no-underline text-xs">
                                          {item.email}
                                        </div>
                                      )}
                                      {item.phone_number && (
                                        <a
                                          href={`tel:+${item.phone_number}`}
                                          onClick={(e) => e.stopPropagation()}
                                          className="no-underline hover:underline text-xs"
                                        >
                                          +{item.phone_number}
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </td>

                                <td
                                  onClick={() =>
                                    navigate(`${ENDPOINT_CUSTOMERS}/${item.id}`)
                                  }
                                  class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap"
                                >
                                  {item?.order_amount_total ? (
                                    <div class="flex items-center">
                                      <div className="flex flex-col">
                                        <span>
                                          Ksh.{" "}
                                          {formatNumber(
                                            parseInt(item?.order_amount_total),
                                            0,
                                            0
                                          )}
                                        </span>
                                        <span>
                                          <span>
                                            {formatNumber(
                                              parseInt(item?.orders_count),
                                              0,
                                              0
                                            )}{" "}
                                            order
                                            {item?.orders_count != 1 &&
                                              "s"},{" "}
                                            {formatNumber(
                                              parseInt(item?.order_line_count),
                                              0,
                                              0
                                            )}{" "}
                                            item
                                            {item?.order_line_count != 1 && "s"}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>-</div>
                                  )}
                                </td>
                                <td
                                  class="px-4 py-2 font-medium text-gray-900 whitespace-nowrap"
                                  //  onClick={(e) => e.stopPropagation()}
                                  onClick={() =>
                                    navigate(`${ENDPOINT_CUSTOMERS}/${item.id}`)
                                  }
                                >
                                  <div>
                                    {item.follow_ups?.[0]?.datetime &&
                                      dateIsBeforeNow(
                                        item.follow_ups?.[0]?.datetime
                                      ) && (
                                        <div className="flex flex-col">
                                          <div
                                            className="flex flex-row"
                                            onClick={() =>
                                              showRemidersModal(item)
                                            }
                                          >
                                            <Moment
                                              format="MMM, DD LT"
                                              className="text-[#4ba187] font-bold"
                                            >
                                              {item.follow_ups?.[0]?.datetime}
                                            </Moment>
                                            {item?.follow_ups_count > 1 && (
                                              <span className="text-xs ml-1 text-red-400">
                                                +{item?.follow_ups_count - 1}
                                              </span>
                                            )}
                                          </div>

                                          <div
                                            className="flex pt-1 cursor-pointer"
                                            onClick={() =>
                                              openReminderModal(item)
                                            }
                                          >
                                            <svg
                                              className="h-3.5 w-3.5"
                                              viewBox="0 0 24 24"
                                            >
                                              <g
                                                id="Group_2097"
                                                data-name="Group 2097"
                                                transform="translate(-670 -16)"
                                              >
                                                <rect
                                                  id="Rectangle_614"
                                                  data-name="Rectangle 614"
                                                  width="24"
                                                  height="24"
                                                  transform="translate(670 16)"
                                                  fill="#fff"
                                                  opacity="0"
                                                />
                                                <g
                                                  id="Group_2096"
                                                  data-name="Group 2096"
                                                  transform="translate(1 3)"
                                                >
                                                  <g
                                                    id="Group_2095"
                                                    data-name="Group 2095"
                                                  >
                                                    <g
                                                      id="Ellipse_210"
                                                      data-name="Ellipse 210"
                                                      transform="translate(673 19)"
                                                      fill="#fff"
                                                      stroke="#bf3054"
                                                      stroke-width="1"
                                                    >
                                                      <circle
                                                        cx="8"
                                                        cy="8"
                                                        r="8"
                                                        stroke="none"
                                                      />
                                                      <circle
                                                        cx="8"
                                                        cy="8"
                                                        r="7.5"
                                                        fill="none"
                                                      />
                                                    </g>
                                                    <path
                                                      id="Path_1170"
                                                      data-name="Path 1170"
                                                      d="M681,27V21.928"
                                                      fill="none"
                                                      stroke="#bf3054"
                                                      stroke-linecap="round"
                                                      stroke-width="1"
                                                    />
                                                    <line
                                                      id="Line_112"
                                                      data-name="Line 112"
                                                      x1="5"
                                                      transform="translate(678.5 16.5)"
                                                      fill="none"
                                                      stroke="#bf3054"
                                                      stroke-linecap="round"
                                                      stroke-width="1"
                                                    />
                                                    <line
                                                      id="Line_111"
                                                      data-name="Line 111"
                                                      y1="1"
                                                      transform="translate(681 16.5)"
                                                      fill="none"
                                                      stroke="#bf3054"
                                                      stroke-linecap="square"
                                                      stroke-width="1"
                                                    />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>

                                            <span className="text-xs text-gray-400">
                                              Set reminder
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    {item.follow_ups?.[0]?.datetime &&
                                      dateIsAterNow(
                                        item.follow_ups?.[0]?.datetime
                                      ) && (
                                        <div className="flex flex-col">
                                          <div
                                            className="flex flex-row"
                                            onClick={() =>
                                              showRemidersModal(item)
                                            }
                                          >
                                            <Moment
                                              format="MMM, DD LT"
                                              className="text-[#BF3054] font-bold"
                                            >
                                              {item.follow_ups?.[0]?.datetime}
                                            </Moment>
                                            {item?.follow_ups_count > 1 && (
                                              <span className="text-xs ml-1 text-red-400">
                                                +{item?.follow_ups_count - 1}
                                              </span>
                                            )}
                                          </div>
                                          <div
                                            className="flex pt-1 cursor-pointer"
                                            onClick={() =>
                                              openReminderModal(item)
                                            }
                                          >
                                            <svg
                                              className="h-3.5 w-3.5"
                                              viewBox="0 0 24 24"
                                            >
                                              <g
                                                id="Group_2097"
                                                data-name="Group 2097"
                                                transform="translate(-670 -16)"
                                              >
                                                <rect
                                                  id="Rectangle_614"
                                                  data-name="Rectangle 614"
                                                  width="24"
                                                  height="24"
                                                  transform="translate(670 16)"
                                                  fill="#fff"
                                                  opacity="0"
                                                />
                                                <g
                                                  id="Group_2096"
                                                  data-name="Group 2096"
                                                  transform="translate(1 3)"
                                                >
                                                  <g
                                                    id="Group_2095"
                                                    data-name="Group 2095"
                                                  >
                                                    <g
                                                      id="Ellipse_210"
                                                      data-name="Ellipse 210"
                                                      transform="translate(673 19)"
                                                      fill="#fff"
                                                      stroke="#bf3054"
                                                      stroke-width="1"
                                                    >
                                                      <circle
                                                        cx="8"
                                                        cy="8"
                                                        r="8"
                                                        stroke="none"
                                                      />
                                                      <circle
                                                        cx="8"
                                                        cy="8"
                                                        r="7.5"
                                                        fill="none"
                                                      />
                                                    </g>
                                                    <path
                                                      id="Path_1170"
                                                      data-name="Path 1170"
                                                      d="M681,27V21.928"
                                                      fill="none"
                                                      stroke="#bf3054"
                                                      stroke-linecap="round"
                                                      stroke-width="1"
                                                    />
                                                    <line
                                                      id="Line_112"
                                                      data-name="Line 112"
                                                      x1="5"
                                                      transform="translate(678.5 16.5)"
                                                      fill="none"
                                                      stroke="#bf3054"
                                                      stroke-linecap="round"
                                                      stroke-width="1"
                                                    />
                                                    <line
                                                      id="Line_111"
                                                      data-name="Line 111"
                                                      y1="1"
                                                      transform="translate(681 16.5)"
                                                      fill="none"
                                                      stroke="#bf3054"
                                                      stroke-linecap="square"
                                                      stroke-width="1"
                                                    />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>

                                            <span className="text-xs text-gray-400">
                                              Set reminder
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                    {!item.follow_ups?.[0]?.datetime && (
                                      <div
                                        className="flex pt-1 cursor-pointer"
                                        onClick={() => openReminderModal(item)}
                                      >
                                        <svg
                                          className="h-3.5 w-3.5"
                                          viewBox="0 0 24 24"
                                        >
                                          <g
                                            id="Group_2097"
                                            data-name="Group 2097"
                                            transform="translate(-670 -16)"
                                          >
                                            <rect
                                              id="Rectangle_614"
                                              data-name="Rectangle 614"
                                              width="24"
                                              height="24"
                                              transform="translate(670 16)"
                                              fill="#fff"
                                              opacity="0"
                                            />
                                            <g
                                              id="Group_2096"
                                              data-name="Group 2096"
                                              transform="translate(1 3)"
                                            >
                                              <g
                                                id="Group_2095"
                                                data-name="Group 2095"
                                              >
                                                <g
                                                  id="Ellipse_210"
                                                  data-name="Ellipse 210"
                                                  transform="translate(673 19)"
                                                  fill="#fff"
                                                  stroke="#bf3054"
                                                  stroke-width="1"
                                                >
                                                  <circle
                                                    cx="8"
                                                    cy="8"
                                                    r="8"
                                                    stroke="none"
                                                  />
                                                  <circle
                                                    cx="8"
                                                    cy="8"
                                                    r="7.5"
                                                    fill="none"
                                                  />
                                                </g>
                                                <path
                                                  id="Path_1170"
                                                  data-name="Path 1170"
                                                  d="M681,27V21.928"
                                                  fill="none"
                                                  stroke="#bf3054"
                                                  stroke-linecap="round"
                                                  stroke-width="1"
                                                />
                                                <line
                                                  id="Line_112"
                                                  data-name="Line 112"
                                                  x1="5"
                                                  transform="translate(678.5 16.5)"
                                                  fill="none"
                                                  stroke="#bf3054"
                                                  stroke-linecap="round"
                                                  stroke-width="1"
                                                />
                                                <line
                                                  id="Line_111"
                                                  data-name="Line 111"
                                                  y1="1"
                                                  transform="translate(681 16.5)"
                                                  fill="none"
                                                  stroke="#bf3054"
                                                  stroke-linecap="square"
                                                  stroke-width="1"
                                                />
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                        <span className="text-xs text-gray-400">
                                          Set reminder
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td
                                  class="px-4 py-2 text-gray-900 whitespace-nowrap"
                                  onClick={() =>
                                    navigate(`${ENDPOINT_CUSTOMERS}/${item.id}`)
                                  }
                                >
                                  <div className="text-xs truncate w-32 flex flex-col">
                                    <div>
                                      {item?.is_btob_client && (
                                        <span class="ml-1 mb-1 inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                                          Business
                                        </span>
                                      )}
                                      {item?.is_hot_lead && (
                                        <span class="ml-1 mb-1 inline-flex items-center rounded-md bg-green-50 px-1 py-1 font-medium text-xs text-green-700 ring-1 ring-inset ring-green-600/20">
                                          Hot Lead
                                        </span>
                                      )}
                                      {item?.is_flagged && (
                                        <span class="ml-1 mb-1 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                                          Flagged
                                        </span>
                                      )}
                                    </div>

                                    {item.last_note && (
                                      <span>{item.last_note.message}</span>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p className="font-satoshi text-[18px] py-10 px-2 font-semibold text-center">
                          No customers to display
                        </p>
                      )}
                    </div>

                    {data && data?.length > 0 && (
                      <nav
                        class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
                        aria-label="Table navigation"
                      >
                        <span class="text-sm font-normal text-gray-500">
                          Showing
                          <span class="font-semibold text-gray-900">
                            {" "}
                            {(page - 1) * perPage + 1} -{" "}
                            {(page - 1) * perPage + perPage > count
                              ? count
                              : (page - 1) * perPage + perPage}
                          </span>
                          <span className="m-1">of</span>
                          <span class="font-semibold text-gray-900">
                            {" "}
                            {count}{" "}
                          </span>
                          <span className="m-1">results</span>
                        </span>
                        <ul class="inline-flex items-stretch -space-x-px">
                          <li>
                            <button
                              disabled={page <= 1}
                              onClick={(e) => setPage(Math.max(1, page - 1))}
                              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                            >
                              <span class="sr-only">Previous</span>
                              <svg
                                class="w-5 h-5"
                                aria-hidden="true"
                                fill="currentColor"
                                viewbox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={(e) => setPage(1)}
                              className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                            >
                              1
                            </button>
                          </li>
                          {Math.ceil(count / perPage) >= 2 && (
                            <li>
                              <button
                                onClick={(e) => setPage(2)}
                                className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                              >
                                2
                              </button>
                            </li>
                          )}

                          {Math.ceil(count / perPage) >= 3 && (
                            <li>
                              <button
                                onClick={(e) => setPage(3)}
                                className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                              >
                                3
                              </button>
                            </li>
                          )}
                          <li>
                            <a
                              href="#"
                              class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                            >
                              ...
                            </a>
                          </li>
                          <li>
                            <button
                              onClick={(e) =>
                                setPage(Math.ceil(count / perPage))
                              }
                              className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                            >
                              {Math.ceil(count / perPage)}
                            </button>
                          </li>
                          <li>
                            <button
                              disabled={page >= Math.ceil(count / perPage)}
                              onClick={(e) =>
                                setPage(
                                  Math.min(page + 1, Math.ceil(count / perPage))
                                )
                              }
                              class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 "
                            >
                              <span class="sr-only">Next</span>
                              <svg
                                class="w-5 h-5"
                                aria-hidden="true"
                                fill="currentColor"
                                viewbox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    )}
                  </div>
                </div>
              </section>
            )}
          </div>
        )}
      </div>

      {remindersModal && (
        <RemindersModal
          customer={currentCustomer}
          customerID={currentCustomer?.id}
          handleModalClose={() => setRemindersModal(false)}
          handleReload={() => fetchData()}
        />
      )}

      {reminderModal && (
        <>
          <div className="h-screen pt-1 md:pt-0  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none">
            <div className="relative p-4 w-full md:w-[40%] h-full md:h-auto">
              <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-end p-2 rounded-t "></div>

                <div className="relative p-3 flex-auto">
                  <div className="px-4 pt-4 pb-3 w-full">
                    <div className="flex flex-col">
                      <div onClick={() => setReminderModal(false)} className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 float-right cursor-pointer"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-row gap-2 justify-between">
                        <div className="flex flex-row gap-2">
                          <h2 className="text-[#030A1A] font-satoshi font-bold text-[24px] mb-4">
                            Set a follow up date & time
                          </h2>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2">
                        <form
                          onSubmit={setReminder}
                          className="w-full flex flex-col gap-2"
                        >
                          <div className="w-full flex flex-col gap-1">
                            <DateTimePicker
                              onChange={setFollowUpDateTime}
                              value={followUpDateTime}
                            />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label
                              htmlFor=""
                              className="text-[#666C7C] text-[13px]"
                            >
                              Remarks
                            </label>
                            <textarea
                              name=""
                              id=""
                              cols="5"
                              rows="4"
                              onChange={(event) =>
                                setFollowUpRemarks(event.target.value)
                              }
                              className="px-2 border flex flex-row gap-2 outline-none font-satoshi border-[#999999] text-black rounded-md w-full placeholder:text-black text-[14px]"
                            >
                              {followUpRemarks}
                            </textarea>
                          </div>
                        </form>
                      </div>
                      <div className="flex flex-row-reverse w-full py-4 gap-2">
                        <button
                          onClick={setReminder}
                          className="bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            modalbackdrop=""
            className="h-screen bg-gray-900 bg-opacity-50 fixed inset-0 z-50"
          />
        </>
      )}

      {showAddMessageModal && (
        <AddMessageModal
          closeModal={() => setShowAddMessageModal(false)}
          phoneNumbers={customerNumbers}
          reload={() => {}}
        />
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
export default Customers;
