import { axiosCustom } from '../../../utils';

const CMS_HERO_URL = '/oms/cms/main-subsections/';

export const createHero = async (heroData) => {
    try {
        const response = await axiosCustom.post(CMS_HERO_URL, heroData);
        return response.data;
    } catch (error) {
        console.log("an error occured", error.code)
    }
};

export const getHeroes = async () => {
    try {
        const response = await axiosCustom.get(CMS_HERO_URL);
        return response.data.results;
    } catch (error) {
        console.log("an error occured", error.code)
    }
};

export const updateHero = async (heroId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_HERO_URL}${heroId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("an error occured", error.code)
    }
};

export const deleteHero = async (heroId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_HERO_URL}${heroId}/`);
        return response.data;
    } catch (error) {
        console.log("an error occured", error.code)
    }
};


export const getHomepageSections = async () => {
    try {
        const response = await axiosCustom.get('/oms/cms/homepage-sections/');
        return response.data.results;
    } catch (error) {
        console.log("an error occured", error.code)
    }
}