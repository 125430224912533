import React from 'react'

import ShopAdd from '../components/cms/Shop/ShopAdd'

function ShopAddPage() {
  return (
    <div>
        <ShopAdd/>
    </div>
  )
}

export default ShopAddPage