import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ic_plus_big from "../../../assets/ic_plus_big.svg"

function HeroEdit() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-4 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
        </div>
        <div className="flex flex-col">
          <div className="pl-2 md:pl-28 pt-1  flex flex-row md:grid md:grid-cols-5 gap-2 border-b pb-2 w-full bg-white z-50">
            <div className="col-span-1">
              <h2 className="font-satoshi font-semibold text-xs md:text-lg">
                Edit  Hero 
              </h2>
            </div>
            <div className="col-span-1 "></div>
            <div className="col-span-1 flex flex-row gap-2 items-end"></div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:w-[90%] gap-10 pl-2 md:pl-20 pt-1">
          <div className="h-full pt-2 flex flex-col md:flex-row gap-20 mt-2 flex-[3] p-1 hide-scrollbar overflow-y-scroll">
            <div className="w-full flex flex-col gap-2">
              <p className="text-[#818490] font-satoshi text-md font-semibold">
                Hero Name
              </p>
              <input
                required
                className="border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                placeholder="e.g Brembo ceramic brake calipers 16 inch"
              />

              <div>
                <p className="text-[#818490] font-satoshi text-md font-semibold">
                  Hero Image
                </p>
                <input
                  type="file"
                  name="file"
                  id="files"
                  accept="image/*"
                  multiple
                  className="hidden"
                />
                <label
                  htmlFor="files"
                  className="flex border items-center gap-2 px-3 my-2 h-72 rounded-md font-semibold bg-gray-300 justify-center"
                >
                  <img src={ic_plus_big} className="h-20 bg-gray-300" alt="" />
                </label>
              </div>

              <p className="text-[#818490] font-satoshi text-md font-semibold">
                Hero Tags
              </p>
              <input
                required
                className="border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                placeholder="tag 1 ,tag2 "
              />

              <div class="mt-2 flex gap-2 w-full flex-row-reverse col-span-2">
                <button
                  title="save"
                  class="py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-white border-gray-200 bg-[#19223B] text-sm"
                >
                  Save
                </button>
                <button class="py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm">
                  <span class="text-center">Save and add another</span>
                </button>
                <button class="py-1 md:py-2 px-1 md:px-4 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm">
                  <span class="text-center">Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default HeroEdit