import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { calculateVATandSubtotal, formatDate, formatNumber, makeAuthenticatedRequest } from '../utils'
import { toast } from 'react-toastify';
import html2pdf from 'html2pdf.js';

const BClientInvoice = () => {
    const { invoiceID }  = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [processing, setProcessing] = useState(false)


    const [loading, setLoading] = useState(false)

    const [invoiceDetails, setInvoiceDetails] = useState({})
    const [totalSum , setTotalSum] = useState(0)

    const fetchInvocieDetails = () => {
        setProcessing(true)
        makeAuthenticatedRequest(`/oms/invoices/${invoiceID}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setInvoiceDetails(data)
                setTotalSum(data?.grn?.grn_parts.reduce((sum, lpo_part) => sum + parseFloat(lpo_part?.total), 0))
                setProcessing(false)

            });
    }
    useEffect(() => {
        fetchInvocieDetails(invoiceID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceID])

    console.log('invoiceDetails',invoiceDetails)

    const downloadPDF = () => {
        setLoading(true)
        const element = document.getElementById('pdf-content');
        const opt = {
          margin: 0.5,
          filename: `Invoice${invoiceDetails?.id}.pdf`,
          image:        { type: 'jpeg', quality: 0.98 },
          html2canvas:  { dpi: 192, letterRendering: true },
          jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
        };
        html2pdf().set(opt).from(element).save();


      };
  return (
    <>
                {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
            <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
            <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
    }
        <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10 font-satoshi">
    <div className="flex flex-row justify-between">
                    <div onClick={() => navigate(-1)}
                        className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-4 mt-0.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                        </svg>

                        Back
                    </div>

                </div>
            <div className="flex flex-row">
            <button onClick={downloadPDF} className='bg-gray-200 text-center px-4 py-2'>Download invoice</button>
            </div>

    <div className="border rounded-md p-5">

    <div id='pdf-content' className='w-full flex flex-col gap-2 py-4 h-full justify-between'>
      <div className="flex flex-col w-full gap-2 h-full">

        <div className="flex flex-col gap-3 h-full py-2">
            <div className="flex flex-row items-center w-full justify-between">
              <p className='font-bold text-[#D95762] font-satoshi text-3xl'>Invoice</p>
              <div className="flex flex-row items-center gap-1">
              <img src="/cdn/images/logo.svg"  alt="" />
              </div>
            </div>
            <div className=" border-b py-2 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 w-full">

        <div className="flex flex-col">
            <div className="flex flex-row gap-2">
            <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Inovice Number:</p>
              <p className='font-satoshi text-[16px] text-gray-500 capitalize'>INV-SPL222200{invoiceDetails.id}</p>
            </div>
            <div className="flex flex-row  gap-2">
            {/* <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Reference:</p> */}
              {/* <p className='font-satoshi text-[16px] text-gray-500 capitalize'>KDL/SPL-SER/10</p> */}
            </div>
          </div>
        <div className="w-full items-end flex flex-col">
          <div className="flex flex-col">
            <div className="flex flex-row  gap-2">
            <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Date Issued:</p>
              <p className='font-satoshi text-[16px] text-gray-500 capitalize'>{formatDate(invoiceDetails?.date_created)}</p>
            </div>
            <div className="flex flex-row  gap-2">
            <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Due Date:</p>
              <p className='font-satoshi text-[16px] text-gray-500 capitalize'>{formatDate(invoiceDetails?.over_due_date)}</p>
            </div>
          </div>
          
        </div>
        </div>



        </div>

            <div className="flex flex-row  w-full py-4">

            <div className="grid grid-rows-2 w-full">
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>Sparepap Limited</p>
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>P.O. Box 6957-00100</p>
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>3rd Floor, West One Building Parklands</p>
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>Nairobi</p>
                <p className='font-satoshi text-[14px] text-gray-800 capitalize'>Tel: +254-715 22 22 39</p>
            </div>
            <div className="flex flex-col items-center w-full">
            <div className="flex flex-col">

            <div className="grid grid-rows-2 w-full">
            <p className='font-satoshi text-[14px] text-gray-900 capitalize'>Bill to:</p> 
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>{invoiceDetails?.grn?.client?.name}</p>
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>{invoiceDetails?.grn?.client?.street} </p>
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>{invoiceDetails?.grn?.client?.address}</p>
            <p className='font-satoshi text-[14px] text-gray-800 capitalize'>Tel: +{invoiceDetails?.grn?.client?.phone_number}</p>
            {/* <p className='font-satoshi text-[14px] text-gray-800 capitalize'>PIN: {invoiceDetails?.grn?.client?.phone_number}</p> */}

            </div>
            </div>

            </div>
            </div>


        </div>
        <table class="table-auto w-full h-full ">
          <thead className='p-3 border-b'>
            <tr className=''>
             
              <th className='p-3 font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>#</th>
              <th className='p-3 font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>Description</th>

              <th className='p-3  font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>Qty</th>
              <th className='p-3 font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>Unit Price</th>
              <th className='p-3  font-satoshi text-xs md:text-[16px] text-left font-normal text-gray-900 '>Amount</th>
            </tr>
          </thead>
          <tbody>
           
          {invoiceDetails?.grn?.grn_parts?.map((line, index) => (

              <tr className='p-4 border-b border-b-gray-200 items-center text-gray-500'>
             
             
              <td
                className='py-2 px-2 text-xs md:text-sm cursor-pointer items-center'>

                  <p className='font-satoshi text-left '>{index + 1}</p>

              </td>
        
              <td
                className='py-2 px-2 text-xs md:text-sm cursor-pointer items-center'>
               
                <p className='font-satoshi text-left text-[16px]'>{line.part_name}</p>
                
              </td>
              <td
                className='py-2 px-2 text-xs md:text-sm cursor-pointer items-center'>
               
                <p className='font-satoshi text-left text-[16px]'>{line.quantity}</p>
                
              </td>
              <td
                className='py-2 px-2 text-xs md:text-sm cursor-pointer items-center'>

                <p className='font-satoshi text-left text-[16px]'>
                  {formatNumber(line.price,0,0)}
                  </p>
                 


              </td>
              <td
                className='py-2 px-2  text-xs md:text-sm cursor-pointer items-center'>
               
                  <p className='font-satoshi text-left text-[#030A1A] text-[16px]'>
                    {formatNumber(line.total,0,0)}
                  </p>
                 

              </td>



            </tr>
            ))}



          </tbody>
        </table>


        </div>
        <div className="flex flex-row-reverse py-5 px-2 w-full ">
          <div className="flex flex-col gap-2">

          <div className="grid grid-cols-2  gap-1 border-b p-1.5">

        <p className='font-satoshi text-[14px] text-gray-400'>Subtotal</p>
        <p className='font-satoshi text-[14px] text-gray-800'>{formatNumber(calculateVATandSubtotal(parseFloat(totalSum)).subtotal,0,0)}</p>

          </div>

          <div className="grid grid-cols-2 gap-1  border-b p-1.5">

          <p className='font-satoshi text-[14px] text-gray-400'>VAT(16%)</p>
          <p className='font-satoshi text-[14px] text-gray-800'>{formatNumber(calculateVATandSubtotal(parseFloat(totalSum)).vatAmount,0,0)}</p>

            </div>
          <div className="grid grid-cols-2 gap-1  border-b p-1.5">

          <p className='font-satoshi text-[14px] text-gray-800'>Total</p>
          <p className='font-satoshi text-[14px] text-gray-800'>{formatNumber(parseFloat(totalSum),0,0)}</p>

            </div>
          </div>

        </div>
        <div className="flex flex-col gap-2 py-3">
        {/* <p className='font-satoshi text-[18px] font-semibold'>Due Date:
        <span className='font-satoshi text-[18px] font-semibold'>{dueDate}</span> </p> */}
        <div className="flex flex-col gap-3">
        <p className='font-satoshi text-[14px] text-gray-500 font-normal'>Payment options</p>
        <div className=" border-t w-full border-dashed">
        <p className='font-satoshi text-[16px] text-gray-900'>Bank</p>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full">

        <div className="flex flex-col w-full">
        <p className='font-satoshi text-[16px] text-gray-500 capitalize'>Sparepap Limited Collection Account</p>
            <p className='font-satoshi text-[16px] text-gray-500 capitalize'>Standard Chatered Bank</p>
            <p className='font-satoshi text-[16px] text-gray-500 capitalize'>A/C: 0102476530002 (KES)</p>
            <p className='font-satoshi text-[16px] text-gray-500 capitalize'>Yaya Center Branch</p>
        </div>
        <div className="w-full items-end flex flex-col">
          <div className="flex flex-col">
  <p className='font-satoshi text-[16px] text-gray-800 capitalize'>Cheque (Account Payee)</p>
<p className='font-satoshi text-[16px] text-gray-500 capitalize'>Sparepap Limited</p>
          </div>
          
        </div>
        </div>



        </div>


        </div>

       

        </div>


        <div className="w-full border-b border-red-400 mt-10">
          <p className='text-[14px] text-red-400 italic font-satoshi text-center py-2'>Thank you for your continued support</p>
        </div>
        <div className="flex flex-row  items-center text-gray-500 h-full py-4">
            
            <p className='font-satoshi text-[12px] '>Sparepap Limited | </p>
            <p className='font-satoshi text-[12px] ml-2'>P.O.Box 6957-00100 | </p>
            <p className='font-satoshi text-[12px] ml-2'>3 rd Floor, West One Building, Parklands Rd, Nairobi, Kenya | </p>
            <p className='font-satoshi text-[12px] ml-2'>Tel: +254 715 22 22 39</p>

           
            
        </div>
     
    </div>
    </div>

        </div>

    </>
  )
}

export default BClientInvoice