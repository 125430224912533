import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../utils';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';



const CUSTOMERS_URL = `/oms/customers/`;


function PartRequestsTab() {
    const { customerID } = useParams()
    const [data, setData] = useState()
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [search, setSearch] = useState('')
    const [perPage, setPerPage] = useState(20)
    const [processing, setProcessing] = useState(false)

    const fetchData = async () => {
        setProcessing(true)
        try {
            const params = {
                "s": search,
                "page": page,
                "per_page": perPage,
            }
            const searchParams = new URLSearchParams(params).toString();
            const response = await axiosCustom.get(`${CUSTOMERS_URL}${customerID}/part-requests/?${searchParams}`);
            setPage(response?.data?.page)
            setData(response?.data?.results)
            setCount(response?.data?.count)
            setPerPage(response?.data?.per_page)
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }


    const submitSearch = async (e) => {
        e.preventDefault();
        await fetchData();
    }


    useEffect(() => {
        fetchData();
    }, [page])

    return (
        <>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div class="mx-auto max-w-screen-2xl">
                        <div class="mt-4 relative overflow-hidden">
                            <div class="flex flex-row px-2 py-3 space-y-3">
                                <div class="flex items-center flex-1 space-x-4">
                                    <form class="flex items-center" onSubmit={submitSearch}>
                                        <label for="simple-search" class="sr-only">Search</label>
                                        <div class="relative w-full">
                                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" onClick={submitSearch}>
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 " fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <input
                                                value={search}
                                                onChange={e => setSearch(e.target.value)}
                                                type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2     :ring-primary-500 :border-primary-500" placeholder="Search" required="" />
                                        </div>
                                    </form>
                                </div>
                                <div class="flex flex-col flex-shrink-0 space-y-3 md:flex-row md:items-center lg:justify-end md:space-y-0 md:space-x-3">
                                    <Link                                 
                                        to={'add'}
                                        class="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-black hover:bg-gray-800 focus:ring-4 focus:ring-primary-300  :bg-slate-400 focus:outline-none :ring-primary-800">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-white w-4 h-4 mr-1">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                        New Part Request
                                    </Link>

                                </div>
                            </div>


                            {!data || data?.length < 1 ?
                                <section class="bg-white ">
                                    <div class="py-8 mx-auto px-4">
                                        <span className='text-sm'>No part requests to display</span>
                                    </div>
                                </section>
                                :
                                <div class="relative overflow-x-auto">
                                    {data?.map((item, i) => (
                                        <Link to={`${item.id}`}>
                                            <div className='flex gap-1 items-center flex-[4] m-2 border border-slate-200 p-2 rounded'>
                                                <div className="p-1 flex-[0.5]">
                                                    {
                                                        item?.image_urls && item?.image_urls?.length > 0 ?
                                                            <img src={item?.image_urls?.[0]} class="border border-slate-200 object-cover rounded-md h-16 w-16" />
                                                            :
                                                            <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                                                                <p className='font-satoshi text-[#F77B55] text-[14px] text-center'>No images</p>
                                                            </div>
                                                    }
                                                </div>
                                                <div className='flex items-center flex-[3.5] flex-wrap'>
                                                    <div className='flex flex-col'>
                                                        <div className='flex flex-row font-semibold text-sm text-left line-clamp-2 md:w-4/4 mb-2' href={`/part/${item?.car_part?.id}`}>
                                                            <span className='mr-3 hover:underline'>{item?.name}</span>
                                                            <span
                                                                className={'text-xs px-2 py-1 rounded text-center text-black font-extrabold ' + (
                                                                    item?.status === 'New' ?
                                                                        'bg-[#B9E38D]'
                                                                        : item?.status === 'New' ?
                                                                            'bg-[#B9E38D]'
                                                                            : item?.status === 'Confirmed' ?
                                                                                'bg-[#54D3CA]'
                                                                                : item?.status === 'Dispatched' ?
                                                                                    'bg-[#8CD1FC]'
                                                                                    : item?.status === 'Delivered' ?
                                                                                        'bg-[#3E5DDD]'
                                                                                        : item?.status === 'Requires prepayment' ?
                                                                                            'bg-[#576298]'
                                                                                            : item?.status === 'To be exchanged' ?
                                                                                                'bg-[#F0A6A5]'
                                                                                                : item?.status === 'Exchange complete' ?
                                                                                                    'bg-[#059D5F]'
                                                                                                    : item?.status === 'Rejected' ?
                                                                                                        'bg-[#FCB28E]'
                                                                                                        : item?.status === 'Reject Completed' ?
                                                                                                            'bg-[#F0A6A5]'
                                                                                                            : item?.status === 'Complete' ?
                                                                                                                'bg-[#059D5F]'
                                                                                                                : item?.status === 'Cancelled' ?
                                                                                                                    'bg-[#DE6972]'
                                                                                                                    :
                                                                                                                    'bg-[#FCB28E]')}>
                                                                {item?.status}
                                                            </span>

                                                        </div>
                                                        <div className='flex text-gray-400 text-[11px] text-left items-center'>
                                                            <span className=''>
                                                                Reference #{item?.reference_number}
                                                            </span>
                                                        </div>
                                                        <p className='text-xs text-gray-700'>Quantity: <b>{item?.quantity}</b></p>
                                                        <p className='text-sm'>
                                                            <Moment format="MMM, DD LT" className='flex'>
                                                                {item?.date_created}
                                                            </Moment>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            }

                            {data && data?.length > 0 &&
                                <nav class="flex flex-col items-start justify-between p-4 space-y-3 md:flex-row md:items-center md:space-y-0" aria-label="Table navigation">
                                    <span class="text-sm font-normal text-gray-500 ">
                                        Showing
                                        <span class="font-semibold text-gray-900 "> {((page - 1) * perPage) + 1} - {((page - 1) * perPage) + perPage > count ? count : ((page - 1) * perPage) + perPage}</span>
                                        <span className='m-1'>of</span>
                                        <span class="font-semibold text-gray-900 "> {count} </span>
                                        <span className='m-1'>results</span>
                                    </span>
                                    {count > perPage &&
                                        <ul class="inline-flex items-stretch -space-x-px">
                                            <li>
                                                <button
                                                    disabled={page <= 1}
                                                    onClick={e => setPage(Math.max(1, page - 1))}
                                                    className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">
                                                    <span class="sr-only">Previous</span>
                                                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                    </svg>
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={e => setPage(1)}
                                                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">1</button>
                                            </li>
                                            {
                                                Math.ceil(count / perPage) >= 2 &&
                                                <li>
                                                    <button
                                                        onClick={e => setPage(2)}
                                                        className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">2</button>
                                                </li>
                                            }

                                            {
                                                Math.ceil(count / perPage) >= 3 &&
                                                <li>
                                                    <button
                                                        onClick={e => setPage(3)}
                                                        className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">3</button>
                                                </li>
                                            }
                                            <li>
                                                <a href="#" class="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">...</a>
                                            </li>
                                            <li>
                                                <button
                                                    onClick={e => setPage(Math.ceil(count / perPage))}
                                                    className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">{Math.ceil(count / perPage)}</button>
                                            </li>
                                            <li>
                                                <button
                                                    disabled={page >= Math.ceil(count / perPage)}
                                                    onClick={e => setPage(Math.min(page + 1, Math.ceil(count / perPage)))}
                                                    class="flex items-center justify-center h-full py-1.5 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700    :bg-gray-700 :text-white">
                                                    <span class="sr-only">Next</span>
                                                    <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                                                    </svg>
                                                </button>
                                            </li>
                                        </ul>
                                    }
                                </nav>
                            }
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
export default PartRequestsTab