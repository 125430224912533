import React, { useEffect, useRef, useState } from 'react'
import clockDanger from '../assets/watch-2.svg'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { makeAuthenticatedRequest, formatNumber } from '../utils';
import { Pagination } from '../components/Pagination';
import DateTimePicker from 'react-datetime-picker';
import { ENDPOINT_LEADS } from '../routes'
import Moment from 'react-moment';
import moment from 'moment';
import Avatar from 'react-avatar';
import { Link } from 'react-router-dom';

const ALL_TAB = null
const HOT_LEAD_TAB = "hot_leads"
const COLD_LEAD_TAB = "cold_leads"
const MY_LEADS = "my_leads"


function LeadsPage() {
  const [followUpRemarks, setFollowUpRemarks] = useState()
  const [followUpDateTime, setFollowUpDateTime] = useState(new Date())
  const [reminderModal, setReminderModal] = useState(false)
  const [modalLeadID, setModalLeadID] = useState()
  const [addLeadModal, setAddLeadModal] = useState(false)
  const [newLeadName, setNewLeadName] = useState()
  const [changeStatusModal, setChangeStatusModal] = useState(false)
  const [newLeadPhoneNumber, setNewLeadPhoneNumber] = useState()

  const [newStatus, setNewStatus] = useState()
  const [currentItem, setCurrentItem] = useState()
  const [myLeadsCount, setMyLeadsCount] = useState()
  const [hotLeadsCount, setHotLeadsCount] = useState()
  const [coldLeadsCount, setColdLeadsCount] = useState()

  const [processing, setProcessing] = useState(false)

  const dateIsBeforeNow = (theDate) => {
    return moment(theDate).isAfter(new Date());
  }
  const dateIsAterNow = (theDate) => {
    return moment(theDate).isBefore(new Date());
  }
  const submitSearch = () => {
    if (page != 1) {
      setPage(1)
    } else {
      fetchLeads()
    }
  }

  const [open, setOPen] = useState([]);
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [search, setSearch] = useState('')
  const [currentTab, setCurrentTab] = useState(ALL_TAB)
  const [totalItems, setTotalItems] = useState(0)
  const [leadsData, setLeadsData] = useState()

  const openReminderModal = (item) => {
    setReminderModal(true)
    setModalLeadID(item.id)
    if (item.follow_up_datetime) {
      setFollowUpDateTime(new Date(item.follow_up_datetime))
    } else {
      setFollowUpDateTime(new Date(moment(new Date()).add(60, 'm')))
    }
    setFollowUpRemarks('')
  }

  const changeLeadStatusDialog = (item, newStatus) => {
    setNewStatus(newStatus)
    setFollowUpRemarks('')
    setCurrentItem(item)
    setModalLeadID(item.id)
    setChangeStatusModal(true)
  }

  const openAddLeadModel = () => {
    setAddLeadModal(true)
    setNewLeadName('')
    setFollowUpRemarks('')
    setNewLeadPhoneNumber('')
  }

  const statusChangeDropdown = orderID => {
    const openState = open.slice()
    const index = openState.indexOf(orderID)

    if (index >= 0) {
      openState.splice(index, 1)
      setOPen(openState)
    } else {
      openState.push(orderID)
      setOPen(openState)
    }
  }

  const submitNewStatus = async () => {
    setChangeStatusModal(false)
    setProcessing(true)

    const params = {
      status: newStatus,
      remarks: followUpRemarks
    }
    console.log(JSON.stringify(params))
    try {
      const response = await makeAuthenticatedRequest(`/oms/consumers/lead/${modalLeadID}/status_change/`, "POST", JSON.stringify(params));
      const data = response.json();
      fetchLeads()
    } finally {
      setProcessing(false);
    }
  }

  const fetchLeads = () => {
    setProcessing(true)
    const params = {
      "s": search,
      "page": page,
      "per_page": perPage,
    }
    if (currentTab) {
      params.tab = currentTab
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/consumers/lead?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setLeadsData(data)
        setPage(data.page)
        setPerPage(data.per_page)
        setTotalItems(data.count)
        setMyLeadsCount(data.my_leads_count)
        setHotLeadsCount(data.hot_leads_count)
        setColdLeadsCount(data.cold_leads_count)
        setProcessing(false)
      });
  }

  const pageChanged = (pageNumber) => {
    setPage(pageNumber)
  }

  const statusOptions = [
    { name: 'New', value: 'New', label: 'New', color: '#B9E38D' },
    { name: 'Scheduled', value: 'Scheduled', label: 'Scheduled', color: '#54D3CA' },
    { name: 'Converted', value: 'Converted', label: 'Converted', color: '#8CD1FC' },
    { name: 'In progress', value: 'In progress', label: 'In progress', color: '#3E5DDD' },
    { name: 'Dropped', value: 'Dropped', label: 'Dropped', color: '#DE6972' }
  ]
  

  // new
  // scheduled
  // In progress = > Question
  // Dropped
  // Converted = > Engaged



  useEffect(() => {
    fetchLeads()
  }, [page, currentTab])





  const cancelRef = useRef()


  const setReminder = async () => {
    setProcessing(true)
    setReminderModal(false)

    const params = {
      remarks: followUpRemarks,
      reminder_datetime: moment(followUpDateTime).toISOString()
    }
    console.log(JSON.stringify(params))
    try {
      const response = await makeAuthenticatedRequest(`/oms/consumers/lead/${modalLeadID}/follow_up_date/`, "POST", JSON.stringify(params));
      const data = response.json();
      fetchLeads()
    } finally {
      setProcessing(false);
    }
  }


  const createLead = async (hotLead) => {
    setProcessing(true)
    setAddLeadModal(false)

    const params = {
      phone_number: `254${newLeadPhoneNumber}`,
      is_hot_lead: hotLead,
    }
    if (newLeadName) {
      params.name = newLeadName
    }
    if (followUpRemarks) {
      params.remarks = followUpRemarks
    }
    console.log(JSON.stringify(params))
    return makeAuthenticatedRequest(`/oms/consumers/lead/`, "POST", JSON.stringify(params))
      .then((response) => response.json())
      .then((data) => {
        console.log('sendData', data)
        // return data
        toast.success("Lead succesfully added")
        setAddLeadModal(false)
        fetchLeads()

        // window.location.reload()
      })
      .catch((err) => {
        toast.error("Something went wrong could not create lead")
        console.log(err)
        setAddLeadModal(false)

      }).finally(() => {
        setProcessing(false);
      });

  }

  return (
    <>
      {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20">
        <p className='font-satoshi text-xl font-semibold'>Leads</p>

        <div className="flex flex-col">
          <div className="flex flex-col ">
            <div className="flex flex-row-reverse">
              <button
                onClick={openAddLeadModel}
                className='outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-white w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
                Add Lead</button>
            </div>

            <div className='mt-2 mb-2'>
              <form className="flex items-start md:items-center font-satoshi" method="get">
                <label htmlFor="simple-search" className="sr-only">Search</label>
                <div className="relative w-full">
                  <div
                    onClick={submitSearch}
                    className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md">
                    <svg aria-hidden="true" className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd">
                      </path>
                    </svg>
                  </div>
                  <input
                    onChange={e => setSearch(e.target.value)}
                    required
                    value={search}
                    type="text" name="s"
                    id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    " placeholder="Search a lead" />
                </div>
              </form>
            </div>

            <div className="flex flex-row gap-2 mt-4 overflow-x-scroll scrollbar-hide">
              <div
                onClick={() => setCurrentTab(ALL_TAB)}
                className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === ALL_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}> All
                <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{totalItems}</span>
              </div>
              <div
                onClick={() => setCurrentTab(HOT_LEAD_TAB)}
                className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === HOT_LEAD_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Hot Leads
                <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{hotLeadsCount}</span>
              </div>
              <div
                onClick={() => setCurrentTab(COLD_LEAD_TAB)}
                className={`whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === COLD_LEAD_TAB ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>Cold Leads
                <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{coldLeadsCount}</span>
              </div>
              <div
                onClick={() => setCurrentTab(MY_LEADS)}
                className={` whitespace-nowrap text-center cursor-pointer   py-1 px-3 rounded-md font-satoshi ${currentTab === MY_LEADS ? 'text-white bg-[#333B51]' : 'text-[#808491] hover:bg-[#E5E6E9]'}`}>My Leads
                <span className='p-1 ml-2 text-[#b0b6c7] text-xs rounded'>{myLeadsCount}</span>
              </div>
            </div>


            <div className="flex flex-col ">
              {
                leadsData && !processing ? (
                  <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                    <table class="table-auto w-full  ">
                      <thead className='pl-2'>
                        <tr className='pl-1 border-b border-gray-200'>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Customer</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Date Added</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Follow up</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Added By</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Status</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          leadsData.results.map((item) => (
                            <tr className='p-2 border-b border-b-gray-200 relative'>
                              <td
                                className='p-2 text-xs md:text-[16px] text-left font-satoshi inline-block'>
                                <Link to={`${ENDPOINT_LEADS}/${item.id}`}>
                                  <Avatar name={item.first_name ? `${item.first_name} ${item.last_name}` : item.name} size="32" textSizeRatio={1.75} className="rounded-full shadow" />
                                  <div className='inline-block ml-3 align-middle'>
                                    {item.first_name ?
                                      <>
                                        <span className='capitalize'>{item?.first_name}</span> <span className="capitalize">{item?.last_name}</span>
                                      </> :
                                      <span className='capitalize'>{item.name}</span>
                                    }
                                    <br />
                                    <a className='text-cyan-500 hover:underline pt-2' href={`tel:+${item.phone_number}`}>+{item.phone_number}</a>
                                  </div>
                                </Link>
                              </td>
                              <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                <div className=" text-[10px] md:text-[14px] ">
                                  {item.date_lead_added &&
                                    <Moment format="MMM, DD LT" className='flex'>
                                      {item.date_lead_added}
                                    </Moment>
                                  }
                                </div>
                              </td>

                              <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                <div className=" text-[14px]">
                                  {item.follow_up_datetime && dateIsBeforeNow(item.follow_up_datetime) &&
                                    <>
                                      <Moment format="MMM, DD LT" className='text-[#4ba187] font-bold'>
                                        {item.follow_up_datetime}
                                      </Moment>
                                      <div className="flex pt-1 cursor-pointer" onClick={() => openReminderModal(item)}>
                                        <span className='text-[12px] text-[#4b66a1]'>Edit reminder</span>
                                      </div>
                                    </>
                                  }
                                  {item.follow_up_datetime && dateIsAterNow(item.follow_up_datetime) &&

                                    <>
                                      <Moment format="MMM, DD LT" className='text-[#BF3054] font-bold'>
                                        {item.follow_up_datetime}
                                      </Moment>
                                      <div className="flex pt-1 cursor-pointer" onClick={() => openReminderModal(item)}>
                                        <img src={clockDanger} className="h-2 md:h-4" />
                                        <span className='text-[13px] text-[#BF3054]'>Set reminder</span>
                                      </div>
                                    </>


                                  }

                                  {!item.follow_up_datetime &&
                                    <>
                                      <span>No follow-up scheduled</span>
                                      <div className="flex pt-1 cursor-pointer" onClick={() => openReminderModal(item)}>
                                        <img src={clockDanger} className="h-2 md:h-4" />
                                        <span className='text-[13px] text-[#BF3054]'>Set reminder</span>
                                      </div>
                                    </>
                                  }
                                </div>
                              </td>
                              <td
                                className='p-2 text-xs md:text-[16px] text-left font-satoshi'>
                                <span className='capitalize'>{item?.referrer?.first_name}</span> <span className="capitalize">{item?.referrer?.last_name}</span>
                              </td>
                              <td
                                className='p-4 text-xs md:text-[16px] cursor-pointer relative'>
                                <div
                                  onClick={() => statusChangeDropdown(item.id)}
                                  className={'text-xs flex flex-row cursor-pointer relative w-full  justify-between relative rounded px-3 py-2 font-satoshi text-center ' + (
                                    item.follow_up_status === 'New' ?
                                      'bg-[#B9E38D]'
                                      : item.follow_up_status === 'Scheduled' ?
                                        'bg-[#54D3CA]'
                                        : item.follow_up_status === 'Engaged' ?
                                          'bg-[#8CD1FC]'
                                          : item.follow_up_status === 'Question' ?
                                            'bg-[#3E5DDD]'
                                            : item.follow_up_status === 'Dropped' ?
                                              'bg-[#DE6972]'
                                              :
                                              'bg-[#8d252e]')}>

                                  {item.follow_up_status}
                                  <div className="">
                                    {
                                      open.includes(item.id) ?
                                        // arrow-down
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className=" h-4">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className=" h-4">
                                          <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                        </svg>
                                      // arrow-up
                                    }
                                  </div>
                                </div>

                                {open.includes(item.id) && (


                                  <div className={`bg-white border-4 border-white absolute top-6 rounded-md p-1 flex flex-col gap-2  w-full hide-scrollbar overflow-y-scroll absolute top-14 z-50 shadow-2xl right-2`}>
                                    {statusOptions.filter((statusOption) => statusOption.name !== item.follow_up_status).map((status) => (
                                      <div
                                        onClick={() => changeLeadStatusDialog(item, status.name)}
                                        className={`bg-[${status.color}] rounded-md px-4 py-1.5 cursor-pointer`}>
                                        <p className='text-[13px] font-satoshi cursor-pointer'>{status.name}</p>
                                      </div>
                                    ))}

                                  </div>

                                )}
                              </td>


                              <td className='p-2  flex-col text-left font-satoshi text-[#808491]'>
                                <div className=" text-[10px] md:text-[14px] ">
                                  {item.notes && item.notes.length > 0 &&
                                    <span>{item.notes[0].message}</span>
                                  }
                                </div>
                              </td>
                            </tr>


                          ))}



                      </tbody>
                    </table>
                    {leadsData.results.length > 0 &&
                      <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={page} />
                    }
                  </div>
                ) :
                  <>
                    {!processing &&
                      <p className='font-satoshi text-[18px] py-6'>No leads to display</p>
                    }
                  </>
              }
            </div>

          </div>
        </div>
      </div>

      <ToastContainer

        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {addLeadModal &&
        <>
          <div className='h-screen pt-1 md:pt-0  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
            <div className='relative p-4 w-full md:w-[40%] h-full md:h-auto'>
              <div ref={cancelRef} className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-start justify-end p-2 rounded-t '>
                </div>

                <div className='relative p-3 flex-auto'>
                  <div className='px-4 pt-4 pb-3 w-full'>

                    <div className="flex flex-col">
                      <div onClick={() => setAddLeadModal(false)} className="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                      <div className="flex flex-row gap-2 justify-between">
                        <div className="flex flex-row gap-2">
                          <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                            Enter details
                          </h2>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2">
                        <form className='w-full flex flex-col gap-2'>
                          <div className="w-full flex flex-col gap-1">
                            <label htmlFor="name" className='font-satoshi text-[14px] flex flex-row '><span className="text-gray-500 font-medium">Name</span> </label>
                            <input required name='name'
                              value={newLeadName}
                              onChange={(e) => setNewLeadName(e.target.value)}
                              type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                          </div>
                          <div className="w-full flex flex-col gap-1">
                            <label htmlFor="phone_number" className='font-satoshi text-[14px] flex flex-row '><span className="text-gray-500 font-medium">Phone number</span> <span className='text-red-400'>*</span></label>
                            <div className="relative rounded-md shadow-sm ">
                              <div className="pointer-events-none absolute inset-y-4 left-0 flex items-center p-3">
                                <span className="text-gray-500 font-medium">+254</span>
                              </div>
                              <input
                                type="number"
                                value={newLeadPhoneNumber}
                                onChange={(e) => setNewLeadPhoneNumber(e.target.value)}
                                className="pl-14 block w-full px-4 py-2 mt-2 text-[#030A1A] outline-none border rounded-md focus:outline-none focus:ring focus:ring-opacity-40 PhoneNumber1"
                                placeholder="723456789"
                              />
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <label htmlFor="" className='text-[#666C7C] text-[13px]'>Remarks</label>
                            <textarea name="" id="" cols="5" rows="4"
                              onChange={(event) => setFollowUpRemarks(event.target.value)}
                              className='px-2 border flex flex-row gap-2 outline-none font-satoshi text-[#030A1A] rounded-md w-full  text-[14px]'>
                              {followUpRemarks}
                            </textarea>


                          </div>
                        </form>
                      </div>
                      <div className="flex flex-row-reverse w-full py-4 gap-2">
                        <button
                          onClick={() => createLead(true)}
                          className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>
                          Hot Lead
                        </button>
                        <button
                          onClick={() => createLead(false)}
                          className='border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md'>Cold Lead</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-50' />
        </>
      }

      {reminderModal &&
        <>
          <div className='h-screen pt-1 md:pt-0  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
            <div className='relative p-4 w-full md:w-[40%] h-full md:h-auto'>
              <div className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-start justify-end p-2 rounded-t '>
                </div>

                <div className='relative p-3 flex-auto'>
                  <div className='px-4 pt-4 pb-3 w-full'>

                    <div className="flex flex-col">
                      <div onClick={() => setReminderModal(false)} className="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                      <div className="flex flex-row gap-2 justify-between">
                        <div className="flex flex-row gap-2">
                          <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                            Set a follow up date & time
                          </h2>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2">
                        <form onSubmit={setReminder} className='w-full flex flex-col gap-2'>
                          <div className="w-full flex flex-col gap-1">
                            <DateTimePicker onChange={setFollowUpDateTime} value={followUpDateTime} />
                          </div>
                          <div className="flex flex-col gap-2">
                            <label htmlFor="" className='text-[#666C7C] text-[13px]'>Remarks</label>
                            <textarea name="" id="" cols="5" rows="4"
                              onChange={(event) => setFollowUpRemarks(event.target.value)}
                              className='px-2 border flex flex-row gap-2 outline-none font-satoshi border-[#999999] text-black rounded-md w-full placeholder:text-black text-[14px]'>
                              {followUpRemarks}
                            </textarea>


                          </div>
                        </form>
                      </div>
                      <div className="flex flex-row-reverse w-full py-4 gap-2">
                        <button
                          onClick={setReminder}
                          className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>Submit</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-50' />
        </>
      }


      {changeStatusModal &&
        <>
          <div className='h-screen pt-1 md:pt-0  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
            <div className='relative p-4 w-full md:w-[40%] h-full md:h-auto'>
              <div className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-start justify-end p-2 rounded-t '>
                </div>

                <div className='relative p-3 flex-auto'>
                  <div className='px-4 pt-4 pb-3 w-full'>

                    <div className="flex flex-col">
                      <div onClick={() => setChangeStatusModal(false)} className="">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                      <div className="flex flex-row gap-2 justify-between">
                        <div className="flex flex-row gap-2">
                          <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                            Change status to <span className='text-[#666C7C]'>`{newStatus}`</span>
                          </h2>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2">
                        <form onSubmit={submitNewStatus} className='w-full flex flex-col gap-2'>
                          <div className="flex flex-col gap-2">
                            <label htmlFor="" className='text-[#666C7C] text-[13px]'>Remarks</label>
                            <textarea name="" id="" cols="5" rows="4"
                              onChange={(event) => setFollowUpRemarks(event.target.value)}
                              className='px-2 border flex flex-row gap-2 outline-none font-satoshi border-[#999999] text-black rounded-md w-full placeholder:text-black text-[14px]'>
                              {followUpRemarks}
                            </textarea>
                          </div>
                        </form>
                      </div>
                      <div className="flex flex-row-reverse w-full py-4 gap-2">
                        <button
                          onClick={submitNewStatus}
                          className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>Submit</button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-50' />
        </>
      }
    </>
  )
}

export default LeadsPage