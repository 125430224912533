import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";
import { ENDPOINT_SHORT_URLS } from "../../routes";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { isValidLink, createLink } from "./urlsUtils";

function UrlsAdd() {
  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [fullLink, setFullLink] = useState("");
  const [shortLink, setShortLink] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [expiryDate, setExpiryDate] = useState(null);

  const handleCancel = () => {
    navigate(ENDPOINT_SHORT_URLS);
  };

  const handleSubmit = async (event, endpoint) => {
    event.preventDefault();

    setProcessing(true);

    if (!isValidLink(fullLink)) {
      toast.error("Enter a valid link");
      setProcessing(false);
      return;
    }

    if (shortLink == null || shortLink.trim().length < 1) {
      toast.error("Enter a valid short link");
      setProcessing(false);
      return;
    }

    if (isActive == null) {
      toast.error("Enter a valid status");
      setProcessing(false);
      return;
    }

    const params = {
      full_link: fullLink,
      short_link: shortLink,
      expiration_date: expiryDate,
      is_active: isActive,
      is_autogenerated: false,
    };

    createLink(params)
      .then((response) => {
        toast.success("Short link created successfully");
        navigate(-1);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          const errors = error?.response?.data[0];
          errors
            ? toast.error(errors)
            : toast.error(`Error creating short link`);
          return;
        } else if (error.request) {
          toast.error("Something went wrong!");
          return;
        } else {
          toast.error("Something went wrong!");
          return;
        }
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <>
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-4 mt-0.5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
        </div>

        {false ? (
          <ReactLoading type="bubbles" color="#030A1A" height={50} width={60} />
        ) : (
          <div className="flex flex-col">
            <div className="pl-2 md:pl-28 pt-1  flex flex-row md:grid md:grid-cols-5 gap-2 border-b pb-2 w-full bg-white z-50">
              <div className="col-span-1">
                <h2 className="font-satoshi font-semibold text-xs md:text-lg">
                  Create a Link
                </h2>
              </div>
              <div className="col-span-1 "></div>
              <div className="col-span-1 flex flex-row gap-2 items-end"></div>
            </div>
            <div className="flex flex-col md:flex-row md:w-[90%] gap-10 pl-2 md:pl-20 pt-1">
              <div className="h-full pt-2 flex flex-col md:flex-row gap-20 mt-2 flex-[3] p-1 hide-scrollbar overflow-y-scroll">
                <div className="w-full flex flex-col gap-2">
                  <p className="text-[#818490] font-satoshi text-md font-semibold">
                    Full Link
                  </p>
                  <input
                    onChange={(e) => setFullLink(e.target.value)}
                    required
                    value={fullLink}
                    className="border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                    placeholder="https://sparepap.com/batteries/"
                  />

                  <p className="text-[#818490] font-satoshi text-md font-semibold">
                    Short Link
                  </p>
                  <input
                    onChange={(e) => setShortLink(e.target.value)}
                    required
                    value={shortLink}
                    className="border border-gray-300 p-2 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                    placeholder="spa"
                  />

                  <p className="text-[#818490] font-satoshi text-md font-semibold">
                    Expiry date
                  </p>
                  <DateTimePicker
                    minDate={new Date()}
                    onChange={setExpiryDate}
                    value={expiryDate}
                    className="border border-gray-300 w-full text-[#19223B] font-satoshi text-md rounded placeholder:text-gray-400 placeholder:font-semibold outline-none"
                  />

                  <div className="py-3">
                    <div className="text-gray-500 font-bold text-sm gap-2 mr-4">
                      Activate Link
                      <span className="float-right">
                        <label
                          htmlFor="push-green-toggle-2"
                          className="inline-flex relative items-center mr-5 cursor-pointer"
                        >
                          <input
                            onChange={() => setIsActive(!isActive)}
                            checked={isActive}
                            type="checkbox"
                            value=""
                            id="push-green-toggle-2"
                            className="sr-only peer"
                          />

                          <div className="w-11 h-6 bg-gray-200 rounded-full  peer-focus:ring-3 peer-focus:ring-green-300 :ring-green-800 peer-checked:after:translate-x-full after:absolute after:top-0.5 after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                        </label>
                      </span>
                    </div>
                  </div>
                  <hr />

                  <div className="grid py-4 mt-4">
                    <div className="flex flex-row gap-4 md:col-span-2"></div>
                    {processing ? (
                      <div className="flex flex-row gap-2">
                        <button
                          className="py-1 md:py-2 px-1 md:px-4 text-center font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-xs md:text-sm"
                          onClick={handleCancel}
                        >
                          <span className="text-center">Processing</span>{" "}
                          <ReactLoading
                            type="bubbles"
                            color="#030A1A"
                            height={10}
                            width={30}
                            className="inline-block align-middle"
                          />
                        </button>
                      </div>
                    ) : (
                      <div className="flex gap-2 w-full flex-row-reverse col-span-2">
                        <button
                          title="save"
                          className="py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-white border-gray-200 bg-[#19223B] text-sm"
                          onClick={(e) => handleSubmit(e, ENDPOINT_SHORT_URLS)}
                        >
                          Save
                        </button>
                        <button
                          className="py-1 md:py-2 px-1 md:px-3 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm"
                          onClick={handleSubmit}
                        >
                          <span className="text-center">
                            Save and add another
                          </span>
                        </button>
                        <button
                          className="py-1 md:py-2 px-1 md:px-4 text-center  font-satoshi rounded-md border text-[#808491] border-[#808491] bg-white text-sm"
                          onClick={handleCancel}
                        >
                          <span className="text-center">Cancel</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
export default UrlsAdd;
