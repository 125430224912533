import React, { useState } from 'react'
import Avatar from 'react-avatar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ENDPOINT_DASHBOARD, ENDPOINT_DSF, ENDPOINT_SERVICE_BOOKING,ENDPOINT_ENTER_EMAIL, ENDPOINT_INVENTORY, ENDPOINT_LEADS, ENDPOINT_MARKETPLACE, ENDPOINT_MECHANICS, ENDPOINT_ORDERS, ENDPOINT_PART_REQUESTS, ENDPOINT_STAFF, ENDPOINT_TRACK_ORDERS, ENDPOINT_USERS, ENDPOINT_BTOB, ENDPOINT_CMS, ENDPOINT_SHORT_URLS } from '../routes';
import { AccountObj, clearAccountInfo } from '../utils';



export default function Topbar({len}) {
  const [openDash, setOpenDash] = useState(false)
  const [openDropdown, setOpenDropdown] = useState(false)
  const [userLogout, setUserLogout] = useState('Log out')

  const [activeTab, setActiveTab] = useState(1)
  const accountObj = new AccountObj();
  const navigate = useNavigate()
  const { pathname } = useLocation()
  console.log('pathname', pathname)

    const logout = () => {
      if (userLogout === 'Confirm') {
        clearAccountInfo()
        navigate(ENDPOINT_ENTER_EMAIL)
      }
    }


    const handleSetUserLogout = () => {
      setUserLogout("Confirm")
      setTimeout(() => {
        setUserLogout("Log out")
      }, 2000);
    }
  


  return (
    <nav className={` flex flex-col gap-2  md:flex-row md:items-start md:justify-start fixed w-full pr-2 pl-2 z-50  top-0 ${len >=1? '':'z-50'} bg-white  border-b py-2`}>
      <div className='container mx-auto'>
        <div className='flex flex-row w-full md:w-full justify-between gap-2 md:gap-4'>
          <div className="w-2/4 -ml-0 md:-ml-6">
          { false && 
            <form className="flex items-start md:items-center font-satoshi" method="get">
              <label htmlFor="simple-search" className="sr-only">Search</label>
              <div className="relative w-full">
                <div className="flex absolute inset-y-0 right-0 m-1.5 items-center pl-3 pointer-events-none px-2 py-1 text-sm font-medium text-[#E91A47] bg-[#FDF6F7] rounded-lg border border-[#FDF6F7]">
                  <svg aria-hidden="true" className="w-5 h-5 text-[#E91A47] bg-[#FDF6F7] rounded-lg border border-[#FDF6F7]" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd">
                    </path>
                  </svg>
                </div>
                <input
                  type="text" name="s"
                  id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-10 px-2.5  py-2    " placeholder="Search inventory" required />
              </div>
            </form>}
          </div>


          {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
          </svg> */}


          {false &&
            <>

              <div className="flex flex-row gap-4">
                <div className="flex flex-col gap-1">
                  <div className="flex justify-end items-end">
                    <div className="rounded-full bg-[#E91A47] h-1.5 w-1.5"></div>
                  </div>
                  <img src="/cdn/images/ic_make_part_request.svg" alt="" />
                </div>
              </div>

              <div className="flex flex-row gap-4">
                <div className="flex flex-col gap-1">
                  <div className="flex justify-end items-end">
                    <div className="rounded-full bg-[#E91A47] h-1.5 w-1.5"></div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                  </svg>
                </div>

              </div>
            </>
          }

          <div className='mr-0 md:mr-64'>



            <div className="cursor-pointer" >

              <div
              
               className='flex flex-row gap-1 items-center relative justify-center -ml-60 md:ml-0 '>
                <Avatar name={accountObj.name} size="32" textSizeRatio={1.75} className="mr-2 rounded-full shadow" />
                <div
                  onClick={() => setOpenDropdown(!openDropdown)}

                 className="flex flex-row gap-2">
                  <p className='text-xs md:text-sm text-[#030A1A] font-satoshi'>{accountObj.name}</p>
                  {!openDropdown ?
                  <svg
                  onClick={() => setOpenDropdown(true)}
                   xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>
                  :
                  <svg 
                  onClick={() => setOpenDropdown(false)} 
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                </svg>
                }


                  
                </div>
                {openDropdown &&
                <div 
                onClick={() => {
                  handleSetUserLogout()
                  logout()
                }}
                className="flex flex-row gap-2 hover:bg-red-50 absolute items-center top-10 bg-white rounded-md shadow-md border w-full px-2 py-1">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-red-500">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                    </svg>

                  <p className='  text-xs md:text-sm text-red-500 font-satoshi'>{userLogout}</p>
                </div>
} 
              </div>

            </div>


          </div>
          <button
            onClick={() => setOpenDash(!openDash)}
            className="md:hidden flex h-10 items-center px-4 cursor-pointer border rounded text-black  border-gray-300  outline-none">
            {!openDash ?
              <svg className="fill-current  w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-3">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>

            }
          </button>
        </div>
      </div>

      <>
        {openDash && (
          <div className='flex flex-col '>
            <ul className="pt-2 pb-4 space-y-1 text-sm w-full">
                               
                <li 
                onClick={() => navigate(ENDPOINT_DASHBOARD)}
                className="rounded-sm w-full cursor-pointer">
                  <div
                    className="flex items-center py-2 pl-4 pr-10 rounded-md"
                  >
                    <p className={`text-sm ${pathname === ENDPOINT_DASHBOARD ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Dashboard</p>
                  </div>
                </li>
                <li 
                onClick={() => navigate(ENDPOINT_MARKETPLACE)}
                className="rounded-sm w-full cursor-pointer">
                  <div
                    className="flex items-center py-2 pl-4 pr-10 rounded-md"
                  >
                    <p className={`text-sm ${pathname === ENDPOINT_MARKETPLACE ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Marketplace</p>
                  </div>
                </li>
             {false && <li
                onClick={() => navigate(ENDPOINT_ORDERS)}               
                className="rounded-sm w-full cursor-pointer">
                <div
                  className="flex items-center py-2 pl-4 pr-10 rounded-md"
                >
                  <p className={`text-sm ${pathname === ENDPOINT_ORDERS ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Orders</p>
                </div>
              </li>}
              <li
                 onClick={() => navigate(ENDPOINT_MECHANICS)}
                className="rounded-sm w-full cursor-pointer">
                <div
                  className="flex items-start pl-4  rounded-md"
                >
                  <p className={`text-sm ${pathname === ENDPOINT_MECHANICS ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Mechanics</p>
                </div>
              </li>
              <li
                onClick={() => navigate(ENDPOINT_INVENTORY)}
                className="rounded-sm w-full py-2 cursor-pointer">
                <div
                  className="flex items-start pl-4  rounded-md"
                >
                  <p className={`text-sm ${pathname === ENDPOINT_INVENTORY ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Inventory</p>
                </div>
              </li>

              <li
                onClick={() => navigate(ENDPOINT_DSF)}
                className="rounded-sm w-full py-2 cursor-pointer">
                <div
                  className="flex items-start pl-4  rounded-md"
                >
                  <p className={`text-sm ${pathname === ENDPOINT_DSF ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>DSF</p>
                </div>
              </li>
              <li
                onClick={() => navigate(ENDPOINT_PART_REQUESTS)}
                className="rounded-sm w-full cursor-pointer">
                <div
                  className="flex items-start pl-4  rounded-md"
                >
                  <p className={`text-sm ${activeTab === ENDPOINT_PART_REQUESTS ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Part Requests</p>
                </div>
              </li>
              <li
                onClick={() => navigate(ENDPOINT_STAFF)}
                className="rounded-sm w-full cursor-pointer">
                <div
                  className="flex items-start pl-4  rounded-md"
                >
                  <p className={`text-sm ${activeTab === ENDPOINT_STAFF ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Staff</p>
                </div>
              </li>
              <li
                onClick={() => navigate(ENDPOINT_LEADS)}
                className="rounded-sm w-full cursor-pointer">
                <div
                  className="flex items-start pl-4  rounded-md"
                >
                  <p className={`text-sm ${activeTab === ENDPOINT_LEADS ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Leads</p>
                </div>
              </li>
              <li
                  onClick={() => navigate(ENDPOINT_CMS)}
                  className="rounded-sm w-full cursor-pointer pb-[20px]">
                  <div
                      className="flex items-center pl-4 pr-10 rounded-md"
                  >
                      <p className={`text-xs md:text-[18px] ${activeTab === ENDPOINT_CMS ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>CMS</p>
                  </div>
              </li>

              <li
                onClick={() => navigate(ENDPOINT_SERVICE_BOOKING)}
                className="rounded-sm w-full cursor-pointer">
                <div
                  className="flex items-start pl-4  rounded-md"
                >
                  <p className={`text-sm ${activeTab === ENDPOINT_SERVICE_BOOKING ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Services</p>
                </div>
              </li>

              <li
                onClick={() => navigate(ENDPOINT_SHORT_URLS)}
                className="rounded-sm w-full cursor-pointer">
                <div
                  className="flex items-start pl-4  rounded-md"
                >
                  <p className={`text-sm ${activeTab === ENDPOINT_SHORT_URLS ? 'text-[#E91A47]' : 'text-[#707070]'} font-satoshi`}>Urls</p>
                </div>
              </li>
            </ul>
          </div>
        )}
      </>


    </nav>

  )
}