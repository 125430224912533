import React, { useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { makeAuthenticatedRequest } from "../../utils";
import { Pagination } from "../Pagination";
import { getCreatedLinks } from "./urlsUtils";
import { ENDPOINT_SHORT_URLS_ADD, ENDPOINT_SHORT_URLS } from "../../routes";
import { toast } from "react-toastify";

const ALL_TAB = "all";

function Urls() {
  const [links, setLinks] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(16);
  const [search, setSearch] = useState("");
  const [processing, setProcessing] = useState(false);
  const [currentTab, setCurrentTab] = useState(ALL_TAB);
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = (page, perPage, s = "") => {
    setProcessing(true);
    getCreatedLinks(page, perPage, s)
      .then(({ results, count, page, per_page }) => {
        setLinks(results);
        setTotalItems(count);
        setPage(page);
        setPerPage(per_page);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  useEffect(() => {
    fetchData(page, perPage);
  }, []);

  const handleSubmitSearch = (e) => {
    e.preventDefault();

    setPage(1);
    fetchData(page, perPage, search);
  };

  const pageChanged = (pageNumber) => {
    setPage(pageNumber);
    fetchData(pageNumber, perPage);
  };

  return (
    <>
      {processing && (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img
            src="/cdn/images/loading.gif"
            className="mr-3 cursor-pointer  h-24"
            alt="Sparepap Logo"
          />
          <h2 class="text-center text-white text-xl font-semibold">
            Processing...
          </h2>
        </div>
      )}
      <div className="flex flex-col">
        <div className="flex flex-col ">
          <div className="flex flex-col gap-4">
            <div>
              <div className="flex flex-row rounded  w-fit float-right gap-2">
                <Link
                  to={ENDPOINT_SHORT_URLS_ADD}
                  className="outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="text-white w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                  Create Short Link
                </Link>
              </div>
            </div>
          </div>

          <div className="mt-2 mb-2">
            <form
              className="flex items-start md:items-center font-satoshi"
              method="get"
              onSubmit={(e) => {
                handleSubmitSearch(e);
              }}
            >
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>
              <div className="relative w-full">
                <div
                  onClick={(e) => {
                    handleSubmitSearch(e);
                  }}
                  className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  required
                  value={search}
                  type="text"
                  name="s"
                  id="simple-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    "
                  placeholder="Search link"
                />
              </div>
            </form>
          </div>

          <div className="flex flex-col ">
            {!processing && links ? (
              <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                <table class="table-auto w-full">
                  <thead className="pl-1 md:pl-2 table-auto rounded-lg">
                    <tr className="pl-1 md:pl-2 rounded-lg">
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        <span>
                          <span className="inline pr-2">Short Link</span>
                        </span>
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-sm tracking-wide text-left text-gray-400">
                        <span>
                          <span className="inline pr-2">Full Link</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {links.map((link) => (
                      <tr
                        key={link?.id}
                        className="p-2 border border-b-gray-200 relative"
                      >
                        <Link to={`${ENDPOINT_SHORT_URLS}/${link?.id}`}>
                          <td className="p-4 text-xs md:text-sm text-left font-satoshi hover:underline">
                            <div className=" text-[10px] md:text-[14px] ">
                              {link?.short_link}
                            </div>
                          </td>
                        </Link>
                        <td className="p-4 text-xs md:text-sm text-left font-satoshi">
                          <div className=" text-[10px] md:text-[14px] ">
                            {link?.full_link}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {links.length > 0 && (
                  <Pagination
                    pageChanged={pageChanged}
                    totalItems={totalItems}
                    itemsPerPage={perPage}
                    currentPage={page}
                  />
                )}
              </div>
            ) : (
              <>
                {!processing && (
                  <p className="font-satoshi text-[18px] py-6">
                    No Urls to display
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Urls;
