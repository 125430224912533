import React, { useState, useEffect } from 'react'
import ReactLoading from 'react-loading';
import Avatar from 'react-avatar';
import Moment from 'react-moment';
import Datepicker from "react-tailwindcss-datepicker";
import dayjs from "dayjs";
import { toast } from 'react-toastify'
import { axiosCustom } from '../utils';
import { getName } from '../components/customers/utils';
import { useParams, useNavigate } from 'react-router-dom';


const STAFF_ORDER_STATS_DETAILS_URL = `/oms/staff-order-stats/`;

function StaffOrderStatsDetails() {
    const navigate = useNavigate();
    const { staffID } = useParams()
    const [data, setData] = useState()
    const [processing, setProcessing] = useState(false)
    const [dateRange, setDateRange] = useState({
        startDate: dayjs(new Date()).format("YYYY-MM-DD"),
        endDate: dayjs(new Date()).format("YYYY-MM-DD")
    });
    
    const handleDateRangeChange = (newValue) => {
        setDateRange(newValue);
    }

    const fetchData = async () => {
        setProcessing(true)
        try {
            const params = {}

            if (dateRange?.startDate && dateRange?.startDate){
                params.start_date = dateRange.startDate;
                params.end_date = dateRange.endDate
            }

            const searchParams = new URLSearchParams(params).toString();
            const response = await axiosCustom.get(`${STAFF_ORDER_STATS_DETAILS_URL}${staffID}?${searchParams}`);
            setData(response?.data)
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [dateRange])

    return (
        <>
            <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll  font-satoshi hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
                <div class="flex items-center justify-between relative">
                    <div className='none mr-2 flex flex-row justify-between mb-2'>
                        <button onClick={() => navigate(-1)} className='bg-gray-200 border rounded-md px-2 py-1 gap-1 border-gray-200 text-center font-satoshi text-sm flex flex-row'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="ic_back" transform="translate(-103 -49)">
                                    <rect id="Rectangle_561" data-name="Rectangle 561" width="20" height="20" transform="translate(103 49)" fill="none" opacity="0" />
                                    <g id="Group_1699" data-name="Group 1699" transform="translate(108 54.625)">
                                        <path id="Path_1149" data-name="Path 1149" d="M-1037.5,2208l-2.5,2.5,2.5,2.5" transform="translate(1040 -2208)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                        <path id="Path_1150" data-name="Path 1150" d="M-1035,2217.625h2.188a2.813,2.813,0,0,0,2.813-2.812h0a2.813,2.813,0,0,0-2.812-2.812H-1040" transform="translate(1040 -2209.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" />
                                    </g>
                                </g>
                            </svg>
                            Back
                        </button>
                    </div>
                </div>

                <div className='w-full mt-12 mb-10'>
                    <div
                        className='w-60 float-right'>
                        <Datepicker
                            value={dateRange}
                            onChange={handleDateRangeChange}
                            showShortcuts={true}
                        />
                    </div>
                </div>

                <div className="w-full">
                    <div className='none mr-2 flex flex-row justify-start items-end mb-2'>
                        <h1 className='font-bold text-lg'></h1>
                    </div>
                    <div className="w-full  bg-white rounded-lg border-gray-300">
                        <div className="p-3 mt-4 mb-4 bg-white border border-slate-200 shadow-sm  sm:rounded-lg">
                            <div class="flex items-center justify-between border-b rounded-t ">
                                <p className='mb-2 font-semibold text-sm'>Staff details</p>
                            </div>
                            {
                                processing ?
                                    <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                                    :
                                    <>
                                        <div className="mt-2 float-right flex flex-col">
                                            {
                                                data?.thumbnail ?
                                                    <img className='w-20 h-20 bg-gray-400 rounded-full object-cover' src={data?.thumbnail} alt={"Profile photo for " + getName(data)} />
                                                    :
                                                    <Avatar name={getName(data)} size="80" className="mr-2 rounded-full shadow" />
                                            }
                                        </div>
                                        <div className="py-2 flex flex-col gap-3">
                                            <div className='flex flex-col gap-2 md:flex-row md:gap-20 items-start md:items-center'>
                                                <p className='text-black text-left text-[14px] w-24'>Name</p>
                                                <p className='text-black font-bold text-sm text-left capitalize'>{getName(data)}</p>
                                            </div>
                                            <div className='flex flex-col gap-2 md:flex-row md:gap-20 items-start md:items-center'>
                                                <p className='text-black text-left text-[14px] w-24'>Email</p>
                                                <small className='text-gray-400 text-sm text-left'>{data?.email ? data.email : "-"}</small>
                                            </div>
                                            <div className='flex flex-col gap-2 md:flex-row md:gap-20 items-start md:items-center'>
                                                <p className='text-black text-left text-[14px] w-24'>Phone Number</p>
                                                <small className='text-gray-400 text-sm text-left hover:underline'>{data?.phone_number ? <a href={`tel:+${data?.phone_number}`}>+{data?.phone_number}</a> : "-"}</small>
                                            </div>
                                            <div className='flex flex-col gap-2 md:flex-row md:gap-20 items-start md:items-center'>
                                                <p className='text-black text-left text-[14px] w-24'>Date Joined</p>
                                                <small className='text-gray-400 text-sm text-left'><Moment format="MMM, DD, YYYY" className=''>{data?.date_created}</Moment></small>
                                            </div>
                                        </div>
                                    </>
                            }
                        </div>

                        {data?.stats &&
                            <div className="mt-4 mb-4">
                                <div className="flex flex-col md:flex-row gap-4">
                                    <div className="mt-1 mb-1 w-auto md:w-full  border border-gray-200 rounded-md p-2">
                                        <p className="block text-[14px] mb-4 font-satoshi text-gray-700">Total Number of Sales</p>
                                        <div className="">
                                            <p className="block text-[20px] text-[#808491] font-semibold font-satoshi ">
                                                <span className='text-[#19223B] mr-1 font-semibold text-sm md:text-[32px]'>{data?.stats?.number_of_sales}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-1 mb-1 w-auto md:w-full  border border-gray-200 rounded-md p-2">
                                        <p className="block text-[14px] mb-4 font-satoshi text-gray-700">Total Sales</p>
                                        <div className="">
                                            <p className="block text-xs md:text-[20px] text-[#808491] font-semibold font-satoshi ">
                                                <span className='mr-1'>Ksh</span>
                                                <span className='text-[#19223B] mr-1 font-semibold text-sm md:text-[32px]'>{data?.stats?.sum_of_sales}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div >

        </>
    )
}
export default StaffOrderStatsDetails
