import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { ENDPOINT_SERVICE_CENTER } from '../../routes'
import { makeAuthenticatedRequest } from '../../utils'
import GoogleMapInput from '../../components/GoogleMapInput'
import ModalLayout from '../../components/shared/ModalLayout'
import { Pagination } from '../Pagination'
import { toast } from 'react-toastify'

function ServiceCenters() {
    const [data, setData] = useState(null)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [perPage, setPerPage] = useState(16)
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)

    const [addServiceCenter, setAddServiceCenter] = useState(false)

    const [name, setName] = useState('')
    const [staffName, setStaffName] = useState('')
    const [staffEmail, setStaffEmail] = useState('')
    const [address, setAddress] = useState('')
    const [totalItems, setTotalItems] = useState(0)
    const [open, setOPen] = useState([]);
    const [deleteEmployee, setDeleteEmployee] = useState('Delete')

    const [editEmployee, setEditEmployee] = useState(false)
    const [editData, setEditData] = useState(null)

    const handleOpenEditCenter = (data) => {
        setName(data?.name)
        setAddress(data?.location)
        setEditEmployee(true)
        setEditData(data)
      }
    
      const handleCloseEditCenter = () => {
        setName("")
        setAddress("")
        setEditEmployee(false)
        setEditData(null)
      }

    const handleCloseCreateServiceModal = () => {
        setAddServiceCenter(false)
    }
    // const handleOpenAddServiceCenter = () => {
    //     setAddServiceCenter(true)
    //     console.log("Opening the add service center modal")
    //     console.log(addServiceCenter)
    // }

    const fetchData = (page) => {
        setProcessing(true)
        const params = {
            "s": search,
            "page": page,
            "per_page": perPage,
        }
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/service_centers?${searchParams}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setData(data)
                setTotalItems(data?.count)
                setProcessing(false)
            })
            .catch((err) => {
                setProcessing(false)

                console.error('err', err)
            })
    }

    const postData = (e) => {
        e.preventDefault()
        setProcessing(true)
        const params = {
            staff_email : staffEmail,
            staff_name : staffName,
            location : address,
            name: name
        }
        makeAuthenticatedRequest(`/oms/service_centers/`, "POST", JSON.stringify(params))
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                fetchData(1)
                setProcessing(false)
                setAddress('')
                setStaffEmail('')
                setStaffName('')
                setName('')
                setAddServiceCenter(false)
            })
            .catch((err) => {
                setProcessing(false)
            })
    }

    const submitSearch = () => {
        setPage(1)
        fetchData()
    }

    useEffect(() => {
        fetchData(1)
    }, [])

    const handleOpenAddServiceCenter = () => {
        setAddServiceCenter(true)
    }

    const pageChanged = (pageNumber) => {
        setPage(pageNumber)
        fetchData(pageNumber)
      
      }


      const handleDeleteCenter = async(id) => {
        setLoading(true)
        const payload = {
            deleted:true
        }
    
        try {
          const res = await  makeAuthenticatedRequest(`/oms/service_centers/${id}/`, "POST", JSON.stringify(payload))
          if (!res.ok) {
            setLoading(false)
            throw new Error('Service center delete failed.')
          }
          const data = await res.json();
          fetchData(1)
          setLoading(false)
          toast.success('Service center deleted successfully')
        } catch (error) {
          setLoading(false)
          toast.error('Service center delete failed.')
        }
        
      }
    
      const handleUpdateCenter = async(e) => {
        e.preventDefault()
        setLoading(true)
        const payload = {
          name,
          location : address,
        }
        try {
          const res = await makeAuthenticatedRequest(`/oms/service_centers/${editData?.id}/`, "POST", JSON.stringify(payload))
          if (!res.ok) {
            setLoading(false)
            handleCloseEditCenter()
            throw new Error('Service center update failed.')
          }
          const data = await res.json();
          fetchData(1)
          setLoading(false)
          handleCloseEditCenter()
          toast.success('Service center updated successfully')
        } catch (error) {
          setLoading(false)
          toast.error('Service center update failed.')
        }
      }

    const handleSetDeleteEmployee = () => {
        setDeleteEmployee('Confirm')
        setTimeout(() => {
          setDeleteEmployee('Delete')
        }, 2000);
      }
    
      const toggleOpen = employeeId => {
        const openState = open.slice()
        const index = openState.indexOf(employeeId)
    
        if (index >= 0) {
          openState.splice(index, 1)
          setOPen(openState)
        } else {
          openState.push(employeeId)
          setOPen(openState)
        }
      }
      const editRef = useRef()

      useEffect(() => {
        let actionhandler = e => {
          if (editRef.current && !editRef.current.contains(e.target)) {
    
            setOPen([])
    
            // console.log(editRef.current)
          }
        }
        document.addEventListener('mousedown', actionhandler);
    
        return () => {
          document.removeEventListener('mousedown', actionhandler);
        }
    
    
      })


    return (
        <>
            {
                processing &&
                <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
                    <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
                    <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
                </div>
            }

{
                editEmployee &&
                <>
                    <div className='min-h-h-screen flex justify-center items-center overflow-x-hidden overflow-y-auto py-10 fixed inset-0 z-[100] outline-none focus:outline-none'>
                        {/* <div className='relative p-2 w-full max-w-lg h-full md:h-auto'> */}
                        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-[90%] md:w-auto md:min-w-[50%] bg-white outline-none focus:outline-none'>
                            <div className='flex items-start justify-end p-2 rounded-t '>
                                <button
                                    className='bg-transparent border-0 text-black float-right p-2'
                                    onClick={handleCloseEditCenter}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </div>
                            <div className="w-full p-6">
                                <div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
                                    <p className='text-[20px] font-semibold'>Edit service center</p>
                                    <form action="" onSubmit={handleUpdateCenter} className='flex flex-col gap-2 w-full'>

                                        <div className="w-full flex flex-col gap-1">
                                            <label htmlFor="" className='text-[16px]'>Name</label>
                                            <input
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text" className='outline-none px-2 py-2 rounded-md border' />
                                        </div>
                                        <div className="w-full flex flex-col gap-1">
                                            <label htmlFor="" className='text-[16px]'>Location</label>
                                            <input
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                type="text" className='outline-none px-2 py-2 rounded-md border' />
                                        </div>

                                        {false &&
                                            <>

                                                <div className="flex flex-col w-full gap-1">
                                                    <label htmlFor="" className='text-[16px]'>Location</label>

                                                    <GoogleMapInput classCss='w-full p-3 rounded border border-solid border-gray-200 outline-none' />
                                                </div>
                                                <div className="flex flex-col w-full gap-1">
                                                    <label htmlFor="" className='text-[16px]'>Logo</label>
                                                    <div className='w-full'>
                                                        <input type="file" name="file"
                                                            id="files"
                                                            multiple
                                                            // ref={refFileInput}
                                                            accept="image/*"
                                                            // onChange={onFileChange}
                                                            className='hidden' />
                                                        <label htmlFor="files" className='flex border items-center gap-2 px-3 my-2 h-20 w-20 rounded-md font-semibold bg-gray-300 justify-center'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                                                            </svg>
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="flex flex-row-reverse gap-2 w-full">
                                            <button
                                            type='submit'
                                                className='px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md'>Continue</button>
                                            <button
                                                onClick={handleCloseCreateServiceModal}
                                                className='px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100'>Cancel</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-70  fixed inset-0 z-50' />
                </>
            }
            {
                addServiceCenter &&
                <>
                    <div className='min-h-h-screen flex justify-center items-center overflow-x-hidden overflow-y-auto py-10 fixed inset-0 z-[100] outline-none focus:outline-none'>
                        {/* <div className='relative p-2 w-full max-w-lg h-full md:h-auto'> */}
                        <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-[90%] md:w-auto md:min-w-[50%] bg-white outline-none focus:outline-none'>
                            <div className='flex items-start justify-end p-2 rounded-t '>
                                <button
                                    className='bg-transparent border-0 text-black float-right p-2'
                                    onClick={handleCloseCreateServiceModal}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </div>
                            <div className="w-full p-6">
                                <div className="w-full font-satoshi text-[16px] flex flex-col gap-3">
                                    <p className='text-[20px] font-semibold'>Add service center</p>
                                    <form action="" onSubmit={postData} className='flex flex-col gap-2 w-full'>

                                        <div className="w-full flex flex-col gap-1">
                                            <label htmlFor="" className='text-[16px]'>Name</label>
                                            <input
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                type="text" className='outline-none px-2 py-2 rounded-md border' />
                                        </div>
                                        <div className="w-full flex flex-col gap-1">
                                            <label htmlFor="" className='text-[16px]'>Location</label>
                                            <input
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                                type="text" className='outline-none px-2 py-2 rounded-md border' />
                                        </div>
                                        <div className="w-full flex flex-col gap-1">
                                            <label htmlFor="" className='text-[16px]'>Staff Email</label>
                                            <input
                                                value={staffEmail}
                                                onChange={(e) => setStaffEmail(e.target.value)}
                                                type="text" className='outline-none px-2 py-2 rounded-md border' />
                                        </div>

                                        <div className="w-full flex flex-col gap-1">
                                            <label htmlFor="" className='text-[16px]'>Staff Name</label>
                                            <input
                                                value={staffName}
                                                onChange={(e) => setStaffName(e.target.value)}
                                                type="text" className='outline-none px-2 py-2 rounded-md border' />
                                        </div>

                                        {false &&
                                            <>

                                                <div className="flex flex-col w-full gap-1">
                                                    <label htmlFor="" className='text-[16px]'>Location</label>

                                                    <GoogleMapInput classCss='w-full p-3 rounded border border-solid border-gray-200 outline-none' />
                                                </div>
                                                <div className="flex flex-col w-full gap-1">
                                                    <label htmlFor="" className='text-[16px]'>Logo</label>
                                                    <div className='w-full'>
                                                        <input type="file" name="file"
                                                            id="files"
                                                            multiple
                                                            // ref={refFileInput}
                                                            accept="image/*"
                                                            // onChange={onFileChange}
                                                            className='hidden' />
                                                        <label htmlFor="files" className='flex border items-center gap-2 px-3 my-2 h-20 w-20 rounded-md font-semibold bg-gray-300 justify-center'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6" />
                                                            </svg>
                                                        </label>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="flex flex-row-reverse gap-2 w-full">
                                            <button
                                            type='submit'
                                                className='px-4 py-1 text-center text-[14px] bg-[#19223B] text-white rounded-md'>Continue</button>
                                            <button
                                                onClick={handleCloseCreateServiceModal}
                                                className='px-4 py-1 text-center text-[14px] border rounded-md bg-gray-100'>Cancel</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                    <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-70  fixed inset-0 z-50' />
                </>
            }


            <div className="flex flex-col">
                <div className="flex flex-col ">
                    <div className='mt-2 mb-2'>
                        <form className="flex items-start md:items-center font-satoshi" method="get">
                            <label htmlFor="simple-search" className="sr-only">Search</label>
                            <div className="relative w-full">
                                <div
                                    onClick={submitSearch}
                                    className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md">
                                    <svg aria-hidden="true" className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd">
                                        </path>
                                    </svg>
                                </div>
                                <input
                                    onChange={e => setSearch(e.target.value)}
                                    required
                                    value={search}
                                    type="text" name="s"
                                    id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    " placeholder="Search for a service center" />
                            </div>
                        </form>
                    </div>
                    <div className="flex flex-row-reverse">
                        <button
                            onClick={handleOpenAddServiceCenter}
                            className='outline-none rounded-md flex flex-row gap-2 font-satoshi text-white bg-black px-4 py-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-white w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            Add service center</button>
                    </div>

                    <div className="flex flex-col ">
                        {
                            !processing && data ? (
                                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border border-gray-200 mt-4">
                                    <table class="table-auto w-full  ">
                                        <thead className='pl-2'>
                                            <tr className='pl-1 border-b border-gray-200'>
                                                <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                                                <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Date Added</th>
                                                <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Location</th>
                                                <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.results.map((item, index) => (

                                                    <tr key={index} className='p-2 border-b border-b-gray-200 relative'>
                                                        <td
                                                            className='p-2 text-xs md:text-[16px] text-left font-satoshi inline-block cursor-pointer'>
                                                            <Link className='hover:underline' to={`${ENDPOINT_SERVICE_CENTER}/${item?.id}`}>
                                                                <p>{item.name}</p>
                                                            </Link>
                                                        </td>
                                                        <td
                                                            className='p-4 text-xs md:text-[16px] relative'>
                                                            <div className=" text-[10px] md:text-[14px] ">
                                                                <Moment format="MMM, DD LT">
                                                                    {item.date_created}
                                                                </Moment>
                                                            </div>
                                                        </td>
                                                        <td className='p-2 text-xs md:text-[16px] text-left font-satoshi'>
                                                            <div className=" text-[10px] md:text-[14px] ">
                                                                <p>{item.location}</p>
                                                            </div>
                                                        </td>
                                                        <td className='p-2 text-xs md:text-[16px] text-left font-satoshi relative'>
                                

                                <svg
                              onClick={() => toggleOpen(item?.id)}
                               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="cursor-pointer w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                              </svg>
  
                                {open.includes(item?.id) && (
                                      <>
                                      <div ref={editRef} className={`border border-gray-200 w-40 -top-10 mt-4 bg-white z-50 absolute right-4 rounded-md shadow-md pr-4 py-2 pl-2 `}>
                                      
                                          <div
                                                onClick={() =>handleOpenEditCenter(item)}
                                              className='flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer'>
                                              <div className='flex flex-row gap-2'>
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
                                                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
  
                                              </svg>
                                              <p className='font-satoshi text-[12px]'>Edit</p>
                                              </div>
                                          </div>
  
                                          <div
                                          onClick={(e) => {
                                              handleSetDeleteEmployee()
                                              handleDeleteCenter(item?.id)
                                          }}
                                          className='flex flex-col py-1 px-1 hover:bg-[#FDF6F7] rounded-md cursor-pointer'>
                                          <div className='flex flex-row gap-2'>
                                              {deleteEmployee === 'Delete' ?
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                                              </svg>
                                              :
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-[#BF3054]">
                                                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                              </svg>
                                              }
                                              <p className='font-satoshi text-sm text-[#BF3054]'>{deleteEmployee}</p>
                                          </div>
                                          </div>
                                      </div>
                                      </>
                                  )}
  
                                  
                                </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    {data.results.length > 0 &&
                      <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={page} />
                    }
                                </div>
                            ) :
                                <>
                                    {!processing &&
                                        <p className='font-satoshi text-[18px] py-6'>No service centers to display</p>
                                    }
                                </>
                        }
                    </div>

                </div>
            </div>
        </>
    )
}
export default ServiceCenters