import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import slugify from "slugify";
import { toast } from "react-toastify";
import { makeAuthenticatedRequest } from "../utils";
import { CUSTOMER_TYPES } from "../constants";

export const OrderMeta = ({ orderData }) => {
  const [bdes, setBdes] = useState([]);
  const [isBdeLoading, setBdeLoading] = useState(false);
  const [orderMeta, setOrderMeta] = useState(null);

  const getOrderMeta = async (orderID) => {
    try {
      const response = await makeAuthenticatedRequest(
        `/oms/order/meta/?order_id=${orderID}`
      );

      const { results } = await response.json();
      let processedResults = [];

      const notDates = results?.filter((result) => {
        return !result?.slug?.startsWith("date");
      });

      notDates?.map((notDate) => {
        return processedResults.push(notDate);
      });

      const dates = results.filter((result) => {
        return result?.slug?.startsWith("date");
      });

      let newDates = dates.map((date) => {
        const values = date?.values;

        const dateValues = values.map((value) => {
          const date = new Date(value);
          return date.toLocaleString();
        });

        return {
          ...date,
          values: dateValues,
        };
      });

      newDates.map((date) => {
        return processedResults.push(date);
      });

      setOrderMeta(processedResults);
    } catch (error) {
      toast.error("An error occurred while getting order meta.");
    }
  };

  const createOrderMeta = async (params) => {
    try {
      return makeAuthenticatedRequest(
        `/oms/order/meta/`,
        "POST",
        JSON.stringify(params)
      );
    } catch (error) {
      toast.error("An error occurred while creating order meta.");
    }
  };

  const handleBdeChange = async (value) => {
    const params = {
      name: "BDE",
      order: orderData.id,
      slug: "bde",
      values: [value],
    };

    await createOrderMeta(params);
    await getOrderMeta(orderData?.id);
  };

  const handleCustomerTypeChange = async (value) => {
    const params = {
      name: "Customer Type",
      order: orderData.id,
      slug: "customer-type",
      values: [value],
    };

    await createOrderMeta(params);
    await getOrderMeta(orderData?.id);
  };

  useEffect(() => {
    getOrderMeta(orderData?.id);
  }, [orderData]);

  // useEffect(() => {
  //   getBdes();
  // }, []);

  const [editBdeAttribution, setEditBdeAttribution] = useState(false);

  const toggleEditBdeAttribution = () => {
    setEditBdeAttribution(!editBdeAttribution);
  };

  const [activePodImage, setActivePodImage] = useState("");

  const orderMetaWithoutImages = useMemo(() => {
    return orderMeta?.filter((meta) => {
      return meta?.slug !== "delivery-proof";
    });
  }, [orderMeta]);

  const deliveryProof = useMemo(() => {
    return orderMeta?.filter((meta) => {
      return meta?.slug === "delivery-proof";
    });
  }, [orderMeta]);

  useEffect(() => {
    if (deliveryProof?.length > 0) {
      setActivePodImage(deliveryProof[0]?.values[0]);
    }
  }, [orderMeta]);

  const handleActivePodImageChange = (i) => {
    setActivePodImage(deliveryProof[0]?.values[i]);
  };

  const [showPodImages, setShowPodImages] = useState(false);

  const toggleShowPodImages = () => {
    setShowPodImages(!showPodImages);
  };

  const NAME_OPTIONS = [
    {
      label: "Customer Type",
      value: "customer-type",
      nestedOptions: CUSTOMER_TYPES,
    },
  ];

  const [editMeta, setEditMeta] = useState(false);
  const toggleEditMeta = () => {
    setEditMeta(!editMeta);
  };

  const [nameOptions, setNameOptions] = useState(NAME_OPTIONS);
  const [attributeOptions, setAttributeOptions] = useState([]);

  const [selectedNameOption, setSelectedNameOption] = useState(null);

  const [nameValue, setNameValue] = useState(null);
  const [attributeValue, setAttributeValue] = useState(null);

  const getBdes = async () => {
    try {
      setBdeLoading(true);
      const response = await makeAuthenticatedRequest(
        "/oms/staff/?page=1&per_page=60"
      );

      const { results } = await response.json();
      let bdes = [];
      results.map((bde) => {
        bdes.push({
          value: bde?.first_name
            ? `${bde?.first_name} ${bde?.last_name}`
            : bde?.email,
          label: bde?.first_name
            ? `${bde?.first_name} ${bde?.last_name}`
            : bde?.email,
        });
      });

      setBdes(bdes);
      const bdeOptions = {
        label: "BDE Attribution",
        value: "bde-attribution",
        nestedOptions: bdes,
      };

      // dont add duplicate key options
      if (nameOptions?.some((option) => option?.value === bdeOptions?.value)) {
        return;
      }
      setNameOptions([...nameOptions, bdeOptions]);
      setBdeLoading(false);
      return;
    } catch (error) {
      toast.error("An error occurred while fetching Bdes.");
      setBdeLoading(false);
    }
  };

  // Names
  const handleNameOptionCreate = (e) => {
    const newNameOption = {
      label: e,
      value: slugify(e, { lower: true }),
    };

    setNameOptions([...nameOptions, newNameOption]);
    setNameValue(newNameOption);
    setSelectedNameOption(newNameOption);
    setAttributeOptions([]);
  };

  const handleNameOptionChange = (e) => {
    const { value, label, nestedOptions } = e;
    setNameValue(e);
    setSelectedNameOption({ label, value });
    setAttributeOptions(nestedOptions);
  };

  // Attributes
  const handleAttributeOptionCreate = async (e) => {
    const newValueOption = {
      label: e,
      value: e,
    };
    setAttributeOptions([newValueOption]);
    setAttributeValue(newValueOption);

    const params = {
      order: orderData?.id,
      name: selectedNameOption?.label,
      slug: selectedNameOption?.value,
      values: [e],
    };

    await createOrderMeta(params);
    await getOrderMeta(orderData?.id);
    return;
  };

  const handleAttributeOptionsChange = async (e) => {
    setAttributeValue(e);

    const params = {
      order: orderData?.id,
      name: selectedNameOption?.label,
      slug: selectedNameOption?.value,
      values: [e.value],
    };

    await createOrderMeta(params);
    await getOrderMeta(orderData?.id);
    return;
  };

  return (
    <div className="border border-[#E5E6E9] rounded-lg py-2 shadow-sm">
      <div className="flex flex-row justify-between">
        <div>
          <p className="font-satoshi text-[#030A1A] px-4 font-semibold text-[18px]">
            Other Details
          </p>
        </div>

        <div className="py-2 pr-4 hover:cursor-pointer ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            // className="size-6"
            className="h-6 w-6"
            onClick={toggleEditMeta}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>
        </div>
      </div>

      <div className="border-b w-full border-[#E5E6E9]"></div>

      {editMeta && (
        <div className="flex flex-col p-2 w-full ">
        <div className="h-full pt-2 flex flex-col md:flex-row gap-20 mt-2 flex-[3] p-1">
          <div className="w-full flex flex-row gap-2">
            <div className="flex flex-col gap-1 w-full mt-2 mb-2">
              <p className="text-[#818490] font-satoshi text-md font-semibold">
                Name
              </p>
              <CreatableSelect
                value={nameValue}
                options={nameOptions}
                onCreateOption={(e) => handleNameOptionCreate(e)}
                onChange={(e) => handleNameOptionChange(e)}
                onMenuOpen={() => getBdes()}
              />
            </div>

            <div className="flex flex-col gap-1 w-full mt-2 mb-2">
              <p className="text-[#818490] font-satoshi text-md font-semibold">
                Attribute
              </p>
              <CreatableSelect
                value={attributeValue}
                options={attributeOptions}
                onCreateOption={(e) => handleAttributeOptionCreate(e)}
                onChange={(e) => handleAttributeOptionsChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
      )}

      {orderMetaWithoutImages?.length > 0 && (
        <div className="p-2 w-full  mt-4 rounded-lg  ">
          <div className="w-full overflow-x-scroll hide-scrollbar">
            <table class="table-auto w-full">
              <tbody>
                {orderMetaWithoutImages?.map((meta) => (
                  <tr className="p-2 border-b border-b-gray-200 relative">
                    <td className="p-4 text-xs md:text-[12px] text-left font-satoshi text-[#808491]">
                      <p className="font-satoshi text-[12px] uppercase">
                        {meta?.name}
                      </p>
                    </td>
                    <td className="p-4 text-xs md:text-[12px] text-left font-satoshi">
                      {meta?.values?.join(" ,")}
                    </td>
                  </tr>
                ))}
                {deliveryProof?.map((meta) => (
                  <tr className="p-2 border-b border-b-gray-200 relative">
                    <td className="p-4 text-xs md:text-[12px] text-left font-satoshi text-[#808491]">
                      <p className="font-satoshi text-[12px] uppercase">
                        {meta?.name}
                      </p>
                    </td>
                    <td className="p-4 text-xs md:text-[12px] text-left font-satoshi">
                      {meta?.values?.map((value, i) => (
                        <div className="my-2">
                          <a
                            className="text-blue-500 hover:underline mr-4"
                            target="_blank"
                            href={value}
                            rel="noopener noreferrer"
                          >
                            Image {i + 1}
                          </a>
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* <div className="p-4 mt-4">
        <div className="mb-2">
          <div
            className="flex flex-row justify-between hover:cursor-pointer"
            onClick={() => {
              toggleShowPodImages();
            }}
          >
            <h3 className="font-satoshi text-[#999DA8] px-2 text-[14px] font-semibold font-satoshi text-xs md:text-sm">
              Proof of Delivery
            </h3>

            {showPodImages ? (
              <div className="pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m19.5 8.25-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            ) : (
              <div className="pr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 19.5 8.25 12l7.5-7.5"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>

        <div className="px-2">
          <div className="flex flex-col gap-4 w-full">
            <div className="flex gap-4 md:flex-row flex-col">
              {showPodImages && (
                <div className="flex flex-col pb-5 gap-2 w-full flex-[1]">
                  {deliveryProof.length ? (
                    <a href={activePodImage} target="_blank" rel="noopener noreferrer">
                      <img
                        className="h-96 w-full object-contain rounded border"
                        src={activePodImage}
                        alt=""
                      />
                    </a>
                  ) : (
                    <div className="flex flex-col text-sm text-gray-600 items-center bg-[#F7F7F8] px-6 pt-4 md:pt-16 pb-4 md:pb-16 w-full rounded-lg border border-[#E5E6E9] cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
                        />
                      </svg>

                      <span className="font-satoshi text-[16px]">
                        No Proof of delivery images.
                      </span>
                    </div>
                  )}
                  <div className="flex gap-2 overflow-x-scroll hide-scrollbar">
                    {deliveryProof && (
                      <>
                        {deliveryProof[0]?.values?.map((image, i) => (
                          <div key={i} className="relative max-w-[6rem]">
                            <img
                              // className="h-full w-24 object-contain rounded"
                              className={`h-full w-24 object-contain rounded ${
                                activePodImage === image
                                  ? "border border-red-500"
                                  : ""
                              }`}
                              src={image}
                              alt=""
                              onClick={() => {
                                handleActivePodImageChange(i);
                              }}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
