import React from 'react'
import UrlsDetails from '../components/urls/UrlsDetails'

function UrlsDetailsPage() {
  return (
    <div>
      <UrlsDetails/>
    </div>
  )
}

export default UrlsDetailsPage
