import React, { useState, useEffect } from 'react'
import { formatNumber, makeAuthenticatedRequest } from '../utils';
import { Pagination } from './Pagination';
import { ENDPOINT_USERS } from '../routes';
import { Link, useSearchParams } from 'react-router-dom';
import Avatar from 'react-avatar';

function UsersMarketPlaceMerchants() {
  let currentUrlParams = new URLSearchParams(window.location.search);
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
  const [currentPage, setCurrentPage] = useState( currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)

  const [processing, setProcessing] = useState(true)
  const [perPage, setPerPage] = useState(16)
  const [totalItems, setTotalItems] = useState(0)
  const [search, setSearch] = useState('')
  const [data, setData] = useState(null)

  const fetchData = (page) => {
    const params = {
      "s": search,
      "page": page,
      "per_page": perPage,
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/merchants/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setData(data)
        // setCurrentPage(data.page)
        setPerPage(data.per_page)
        setTotalItems(data.count)
        setProcessing(false)
      });
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submitSearch()
    }
  }

  const submitSearch = () => {
    if (search) {
      fetchData(1)
    }
  }

  useEffect(() => {
    fetchData(currentPage);
  }, [Number(currentUrlParams.get('page'))])

  const pageChanged = (pageNumber) => {
    currentUrlParams.set('page', pageNumber)
    currentUrlParams.set('tab', 'users')
    currentUrlParams.set('usertype', 'merchants')
    setCurrentPage(Number(currentUrlParams.get('page')))
    setUrlParams(currentUrlParams)

    fetchData(Number(currentUrlParams.get('page')))
  }


  return (
    <>
      {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }
      <div className="flex flex-col py-2">
        <div className='mt-2 mb-2'>
          <form className="flex items-start md:items-center font-satoshi" method="get">
            <label htmlFor="simple-search" className="sr-only">Search</label>
            <div className="relative w-full">
              <div
                onClick={submitSearch}
                className="flex absolute inset-y-0 right-0 m-1 items-center cursor-pointer px-1 py-1 text-sm font-medium bg-[#E5E6E9] rounded-md">
                <svg aria-hidden="true" className="w-5 h-5 text-[#333B51] bg-[#E5E6E9] rounded-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd">
                  </path>
                </svg>
              </div>
              <input
                onKeyDown={handleKeyDown}
                onChange={e => setSearch(e.target.value)}
                required
                value={search}
                type="text" name="s"
                id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm outline-none rounded-md block w-full pl-4 px-2.5  py-2    " placeholder="Search a merchant" />
            </div>
          </form>
        </div>
        <div className="pt-2 w-full rounded-lg overflow-x-scroll hide-scrollbar mt-4">
          <table class="table-auto w-full  ">
            <thead className='pl-2'>
              <tr className='pl-2  border-b border-b-gray-200'>
                <th className='pl-2 pb-1 font-satoshi text-sm tracking-wide text-left text-gray-400'>
                  {/* <input type="checkbox" /> */}
                </th>
                <th className='pl-2 pb-1 font-satoshi text-[12px] md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                <th className='pl-2 pb-1 font-satoshi text-[12px] md:text-[16px] tracking-wide text-left text-gray-400'>Inventory</th>
                <th className='pl-2 pb-1 font-satoshi text-[12px] md:text-[16px] tracking-wide text-left text-gray-400'>Parts sold</th>
                <th className='pl-2 pb-1 font-satoshi text-[12px] md:text-[16px] tracking-wide text-left text-gray-400'>Orders</th>
                {/* <th className='pl-2 pb-1 font-satoshi text-[12px] md:text-[16px] tracking-wide text-left text-gray-400'>Tier</th> */}
                {/* <th className='pl-2 pb-1 font-satoshi text-[12px] md:text-[16px] tracking-wide text-left text-gray-400'>Zone</th> */}
                <th className='pl-2 pb-1 font-satoshi text-[12px] md:text-[16px] tracking-wide text-left text-gray-400'>Contact</th>
              </tr>

            </thead>
            <tbody>
              {
                data?.results.map((user) => (
                  <tr className='p-2 border-b border-b-gray-200 relative'>
                    <td
                      className='p-4 text-xs md:text-sm cursor-pointer'>
                      {user.merchant_thumbnail ?
                        <img src={user.merchant_thumbnail} alt="" className='rounded-full w-6 h-6' />
                        :
                        <Avatar name={user.merchant_name} size="32" textSizeRatio={1.75} className="rounded-full shadow" />
                      }
                    </td>
                    <Link to={`${ENDPOINT_USERS}/merchant/${user.id}`}>

                      <td className='p-4 text-[16px]  cursor-pointer text-left font-satoshi capitalize'>{user.merchant_name}</td>
                    </Link>
                    <td className='p-4 text-[16px] text-left font-satoshi text-[#808491]'>{user.inventory_count > 0 ? formatNumber(user.inventory_count, 0, 0) : "-"}</td>
                    <td className='p-4 text-[16px] text-left font-satoshi text-[#808491]'>{user.parts_sold_count > 0 ? formatNumber(user.parts_sold_count, 0, 0) : "-"}</td>
                    <td className='p-4 text-[16px] text-left font-satoshi'>
                      {user.order_count > 0 ? formatNumber(user.order_count, 0, 0) : "-"}
                    </td>
                    {/* <td className='p-4 text-[16px] text-left font-satoshi'>
                  Tier 1
                </td>
                <td className='p-4 text-[16px] text-left font-satoshi'>
                  A
                </td> */}
                    <td className='p-4 text-[16px] flex flex-row gap-2'>
                      <a href="tel:+{user.phone_number}" className='flex-1 font-satoshi'>+{user.phone_number}</a>
                      {/* <div className="flex flex-row flex-1 justify-between bg-[#ECF0FD] text-[16px] px-4 py-2 rounded-md font-satoshi">
                    Active

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                  </svg>

                  </div> */}
                      {true && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="flex-1 w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                      </svg>
                      }
                    </td>
                  </tr>
                ))}
            </tbody>

          </table>
        </div>
        <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} />
        {/* table for all */}
      </div>
    </>
  )
}

export default UsersMarketPlaceMerchants