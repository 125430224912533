import React from 'react'

import AdvertAdd from '../components/cms/Advert/AdvertAdd'

function AdvertAddPage() {
  return (
    <div>
        <AdvertAdd/>
    </div>
  )
}

export default AdvertAddPage