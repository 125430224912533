import React, { useEffect, useState } from 'react'
import { ENDPOINT_BTOB, ENDPOINT_BTOB_ONBOARDING } from '../routes'
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { makeAuthenticatedRequest } from '../utils';
import DSFPagination from '../components/DSFPagination';
import { Pagination } from '../components/Pagination';
import { BsDot } from "react-icons/bs";
import RfsData from '../components/RfsData';
import Quotes from '../components/Quotes';
import LpoData from '../components/LpoData';
import GrnsData from '../components/GrnsData';
import InvoicesData from '../components/InvoicesData';
import { toast } from 'react-toastify';

const CLIENTS_TAB = "Clients"
const ORDERS_TAB = "Orders"
const SUPPORT_TICKETS_TAB = "Support tickets"
const ACTIVITY_TAB = "Activity"

const NEW_CLIENTS_TAB = 'New'
const ACTIVE_CLIENTS_TAB = 'Active'
const PENDING_APPROVAL_CLIENTS_TAB = 'Pending approval'
const SUSPENDED_CLIENTS_TAB = 'Suspended'
const ALL_CLIENTS_TAB = 'All'


const ACTIVE_ORDER_TAB = 'Active'
const RFQS_ORDER_TAB = 'RFQs'
const QUOTES_ORDER_TAB = 'Quotes'
const LPOS_ORDER_TAB = 'LPOs'
const GRN_ORDER_TAB = 'Goods received notes'
const INVOICE_ORDER_TAB = 'Invoices'
const COMPLETED_ORDER_TAB = 'Completed'





const BClients = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  let [urlParams, setUrlParams] = useSearchParams(currentUrlParams)
  let [urlParamsRfqs, setUrlParamsRfqs] = useSearchParams(currentUrlParams)


  const [currentTab, setCurrentTab] = useState(CLIENTS_TAB)
  const [currentClientsTab, setCurrentClientsTab] = useState(ACTIVE_CLIENTS_TAB)
  const [currentOrdersTab, setCurrentOrdersTab] = useState(ACTIVE_ORDER_TAB)

  const [currentPageRfq, setCurrentPageRfq] = useState(currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)

  const [rfqs, setRfqs] = useState([])
  const [perPageRfq, setPerPageRfq] = useState(20)
  const[pagesRfq, setPagesRfq] = useState(0)
  const [itemCountRfq, setItemCountRfq] = useState(0)
  const [totalItemsRfq, setTotalItemsRfq] = useState(0)
  const [searchRfq, setSearchRfq] = useState('')
  const [processingRfq, setProcessingRfq] = useState(false)

  const fetchRfqs = (page) => {
    setProcessing(true)
    const params = {
      "s": search,
      "page": page,
      "per_page": perPage,
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/rfqs/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('rfq data',data)
        setRfqs(data)
        setCurrentPageRfq(data?.page)
        setPerPageRfq(data?.per_page)
        setPagesRfq(data?.number_of_pages)
        setItemCountRfq(data?.count)
        setTotalItemsRfq(data?.count)
        setProcessingRfq(false)
      }).catch((error) => {
        setProcessingRfq(false)
        console.log(error)
  })
  }
  
  const pageRfqChanged = (pageNumber) => {
    // console.log('called')
    currentUrlParams.set('page', pageNumber)
    setCurrentPageRfq(Number(currentUrlParams.get('page')))
    setUrlParamsRfqs(currentUrlParams)    
    fetchRfqs(Number(currentUrlParams.get('page')))
  }
  // console.log('currentPage', currentPage)
  useEffect(() => {
    fetchClients(currentPage)
    fetchRfqs(currentPageRfq)
  }, [])


  const [currentPage, setCurrentPage] = useState(currentUrlParams.has('page') ? Number(currentUrlParams.get('page')) : 1)

  const [clients, setClients] = useState([])
  const [perPage, setPerPage] = useState(20)
  const[pages, setPages] = useState(0)
  const [itemCount, setItemCount] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [search, setSearch] = useState('')
  const [processing, setProcessing] = useState(false)

  const fetchClients = (page) => {
    setProcessing(true)
    const params = {
      "s": search,
      "page": page,
      "per_page": perPage,
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/btobclients/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        setClients(data)
        setCurrentPage(data?.page)
        setPerPage(data?.per_page)
        setPages(data?.number_of_pages)
        setItemCount(data?.count)
        setTotalItems(data?.count)
        setProcessing(false)
      }).catch((error) => {
        setProcessing(false)
        console.log(error)
  })
  }
  
  const pageChanged = (pageNumber) => {
    // console.log('called')
    currentUrlParams.set('page', pageNumber)
    setCurrentPage(Number(currentUrlParams.get('page')))
    setUrlParams(currentUrlParams)    
    fetchClients(Number(currentUrlParams.get('page')))
  }
  // console.log('currentPage', currentPage)
  useEffect(() => {
    fetchClients(currentPage)
  }, [])

  const filterClientsLast7Days = (clients) => {
    const currentDate = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    sevenDaysAgo.setHours(0, 0, 0, 0); // Set to midnight
  
    return clients?.results?.filter(client => {
      const clientDate = new Date(client?.date_added);
      clientDate.setHours(0, 0, 0, 0); // Set to midnight

      console.log('Client Date:', clientDate);

      // Check if the client was created within the last 7 days
      return clientDate >= sevenDaysAgo && clientDate <= currentDate;
    });
  }

  const newClients = filterClientsLast7Days(clients?.results)

  const handleSuspendB2BClient = async (id) => {
    setProcessing(id)
    return makeAuthenticatedRequest(`/oms/btobclients/${id}/suspend/`, "POST")
      .then((response) => response.json())
      .then((data) => {
        toast.success("B2bClient succesfully suspended")
        fetchClients(currentPage)
      })
      .catch((err) => {
        toast.error("Something went wrong could not suspend client")
        console.log("err",err)
      }).finally(() => {
        setProcessing(false);
      });
  }

  const handleActivateB2BClient = async (id) => {
    setProcessing(id)
    return makeAuthenticatedRequest(`/oms/btobclients/${id}/un-suspend/`, "POST")
      .then((response) => response.json())
      .then((data) => {
        toast.success("B2bClient succesfully activated")
        fetchClients(currentPage)
      })
      .catch((err) => {
        toast.error("Something went wrong could not activated client")
        console.log("err",err)
      }).finally(() => {
        setProcessing(false);
      });
  }
  
  return (
    <>
          {
        processing &&
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
          <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
        </div>
      }
      
    <div className=" px-4 md:px-20 flex flex-col w-full md:w-[90%] h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10 font-satoshi justify-center">
     <div className="flex flex-row items-center justify-between">
      <p className='font-semibold text-[24px] text-gray-900'>B2B Portal</p>
            <Link to={ENDPOINT_BTOB_ONBOARDING} className='bg-gray-200 rounded-md px-4 text-center flex flex-row gap-2 items-center py-2'>
            Onboard new client
            </Link>
     </div>
     <div className="flex flex-row w-full gap-5 items-center border-b border-gray-300 overflow-x-auto">
      <div onClick={() => setCurrentTab(CLIENTS_TAB)} className={` cursor-pointer ${currentTab === CLIENTS_TAB ? 'text-gray-800 underline-offset-[5px] underline decoration-4':'text-gray-400'} font-semibold text-[16px]`}>{CLIENTS_TAB}</div>
      <div onClick={() => setCurrentTab(ORDERS_TAB)} className={` cursor-pointer ${currentTab === ORDERS_TAB ? 'text-gray-800 underline-offset-[5px] underline decoration-4':'text-gray-400'} font-semibold text-[16px]`}>{ORDERS_TAB}</div>
      <div onClick={() => setCurrentTab(SUPPORT_TICKETS_TAB)} className={` cursor-pointer ${currentTab === SUPPORT_TICKETS_TAB ? 'text-gray-800 underline-offset-[5px] underline decoration-4':'text-gray-400'} font-semibold text-[16px] line-clamp-1`}>{SUPPORT_TICKETS_TAB}</div>
      <div onClick={() => setCurrentTab(ACTIVITY_TAB)} className={` cursor-pointer ${currentTab === ACTIVITY_TAB ? 'text-gray-800 underline-offset-[5px] underline decoration-4':'text-gray-400'} font-semibold text-[16px]`}>{ACTIVITY_TAB}</div>
     </div>
      {currentTab === CLIENTS_TAB &&
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center w-full gap-5">

     <div className="flex flex-1 flex-col md:flex-row w-full gap-5 items-start md:items-center py-2">
      <div onClick={() => setCurrentClientsTab(NEW_CLIENTS_TAB)} className={` cursor-pointer ${currentClientsTab === NEW_CLIENTS_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white ':'text-gray-400'} text-[16px]`}>{NEW_CLIENTS_TAB}</div>
      <div onClick={() => setCurrentClientsTab(ACTIVE_CLIENTS_TAB)} className={` cursor-pointer ${currentClientsTab === ACTIVE_CLIENTS_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white ':'text-gray-400'} text-[16px]`}>{ACTIVE_CLIENTS_TAB}</div>
      <div onClick={() => setCurrentClientsTab(PENDING_APPROVAL_CLIENTS_TAB)} className={` cursor-pointer ${currentClientsTab === PENDING_APPROVAL_CLIENTS_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white ':'text-gray-400'} text-[16px]`}>{PENDING_APPROVAL_CLIENTS_TAB}</div>
      <div onClick={() => setCurrentClientsTab(SUSPENDED_CLIENTS_TAB)} className={` cursor-pointer ${currentClientsTab === SUSPENDED_CLIENTS_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white ':'text-gray-400'} text-[16px]`}>{SUSPENDED_CLIENTS_TAB}</div>
      <div onClick={() => setCurrentClientsTab(ALL_CLIENTS_TAB)} className={` cursor-pointer ${currentClientsTab === ALL_CLIENTS_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white':'text-gray-400'} text-[16px]`}>{ALL_CLIENTS_TAB}</div>
     </div>
     <button className='flex flex-row items-center px-4 py-2 gap-2 border rounded-md'>
      <p className='text-gray-500 text-[16px]'>Filters</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500">
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
    </svg>

     </button>
      </div>
     }

{currentTab === ORDERS_TAB &&
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center w-full gap-5">

     <div className="flex flex-1 flex-col  md:flex-row w-full gap-5 items-start md:items-center py-2">
      <div onClick={() => setCurrentOrdersTab(ACTIVE_ORDER_TAB)} className={` cursor-pointer ${currentOrdersTab === ACTIVE_ORDER_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white ':'text-gray-400'} text-[16px]`}>{ACTIVE_ORDER_TAB}</div>
      <div onClick={() => setCurrentOrdersTab(RFQS_ORDER_TAB)} className={` cursor-pointer ${currentOrdersTab === RFQS_ORDER_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white ':'text-gray-400'} text-[16px]`}>{RFQS_ORDER_TAB}</div>
      <div onClick={() => setCurrentOrdersTab(QUOTES_ORDER_TAB)} className={` cursor-pointer ${currentOrdersTab === QUOTES_ORDER_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white ':'text-gray-400'} text-[16px]`}>{QUOTES_ORDER_TAB}</div>
      <div onClick={() => setCurrentOrdersTab(LPOS_ORDER_TAB)} className={` cursor-pointer ${currentOrdersTab === LPOS_ORDER_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white ':'text-gray-400'} text-[16px]`}>{LPOS_ORDER_TAB}</div>
      <div onClick={() => setCurrentOrdersTab(GRN_ORDER_TAB)} className={` cursor-pointer ${currentOrdersTab === GRN_ORDER_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white':'text-gray-400'} text-[16px]`}>{GRN_ORDER_TAB}</div>
      <div onClick={() => setCurrentOrdersTab(INVOICE_ORDER_TAB)} className={` cursor-pointer ${currentOrdersTab === INVOICE_ORDER_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white':'text-gray-400'} text-[16px]`}>{INVOICE_ORDER_TAB}</div>
      <div onClick={() => setCurrentOrdersTab(COMPLETED_ORDER_TAB)} className={` cursor-pointer ${currentOrdersTab === COMPLETED_ORDER_TAB ? 'bg-gray-800 text-center px-4 py-2 rounded-md text-white':'text-gray-400'} text-[16px]`}>{COMPLETED_ORDER_TAB}</div>

     </div>
     <button className='flex flex-row items-center px-4 py-2 gap-2 border rounded-md'>
      <p className='text-gray-500 text-[16px]'>Filters</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500">
      <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75" />
    </svg>

     </button>
      </div>
     }
        {currentTab === ORDERS_TAB &&
      currentOrdersTab === RFQS_ORDER_TAB &&
             <RfsData/>

              
              }  
                     {currentTab === ORDERS_TAB &&
      currentOrdersTab === INVOICE_ORDER_TAB &&
             <InvoicesData/>

              
              }  
              
            {currentTab === ORDERS_TAB &&
      currentOrdersTab === ACTIVE_ORDER_TAB &&
             <RfsData/>

              
              }    

                          {currentTab === ORDERS_TAB &&
      currentOrdersTab === QUOTES_ORDER_TAB &&
             <Quotes/>

              
              }  
      {currentTab === ORDERS_TAB &&
      currentOrdersTab === LPOS_ORDER_TAB &&
             <LpoData/>

              
              } 
            {currentTab === ORDERS_TAB &&
      currentOrdersTab === GRN_ORDER_TAB &&
             <GrnsData/>

              
              }         
                 

      {currentTab === CLIENTS_TAB &&(
        clients?.results?.filter((activeClient) => activeClient?.suspended ===false)?.length > 0 &&
        currentClientsTab === ACTIVE_CLIENTS_TAB &&
        <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                    <table class="table-auto w-full  ">
                      <thead className='pl-2'>
                        <tr className='pl-1 border-b border-gray-200'>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>
                            
                          </th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Customer Name</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Phone number</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Email</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Type</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Account status</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Action</th>

                        </tr>
                      </thead>
                      <tbody>
                        {clients?.results?.slice().reverse().map((client) => (
                                    
                          <tr className='p-2 border-b border-b-gray-200 font-satoshi relative'>
                            <td
                              className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                            </td>
                            <Link to={`${ENDPOINT_BTOB}/${client?.id}`}>
                            <td
                            className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                            {client?.name}

                            </td>
                            </Link>
                          <td className='pl-2 text-xs md:text-[16px]'>
                            {client?.customer_name}
                            </td>
                            <td className='pl-2 text-xs md:text-[16px]'>
                            {client?.phone_number}

                            </td>

                              <td className='pl-2'>
                            {client?.email_address}

                            </td>

                              <td className='pl-2'>
                            {client?.entity_type}

                            </td>

                              <td className='pl-2'>
                            {client?.suspended? "Supended":"Active"}

                            </td>
                            <td className='pl-2'>
                            {client?.suspended? 
                            <button 
                            onClick={() => handleActivateB2BClient(client?.id)} 
                            className='text-center px-4 py-2 rounded-md text-[14px] bg-green-300'>{processing === client?.id ? 'processing...':'Activate account'}</button>
                            :
                            <button
                            onClick={() => handleSuspendB2BClient(client?.id)} 
                            className='text-center px-4 py-2 rounded-md text-[14px] bg-red-300'>{processing === client?.id ? 'processing...':'Deactivate account'}</button>

                            }

                            </td>
                          
                          </tr>
                            )
                        )}


                      </tbody>
                    </table>
                    {clients?.results?.length > 0 && 
                    <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} pages={pages} />
                    }
        </div>
        )}

        {currentTab === CLIENTS_TAB &&(
                clients?.results?.filter((activeClient) => activeClient?.suspended ===true)?.length > 0 &&
                currentClientsTab === SUSPENDED_CLIENTS_TAB &&
                <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                            <table class="table-auto w-full  ">
                              <thead className='pl-2'>
                                <tr className='pl-1 border-b border-gray-200'>
                                  <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>
                                    
                                  </th>
                                  <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                                  <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Customer Name</th>
                                  <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Phone number</th>
                                  <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Email</th>
                                  <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Type</th>
                                  <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Active</th>

                                </tr>
                              </thead>
                              <tbody>
                                {clients?.results?.slice().reverse().filter((activeClient) => activeClient?.suspended ===true).map((client) => (
                                            
                                  <tr className='p-2 border-b border-b-gray-200 font-satoshi relative'>
                                    <td
                                      className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                                    </td>
                                    {/* <Link to={`${ENDPOINT_DSF}/${dsf.code}`}> */}
                                    <td
                                    className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                                    {client?.name}

                                    </td>
                                    {/* </Link> */}
                                  <td className='pl-2 text-xs md:text-[16px]'>
                                    {client?.customer_name}
                                    </td>
                                    <td className='pl-2 text-xs md:text-[16px]'>
                                    {client?.phone_number}

                                    </td>

                                      <td className='pl-2'>
                                    {client?.email_address}

                                    </td>

                                      <td className='pl-2'>
                                    {client?.entity_type}

                                    </td>

                                      <td className='pl-2'>
                                    {client?.suspended? "In active":"Active"}

                                    </td>
                                  
                                  </tr>
                                    )
                                )}


                              </tbody>
                            </table>
                            {clients?.results?.length > 0 && 
                            <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} pages={pages} />
                            }
                </div>
        )}

        {currentTab === CLIENTS_TAB &&(
          clients?.results?.filter((activeClient) => activeClient?.suspended ===true).length > 0 &&
          currentClientsTab === PENDING_APPROVAL_CLIENTS_TAB &&
          <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                      <table class="table-auto w-full  ">
                        <thead className='pl-2'>
                          <tr className='pl-1 border-b border-gray-200'>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>
                              
                            </th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Customer Name</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Phone number</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Email</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Type</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Active</th>

                          </tr>
                        </thead>
                        <tbody>
                          {clients?.results?.slice().reverse().filter((activeClient) => activeClient?.suspended ===true).map((client) => (
                                      
                            <tr className='p-2 border-b border-b-gray-200 font-satoshi relative'>
                              <td
                                className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                              </td>
                              {/* <Link to={`${ENDPOINT_DSF}/${dsf.code}`}> */}
                              <td
                              className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                              {client?.name}

                              </td>
                              {/* </Link> */}
                            <td className='pl-2 text-xs md:text-[16px]'>
                              {client?.customer_name}
                              </td>
                              <td className='pl-2 text-xs md:text-[16px]'>
                              {client?.phone_number}

                              </td>

                                <td className='pl-2'>
                              {client?.email_address}

                              </td>

                                <td className='pl-2'>
                              {client?.entity_type}

                              </td>

                                <td className='pl-2'>
                              {client?.suspended? "In active":"Active"}

                              </td>
                            
                            </tr>
                              )
                          )}


                        </tbody>
                      </table>
                      {clients?.results?.length > 0 && 
                      <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} pages={pages} />
                      }
          </div>
        )}

        {currentTab === CLIENTS_TAB &&(
          clients?.results?.length > 0 &&
          currentClientsTab === ALL_CLIENTS_TAB &&
          <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                      <table class="table-auto w-full  ">
                        <thead className='pl-2'>
                          <tr className='pl-1 border-b border-gray-200'>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>
                              
                            </th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Customer Name</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Phone number</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Email</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Type</th>
                            <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Active</th>

                          </tr>
                        </thead>
                        <tbody>
                          {clients?.results?.slice().reverse().map((client) => (
                                      
                            <tr className='p-2 border-b border-b-gray-200 font-satoshi relative'>
                              <td
                                className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                              </td>
                              {/* <Link to={`${ENDPOINT_DSF}/${dsf.code}`}> */}
                              <td
                              className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                              {client?.name}

                              </td>
                              {/* </Link> */}
                            <td className='pl-2 text-xs md:text-[16px]'>
                              {client?.customer_name}
                              </td>
                              <td className='pl-2 text-xs md:text-[16px]'>
                              {client?.phone_number}

                              </td>

                                <td className='pl-2'>
                              {client?.email_address}

                              </td>

                                <td className='pl-2'>
                              {client?.entity_type}

                              </td>

                                <td className='pl-2'>
                              {client?.suspended? "In active":"Active"}

                              </td>
                            
                            </tr>
                              )
                          )}


                        </tbody>
                      </table>
                      {clients?.results?.length > 0 && 
                      <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} pages={pages} />
                      }
          </div>
        )}

        {currentTab === CLIENTS_TAB &&(
        newClients?.length > 0 &&
        currentClientsTab === NEW_CLIENTS_TAB &&
        <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                    <table class="table-auto w-full  ">
                      <thead className='pl-2'>
                        <tr className='pl-1 border-b border-gray-200'>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>
                            
                          </th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Customer Name</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Phone number</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Email</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Type</th>
                          <th className='pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Active</th>

                        </tr>
                      </thead>
                      <tbody>
                        {newClients?.slice().reverse().map((client) => (
                                    
                          <tr className='p-2 border-b border-b-gray-200 font-satoshi relative'>
                            <td
                              className='p-4 text-xs md:text-[16px] text-left font-satoshi'>
                            </td>
                            {/* <Link to={`${ENDPOINT_DSF}/${dsf.code}`}> */}
                            <td
                            className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                            {client?.name}

                            </td>
                            {/* </Link> */}
                          <td className='pl-2 text-xs md:text-[16px]'>
                            {client?.customer_name}
                            </td>
                            <td className='pl-2 text-xs md:text-[16px]'>
                            {client?.phone_number}

                            </td>

                              <td className='pl-2'>
                            {client?.email_address}

                            </td>

                              <td className='pl-2'>
                            {client?.entity_type}

                            </td>

                              <td className='pl-2'>
                            {client?.suspended? "In active":"Active"}

                            </td>
                          
                          </tr>
                            )
                        )}


                      </tbody>
                    </table>
                    {newClients?.length > 0 && 
                    <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} pages={pages} />
                    }
        </div>)}
    </div>
    {currentTab === CLIENTS_TAB && (
      <>
    {currentClientsTab === NEW_CLIENTS_TAB && (
    newClients?.length === 0 &&
    <div className="flex flex-col w-full justify-center items-center">

      <p className="font-satoshi text-gray-500">No new B2B Clients found.</p>
    </div>
    
    )}

    {currentClientsTab === ACTIVE_CLIENTS_TAB && (
       clients?.results?.filter((activeClient) => activeClient?.suspended === false)?.length === 0 &&
        <div className="flex flex-col w-full justify-center items-center">

          <p className="font-satoshi text-gray-500">No active B2B Clients found.</p>
        </div>
        
        )}

      {currentClientsTab === PENDING_APPROVAL_CLIENTS_TAB && (
            clients?.results?.filter((activeClient) => activeClient?.suspended ===true)?.length === 0 &&
              <div className="flex flex-col w-full justify-center items-center">

                <p className="font-satoshi text-gray-500">No B2B Clients with pending approvals found.</p>
              </div>
              
      )}  

    {currentClientsTab === SUSPENDED_CLIENTS_TAB && (
    clients?.results?.filter((activeClient) => activeClient?.suspended === true)?.length === 0 &&
      <div className="flex flex-col w-full justify-center items-center">

        <p className="font-satoshi text-gray-500">No suspended B2B Clients found.</p>
      </div>
              
      )}    

          {currentClientsTab === ALL_CLIENTS_TAB && (
    clients?.results?.length === 0 &&
      <div className="flex flex-col w-full justify-center items-center">

        <p className="font-satoshi text-gray-500">No B2B Clients found.</p>
      </div>
              
      )}    
    </>
    )}
    </>
  )
}

export default BClients