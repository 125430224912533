import React from 'react'

const OrderShelfLife = ({ datePlaced, startDateTime, endDateTime }) => {
    
    const getTimeDifference = () => {
        const startDate = new Date(startDateTime);
        const endDate = new Date(endDateTime);
    
        const diffInMilliseconds = endDate - startDate;
        const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    
        if (diffInSeconds >= 30 * 24 * 60 * 60) {
          const months = Math.floor(diffInSeconds / (30 * 24 * 60 * 60));
          return `${months} month${months > 1 ? 's' : ''}`;
        } else if (diffInSeconds >= 7 * 24 * 60 * 60) {
          const weeks = Math.floor(diffInSeconds / (7 * 24 * 60 * 60));
          return `${weeks} week${weeks > 1 ? 's' : ''}`;
        } else if (diffInSeconds >= 24 * 60 * 60) {
          const days = Math.floor(diffInSeconds / (24 * 60 * 60));
          return `${days} day${days > 1 ? 's' : ''}`;
        } else if (diffInSeconds >= 60 * 60) {
          const hours = Math.floor(diffInSeconds / (60 * 60));
          return `${hours} hour${hours > 1 ? 's' : ''}`;
        } else if (diffInSeconds >= 60) {
          const minutes = Math.floor(diffInSeconds / 60);
          return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        } else {
          return `${diffInSeconds} second${diffInSeconds > 1 ? 's': ''}`
        }
      };
  return (
    <div className='font-satoshi text-[16px]'>Shelf life :{getTimeDifference()}</div>
  )
}

export default OrderShelfLife