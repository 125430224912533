import React, { useEffect, useState } from 'react'
import back from '../assets/ic_back.svg'
import disc from '../assets/disc.jpg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { makeAuthenticatedRequest } from '../utils'
import Avatar from 'react-avatar'
import { Pagination } from '../components/Pagination'
import { ENDPOINT_JOBS, ENDPOINT_ORDERS } from '../routes'
import Rating from '../components/Rating'
import Moment from 'react-moment'



const ORDERS_TAB = "orders"
const JOBS_TAB = "jobs"

function UserDetailsPage() {
    const { userID } = useParams()

    const navigate = useNavigate()
    const [customer, setCustomer] = useState([])
    const [currentTab, setCurrentTab] = useState(ORDERS_TAB)


    const [ordersData, setOrdersData] = useState([])
    const [jobs, setJobs] = useState([])

    const [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [ordersCurrentPage, setOrdersCurrentPage] = useState(1)

    const [currentJobPage, setCurrentJobPage] = useState(1)

    const [perPage, setPerPage] = useState(16)
    const [ordersPerPage, setOrdersPerPage] = useState(16)

    const [itemCount, setItemCount] = useState(0)
    const [ordersItemCount, setOrdersItemCount] = useState(0)

    const [totalItems, setTotalItems] = useState(0)
    const [ordersTotalItems, setOrdersTotalItems] = useState(0)



    const getJobs = (page) => {
        const params = {
          "page": page,
          "per_page": perPage
        }
    
        const searchParams = new URLSearchParams(params).toString();
        makeAuthenticatedRequest(`/oms/consumer/${userID}/jobs/?${searchParams}`)
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setJobs(data)
            setCurrentJobPage(data.page)
            setPerPage(data.per_page)
            setItemCount(data.count)
            setTotalItems(data.count)
  
          });
      }
  
    const fetchOrders = (page) => {
      const params = {
        "s": search,
        "page": page,
        "per_page": perPage,
      }
      const searchParams = new URLSearchParams(params).toString();
      makeAuthenticatedRequest(`/oms/consumer/${userID}/orders/?${searchParams}`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setOrdersData(data)
          setOrdersCurrentPage(data.page)
          setOrdersPerPage(data.per_page)
          setOrdersItemCount(data.count)
          setOrdersTotalItems(data.count)
        });
    }



    // consumer/<str:id>/jobs/
//    consumer/<str:id>/orders/</str:id>
    const fetchCustomer = () => {
        makeAuthenticatedRequest(`/oms/consumer/${userID}/`)
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setCustomer(data)
          });
      }

      const pageChanged = (pageNumber) => {
        setCurrentPage(pageNumber)
        fetchOrders(pageNumber)
      }
     
      const orderPageChanged = (pageNumber) => {
        setOrdersCurrentPage(pageNumber)
        fetchOrders(pageNumber)
      }
      useEffect(() => {
        fetchCustomer();
        fetchOrders(1)
        getJobs(1)
      }, [])  

      console.log('customer',customer)
      console.log('orders',ordersData)
      console.log('jobs',jobs)


  return (
    <>
        <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
    <div className="flex flex-row">
        <div onClick={() => navigate(-1)}
        className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] text-[14px] rounded-lg">
        <img src={back} alt="" />
        Back
        </div>
    </div>
    <div className="flex flex-row w-full gap-4">
            <div className="">
                {/* <img src={disc} className='rounded-full w-20 h-20 object-cover' alt="" /> */}
                <Avatar name={customer?.first_name} circle='true' size={45} className="rounded-full shadow" />
            </div>
            <div className="flex-1 flex flex-col gap-1">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] mt-2'>{customer?.first_name} {customer?.last_name}</p>
        {/* <Rating avarageRating={3}/> */}
{false &&       
 <div className="flex flex-row gap-1">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
        </svg>
        

        <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] '>4.1</p>

        </div> } 
            </div>

        </div>
    
    <div className="flex flex-row gap-4 w-full md:w-[90%] ">
        <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Contacts</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4 px-2">
            
            <div className="flex-1 flex flex-col md:flex-row gap-6">
        <div className="flex flex-row gap-1">
            <div className="border border-red-400 rounded-full p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-red-400 w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>

            </div>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{customer?.phone_number ? `+ ${customer?.phone_number} `: ''}</p>

        </div>

        <div className="flex flex-row gap-1">
        <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px] '>Email:</p>            
        <p className='font-satoshi text-[#030A1A] px-2 text-[13px] md:text-[16px] '>{customer?.email}</p>

        </div>

                
            </div>

        </div>

        
        </div>


    </div>
    {customer?.vehicles?.length >0 ? 
    <div className="flex flex-col w-full md:w-[90%] border rounded-lg gap-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Vehicle</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-col md:flex-row gap-2 p-2">

        {customer?.vehicles?.map((vehicle) => (

        <div className="flex flex-row w-full gap-4 px-2 shadow-sm border rounded-md py-2">
            <div className="">
                <img src='/cdn/images/vehicle.svg' className='rounded w-30 h-16 object-cover' alt="" />
            </div>
            <div className="flex-1 flex flex-col gap-1 pt-4">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>{vehicle.formatted_name}</p>
        {/* <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px] '>123 Anywhere street, Nairobi</p>             */}

            </div>
            </div>
        ))}
        </div>

        
    </div>
    :
    null
    }

        <div className="flex flex-col w-full md:w-[90%] border rounded-lg gap-2 pt-1 pb-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Address</p>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4 px-2">
            
            <div className="flex-1 flex flex-col md:flex-row gap-2">
                {customer?.addresses?.map((address) => (
                    <div className="flex flex-col gap-2 rounded-md px-4 py-2 border shadow-sm">

                    <>
                    <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] capitalize font-semibold'>{address.category}</p>
                    <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] capitalize '>{address.town}</p>         
                    </>
                    </div>

                ))}


            </div>
            </div>

        
        </div>

        <div className="flex flex-col w-full md:w-[90%] border rounded-lg gap-2 pt-1 pb-2">
            <div className="flex">

        <p onClick={() =>setCurrentTab(ORDERS_TAB)} className={`font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] ${currentTab === ORDERS_TAB ? 'text-[#030A1A]': 'text-[#999DA8]' } cursor-pointer`}>Order history</p>
        <p onClick={() =>setCurrentTab(JOBS_TAB)} className={`font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] ${currentTab === JOBS_TAB ? 'text-[#030A1A]': 'text-[#999DA8]' } cursor-pointer`}>Jobs history</p>
            </div>

        <div className="border-b border-b-gray-200"></div>
        {currentTab === ORDERS_TAB ?
        <>
        <div className="flex flex-col gap-4 px-4">
            <div className="flex flex-col md:flex-row gap-3">
                <div className="flex flex-col gap-2  rounded-md bg-[#F7F7F8] px-4 py-2">
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Total orders</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>{customer?.order_count}</p>


                </div>

                <div className="flex flex-col gap-2 rounded-md bg-[#F7F7F8] px-4 py-2">
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Total spend</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Ksh {customer?.order_total}</p>
                </div>

                <div className="flex flex-col gap-2 rounded-md bg-[#F7F7F8] px-4 py-2">
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Cancelled</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>1</p>


                </div>
            </div>

        </div>
        <div className="flex flex-col gap-4 px-2">

            <div className="flex flex-col gap-2 px-2">
            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Recent</p>
            <div className="border-b w-full border-gray-200"></div>
            {ordersData.results?.filter((orderData) => orderData.passport.id === userID).map((order) =>(

            <>
            <div className="flex flex-row gap-2 justify-between">
                <div className="flex flex-col gap-1">
                    <Link to={`${ENDPOINT_ORDERS}/${order.id}`}>
                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Order # {order.order_id}</p>
                    </Link>
            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Completed 
            {order.date_completed &&
            <Moment format="MMM, DD LT" className='flex'>
            {order.date_completed} 
            </Moment>
            }
            
            </p>



                </div>
                <div className="flex flex-row">
            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>KSH</p>


                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>{order.total}</p>
                </div>

            </div>

        <div className="border-b border-b-gray-200"></div>
            </>
            ))}

            </div>
        </div>
        <div className="flex flex-row-reverse">
        {ordersData.results?.length > 0 &&
        <Pagination pageChanged={orderPageChanged} totalItems={ordersTotalItems} itemsPerPage={ordersPerPage} currentPage={ordersCurrentPage} />
        }

        {/* <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>See all</p> */}

        </div>
        </>
        : currentTab === JOBS_TAB ?
        <>
        <div className="flex flex-col gap-4 px-4">
            <div className="flex flex-col md:flex-row gap-3">
                <div className="flex flex-col gap-2  rounded-md bg-[#F7F7F8] px-4 py-2">
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Total jobs</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>{jobs.count}</p>


                </div>

                <div className="flex flex-col gap-2 rounded-md bg-[#F7F7F8] px-4 py-2">
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Total spend</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Ksh {customer?.order_total}</p>
                </div>

                <div className="flex flex-col gap-2 rounded-md bg-[#F7F7F8] px-4 py-2">
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Cancelled</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>1</p>


                </div>
            </div>

        </div>
        <div className="flex flex-col gap-4 px-2">

            <div className="flex flex-col gap-2 px-2">
            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Recent</p>
            <div className="border-b w-full border-gray-200"></div>
            {jobs.results?.map((job) =>(

            <>
            <div className="flex flex-row gap-2 justify-between">
                <div className="flex flex-col gap-1">
                    <Link to={`${ENDPOINT_JOBS}/${job.id}`}>
                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Job # {job.id}</p>
                    </Link>
            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Completed 
            {job.date_completed &&
            <Moment format="MMM, DD LT" className='flex'>
            {job.date_completed}
            </Moment>
}
             </p>



                </div>
                <div className="flex flex-row">
            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>KSH</p>


                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>{job.price}</p>
                </div>

            </div>

        <div className="border-b border-b-gray-200"></div>
            </>
            ))}

            </div>
        </div>
        <div className="flex flex-row-reverse">
        {jobs.results?.length > 0 &&
        <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} />
        }
        {/* <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>See all</p> */}

        </div>
        </>
        : null
            }

        </div>


    </div>
    </>
  )
}

export default UserDetailsPage