import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatNumber,
  makeAuthenticatedRequest,
  getLocalStoreItem,
} from "../utils";
import { Pagination } from "../components/Pagination";
import { API_PREFIX, LS_ACCOUNT_ID, LS_ACCESS_TOKEN } from "../constants";
import back from "../assets/ic_back.svg";
import Avatar from "react-avatar";

const INVENTORY_TAB = "inventory";
const ORDERS_TAB = "orders";

function MerchantDetailsPage() {
  const { userID } = useParams();

  // authenticate export endpoint
  const authUserId = getLocalStoreItem(LS_ACCOUNT_ID);
  const token = getLocalStoreItem(LS_ACCESS_TOKEN);
  const key =
    token.substring(0, 4) + token.substring(token.length - 4, token.length);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(INVENTORY_TAB);

  const [merchantData, setMerchantData] = useState([]);

  const [ordersData, setOrdersData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(1000);
  const [itemCount, setItemCount] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [openSendSMSModal, setOpenSendSMSModal] = useState(false);
  const [editing, setEditing] = useState(false);

  const [retailCommission, setRetailCommission] = useState(10);
  const [wholesaleCommission, setWholesaleCommission] = useState(8);

  const [inventoryData, setInventoryData] = useState([]);
  const [processing, setProcessing] = useState(false);

  const fetchInventory = (page) => {
    setProcessing(true);
    const params = {
      page: page,
      per_page: perPage,
    };
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/inventory/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInventoryData(data);
        setCurrentPage(data.page);
        setPerPage(data.per_page);
        setItemCount(data.count);
        setTotalItems(data.total_items);
        setProcessing(false);
      });
  };

  const changeCommission = () => {
    setOpenSendSMSModal(false);
    setProcessing(true);
    const params = {
      merchant_commission: retailCommission,
      merchant_wholesale_commission: wholesaleCommission,
    };
    makeAuthenticatedRequest(
      `/oms/merchant/${userID}/commission/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        fetchMerchant();
        setProcessing(false);
      });
  };

  const [orders, setOrders] = useState([]);
  const [ordersCurrentPage, setOrdersCurrentPage] = useState(1);
  const [ordersPerPage, setOrdersPerPage] = useState(16);
  const [ordersItemCount, setOrdersItemCount] = useState(0);
  const [ordersTotalItems, setOrdersTotalItems] = useState(0);
  const [name, setName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");

  const fetchUserOrders = (page) => {
    const params = {
      s: search,
      page: page,
      per_page: perPage,
    };
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/consumer/${userID}/orders/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setOrders(data);
        setOrdersCurrentPage(data.page);
        setOrdersPerPage(data.per_page);
        setOrdersItemCount(data.count);
        setOrdersTotalItems(data.count);
      });
  };

  const fetchOrders = (page) => {
    const params = {
      s: search,
      page: page,
      per_page: perPage,
    };
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/orders/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setOrdersData(data);
        setCurrentPage(data.page);
        setPerPage(data.per_page);
        setItemCount(data.count);
        setTotalItems(data.count);
      });
  };

  const fetchMerchant = () => {
    setProcessing(true);
    makeAuthenticatedRequest(`/oms/merchant/${userID}/`)
      .then((response) => response.json())
      .then((data) => {
        setProcessing(false);
        console.log(data);
        setMerchantData(data);
        setRetailCommission(data.merchant_commission);
        setWholesaleCommission(data.merchant_wholesale_commission);
      });
  };

  const orderPageChanged = (pageNumber) => {
    setOrdersCurrentPage(pageNumber);
    fetchUserOrders(pageNumber);
  };
  useEffect(() => {
    fetchMerchant();
    // fetchOrders(1)
    fetchUserOrders(1);
    fetchInventory(1);
  }, []);

  const verifyMerchant = () => {
    const params = {
      is_merchant_verified: true,
    };
    makeAuthenticatedRequest(
      `/oms/merchant/${userID}/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMerchantData(data);
      });
  };

  const UnVerifyMerchant = () => {
    const params = {
      is_merchant_verified: false,
    };
    makeAuthenticatedRequest(
      `/oms/merchant/${userID}/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setMerchantData(data);
      });
  };

  useEffect(() => {
    setName(merchantData?.merchant_name);
    setPhoneNumber(merchantData?.phone_number);
    setEmail(merchantData?.merchant_contact_email);
    setLocation(merchantData?.location);
  }, [merchantData]);

  console.log("merchantData", merchantData);
  console.log("orders", ordersData);

  return (
    <>
      {processing && (
        <div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
          <img
            src="/cdn/images/loading.gif"
            className="mr-3 cursor-pointer  h-24"
            alt="Sparepap Logo"
          />
          <h2 class="text-center text-white text-xl font-semibold">
            Processing...
          </h2>
        </div>
      )}
      <div className=" px-4 md:px-20 flex w-full flex-col h-full max-h-auto md:max-h-screen  overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
        <div className="flex flex-row justify-between md:w-[90%]">
          <div
            onClick={() => navigate(-1)}
            className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] text-[14px] rounded-lg"
          >
            <img src={back} alt="" />
            Back
          </div>

          <div className="flex flex-row gap-2">
            <a
              href={`${API_PREFIX}/oms/consumer/${userID}/orders/export/?id=${authUserId}&key=${key}`}
              className="rounded whitespace-nowrap text-center bg-[#E5E6E9]  cursor-pointer px-3 py-1.5 font-satoshi text-[#808491] hover:bg-[#cdced3]"
            >
              Export Orders
              <span className="p-1 ml-2 text-[#b0b6c7] text-xs">
                <svg
                  className="w-5 h-5 inline"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Download-2</title>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="Download-2">
                      <rect
                        id="Rectangle"
                        fill-rule="nonzero"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      ></rect>
                      <line
                        x1="12"
                        y1="14"
                        x2="12"
                        y2="20"
                        id="Path"
                        stroke="#0C0310"
                        stroke-width="2"
                        stroke-linecap="round"
                      ></line>
                      <path
                        d="M15,19 L12.7071,21.2929 C12.3166,21.6834 11.6834,21.6834 11.2929,21.2929 L9,19"
                        id="Path"
                        stroke="#0C0310"
                        stroke-width="2"
                        stroke-linecap="round"
                      ></path>
                      <path
                        d="M19.9495,16 C20.5978,15.3647 21,14.4793 21,13.5 C21,11.567 19.433,10 17.5,10 C17.3078,10 17.1192,10.0155 16.9354,10.0453 C16.4698,6.63095 13.5422,4 10,4 C6.13401,4 3,7.13401 3,11 C3,12.9587 3.80447,14.7295 5.10102,16"
                        id="Path"
                        stroke="#0C0310"
                        stroke-width="2"
                        stroke-linecap="round"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
            </a>

            <div className="flex flex-row  gap-2">
              {editing && (
                <>
                  <button
                    onClick={() => setEditing(false)}
                    className="text-center px-3 py-1.5 border rounded-md"
                  >
                    cancel
                  </button>
                  <button className="text-center px-3 py-1.5 text-white bg-gray-900 rounded-md">
                    Save
                  </button>
                </>
              )}
              {!editing && (
                <button
                  onClick={() => setEditing(true)}
                  className="text-center px-3 py-1.5 text-white bg-gray-900 rounded-md"
                >
                  Edit
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4">
          <div>
            {merchantData.merchant_thumbnail !== null ? (
              <img
                src={merchantData?.merchant_thumbnail}
                className="rounded w-14 h-14 border object-cover"
                alt=""
              />
            ) : (
              <Avatar
                name={merchantData?.merchant_name}
                circle="true"
                size={45}
                className="rounded-full shadow"
              />
            )}
          </div>
          <div className="flex-1 flex flex-col gap-1 mt-2">
            <div className="flex flex-row gap-2">
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                className={`${
                  editing
                    ? "border rounded-md p-2 outline-none"
                    : "outline-none"
                } font-satoshi text-[#030A1A] font-semibold px-2 text-[16px] md:text-[16px] `}
              />

              {merchantData?.is_merchant_verified && (
                <span>
                  <img src="/cdn/images/ic_verified.svg" alt="" />
                </span>
              )}
              <a
                target="_blank"
                href={`https://sparepap.com/merchant/${merchantData?.id}`}
              >
                <p className="font-satoshi text-[#D95762] cursor-pointer px-2 text-[16px] md:text-[16px] underline">
                  view page
                </p>
              </a>
            </div>
            {merchantData?.is_merchant_verified ? (
              <button
                onClick={UnVerifyMerchant}
                className="font-satoshi text-[16px] bg-gray-200 rounded-md px-4 py-1 outline-none w-[50%] md:w-[10%]"
              >
                Unverify
              </button>
            ) : (
              <button
                onClick={verifyMerchant}
                className="font-satoshi text-[16px] bg-gray-200 rounded-md px-4 py-1 outline-none w-[50%] md:w-[10%]"
              >
                Verify
              </button>
            )}
          </div>
        </div>

        <div className="flex flex-row gap-4 w-full md:w-[90%] ">
          <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
            <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold">
              Contacts
            </p>
            <div className="border-b border-b-gray-200"></div>
            <div className="flex flex-row w-full gap-4 px-2">
              <div className="flex-1 flex flex-col md:flex-row gap-6">
                <div className="flex flex-row gap-1">
                  <div className="border border-red-400 rounded-full p-1.5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="text-red-400 w-3 h-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                  </div>
                  <input
                    value={phone_number}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    type="text"
                    className={`${
                      editing
                        ? "border rounded-md p-2 outline-none"
                        : "outline-none"
                    }`}
                  />
                </div>
                <div className="flex flex-row gap-1">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px] ">
                    Email:
                  </p>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    className={`${
                      editing
                        ? "border rounded-md p-2 outline-none"
                        : "outline-none"
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-4 w-full md:w-[90%] ">
          <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
            <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold">
              Commission
            </p>
            <div className="border-b border-b-gray-200"></div>
            <div className="flex flex-row w-full gap-4 px-2">
              <div className="flex-1 flex flex-col md:flex-row gap-6">
                <div className="flex flex-row gap-1">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px] ">
                    Retail:
                  </p>
                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] ">
                    {merchantData?.merchant_commission}%
                  </p>
                </div>

                <div className="flex flex-row gap-1">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px] ">
                    Wholesale:
                  </p>
                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] ">
                    {merchantData?.merchant_wholesale_commission}%
                  </p>
                </div>

                <div className="flex flex-row gap-1">
                  <span
                    onClick={() => setOpenSendSMSModal(true)}
                    className="font-satoshi text-[#D95762] cursor-pointer px-2 text-[16px] md:text-[16px] underline float-right text-sm"
                  >
                    Edit
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-4 w-full md:w-[90%]">
          <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
            <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold">
              Location
            </p>
            <div className="flex flex-col w-full gap-4 px-2">
              <div className="flex-1 flex flex-col gap-2">
                <div className="flex flex-col gap-2">
                  <div className="flex flex-row gap-1"></div>

                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] ">
                    {" "}
                    {merchantData?.merchant_address}
                  </p>
                </div>
                <div className="border-b border-b-gray-200"></div>

                <div className="flex flex-col gap-2">
                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] ">
                    {merchantData?.merchant_address_details}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full md:w-[90%] border rounded-lg gap-2 pt-1 pb-2">
          <div className="flex flex-row gap-2">
            <p
              onClick={() => setActiveTab(INVENTORY_TAB)}
              className={` font-satoshi ${
                activeTab === INVENTORY_TAB
                  ? " text-[#D95762] underline underline-offset-[14px]"
                  : "text-[#999DA8]"
              } cursor-pointer px-2 text-[13px] md:text-[16px] `}
            >
              Inventory
            </p>
            <p
              onClick={() => setActiveTab(ORDERS_TAB)}
              className={`font-satoshi  ${
                activeTab === ORDERS_TAB
                  ? " text-[#D95762] underline underline-offset-[14px]"
                  : "text-[#999DA8]"
              }  cursor-pointer px-2 text-[13px] md:text-[16px] `}
            >
              Orders
            </p>
          </div>
          <div className="border-b border-b-gray-200"></div>
          {activeTab === INVENTORY_TAB && (
            <div className="flex flex-col gap-4 px-4">
              <div className="flex flex-col md:flex-row gap-3">
                <div className="flex flex-col gap-2  rounded-md bg-[#F7F7F8] px-4 py-2">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                    Total inventory
                  </p>

                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                    {merchantData?.inventory_count}
                  </p>
                </div>

                <div
                  className={`  bg-[#F7F7F8] flex flex-col gap-2 rounded-md  px-4 py-2`}
                >
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                    Approved
                  </p>

                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                    544
                  </p>
                </div>

                <div className="flex flex-col gap-2 rounded-md bg-[#F7F7F8] px-4 py-2">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                    Out of stock
                  </p>

                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                    129
                  </p>
                </div>
              </div>
            </div>
          )}
          {activeTab === ORDERS_TAB && (
            <div className="flex flex-col gap-4 px-4">
              <div className="flex flex-col md:flex-row gap-3">
                <div className="flex flex-col gap-2  rounded-md bg-[#F7F7F8] px-4 py-2">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                    Total orders
                  </p>

                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                    {merchantData?.order_count}
                  </p>
                </div>

                <div
                  className={`  bg-[#F7F7F8] flex flex-col gap-2 rounded-md  px-4 py-2`}
                >
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                    Total spend
                  </p>

                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                    KSh. 600,576
                  </p>
                </div>

                <div className="flex flex-col gap-2 rounded-md bg-[#F7F7F8] px-4 py-2">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                    Cancelled
                  </p>

                  <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                    9
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col gap-4 px-2">
            {activeTab === ORDERS_TAB && (
              <>
                <div className="flex flex-col gap-2 px-2">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                    Recently added
                  </p>
                  <div className="border-b w-full border-gray-200"></div>

                  {orders.results?.lines?.map((order) => (
                    <>
                      <div className="flex flex-row gap-2 justify-between">
                        <div className="flex flex-col gap-1">
                          <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                            Order # {order.order_id}
                          </p>
                          <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                            Completed {order.date_completed}
                          </p>
                        </div>
                        <div className="flex flex-row">
                          <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                            KSH
                          </p>

                          <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                            {order.total}
                          </p>
                        </div>
                      </div>

                      <div className="border-b border-b-gray-200"></div>
                    </>
                  ))}
                </div>
                <div className="flex flex-row-reverse">
                  {orders.results?.length > 0 && (
                    <Pagination
                      pageChanged={orderPageChanged}
                      totalItems={ordersTotalItems}
                      itemsPerPage={ordersPerPage}
                      currentPage={ordersCurrentPage}
                    />
                  )}
                </div>
              </>
            )}
            {activeTab === INVENTORY_TAB && (
              <>
                <div className="flex flex-col gap-2 px-2">
                  <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]">
                    Recently added
                  </p>
                  <div className="border-b w-full border-gray-200"></div>

                  {inventoryData.results
                    ?.filter((inventory) => inventory.passport.id === userID)
                    .map((inv) => (
                      <>
                        <div className="flex flex-row gap-2 justify-between overflow-x-scroll hide-scrollbar">
                          <div className="flex flex-row gap-1 flex-1">
                            <div className="">
                              {inv.cover_image_url || inv.thumbnail_urls ? (
                                <img
                                  src={
                                    inv.cover_image_url
                                      ? inv.cover_image_url
                                      : inv.thumbnail_urls[0]
                                  }
                                  className="object-cover rounded-full h-12 w-12"
                                  alt=""
                                />
                              ) : null}
                            </div>
                            <div className="flex flex-col gap-1">
                              <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                                {inv?.name}
                              </p>
                              <div className="flex flex-row gap-1">
                                <p className="font-satoshi text-[#999DA8] px-2 text-[14px] md:text-[14px]">
                                  Added today .
                                </p>
                                <p className="font-satoshi text-[#999DA8] px-2 text-[14px] md:text-[14px]">
                                  {inv?.brand} .
                                </p>
                                <p className="font-satoshi text-[#999DA8] px-2 text-[12px] md:text-[12px]">
                                  {inv?.stock_available} In stock .
                                </p>
                                <p className="font-satoshi text-[#999DA8] px-2 text-[14px] md:text-[14px]">
                                  {inv?.weight < 5
                                    ? "0-5 kg"
                                    : inv?.weight > 5 && inv?.weight < 20
                                    ? "5-20 kg"
                                    : inv?.weight > 20 && inv?.weight < 50
                                    ? "20-50 kg"
                                    : inv?.weight > 50
                                    ? "> 50 kg"
                                    : null}
                                  .
                                </p>
                                <p className="font-satoshi text-[#999DA8] px-2 text-[12px] md:text-[12px]">
                                  Discount: ksh {inv?.discount_price}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-row">
                            <p className="font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]"></p>
                            <p className="font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]">
                              KSH {formatNumber(inv.price, 0, 0)}
                            </p>
                          </div>
                        </div>
                        <div className="border-b border-b-gray-200"></div>
                      </>
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {openSendSMSModal && (
        <>
          <div className="h-screen pt-14  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none">
            <div className="relative p-4 w-full md:w-[50%] h-full md:h-auto">
              <div className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-end p-2 rounded-t "></div>

                <div className="relative p-3 flex-auto">
                  <div className="px-4 pt-4 pb-3 w-full">
                    <div className="flex flex-col">
                      <div
                        onClick={() => setOpenSendSMSModal(false)}
                        className=""
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 float-right cursor-pointer"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-row gap-2 justify-between">
                        <div className="flex flex-row gap-2">
                          <img src="" alt="" />
                          <h2 className="text-[#030A1A] font-satoshi font-bold text-[24px] mb-4">
                            Change merchant commission
                          </h2>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2  pt-2">
                        <label
                          htmlFor=""
                          className="text-[#666C7C] text-[13px]"
                        >
                          Retail
                        </label>
                        <input
                          type="number"
                          min="0"
                          max="999999999"
                          onChange={(e) => setRetailCommission(e.target.value)}
                          value={retailCommission}
                          className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                        />
                      </div>

                      <div className="flex flex-col gap-2  pt-2">
                        <label
                          htmlFor=""
                          className="text-[#666C7C] text-[13px]"
                        >
                          Wholesale
                        </label>
                        <input
                          type="number"
                          min="0"
                          max="999999999"
                          onChange={(e) =>
                            setWholesaleCommission(e.target.value)
                          }
                          value={wholesaleCommission}
                          className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                        />
                      </div>
                    </div>
                    <div className="flex flex-row-reverse w-full py-4 gap-2">
                      <button
                        onClick={changeCommission}
                        className="bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md"
                      >
                        Submit
                      </button>
                      <button
                        onClick={() => setOpenSendSMSModal(false)}
                        className="border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            modalbackdrop=""
            className="h-screen bg-gray-900/70 fixed inset-0 z-50"
          />
        </>
      )}
    </>
  );
}

export default MerchantDetailsPage;
