import React, { useState, useRef, useEffect } from "react";
import { ChevronDownIcon } from "./Icons";

export default function DropdownMenu({ children }) {
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutsideMenuOptions = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutsideMenuOptions);

    return () => {
      document.removeEventListener("click", handleClickOutsideMenuOptions);
    };
  }, []);

  return (
    <div className="relative" ref={optionsRef}>
      <button
        id="dropdownActionButton"
        data-dropdown-toggle="dropdownAction"
        class="font-satoshi  inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
        type="button"
        onClick={() => {
          setShowOptions(!showOptions);
        }}
      >
        <span class="sr-only">Action button</span>
        Options
        <ChevronDownIcon />
      </button>

      {showOptions && (
        <div class="absolute z-10 mt-1 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
          {children}
        </div>
      )}
    </div>
  );
}
