import { signInWithCustomToken } from '@firebase/auth';
import { toast } from 'react-toastify';
import {
  API_PREFIX, CLIENT_KEY, LS_ACCESS_TOKEN, LS_ACCESS_TOKEN_TYPE,
  LS_ACCOUNT_ID, LS_ACCOUNT_USERNAME,
  LS_REQUEST_TOKEN, LS_REQUEST_TOKEN_TYPE,
  LS_ACCOUNT_FIRST_NAME,
  LS_ACCOUNT_LAST_NAME,
  LS_ACCOUNT_PERMISSIONS,
  URL_SHORTENER_PREFIX,
} from './constants';
import { auth } from './firebaseUtils';
import axios from "axios";
import {
  ENDPOINT_DASHBOARD, ENDPOINT_ENTER_EMAIL, ENDPOINT_EMAIL_VERIFY
} from './routes';



export default axios.create({
  baseURL: process.env.REACT_APP_API_PREFIX
})

export const axiosCustom = axios.create({
  baseURL: process.env.REACT_APP_API_PREFIX,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN_TYPE)} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
  }
})

export const axiosService = axios.create({
  baseURL: process.env.REACT_APP_API_PREFIX,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `${localStorage.getItem(LS_ACCESS_TOKEN_TYPE)} ${localStorage.getItem(LS_ACCESS_TOKEN)}`
  }
})

const setAccessToken = function (token, bearer, expires_in) {
  localStorage.setItem(LS_ACCESS_TOKEN, token);
  localStorage.setItem(LS_ACCESS_TOKEN_TYPE, bearer)
};

const setAccountDetails = function (ID, username, firstName, lastName, permissions) {
  let _;
  _ = ID != null ? localStorage.setItem(LS_ACCOUNT_ID, ID) : null;
  _ = username != null ? localStorage.setItem(LS_ACCOUNT_USERNAME, username) : null;
  _ = lastName != null ? localStorage.setItem(LS_ACCOUNT_LAST_NAME, lastName) : null;
  _ = firstName != null ? localStorage.setItem(LS_ACCOUNT_FIRST_NAME, firstName) : null;
  _ = permissions != null ? localStorage.setItem(LS_ACCOUNT_PERMISSIONS, JSON.stringify(permissions)) : null;
  return _
};

const setRequestToken = function (token, bearer, expires_in) {
  let _;
  _ = token != null ? localStorage.setItem(LS_REQUEST_TOKEN, token) : null;
  _ = bearer != null ? localStorage.setItem(LS_REQUEST_TOKEN_TYPE, bearer) : null;
  return _
};


export const clearAccountInfo = function () {
  localStorage.removeItem(LS_ACCESS_TOKEN)
  localStorage.removeItem(LS_ACCESS_TOKEN_TYPE)
  localStorage.removeItem(LS_ACCOUNT_ID)
  localStorage.removeItem(LS_ACCOUNT_USERNAME)
  localStorage.removeItem(LS_ACCOUNT_LAST_NAME)
  localStorage.removeItem(LS_ACCOUNT_FIRST_NAME)
};

export function isAuthenticated() {
  const accountObj = new AccountObj()
  if (accountObj.ID !== null) {
    if (accountObj.username === null) {
      return ENDPOINT_ENTER_EMAIL
    } else {
      return ENDPOINT_DASHBOARD
    }
  }
  clearAccountInfo();
  return ENDPOINT_ENTER_EMAIL
}
export function hasValidAccessToken() {
  const accessTokenObj = new AccessTokenObj();
  if (accessTokenObj.token !== null && accessTokenObj.tokenType !== null) {
    return true
  } else {
    return false
  }
}

export function isAnonymous() {
  const accessTokenObj = new AccessTokenObj();
  if (accessTokenObj.token !== null || accessTokenObj.tokenType !== null) {
    return false;
  } else {
    return true;
  }
}



export const AccessTokenObj = function (data = null) {
  if (data) {
    setAccessToken(data.token, data.token_type, null)
  }
  const token = localStorage.getItem(LS_ACCESS_TOKEN);
  const tokenType = localStorage.getItem(LS_ACCESS_TOKEN_TYPE);
  return { token, tokenType };
};

export const getLocalStoreItem = (key) => {
  return localStorage.getItem(key)
}

export const AccountObj = function (data = null, _permissions = null) {
  if (data != null || data != undefined) {
    setAccountDetails(
      data.pk,
      data.username,
      data.first_name,
      data.last_name,
      _permissions
    )
  }
  const ID = localStorage.getItem(LS_ACCOUNT_ID);
  const lastName = localStorage.getItem(LS_ACCOUNT_LAST_NAME);
  const firstName = localStorage.getItem(LS_ACCOUNT_FIRST_NAME);
  const username = localStorage.getItem(LS_ACCOUNT_USERNAME);
  const name = lastName && firstName ? `${firstName} ${lastName}` : username;
  const perm = localStorage.getItem(LS_ACCOUNT_PERMISSIONS)
  const permissions = perm ? JSON.parse(perm) : null;
  return { ID, username, firstName, lastName, name, permissions };
};

export const hasPermission = (permission) => {
  const perm = localStorage.getItem(LS_ACCOUNT_PERMISSIONS)
  const permissions = perm ? JSON.parse(perm) : null;
  return permissions ? permissions.includes(permission) : false
}

export const makeid = () => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < 4; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  result += "-"
  for (var i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return `KE-${result}`;
}

export const makeAuthenticatedRequestToUrlShortenerService = async (url, method = "GET", body = null) => {
  const headers = {
    "Content-type": "application/json",
  }
  return new Promise((resolve, reject) => {
    fetch(`${URL_SHORTENER_PREFIX}${url}`, { method: method, headers: headers, body: body })
      .then(response => {
        resolve(response)
      })
  })
}


export const makeAuthenticatedRequest = async (url, method = "GET", body = null) => {
  //TODO: introduce try/catch handle errors
  const accessToken = localStorage.getItem(LS_ACCESS_TOKEN);
  if (accessToken != null) {
    const accessTokenType = localStorage.getItem(LS_ACCESS_TOKEN_TYPE);
    const headers = {
      "Content-type": "application/json",
      "Authorization": `${accessTokenType} ${accessToken}`
    }
    return new Promise((resolve, reject) => {
      fetch(`${API_PREFIX}${url}`, { method: method, headers: headers, body: body })
        .then(response => {
          if (response.status == 401) {
            clearAccountInfo()
            window.location.reload(true);
          } else {
            resolve(response)
          }
        })
    })
    //return fetch(`${API_PREFIX}${url}`, { method: method, headers: headers, body: body });
  }

  const requestToken = localStorage.getItem(LS_REQUEST_TOKEN);
  if (requestToken != null) {
    const requestTokenType = localStorage.getItem(LS_REQUEST_TOKEN_TYPE);
    const headers = {
      "Content-type": "application/json",
      "Authorization": `${requestTokenType} ${requestToken}`
    }
    return fetch(`${API_PREFIX}${url}`, { method: method, headers: headers, body: body });
  }

  const headers = { "Content-type": "application/json" }
  return fetch(
    `${API_PREFIX}/auth/token/`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({ "client_id": CLIENT_KEY })
  }
  )
    .then((response) => response.json())
    .then((data) => {
      const requestToken = data.request_token;
      const requestTokenType = data.token_type;
      setRequestToken(requestToken, requestTokenType);
      return data;
    })
    .then((data) => {
      const requestToken = data.request_token;
      const requestTokenType = data.token_type;
      const headers = {
        "Content-type": "application/json",
        "Authorization": `${requestTokenType} ${requestToken}`
      }
      return fetch(`${API_PREFIX}${url}`, { method: method, headers: headers, body: body })
    });
};


export const formatNumber = (num, maximumFractionDigits = 2, minimumFractionDigits = 2) => {
  const res = Number(num).toFixed(2);
  return res == 'NaN' ? "0" : new Intl.NumberFormat("en", { maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: minimumFractionDigits }).format(res)
}

export const formatNumberStr = (num) => {
  const res = Number(num).toFixed(5);
  if (res > 1000000000) return `${Number(res / 1000000000).toFixed(2)} B`
  if (res > 1000000) return `${Number(res / 1000000).toFixed(2)} M`
  return `${Number(res).toFixed(0)}`
}



export const truncateNumber = (num, precision = 2) => {
  return Number(num).toFixed(precision);
}

export const getFirebaseTokenThenSignin = async () => {
  return makeAuthenticatedRequest(`/oms/firebase/`, "POST", JSON.stringify({}))
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      if (data !== null && data.firebase_token !== undefined) {
        return signInWithCustomToken(auth, data.firebase_token);
      } else {
        toast.error('Something went wrong')
      }
    });
}

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  const formattedDate = `${day}${day === 1 || day === 21 || day === 31 ? 'st' : day === 2 || day === 22 ? 'nd' : day === 3 || day === 23 ? 'rd' : 'th'} ${month} ${year}`;
  return formattedDate;
}

export const PartCondition = {
  BRAND_NEW: "N",
  SECOND_HAND: "S",
  REFURBISHED: "R",
  ANY: "A",
  DISPLAY: function (value) {
    if (value == PartCondition.BRAND_NEW) {
      return "New"
    }
    if (value == PartCondition.SECOND_HAND) {
      return "Second Hand"
    }
    if (value == PartCondition.REFURBISHED) {
      return "Refurbished"
    }
    if (value == PartCondition.ANY) {
      return "Any"
    }
  }
}
export const ShippingMode = {
  BIKE: "Bike",
  VAN_TRUCK: "Van",
}


export function formatDateAndTimeAgo(datetime) {
  if (datetime !== null && datetime !== undefined) {


    const dateObj = new Date(datetime);

    const shortMonthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    function timeAgo(date) {
      const now = new Date();
      const seconds = Math.floor((now - date) / 1000);

      const interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return `${interval} years ago`;
      }
      if (interval === 1) {
        return `${interval} year ago`;
      }

      const monthInterval = Math.floor(seconds / 2592000);
      if (monthInterval > 1) {
        return `${monthInterval} months ago`;
      }
      if (monthInterval === 1) {
        return `${monthInterval} month ago`;
      }

      const dayInterval = Math.floor(seconds / 86400);
      if (dayInterval > 1) {
        return `${dayInterval} days ago`;
      }
      if (dayInterval === 1) {
        return `${dayInterval} day ago`;
      }

      const hourInterval = Math.floor(seconds / 3600);
      if (hourInterval > 1) {
        return `${hourInterval} hours ago`;
      }
      if (hourInterval === 1) {
        return `${hourInterval} hour ago`;
      }

      const minuteInterval = Math.floor(seconds / 60);
      if (minuteInterval > 1) {
        return `${minuteInterval} minutes ago`;
      }
      if (minuteInterval === 1) {
        return `${minuteInterval} minute ago`;
      }

      return "just now";
    }

    const formattedDate = `${dateObj.getDate()} ${shortMonthNames[dateObj.getMonth()]}`;
    const timeAgoString = timeAgo(dateObj);

    return `${formattedDate} ${timeAgoString}`
  } else {
    return `- -`
  }
}

export function calculateVATandSubtotal(compoundAmount) {
  if (typeof compoundAmount !== 'number' || isNaN(compoundAmount)) {
    return {
      vatAmount: 0,
      subtotal: 0
    };
  }

  // Calculate VAT (16%) - Applied from KRA Calculation formula
  const vatRate = 1.16;
  const subtotal = compoundAmount / vatRate;
  const vatAmount = compoundAmount - subtotal;
  return {
    vatAmount: vatAmount.toFixed(2),
    subtotal: subtotal.toFixed(2)
  };
}