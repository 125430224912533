import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);



function Graph({ orders, sales, vistors, labels }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
      }
    },
    scales: {
      x: { 
        grid : {
          drawOnChartArea: false,
        }
      },
      y: { 
        ticks: {  precision:0 }, 
        min: 0, 
        grid : {
          drawOnChartArea: false,
        }
      }
    }
  };

  const data = {
    labels,
    datasets: [

      {
        label: 'Sales',
        data: sales,
        borderColor: '#E8795C',
        backgroundColor: '#DC806433',
        borderWidth: 2,
        pointRadius: 3,
        fill: true,
      },

      {
        label: 'Orders',
        data: orders,
        borderColor: '#32C7F4',
        backgroundColor: '#EAFAFE',
        borderWidth: 2,
        pointRadius: 3,
        fill: true,
      },

      {
        label: 'Vistors',
        data: vistors,
        borderColor: '#54D864',
        backgroundColor: '#EDFBF0',
        borderWidth: 2,
        pointRadius: 3,
        fill: true,
      },
    ],
  };
  return (
    <div className="h-72">
      <Line options={options} data={data} />
    </div>

  )
}
export default Graph