import { axiosCustom } from '../../../utils';
import { makeAuthenticatedRequest } from '../../../utils'

const CMS_HERO_URL = '/oms/cms/patner-subsections/';


export const createPartnerSubsection = async (partnerData) => {
    try {
        const response = await axiosCustom.post(CMS_HERO_URL, partnerData);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const getPartnerSubsections  = async () => {
    try {
        const response = await axiosCustom.get(CMS_HERO_URL);
        return response.data.results;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const updatePartnerSubsection   = async (partnerSubsectionId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_HERO_URL}${partnerSubsectionId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};

export const deletePartnerSubsection  = async (partnerSubsectionId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_HERO_URL}${partnerSubsectionId}/`);
        return response.data;
    } catch (error) {
        console.log("an error occured", error)
    }
};
