import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import back from "../assets/ic_back.svg";
import placeholder from "../assets/placeholder.svg";
import { ENDPOINT_INVENTORY } from "../routes";
import { formatNumber, makeAuthenticatedRequest } from "../utils";
import { PartType } from "../constants";
import Avatar from "react-avatar";
import Moment from "react-moment";

function InventoryDetailsPage() {
  let { inventoryID } = useParams();
  const [processing, setProcessing] = useState(false);
  const [inventoryData, setInventoryData] = useState(null);
  const [correctionMessage, setCorrectionMessage] = useState();
  const [addCorrectionNote, setAddCorrectionNote] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);

  const [retailPrice, setRetailPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [priceWholesale, setPriceWholesale] = useState();
  const [discountPercentage, setDiscountPercentage] = useState();
  const [sideBarWholesale, setSideBarWholesale] = useState(false);
  const [moq, setMoq] = useState();
  const [receivePriceWholesale, setReceivePriceWholesale] = useState();
  const [wholesaleCommission, setWholesaleCommission] = useState();

  const [retailCommission, setRetailCommission] = useState();
  const [merchantPrice, setMerchantPrice] = useState();
  const [stockAvailable, setStockAvailable] = useState();
  const [sideBarDiscountStatus, setSideBarDiscountStatus] = useState(false);

  const handleClickScroll = () => {
    setOpenNotes(true);
    setAddCorrectionNote(true);
    const element = document.getElementById("invnotes");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const fetchInventory = () => {
    makeAuthenticatedRequest(`/oms/inventory/${inventoryID}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInventoryData(data);
      });
  };

  useEffect(() => {
    fetchInventory();
  }, []);

  const sendForCorrection = () => {
    setAddCorrectionNote(false);
    const params = {
      message: correctionMessage,
    };
    makeAuthenticatedRequest(
      `/oms/inventory/${inventoryID}/send_for_corrections/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInventoryData(data);
        setCorrectionMessage("");
      });
  };

  const approveInventory = () => {
    const params = {
      is_approved: true,
    };
    makeAuthenticatedRequest(
      `/oms/inventory/${inventoryID}/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInventoryData(data);
      });
  };

  const calculatePercentage = (price1, price2) => {
    if (!price1 || !price2) {
      return 0;
    }
    return formatNumber(
      (parseInt(price1) / parseInt(price2)) * 100 - 100,
      2,
      2
    );
  };

  const unapproveInventory = () => {
    const params = {
      is_approved: false,
    };
    makeAuthenticatedRequest(
      `/oms/inventory/${inventoryID}/`,
      "POST",
      JSON.stringify(params)
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInventoryData(data);
      });
  };

  const [currentImage, setCurrentImage] = useState(
    inventoryData?.cover_image_url || inventoryData?.thumbnail_urls
  );
  console.log("thumb", inventoryData?.thumbnail_urls);
  console.log("inventoryData", inventoryData);

  const cancelRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (cancelRef.current && !cancelRef.current.contains(e.target)) {
        setAddCorrectionNote(false);

        console.log(cancelRef.current);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });
  const navigate = useNavigate();

  const displayLogs = useMemo(() => {
    let logsToDisplay = inventoryData?.logs || [];
    if (logsToDisplay?.length > 10) {
      return logsToDisplay?.slice(-7).reverse();
    }
    return logsToDisplay.reverse();
  }, [inventoryData]);

  console.log("inventoryData?.weight", inventoryData?.weight);

  const expiryDate = new Date(inventoryData?.expiry_date).toLocaleDateString();
  return (
    <>
      {addCorrectionNote && (
        <>
          <div className="h-screen pt-14  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative p-4 w-full md:w-[30%] h-full md:h-auto">
              <div
                ref={cancelRef}
                className="border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
              >
                <div className="flex items-start justify-end p-2 rounded-t "></div>

                <div className="relative p-3 flex-auto">
                  <div className="px-4 pt-4 pb-3 w-full">
                    <div className="flex flex-col">
                      <div
                        onClick={() => setAddCorrectionNote(false)}
                        className=""
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 float-right cursor-pointer"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-row gap-2 justify-between">
                        <div className="flex flex-row gap-2">
                          <img src="" alt="" />
                          <h2 className="text-[#030A1A] font-satoshi font-bold text-[24px] mb-4">
                            Add a note
                          </h2>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2 py-2">
                        <p className="flex flex-row py-2 text-[16px] font-satoshi text-[#333B51]">
                          {/* Please select a reason for the cancellation of this order */}
                        </p>
                      </div>
                      <div className="flex flex-col gap-2">
                        <form className="w-full flex flex-col gap-2">
                          <div className="w-full flex flex-col gap-1">
                            <label
                              htmlFor="rname"
                              className="font-satoshi text-[14px]"
                            >
                              Note
                            </label>
                            <textarea
                              name="rname"
                              rows={5}
                              placeholder="Add a note"
                              value={correctionMessage}
                              onChange={(e) =>
                                setCorrectionMessage(e.target.value)
                              }
                              type="text"
                              className="px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]"
                            ></textarea>
                          </div>
                        </form>
                      </div>
                      <div className="flex flex-row-reverse w-full py-4 gap-2">
                        <button
                          onClick={sendForCorrection}
                          className="bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md"
                        >
                          Save
                        </button>
                        <button
                          onClick={() => setAddCorrectionNote(false)}
                          className="border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            modalbackdrop=""
            className="h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-40"
          />
        </>
      )}
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-4 md:px-20">
        <div className="flex flex-row justify-between">
          <div
            onClick={() => navigate(-1)}
            className="flex w-auto  flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] rounded-lg"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="w-4 h-4 mt-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            Back
          </div>
          <div className="flex flex-row gap-1">
            <a
              target="_blank"
              href={`https://marketplace.sparepap.com/part/${inventoryData?.id}`}
            >
              <button className=" w-full px-4 py-2 font-satoshi text-center border rounded-md">
                Preview
              </button>
            </a>
            <Link to={`${ENDPOINT_INVENTORY}/edit/${inventoryData?.id}`}>
              <button className=" w-full px-4 py-2 font-satoshi text-center bg-black text-white rounded-md">
                Edit
              </button>
            </Link>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <div className="flex flex-col md:flex-row gap-6 w-full font-satoshi">
            <div className="flex flex-col gap-2 w-full md:w-[70%]">
              <div className="flex flex-col w-full gap-1">
                <label
                  htmlFor=""
                  className={` font-satoshi text-gray-400 font-semibold ${
                    inventoryData?.name === null
                      ? "border border-[#F77B55]"
                      : ""
                  } `}
                >
                  Title
                </label>
                <input
                  type="text"
                  className="rounded-md px-4 py-2.5 border"
                  value={inventoryData?.name}
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                <img
                  src={
                    currentImage ? currentImage : inventoryData?.cover_image_url
                  }
                  className={`${
                    inventoryData?.cover_image_url === null
                      ? "border border-[#F77B55]"
                      : "border"
                  } object-contain rounded h-72`}
                  alt=""
                />
                <div className="flex flex-row gap-2">
                  {inventoryData?.thumbnail_urls?.map((thumbnail) => (
                    <img
                      onClick={() => setCurrentImage(thumbnail)}
                      src={thumbnail}
                      className="rounded w-20 h-20"
                      alt=""
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label
                  htmlFor=""
                  className="font-satoshi text-gray-400 font-semibold"
                >
                  Description
                </label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  className={` ${
                    inventoryData?.description ? "" : "border-[#F77B55]"
                  } rounded-md px-4 py-2.5 border`}
                  value={inventoryData?.description}
                ></textarea>
              </div>
              <div className="flex flex-col w-full gap-2 border rounded-md p-2">
                <p className="font-semibold text-gray-400 font-satoshi">
                  Detailed specifications
                </p>
                <div className="flex md:grid flex-col md:grid-cols-3 gap-2">
                  <div className="col-span-1 flex flex-col gap-6">
                    <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                      Part Number
                    </p>
                    <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                      Brand
                    </p>
                  </div>
                  <div className="col-span-2 flex flex-col gap-2">
                    <div
                      type="text"
                      className={` ${
                        inventoryData?.part_number
                          ? "border"
                          : "border-[#F77B55]  min-h-[2.5em]"
                      } px-4 py-4 rounded-md`}
                    >
                      {inventoryData?.part_number}
                    </div>

                    <div
                      type="text"
                      className={`${inventoryData?.brand}? "border":" border border-[#F77B55]"} px-4 py-2 rounded-md  min-h-[2.5em]`}
                    >
                      {inventoryData?.brand}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`flex flex-col w-full gap-2  rounded-md p-2 
                ${inventoryData?.type ? "border" : "border border-[#F77B55"}`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Part Type
                </p>
                <div className="flex md:grid flex-col md:grid-cols-3 gap-2">
                  <div className="col-span-1 flex flex-col gap-6">
                    <div
                      className={`flex flex-row border gap-1 rounded-md p-2 border-[#333B51] `}
                    >
                      <input
                        type="checkbox"
                        className={` accent-[#333B51] `}
                        checked={inventoryData?.type == PartType.OEM}
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        OEM
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-6">
                    <div
                      className={`flex flex-row border gap-1 rounded-md p-2 border-[#333B51] `}
                    >
                      <input
                        type="checkbox"
                        className={` accent-[#333B51] `}
                        checked={inventoryData?.type == PartType.AFTER_MARKET}
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        Aftermarket
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`flex flex-col w-full gap-2  rounded-md p-2 
          ${
            inventoryData?.condition === "N" ||
            inventoryData?.condition === "R" ||
            inventoryData?.condition === "U"
              ? "border"
              : "border border-[#F77B55"
          }`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Part condition
                </p>
                {inventoryData?.condition === "N" ? (
                  <div className="flex md:grid flex-col md:grid-cols-3 gap-2">
                    <div className="col-span-1 flex flex-col gap-6">
                      <div
                        className={`flex flex-row border gap-1 rounded-md p-2 border-[#333B51] `}
                      >
                        <input
                          type="checkbox"
                          className={` accent-[#333B51] `}
                          checked
                        />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Brand new
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-2">
                      <div
                        className={` border flex flex-row  gap-1 rounded-md p-2 `}
                      >
                        <input type="checkbox" className={` `} />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Refurbished
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-2">
                      <div
                        className={`flex flex-row border gap-1 rounded-md p-2`}
                      >
                        <input type="checkbox" className={``} />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Used
                        </p>
                      </div>
                    </div>
                  </div>
                ) : inventoryData?.condition === "R" ? (
                  <div className="flex md:grid flex-col md:grid-cols-3 gap-2">
                    <div className="col-span-1 flex flex-col gap-6">
                      <div
                        className={`flex flex-row border gap-1 rounded-md p-2  border `}
                      >
                        <input type="checkbox" className={`  `} />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Brand new
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-2 border-[#333B51]">
                      <div
                        className={` border flex flex-row  gap-1 rounded-md p-2 `}
                      >
                        <input
                          type="checkbox"
                          className={`accent-[#333B51] `}
                          checked
                        />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Refurbished
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-2">
                      <div
                        className={`flex flex-row border gap-1 rounded-md p-2`}
                      >
                        <input type="checkbox" className={``} />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Used
                        </p>
                      </div>
                    </div>
                  </div>
                ) : inventoryData?.condition === "U" ? (
                  <div className="flex md:grid flex-col md:grid-cols-3 gap-2">
                    <div className="col-span-1 flex flex-col gap-6">
                      <div
                        className={`flex flex-row border gap-1 rounded-md p-2 `}
                      >
                        <input type="checkbox" className={`  `} />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Brand new
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-2 border">
                      <div
                        className={` border flex flex-row  gap-1 rounded-md p-2 `}
                      >
                        <input type="checkbox" className={` `} />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Refurbished
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col gap-2 border-[#333B51] ">
                      <div
                        className={`flex flex-row border gap-1 rounded-md p-2`}
                      >
                        <input
                          type="checkbox"
                          className={`accent-[#333B51]`}
                          checked
                        />
                        <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                          Used
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {/* <div className={`flex flex-col w-full gap-2  rounded-md p-2 ${inventoryData?.categories.length !==0 ? 'border':'border border-[#F77B55]'}`}> */}
              <div
                className={`flex flex-col w-full gap-2  rounded-md p-2 ${
                  inventoryData?.categories !== null
                    ? "border"
                    : "border border-[#F77B55]"
                }`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Categories
                </p>
                <div className="flex flex-col md:flex-row gap-2">
                  {inventoryData?.categories?.map((category) => (
                    <div className="rounded-md border px-4 py-1.5 flex flex-row gap-2 justify-between">
                      <p className="font-satoshi text-[14px] ">
                        {category.name}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 mt-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              </div>

              {/* <div className={`flex flex-col w-full gap-2  ${inventoryData?.tags.length !==0 ? 'border':'border border-[#F77B55]'} rounded-md p-2`}> */}
              <div
                className={`flex flex-col w-full gap-2  ${
                  inventoryData?.tags !== null
                    ? "border"
                    : "border border-[#F77B55]"
                } rounded-md p-2`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Tags
                </p>
                <div className="flex flex-col md:flex-row gap-2">
                  {inventoryData?.tags?.map((tag) => (
                    <div className="rounded-md border px-4 py-1.5 flex flex-row gap-2 justify-between">
                      <p className="font-satoshi text-[14px] ">{tag}</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 mt-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              </div>

              {inventoryData?.tags2?.length > 0 && (
                <div
                  className={`flex flex-col w-full gap-2  ${
                    inventoryData?.tags2 !== null
                      ? "border"
                      : "border border-[#F77B55]"
                  } rounded-md p-2`}
                >
                  <p className="font-semibold text-[#333B51] font-satoshi">
                    Tags2
                  </p>
                  <div className="flex flex-col md:flex-row gap-2">
                    {inventoryData?.tags2?.map((tag) => (
                      <div className="rounded-md border px-4 py-1.5 flex flex-row gap-2 justify-between">
                        <p className="font-satoshi text-[14px] ">{tag?.name}</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 mt-1"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div
                className={` ${
                  inventoryData?.vehicles.length < 1
                    ? "border border-[#F77B55]"
                    : "border"
                } flex flex-col w-full gap-2 border rounded-md p-2`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Compatibility
                </p>
                <div className="flex flex-row gap-1">
                  <p className="font-satoshi text-[14px] ">
                    This part is compatible with
                  </p>
                  {inventoryData?.vehicles?.map((vehicle) => (
                    <p className="font-satoshi text-[14px] text-black">
                      {vehicle.make} {vehicle.model},
                    </p>
                  ))}
                </div>
              </div>

              <div
                className={` ${
                  inventoryData?.is_expired
                    ? "border border-[#F77B55]"
                    : "border"
                } flex flex-col w-full gap-2 border rounded-md p-2`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Price expiry
                </p>
                <div className="flex flex-row gap-1">
                  <p className="font-satoshi text-[14px] ">{expiryDate}</p>
                </div>
              </div>

              <div className="flex flex-col w-full gap-2 border rounded-md p-2">
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Weight
                </p>
                <div className="flex md:grid flex-col md:grid-cols-4 gap-2">
                  <div className="col-span-1 flex flex-col gap-6">
                    <div
                      className={`${
                        inventoryData?.weight <= 5
                          ? "border border-[#333B51]"
                          : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        type="checkbox"
                        className={` ${
                          inventoryData?.weight <= 5 ? "accent-[#333B51]" : ""
                        }`}
                        checked={inventoryData?.weight <= 5}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 mt-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                      </svg>
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        5kg
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-2">
                    <div
                      className={`${
                        inventoryData?.weight > 5 && inventoryData?.weight <= 20
                          ? "border border-[#333B51]"
                          : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        type="checkbox"
                        className={` ${
                          inventoryData?.weight > 5 &&
                          inventoryData?.weight <= 20
                            ? "accent-[#333B51]"
                            : ""
                        }`}
                        checked={
                          inventoryData?.weight > 5 &&
                          inventoryData?.weight <= 20
                        }
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        5-20kg
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-2">
                    <div
                      className={`${
                        inventoryData?.weight > 20 && inventoryData?.weight < 50
                          ? "border border-[#333B51]"
                          : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        type="checkbox"
                        className={` ${
                          inventoryData?.weight > 20 &&
                          inventoryData?.weight < 50
                            ? "accent-[#333B51]"
                            : ""
                        }`}
                        checked={
                          inventoryData?.weight > 20 &&
                          inventoryData?.weight < 50
                        }
                      />
                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        20-50 kg
                      </p>
                    </div>
                  </div>
                  <div className="col-span-1 flex flex-col gap-2">
                    <div
                      className={`${
                        inventoryData?.weight >= 50
                          ? "border border-[#333B51]"
                          : "border"
                      } flex flex-row  gap-1 rounded-md p-2 `}
                    >
                      <input
                        type="checkbox"
                        className={` ${
                          inventoryData?.weight >= 50 ? "accent-[#333B51]" : ""
                        }`}
                        checked={inventoryData?.weight >= 50}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 mt-1"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                      </svg>

                      <p className="font-satoshi text-[#333B51] font-medium text-[16px]">
                        {" "}
                        50 kg
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`flex flex-col w-full gap-2 ${
                  inventoryData?.shipping_mode !== "Bike" &&
                  inventoryData?.shipping_mode !== "Van"
                    ? "border border-[#F77B55]"
                    : "border"
                } rounded-md p-2`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Mode of transport
                </p>
                <div className="flex flex-row gap-1">
                  <p className="font-satoshi text-[14px] ">
                    What's the recommended way to transport this item?
                  </p>
                </div>

                <div className="flex md:grid flex-col md:grid-cols-2 gap-3">
                  <div
                    className={`col-span-1 flex flex-row ${
                      inventoryData?.shipping_mode === "Bike"
                        ? "border border-[#333B51]"
                        : "border"
                    } rounded-md px-2 py-2  gap-1`}
                  >
                    <img src="/cdn/images/bike.svg" alt="" />
                    {/* <input type="radio" className='bg-white border border-white py-2 ' placeholder='Motorcycle' /> */}
                    <label
                      htmlFor=""
                      className={` py-2 font-satoshi text-[16px] ${
                        inventoryData?.shipping_mode === "Bike"
                          ? "text-[#333B51]"
                          : "text-gray-4"
                      }`}
                    >
                      Motorcycle
                    </label>
                  </div>
                  <div
                    className={`col-span-1 flex flex-row ${
                      inventoryData?.shipping_mode === "Van"
                        ? "border border-[#333B51]"
                        : "border"
                    } rounded-md px-2 py-2 border gap-1`}
                  >
                    <img src="/cdn/images/van.svg" alt="" />
                    {/* <input type="radio" className='bg-white border border-white py-2 '  /> */}
                    <label
                      htmlFor=""
                      className={`${
                        inventoryData?.shipping_mode === "Van"
                          ? "text-[#333B51]"
                          : "text-gray-4"
                      } py-2 font-satoshi text-[16px]`}
                    >
                      Van
                    </label>
                  </div>
                </div>
              </div>

              {/* <div className={`flex flex-col w-full gap-2 ${inventoryData?.variations.length !==0 ? 'border':'border border-[#F77B55]'} rounded-md p-2`}> */}
              <div
                className={`flex flex-col w-full gap-2 ${
                  inventoryData?.variations !== null
                    ? "border"
                    : "border border-[#F77B55]"
                } rounded-md p-2`}
              >
                <p className="font-semibold text-[#333B51] font-satoshi">
                  Variants
                </p>
              </div>
              <div className="flex flex-row gap-2">
                {/* <button className='px-4 py-2 font-satoshi text-center text-[12px] md:text-[16px] bg-black text-white rounded-md'>Save</button> */}
                {inventoryData?.is_approved ? (
                  <button
                    onClick={unapproveInventory}
                    className="px-4 py-2 font-satoshi text-center text-[12px] md:text-[16px] bg-black text-white rounded-md"
                  >
                    Unapprove
                  </button>
                ) : (
                  <button
                    onClick={approveInventory}
                    className="px-4 py-2 font-satoshi text-center text-[12px] md:text-[16px] bg-black text-white rounded-md"
                  >
                    Approve
                  </button>
                )}
                <button
                  onClick={handleClickScroll}
                  className="px-4 py-2 font-satoshi text-center text-[12px] md:text-[16px] bg-black text-white rounded-md "
                >
                  Send for correction
                </button>
              </div>
            </div>
            <div className="sticky top-28 pt-4 flex flex-col w-full pr-2 md:pr-0 md:w-[20%] flex-[1] mb-3">
              <p className="text-[#19223B] font-satoshi text-md font-semibold">
                Price Details
              </p>
              <div className=" border border-gray-300 p-2 mt-2 mb-2 rounded-md  bg-white">
                <div className={`flex-col `}>
                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Buying/Merchant Price
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        min="0"
                        max="999999999"
                        value={inventoryData?.merchant_price}
                        className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                        <span className="text-gray-500">KSH</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Commission (%)
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        value={calculatePercentage(
                          inventoryData?.price,
                          inventoryData?.merchant_price
                        )}
                        className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                        <span className="text-gray-500">%</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Retail Price
                    </label>

                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        min="0"
                        max="999999999"
                        value={inventoryData?.price}
                        className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pl-3 p-2">
                        <span className="text-gray-500">KSH</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Units in stock
                    </label>
                    <div className="flex flex-row ">
                      <input
                        value={inventoryData?.stock_available}
                        type="number"
                        name=""
                        className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" border border-gray-300 p-2 mt-2 mb-2 rounded-md  bg-white">
                <div onClick={() => setSideBarDiscountStatus((open) => !open)}>
                  <div className="mt-2 mb-2">
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Discount price
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        min="0"
                        max="999999999"
                        value={inventoryData?.discount_price}
                        className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                        placeholder="13,499"
                      />
                      <div className="pointer-events-none absolute inset-y-0 right-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 flex items-center pl-3 p-2">
                        <span className="text-gray-500">KSH</span>
                      </div>
                    </div>
                  </div>
                </div>
                {sideBarDiscountStatus && (
                  <div className={`flex-col `}>
                    <div className="mt-2 mb-2">
                      <label
                        htmlFor="retail"
                        className="block text-sm font-satoshi text-gray-700"
                      >
                        Discount
                      </label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          readOnly
                          disabled
                          type="number"
                          min="0"
                          max="100"
                          value={calculatePercentage(
                            inventoryData?.price,
                            inventoryData?.discount_price
                          )}
                          className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 flex items-center pl-3 p-2">
                          <span className="text-gray-500">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-2 mb-2 rounded-md border border-gray-300 p-2 bg-white">
                <div className="flex flex-col">
                  <div
                    onClick={() => setSideBarWholesale((open) => !open)}
                    className="mt-2 mb-2"
                  >
                    <label
                      htmlFor="retail"
                      className="block text-sm font-satoshi text-gray-700"
                    >
                      Wholesale price
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                      <input
                        type="number"
                        min="0"
                        max="999999999"
                        value={inventoryData?.wholesale_price}
                        className="w-full p-1.5 border border-gray-200 bg-gray-100 outline-none appearance-none"
                      />
                      <div className="pointer-events-none absolute inset-y-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 right-0 flex items-center pl-3 p-2">
                        <span className="text-gray-500">KSH</span>
                      </div>
                    </div>
                  </div>
                  {sideBarWholesale && (
                    <>
                      <div className="mt-2 mb-2">
                        <label
                          htmlFor="retail"
                          className="block text-sm font-satoshi text-gray-700"
                        >
                          Minimum order quantity
                        </label>
                        <div className="relative ">
                          <input
                            value={inventoryData?.wholesale_moq}
                            type="text"
                            name=""
                            className="w-full p-1.5 border border-gray-200 bg-gray-200  outline-none"
                            placeholder="0"
                          />
                          <div className="pointer-events-none absolute inset-y-0 bg-white border border-t-gray-200 border-b-gray-200 border-r-gray-200 right-0 flex items-center pl-3 p-2">
                            <span className="">units</span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                id="invnotes"
                className={`${
                  openNotes ? "block" : "block"
                } flex flex-col gap-2`}
              >
                {displayLogs?.map((log) => (
                  <div className="flex flex-col py-2 w-full">
                    <div className="flex flex-row gap-2 w-full">
                      <div className="">
                        <Avatar
                          name={
                            log.user?.first_name
                              ? `${log.user?.first_name} ${log.user?.last_name}`
                              : log?.passport
                              ? `${log.passport?.merchant_name}`
                              : log.user?.email
                          }
                          size="50"
                          className="h-12 w-12 mr-2 rounded-full shadow"
                        />
                      </div>
                      <div className="flex flex-col gap-3 flex-1">
                        <div className="flex flex-row gap-2">
                          <p className="font-satoshi text-[#030A1A]  text-[18px]">
                            {log.user?.first_name
                              ? `${log.user?.first_name} ${log.user?.last_name}`
                              : log?.passport
                              ? `${log.passport?.merchant_name}`
                              : log.user?.email}
                          </p>

                          <span>.</span>

                          <p className="font-satoshi text-[#666C7C]  text-[18px]">
                            <Moment
                              date={log.date_created}
                              format="D MMM"
                              trim
                            />
                          </p>
                          <p className="font-satoshi text-[#666C7C]  text-[18px]">
                            <Moment
                              date={log.date_created}
                              format="hh:mm"
                              trim
                            />
                          </p>
                        </div>
                        <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md">
                          <p className="font-satoshi text-[#030A1A]  text-[14px]">
                            {log.system_message}
                          </p>
                        </div>
                        {log.message && (
                          <div className="flex flex-col px-2 py-2 bg-gray-100 rounded-md">
                            <p className="font-satoshi text-[#030A1A]  text-[14px]">
                              {log.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InventoryDetailsPage;
