import React from 'react'
import { useState } from 'react'
import ServiceCenters from '../components/service_center/ServiceCenters'
import Bookings from '../components/service_center/Bookings'
import ModalLayout from '../components/shared/ModalLayout'


const SERVICE_CENTERS_TAB = "Service centers"
const BOOKINGS_TAB = "Bookings"

function ServiceCentersPage() {
  const [activeMainTab, setActiveMainTab] = useState(BOOKINGS_TAB)




  const submitSearch = () => { }

  const handleCloseCreateServiceModal = () => {
  }

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectChange = (newValue, actionMeta) => {
    setSelectedOptions(newValue);
  };


  const [selectedSlots, setSelectedSlots] = useState([]);

  const handleSelectSlotChange = (newValue, actionMeta) => {
    setSelectedSlots(newValue);
  };

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const handleSelectEmployeeChange = (newValue, actionMeta) => {
    setSelectedEmployees(newValue);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#D3D3D3' : '#D3D3D3',
      '&:hover': {
        borderColor: state.isFocused ? '#D3D3D3' : '#D3D3D3',
      },
    }),
  };

  return (
    <>
      <div className="flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10  px-2 md:px-20">

        <div className="flex flex-row gap-2 mt-4  scrollbar-hide w-full">
          <div
            onClick={() => setActiveMainTab(BOOKINGS_TAB)}
            className={`whitespace-nowrap text-center cursor-pointer  text-xl font-semibold  py-1 px-3 rounded-md font-satoshi ${activeMainTab === BOOKINGS_TAB ? 'text-red-500 underline underline-offset-4 decoration-2' : ''}`}> Bookings

          </div>
          <div
            onClick={() => setActiveMainTab(SERVICE_CENTERS_TAB)}
            className={`whitespace-nowrap text-center cursor-pointer text-xl font-semibold   py-1 px-3 rounded-md font-satoshi ${activeMainTab === SERVICE_CENTERS_TAB ? 'text-red-500 underline underline-offset-4 decoration-2' : ''}`}>Service centers
          </div>
        </div>
        {activeMainTab === BOOKINGS_TAB && <Bookings/>}
        {activeMainTab === SERVICE_CENTERS_TAB && <ServiceCenters/>}
      </div>
    </>
  )
}

export default ServiceCentersPage