import React, { useState } from 'react'
import ModalLayout from '../components/shared/ModalLayout'
import ExcelInvoice from '../components/ExcelInvoice'

const GenerateInvoice = () => {
    const [openModal, setOpenModal] = useState(false)

    const handleClose = () => {
        setOpenModal(false)
    }


  return (
    <>
    <div className=" pl-4 md:px-20 flex flex-col w-full h-full overflow-y-scroll hide-scrollbar gap-4 pt-16 md:pt-20 pb-10 font-satoshi">
    <div className="flex flex-col gap-2 w-full">
        <div className="flex">
            <button 
            onClick={() => setOpenModal(true)}
            className='bg-gray-200 px-4 py-2 rounded-md text-center'>
                Create invoice
            </button>
        </div>
    </div>
    </div>
    {openModal &&
        <ModalLayout open={openModal} handleClose={handleClose}>
                <ExcelInvoice/>
        </ModalLayout>
      }
      
    </>
  )
}

export default GenerateInvoice



