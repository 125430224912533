import React, { useEffect, useState } from 'react'
import Select from "react-select"
import { makeAuthenticatedRequest } from '../../../utils'
import { toast } from 'react-toastify'

const CreateServiceJob = ({handleCloseModal,order,fetchOrder}) => {
  const [service_type, setServiceType] = useState()
  const [customer, setCustomer] = useState()
  const [mechanic, setMechanic] = useState()
  const [service_center, setServiceCenter] = useState()
  const [service_needed, setServiceNeeded] = useState()
  const [price, setPrice] = useState()
  const [parts_cost, setPartsCosts] = useState()
  const [service_cost, setServiceCosts] = useState()
  const [date, setDate] = useState(new Date())
  const [start_time, setStartTime] = useState()
  const [end_time, setEndTime] = useState()
  const [car_number_plate, setCarNumberPlate] = useState()
  const [loading, setLoading] = useState(false)


  const [data, setData] = useState(null)
  const [mechanics, setMechanics] = useState(null)
  const [centers, setCenters] = useState(null)


  const handleOnchange = (seState) => (e) => {
    seState(e.target.value)
  }

  const fetchServiceCenters = () => {
    const params = {
      "per_page": 100,
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/service_centers?${searchParams}`)
        .then((response) => response.json())
        .then((data) => {
          const service_centers = data?.results?.map((service_center) => {
            return {
              value: service_center?.id,
              label: service_center?.name,
            }
          })
          setCenters(service_centers)
          
        })
        .catch((err) => {

            console.error('err', err)
        })
}

  const fetchData = () => {
    const params = {
      "per_page": 100,
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/consumers/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        const consumers = data?.results?.map((user) => {
          return {
            value: user.id,
            label: `${user?.name?user?.name:user?.first_name?user?.first_name:""} - +${user?.phone_number}`,
          }
        })
        setData(consumers)
      });
  }

  const fetchMechanics = () => {
    const params = {
      "per_page": 100,
    }
    const searchParams = new URLSearchParams(params).toString();
    makeAuthenticatedRequest(`/oms/mechanics/?${searchParams}`)
      .then((response) => response.json())
      .then((data) => {
        const mechs = data?.results?.map((user) => {
          return {
            value: user.id,
            label: `${user?.name?user?.name:user?.first_name?user?.first_name:""} - +${user?.phone_number}`,
          }
        })
        setMechanics(mechs)
      });
  }

  

  useEffect(() => {
    fetchData()
    fetchMechanics()
    fetchServiceCenters()
  },[])

  const handleSelectType = (option) => {
    setServiceType(option)
  }

  const handleSelectCustomer = (option) => {
    setCustomer(option)
  }

  const handleSelectCenter = (option) => {
    setServiceCenter(option)
  }
  const handleSelectMechanic = (option) => {
    setMechanic(option)
  }
  const OPTIONS = [
    { value: 'mech', label: 'Mechanic service' },
    { value: 'service_center', label: 'Service center job' },
  ]
// service_center-jobs/order/<str:id>/add-job/

const createMechanicJob = async () => {
  setLoading(true)
  if(!date || !start_time || !end_time || !car_number_plate || !price || !customer || !mechanic){
    toast.error('Please fill all the fields')
    setLoading(false)
    return
  }
  const payload = {
    mechanic:mechanic?.value,
    consumer:customer?.value,
    date_started:`${date}T${start_time}:00`,
    date_completed:`${date}T${end_time}:00`,
    price:price,
    status:"Completed",
    car:{"car_number_plate":car_number_plate},
  }
  // console.log('payload',payload)
  try {
    const res = {}
    await makeAuthenticatedRequest(`/oms/services/service_center-jobs/order/${order?.order_id}/add-job/`, "POST", JSON.stringify(payload))
    // if (!res.ok) {
    //   setLoading(false)
    //   handleCloseModal()
    //   throw new Error('Service creation failed.')
    // }
    const data = await res.json();
    setLoading(false)
    toast.success('Service added successfully')
    fetchOrder()
    handleCloseModal()
  } catch (error) {
    setLoading(false)
    toast.error('Service could not be added.')
  }
} 
  const createServiceCenterJob = async () => {
    setLoading(true)
    if(!date || !start_time || !end_time || !car_number_plate || !service_cost || !parts_cost || !customer || !service_center){
      toast.error('Please fill all the fields')
      setLoading(false)
      return
    }
    const payload = {
      appointment_date:`${date}T${start_time}:00`,
      service_center:parseInt(service_center?.value),
      car_number_plate:car_number_plate,
      start_time:`${date}T${start_time}:00`,
      end_time:`${date}T${end_time}:00`,
      service_cost:service_cost,
      parts_cost:parts_cost,
      status:"O",
      service_needed:service_needed,
      customer:customer?.value,
      car_specs:{"car_number_plate":car_number_plate},
    }
    // console.log('payload',payload)
    try {
      const res = await makeAuthenticatedRequest(`/oms/services/service_center-jobs/order/${order?.order_id}/add-serice-center-job/`, "POST", JSON.stringify(payload))
      // if (!res.ok) {
      //   setLoading(false)
      //   handleCloseModal()
      //   throw new Error('Service creation failed.')
      // }
      const data = await res.json();
      setLoading(false)
      toast.success('Service added successfully')
      fetchOrder()
      handleCloseModal()
    } catch (error) {
      setLoading(false)
      toast.error('Service could not be added.')
    }
}   

  // mechanic, consumer, date_started, date_completed, price, car
  // appointment_date, service_center, car_number_plate, start_time, end_time, service_cost, parts_cost, status, service_needed, customer, car_specs
  return (
    <div className='w-full flex flex-col gap-2'>
      <Select
      value={service_type}
      onChange={handleSelectType}
      className='w-full outline-none'
      placeholder="Select service type"
      options={OPTIONS}
      />
      <div className="flex flex-col w-full gap-2">
      {service_type?.value === "mech" &&
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Mechanic</label>
          <Select
          value={mechanic}
          className='w-full outline-none'
          placeholder="Select mechanic"
          onChange={handleSelectMechanic}
          options={mechanics}
          />
        </div>}
        {(service_type?.value === "mech" || service_type?.value === "service_center") &&
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Customer</label>
          <Select
          value={customer}
          className='w-full outline-none focus:outline-none'
          placeholder="Select customer"
          onChange={handleSelectCustomer}
          options={data}
          />
        </div>}
        {service_type?.value === "service_center" &&

        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Service center</label>
          <Select
          value={service_center}
          className='w-full outline-none focus:outline-none'
          placeholder="Select service center"
          onChange={handleSelectCenter}
          options={centers}
          />
        </div>}
        {(service_type?.value === "mech" || service_type?.value === "service_center") &&
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">

        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Service date</label>
          <input type="date"  
          value={date}
          onChange={handleOnchange(setDate)}
          className='outline-none border rounded-md border-gray-200 focus:outline-none'/>
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Service start time</label>
          <input type="time" 
          value={start_time}
          onChange={handleOnchange(setStartTime)} 
          className='outline-none border rounded-md border-gray-200 focus:outline-none'/>
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Service end time</label>
          <input type="time"
          value={end_time}
          onChange={handleOnchange(setEndTime)}  
          className='outline-none border rounded-md border-gray-200 focus:outline-none'/>
        </div>
        </div>
        }
        {service_type?.value === "mech" &&
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Price</label>
          <input type="number"
                    value={price}
                    onChange={handleOnchange(setPrice)}    
          className='outline-none border rounded-md border-gray-200 focus:outline-none'/>
          
        </div>}

        {service_type?.value === "service_center" &&
        <div className="flex flex-col gap-1 w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 2-full gap-2">
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Parts cost</label>
          <input type="number"  
                    value={parts_cost}
                    onChange={handleOnchange(setPartsCosts)}  
          className='outline-none border rounded-md border-gray-200 focus:outline-none'/>
        </div>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Service cost</label>
          <input type="number"  
                    value={service_cost}
                    onChange={handleOnchange(setServiceCosts)}  
          className='outline-none border rounded-md border-gray-200 focus:outline-none'/>
        </div>
          </div>
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Service needed</label>
          <textarea rows={3}  
                    value={service_needed}
                    onChange={handleOnchange(setServiceNeeded)}  
          className='outline-none border rounded-md border-gray-200 focus:outline-none'/>
        </div>
        </div>
        }
        {(service_type?.value === "mech" || service_type?.value === "service_center")  &&
        <div className="flex flex-col gap-1 w-full">
          <label htmlFor="" className='text-gray-400 text-[14px]'>Car number plate</label>
          <input type="text"
          placeholder='KDN330Z'
                    value={car_number_plate}
                    onChange={handleOnchange(setCarNumberPlate)}    
          className='outline-none border rounded-md border-gray-200 focus:outline-none'/>
          
        </div>}
        <div className="flex flex-row-reverse w-full justify-between items-center gap-2">
          
          <button
          onClick={service_type ==="mech"?createMechanicJob:createServiceCenterJob}
          disabled={loading}
           className='text-center font-satoshi bg-slate-800 text-white rounded-md px-6 py-2'>{loading?"Saving...":"Save"}</button>
        </div>
      </div>
    </div>
  )
}

export default CreateServiceJob