import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import back from '../assets/ic_back.svg'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function LeadEditPage() {
    const { leadID } = useParams()

    const navigate = useNavigate()

    const [ leadFirstName, setLeadFirstName ] = useState('')
    const [ leadLastName, setLeadLastName ] = useState('')
    const [ leadPhoneNumber, setLeadPhoneNumber ] = useState('')
    const [ leademail, setLeademail ] = useState('')
    const [ leadPartServiceNeeded, setLeadPartServiceNeeded ] = useState('')
    const [ leadVehicleModel, setLeadVehicleModel ] = useState('')
    const [ leadVehicleEngineNo, setLeadVehicleEngineNo ] = useState('')
    const [ leadVehicleYOM, setLeadVehicleYOM ] = useState('')
    const [ leadVehicleChasisNo, setLeadVehicleChasisNo ] = useState('')
    const [ leadComment, setLeadComment ] = useState('')

    const [processing,setProcessing] = useState(false)

    const updateLead = () =>{

    }

  return (
    <>
    <div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-16 pb-10"> 
    <div className="flex flex-row">
        <div onClick={() => navigate(-1)}
        className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] text-[14px] rounded-lg">
        <img src={back} alt="" />
        Back
        </div>

    </div>
    <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 w-full md:w-[60%]">

            <div className="flex flex-col">
               
                  <div className="flex flex-row gap-2 justify-between">
                    
                      <h2 className='text-[#030A1A] font-satoshi font-bold text-[20px]'>
                        Edit lead
                      </h2>
                  </div>

                  <div className="flex flex-col gap-2">
                   <form onSave={updateLead} className='w-full flex flex-col gap-2'>
                   <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadFirstName" className='font-satoshi text-[14px]'>Lead first name</label>
                      <input required name='leadFirstName' 
                      placeholder='Add lead name'
                      value={leadFirstName}
                      onChange={(e) => setLeadFirstName(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadLastName" className='font-satoshi text-[14px]'>Lead last name</label>
                      <input required name='leadLastName' 
                      placeholder='Add lead lastname'
                      value={leadLastName}
                      onChange={(e) => setLeadLastName(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadPhoneNumber" className='font-satoshi text-[14px]'>Lead phone number</label>
                      <input required name='leadPhoneNumber' 
                      type="text" 
                      placeholder='Lead phone number'
                      value={leadPhoneNumber}
                      onChange={(e) => setLeadPhoneNumber(e.target.value)}
                      className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>


                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leademail" className='font-satoshi text-[14px]'>Lead email</label>
                      <input  name='leademail' 
                      placeholder='Lead email'
                      value={leademail}
                      onChange={(e) => setLeademail(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadPartServiceNeeded" className='font-satoshi text-[14px]'>Lead part / service needed</label>
                      <input  name='leadPartServiceNeeded' 
                      placeholder='Lead part / service needed'
                      value={leadPartServiceNeeded}
                      onChange={(e) => setLeadPartServiceNeeded(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadVehicleModel" className='font-satoshi text-[14px]'>Lead vehicle model</label>
                      <input  name='leadVehicleModel' 
                      placeholder='Lead vehicle model'
                      value={leadVehicleModel}
                      onChange={(e) => setLeadVehicleModel(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadVehicleEngineNo" className='font-satoshi text-[14px]'>Lead vehicle engine no</label>
                      <input  name='leadVehicleEngineNo' 
                      placeholder='Lead vehicle engine nor'
                      value={leadVehicleEngineNo}
                      onChange={(e) => setLeadVehicleEngineNo(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadVehicleYOM" className='font-satoshi text-[14px]'>Lead vehicle YOM</label>
                      <input  name='leadVehicleYOM' 
                      placeholder='Lead vehicle YOM'
                      value={leadVehicleYOM}
                      onChange={(e) => setLeadVehicleYOM(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadVehicleChasisNo" className='font-satoshi text-[14px]'>Lead vehicle chasis no</label>
                      <input  name='leadVehicleChasisNo' 
                      placeholder='Lead vehicle chasis no'
                      value={leadVehicleChasisNo}
                      onChange={(e) => setLeadVehicleChasisNo(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>

                    <div className="w-full flex flex-col gap-1">
                      <label htmlFor="leadComment" className='font-satoshi text-[14px]'>Comment</label>
                      <textarea  name='leadComment' 
                      placeholder='Comment'
                      value={leadComment}
                      onChange={(e) => setLeadComment(e.target.value)}
                      type="text" className='px-4 py-2 outline-none rounded-md border font-satoshi text-[16px]' />
                    </div>
              
                   </form>
                  </div>
                  <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                    onClick={updateLead}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>
                        {processing? 'Saving ...':'Save' }
                    </button>
                  

                </div>
                </div>
            </div>
        </div> 
    </div>

    <ToastContainer

    position="bottom-center"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    />
    </>
  )
}

export default LeadEditPage