import React from 'react'
import ProductView from '../components/cms/Product/ProductView'

function ProductViewPage() {
  return (
    <div>
      <ProductView/>
    </div>
  )
}

export default ProductViewPage
