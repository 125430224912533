import Moment from "react-moment";
import React, { useState, useEffect } from "react";
import clockDanger from "../../assets/watch-2.svg";
import { ENDPOINT_ORDERS } from "../../routes";
import { useNavigate } from "react-router-dom";
import {
  formatNumber,
  makeAuthenticatedRequest,
  getLocalStoreItem,
} from "../../utils";
import { toast } from "react-toastify";
import { API_PREFIX, LS_ACCOUNT_ID, LS_ACCESS_TOKEN } from "../../constants";

export const RecentOrders = ({ customerID }) => {
  const userId = getLocalStoreItem(LS_ACCOUNT_ID);
  const token = getLocalStoreItem(LS_ACCESS_TOKEN);
  const key =
    token.substring(0, 4) + token.substring(token.length - 4, token.length);
  const navigate = useNavigate();

  const [orders, setOrders] = useState(null);
  const [processing, setProcessing] = useState(true);

  const fetchOrders = async (page) => {
    try {
      setProcessing(true);

      const url = `/oms/customers/${customerID}/orders/?&page=1&per_page=5`;
      const response = await makeAuthenticatedRequest(url);
      const { results } = await response.json();
      setProcessing(false);
      setOrders(results);
    } catch (error) {
      toast.error("Something went wrong while getting recent orders!");
      setProcessing(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <>
      <div className="border border-[#E5E6E9] rounded-lg py-2 shadow-sm">
        <div className="flex flex-row justify-between">
          <p className="font-satoshi text-[#030A1A] px-2 py-2 mr-2 font-semibold text-[18px]">
            Recent Orders
          </p>

          <a
            href={`${API_PREFIX}/oms/customers/${customerID}/orders/export/?id=${userId}&key=${key}`}
            className="rounded whitespace-nowrap text-center bg-[#E5E6E9]  cursor-pointer py-1 px-3 mb-2 mr-4 font-satoshi text-[#808491] hover:bg-[#cdced3]"
          >
            Export
            <span className="p-1 ml-2 text-[#b0b6c7] text-xs">
              <svg
                className="w-5 h-5 inline"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Download-2</title>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g id="Download-2">
                    <rect
                      id="Rectangle"
                      fill-rule="nonzero"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>
                    <line
                      x1="12"
                      y1="14"
                      x2="12"
                      y2="20"
                      id="Path"
                      stroke="#0C0310"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></line>
                    <path
                      d="M15,19 L12.7071,21.2929 C12.3166,21.6834 11.6834,21.6834 11.2929,21.2929 L9,19"
                      id="Path"
                      stroke="#0C0310"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                    <path
                      d="M19.9495,16 C20.5978,15.3647 21,14.4793 21,13.5 C21,11.567 19.433,10 17.5,10 C17.3078,10 17.1192,10.0155 16.9354,10.0453 C16.4698,6.63095 13.5422,4 10,4 C6.13401,4 3,7.13401 3,11 C3,12.9587 3.80447,14.7295 5.10102,16"
                      id="Path"
                      stroke="#0C0310"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
          </a>
        </div>
        <div className="border-b w-full border-[#E5E6E9]"></div>
        <div className="flex flex-col m-2">
          <div className="flex flex-col ">
            {orders && !processing ? (
              <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg  mt-4">
                <table class="table-auto w-full  ">
                  <thead className="pl-2">
                    <tr className="pl-1 border-b border-gray-200">
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                        Order #
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                        Date
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                        Customer
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                        Purchased
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                        Order Total(Ksh)
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                        Courier
                      </th>
                      <th className="pl-2 pb-1 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => (
                      <tr
                        className="p-2 border-b border-b-gray-200 relative"
                        key={order?.order_id}
                      >
                        <td
                          onClick={() =>
                            navigate(`${ENDPOINT_ORDERS}/${order.id}`)
                          }
                          className="p-4 text-xs md:text-[16px] text-left cursor-pointer font-satoshi"
                        >
                          <span className="hover:underline">
                            #{order.order_id}
                          </span>
                        </td>
                        <td className="p-4  flex-col text-left font-satoshi text-[#808491]">
                          <div className=" text-[10px] md:text-[14px] ">
                            <Moment format="MMM, DD LT" className="flex">
                              {order.date_created}
                            </Moment>
                          </div>
                          <div className="flex">
                            {order.status &&
                              (order.status !== "Refunded" ||
                                order.status !== "Complete" ||
                                order.status !== "Cancelled" ||
                                order.status !== "Delivered" ||
                                order.status !== "To be replaced" ||
                                order.status !== "To be returned") && (
                                <div className="flex pt-1">
                                  <img
                                    src={clockDanger}
                                    className="h-2 md:h-4"
                                  />

                                  <Moment
                                    className="text-[10px] md:text-[14px] text-[#BF3054]"
                                    fromNow
                                  >
                                    {order.date_created}
                                  </Moment>
                                </div>
                              )}
                          </div>
                        </td>
                        <td className="p-4 text-xs md:text-[16px] text-left font-satoshi">
                          {order.passport.first_name
                            ? `${order.passport.first_name} ${order.passport.last_name}`
                            : order.passport.name}
                          <br />

                          <a
                            className="text-cyan-500 hover:underline"
                            href={`tel:+${order.passport.phone_number}`}
                          >
                            +{order.passport.phone_number}
                          </a>
                        </td>

                        <td className="p-4 text-xs md:text-[16px] text-left font-satoshi">
                          {order?.item_count === 1 &&
                            order?.cart &&
                            order.cart > 0
                            ? order.cart.lines[0].carpart.name
                            : formatNumber(order.item_count, 0, 0) + " items"}
                        </td>

                        <td className="p-4 text-xs md:text-[16px] text-left font-satoshi">
                          {order?.transaction.length > 0
                            ? "Ksh. " +
                            formatNumber(order?.transaction[0]?.amount, 0, 0)
                            : "-"}
                        </td>

                        <td className="p-4 text-xs md:text-[16px] text-left font-satoshi">
                          {order.delivery_partner ? (
                            <>
                              {order.delivery_partner.name}
                              <br />
                              <a
                                className="text-cyan-500 hover:underline"
                                href={`tel:+${order.delivery_partner.phone_number}`}
                              >
                                +{order.delivery_partner.phone_number}
                              </a>
                            </>
                          ) : (
                            <>-</>
                          )}
                        </td>


                        <td className="text-xs text-left font-satoshi">
                          <div
                            className={`
                            ${order?.status === "New"
                                ? "bg-[#B9E38D] text-[#333B51]"
                                : order?.status === "Cancelled"
                                  ? "bg-[#DE6972] text-white"
                                  : order?.status === "Confirmed"
                                    ? "bg-[#54D3CA] text-white"
                                    : // : orderData?.status === 'Complete' ? 'bg-[#54D3CA] text-white'

                                    order?.status === "Dispatched"
                                      ? "bg-[#8CD1FC] text-white"
                                      : order?.status === "Delivered"
                                        ? "bg-[#3E5DDD] text-white"
                                        : order?.status === "To be returned"
                                          ? "bg-[#FCB28E] text-white"
                                          : order?.status === "To be replaced"
                                            ? "bg-[#F0A6A5] text-white"
                                            : order?.status === "Complete"
                                              ? "bg-[#059D5F] text-white"
                                              : order?.status === "To be repaid"
                                                ? "bg-[#576298] text-white"
                                                : "bg-[#576298] text-white"
                              }
               rounded text-center relative capitalize font-satoshi text-xs px-2 py-1`}
                          >{order?.status}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                {!processing && (
                  <p className="font-satoshi text-[18px] py-6">
                    No recent orders to display
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
