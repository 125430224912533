import React, { useEffect, useState } from 'react'
import { useRef } from 'react'

function ModalLayout(props) {
    const [showModal, setShowModal] = useState(props.open)

    const cancelRef = useRef()
    useEffect(() => {
        let handler = e => {
          if (cancelRef.current && !cancelRef.current.contains(e.target)) {
    
            
            setShowModal(false)
    
            // console.log(cancelRef.current)
          }
        }
        document.addEventListener('mousedown', handler);
    
        return () => {
          document.removeEventListener('mousedown', handler);
        }
    
      })

    const handleClose = () => {
        setShowModal(false)
        props.handleClose()
        // close
    }  
      
  return (
    <>

{showModal
  ? (
    <>
      <div className='min-h-h-screen flex justify-center items-center overflow-x-hidden overflow-y-auto py-10 fixed inset-0 z-[100] outline-none focus:outline-none'>
        {/* <div className='relative p-2 w-full max-w-lg h-full md:h-auto'> */}
          <div  className='border-0 rounded-lg shadow-lg relative flex flex-col w-[90%] md:w-auto md:min-w-[50%] bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-between p-2 rounded-t '>
              <p className='text-black font-semibold font-satoshi'>{props.title}</p>
              <button
                className='bg-transparent border-0 text-black float-right p-2'
                onClick={handleClose}
              >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
              </button>
            </div>
           <div className="w-full p-6">
            {props.children}
           </div>
          </div>
        {/* </div> */}
      </div>
      <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-70  fixed inset-0 z-50' />
    </>
    )
  : null}
</>
  )
}

export default ModalLayout