import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import ReactLoading from 'react-loading';
import { axiosCustom, formatNumber } from '../../utils';
import Moment from 'react-moment';
import { Link, useParams } from 'react-router-dom';
import { MARKETPLACE_HOME_URL } from '../../constants'

const REQUEST_URL = `/oms/customers/part-requests/`;


function PartRequestDetailsTab() {
    const { partRequestID } = useParams();
    const [data, setData] = useState()
    const [processing, setProcessing] = useState(false)
    const [fullName, setFullName] = useState()
    const [email, setEmail] = useState()

    const fetchData = async () => {
        setProcessing(true)
        try {
            const response = await axiosCustom.get(`${REQUEST_URL}${partRequestID}`);
            setData(response?.data)
        } catch (err) {
            toast.error("Something went wrong");
        } finally {
            setProcessing(false)
        }
    }


    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {processing ?
                <ReactLoading className="mb-10" type="bubbles" color="#030A1A" height={50} width={100} />
                :
                <section>
                    <div class="mx-auto max-w-screen-2xl">
                        <div class="mt-4 relative">
                            <div className="flex flex-col md:grid grid-cols-6 mt-4">
                                <div className="col-span-6 w-full">
                                    <div className='w-full flex flex-row justify-between items-start md:items-center mt-3 mb-3'>
                                        <div className='mr-2 flex flex-col gap-2 md:flex-row justify-start items-start md:items-end'>
                                            <span className='text-gray-400 text-sm font-satoshi ml-0 md:ml-1'>Ref #{data?.reference_number}</span>
                                            <span
                                                className={'text-xs px-2 py-1 rounded text-center text-black font-extrabold ' + (
                                                    data?.status === 'New' ?
                                                        'bg-[#B9E38D]'
                                                        : data?.status === 'New' ?
                                                            'bg-[#B9E38D]'
                                                            : data?.status === 'Confirmed' ?
                                                                'bg-[#54D3CA]'
                                                                : data?.status === 'Dispatched' ?
                                                                    'bg-[#8CD1FC]'
                                                                    : data?.status === 'Delivered' ?
                                                                        'bg-[#3E5DDD]'
                                                                        : data?.status === 'Requires prepayment' ?
                                                                            'bg-[#576298]'
                                                                            : data?.status === 'To be exchanged' ?
                                                                                'bg-[#F0A6A5]'
                                                                                : data?.status === 'Exchange complete' ?
                                                                                    'bg-[#059D5F]'
                                                                                    : data?.status === 'Rejected' ?
                                                                                        'bg-[#FCB28E]'
                                                                                        : data?.status === 'Reject Completed' ?
                                                                                            'bg-[#F0A6A5]'
                                                                                            : data?.status === 'Complete' ?
                                                                                                'bg-[#059D5F]'
                                                                                                : data?.status === 'Cancelled' ?
                                                                                                    'bg-[#DE6972]'
                                                                                                    :
                                                                                                    'bg-[#FCB28E]')}>
                                                {data?.status}
                                            </span>
                                            <p className='font-satoshi text-gray-500 text-sm '>
                                                <Moment format="MMM, DD LT" className='flex'>
                                                    {data?.date_created}
                                                </Moment>
                                            </p>
                                        </div>
                                    </div>


                                    <div className="w-full border border-gray-200 rounded">



                                        <p className='text-md py-3 px-2 font-satoshi text-black font-semibold'>Part </p>
                                        <div className="flex flex-col px-2 gap-3 border-y w-full py-3 justify-between">
                                            <div className='flex items-center justify-between flex-[6]'>
                                                <div className='flex gap-1 items-center flex-[4]'>
                                                    <div className="p-1 flex-[0.5]">
                                                        {
                                                            data?.image_urls && data?.image_urls?.length > 0 ?
                                                                <img src={data?.image_urls?.[0]} class="border border-slate-300 shadow-md object-cover rounded-md h-16 w-16" />
                                                                :
                                                                <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                                                                    <p className='font-satoshi text-[#F77B55] text-[14px] text-center'>No images</p>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className='flex items-center flex-[3.5] flex-wrap'>
                                                        <div className='flex flex-col gap-2'>
                                                            <p className='font-semibold text-sm text-left line-clamp-2 md:w-3/4'>
                                                                {data?.name}
                                                            </p>
                                                            <div className='flex gap-2 text-gray-400 text-[11px] text-left items-center'>
                                                                <span className=''>
                                                                    {data?.description}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex justify-end py-4 flex-[2]">

                                                    {data?.vehicle_tags &&

                                                        <div className="flex flex-col">
                                                            <div className="flex flex-row gap-4 p-2">
                                                                <div className=" border border-gray-200 rounded-md p-1">
                                                                    {
                                                                        data?.vehicle_tags?.photo_url ?
                                                                            <img src={data?.vehicle_tags?.photo_url} class="border border-slate-200 object-cover rounded-md h-16 w-16" />
                                                                            :
                                                                            <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                                                                                <p className='font-satoshi text-[#F77B55] text-[14px] text-center'>No image</p>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className="flex flex-col">
                                                                    <p className='text-sm font-satoshi text-black font-semibold'>{data?.vehicle_tags?.make} {data?.vehicle_tags?.model} {data?.vehicle_tags?.engineType} {data?.vehicle_tags?.year}</p>
                                                                    <p className='text-sm font-satoshi text-gray-400'>{data?.vehicle_tags?.chasis}</p>
                                                                    <p className='text-sm font-satoshi font-semibold text-gray-400'>{data?.vehicle_tags?.numberPlate}</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full py-4">
                                        <div className="flex flex-row gap-3">
                                            <div className="border border-gray-200 px-3 py-4  w-full rounded-md ">
                                                <div className="flex flex-col">

                                                    <p className='font-satoshi text-md text-black font-semibold'>Request Details </p>
                                                    <div className="border-b border-gray-300 w-full py-1 "></div>

                                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col mb-2 mt-3">
                                                        <p className='font-satoshi text-left text-sm font-semibold text-gray-400'>Quantity</p>
                                                        <p className='font-satoshi text-left text-sm'>{data?.quantity}</p>
                                                    </div>

                                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex-col mb-2">
                                                        <p className='font-satoshi text-left text-sm font-semibold text-gray-400'>Condition</p>
                                                        <p className='font-satoshi text-left text-sm'>{data?.condition}</p>
                                                    </div>
                                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col mb-2">
                                                        <p className='font-satoshi text-left text-sm font-semibold text-gray-400'>Part Number</p>
                                                        <p className='font-satoshi text-left text-sm'>{data?.part_number ? data?.part_number : '-'}</p>
                                                    </div>

                                                    <div className="grid grid-cols-2 col-span-1 gap-3 flex flex-col mb-2">
                                                        <p className='font-satoshi text-left text-sm font-semibold text-gray-400'>Model/VIN Number</p>
                                                        <p className='font-satoshi text-left text-sm'>{data?.vin_number ? data?.vin_number : '-'}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="border border-gray-200 px-3 py-4 w-full rounded-md ">
                                                <div className="flex flex-col">
                                                    <p className='font-satoshi text-md text-black font-semibold'>Offers </p>
                                                    <div className="border-b border-gray-300 w-full py-1 "></div>
                                                    <div className="flex flex-col py-2 gap-1">
                                                        <p>No offers yet</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="p-3 mt-4 mb-4 bg-white border border-slate-200 shadow-sm  sm:rounded-lg">
                                        <p className='font-satoshi text-md text-black font-semibold'>Tagged Merchants </p>
                                        <div className="border-b border-gray-300 w-full py-1 "></div>

                                        <div className="py-2 flex flex-col gap-3">
                                            {
                                                data?.merchants && data?.merchants?.length ?
                                                    <div className='grid grid-cols-2 gap-4 p-1'>
                                                        {
                                                            data?.merchants?.map((item, i) => (
                                                                <div className="flex flex-col">
                                                                    <a target="_blank" href={`${MARKETPLACE_HOME_URL}/merchant/${item?.id}`} className="flex flex-row gap-4 p-2">
                                                                        <div className=" border boreder-gray-200 rounded-md p-1">
                                                                            {
                                                                                item?.merchant_avatar ?
                                                                                    <img src={item?.merchant_avatar} class="border border-slate-200 object-cover rounded-md h-16 w-16" />
                                                                                    :
                                                                                    <div className="border border-[#F77B55] bg-[#FDF5F2] rounded-md h-16 w-16">
                                                                                        <p className='font-satoshi text-[#F77B55] text-[14px] text-center'>No merchant image</p>
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-col">
                                                                            <p className='text-sm font-satoshi text-black font-semibold'>{item?.merchant_name}</p>
                                                                            <p className='text-sm font-satoshi text-gray-400'>{item?.merchant_address}</p>
                                                                            <a href={`tel:+${item?.phone_number}`} className='text-sm hover:underline font-satoshi text-gray-600'>+{item?.phone_number}</a>

                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            ))}
                                                    </div>
                                                    :
                                                    <div className='py-2'>
                                                        <span className='text-sm'>No tagged merchants</span>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}
export default PartRequestDetailsTab