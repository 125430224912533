import React, { useEffect, useRef, useState } from 'react'

function ChangeStatusModal({ order: { status }, newStatus, order, handleClose, changeTo, handleSubmit, delPartners }) {
  const cancelRef = useRef()
  const [remarks, setRemarks] = useState()
  const [selectedReason, setSelectedReason] = useState()
  const [selectedPartner, setSelectedPartner] = useState();
  const [deliveryPartners, setDeliveryPartners] = useState(delPartners)


  useEffect(() => {
    if (deliveryPartners && deliveryPartners.length > 0) {
      setSelectedPartner(deliveryPartners[0].id)
    }
  }, [deliveryPartners])

  useEffect(() => {
    let handler = e => {
      if (!cancelRef.current.contains(e.target)) {
        handleClose();
        console.log(cancelRef.current)
      }
    }
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    }
  })

  const changeStatus = () => {
    handleSubmit(order, newStatus, remarks, selectedReason, selectedPartner)
    handleClose()
  }
  return (
    <>
      <div className='h-screen pt-14  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
        <div className='relative p-4 w-full md:w-[30%] h-full md:h-auto'>
          <div ref={cancelRef} className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
            <div className='flex items-start justify-end p-2 rounded-t '>
            </div>

            <div className='relative p-3 flex-auto'>
              <div className='px-4 pt-4 pb-3 w-full'>

                <div className="flex flex-col">
                  <div onClick={handleClose} className="">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <div className="flex flex-row gap-2 justify-between">

                    <div className="flex flex-row gap-2">
                      <img src="" alt="" />
                      <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                        Confirm status
                      </h2>
                    </div>

                  </div>

                  <div className="flex flex-col gap-2 py-2">
                    <p className="flex flex-row py-2 text-[16px] font-satoshi text-[#333B51]">
                      Are you sure you want to change the status of this
                    </p>
                    <div className="flex flex-row gap-1 text-[14px] font-satoshi text-[#333B51]">
                      order from
                      <>

                        <p className={`${status === 'New' ? 'bg-[#B9E38D] text-[#333B51]'
                          : status === 'Cancelled' ? 'bg-[#DE6972]'
                            : status === 'Confirmed' ? 'bg-[#54D3CA]'
                              : status === 'Dispatched' ? 'bg-[#8CD1FC]'
                                : status === 'Delivered' ? 'bg-[#3E5DDD]'
                                  : status === 'To be replaced' ? 'bg-[#F0A6A5]'
                                    : status === 'To be returned' ? 'bg-[#FCB28E]'
                                      : status === 'Complete' ? 'bg-[#059D5F]'
                                        : status === 'To be repaid' ? 'bg-[#576298]' : 'bg-[#576298]'} 
  
  text-[14px] font-satoshi rounded-md   text-white px-2`}>
                          {status}
                        </p>

                      </>
                      to
                      <>
                        <p className={`
  ${changeTo === 'New' ? 'bg-[#B9E38D] text-[#333B51]'
                            : changeTo === 'Cancelled' ? 'bg-[#DE6972]'
                              : changeTo === 'Confirmed' ? 'bg-[#54D3CA]'
                                : changeTo === 'Dispatched' ? 'bg-[#8CD1FC]'
                                  : changeTo === 'Delivered' ? 'bg-[#3E5DDD]'
                                    : changeTo === 'To be replaced' ? 'bg-[#F0A6A5]'
                                      : changeTo === 'To be returned' ? 'bg-[#FCB28E]'
                                        : changeTo === 'Complete' ? 'bg-[#059D5F]'
                                          : changeTo === 'To be repaid' ? 'bg-[#576298]' : 'bg-[#576298]'} 
  text-[14px] font-satoshi rounded-md bg-[#B9E38D]  text-white px-2 py-1`}>
                          {changeTo}
                        </p>
                      </>
                      ?
                    </div>
                  </div>

                  {newStatus == 'Cancelled' &&
                    <div className="flex flex-col gap-2 py-4">
                      <div className="flex flex-col gap-2">
                        <p className="text-[#666C7C] text-[13px]">
                          Please select a reason for the cancellation of this order
                        </p>
                      </div>
                      <div className="flex flex-col gap-2">

                        <select
                          className='outline-none py-2 rounded-md border border-[#E5E6E9] font-satoshi text-[16px]'
                          value={selectedReason}
                          onChange={e => selectedReason(e.target.value)}>
                          <option value="Unresponsive merchant">Unresponsive merchant</option>
                          <option value="Impatient customer">Impatient customer</option>
                          <option value="Unresponsive consumer">Unresponsive consumer</option>
                          <option value="Out of stock">Out of stock</option>
                          <option value="Other">Other</option>
                        </select>

                      </div>
                    </div>
                  }
                  <div className="flex flex-col gap-2">
                    <label htmlFor="" className='text-[#666C7C] text-[13px]'>Remarks</label>
                    <textarea name="" id="" cols="5" rows="4"
                      onChange={(event) => setRemarks(event.target.value)}
                      className='px-2 border flex flex-row gap-2 outline-none font-satoshi border-[#BF3054] text-black rounded-md w-full placeholder:text-black text-[14px]'>
                      {remarks}
                    </textarea>


                  </div>
                </div>
                <div className="flex flex-row-reverse w-full py-4 gap-2">
                  <button
                    onClick={changeStatus}
                    className='bg-[#19223B]  text-sm text-white px-6 py-2 font-satoshi rounded-md'>Continue</button>
                  <button
                    onClick={handleClose}
                    className='border  text-sm text-[#808491] px-6 py-2 font-satoshi rounded-md'>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={handleClose} modalbackdrop='' className='h-screen bg-gray-900/70 fixed inset-0 z-50' />
    </>
  )
}

export default ChangeStatusModal