import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { makeAuthenticatedRequest } from '../utils'
import { toast } from 'react-toastify';

const GRNDetailsPage = () => {
    const { grnID } = useParams()
    const navigate = useNavigate()
    const [processing, setProcessing] = useState(false)

    const [grnData, setGRNData] = useState([])

    const fetchGRN = () => {
        setProcessing(true)
        makeAuthenticatedRequest(`/oms/grns/${grnID}`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setGRNData(data)
                setProcessing(false)
            });
    }
    useEffect(() => {
        fetchGRN()
    }, [])

    // console.log('grnData',grnData)
  return (
    <>
    {
processing &&
<div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-60 flex flex-col items-center justify-center">
    <img src='/cdn/images/loading.gif' className='mr-3 cursor-pointer  h-24' alt='Sparepap Logo' />
    <h2 class="text-center text-white text-xl font-semibold">Processing...</h2>
</div>
}
<div className="flex w-full px-4 md:px-10 flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
<div className="flex flex-row justify-between">
            <div onClick={() => navigate(-1)}
                className="flex w-auto md:w-[6%] flex-row gap-1 cursor-pointer px-2 py-2 bg-[#F2F2F4] text-[14px] rounded-lg">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-4 mt-0.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                </svg>

                Back
            </div>

        </div>

        <div className="pt-2 w-full overflow-x-scroll hide-scrollbar rounded-lg border  mt-4">
                  <table class="table-auto w-full  ">
                    <thead className='pl-2'>
                      <tr className='pl-1 border-b border-gray-200'>
                        <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Name</th>
                        <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Vehicle</th>
                        <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Quantity</th>
                        <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Part No</th>
                        <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Price</th>
                        <th className='pl-2 py-2 font-satoshi text-xs md:text-[16px] tracking-wide text-left text-gray-400'>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                    {grnData?.grn_parts?.slice().reverse().map((part, index) => (
                              
                          <tr className='p-3 border-b border-b-gray-200 font-satoshi relative'>
                            <td
                            className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                               {part?.part_name}
                            </td>
                            
                          <td className='pl-2 text-xs md:text-[16px] flex-1 w-[40%]'>
                            <p className='text-[16px] text-gray-600'>
                            
                            {part?.vehicle.make}/{part?.vehicle.model}/{part?.vehicle.year} 
                            </p>
                            </td>
                            <td className='pl-2 text-xs md:text-[16px]'>
                            <p className='text-gray-500'>
                            {part?.quantity}
                            </p>
                            </td>
                          <td
                            className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                              {part?.part_number} 
                            </td>
                            <td
                            className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                               {parseFloat(part.price)}
                            </td>
                            <td
                            className='p-4 text-xs md:text-[16px] text-left font-satoshi text-[#808491]'>
                               {parseFloat(part?.total)}
                            </td>

                          </tr>
                      ))}

                    </tbody>
                  </table>
                  <div className="flex flex-row">

                  </div>
           
</div>
</div>

</>
  )
}

export default GRNDetailsPage