import { axiosCustom } from '../../../utils';
import { makeAuthenticatedRequest } from '../../../utils'

const CMS_HERO_URL = '/oms/cms/homepage-accessory-subsections/';
const ACCESSORIES_URL = `/oms/cms/homepage-accessories/`;

export const createAccessorySubsection = async (accessoryData) => {
    try {
        const response = await axiosCustom.post(CMS_HERO_URL, accessoryData);
        return response.data;
    } catch (error) {
        console.log("Error creating accessory subsection:", error);
    }
};

export const getAccessorySubsections = async () => {
    try {
        const response = await axiosCustom.get(CMS_HERO_URL);
        return response.data.results;
    } catch (error) {
        console.log("Error fetching accessory subsections:", error);
    }
};

export const updateAccessorySubsection = async (accessorySubsectionId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_HERO_URL}${accessorySubsectionId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("Error updating accessory subsection:", error);
    }
};

export const deleteAccessorySubsection = async (accessorySubsectionId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_HERO_URL}${accessorySubsectionId}/`);
        return response.data;
    } catch (error) {
        console.log("Error deleting hero:", error);
    }
};

export const getHomepageAccessories = async () => {
    try {
        const data = await makeAuthenticatedRequest(ACCESSORIES_URL)
        return data.json();
    } catch (error) {
        console.log("Error fetching brands:", error);
    }
}
