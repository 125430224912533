import { axiosCustom } from '../../../utils';
import { makeAuthenticatedRequest } from '../../../utils'

const CMS_HERO_URL = '/oms/cms/homepage-category-subsections/';
const CATEGORIES_URL = `/oms/cms/homepage-categories/`;

export const createCategorySubsection = async (categoryData) => {
    try {
        const response = await axiosCustom.post(CMS_HERO_URL, categoryData);
        return response.data;
    } catch (error) {
        console.log("Error creating category subsection:", error);
    }
};

export const getCategorySubsections  = async () => {
    try {
        const response = await axiosCustom.get(CMS_HERO_URL);
        return response.data.results;
    } catch (error) {
        console.log("Error fetching category subsections:", error);
    }
};

export const updateCategorySubsection   = async (categorySubsectionId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_HERO_URL}${categorySubsectionId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("Error updating category subsection:", error);
    }
};

export const deleteCategorySubsection  = async (categorySubsectionId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_HERO_URL}${categorySubsectionId}/`);
        return response.data;
    } catch (error) {
        console.log("Error deleting hero:", error);
    }
};

export const getHomepageCategories = async () => {
    try{
        const data = await makeAuthenticatedRequest(CATEGORIES_URL)
        return data.json();
    } catch(error){
        console.log("Error fetching brands:", error);
    }
  }
