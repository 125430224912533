import React from 'react'
import HeroView from '../components/cms/Hero/HeroView'

function HeroViewPage() {
  return (
    <div>
      <HeroView/>
    </div>
  )
}

export default HeroViewPage