import React from 'react'

import MechanicAdd from "../components/cms/Mechanic/MechanicAdd"

function MechanicsAddPage() {
  return (
    <div>
        <MechanicAdd/>
    </div>
  )
}

export default MechanicsAddPage