import React, { useEffect, useRef } from 'react'
import back from '../assets/ic_back.svg'
import disc from '../assets/disc.jpg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { formatNumber, makeAuthenticatedRequest } from '../utils'
import { toast, ToastContainer } from 'react-toastify'
import Avatar from 'react-avatar'
import Rating from '../components/Rating'
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import vehiclesList from '../components/Vehicles/list.json'
import Select from 'react-select'
import { ENDPOINT_JOBS } from '../routes'
import { Pagination } from '../components/Pagination'


const DETAILS_TAB ='details'
const DOCUMENTS_TAB ='documents'
const JOB_HISTORY ='Job history'
const COMPLAINTS_TAB ='complaints'
const EARNINGS_TAB ='earnings'
const CANCELLED_TAB = 'cancelled'

function MechanicsDetailPage() {
    let { userID } = useParams();
    const navigate = useNavigate()
    const [activeTab, setActiveTab] =useState(DETAILS_TAB)
    const [jobTab, setJobTab] =useState(JOB_HISTORY)
    const [jobs, setJobs] =useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(16)
    const [itemCount, setItemCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)


    const [mechanicData, setMechanicData] = useState(null)
    const [showDocToBeVerified, setShoDocToBeVerified] = useState('')
    const [rotationDeg, setRotationDeg] = useState(0)
    const [processing, setProcessing] = useState(false)
    const [verify, setVerify] = useState('Verify')
    const [suspend, setSuspend] = useState('Suspend account')

    const [bio, setBio] = useState('')
    const [idNumber, setIdNumber] = useState('')
    const [editContact, setEditContact] = useState(false)
    const [mechEmail, setMechEmail] = useState('')
    const [mechPhone, setMechPhone] = useState('')

    


    const [carSpecializations, setCarSpecializations] = useState([])
    const [carSpecializationsPrev, setCarSpecializationsPrev] = useState([])
    const [carSpecializationsToServer, setCarSpecializationsToServer] = useState([])


    const [services, setServices] = useState([])
    const [servicesPrev, setServicesPrev] = useState([])

    const [certifications, setCertifications] = useState([])

    const [certificationsPrev, setCertificationsPrev] = useState([])

    const [edit, setEdit] = useState(false)

    // const getJobs = (page) => {
    //   const params = {
    //     "page": page,
    //     "per_page": perPage
    //   }
  
    //   const searchParams = new URLSearchParams(params).toString();
    //   makeAuthenticatedRequest(`/oms/jobs/?${searchParams}`)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log(data)
    //       setJobs(data)
    //       setCurrentPage(data.page)
    //       setPerPage(data.per_page)
    //       setItemCount(data.count)
    //       setTotalItems(data.count)

    //     });
    // }
    
    const getJobs = (page) => {
      const params = {
        "page": page,
        "per_page": perPage
      }
  
      const searchParams = new URLSearchParams(params).toString();
      makeAuthenticatedRequest(`/oms/consumer/${userID}/jobs/?${searchParams}`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setJobs(data)
          setCurrentPage(data.page)
          setPerPage(data.per_page)
          setItemCount(data.count)
          setTotalItems(data.count)

        });
    }


    const pageChanged = (pageNumber) => {
      setCurrentPage(pageNumber)
      getJobs(pageNumber)
    }
    const loadVehicleOptions = vehiclesList.map((vehicle) => ({
      ...vehicle, 
      value:vehicle.make,
      label:vehicle.make}))

    const handleVerifyMech = () => {
      setVerify('Confirm')
      setTimeout(() => {
        setVerify('Verify')
      }, 4000);
  
    }

    const handleSuspendMech = () => {
      setSuspend('Confirm')
      setTimeout(() => {
        setSuspend('Suspend account')
      }, 4000);
  
    }

    console.log(verify)

    const rotateRight = () => {
        let newRotation = rotationDeg + 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }
        setRotationDeg(newRotation)
      }
      
    const  rotateleft = () =>{
        let newRotation = rotationDeg - 90;
        if(newRotation >= 360){
          newRotation =- 360
        }
        setRotationDeg(newRotation)
      }
    
      console.log('rotationDeg',rotationDeg)
    const cancelRef = useRef()


    useEffect(() => {
        let handler = e => {
          if (cancelRef.current && !cancelRef.current.contains(e.target)) {
    
            setShoDocToBeVerified('')
    
            console.log(cancelRef.current)
          }
        }
        document.addEventListener('mousedown', handler);
    
        return () => {
          document.removeEventListener('mousedown', handler);
        }
    
      })
    
      console.log('mechPhone',mechPhone)

    const fetchMechanic = (id) => {
        makeAuthenticatedRequest(`/oms/mechanic/${id}/`)
          .then((response) => response.json())
          .then((data) => {
            console.log('get',data)
            setMechanicData(data)
            setMechEmail(data?.email)
          });
      }

      const verifyMechanic = () => {
        const params = {
          is_mechanic_verified: true,
        }
        makeAuthenticatedRequest(`/oms/mechanic/${userID}/`, "POST", JSON.stringify(params))
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setMechanicData(data)
            setMechEmail(data?.email)

          });
      }

      const susMechanic = () => {
        const suspendParams = {
          is_mechanic_suspended: !mechanicData.is_mechanic_verified,
        }
        makeAuthenticatedRequest(`/oms/mechanic/${userID}/`, "POST", JSON.stringify(suspendParams))
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setMechanicData(data)
          });
      }


      const components = {
        DropdownIndicator: null,
     };


    // console.log('carSpecializationsToServer',carSpecializationsToServer)
    const updateMechanic = () => {
      if (carSpecializations.length > 0){

        const carSpecsToServer = [...new Set(carSpecializationsPrev.concat(carSpecializations))];
        setCarSpecializationsToServer(carSpecsToServer)
        
      }
      const updateparams = {
        mechanic_about: bio,
        mechanic_id_number: idNumber,
        id: userID,
        phone_number: mechanicData.phone_number,
        mechanic_vehicle_types: carSpecializationsToServer,
        // mechanic_service_types: services,
        // mechanic_certifications: certificationsPrev,
      }
    console.log('carSpecializationsToServer',carSpecializationsToServer)

      makeAuthenticatedRequest(`/oms/mechanic/${userID}/`, "PUT", JSON.stringify(updateparams))
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setMechanicData(data)
          setEdit(false)
          setBio(data.mechanic_about)
          setIdNumber(data.mechanic_id_number)
          setMechEmail(data?.email)
          setCarSpecializationsPrev(data.mechanic_vehicle_types)
          toast.success("Mechanic succesfully updated")
        });
    } 




      useEffect(() => {
        fetchMechanic(userID);
        getJobs(1)
      }, [])  
      
      useEffect(() => {
        setBio(mechanicData?.mechanic_about)
        setCertifications(mechanicData?.mechanic_certifications)
        setCarSpecializationsPrev(mechanicData?.mechanic_vehicle_types)
        setServicesPrev(mechanicData?.mechanic_service_types)
        setCertificationsPrev(mechanicData?.mechanic_certifications)
        setIdNumber(mechanicData?.mechanic_id_number)
        setMechEmail(mechanicData?.email)
        setMechPhone(mechanicData?.phone_number)



      },[mechanicData])

      const removeSelecteSPecializationPrev = (index) => {
        const list = [...carSpecializationsPrev];
        list.splice(index, 1);
        setCarSpecializationsPrev(list);
     }

     const removeServicePrev = (index) => {
      const list = [...servicesPrev];
      list.splice(index, 1);
      setServicesPrev(list);
   }

   const removeCertificationsPrev = (index) => {
    const list = [...certificationsPrev];
    list.splice(index, 1);
    setCertificationsPrev(list);
 }

 console.log('carSpecializationsToServer', carSpecializationsToServer)
  
  console.log(typeof(carSpecializationsToServer))

  console.log('mech',mechanicData)
  console.log('jobs',jobs)






  return (
    <>
    <div className=" px-4 md:px-20 flex w-full flex-col h-full max-h-auto md:max-h-screen overflow-y-scroll hide-scrollbar gap-4 pt-32 md:pt-20 pb-10">
    <div className="flex flex-row">
        <div onClick={() => navigate(-1)}
        className="flex flex-row gap-1 cursor-pointer px-2 py-1 bg-[#F2F2F4] text-[14px] rounded-lg">
        <img src={back} alt="" />
        Back
        </div>
    </div>
    <div className="flex flex-row w-full gap-4">
            <div className="">
              {mechanicData?.mechanic_thumbnail !== null ?
                <img src={mechanicData?.mechanic_thumbnail} className='rounded-full w-20 h-20 object-cover' alt="" />
                :
                <Avatar name={mechanicData?.mechanic_name} circle='true' size={45} className="rounded-full shadow" />

              }
            </div>
            <div className="flex-1 flex flex-col gap-1">
                <div className="flex flex-row gap-2">

        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{mechanicData?.mechanic_name}</p>
        {mechanicData?.is_mechanic_verified?
                <div className="flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 text-[#456DE6] h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                    </svg>
        

        <p className='font-satoshi text-[#456DE6] text-[16px] md:text-[16px] '>Verified</p>



                </div>
                :
                <div className="flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 text-red-300 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                    </svg>
        

              <p className='font-satoshi text-red-300 text-[16px] md:text-[16px] '>Suspended/Unverified</p>



                </div>

         
                 }
                </div>
                <Rating avarageRating={mechanicData?.mechanic_rating_count} />
 
        {mechanicData?.is_mechanic_verified ?
        <div
        onClick={suspend === 'Suspend account' ? handleSuspendMech : susMechanic}
         className="w-full md:w-[20%] cursor-pointer rounded-md px-4 py-1 bg-[#E5E6E9] text-center font-satoshi text-[16px]">{suspend}</div>
         :
         <button
         onClick={verify === 'Verify'? handleVerifyMech : verifyMechanic}

          className='w-full md:w-[20%] font-satoshi text-center px-4 py-2 rounded-md bg-[#E5E6E9] outline-none  text-[16px] md:text-[16px] '>
            {verify}
            </button>
        

        }

            </div>

        </div>
    
    <div className="flex flex-row gap-4 w-full md:w-[90%] ">
        <div className="flex flex-col w-full border rounded-lg gap-2 pt-1 pb-2">
          <div className="flex flex-row justify-between items-center">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Contacts</p>
        <div className="flex flex-row items-center px-2 gap-2">

          {editContact && 
          <div className="flex flex-row items-center gap-2">
        <button onClick={() => setEditContact(false)} className='border border-gray-200 items-center rounded-md py-1 px-4 text-center'>Cancel</button>
        <button  className='bg-gray-800 text-white text-center px-4 items-center rounded-md py-1'>Save</button>

          </div>
          }
        <button onClick={() => setEditContact(true)} className='bg-gray-200 items-center rounded-md py-1 px-4 text-center'>Edit</button>
        </div>
          </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-row w-full gap-4 px-2">
            
            <div className="flex-1 flex flex-col md:flex-row gap-6">
        <div className="flex flex-row gap-1 items-center">
            <div className="border border-red-400 rounded-full p-1.5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="text-red-400 w-3 h-3">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
            </svg>

            </div>
        +<input type="text" onChange={(e) => setMechPhone(e.target.value)}  placeholder={mechanicData?.phone_number} value={mechPhone} className={`${editContact? "border border-gray-200 rounded-md p-2 outline-none":""} `} />           

        {/* <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>+{mechanicData?.phone_number}</p> */}

        </div>

        <div className="flex flex-row gap-1 items-center">
        <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px] '>Email:</p> 
        <input onChange={(e) => setMechEmail(e.target.value)} type="text" placeholder={mechanicData?.email} value={mechEmail} className={`${editContact? "border border-gray-200 rounded-md p-2 outline-none":""} `} />           
        {/* <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] '>{mechanicData?.email}</p> */}

        </div>

                
            </div>

        </div>

        
        </div>


    </div>
    <div className="flex flex-col w-full md:w-[90%] border rounded-lg gap-2 pt-1 pb-2">
        <div className="flex flex-row gap-2 justify-between">
        <div className="flex flex-row gap-2">
        <p 
        onClick={() => setActiveTab(DETAILS_TAB)}
        className={` font-satoshi ${activeTab === DETAILS_TAB ? ' text-[#D95762] underline underline-offset-[14px]':'text-[#999DA8]' } cursor-pointer px-2 text-[13px] md:text-[16px] `}>Details</p>
        <p 
        onClick={() => setActiveTab(DOCUMENTS_TAB)}
        className={`font-satoshi  ${activeTab === DOCUMENTS_TAB ? ' text-[#D95762] underline underline-offset-[14px]':'text-[#999DA8]' }  cursor-pointer px-2 text-[13px] md:text-[16px] `}>Documents</p>
        </div>
        <div className="flex flex-row gap-2">

        <p 
        onClick={() => setEdit(true)}
        className={`font-satoshi   cursor-pointer px-2 text-[13px] md:text-[16px] `}>Edit</p>
        {edit && (

        <p 
        onClick={updateMechanic}
        className={`font-satoshi   cursor-pointer px-2 text-[13px] md:text-[16px] `}>Save</p>
        )}
        </div>
        </div>
        <div className="border-b border-b-gray-200"></div>
        {activeTab === DETAILS_TAB &&
        <div className="flex flex-col gap-3 w-full px-4">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Bio</p>
        <div className="flex flex-col rounded-md px-2 py-2 bg-[#F7F7F8]">
          <input
          readOnly={!edit}
          value={bio}
          onChange={(e) => setBio(e.target.value)}
           type="text" className='font-satoshi outline-none text-[#030A1A]  bg-[#F7F7F8] px-4 py-2 text-[16px] md:text-[16px]'/>
     
        </div>
        <div className="flex flex-col rounded-md px-2 py-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] py-1 md:text-[16px] font-semibold'>Car specializations</p>
        <div className="flex flex-row gap-2 overflow-x-scroll hide-scrollbar">
          
            {carSpecializationsPrev?.map((car, i)=> (

            // <img src={car.thumbnail} alt={car.name} className='w-24 h-16 rounded' />
            <div className="flex flex-row gap-1 bg-gray-200 px-4 py-2 rounded-md">

              <p className='font-satoshi text-[#808491] px-2 text-[14px] md:text-[14px]'>{car.make}</p>
              {edit &&
              <svg
              onClick={() => removeSelecteSPecializationPrev(i)}
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
              }

            </div>

            ))}


        </div>
        {edit &&
        <div className="flex flex-col py-2">
            <Select
            onChange={e => setCarSpecializations(e)}
            options={loadVehicleOptions}
            isMulti

            placeholder='Add car specialization' 
            className='font-satoshi outline-none' />
          {/* <AsyncSelect
            cacheOptions
            placeholder="Search or select a vehicle"
            className='font-satoshi'
            isMulti
            loadOptions={loadVehicleOptions}
            name="vehicles"
            onChange={e => setCarSpecializations(e)}
          /> */}
      </div>
    }

        </div>
        <div className="flex flex-col rounded-md px-2 py-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] py-1 md:text-[16px] font-semibold'>Certifications</p>


        <div className="flex flex-row gap-2 overflow-x-scroll hide-scrollbar">
          
          {certificationsPrev?.map((certification, i)=> (

          // <img src={car.thumbnail} alt={car.name} className='w-24 h-16 rounded' />
          <div className="flex flex-row gap-1 bg-gray-200 px-4 py-2 rounded-md">

            <p className='font-satoshi text-[#808491] px-2 text-[14px] md:text-[14px]'>{certification.type} - {certification.qualification}</p>
          <p className='font-satoshi text-[#808491] px-2 text-[14px] md:text-[14px]'>{certification.institute}</p>

            {edit &&
            <svg
            onClick={() => removeCertificationsPrev(i)}
             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
            }

          </div>

          ))}


      </div>
      {edit &&
      <div className="flex flex-col py-2">
        <CreatableSelect
          cacheOptions
          placeholder="Certification Details"
          className='font-satoshi'
          isMulti
          name="vehicles"
          onChange={e => setCertifications(e)}
        />
    </div>
  }

        </div>

        <div className="flex flex-col rounded-md px-2 py-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] py-1 md:text-[16px] font-semibold'>Services</p>
        <div className="flex flex-row gap-2 overflow-x-scroll hide-scrollbar">
          
            {servicesPrev?.map((service, i)=> (

            <div className="flex flex-row gap-1 bg-gray-200 px-4 py-2 rounded-md">

              <p className='font-satoshi text-[#808491] px-2 text-[14px] md:text-[14px]'>{service}</p>
              {edit &&
              <svg
              onClick={() => removeServicePrev(i)}
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            }

            </div>

            ))}


        </div>
        {edit &&
        <div className="flex flex-col py-2">
          <AsyncSelect
            cacheOptions
            placeholder="Services"
            className='font-satoshi'
            isMulti
            name="Services"
            onChange={e => setServices(e)}
          />
      </div>
        }

        </div>

        </div>
        }
        {activeTab === DOCUMENTS_TAB &&

        <div className="flex flex-col gap-3 w-full px-4">
            <div className="flex flex-row gap-3">
                <div className="rounded-md h-20 w-20 px-2 py-1 bg-[#F2F2F4]">
               { mechanicData?.mechanic_good_conduct_url?
            <img src={mechanicData?.mechanic_good_conduct_url} alt="" />
            :
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full h-full">
        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>

        }

                </div>
                <div className="flex flex-col gap-2">
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Certificate of good conduct</p>
            {mechanicData?.mechanic_good_conduct_url ?
        <div
        onClick={() => setShoDocToBeVerified('conduct') }
         className=" rounded-md px-4 py-1 bg-[#E5E6E9] cursor-pointer text-center font-satoshi text-[16px]">Verify document</div>
        :
        <div className=" rounded-md px-4 py-1 bg-[#E5E6E9] text-center font-satoshi text-[16px]">Not uploaded</div>

            }
                </div>
            </div>

            <div className="flex flex-row gap-3">
                <div className="rounded-md h-20 w-20 px-2 py-1 bg-[#F2F2F4]">
                {mechanicData?.mechanic_document_url?
            <img src={mechanicData?.mechanic_document_url} className='w-full h-full object-cover' alt="" />

            :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full h-full">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
            </svg>
        }

                </div>
                <div className="flex flex-col md:flex-row gap-2 ">
                  <div className="flex flex-col gap-2">

        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>National ID</p>
        {mechanicData?.mechanic_document_url?
        <div
        onClick={() => setShoDocToBeVerified('id') } className="cursor-pointer rounded-md px-4 py-1 bg-[#E5E6E9] text-center font-satoshi text-[16px]">Verify document</div>
        :
        <div className=" rounded-md px-4 py-1 bg-[#E5E6E9] text-center font-satoshi text-[16px]">Not uploaded</div>

        }
          <input
          readOnly={!edit}
          value={idNumber}
          onChange={(e) => setIdNumber(e.target.value)}
          placeholder='ID number'
           type="text" className='font-satoshi outline-none text-[#030A1A]  bg-[#F7F7F8] border px-4 py-2 text-[16px] md:text-[16px]'/>
                  </div>
                </div>
                
     
            </div>
        </div>
        }

    </div>
    



        <div className="flex flex-col w-full md:w-[90%] border rounded-lg gap-2 pt-1 pb-2">
        <div className="flex flex-row gap-2">
        <p 
        onClick={() => setJobTab(JOB_HISTORY)}
        className={` font-satoshi ${jobTab === JOB_HISTORY ? ' text-[#333B51] underline underline-offset-[14px]':'text-[#999DA8]' } cursor-pointer px-2 text-[13px] md:text-[16px] `}>Job history</p>
        <p 
        onClick={() => setJobTab(COMPLAINTS_TAB)}
        className={`font-satoshi  ${jobTab === COMPLAINTS_TAB ? ' text-[#333B51] underline underline-offset-[14px]':'text-[#999DA8]' }  cursor-pointer px-2 text-[13px] md:text-[16px] `}>Complaints</p>
         <p 
        onClick={() => setJobTab(EARNINGS_TAB)}
        className={`font-satoshi  ${jobTab === EARNINGS_TAB ? ' text-[#333B51] underline underline-offset-[14px]':'text-[#999DA8]' }  cursor-pointer px-2 text-[13px] md:text-[16px] `}>Earnings</p>
        <p 
        onClick={() => setJobTab(CANCELLED_TAB)}
        className={`font-satoshi  ${jobTab === CANCELLED_TAB ? ' text-[#333B51] underline underline-offset-[14px]':'text-[#999DA8]' }  cursor-pointer px-2 text-[13px] md:text-[16px] `}>Cancelled</p>

        </div>
        <div className="border-b border-b-gray-200"></div>
        <div className="flex flex-col gap-4 px-4">
            <div className="flex flex-col md:flex-row gap-3">
                <div className="flex flex-col gap-2  rounded-md bg-[#F7F7F8] px-4 py-2">
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Total jobs</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>{jobs.count}</p>


                </div>

                <div className={` ${jobTab === COMPLAINTS_TAB ? 'bg-[#FFF0EB]':'bg-[#F7F7F8]'} flex flex-col gap-2 rounded-md  px-4 py-2`}>
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Complaints</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>1</p>


                </div>

                <div className="flex flex-col gap-2 rounded-md bg-[#F7F7F8] px-4 py-2">
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Total spend</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>KSh. 27,899</p>
                </div>

                <div className={` flex flex-col gap-2 rounded-md bg-[#F7F7F8] px-4 py-2`}>
                <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Cancelled</p>

                <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>
                {jobs?.results?.filter((cancelledJob) => cancelledJob.status !== 'X').length}
                </p>


                </div>
            </div>

        </div>
        <div className="flex flex-col gap-4 px-2">

        {jobTab === JOB_HISTORY &&
        <>
        
            <div className="flex flex-col gap-2 px-2">
            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Recent</p>
            <div className="border-b w-full border-gray-200"></div>

            {jobs?.results?.filter((cancelledJob) => cancelledJob.status !== 'X').map((job) => (
              <>
              <div className="flex flex-row gap-2 justify-between">
                  <div className="flex flex-col gap-1">
                    <Link to={`${ENDPOINT_JOBS}/${job.id}`}>

                  <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Job #{job.id}</p>
                    </Link>
              <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>
                {job.status === 'X'? 'Cancelled':  job.status === 'T'? 'Timeout':'Ongoing'}
                </p>
  
  
  
                  </div>
                  <div className="flex flex-row">
              <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'> {job.price ? 'KSH': null}</p>
              
              
  
                  <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>{formatNumber(job.price, 0, 0)}</p>
                  </div>
  
              </div>
  
          <div className="border-b border-b-gray-200"></div>
              </>
            ))}
            {jobs?.results?.length > 0 &&
        <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} />
            }


            </div>
        <div className="flex flex-row-reverse">
        {/* <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>See all</p> */}

        </div>
        </>
        }

{jobTab === CANCELLED_TAB &&
        <>
        
            <div className="flex flex-col gap-2 px-2">
            <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>Recent</p>
            <div className="border-b w-full border-gray-200"></div>

            {jobs?.results?.filter((cancelledJob) => cancelledJob.status === 'X').map((job) => (
              <>
              <div className="flex flex-row gap-2 justify-between">
                  <div className="flex flex-col gap-1">
                    <Link to={`${ENDPOINT_JOBS}/${job.id}`}>

                  <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>Job #{job.id}</p>
                    </Link>
              <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'>
                {job.status === 'X'? 'Cancelled':  job.status === 'T'? 'Timeout':'Ongoing'}
                </p>
  
  
  
                  </div>
                  <div className="flex flex-row">
              <p className='font-satoshi text-[#999DA8] px-2 text-[16px] md:text-[16px]'> {job.price ? 'KSH': null}</p>
              
              
  
                  <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>{formatNumber(job.price, 0, 0)}</p>
                  </div>
  
              </div>
  
          <div className="border-b border-b-gray-200"></div>
              </>
            ))}

{jobs?.results?.length > 0 &&
        <Pagination pageChanged={pageChanged} totalItems={totalItems} itemsPerPage={perPage} currentPage={currentPage} />
}

            </div>
        <div className="flex flex-row-reverse">
        {/* <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>See all</p> */}

        </div>
        </>
        }
        {jobTab === COMPLAINTS_TAB && 
        <div className="flex flex-row px-4 py-2 bg-[#FFF0EB]">
            <div className="">
                <img src={disc} className='rounded-full object-cover h-10 w-10' alt="" />
            </div>
            <div className="flex flex-col gap-1">
                <div className="flex flex-row gap-1">
                <p className='font-satoshi text-[#D95762] px-2 text-[16px] md:text-[16px]'>Complaint from</p>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px] font-semibold'>Annabelle Wangui</p>


                </div>
        <p className='font-satoshi text-[#030A1A] px-2 text-[16px] md:text-[16px]'>The mechanic installed a fan belt 2 hours ago and now it’s not working.</p>

            </div>
        </div>
        }

        </div>


        </div>


    </div>

    {showDocToBeVerified !== '' && 

              <>
                <div className='h-screen pt-14  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
                  <div className='relative p-4 w-full md:w-[50%] h-full md:h-auto'>
                    <div ref={cancelRef} className='border-0 rounded-2xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                      <div className='flex items-start justify-end p-2 rounded-t '>
                      </div>

                      <div className='relative p-3 flex-auto'>
                        <div className='px-4 pt-4 pb-3 w-full'>

                          <div className="flex flex-col">
                            <div onClick={() => setShoDocToBeVerified('')} className="">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 float-right cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </div>
                            <div className="flex flex-row gap-2 justify-between">

                              <div className="flex flex-row gap-2">
                                <img src="" alt="" />
                                <h2 className='text-[#030A1A] font-satoshi font-bold text-[24px] mb-4'>
                                  Verify Document
                                </h2>
                              </div>

                            </div>

                            <div className="flex flex-col gap-2 py-2 relative">
                                {showDocToBeVerified === 'id' ?
                                <img 
                                style={{transform: `rotate(${rotationDeg}deg)`}}
                                src={mechanicData?.mechanic_document_url} 
                                alt="" className={`w-full h-[400px] object-contain relative`} />
                                :showDocToBeVerified === 'conduct'?
                                <img 
                                style={{transform: `rotate(${rotationDeg}deg)`}}
                              src={mechanicData?.mechanic_good_conduct_url} 
                              alt="" className={`w-full h-[400px] object-contain relative`} />
                              :
                              null
                                }
                                <div 
                                onClick={rotateRight}
                                className="top-44 right-4 absolute cursor-pointer">
                                <svg 
                                onClick={rotateRight}
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>

                                </div>

                                <div 
                                onClick={rotateleft}
                                className="top-44 left-4 absolute cursor-pointer">
                                <svg
                                onClick={rotateleft}
                                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>

                                </div>

                            </div>

                            <div className="flex flex-row gap-2">
                            {showDocToBeVerified === 'id' ?
                                <button className=" w-[20%] rounded-md px-4 py-2 text-center bg-[#E5E6E9] text-center font-satoshi text-[16px]"> Verify</button>
                                :showDocToBeVerified === 'conduct'?
                                <button className=" w-[20%] rounded-md px-4 py-2 text-center bg-[#E5E6E9] text-center font-satoshi text-[16px]"> Verify</button>
                                :
                                null}
                                <button
                                onClick={() => setShoDocToBeVerified('')}
                                 className=" w-[20%] rounded-md px-4 py-2 text-center bg-[#E5E6E9] text-center font-satoshi text-[16px]"> Cancel</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div modalbackdrop='' className='h-screen bg-gray-900 bg-opacity-50  fixed inset-0 z-40' />
              </>
}
<ToastContainer

position="bottom-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>
    </>
  )
}

export default MechanicsDetailPage