import { axiosCustom } from '../../../utils';

const CMS_MECHANIC_URL = '/oms/cms/mechanic-subsections/';
const HOMEPAGE_SECTION_URL = '/oms/cms/homepage-sections/';

export const createMechanicSubsection = async (mechanicData) => {
    try {
        const response = await axiosCustom.post(CMS_MECHANIC_URL, mechanicData);
        return response.data;
    } catch (error) {
        console.log("an error occured while creating a mechanic subsection", error)
    }
};

export const getMechanicSubsections  = async () => {
    try {
        const response = await axiosCustom.get(CMS_MECHANIC_URL);
        return response.data.results;
    } catch (error) {
        console.log("an error occured while getting mechanic subsections", error)
    }
};

export const updateMechanicSubsection   = async (mechanicSubsectionId, updatedData) => {
    try {
        const response = await axiosCustom.put(`${CMS_MECHANIC_URL}${mechanicSubsectionId}/`, updatedData);
        return response.data;
    } catch (error) {
        console.log("an error occured while updating mechanic subsection", error)
    }
};

export const deleteMechanicSubsection  = async (mechanicSubsectionId) => {
    try {
        const response = await axiosCustom.delete(`${CMS_MECHANIC_URL}${mechanicSubsectionId}/`);
        return response.data;
    } catch (error) {
        console.log("an error occured while deleting a mechanic subsection", error)
    }
};
