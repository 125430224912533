import { Navigate, Outlet } from "react-router-dom"
import { hasValidAccessToken, isAnonymous, isAuthenticated } from "./utils"

export const ENDPOINT_DASHBOARD                 = '/'
export const ENDPOINT_USER_STATS                = '/user-stats'
export const ENDPOINT_STAFF                     = '/staff'
export const ENDPOINT_ACCOUNT                   = '/account'
export const ENDPOINT_ENTER_EMAIL               = '/account/login'
export const ENDPOINT_EMAIL_VERIFY              = '/account/login/2FA'
export const ENDPOINT_ORDERS                    = '/orders'
export const ENDPOINT_MECHANICS                 = '/mechanics'
export const ENDPOINT_JOBS                      = '/jobs'
export const ENDPOINT_CUSTOMERS                 = '/customers'
export const ENDPOINT_CUSTOMERS_ADD             = `${ENDPOINT_CUSTOMERS}/add`
export const ENDPOINT_CUSTOMERS_DETAILS         = `${ENDPOINT_CUSTOMERS}/:customerID`
export const ENDPOINT_MARKETPLACE               = '/marketplace'
export const ENDPOINT_JOB_DETAILS               = `/${ENDPOINT_JOBS}/:jobID`
export const ENDPOINT_STAFF_DETAILS             = `/${ENDPOINT_STAFF}/:staffID`
export const ENDPOINT_ORDER_DETAILS             = `/${ENDPOINT_ORDERS}/:orderID`
export const ENDPOINT_USERS                     = '/user'
export const ENDPOINT_INVENTORY                 = '/inventory'
export const ENDPOINT_VEHICLES                  = '/vehicles'
export const ENDPOINT_BRANDS                    = '/brands'

export const ENDPOINT_STAFF_ORDER_STATS         = '/staff-order-stats'
export const ENDPOINT_STAFF_ORDER_STATS_DETAILS = `${ENDPOINT_STAFF_ORDER_STATS}/:staffID`

export const ENDPOINT_CMS = '/cms'

export const ENDPOINT_CMS_HERO = '/cms/hero'
export const ENDPOINT_CMS_HERO_DETAILS = `/${ENDPOINT_CMS_HERO}/:heroID`
export const ENDPOINT_CMS_ADD_HERO              = '/cms/add-hero'
export const ENDPOINT_CMS_EDIT_HERO              = "/cms/edit-hero";

export const ENDPOINT_CMS_PRODUCT               = '/cms/product'
export const ENDPOINT_CMS_ADD_PRODUCT = '/cms/add-product'
export const ENDPOINT_CMS_PRODUCT_DETAILS = `/${ENDPOINT_CMS_PRODUCT}/:productID`

export const ENDPOINT_CMS_CATEGORY              = '/cms/category'
export const ENDPOINT_CMS_ADD_CATEGORY = "/cms/add-category";
export const ENDPOINT_CMS_CATEGORY_DETAILS     = `/${ENDPOINT_CMS_CATEGORY}/:categoryID`


export const ENDPOINT_CMS_BRAND                 = '/cms/brand'
export const ENDPOINT_CMS_ADD_BRAND = "/cms/add-brand";
export const ENDPOINT_CMS_BRAND_DETAILS         = `/${ENDPOINT_CMS_BRAND}/:brandID`

export const ENDPOINT_CMS_SHOP                  = '/cms/shop'
export const ENDPOINT_CMS_ADD_SHOP = "/cms/add-shop";
export const ENDPOINT_CMS_SHOP_DETAILS          = `/${ENDPOINT_CMS_SHOP}/:shopID`

export const ENDPOINT_CMS_ACCESSORY             = '/cms/accessory'
export const ENDPOINT_CMS_ADD_ACCESSORY = "/cms/add-accessory"
export const ENDPOINT_CMS_ACCESSORY_DETAILS     = `/${ENDPOINT_CMS_ACCESSORY}/:accessoryID`

export const ENDPOINT_CMS_PARTNER               = '/cms/partner'
export const ENDPOINT_CMS_ADD_PARTNER = "/cms/add-partner";
export const ENDPOINT_CMS_PARTNER_DETAILS       = `/${ENDPOINT_CMS_PARTNER}/:partnerID`

export const ENDPOINT_CMS_MECHANIC              = '/cms/mechanic'
export const ENDPOINT_CMS_ADD_MECHANIC = "/cms/add-mechanic";
export const ENDPOINT_CMS_MECHANIC_DETAILS      = `/${ENDPOINT_CMS_MECHANIC}/:mechanicID`

export const ENDPOINT_CMS_ADVERT                = '/cms/advert'
export const ENDPOINT_CMS_ADD_ADVERT            = "/cms/add-advert";
export const ENDPOINT_CMS_ADVERT_DETAILS        = `/${ENDPOINT_CMS_ADVERT}/:advertID`




export const ENDPOINT_BTOB                      = '/b2b'
export const ENDPOINT_BTOB_ONBOARDING           = '/onboard-b2b-client'
export const ENDPOINT_BTOB_CLIENT_DETAILS = `/${ENDPOINT_BTOB}/:clientID`



export const ENDPOINT_RFQS                       = '/rfqs'
export const ENDPOINT_RFQ_DETAILS               = `/${ENDPOINT_RFQS}/:rfqID` 

export const ENDPOINT_LPOS                       = '/lpos'
export const ENDPOINT_LPO_DETAILS               = `/${ENDPOINT_LPOS}/:lpoID`

export const ENDPOINT_INVOICES                       = '/invoices'
export const ENDPOINT_INVOICE_DETAILS               = `/${ENDPOINT_INVOICES}/:invoiceID`

export const ENDPOINT_GRNS                       = '/grns'
export const ENDPOINT_GRN_DETAILS               = `/${ENDPOINT_GRNS}/:grnID`

export const ENDPOINT_INVENTORY_DETAILS         = `/${ENDPOINT_INVENTORY}/:inventoryID`
export const ENDPOINT_INVENTORY_EDIT            = `/${ENDPOINT_INVENTORY}/edit/:inventoryID`
export const ENDPOINT_INVENTORY_ADD             = `/add-inventory`

export const ENDPOINT_TRACK_ORDERS              = '/track-orders'

export const ENDPOINT_USER_DETAILS              = `/${ENDPOINT_USERS}/:userID`
export const ENDPOINT_MERCHANT_DETAILS          = `/${ENDPOINT_USERS}/merchant/:userID`
export const ENDPOINT_MECHANIC_DETAILS          = `/${ENDPOINT_USERS}/mechanic/:userID`
export const ENDPOINT_DSF                       = '/dsfs'
export const ENDPOINT_DSF_DETAILS               = `/${ENDPOINT_DSF}/:dsfID` 
export const ENDPOINT_DSF_EDIT                  = `/${ENDPOINT_DSF}/:dsfID/edit` 

export const ENDPOINT_LEADS                       = '/leads'
export const ENDPOINT_LEAD_DETAILS               = `/${ENDPOINT_LEADS}/:leadID` 
export const ENDPOINT_LEAD_EDIT               = `/${ENDPOINT_LEADS}/:leadID/edit`

export const ENDPOINT_SERVICE_BOOKING           = '/bookings'
export const ENDPOINT_SERVICE_BOOKING_DETAILS   = `/${ENDPOINT_SERVICE_BOOKING}/:bookingID`

export const ENDPOINT_SERVICE_CENTER           = '/service-centers'
export const ENDPOINT_SERVICE_CENTER_DETAILS   = `/${ENDPOINT_SERVICE_CENTER}/:serviceCenterID`

export const ENDPOINT_PART_REQUESTS             = '/part-requests'
export const ENDPOINT_PART_REQUESTS_DETAILS     = `/${ENDPOINT_PART_REQUESTS}/:partRequestID` 


export const CUSTOMERS_TAB_ORDERS = "orders"
export const CUSTOMERS_TAB_PROFILE = "profile"
export const CUSTOMERS_TAB_TICKETS = "tickets"
export const CUSTOMERS_TAB_PAYMENTS = "payments"
export const CUSTOMERS_TAB_SERVICES = "services"
export const CUSTOMERS_TAB_BOOKINGS = "bookings"
export const CUSTOMERS_TAB_ACTIVITY = "activity"
export const CUSTOMERS_TAB_FAVORITES = "favorites"
export const CUSTOMERS_TAB_MESSAGES = "messages"
export const CUSTOMERS_TAB_PART_REQUESTS = "part_requests"
export const CUSTOMERS_TAB_ADD_PART_REQUESTS = "part_requests/add"
export const CUSTOMERS_TAB_ORDER_DETAILS = `${CUSTOMERS_TAB_ORDERS}/:orderID`
export const CUSTOMERS_TAB_PART_REQUEST_DETAILS = `${CUSTOMERS_TAB_PART_REQUESTS}/:partRequestID`
export const ENDPOINT_GENERATE_INVOICE                      = '/invoice-generate'

export const ENDPOINT_SHORT_URLS = `/urls`
export const ENDPOINT_SHORT_URLS_ADD = `/urls/add`
export const ENDPOINT_SHORT_URLS_DETAILS = `/urls/:urlID`

export const ENDPOINT_REVIEWS = `/reviews`
export const ENDPOINT_REVIEW_DETAILS = `/reviews/:reviewID`

export const AnonymousRoute = () => {
    return (
        isAnonymous() ? <Outlet/> : <Navigate to={ENDPOINT_DASHBOARD}/>
    )
}

export const HasTokenRoute = () => {
    return (
        hasValidAccessToken() ? <Outlet/> : <Navigate to={ENDPOINT_ENTER_EMAIL}/>
    )
}

export const AuthenticatedRoute = () => {
    const endpoint = isAuthenticated()
    return (
        renderSwitch(endpoint)
    )
}


const renderSwitch = (param) => {
    switch(param) {
        case ENDPOINT_DASHBOARD:
            return <Outlet/>
        case ENDPOINT_ENTER_EMAIL:
            return <Navigate to={ENDPOINT_ENTER_EMAIL}/>
        case ENDPOINT_EMAIL_VERIFY:
            return <Navigate to={ENDPOINT_EMAIL_VERIFY}/>
        default:
            return <Outlet/>
    }
}